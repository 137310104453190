import React, {PureComponent} from 'react';
import styled from 'styled-components';

import {intl, stylingVariables} from '@global';
import {EmployeeInterfaces, LeaveInterfaces, ApiEmployee} from '@api';
import {Modal as CommonModal, Switch, BlankButton} from '@common';

import ModalHeader from './ModalHeader';
import ProcessDetails from './ProcessDetails';
import LeaveDetails from './LeaveDetails';
import Attachment from './Attachment';
import {connect} from 'react-redux';
import {RootState} from '../../../../../../rootReducer';
import * as globalSelectors from '../../../../../selectors';
import {PayrollPeriod} from '../../../../../../api/payroll.interfaces';
import ApproveAction from '../Approve';
import RejectAction from '../Reject';

interface ModalProps {
    employee: EmployeeInterfaces.Employee|null;
    transactions: LeaveInterfaces.Transaction[]|null;
    onTransactionOpen?: (e: any, transaction: LeaveInterfaces.Transaction) => void;

    onClickEdit: () => void;
    onClickReject: (employeeId: number, transaction: LeaveInterfaces.Transaction) => void;
    onClickApprove: (employeeId: number, transaction: LeaveInterfaces.Transaction) => void;
    onClickClose: () => void;
    currentPayrollPeriod: null|PayrollPeriod;
}

interface ModalState {
    allOpen: boolean;
    masterdataEditAllowed:boolean 
}

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const ExpandButton = styled(props => <BlankButton {...props} />)`
  color: ${stylingVariables.colorPalette.green};
  font-weight: ${stylingVariables.fontWeight.bold};
  font-size: ${stylingVariables.fontSize.mediumLarge};
  margin: 30px 51px 0 0;
  color: ${stylingVariables.colorPalette.darkest};
  padding: 5px 15px;
  border-radius: 13px;
  &:hover {
    background-color: ${stylingVariables.colorPalette.primaryHover};
  }
`;

const ModalContentWrapper = styled.div`
  max-height: calc(100vh - 200px);
  overflow-y: scroll;
`;

const ProcessingWarning = styled.div`
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  font-size: ${stylingVariables.fontSize.mediumSmall};
  color: ${stylingVariables.colorPalette.deepGray};
`;

const EssTransactionAlert = styled.div`
  padding: 10px 40px 20px;
  display: flex;
  justify-content: flex-end;
  font-size: ${stylingVariables.fontSize.large};
  color: ${stylingVariables.colorPalette.red};
`;

export class Modal extends PureComponent<ModalProps, ModalState> {
    constructor(props: ModalProps) {
        super(props);
        this.state = {
            allOpen: true,
            masterdataEditAllowed: false,
        };
    }

    async componentDidMount() {
        let editAllowed = await this.masterdataEditAllowed();
        this.setState({ masterdataEditAllowed: editAllowed });
    }

    private masterdataEditAllowed = async (): Promise<boolean> => {
        const legalEntityId= this.props.employee?.legalEntityId
        const id = this.props.employee?.id
        let allowed =  false
        if (legalEntityId && id) {
          const { employeesCutoffStatuses } = await ApiEmployee.getEmployeeCutOffs(
            legalEntityId,
            [id]
          );
          allowed =
            employeesCutoffStatuses &&
            employeesCutoffStatuses[id]?.masterdataEditAllowed;
        }
        return allowed;
    };
    

    public render() {
        if (!this.props.employee || !this.props.transactions) {
            return null;
        }

        return (
            <CommonModal.CenterModal
                isDrawer={true}
                hasBackground={false}
                width={'650px'}
                minWidth={'650px'}
                onClickClose={this.props.onClickClose}
                shouldSupportCloseWhenClickOutside={false}
                hasBottomPadding={false}
            >
                {this.modalContent()}
            </CommonModal.CenterModal>
        );
    }

    toggleCards = () => {
        this.setState(prevState => ({
            allOpen: !prevState.allOpen,
        }));
    }

    private modalContent = () => {
        if (!this.props.employee || !this.props.transactions) {
            return null;
        }

        const { transactions, currentPayrollPeriod } = this.props;
        const isGroupView = transactions.length > 1;
        const transaction = transactions[0];
        const payrollProcessing = currentPayrollPeriod?.status === 'processing';
        const essTransaction = transactions.some((transaction) => transaction.source === 'ess')

        return (
            <ModalContentWrapper>
                <ModalHeader leave={transaction} forEmployee={this.props.employee}/>

                <ButtonWrapper>
                    <ExpandButton onClick={this.toggleCards}>
                        {intl.get(this.state.allOpen ? 'collapse_all' : 'expand_all')}
                    </ExpandButton>
                </ButtonWrapper>

                <ProcessDetails leave={transaction} isOpen={this.state.allOpen}/>
                <LeaveDetails
                    onClickEdit={this.props.onClickEdit}
                    leave={transactions}
                    isOpen={this.state.allOpen}
                    employee={this.props.employee}
                    onOpen={this.props.onTransactionOpen}
                />
                {(transaction.status === LeaveInterfaces.TransactionStatus.draft ||
                    transaction.status === LeaveInterfaces.TransactionStatus.pending) && (
                    <Attachment isOpen={this.state.allOpen}/>
                )}
                <Switch
                    current={transaction.status}
                    options={new Map([
                        [
                            LeaveInterfaces.TransactionStatus.draft,
                            <>
                                {payrollProcessing &&
                                    <ProcessingWarning>
                                        {intl.get('cannot_approve_payroll_processing')}
                                    </ProcessingWarning>
                                }
                                <CommonModal.StyledModalActionBar>
                                    {!isGroupView && !payrollProcessing &&
                                    <ApproveAction
                                        method={'approveButton'}
                                        employee={this.props.employee}
                                        transaction={transaction}
                                        onClickApprove={this.approve}
                                        masterdataEditAllowed={this.state.masterdataEditAllowed}
                                    />
                                    }
                                    {isGroupView && !payrollProcessing &&
                                    <ApproveAction
                                        method={'approveAllButton'}
                                        employee={this.props.employee}
                                        transaction={transaction}
                                        onClickApprove={this.approveAll}
                                        masterdataEditAllowed={this.state.masterdataEditAllowed}
                                    />
                                    }
                                    

                                    {!transaction.originalTransaction && !isGroupView && !essTransaction &&
                                    <RejectAction
                                        method={'rejectButton'}
                                        employee={this.props.employee}
                                        transaction={transaction}
                                        onClickReject={this.reject}
                                        masterdataEditAllowed={this.state.masterdataEditAllowed}
                                    />
                                    }
                                    {isGroupView && !essTransaction &&
                                    <RejectAction
                                        method={'rejectAllButton'}
                                        employee={this.props.employee}
                                        transaction={transaction}
                                        onClickReject={this.rejectAll}
                                        masterdataEditAllowed={this.state.masterdataEditAllowed}
                                    />
                                    }
                                </CommonModal.StyledModalActionBar>
                            </>,
                            ],
                            [
                            LeaveInterfaces.TransactionStatus.pending,
                            <CommonModal.StyledModalActionBar>
                                {!transaction.originalTransaction && !isGroupView && !essTransaction &&
                                <RejectAction
                                    method={'rejectButton'}
                                    employee={this.props.employee}
                                    transaction={transaction}
                                    onClickReject={this.reject}
                                    masterdataEditAllowed={this.state.masterdataEditAllowed}
                                />
                                }
                                {isGroupView && !essTransaction &&
                                <RejectAction
                                    method={'rejectAllButton'}
                                    employee={this.props.employee}
                                    transaction={transaction}
                                    onClickReject={this.rejectAll}
                                    masterdataEditAllowed={this.state.masterdataEditAllowed}
                                />
                                }
                            </CommonModal.StyledModalActionBar>,
                        ],
                    ])}
                />
                {(transaction.status === LeaveInterfaces.TransactionStatus.draft ||
                    transaction.status === LeaveInterfaces.TransactionStatus.pending) && essTransaction && (
                    <EssTransactionAlert>
                        {intl.get('cannot_reject_transaction_from_ess')}
                    </EssTransactionAlert>
                )}
            </ModalContentWrapper>
        );
    }

    private approve = () => {
        if (!this.props.employee || !this.props.transactions) {
            return;
        }

        const transaction = Array.isArray(this.props.transactions) ?
            this.props.transactions[0] : this.props.transactions;

        if (!transaction) {
            return;
        }

        this.props.onClickApprove(this.props.employee.id, transaction);
        this.props.onClickClose();
    }

    private approveAll = async() => {
        if (!this.props.employee || !this.props.transactions || !Array.isArray(this.props.transactions)) {
            return;
        }

        await Promise.all(this.props.transactions.map(async transaction => {
            if (this.props.employee) {
                await this.props.onClickApprove(this.props.employee.id, transaction);
            }
        }));

        this.props.onClickClose();
    }

    private reject = () => {
        if (!this.props.employee || !this.props.transactions) {
            return;
        }

        const transaction = Array.isArray(this.props.transactions) ?
            this.props.transactions[0] : this.props.transactions;

        this.props.onClickReject(this.props.employee.id, transaction);
        this.props.onClickClose();
    }

    private rejectAll = async() => {
        if (!this.props.employee || !this.props.transactions || !Array.isArray(this.props.transactions)) {
            return;
        }

        await Promise.all(this.props.transactions.map(async transaction => {
            if (this.props.employee) {
                await this.props.onClickReject(this.props.employee.id, transaction);
            }
        }));

        this.props.onClickClose();
    }
}

const mapStateToProps = (state: RootState) => ({
    currentPayrollPeriod: globalSelectors.getCurrentPayrollPeriod(state),
});

export default connect(mapStateToProps)(Modal);
