import React, {PureComponent} from 'react';
import {Modal as CommonModal, Layout, Icon} from '@common';
import styled from 'styled-components';
import Validator from 'validatorjs';

import {EmployeeInterfaces, LeaveInterfaces, PayrollInterfaces , ApiEmployee} from '@api';
import {constants, intl, stylingVariables} from '@global';

import EmployeeRow from './EmployeeRow';
import LeaveTypeRow from './LeaveTypeRow';
import PeriodRow from './PeriodRow';
import {RootState} from '../../../../../../rootReducer';
import * as globalSelectors from '../../../../../selectors';
import {connect} from 'react-redux';

import { AnimationStyles, SpinnerIcon } from "../../../../../Loader";

const IconWrapper = styled.div`
    border-radius: 50%;
    background: ${stylingVariables.colorPalette.lightGray};
    width: 42px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
`;

const ActionBar = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 30px 85px 50px 0;

    button {
        margin-left: 30px;
    }
`;

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 10px 0;
`;


export const ModalHeader = styled.div<{color?: string}>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 33px 0 10px 20px;
  h4 {
    color: ${props => props.color || stylingVariables.colorPalette.green};
    line-height: 1.4em;
  }
`;

const LinedHeader = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    h4 {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-size: ${stylingVariables.fontSize.mediumLarge};
        padding: 20px 0;
    }

    h4:after {
        content: '';
        border-top: 1px solid ${stylingVariables.colorPalette.gray};
        margin: 0 0 0 18px;
        flex: 1 0 18px;
    }

    h4:before {
        content: '';
        border-top: 1px solid ${stylingVariables.colorPalette.gray};
        margin: 0 18px 0 0;
        width: 50px;
    }
`;

const ProcessingWarning = styled.div`
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  font-size: ${stylingVariables.fontSize.default};
  color: ${stylingVariables.colorPalette.red};
`;

interface ModalProps {
    employee?: EmployeeInterfaces.Employee|null;
    types: LeaveInterfaces.Type[];
    legalEntityId: number|null;

    onClickClose: () => void;
    onClickCreate: (employeeId: number, transaction: LeaveInterfaces.Transaction) => void;
    onClickCreateAndApprove?: (employeeId: number, transaction: LeaveInterfaces.Transaction) => void;
    currentPayrollPeriod: PayrollInterfaces.PayrollPeriod|null;
}

interface ModalState {
    employeeId: number | null;
    leaveTypeId: number | null;
    from: string | null | undefined;
    till: string | null | undefined;

    errors: {
        [key: string]: string[],
    };
    masterdataEditAllowed: boolean;
    isLoading: boolean;
}

export class AddModal extends PureComponent<ModalProps, ModalState> {
    private readonly validationRules: any = {
        employeeId: 'required',
        leaveTypeId: 'required',
        from: 'required',
    };

    private readonly validationMessages = {
        required: 'Field can not be empty',
        'required.till': 'Open ended leave can be created only for leave types that do not have balance',
    };

    private readonly FIELD_EMPLOYEE = 'employeeId';
    private readonly FIELD_LEAVE_TYPE = 'leaveTypeId';
    private readonly FIELD_DAYS = 'till';

    public constructor(props: ModalProps) {
        super(props);

        this.state = {
            employeeId: props.employee ? props.employee.id : null,
            leaveTypeId: null,
            from: null,
            till: null,
            errors: {},
            masterdataEditAllowed: true,
            isLoading: true,
        };
    }

    async componentDidMount() {
        if (this.state.employeeId) {
            const editAllowed = await this.masterdataEditAllowed(this.state.employeeId);
            this.setState({ masterdataEditAllowed: editAllowed });
        }
        this.setState({isLoading:false})
    }

    render() {
        const { masterdataEditAllowed, isLoading } = this.state;
        const payrollProcessing = this.props.currentPayrollPeriod?.status === 'processing';
        return (
            <CommonModal.CenterModal width={'660px'} minWidth={'650px'} onClickClose={this.props.onClickClose}>
                <ModalHeader color={stylingVariables.colorPalette.darkest}>
                    <IconWrapper>
                        <Icon type={constants.IconTypes.INTERVIEW}
                              fillColor={stylingVariables.colorPalette.green}
                              width={18}
                              height={18}
                        />
                    </IconWrapper><h4>{intl.get('new_leave_transaction')}</h4>
                </ModalHeader>

                <LinedHeader>
                    <h4>{intl.get('leave_details')}</h4>
                </LinedHeader>
                <CommonModal.OverflowableContent style={{padding: '20px 40px'}}>
                    <Layout.Rows>
                        <EmployeeRow
                            employee={this.props.employee}
                            currentLegalEntityId={this.props.legalEntityId}
                            onChange={this.onChangeEmployee}
                            errors={this.state.errors[this.FIELD_EMPLOYEE]}
                            />

                        <LeaveTypeRow
                            leaveTypes={this.props.types}
                            onChange={this.onChangeLeaveType}
                            errors={this.state.errors[this.FIELD_LEAVE_TYPE]}
                        />

                        <PeriodRow
                            onChange={this.onChangePeriod}
                            errors={this.state.errors[this.FIELD_DAYS]}
                            defaultFrom={this.props.currentPayrollPeriod ?
                                this.props.currentPayrollPeriod.beginDate :
                                undefined}
                        />
                    </Layout.Rows>
                </CommonModal.OverflowableContent>

                {payrollProcessing &&
                    <ProcessingWarning>
                        {intl.get('cannot_approve_payroll_processing')}
                    </ProcessingWarning>
                }

                {isLoading ? (
                  <LoaderWrapper>
                    <AnimationStyles />
                    <SpinnerIcon />
                  </LoaderWrapper>
                ) : !masterdataEditAllowed ? (
                  <ProcessingWarning>
                    {intl.get("masterdata_edit_not_allowed")}
                  </ProcessingWarning>
                ) : (
                  <ActionBar>
                    <CommonModal.StyledCloseModalButton
                      onClick={this.props.onClickClose}
                    >
                      {intl.get("cancel")}
                    </CommonModal.StyledCloseModalButton>

                    <CommonModal.StyledAddButton onClick={() => this.save(false)}>
                      {intl.get("add_leave_transaction")}
                    </CommonModal.StyledAddButton>

                    {this.props.onClickCreateAndApprove && !payrollProcessing && (
                      <CommonModal.StyledApproveButton onClick={() => this.save(true)}>
                        {intl.get("add_and_approve")}
                      </CommonModal.StyledApproveButton>
                    )}
                  </ActionBar>
                )}
            </CommonModal.CenterModal>
        );
    }

    private save = (approve: boolean) => {
        const leaveType = this.props.types.find(x => x.id === this.state.leaveTypeId);
        if (leaveType?.hasBalance) {
            this.validationRules.till = 'required';
        } else {
            if (this.validationRules.till) {
                delete this.validationRules.till;
            }
        }

        const validator = new Validator(this.state, this.validationRules, this.validationMessages);
        validator.check();
        if (validator.passes()) {
            if (approve && this.props.onClickCreateAndApprove) {
                this.props.onClickCreateAndApprove(
                    this.state.employeeId as number,
                    {
                        leaveTypeId: this.state.leaveTypeId,
                        startDate: this.state.from,
                        endDate: this.state.till,
                    } as LeaveInterfaces.Transaction,
                );
            } else {
                this.props.onClickCreate(
                    this.state.employeeId as number,
                    {
                        leaveTypeId: this.state.leaveTypeId,
                        startDate: this.state.from,
                        endDate: this.state.till,
                    } as LeaveInterfaces.Transaction,
                );
            }
            this.props.onClickClose();
        } else {
            this.setState({errors: validator.errors.errors});
        }
    }


    private masterdataEditAllowed = async (employeeId: number): Promise<boolean> => {
        const entityId = this.props.legalEntityId
        let allowed =  false
        if (entityId){
            const { employeesCutoffStatuses } = await ApiEmployee.getEmployeeCutOffs(entityId, [employeeId])
            allowed = employeesCutoffStatuses && employeesCutoffStatuses[employeeId]?.masterdataEditAllowed

        }
        return allowed
    }

    private onChangeEmployee = async (employeeId: number | null, employee: EmployeeInterfaces.Employee|undefined) =>
        {this.setState({employeeId, isLoading:true})
        let editAllowed = true
        if (employeeId) {
            editAllowed = await this.masterdataEditAllowed(employeeId)
        }

        this.setState({masterdataEditAllowed: editAllowed ,  isLoading:false})

    };
    private onChangeLeaveType = (leaveTypeId: number | null) => this.setState({leaveTypeId});
    private onChangePeriod = (from?: string|null, till?: string|null) => this.setState({from, till});
}

const mapStateToProps = (state: RootState) => ({
    currentPayrollPeriod: globalSelectors.getCurrentPayrollPeriod(state),
});

export default connect(mapStateToProps)(AddModal);
