import {ApiObject} from '@api';
import {ToggleCheckbox} from 'hrb-common';
import React, {PureComponent} from 'react';
import {intl} from '@global';
import {Modal as CommonModal, Layout} from '@common';
import styled from 'styled-components';

import {stylingVariables, constants} from '@global';
import {RootState} from '../../../../../rootReducer'
import {createReport, openReportExportProcess} from '../../redux';
import * as selectors from '../../selectors';
import NameRow from '../NameRow';
import {connect} from 'react-redux';
import {RouteHelper} from '@library';

const ActionBar = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 25px 70px 0 0;
    
    button {
        margin-left: 30px;
    }
`;

interface IProps {
    report: ApiObject.Report|null;
    onClose: () => void;
    templateLabel: string;
    reportSuffix: string;
    createReport: (partOfReport: { label: string, visibility: ApiObject.ReportVisibility, legalEntityReportType?: ApiObject.DataSource, regenerateMasterdata?: boolean}) => any;
    openReportExportProcess: (report: ApiObject.Report, mode?: constants.ReportProcessMode) => void;
}

interface IState {
    reportName: string;
    visibility: ApiObject.ReportVisibility;
    hasError: boolean;
    isSaving: boolean;
}

export class SaveReportModal extends PureComponent<IProps, IState> {
    public constructor(props: IProps) {
        super(props);

        this.state = {
            reportName: props.templateLabel,
            visibility: props.report ? props.report.visibility : ApiObject.ReportVisibility.public,
            hasError: false,
            isSaving: false,
        };
    }

    render() {
        return (
            <CommonModal.CenterModal width={'750px'} minWidth={'750px'} onClickClose={this.props.onClose}>
                <CommonModal.StyledHeader
                    style={{padding: '20px 80px'}}
                    color={stylingVariables.colorPalette.darkest}
                >
                    <h4>{intl.get('save_and_generate_report')}</h4>

                    <ToggleCheckbox
                        isChecked={this.state.visibility === ApiObject.ReportVisibility.public}
                        labelOptions={{yes: intl.get('common'), no: intl.get('personal')}}
                        onChange={(bool: boolean) => {
                            this.onChangeVisibility(
                                bool ? ApiObject.ReportVisibility.public : ApiObject.ReportVisibility.private
                            )
                        }} />
                </CommonModal.StyledHeader>

                <CommonModal.OverflowableContent style={{padding: '20px 80px'}}>
                    <Layout.Rows>
                        <NameRow
                            reportName={this.props.templateLabel}
                            suffix={`_${this.props.reportSuffix}`}
                            onChange={this.onChangeName}
                            errors={this.state.hasError ? [intl.get('field_cannot_be_empty')] : undefined}
                        />
                    </Layout.Rows>
                </CommonModal.OverflowableContent>

                <ActionBar>
                    <CommonModal.StyledCancelButton onClick={this.props.onClose}>
                        {intl.get('cancel')}
                    </CommonModal.StyledCancelButton>

                    <CommonModal.StyledOkButton isDisabled={this.state.isSaving} onClick={this.onSave}>
                        {intl.get('save_and_generate')}
                    </CommonModal.StyledOkButton>

                </ActionBar>
            </CommonModal.CenterModal>
        );
    }

    private isValid = () => {
        const isOk = !!this.state.reportName
        this.setState({
            hasError: !isOk
        })

        return isOk;
    }

    private onSave = async () => {
        this.setState({isSaving: true});
        if (this.isValid()) {
            const legalEntityReportType = this.props.report
              ? this.props.report.legalEntityReportType
              : ApiObject.DataSource.payroll

            const createdReport = await this.props.createReport({
                label: this.state.reportName,
                visibility: this.state.visibility,
                legalEntityReportType,
                regenerateMasterdata: (this.props.report && this.props.report.regenerateMasterdata) || false,
            });

            if (createdReport) {
                this.props.openReportExportProcess(createdReport, constants.ReportProcessMode.createReport);
                this.props.onClose();
                RouteHelper.go(`${constants.ROUTE_REPORTS}?visibility=${createdReport.visibility}`);
            }
        }
        this.setState({isSaving: false});
    }

    private onChangeName = (reportName: string) => this.setState({reportName});
    private onChangeVisibility = (visibility: ApiObject.ReportVisibility) => this.setState({visibility});
}

const mapStateToProps = (state: RootState) => ({
    report: selectors.getReport(state),
    templateLabel: selectors.getTemplateLabel(state),
    reportSuffix: selectors.getReportSuffix(state)
});

const mapDispatchToProps = {
    createReport,
    openReportExportProcess
};

export default connect(mapStateToProps, mapDispatchToProps)(SaveReportModal);
