import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {Column} from 'react-virtualized';

import {constants} from '@global';
import {EmployeeInterfaces, PayrollInterfaces} from '@api';
import {
    defaultInfiniteTableCellDataGetter,
    Switch,
    Actions as CommonActions,
} from '@common';

import Activate from '../../../common/Employee/Activate';
import InsertSeparation from '../Actions/Separation/InsertSeparation';
import RemovePendingTermination from "../../../common/Employee/RemovePendingTermination";
import * as Headers from '../Header';

import {RootState} from '../../../../rootReducer';
import * as globalSelectors from '../../../selectors';
import {activate, suspend, sendEssInvitation, revokeEssAccess, removePendingTermination} from '../../redux';

interface IProps {
    cellData: EmployeeInterfaces.Employee;
    isLoading: boolean;
    defaultWelcomeMessage: string;

    currentPeriod: PayrollInterfaces.PayrollPeriod|null;
    activate: (ids: Set<number>) => void;
    suspend: (ids: Set<number>) => void;
    sendEssInvitation: (payload: any) => void;
    revokeEssAccess: (ids: Set<number>) => void;
    removePendingTermination: (id: number) => void;
}

interface IState {
    employeeIds: Set<number>;
    employeeId : number
    cuttOffs?:Object|null
    hiredAfterPeriodEnd:boolean|false
}

class Cell extends PureComponent<IProps, IState> {
    public constructor(props: IProps) {
        super(props);

        this.state = {
            employeeIds: new Set([props.cellData.id]),
            employeeId: props.cellData.id,
            cuttOffs : props.cellData.cuttOffs || null,
            hiredAfterPeriodEnd: false
        };
    }


    componentDidMount() {
       const hired = this.props.cellData?.hireDate
       const periodEndDate = this.props.currentPeriod?.endDate
       if (hired && periodEndDate){
           const hiredAfterPeriodEnd = new Date(hired) > new Date(periodEndDate)
           this.setState({hiredAfterPeriodEnd})
       }
    }

    public render() {
        const { status, terminationStatus } = this.props.cellData;
        return (
            <Switch current={status} options={
                new Map([
                    [
                        constants.EmployeeStatuses.active,
                        <CommonActions.MenuActions>
                            {terminationStatus === "draft" && this.renderRemovePendingTerminationAction()}
                            {this.renderInsertSeparationAction()}
                        </CommonActions.MenuActions>,
                    ],
                    [
                        constants.EmployeeStatuses.draft,
                        <CommonActions.MenuActions>
                            {this.renderActivateAction()}
                            {this.renderInsertSeparationAction()}
                        </CommonActions.MenuActions>,
                    ],
                ])}
            />
        );
    }

    private renderActivateAction() {
        return (
            <Activate
                forEmployeeIds={this.state.employeeIds}
                isLoading={this.props.isLoading}
                onClickActivate={this.props.activate}
                cuttOffs={this.state.cuttOffs}
                hiredAfterPeriodEnd={this.state.hiredAfterPeriodEnd}
            />
        );
    }

    private renderInsertSeparationAction() {
        return (
            <InsertSeparation forEmployeeIds={this.state.employeeIds} employee={this.props.cellData} cuttOffs={this.state.cuttOffs}/>
        );
    }
    
    private renderRemovePendingTerminationAction() {
        return (
          <RemovePendingTermination
            forEmployeeId={this.state.employeeId}
            isLoading={this.props.isLoading}
            onClickRemovePendingTermination={this.props.removePendingTermination}
            cuttOffs={this.state.cuttOffs}
          />
        );
      }
}

const mapStateToProps = (state: RootState, ownProps: any) => ({
    defaultWelcomeMessage: globalSelectors.getWelcomeMessage(state, ownProps),
    isLoading: globalSelectors.isLoading(state),
    currentPeriod: globalSelectors.getCurrentPayrollPeriod(state)
});

const mapDispatchToProps = {
    activate,
    suspend,
    sendEssInvitation,
    revokeEssAccess,
    removePendingTermination
};

const ConnectedCell = connect(mapStateToProps, mapDispatchToProps)(Cell);

const Actions = () => (
    <Column
        dataKey='actions'
        width={80}
        cellDataGetter={defaultInfiniteTableCellDataGetter}
        headerRenderer={() => <Headers.Settings/>}
        cellRenderer={({cellData}) => <ConnectedCell cellData={cellData}/>}
    />
);

export default Actions;
