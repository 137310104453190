import React, {PureComponent} from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';

import {stylingVariables} from '@global';
import {DomHelper} from '@library';

import CloseButton from './CloseButton';

export const StyledBackground = styled.div<{ zIndex?: number }>`
    width: 100vw;
    height: 100vh;
    position: absolute;
    left: 0;
    top: 0;
    z-index: ${props => props.zIndex ?? 10000};
    background-color: #97A0AC99;
    overflow: auto;
`;

export const Container = styled.div<{ zIndex?: number }>`
    position: absolute;
    top: 150px;
    right: 45px;
    z-index: ${props => props.zIndex ?? 10000};
`;

// eslint-disable-next-line no-unexpected-multiline
export const StyledModalWrapper = styled.div<{
    margins: string;
    minWidth?: string;
    width?: string;
    isDrawer?: boolean;
    zIndex?: number;
    hasBottomPadding?: boolean;
    borderRadius?: string;
}>`
    position: relative;
    width: ${props => props.width || '40%'};
    min-width: ${props => props.minWidth || '650px'};
    background-color: ${stylingVariables.colorPalette.white};
    box-shadow: ${stylingVariables.layout.boxShadow};
    border-radius: ${props => props.borderRadius || '8px'};
    z-index: ${props => props.zIndex ?? 10000};
    margin: ${props => props.margins};
    padding-bottom: ${props => props.hasBottomPadding ? 30 : 0}px;
`;

const StyledCloseButton = styled(CloseButton)`  
    z-index: 2;
`;

interface IProps {
    children: any;
    shouldSupportCloseWhenClickOutside?: boolean;

    onClickClose: () => void;
    isDrawer?: boolean;
    hasBackground: boolean;

    width?: string;
    borderRadius?: string;
    minWidth?: string;
    hasBottomPadding?: boolean;
}

const appRoot = document.getElementById('root') ?? document.body;

export default class CenterModal extends PureComponent<IProps> {
    private wrapperRef: any;

    static defaultProps = {
        shouldSupportCloseWhenClickOutside: true,
        hasBackground: true,
        hasBottomPadding: true,
    };

    public componentDidMount() {
        if (this.props.shouldSupportCloseWhenClickOutside) {
            document.addEventListener('mousedown', this.closeWhenClickOutside);
        }
    }

    public componentWillUnmount() {
        if (this.props.shouldSupportCloseWhenClickOutside) {
            document.removeEventListener('mousedown', this.closeWhenClickOutside);
        }
    }

    public render() {
        return ReactDOM.createPortal(
            this.renderModal(
                <StyledModalWrapper
                    ref={this.setWrapperRef}
                    width={this.props.width}
                    minWidth={this.props.minWidth || this.props.width}
                    isDrawer={this.props.isDrawer}
                    margins={this.getMargins()}
                    borderRadius={this.props.borderRadius}
                    zIndex={this.getZIndex()}
                    hasBottomPadding={this.props.hasBottomPadding}
                >
                    <StyledCloseButton onClick={this.props.onClickClose}/>
                    {this.props.children}
                </StyledModalWrapper>,
            ),
            appRoot,
        );
    }

    public setWrapperRef = (node: any) => {
        this.wrapperRef = node;
    }

    public closeWhenClickOutside = (event: Event) => {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            if (DomHelper.hasSomeParentTheClass(event.target, 'modal')) {
                return;
            }
            this.props.onClickClose();
        }
    }

    private renderModal = (children: any) => {
        if (this.props.hasBackground) {
            return (
                <StyledBackground zIndex={this.getZIndex()} className={'modal'}>{children}</StyledBackground>
            );
        }
        return <Container zIndex={this.getZIndex()} className={'modal'}>{children}</Container>;
    }

    private getMargins = () => {
        if (this.props.isDrawer) {
            if (this.props.hasBackground) {
                return '150px 46px auto auto';
            } else {
                return '5px';
            }
        }
        return '150px auto';
    }

    private getZIndex = () => {
        return this.props.hasBackground ? 10000 : 900;
    }
}
