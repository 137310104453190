import React, {Fragment, PureComponent} from 'react';
import {Actions} from '@common';
import {intl} from '@global';

interface IProps {
    bulkAction: any;
    ids: Set<number>;
    totalCount: number;
    isLoading: boolean;
}

export class BulkActions extends PureComponent<IProps> {
    render() {
        return (
            <Actions.DropdownActions
                label={intl.get('bulk_actions') + this.props.ids.size + intl.get('out_of') + this.props.totalCount}
            >
                <Fragment>
                    {this.props.bulkAction}
                </Fragment>
            </Actions.DropdownActions>
        );
    }
}

export default BulkActions;
