import React, {PureComponent} from 'react';
import styled from 'styled-components';
import {Column} from 'react-virtualized';
import {constants, interfaces, stylingVariables, intl} from '@global';

import {defaultInfiniteTableCellDataGetter, Icon, Layout} from '@common';
import TransactionPeriod from '../../../TransactionPeriod';
import {LeaveInterfaces} from '@api';
import moment from 'moment';
import TransactionsGroup from '../../TransactionsGroup';

const StyledTransactionRow = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;

  span {
    font-weight: ${stylingVariables.fontWeight.regular};
  }
`;

const IconWrapper = styled.div<{isOpen: boolean}>`
  padding: 10px 7px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: ${stylingVariables.colorPalette.primaryHover};
  }

  svg {
    transition: all 0.5s 0.25s;
  }

  ${props => props.isOpen && `
    svg {
      transform: rotate(180deg);
    }
  `}

  ${props => !props.isOpen && `
    svg {
      transform: rotate(0deg);
    }
  `}
`;

const Subtitle = styled(Layout.Subtitle)`
    min-width: 200px;
    font-size: ${stylingVariables.fontSize.default};

    label {
        cursor: pointer;
        margin-left: 5px;
        color: ${stylingVariables.colorPalette.white};
        font-size: ${stylingVariables.fontSize.medium};
        background: ${stylingVariables.colorPalette.secondary};
        padding: 2px 6px;
        border-radius: 4px;
    }
`;

const TransactionsTitleWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
`;

interface CellProps {
    cellData: any;
    openLeaveTransactionModal: (modalState: LeaveInterfaces.Transaction[]) => void;
    toggleRowHeight: (index: number) => void;
    rowIndex: number;
    isOpen: boolean;
}

interface CellState {
    isOpen: boolean;
}

class Cell extends PureComponent<CellProps, CellState> {
    constructor(props: CellProps) {
        super(props);
        this.state = {
            isOpen: props.isOpen,
        };
    }

    componentDidUpdate(prevProps: Readonly<CellProps>, prevState: Readonly<CellState>) {
        if (prevProps.isOpen !== this.props.isOpen) {
            this.setState( { isOpen: this.props.isOpen });
        }
    }

    public render() {
        const { cellData } = this.props;

        return (
            <div style={{width: '100%'}}>
                <StyledTransactionRow key={cellData.id}>
                    {cellData.transactions.length === 1 && (
                        <TransactionPeriod transaction={cellData.transactions[0]} short={true} />
                    )}
                    {cellData.transactions.length > 1 && (
                        <>
                            <TransactionsTitleWrapper>
                                <Subtitle>
                                    {moment(cellData.transactions[0].startDate, 'YYYY-MM-DD').format('MMM DD, YYYY')
                                    } - { cellData.transactions[cellData.transactions.length - 1].endDate ?
                                    moment(cellData.transactions[cellData.transactions.length - 1].endDate,
                                        'YYYY-MM-DD')
                                        .format('MMM DD, YYYY') : '...'}
                                    <label>{cellData.transactions.length}
                                        {intl.get('trans')}
                                    </label>
                                </Subtitle>
                                <IconWrapper isOpen={this.state.isOpen} onClick={(e: any) => this.toggle(e)}>
                                    <Icon
                                        type={constants.IconTypes.ARROW_DOWN}
                                        width={10}
                                        height={6}
                                    />
                                </IconWrapper>
                            </TransactionsTitleWrapper>
                            {this.state.isOpen && <TransactionsGroup
                              showBubble={false}
                              transactions={cellData.transactions}
                              onOpen={this.open}
                            />}
                        </>
                    )}
                </StyledTransactionRow>
            </div>
        );
    }

    toggle = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState(prevState => ({
            isOpen: !prevState.isOpen,
        }));
        this.props.toggleRowHeight(this.props.rowIndex);
    }

    open = (e: any, transaction: LeaveInterfaces.Transaction) => {
        e.preventDefault();
        e.stopPropagation();

        this.props.openLeaveTransactionModal([transaction]);
    }
}

const StyledHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

interface IProps {
    orders?: interfaces.ListingOrders;
    changeOrder?: (column: string) => any;
    openLeaveTransactionModal: (modalState: LeaveInterfaces.Transaction[]) => void;
    toggleRowHeight: (index: number) => void;
    openRows: number[];
}

const Dates: React.FC<IProps> = ({
         openLeaveTransactionModal,
         toggleRowHeight,
         openRows,
     }) => (
    <Column
        dataKey={'dates'}
        width={350}
        flexGrow={1}
        cellDataGetter={defaultInfiniteTableCellDataGetter}
        headerRenderer={() => (
            <StyledHeaderWrapper>
                {intl.get('dates')}
            </StyledHeaderWrapper>
        )}
        cellRenderer={({cellData, rowIndex}) => <Cell
            cellData={cellData}
            openLeaveTransactionModal={openLeaveTransactionModal}
            toggleRowHeight={toggleRowHeight}
            rowIndex={rowIndex}
            isOpen={openRows.includes(rowIndex)}
        />}
    />
);

export default Dates;
