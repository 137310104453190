import React, {PureComponent} from 'react';
import styled from 'styled-components';

import {constants, intl, stylingVariables} from '@global';
import {Icon} from '@common';
import {EnvironmentHelper} from '@library';

const WarningWrapper = styled.div`
    display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-around;
	position: fixed;
	width: 100%;
	height: 50px;
	background: ${stylingVariables.colorPalette.yellow};
	bottom: 0;
	z-index: 1001;
	box-shadow: 0 -2px 8px rgba(0,0,0,.33);
	padding: 10px;
`;

const WarningMessage = styled.div`
    color: ${stylingVariables.colorPalette.white};
    span{
        font-weight: bold;
    }
`;

const CookieIconWrapper = styled.div`
    margin: 10px;
    width: 30px;
    height: 30px;
`;

const CloseIconWrapper = styled.div`
    cursor: pointer;
    margin: 10px;
    width: 30px;
    height: 30px;
    &:hover{
        opacity: 0.8;
    }
`;

interface IState {
    closed: boolean;
}

export default class EnvWarnings extends PureComponent<{}, IState> {

    public constructor(props: {}) {
        super(props);

        this.state = {
            closed: false,
        };

    }

    render() {
        if (this.state.closed || EnvironmentHelper.isCookieEnabled()) {
            return null;
        }

        return (
            <WarningWrapper>
                <CookieIconWrapper>
                    <Icon type={constants.IconTypes.COOKIE}
                          fillColor={stylingVariables.colorPalette.white}
                          width={30}
                          height={30}
                    />
                </CookieIconWrapper>
                <WarningMessage>
                    <span>{intl.get('warning')} </span>
                    {intl.get('cookie_disabled_message')}
                </WarningMessage>
                <CloseIconWrapper onClick={() => {this.setState({closed: true});}}>
                    <Icon type={constants.IconTypes.CLOSE}
                          fillColor={stylingVariables.colorPalette.white}
                          width={20}
                          height={20}
                    />
                </CloseIconWrapper>
            </WarningWrapper>
        );
    }
}
