import React, {PureComponent} from 'react';
import {intl} from '@global';
import {connect} from 'react-redux';

import {ApiObject} from '@api';
import {Modal as CommonModal} from '@common';
import {stylingVariables} from '@global';
import {RootState} from '../../../../../../rootReducer';
import * as selectors from '../../../../selectors';
import {closeDocumentModal, deleteDocument} from '../../../../redux';

import Actions from './Actions';

interface IProps {
    document?: ApiObject.FileHandle;

    closeDocumentModal: () => void;
    deleteDocument: (id: string) => any;
}

export class Modal extends PureComponent<IProps> {
    render() {
        return (
            <CommonModal.CenterModal width={'550px'} minWidth={'550px'} onClickClose={this.props.closeDocumentModal}>
                <CommonModal.StyledHeader color={stylingVariables.colorPalette.darkest}>
                    <h4>{intl.get('remove_document')}</h4>
                </CommonModal.StyledHeader>

                <CommonModal.OverflowableContent style={{padding: '20px 40px'}}>
                    {intl.get('remove_document_confirmation')}
                </CommonModal.OverflowableContent>

                <Actions onConfirm={this.onConfirm} onCancel={this.props.closeDocumentModal}/>
            </CommonModal.CenterModal>
        );
    }

    private onConfirm = async() => {
        if (!this.props.document) {
            return;
        }
        await this.props.deleteDocument(this.props.document.id);
        this.props.closeDocumentModal();
    }
}

const mapStateToProps = (state: RootState) => ({
    document: selectors.getDocument(state),
});

const mapDispatchToProps = {
    deleteDocument,
    closeDocumentModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
