import en from './en'
import et from './et'
import es from './es'
import pl from './pl'

export default {
  en,
  et,
  es,
  pl
}
