import {createSelector} from 'reselect';
import moment from 'moment';

import {RootState} from '../../../rootReducer';

export const getPeriodFilterFrom = createSelector(
    [(state: RootState, props: any) => state.leaves.filter.from],
    date => moment(date),
);
export const getPeriodFilterTill = createSelector(
    [(state: RootState, props: any) => state.leaves.filter.till],
    date => moment(date),
);

export const getLeaveTypes = (state:RootState, props: any) => state.leaves.types;
export const getFilters = (state:RootState, props: any) => state.leaves.filters;
export const getTags = (state:RootState, props: any) => state.leaves.tags;
export const getOrders = (state:RootState, props: any) => state.leaves.orders;
export const getEmployees = createSelector(
    [(state: RootState) => state.leaves.employees],
    employees => employees.map((e, index) => {
        e.index = index + 1;
        e.person = e.person || {};
        e.fullName = `${e.person.firstName} ${e.person.lastName}`;

        return e;
    }),
)

export const getFieldGroups = (state: RootState): any => state.leaves.fieldGroups;
export const getEmployeeGroups = (state: RootState): any => state.leaves.employeeGroups;
export const getPeriodFrom = createSelector(
    [(state:RootState, props: any) => state.employee.leave.from],
    period => moment(period),
);
export const getPeriodTill = createSelector(
    [(state:RootState, props: any) => state.employee.leave.till],
    period => moment(period),
);
