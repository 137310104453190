import React, {PureComponent} from 'react';
import {intl, stylingVariables} from '@global';
import {connect} from 'react-redux';
import {get} from 'lodash';
import styled from 'styled-components';

import {ApiObject, EmployeeInterfaces} from '@api';
import {Modal, EditableField} from '@common';

import {RootState} from '../../../../../rootReducer';
import {isLoading} from '../../../../selectors';

import {
    getFieldGroupsForTerminationDetails,
    getValuesForTerminationDetails,
    getSeparationErrors,
} from '../../../selectors';
import {
    getTerminationDetailsFieldGroups,
    getTerminationDetailsValues,
    clearSeparation,
    saveTerminationDetails,
    clearSeparationErros
} from '../../../redux';

interface TerminationDetailsProps {
    forEmployeeIds: Set<number>;
    onClickClose: () => void;
    isLoading: boolean;
    fieldGroups: any;
    values: any;
    errors: any;
    getTerminationDetailsFieldGroups: () => void;
    getTerminationDetailsValues: (ids: Set<number>) => void;
    saveTerminationDetails: (values: [], ids: Set<number>) => void;
    clearSeparation: () => void;
    clearSeparationErros: () => void;
    employee: EmployeeInterfaces.Employee;
}

interface TerminationDetailsState {
    loaded: boolean;
    updatedValues: [];
}
const StyledFieldsGroupHeader = styled.h4`
    color: ${stylingVariables.colorPalette.darkest};
    font-size: 14px;
    font-weight: ${stylingVariables.fontWeight.bold};
    padding: 0;
`;
const SpinnerIcon = styled.div`
  margin: 50px auto;
  width: 30px;
  height: 30px;
  box-sizing: border-box;

  border: solid 4px transparent;
  border-top-color: ${stylingVariables.colorPalette.green};
  border-left-color: ${stylingVariables.colorPalette.green};
  border-radius: 50%;

  -webkit-animation: nprogress-spinner 600ms linear infinite;
  animation: nprogress-spinner 600ms linear infinite;
`;

export class TerminationDetails extends PureComponent<TerminationDetailsProps, TerminationDetailsState> {
    public constructor(props: any) {
        super(props);

        this.state = {
            loaded: false,
            updatedValues: [],
        };

        this.getFormData();
    }

    private getFormData = async() => {
        this.props.clearSeparation();
        this.props.clearSeparationErros()
        await this.props.getTerminationDetailsFieldGroups();
        await this.props.getTerminationDetailsValues(this.props.forEmployeeIds);
        this.setState({loaded: true});
    }

    render() {
        return (
            <Modal.CenterModal onClickClose={this.props.onClickClose}>
                <Modal.StyledHeader>
                    <h4>{`${this.props.employee.referenceCode} ${this.props.employee.person.firstName} ${this.props.employee.person.lastName}`}</h4>
                </Modal.StyledHeader>

                <Modal.OverflowableContent padding={'20px 40px'}>
                    {this.props.fieldGroups &&
                    <StyledFieldsGroupHeader>{this.props.fieldGroups.name}</StyledFieldsGroupHeader>}
                    {(this.state.loaded && !this.props.isLoading) &&
                    this.props.fieldGroups.fields.map((field: ApiObject.Field) => {

                        return <EditableField key={field.label}
                                              label={field.label}
                                              code={field.code}
                                              type={(field.type === ApiObject.FieldType.text
                                                  && !!field.options)
                                                  ? ApiObject.FieldType.dropdown
                                                  : field.type}
                                              isRequired={field.required}
                                              errorMessage={get(this.props.errors, `${field.entity}.${field.code}`)}
                                              defaultValue={get(this.props.values, field.code, null)}
                                              options={field.options}
                                              onChange={(value: any) => {
                                                  this.updateField(field.entity, field.code, value);
                                              }}/>;
                    })
                    }
                    {(!this.state.loaded || this.props.isLoading) && <SpinnerIcon/>}
                </Modal.OverflowableContent>

                <Modal.StyledActionBar>
                    <Modal.StyledCancelButton onClick={this.close}>
                        {intl.get('cancel')}
                    </Modal.StyledCancelButton>

                    <Modal.StyledOkButton onClick={this.save}>
                        {intl.get('save')}
                    </Modal.StyledOkButton>
                </Modal.StyledActionBar>
            </Modal.CenterModal>
        );
    }

    private updateField = (entity: string | number, code: string, value: any) => {
        const updatedValues: any = this.state.updatedValues;
        if (!updatedValues[entity]) {
            updatedValues[entity] = [];
        }

        const valueIndex = updatedValues[entity].findIndex((item: any) => item.code === code);

        if (valueIndex === -1) {
            updatedValues[entity].push({value: value, code: code});
        } else {
            updatedValues[entity][valueIndex].value = value;
        }

        this.setState({updatedValues: updatedValues});
    }

    private close = () => {
        this.props.clearSeparation();
        this.props.onClickClose();
    }

    private save = () => {
        this.props.saveTerminationDetails(this.state.updatedValues, this.props.forEmployeeIds);
    }
}

const mapStateToProps = (state: RootState) => ({
    isLoading: isLoading(state),
    fieldGroups: getFieldGroupsForTerminationDetails(state),
    values: getValuesForTerminationDetails(state),
    errors: getSeparationErrors(state),
});

const mapDispatchToProps = {
    getTerminationDetailsFieldGroups,
    getTerminationDetailsValues,
    saveTerminationDetails,
    clearSeparation,
    clearSeparationErros
};

export default connect(mapStateToProps, mapDispatchToProps)(TerminationDetails);
