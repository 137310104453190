import React from 'react';
import { Column } from 'react-virtualized';
import { defaultInfiniteTableCellDataGetter, Icon } from '@common';
import styled from 'styled-components';
import { constants, stylingVariables } from '@global';
import { ApiObject, EmployeeInterfaces } from '@api';


const StyledActionWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    flex-grow: 1;
`;

export const StyledEditAction = styled.div`
    cursor: pointer;
    padding: 10px;
    border-radius: 5px;
    &:hover{
        background-color: ${stylingVariables.colorPalette.lightGreen};
    }
`;

interface IProps {
    employee: EmployeeInterfaces.Employee;
    group: ApiObject.FieldGroup;
    onAction: (entityId: string) => void
}

const EditColumn: React.FC<IProps> = ({ onAction }) => (
    <Column
        dataKey='actions'
        width={70}
        cellDataGetter={defaultInfiniteTableCellDataGetter}
        cellRenderer={({ cellData }) =>
            <StyledActionWrapper>
                <StyledEditAction onClick={() => onAction(cellData.entityId)}>
                    <Icon type={constants.IconTypes.PENCIL_EDIT}
                        fillColor={stylingVariables.colorPalette.green}
                        height={14}
                        width={14}
                    />
                </StyledEditAction>
            </StyledActionWrapper>
        }
    />
);

export default EditColumn;
