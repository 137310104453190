import { api } from '@global'

export default class ApiLegalEntity {
  public static async list() {
    const { data } = await api.get('/mss/legal-entities')

    return data ? data.data || [] : []
  }

  public static async consolidationGroups() {
    const { data } = await api.get('/mss/consolidation-groups')

    return data ? data.data.consolidationGroups || [] : []
  }

  public static async getLogo(id: number, fileId: string) {
    const response = await api.get(`/mss/legal-entity/${id}/file/${fileId}`, {
      responseType: 'arraybuffer',
    })

    if (!response?.data) {
      return ''
    }

    return 'data:image/png;base64,' + Buffer.from(response?.data, 'binary').toString('base64')
  }

  public static async getPeriods(
    legalEntityId: number,
    params?: { start_date?: string; end_date?: string; only_active?: boolean }
  ) {
    const { data } = await api.get(`/masterdata/public/legal-entity/${legalEntityId}/periods?start_date=2010-01-01`,
        params || {})

    return data ? (data.data ? data.data.periods : []) : []
  }
}
