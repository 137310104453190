import React, {memo} from 'react';
import {Column} from 'react-virtualized';
import styled from 'styled-components';
import {interfaces, stylingVariables, intl} from '@global';
import {EmployeeInterfaces} from '@api';
import {ChartersPreview, defaultInfiniteTableCellDataGetter,} from '@common';

import * as Header from '../Header';

const StyledEmployeeWrapper = styled.div`
  display: flex;
  cursor: default;
  width: 100%;
  align-items: center;
`;

const StyledContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 15px;
  width: 100%;
`;

const StyledNameWrapper = styled.span`
  cursor: default;
  padding: 5px 0;
  font-size: ${stylingVariables.fontSize.medium};
  font-weight: ${stylingVariables.fontWeight.regular};
`;

const StyledIdWrapper = styled.span`
  padding: 3px 0;
  font-size: ${stylingVariables.fontSize.mediumLarge};
  color: ${stylingVariables.colorPalette.green};
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

const Cell: React.FC<{ cellData: EmployeeInterfaces.Employee, }> = memo(({cellData}) => {
    const fullName = cellData.person ? `${cellData.person.firstName} ${cellData.person.lastName}` : '';
    const photoAccessToken = cellData.photoAccessToken;
    const legalEntityId = cellData.legalEntityId;

    return (
        <StyledEmployeeWrapper>
            <ChartersPreview text={fullName} photo={photoAccessToken} legalEntityId={legalEntityId}/>
            <StyledContentWrapper>
                <StyledIdWrapper>{cellData.referenceCode}</StyledIdWrapper>
                <StyledNameWrapper>
                    {fullName}
                </StyledNameWrapper>
            </StyledContentWrapper>
        </StyledEmployeeWrapper>
    );
});

interface IProps {
    width: number;
    query?: string;
    orders?: interfaces.ListingOrders;
    filters?: interfaces.ListingFilters;
    changeOrder?: (column: string) => any;
    changeFilter?: (params: interfaces.FilterParams) => any;
    getEmployees?: (
        query: string,
        limit: number,
        page: number,
    ) => any;
}

const Employee: React.FC<IProps> = ({
    width,
    filters,
    orders,
    changeOrder,
    changeFilter,
    getEmployees
}) => (
    <Column
        dataKey='employee'
        width={width}
        flexGrow={1}
        cellDataGetter={defaultInfiniteTableCellDataGetter}
        headerRenderer={() => (
            <Header.Employee
                label={intl.get('id_and_name')}

                filters={filters}
                orders={orders}

                changeOrder={changeOrder}
                changeFilter={changeFilter}
                getEmployees={getEmployees}
            />
        )}
        cellRenderer={({cellData}) => (
            <Cell cellData={cellData} />
        )}
    />
);

export default Employee;
