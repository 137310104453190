import React, {PureComponent} from 'react';
import styled from 'styled-components';

import {interfaces} from '@global';
import {EmployeeInterfaces, PayrollInterfaces} from '@api';
import {InfiniteTableHeader} from '@common';

import FilterContent from './FilterContent';

const StyledHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
`;

interface IProps {
    label: string;
    currentPeriod?: PayrollInterfaces.PayrollPeriod|null;

    orders?: interfaces.ListingOrders;
    filters?: interfaces.ListingFilters;
    tags?: EmployeeInterfaces.Tag[];

    changeOrder?: (column: string) => void;
    changeFilter?: (params: interfaces.FilterParams) => void;
    setTags?: (tags: EmployeeInterfaces.Tag[]) => void;

    getEmployees?: (
        query: string,
        tags: EmployeeInterfaces.Tag[],
        limit: number,
        page: number,
    ) => any;
}

export default class Employee extends PureComponent<IProps> {
    render() {
        return (
            <StyledHeaderWrapper>
                {this.props.label}

                <StyledActionsWrapper>
                    <InfiniteTableHeader.Filter
                        column={'id'}
                        filters={this.props.filters}
                        outsideFilters={this.props.tags || []}
                    >
                        <FilterContent
                            currentPeriod={this.props.currentPeriod}

                            filters={this.props.filters}
                            tags={this.props.tags || []}

                            changeFilter={this.props.changeFilter}
                            setTags={this.props.setTags}

                            getEmployees={this.props.getEmployees}
                        />
                    </InfiniteTableHeader.Filter>

                    <InfiniteTableHeader.Order column={'id'}
                           orders={this.props.orders}
                           changeOrder={this.props.changeOrder}
                    />
                </StyledActionsWrapper>
            </StyledHeaderWrapper>
        );
    }
}
