import {ApiObject} from '@api';
import {Modal as CommonModal, Switch} from '@common';
import {stylingVariables, interfaces, intl} from '@global'
import React, {PureComponent} from 'react';
import {connect} from 'react-redux';

import {clearStateForGenerateReport, changePeriods, changeTemplate} from '../../redux';
import SelectDataContent from './SelectDataContent/SelectDataContent';
import SaveReportContent from './SaveReportContent/SaveReportContent';
import {ToggleCheckbox} from 'hrb-common'

enum ModalStep {
    selectData = 'selectData',
    saveReport = 'saveReport',
}

interface IProps {
    template: ApiObject.ReportTemplate;
    onClose: () => void;
    clearStateForGenerateReport: (template: ApiObject.ReportTemplate) => any;

    changeTemplate: (template: ApiObject.ReportTemplate) => any;
    changePeriods: (periods: interfaces.Periods) => any;
}

interface IState {
    modalStep: ModalStep;

    template: ApiObject.ReportTemplate;
    periods: interfaces.Periods;
}

export class GenerateReportModal extends PureComponent<IProps, IState> {
    public constructor(props: IProps) {
        super(props);

        this.state = {
            modalStep : ModalStep.selectData,
            template: props.template,
            periods: []
        };
    }

    async componentDidMount() {
        this.props.clearStateForGenerateReport(this.props.template);
    }

    render() {
        return (
            <CommonModal.CenterModal width={'750px'} minWidth={'750px'} onClickClose={this.props.onClose}>
                <CommonModal.StyledHeader
                    style={{padding: '20px 80px'}}
                    color={stylingVariables.colorPalette.darkest}
                >
                    <h4>{this.props.template.label}</h4>
                    <ToggleCheckbox
                      isChecked={this.state.template.visibility === ApiObject.ReportVisibility.public}
                      labelOptions={{yes: intl.get('common'), no: intl.get('personal')}}
                      onChange={(bool: boolean) => {
                          this.changeTemplate({
                              ...this.state.template,
                              visibility: bool ? ApiObject.ReportVisibility.public : ApiObject.ReportVisibility.private
                          })
                        }
                      } />
                </CommonModal.StyledHeader>

                <Switch
                    current={this.state.modalStep}
                    options={new Map([
                        [
                            ModalStep.selectData,
                            <SelectDataContent
                                template={this.state.template}
                                periods={this.state.periods}

                                changeTemplate={this.changeTemplate}
                                changePeriods={this.changePeriods}

                                onClose={this.props.onClose}
                                onContinue={() => this.onSelectStep(ModalStep.saveReport)}
                            />,
                        ],
                        [
                            ModalStep.saveReport,
                            <SaveReportContent
                                onClose={this.props.onClose}
                                onBack={() => this.onSelectStep(ModalStep.selectData)}
                            />
                        ],
                    ])}
                />

            </CommonModal.CenterModal>
        );
    }

    changeTemplate = (template: ApiObject.ReportTemplate) => {
        this.setState({
            template
        })
        this.props.changeTemplate(template);
    }

    changePeriods = (periods: interfaces.Periods) => {
        this.setState({
            periods
        })

        this.props.changePeriods(periods as interfaces.Periods);
    }

    onSelectStep = (modalStep: ModalStep) => this.setState({modalStep});
}

const mapDispatchToProps = {
    clearStateForGenerateReport,
    changePeriods,
    changeTemplate,
};

export default connect(null, mapDispatchToProps)(GenerateReportModal);
