import React, {PureComponent} from 'react';
import {intl} from '@global';

import {stylingVariables} from '@global';
import {Layout, ToggleButton} from '@common';

interface IProps {
    onChange: (visibleInESS: boolean) => any;
    visible: boolean;
    errors?: string[];
}

export default class ESSVisibilityRow extends PureComponent<IProps> {
    public render() {
        return (
            <Layout.Row>
                <Layout.RowLabel percent={30} color={this.props.errors ? stylingVariables.colorPalette.red : undefined}>
                    {intl.get('visible_in_ess')}*
                </Layout.RowLabel>
                <Layout.RowValue percent={70}>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        width: '100%'
                    }}>
                        <ToggleButton
                            isChecked={this.props.visible}
                            onChange={visibleInESS => this.props.onChange(visibleInESS)}
                        />
                    </div>
                </Layout.RowValue>
            </Layout.Row>
        );
    }
}
