import {ApiObject} from '@api';
import {SimpleDropdown, Icon} from '@common';
import {constants, stylingVariables, interfaces} from '@global';
import React, {PureComponent} from 'react';
import {SortableHandle} from 'react-sortable-hoc';
import {FieldWrapper, SortIcon, FieldDropdown} from './Styles';
import { FieldGroupHelper } from '@library'

interface IProps {
    field: interfaces.IGroupFieldBuilderField;
    onUpdate: (field: interfaces.IGroupFieldBuilderField) => any;
    onRemove: () => any;

    list: ApiObject.SelectOption[];
    selectedFieldKeys: string[];

    hasError: boolean;
}

interface IState {
    isOpened: boolean;
    list: ApiObject.SelectOption[];
}

const DragFieldHandle = SortableHandle((props: any) => {
    return <SortIcon isOpened={props.isOpened}>
        <Icon type={constants.IconTypes.DRAG_VERTICAL} height={16} fillColor={stylingVariables.colorPalette.green}/>
    </SortIcon>
});

export default class Field extends PureComponent<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            isOpened: false,
            list: Field.formattedList(this.props.list)
        };
    }

    static getDerivedStateFromProps(nextProps: any, prevState: any) {
        if (nextProps && prevState.list !== nextProps.list) {
            return {
                list: Field.formattedList(nextProps.list)
            }
        }
        return null;
    }

    static columnKey(key?: string) {
        return key
          ? FieldGroupHelper.getFieldKeyFromMasterdataFormula(key)
          : ''
    }

    static formattedList(list: ApiObject.SelectOption[]): ApiObject.SelectOption[] {
        return list.map((i => {
            const key: string = Field.columnKey(i.key)
            return {
                key,
                label: i.label,
            }
        }))
    }

    change = (key: string) => {
        this.props.onUpdate({
            ...this.props.field,
            key,
            label: (this.state.list.find(i => i.key === Field.columnKey(key)) as ApiObject.SelectOption).label
        })
    }

    render() {
        return <FieldWrapper hasError={this.props.hasError}>
            <DragFieldHandle isOpened={this.state.isOpened}/>
            <FieldDropdown>
                <SimpleDropdown
                    ignoreKeys={this.props.selectedFieldKeys}
                    selectedValue={Field.columnKey(this.props.field.key)}
                    list={this.state.list}
                    onChange={this.change}
                    placeholder={'select'}
                    onOpenContent={(value) => this.setState({
                        isOpened: value
                    })}
                />
            </FieldDropdown>
        </FieldWrapper>;
    }
}
