import React, {PureComponent, Fragment} from 'react';
import {connect} from 'react-redux';
import {ScrollEventData} from 'react-virtualized';
import styled from 'styled-components';

import Actions from './Actions';
import Table from './Table/Table';
import StatusBar from './StatusBar';

import {
    getFieldGroups,
    getColumnSettings,
    getEmployeeGroups,
    clearOldAndGetEmployees,
    closeColumnSettingsModal,
    saveColumnSettings,
    getEmployeeCounts,
} from './redux';
import {RootState} from '../../rootReducer';
import {
    getSelectedColumns,
    getUnselectedColumns,
    isColumnsSettingsModalOpen,
} from './columnSettingsSelectors';
import ColumnSettings from '../common/Employee/ColumnSettings/ColumnSettings';
import {interfaces, intl, stylingVariables} from '@global';
import {initializeBasicData} from "../Reports/redux";

const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 20px 20px;

  h4 {
    font-size: ${stylingVariables.fontSize.big};
    font-weight: ${stylingVariables.fontWeight.bold};
  }
`;

const HeadingContainer = styled.div<{isHidden: boolean}>`
  margin-top: 0;
  transition: all .4s;
  ${props => props.isHidden && `
    transition: all .4s;
    margin-top: -140px;
  `}
`;

interface EmployeesProps {
    totalCount: number;

    getFieldGroups: () => void;
    getColumnSettings: () => void;
    getEmployeeGroups: () => void;
    clearOldAndGetEmployees: () => void;
    getEmployeeCounts: () => void;
    initializeBasicData: () => void;

    isModalOpen: boolean;
    selectedColumns: interfaces.EmployeeColumn[];
    unselectedColumns: interfaces.EmployeeColumn[];

    saveColumnSettings: (columns: string[]) => void;
    closeColumnSettingsModal: () => void;
}

interface IState {
    fullSizeTable: boolean;
    tableScrollTop: number;
}

export class Employees extends PureComponent<EmployeesProps, IState> {
    public DEFAULT_HEADING_HEIGHT = 120;
    public constructor(props: any) {
        super(props);

        this.props.getEmployeeGroups();
        this.props.getFieldGroups();
        this.props.getColumnSettings();
        this.props.clearOldAndGetEmployees();
        this.props.getEmployeeCounts();
        this.props.initializeBasicData();

        this.state = {
            tableScrollTop: 0,
            fullSizeTable: false,
        }
    }

    public onScrollTable = (e: ScrollEventData) => {
        const scrollTop = e.scrollTop;
        // Dont need trigger if value already bigger then max heading height
        if (scrollTop > this.DEFAULT_HEADING_HEIGHT && this.state.tableScrollTop > this.DEFAULT_HEADING_HEIGHT) {
            return;
        }

        this.setState({
            tableScrollTop: scrollTop,
            fullSizeTable: scrollTop > this.DEFAULT_HEADING_HEIGHT,
        });
    }

    public render() {
        return (
            <Fragment>
                <HeadingContainer isHidden={this.state.fullSizeTable}>
                    <StyledHeader>
                        <h4>
                            {intl.get('employees')}
                        </h4>

                        <Actions />
                    </StyledHeader>

                    <StatusBar />
                </HeadingContainer>

                <Table reservedHeight={this.state.fullSizeTable ? 0 : this.DEFAULT_HEADING_HEIGHT} onScrollTable={() => {}} />

                <ColumnSettings isModalOpen={this.props.isModalOpen}
                                selectedColumns={this.props.selectedColumns}
                                unselectedColumns={this.props.unselectedColumns}

                                saveColumnSettings={this.props.saveColumnSettings}
                                closeColumnSettingsModal={this.props.closeColumnSettingsModal}/>
            </Fragment>
        );
    }
}

const mapStateToProps = (state: RootState, ownProps: any) => ({

    isModalOpen: isColumnsSettingsModalOpen(state),
    selectedColumns: getSelectedColumns(state),
    unselectedColumns: getUnselectedColumns(state),
});

const mapDispatchToProps = {
    getFieldGroups,
    getColumnSettings,
    getEmployeeGroups,
    clearOldAndGetEmployees,
    getEmployeeCounts,

    saveColumnSettings,
    closeColumnSettingsModal,

    initializeBasicData
};

export default connect(mapStateToProps, mapDispatchToProps)(Employees);
