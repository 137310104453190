import React, {PureComponent} from 'react';
import {intl} from '@global';

import {Modal as CommonModal} from '@common';

interface ActionsProps {
    onConfirm: () => any;
    onCancel: () => any;
}

export default class Actions extends PureComponent<ActionsProps> {
    public render() {
        return (
            <CommonModal.StyledActionBar style={{paddingTop: 20}}>
                <CommonModal.StyledCancelButton onClick={this.props.onCancel}>
                    {intl.get('cancel')}
                </CommonModal.StyledCancelButton>

                <CommonModal.StyledOkButton onClick={this.props.onConfirm}>
                    {intl.get('remove')}
                </CommonModal.StyledOkButton>
            </CommonModal.StyledActionBar>
        );
    }
}
