import React, {PureComponent} from 'react';
import styled from 'styled-components';

import {BlankButton, Icon} from '@common';
import {ApiObject} from '@api';
import {constants, interfaces, stylingVariables} from '@global';

interface OrderProps {
    column?: string;
    orders?: interfaces.ListingOrders;
    changeOrder?: (column: string) => any;
}

export const StyledOrderButton = styled(props => <BlankButton {...props} />)`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-left: 10px;
`;

export default class Order extends PureComponent<OrderProps> {
    private changeOrder = () => {
        if (this.props.changeOrder && this.props.column) {
            this.props.changeOrder(this.props.column);
        }
    }

    private get orderItem(): interfaces.OrderParams|undefined {
        if (!this.props.column || !this.props.orders) {
            return undefined;
        }

        return this.props.orders[this.props.column];
    }

    private get isAsc(): boolean {
        return !!(this.orderItem && this.orderItem.direction === ApiObject.OrderByOperator.asc);
    }

    private get isDesc(): boolean {
        return !!(this.orderItem && this.orderItem.direction === ApiObject.OrderByOperator.desc);
    }

    render() {
        return (
            <StyledOrderButton onClick={this.changeOrder}>
                <Icon type={constants.IconTypes.ARROW_UP}
                      width={8}
                      height={8}
                      fillColor={this.isDesc
                          ? stylingVariables.colorPalette.green
                          : stylingVariables.colorPalette.deepGray
                      }
                />
                <Icon type={constants.IconTypes.ARROW_DOWN}
                      width={8}
                      height={8}
                      fillColor={this.isAsc
                          ? stylingVariables.colorPalette.green
                          : stylingVariables.colorPalette.deepGray
                      }
                />
            </StyledOrderButton>
        );
    }
}
