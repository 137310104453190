import {ReportHelper, ReportTemplatesHelper} from '@library';
import React, {PureComponent} from 'react';
import {Column} from "react-virtualized";
import {intl} from '@global';
import {Actions as CommonActions, defaultInfiniteTableCellDataGetter} from "@common";
import {ApiObject} from '@api';
import ReportModal from '../../../Process/common/ReportModal/ReportModal';
import Delete from './Delete'

interface IProps {
    onClickExport: (report: ApiObject.Report) => any;
    templates: ApiObject.ReportTemplate[];
}

interface ICellProps {
    onClickExport: (report: ApiObject.Report) => void;
    report: ApiObject.Report;
    templates: ApiObject.ReportTemplate[];
}

interface ICellState {
    deleteReport: boolean;
    updateReportModal: boolean;
}

class Cell extends PureComponent<ICellProps, ICellState> {
    public constructor(props: ICellProps) {
        super(props);

        this.state = {
            deleteReport: false,
            updateReportModal: false
        };
    }

    private toggleModal(modalName: keyof ICellState) {
        this.setState({...this.state, [modalName]: !this.state[modalName]})
    }

    public render() {
        return (
            <CommonActions.MenuActions>
                <CommonActions.Action onClick={this.onExport}>
                    {intl.get('export')}
                </CommonActions.Action>

                {this.renderUpdateReportAction()}
                {this.renderDeleteReportAction()}

                {this.state.deleteReport ? this.renderDeleteModal() : null}
                {this.state.updateReportModal && this.renderUpdateReportModal()}
            </CommonActions.MenuActions>
        );
    }

    renderUpdateReportAction = () => {
        if (!this.props.report.templateId) {
            return null;
        }

        if (!ReportHelper.isCurrentUserOwnerOnReport(this.props.report)) {
            return null;
        }

        if (ReportTemplatesHelper.isTemplateTemporary(this.props.templates, this.props.report.templateId)) {
            return null;
        }

        return <CommonActions.Action onClick={() => this.toggleModal('updateReportModal')}>
            {intl.get('update_report')}
        </CommonActions.Action>;
    }

    renderDeleteReportAction = () => {
        if (!this.props.report.templateId) {
            return null;
        }

        if (!ReportHelper.isCurrentUserOwnerOnReport(this.props.report)) {
            return null;
        }

        return <CommonActions.Action onClick={() => this.toggleModal('deleteReport')}>
            {intl.get('delete')}
        </CommonActions.Action>;
    }

    private onExport = () => {
        this.props.onClickExport(this.props.report);
    }

    private renderDeleteModal() {
        return (
            <Delete
                isOpen={this.state.deleteReport}
                reportId={this.props.report.id}
                onClose={() => this.toggleModal('deleteReport')}
            />
        );
    }

    private renderUpdateReportModal() {
        return (
            <ReportModal report={this.props.report} onClose={() => this.toggleModal('updateReportModal')}/>
        );
    }
}

const Actions = ({onClickExport, templates}: IProps) => (
    <Column
        dataKey="actions"
        width={80}
        cellDataGetter={defaultInfiniteTableCellDataGetter}
        cellRenderer={({cellData}) => <Cell report={cellData} onClickExport={onClickExport} templates={templates} />}
    />
);

export default Actions;
