import React, { PureComponent } from 'react'
import * as Cookies from 'js-cookie'
import { EnvironmentHelper } from '@library'
import moment from 'moment'
import styled from 'styled-components'
import { stylingVariablesGlobal as stylingVariables, Icon } from 'hrb-common'
import { constants } from '@global'
import { connect } from 'react-redux'
import { ApiObject } from '@api'
import intl from 'react-intl-universal'
import { Language } from 'api/api.object'
interface State {
  locale: string
  isOpen: boolean
}

interface Props {
  onSelect: () => void
  userLegalEntity: ApiObject.LegalEntity
}

const LocaleChangerWrapper = styled.div`
  align-items: flex-end;
  display: flex;
  flex: 1;
  position: relative;
  z-index: 4;
  height: 66px;
`

const LocaleChangerSelector = styled.button`
  padding: 0 30px;
  display: inline-block;
  border: none;
  background: transparent;
  width: 100%;
  height: 100%;
  position: relative;
  min-width: fit-content;
  cursor: pointer;

  &:hover {
    @media (min-width: 768px) {
      background-color: ${stylingVariables.colorPalette.lightGreen};
    }
    color: ${stylingVariables.colorPalette.green};

    svg {
      fill: ${stylingVariables.colorPalette.green};
    }
  }
`

const LocaleChangerSelectedWrapper = styled.span`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const LocaleChangerSelectedText = styled.span<{ isOpen: boolean }>`
  display: flex;
  align-items: center;
  font-weight: ${stylingVariables.fontWeight.semibold};
  text-transform: uppercase;

  svg {
    margin-right: 10px;
  }

  ${(props: any): any =>
    props.isOpen &&
    `
    color: ${stylingVariables.colorPalette.green};
    svg {
      fill: ${stylingVariables.colorPalette.green};
    }
  `}
`

const LocaleChangerDropdown = styled.div`
  background-color: ${stylingVariables.colorPalette.lightGray};
  padding: 0;
  display: inline-block;
  width: 100%;
  position: absolute;
  z-index: 3;
  left: 0;
  top: 62px;
  box-shadow: 0px 16px 16px #00000029;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;

  @media (min-width: 768px) {
    background-color: ${stylingVariables.colorPalette.white};
  }
`

const LocaleChangerDropdownContent = styled.div<{ isActive: boolean }>`
  min-width: fit-content;
  cursor: pointer;
  line-height: 18px;
  padding: 9px 0 9px 40px;
  background-color: ${stylingVariables.colorPalette.lightGray};
  color: ${stylingVariables.colorPalette.deepGray};
  font-size: 14px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  ${(props: any): any =>
    props.isActive
      ? `
      color: ${stylingVariables.colorPalette.green};
  `
      : ''}

  @media (min-width: 768px) {
    background-color: ${stylingVariables.colorPalette.white};
  }

  &:hover {
    color: ${stylingVariables.colorPalette.green};
  }

  &:first-of-type {
    padding-top: 10px;
  }

  &:last-of-type {
    padding-bottom: 30px;
  }
`

export class LocaleChanger extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      locale: 'en',
      isOpen: false,
    }
  }

  private cookieExpires = 7

  private availableLocales = () => {
    let availableLocales = intl.getInitOptions().locales || {}
    availableLocales = Object.keys(availableLocales).map((locale) => {
      return locale
    })

    return availableLocales
  }

  public componentDidMount(): void {
    const locale = Cookies.get('locale')

    if (locale) {
      this.setCurrentLocale(locale)
    }
  }

  private capitalize = (string: string): string => {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }

  private localeList: Language[] = this.props.userLegalEntity?.defaultLanguage
    ? [this.props.userLegalEntity.defaultLanguage, ...(this.props.userLegalEntity.enabledLanguages || [])]
    : [...(this.props.userLegalEntity?.enabledLanguages || [])]

  private uniqueLocales: Language[] = Object.values(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.localeList.reduce((c: Record<string, any>, e) => {
      if (!c[e.code]) {
        c[e.code] = e
      }
      return c
    }, {})
  )

  private languages = this.uniqueLocales.filter((language: Language) => {
    return this.availableLocales().includes(language.code)
  })

  private setCurrentLocale = (locale: string) => {
    this.setState({ locale: locale })
  }

  private onChangeLanguage = (selectedLocale: string) => {
    this.setCurrentLocale(selectedLocale)
    Cookies.set('locale', selectedLocale, {
      expires: this.cookieExpires,
      domain: EnvironmentHelper.cookieDomainUrl() || window.location.hostname,
    })

    moment.locale(selectedLocale)
    this.props.onSelect()
    this.toggle()
    window.location.reload()
  }

  public toggle = () => {
    this.setState({ isOpen: !this.state.isOpen })
  }

  render(): JSX.Element | null {
    return this.languages && Object.keys(this.languages).length > 1 ? (
      <LocaleChangerWrapper>
        <LocaleChangerSelector onClick={this.toggle}>
          <LocaleChangerSelectedWrapper>
            <LocaleChangerSelectedText isOpen={this.state.isOpen}>
              <Icon type={constants.hrb.IconTypes.LANGUAGE} width={20} height={20} />
              {this.state.locale}
            </LocaleChangerSelectedText>
            <Icon type={constants.hrb.IconTypes.ARROW_DOWN} width={10} height={8} />
          </LocaleChangerSelectedWrapper>
        </LocaleChangerSelector>
        {this.state.isOpen && (
          <LocaleChangerDropdown>
            {this.languages.map((locale, index) => (
              <LocaleChangerDropdownContent
                key={index}
                onClick={() => this.onChangeLanguage(locale.code)}
                isActive={locale.code === this.state.locale}
              >
                {this.capitalize(locale.localName)}
              </LocaleChangerDropdownContent>
            ))}
          </LocaleChangerDropdown>
        )}
      </LocaleChangerWrapper>
    ) : null
  }
}

export default connect(null, null)(LocaleChanger)
