import React, {PureComponent} from 'react';
import styled from 'styled-components';
import { ToastContainer } from 'react-toastify';

import { stylingVariables } from '@global';

import 'react-toastify/dist/ReactToastify.css';

const StyledToastContainer = styled(ToastContainer)`
  z-index: 1000000!important;

  .Toastify__toast {
    line-height: 1.1rem;
    border-radius: 8px;
    min-height: 96px;
    width: 480px;
    display: inline-flex;
    position: relative;
    flex-direction: column;
    padding: 0;
    color: ${stylingVariables.colorPalette.darkest}
  }

  .Toastify__toast--success {
    background: #d6f5df;
    border: 1px solid #1f793a;

    &:after {
      content: '';
      width: 100%;
      background-color: #1f793a;
      height: 8px;
    }
  }

  .Toastify__toast--error {
    background-color: #ffe4e2;
    border: 1px solid #cc1000;

    &:after {
      content: '';
      width: 100%;
      background-color: #cc1000;
      height: 8px;
    }
  }

  .Toastify__toast--info {
    color: ${stylingVariables.colorPalette.white};
  }

  .Toastify__toast-body {
    font-weight: ${stylingVariables.fontWeight.medium};
    padding: 8px 50px 0 16px;
    display: flex;
    align-items: center;
    position: relative;
    line-height: 1.1rem;
    font-size: 14px;
    height: calc(100% - 8px);
  }

  .Toastify__close-button {
    position: absolute;
    right: 22px;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background-color: ${stylingVariables.colorPalette.darkest};
    font-weight: ${stylingVariables.fontWeight.light};
    opacity: 1;
    font-size: 10px;
    top: calc(50% - 8px);

    &:hover {
      transform: scale(1.2);
    }
  }

  .Toastify__progress-bar {
    opacity: 0.3 !important;
    height: 7px;
  }
`;

export default class Toast extends PureComponent {
    render() {
        return <StyledToastContainer autoClose={20000}/>;
    }
}
