import {UrlHelper} from '@library';
import React, {PureComponent, Fragment} from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';

import {SimpleStatusBar, FoldingSearchInput, InlineYearSelector} from '@common';
import {intl} from '@global';
import {RootState} from '../../../rootReducer';
import {ReportVisibility, URL_VISIBILITY} from '../constants';
import {changeFilterVisibility, changeFilterSearch, changeFilterYear} from './redux';
import ClearFiltersAndSorts from './ClearFiltersAndSorts';

import * as selectors from './selectors';

const StyledStatusBar = styled.div`
	position: relative;
	> div {
		display: flex;
		justify-content: space-between;
		overflow: visible;
	} 
   	button {
   		text-transform: capitalize;
   	}
`;
const StatusBarOptions = styled.div`
	position: absolute;
	right: 10px;
	top: 10px;
	
	display: flex;
	align-items: center;
	
	>div:not(:last-child) {
		margin-right: 20px;
	}
`;

interface IProps {
	filterVisibility: ReportVisibility;
	filterSearch: string;
	filterYear: number;

	changeFilterVisibility: (visibility: ReportVisibility) => void;
	changeFilterSearch: (search: string) => void;
	changeFilterYear: (year: number) => void;
}

export class Navigation extends PureComponent<IProps> {
	private onSelectFilterVisibility = async (visibility: ReportVisibility) => {
		UrlHelper.setQueryParam(URL_VISIBILITY, visibility);
		await this.props.changeFilterVisibility(visibility);
	}

	componentDidMount() {
		const urlVisibility: ReportVisibility|null = UrlHelper.getSearchQueryParam(URL_VISIBILITY);
		if (urlVisibility && urlVisibility in ReportVisibility) {
			this.onSelectFilterVisibility(urlVisibility);
		} else {
			this.onSelectFilterVisibility(ReportVisibility.private);
		}
	}

	get selectedTab(){
		return this.props.filterVisibility
	}

	get statusBarItems() {
		return [
			{key: ReportVisibility.private, label: intl.get('personal')},
			{key: ReportVisibility.public, label: intl.get('common')},
		]
	}

	public render() {
		return (
			<Fragment>
				<StyledStatusBar>
					<SimpleStatusBar
						items={this.statusBarItems}
						status={this.selectedTab as string}
						onChange={(visibility: string) => this.onSelectFilterVisibility(visibility as ReportVisibility)}
					/>

					<StatusBarOptions>
						<ClearFiltersAndSorts />

						<FoldingSearchInput
							onChange={(value: any) => this.props.changeFilterSearch(value || '')}
							placeholder={intl.get('search')}
							value={this.props.filterSearch}
							autoFocus={true}
						/>

						<InlineYearSelector
							year={this.props.filterYear}
							onChange={this.props.changeFilterYear}
						/>
					</StatusBarOptions>
				</StyledStatusBar>
			</Fragment>
		);
	}
}

const mapStateToProps = (state: RootState) => ({
	filterVisibility: selectors.getFilterVisibility(state),
	filterSearch: selectors.getFilterSearch(state),
	filterYear: selectors.getFilterYear(state)
});

const mapDispatchToProps = {
	changeFilterVisibility,
	changeFilterSearch,
	changeFilterYear
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(Navigation);
