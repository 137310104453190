export default class ApiError extends Error {
    constructor(type: string, message: string, additionalInfo?: string) {
        super(message);
        this.name = type;
        this.stack = additionalInfo;
    }

    public static unexpectedStatusError(status: string, additionalInfo?: string) {
        throw new ApiError('UnexpectedStatusError', 'Unexpected response status: ' + status, additionalInfo);
    }

    public static unexpectedError() {
        throw new ApiError('UnexpectedError', 'Unexpected error without response');
    }
}
