import React, { PureComponent } from 'react'
import { IntlProvider, addLocaleData } from 'react-intl'
import { connect } from 'react-redux'
import styled, { createGlobalStyle } from 'styled-components'
import { Location, UnregisterCallback } from 'history'

import { history, stylingVariables, constants, intl, api } from '@global'
import { ApiObject } from '@api'
import locales from '@locales'
import { UrlHelper, RouteHelper, EnvironmentHelper } from '@library'

import * as selectors from './selectors'
import {
  authorizeAndLoadGlobalProps,
  authorizeAndLoadEntities,
  goToOldMssRoute,
  stopLoading,
  setLocale,
  getEmployeeGroups
} from './redux'
import { RootState } from '../rootReducer'

import * as Cookies from 'js-cookie';
import moment from 'moment'

import Header from './Layout/Header'
import Menu from './Layout/Navigation/Menu'
import Routes from './Routes'
import Toast from './Toast'
import ErrorModal from './ErrorModal'
import Loader from './Loader'
import EnvWarnings from './EnvWarnings'
import { Back } from '@common'
import Welcome from './Welcome'

import * as en from 'react-intl/locale-data/en'
import * as et from 'react-intl/locale-data/et'
import * as es from 'react-intl/locale-data/es'
import * as pl from 'react-intl/locale-data/pl'

addLocaleData([...et, ...en, ...es, ...pl])

const StyledGlobal = createGlobalStyle`
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
  }

  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }

  ol, ul, li {
    list-style: none;
  }

  blockquote, q {
    quotes: none;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  html {
    box-sizing: border-box;
    background-color: ${stylingVariables.colorPalette.gray};
  }

  body {
    height: 100%;
    line-height: 1;
    &, * {
      font-family: 'Source Sans Pro', sans-serif
    };
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }

  input, button {
     outline: none;
     -webkit-appearance: none;
     border-color: transparent;
  }

  h4 {
    font-size: 17px;
    font-weight: 600;
    color: ${stylingVariables.colorPalette.dark};
    padding: 5px;
  }

  #root {
    height: 100%;
    overflow: auto;
    position:relative;
  }

  .hover-opacity {
    opacity: .8;
    &:hover {
      opacity: 1;
    }
  }

  .big-z-index {
    z-index: 1000;
  }

  .Toastify__toast-container {
    width: 500px;
  }
`

export const StyledRouterWrapper = styled.div<{ isEntities: boolean; isMenuCollapsed: boolean }>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: ${(props) => (props.isEntities ? 'auto' : `calc(100vh - ${stylingVariables.layout.height})`)};
  margin-left: ${(props) =>
    !props.isEntities
      ? props.isMenuCollapsed
        ? stylingVariables.layout.menuCollapsedWidth
        : stylingVariables.layout.menuWidth
      : '0'};
  padding: 20px;
  overflow-x: auto;
`

const BackToLaunchpad = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: right;
  color: ${stylingVariables.colorPalette.green};
  font-size: ${stylingVariables.fontSize.mediumLarge};
  font-weight: ${stylingVariables.fontWeight.bold};
  text-decoration: none;
  margin-top: auto;
`

const BackToLaunchpadTitle = styled.span`
  padding: 5px;
`

interface EState {
  hasError: boolean
}
interface EProps {}

export class ErrorBoundary extends PureComponent<EProps, EState> {
  constructor(props: any) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  async componentDidCatch(error: Error) {
    await api.sendLog(
      error,
      window.location.href,
      undefined,
      'mss-fe',
      error,
    )
  }

  render() {
    if (this.state.hasError) {
      return (
        <>
          <h2 style={{ textAlign: 'center', padding: '70px' }}>{intl.get('oops_sth_went_wrong')}</h2>
          <BackToLaunchpad href={EnvironmentHelper.getBackToLaunchpadUrl()}>
            <Back.Button />
            <BackToLaunchpadTitle>{intl.get('back_to_launchpad')}</BackToLaunchpadTitle>
          </BackToLaunchpad>
        </>
      )
    }

    return this.props.children
  }
}

interface IProps {
  isLoading: boolean
  currentLegalEntityId: number | null
  isCollapsedMenu: boolean
  locale: string

  authorizedUser: ApiObject.AuthorizedUser | null
  currentLegalEntity: ApiObject.LegalEntity | null
  userLegalEntity: ApiObject.LegalEntity | null

  authorizeAndLoadEntities: (token: string | null) => void
  authorizeAndLoadGlobalProps: (token: string | null, currentLegalEntity: number | null) => void
  getEmployeeGroups: () => void

  goToOldMssRoute: (path: string) => void
  stopLoading: () => void
  setLocale: (locale: string) => void
}

interface IState {
  isLoadedGlobalProps: boolean
  locationPathName: string
}

const LAUNCHPAD_PATH = 'launchpad'

export class App extends PureComponent<IProps, IState> {
  private unlisten: UnregisterCallback

  public constructor(props: IProps) {
    super(props)

    this.state = {
      isLoadedGlobalProps: false,
      locationPathName: window.location.pathname,
    }

    this.props.stopLoading()

    if (document.referrer.indexOf(LAUNCHPAD_PATH) > -1) {
      EnvironmentHelper.setLaunchpadUrlCookie()
    }

    this.unlisten = history.listen((location: Location) => {
      this.setState({ locationPathName: location.pathname })
    })

    this.initialize()
  }

  private initialize = async () => {
    const currentLegalEntityId = UrlHelper.getLegalEntityId() || this.props.currentLegalEntityId
    const accessToken = UrlHelper.getAccessToken()
    if (UrlHelper.getSupportNewMSS()) {
      EnvironmentHelper.setSupportNewMSS(true)
    }

    if (history.location.pathname === constants.ROUTE_ENTITIES) {
      await this.props.authorizeAndLoadEntities(accessToken)
    } else {
      await this.props.authorizeAndLoadGlobalProps(UrlHelper.getAccessToken(), currentLegalEntityId)
    }

    this.props.getEmployeeGroups()
    if (this.state.locationPathName === '/') {
      RouteHelper.goToEmployeesPage()
    }

    this.setState({ isLoadedGlobalProps: true })

    const languageList: string[] = []
    if (this.props.currentLegalEntity?.enabledLanguages) {
      Object.values(this.props.currentLegalEntity?.enabledLanguages).forEach(language => {
        languageList.push(language.code)
      })

      const defaultCode = this.props.currentLegalEntity?.defaultLanguage?.code || 'en'

      if (!languageList.includes(defaultCode)) {
        languageList.push(defaultCode)
      }
    }

    const originalCookie = Cookies.get('locale')
    let localeCookie = Cookies.get('locale')
    let localeNotInEN

    const defaultLanguage =  this.props.currentLegalEntity?.defaultLanguage?.code || 'en'
    const cookieNotInList = localeCookie ? !languageList.includes(localeCookie) : true

    if (!localeCookie || cookieNotInList) {
      localeCookie = defaultLanguage
      localeNotInEN = defaultLanguage !== 'en'
    }

    const needsReload = (localeCookie !== originalCookie) || localeNotInEN

    Cookies.set('locale', localeCookie, {
      expires: 7,
      domain: EnvironmentHelper.cookieDomainUrl() || window.location.hostname,
    })

    moment.locale(defaultLanguage)

    if (needsReload) {
      window.location.reload()
    }
  }

  public render() {
    if (!this.state.isLoadedGlobalProps) {
      return (
        <>
          <Welcome />
        </>
      )
    }
    return (
      <ErrorBoundary>
        <StyledGlobal />
        <EnvWarnings />
        <Loader isLoading={this.props.isLoading} />

        {this.state.isLoadedGlobalProps && this.props.authorizedUser && (
          <IntlProvider locale={this.props.locale} messages={(locales as any)[this.props.locale]}>
            <>
              <Header
                authorizedUser={this.props.authorizedUser}
                legalEntity={this.props.userLegalEntity}
                currentLegalEntity={this.props.currentLegalEntity}
              />

              {this.state.locationPathName !== constants.ROUTE_ENTITIES && (
                <Menu currentLegalEntity={this.props.currentLegalEntity} />
              )}

              <StyledRouterWrapper
                className={'main-router-wrapper'}
                isEntities={this.state.locationPathName === constants.ROUTE_ENTITIES}
                isMenuCollapsed={this.props.isCollapsedMenu}
                id={'route-root'}
              >
                <Routes currentLegalEntity={this.props.currentLegalEntity} />
              </StyledRouterWrapper>

              <ErrorModal />

              <Toast />
            </>
          </IntlProvider>
        )}
      </ErrorBoundary>
    )
  }

  public componentWillUnmount() {
    this.unlisten()
  }
}

const mapStateToProps = (state: RootState, ownProps: any) => ({
  isCollapsedMenu: selectors.isMenuCollapsed(state),
  isLoading: selectors.isLoading(state),
  locale: selectors.getLocale(state),
  authorizedUser: selectors.getAuthorizedUser(state),
  currentLegalEntity: selectors.getCurrentLegalEntity(state, ownProps),
  currentLegalEntityId: selectors.getCurrentLegalEntityId(state),
  userLegalEntity: selectors.getUserLegalEntity(state),
})

const mapDispatchToProps = {
  authorizeAndLoadGlobalProps,
  authorizeAndLoadEntities,
  goToOldMssRoute,
  stopLoading,
  getEmployeeGroups,
  setLocale,
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
