import React, {PureComponent} from 'react';
import {intl} from '@global';
import {connect} from 'react-redux';
import styled from 'styled-components';

import {interfaces, stylingVariables} from '@global';
import {RootState} from '../../rootReducer';
import {getLeaveTransactions, reloadLeaves, resetOrdersAndFilters} from './redux';
import * as selectors from './selectors';
import {EmployeeInterfaces} from '@api';

const StyledResetFilters = styled.span`
  position: relative;
  display: flex;
  align-items: center;
  font-size: ${stylingVariables.fontSize.medium};
  color: ${stylingVariables.colorPalette.deepGray};
  padding-right: 10px;
  margin-right: 20px;
  cursor: pointer;
  opacity: .7;
  &:hover {
    opacity: 1;
  };
`;

interface ClearFiltersAndSortsProps {
    orders: interfaces.ListingOrders;
    filters: interfaces.ListingFilters;
    tags: EmployeeInterfaces.Tag[];
    resetOrdersAndFilters: () => void;

    getLeaveTransactions: (override?: boolean) => void;
    reloadLeaves: () => void;
}

class ClearFiltersAndSorts extends PureComponent<ClearFiltersAndSortsProps> {
    private get countActiveFiltersAndOrders(): number {
        return Object.keys(this.props.orders).length + Object.keys(this.props.filters).length + this.props.tags.length;
    }

    private get hasActiveFilters(): boolean {
        return this.countActiveFiltersAndOrders > 0;
    }

    private resetFilters = () => {
        this.props.resetOrdersAndFilters();
        this.props.getLeaveTransactions(true);
        this.props.reloadLeaves();
    }

    render() {
        if (!this.hasActiveFilters) {
            return null;
        }
        return (
            <StyledResetFilters onClick={this.resetFilters}>
                {intl.get('clear_filters_and_sorts')}
            </StyledResetFilters>
        );
    }
}

const mapStateToProps = (state: RootState, ownProps: any) => ({
    orders: selectors.getOrders(state, ownProps),
    filters: selectors.getFilters(state, ownProps),
    tags: selectors.getTags(state, ownProps),
});

const mapDispatchToProps = {
    resetOrdersAndFilters,
    reloadLeaves,
    getLeaveTransactions,
};

export default connect(mapStateToProps, mapDispatchToProps)(ClearFiltersAndSorts);
