import React, {PureComponent} from 'react';
import {intl} from '@global';
import styled from 'styled-components';
import {get} from 'lodash';

import {ApiObject} from '@api';
import {stylingVariables, constants} from '@global';
import {Icon} from '@common';

const StyledContactsWrapper = styled.div`
    margin: 10px auto 0 auto;
`;

const StyledHeader = styled.div`
    font-size: 12px;
    font-weight: normal;
    color: ${stylingVariables.colorPalette.deepGray};
    padding: 5px 0;
`;

const StyledContact = styled.div`
    margin: 10px 0;
    font-size: 14px;
    color: ${stylingVariables.colorPalette.green};
    svg {
        margin-right: 5px;
        display: inline-block;
        float: left;
    }
    span {
        display: inline-block;
        font-size: 14px;
        padding: 0;
    }
    &:first-child {
      margin-top: 5px;
    }
`;

interface IProps {
    fields: ApiObject.Fields;
}

export default class Contacts extends PureComponent<IProps> {
    public render() {
        const email = get(this.props.fields, 'person.primary_email');
        const skype = get(this.props.fields, 'person.primary_skype');
        const phone = get(this.props.fields, 'person.primary_phone_number');

        if (!email && !skype && !phone) {
            return null;
        }

        return (
            <StyledContactsWrapper>
                <StyledHeader>
                    {intl.get('contact')}
                </StyledHeader>
                {
                    email && (
                        <StyledContact>
                            <Icon type={constants.IconTypes.MAIL}
                                fillColor={stylingVariables.colorPalette.deepGray}
                                height={13}
                                width={13}
                            />
                            <span>{email}</span>
                        </StyledContact>
                    )
                }

                {
                    skype && (
                        <StyledContact>
                            <Icon type={constants.IconTypes.SKYPE}
                                fillColor={stylingVariables.colorPalette.deepGray}
                                height={13}
                                width={13}
                            />
                            <span>{skype}</span>
                        </StyledContact>
                    )
                }

                {
                    phone && (
                        <StyledContact>
                            <Icon type={constants.IconTypes.PHONE}
                                  fillColor={stylingVariables.colorPalette.deepGray}
                                  height={13}
                                  width={13}
                            />
                            <span>{phone}</span>
                        </StyledContact>
                    )
                }
            </StyledContactsWrapper>
        );
    }
}
