import React, {PureComponent, Fragment} from 'react';
import {intl} from '@global';
import styled from 'styled-components';
import {connect} from 'react-redux';

import {constants, stylingVariables} from '@global';
import {Can, Modal, ChartersPreview, SmallNavTabs} from '@common';
import {LeaveInterfaces} from '@api';

import * as selectors from '../../../Leaves/selectors';
import {RootState} from '../../../../rootReducer';
import LeaveBalanceEdit from './LeaveBalanceEdit';
import {
    getEmployeeLatestBalances,
    storeBalanceAdjustment,
    getTransitionalBalances,
} from '../../../Leaves/redux';

const BalancesHeader = styled.span`
    h4 {
        color: ${stylingVariables.colorPalette.darkest};
    }
`;

const StyledEmployeeWrapper = styled.div`
    display: flex;
    cursor: pointer;
    width: 100%;
    align-items: center;
    margin-top: 18px;
`;

const StyledContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 15px;
    flex: 1;
`;

const StyledNameWrapper = styled.span`
    cursor: pointer;
    padding: 5px 0;
    font-size: ${stylingVariables.fontSize.mediumLarge};
    font-weight: ${stylingVariables.fontWeight.regular};
    color: ${stylingVariables.colorPalette.green};
    outline: none;
    &:hover, &:focus {
        text-decoration: underline;
    }
`;

const StyledIdWrapper = styled.span`
    padding: 3px 0;
    font-size: ${stylingVariables.fontSize.medium};

    display: flex;
    align-items: center;
    flex-wrap: wrap;
`;

const ViewEmployeeCardWrapper = styled.span`
    font-size: ${stylingVariables.fontSize.medium};
    font-weight: ${stylingVariables.fontWeight.bold};
`;

const NavTabsWrapper = styled.div`
    > div:not(.modalButtons):not(:last-child) {
        padding: 10px 58px 0 58px;
    }

    > div:nth-child(1) {
        padding-top: 0;
        padding-bottom: 0;
        margin: 0;
        border-bottom: 1px solid ${stylingVariables.colorPalette.darkGray};
    }
`;

export enum ModalCloseReason {
    ModalCloseAction,
    SaveAction,
}

export enum ModalLocation {
    EmployeeView,
    LeavesView,
}

export interface LeaveBalanceAdjustmentMap {
    [leaveTypeId: number]: LeaveInterfaces.BalanceAdjustment;
}

interface IProps {
    isLoading?: boolean;
    isOpen: boolean;
    forEmployeeIds?: Set<number>;
    employee: LeaveInterfaces.EmployeeLeave;
    defaultLeaveTypeId?: number;
    showEmployeeWrapper: boolean;
    modalLocation: ModalLocation;

    onClickLink?: (event: any, id: number) => void;
    onClose: (closeReason?: ModalCloseReason) => any;
    afterSave?: () => any;

    latestBalances: LeaveInterfaces.EmployeeLatestBalances;
    leaveTypes: LeaveInterfaces.Type[];
    getEmployeeLatestBalances: (employeeId: number) => void;
    storeBalanceAdjustment: (adjustments: LeaveInterfaces.BalanceAdjustment[], modalLocation: ModalLocation,
                             forEmployees: Set<number>|undefined) => void;
    getTransitionalBalances: (employeeId: number) => void;
    transitionalBalances: LeaveInterfaces.TransitionalBalances;
}

interface IState {
    isOpenModal: boolean;
    adjustments: LeaveBalanceAdjustmentMap;
    invalidAdjustments: string[];
}

export class EditBalances extends PureComponent<IProps, IState> {
    static defaultProps = {
        showEmployeeWrapper: true,
    };

    public constructor(props: IProps) {
        super(props);

        const adjustments: LeaveBalanceAdjustmentMap = {};
        props.leaveTypes.forEach(type => {
            adjustments[type.id] = {
                comment: '',
                employeeId: this.props.employee.id,
                leaveTypeId: type.id,
                balanceAdjustment: 0,
                log: this.props.transitionalBalances[type.id]?.entries,
            };
        });

        this.state = {
            isOpenModal: props.isOpen,
            adjustments: [],
            invalidAdjustments: [],
        };
    }

    UNSAFE_componentWillMount() {
        this.props.getEmployeeLatestBalances(this.props.employee.id);
    }

    public async componentDidMount() {
        await this.props.getTransitionalBalances(this.props.employee.id);
        const adjustments: LeaveBalanceAdjustmentMap = {};
        this.props.leaveTypes.forEach(type => {
            adjustments[type.id] = {
                comment: '',
                employeeId: this.props.employee.id,
                leaveTypeId: type.id,
                balanceAdjustment: 0,
                log: this.props.transitionalBalances[type.id]?.entries,
            };
        });
        this.setState({adjustments: adjustments});
    }

    private fullName = this.props.employee.person ? `${this.props.employee.person.firstName} ${this.props.employee.person.lastName}` : '';
    private photoAccessToken = this.props.employee.photoAccessToken;
    private legalEntityId = this.props.employee.legalEntityId;

    private employeeWrapper() {
        if (!this.props.showEmployeeWrapper) {
            return null;
        }
        return (
            <StyledEmployeeWrapper>
                <ChartersPreview text={this.fullName} photo={this.photoAccessToken} legalEntityId={this.legalEntityId}/>
                <StyledContentWrapper>
                    <Can run={constants.PermissionCode.EMPLOYEE}>
                        <StyledNameWrapper>
                            {this.fullName}
                        </StyledNameWrapper>
                    </Can>

                    <StyledIdWrapper>
                        {this.props.employee.referenceCode}
                        {this.props.employee.position ? ' / ' + this.props.employee.position : null}
                    </StyledIdWrapper>
                </StyledContentWrapper>
                <ViewEmployeeCardWrapper
                    onClick={(event: any) =>
                        this.props.onClickLink &&
                        this.props.onClickLink(event, this.props.employee.id)
                    }
                >
                    {intl.get('view_employee_card' )}
                </ViewEmployeeCardWrapper>
            </StyledEmployeeWrapper>
        );
    }

    render() {
        return (
            <Fragment>
                {
                    this.state.isOpenModal &&
                    <Modal.CenterModal
                        minWidth={'750px'}
                        onClickClose={() => this.closeModal(ModalCloseReason.ModalCloseAction)}>
                        <Modal.StyledHeader
                            style={{
                                flexDirection: 'column',
                                alignItems: 'start',
                                borderBottom: 'none',
                                padding: '20px 77px',
                            }}>
                            <BalancesHeader>
                                <h4>{intl.get('edit_balances')}</h4>
                            </BalancesHeader>
                            {this.employeeWrapper()}
                        </Modal.StyledHeader>
                        <NavTabsWrapper>
                            <SmallNavTabs.NavTabs defaultTabIndex={this.getDefaultLeaveIndex()} tabs={
                                this.props.leaveTypes.map(leave => ({
                                        key: leave.id.toString(),
                                        label: leave.name,
                                        onSelect: this.onTabChange,
                                        renderContent: () => <LeaveBalanceEdit
                                            employee={this.props.employee}
                                            onClose={this.closeModal}
                                            leave={this.props.latestBalances &&
                                            this.props.latestBalances[this.props.employee.id]
                                                ? this.props.latestBalances[this.props.employee.id].balances[leave.id]
                                                : null}
                                            currentLeave={leave.id}
                                            adjustment={this.state.adjustments[leave.id]}
                                            storeAdjustment={this.saveAdjustmentDetailsInState}
                                            invalidAdjustments={this.state.invalidAdjustments}
                                            saveAdjustments={this.saveAdjustments}
                                            modalLocation={this.props.modalLocation}
                                        />,
                                    }),
                                )
                            }/>
                        </NavTabsWrapper>
                    </Modal.CenterModal>
                }
            </Fragment>
        );
    }

    private onTabChange = (selectedLeaveTypeId: string) => {
        const invalidAdjustmentIds = Object.values(this.state.adjustments)
            .filter(x => x !== this.state.adjustments[Number(selectedLeaveTypeId)])
            .filter(x => x.balanceAdjustment !== 0 && x.comment.length === 0)
            .map(x => x.leaveTypeId);
        const invalidAdjustments = this.props.leaveTypes
            .filter(x => invalidAdjustmentIds.includes(x.id))
            .map(x => x.name);
        this.setState({
            invalidAdjustments,
        });
    }

    private closeModal = (closeReason?: ModalCloseReason) => {
        this.setState({isOpenModal: false});
        this.props.onClose(closeReason);
    }

    private getDefaultLeaveIndex = () => {
        for (let i = 0; i < this.props.leaveTypes.length; i++) {
            const leaveType = this.props.leaveTypes[i];
            if (leaveType.id === this.props.defaultLeaveTypeId) {
                return i;
            }
        }
        return undefined;
    }

    private saveAdjustmentDetailsInState = (adjustment: LeaveInterfaces.BalanceAdjustment) => {
        this.setState({
            adjustments: {
                ...this.state.adjustments,
                [adjustment.leaveTypeId!]: adjustment,
            },
        });
    }

    private saveAdjustments = async() => {
        await this.props.storeBalanceAdjustment(
            Object.values(this.state.adjustments) as LeaveInterfaces.BalanceAdjustment[],
            this.props.modalLocation,
            this.props.forEmployeeIds,
        );

        if (this.props.afterSave) {
            this.props.afterSave();
        }
    }
}

const mapStateToProps = (state: RootState, ownProps: any) => ({
    leaveTypes: selectors.getLeaveTypesWithBalance(state, null),
    latestBalances: selectors.getLatestEmployeeBalances(state),
    transitionalBalances: selectors.getLeaveTransitionalBalances(state),
});

const mapDispatchToProps = {
    getEmployeeLatestBalances,
    storeBalanceAdjustment,
    getTransitionalBalances,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditBalances);
