import {DateHelper} from '@library';
import React, {PureComponent} from 'react';
import {Moment} from 'moment';
import styled from 'styled-components';

import {constants, stylingVariables, interfaces} from '@global';
import {Icon} from '@common';
import MultiMonthPicker from './Date/MultiMonthPicker';

const StyledArrow = styled.span`
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background: ${stylingVariables.colorPalette.white};
    cursor: pointer;
    
    &:hover {
      transition: background .3s;
      background: ${stylingVariables.colorPalette.lightGreen};
    }
`;

const Wrapper = styled.div<{ hasError: boolean }>`
    margin-left: 10px;
    font-weight: bold;
    position: relative;
    display: inline-flex;
    align-items: center;
    width: 100%;
    height: 42px;
    cursor: pointer;
`;
const SelectedMonths = styled.div`
  margin: 0 10px;
  font-size: ${stylingVariables.fontSize.default};
  font-weight: ${stylingVariables.fontWeight.semibold};
  color: ${stylingVariables.colorPalette.dark};
`;
const StyledLabel = styled.span`
  font-size: ${stylingVariables.fontSize.default};
  font-weight: ${stylingVariables.fontWeight.regular};
  color: ${stylingVariables.colorPalette.slateGray};
  white-space: nowrap;
`;

const MonthWrapper = styled.div`
   position: absolute;
   top: 42px;
   right: -1px;
   border: 1px solid ${stylingVariables.colorPalette.darkGray};
   z-index: 4;
`;

interface IProps {
    label: string;

    allowPeriods: interfaces.Periods;
    periods: interfaces.Periods;
    onChange: (periods: interfaces.Periods) => void;
    onCancel?: () => void;
    multiselect?: boolean

    disableAfterDate?: string|Moment;
    disableBeforeDate?: string|Moment;

    hasError?: boolean;
}

interface IState {
    isOpen: boolean;
}

export default class DateRangeField extends PureComponent<IProps, IState> {
    private wrapperRef: HTMLDivElement | null = null;
    private pickerRef: HTMLDivElement | null = null;

    static defaultProps = {
        label: '',
        schema: {},
        multiselect: true,
    }

    public constructor(props: IProps) {
        super(props);

        this.state = {
            isOpen: false,
        };
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    public render() {
        return (
            <Wrapper hasError={!!this.props.hasError}
                     ref={_ref => this.wrapperRef = _ref}
                     onClick={this.toggle}
            >
                <StyledLabel>{this.props.label}:</StyledLabel>

                {this.state.isOpen &&
                    <MonthWrapper ref={_ref => this.pickerRef = _ref}>
                        <MultiMonthPicker
                            allowPeriods={this.props.allowPeriods}
                            disableBeforeDate={this.props.disableBeforeDate}
                            periods={this.props.periods}
                            onSave={this.save}
                            onCancel={this.cancel}
                            multiselect={this.props.multiselect}
                        />
                    </MonthWrapper>
                }

                <SelectedMonths>
                    {DateHelper.convertPeriodsToHumanFormat(this.props.periods)}
                </SelectedMonths>

                <StyledArrow>
                    <Icon type={constants.IconTypes.CALENDAR}
                          fillColor={stylingVariables.colorPalette.dark}
                          height={15}
                          width={15}
                    />
                </StyledArrow>
            </Wrapper>
        );
    }

    private save = (periods: interfaces.Periods) => {
        this.props.onChange(periods);
        this.setState({isOpen: false});
    }

    private cancel = () => {
        this.setState({isOpen: false});
    }

    private toggle = (event: any) => {
        if (this.state.isOpen && this.pickerRef && this.pickerRef.contains(event.target)) {
            return;
        }

        this.setState({isOpen: !this.state.isOpen});
    }

    handleClickOutside = (event: any) => {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.setState({isOpen: false});
        }
    }
}
