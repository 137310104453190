import React, {PureComponent} from 'react';

import {
    openSalaryModal,
    setSalaryModalGroups,
    setSalaryModalValues,
} from '../../../redux';
import {PayrollInterfaces} from '@api';
import {RootState} from '../../../../../rootReducer';
import * as globalSelectors from '../../../../selectors';
import {connect} from 'react-redux';
import {UnitsTable} from '../Table/UnitsTable';
import AddButtonRow from '../SalarySection/AddButtonRow';

interface IProps {
    enabledSalaryGroups: string[];
    data: any;
    currentPayrollPeriod: PayrollInterfaces.PayrollPeriod|null;

    openSalaryModal: () => void;
    setSalaryModalGroups: (payElementType: string) => void;
    setSalaryModalValues: (values: any) => void;
    isCurrentPeriod: boolean;
    masterdataEditAllowed: boolean;
}

interface IState {
    containerHeight: number;
}

class UnitsSection extends PureComponent<IProps, IState> {

    public constructor(props: IProps) {
        super(props);
        this.state = {
            containerHeight: ((props.data?.elements?.length || 1)  * 70) + 110,
        };
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        if (prevProps.data?.elements?.length !== this.props.data?.elements?.length) {
            this.setState({
                containerHeight: this.containerHeight(),
            });
        }
    }

    private containerHeight(): number {
        const { data } = this.props;
        return (data?.elements?.length * 70) + 110;
    }

    private changeWrapperHeight = (tableHeight: number) => {
        this.setState({
            containerHeight: tableHeight + 110,
        });
    }

    private edit(payElementType: string, values: any) {
        this.props.setSalaryModalGroups(payElementType);
        this.props.setSalaryModalValues(values);
        this.props.openSalaryModal();
    }

    private add(payElementType: string) {
        this.props.setSalaryModalGroups(payElementType);
        this.props.setSalaryModalValues(null);
        this.props.openSalaryModal();
    }

    public render() {
        const { data, isCurrentPeriod } = this.props;

        return (
            <div>
                <div style={{height: this.state.containerHeight}}>
                    <UnitsTable
                        rows={data.elements}
                        addAction={() => this.add(data.code)}
                        editAction={(values: any) => this.edit(data.code, values)}
                        changeWrapperHeight={this.changeWrapperHeight}
                        isCurrentPeriod={isCurrentPeriod}
                        masterdataEditAllowed={this.props.masterdataEditAllowed}
                    />
                </div>
                {isCurrentPeriod && this.props.masterdataEditAllowed &&
                    <AddButtonRow action={() => this.add(data.code)} />
                }
            </div>
        );
    }
}

const mapStateToProps = (state: RootState) => ({
    currentPayrollPeriod: globalSelectors.getCurrentPayrollPeriod(state),
    masterdataEditAllowed: globalSelectors.masterdataEditAllowed(state, null)
});

const mapDispatchToProps = {
    openSalaryModal,
    setSalaryModalGroups,
    setSalaryModalValues,
};

export default connect(mapStateToProps, mapDispatchToProps)(UnitsSection);
