import React, {PureComponent, Fragment} from 'react';

import {LeaveInterfaces, EmployeeInterfaces} from '@api';
import {stylingVariables} from '@global';
import {intl} from '@global';

import Table from './Table/Table';
import styled from 'styled-components';
import {BlankButton} from '@common';

const StyledTableWrapper = styled.div<{height?: number}>`
    position: relative;
    display: inline-block;
    width: 100%;
    height: ${props => props.height || 500}px;
    padding: 27px 58px;
    .ReactVirtualized__Table {
        overflow-y: visible!important;
    }
    .ReactVirtualized__Table__Grid {
        overflow: visible!important;
        display: inline-block;
    }
`;

const RejectedWrapper = styled.div`
    display: flex;
    flex-direction: row;
    padding-right: 70px;

    button:after {
        content: '';
        border-top: 1px solid ${stylingVariables.colorPalette.gray};
        margin: 0;
        flex: 1 0 18px;
    }
`;

const CounterSpan = styled.span<{top?: number, left?: number}>`
    background: ${stylingVariables.colorPalette.yellow};
    font-weight: ${stylingVariables.fontWeight.regular};
    font-size: ${stylingVariables.fontSize.medium};
    left:  ${props => props.left || 0}px;
    top: ${props => props.top || -13}px;
    position: relative;
    padding: 2px 8px;
    border-radius: 13px;
    max-height: 16px;
`;

const LinedHeader = styled.div`
    display: flex;
    flex-direction: row;
    h4 {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-size: ${stylingVariables.fontSize.default};
    }

    span {
        position: relative;
    }

    h4:after {
        content: '';
        border-top: 1px solid ${stylingVariables.colorPalette.gray};
        margin: 0;
        flex: 1 0 18px;
    }

    h4:before {
        content: '';
        border-top: 1px solid ${stylingVariables.colorPalette.gray};
        margin: 0 18px 0 0;
        width: 50px;
    }
`;

const RejectedButton = styled(props => <BlankButton {...props} />)`
  color: ${stylingVariables.colorPalette.secondary};
  font-weight: ${stylingVariables.fontWeight.bold};
  font-size: ${stylingVariables.fontSize.mediumLarge};
  padding: 5px 15px;
  border-radius: 13px;
  white-space: nowrap;

  &:hover {
    background-color: ${stylingVariables.colorPalette.primaryHover};
  }
`;

export const Border = styled.span<{borderLen: number}>`
    content: '';
    border-top: 1px solid ${stylingVariables.colorPalette.gray};
    margin: 0;
    flex: 1 0 ${props => props.borderLen}px;
    margin-top: 13px;
`;

interface IProps {
    label: string;
    employee: EmployeeInterfaces.Employee;
    transactions: LeaveInterfaces.GroupedTransactions[];
    leaveTypes: LeaveInterfaces.Type[];
    showPayrollPeriod?: boolean;
    onShowRejected?: () => void;
    rejectedTransactions?: LeaveInterfaces.GroupedTransactions[];
    toggleRejected?: () => void;
    buttonMessage?: string;

    onClickSave: (employeeId: number, transaction: LeaveInterfaces.Transaction) => void;
    onClickApprove: (employeeId: number, transaction: LeaveInterfaces.Transaction) => void;
    onClickReject: (employeeId: number, transaction: LeaveInterfaces.Transaction) => void;
}

interface IState {
    height: number;
}

export default class Transactions extends PureComponent<IProps, IState> {
    public constructor(props: IProps) {
        super(props);

        this.state = {
            height: this.props.transactions.length ?
                (this.props.transactions.length * 50) + 100 : 150,
        };
    }


    render() {
        let transactionsCount = 0;
        this.props.transactions.forEach(tran => {
            transactionsCount += tran.transactions?.length;
        });

        let rejectedTransactionsCount = 0;
        this.props.rejectedTransactions?.forEach(tran => {
            rejectedTransactionsCount += tran.transactions?.length;
        });

        return (
            <Fragment>
                <LinedHeader>
                    <h4>{this.props.label}
                       {transactionsCount > 0 &&
                            <CounterSpan left={7}>{transactionsCount}</CounterSpan>}
                    </h4>
                    {this.props.toggleRejected && (
                        <RejectedWrapper>
                            <RejectedButton onClick={this.props.toggleRejected}>
                                {intl.get(this.props.buttonMessage || 'view_rejected_transaction')}
                            </RejectedButton>
                            {this.props.rejectedTransactions && rejectedTransactionsCount > 0 &&
                                <CounterSpan top={-8}>{rejectedTransactionsCount}</CounterSpan>}
                            <Border borderLen={80} />
                        </RejectedWrapper>
                    )}
                </LinedHeader>

                <StyledTableWrapper height={this.state.height}>
                    <Table
                        items={this.props.transactions}
                        showPayrollPeriod={this.props.showPayrollPeriod}
                        employee={this.props.employee}
                        leaveTypes={this.props.leaveTypes}

                        onClickSave={this.props.onClickSave}
                        onClickApprove={this.props.onClickApprove}
                        onClickReject={this.props.onClickReject}
                        changeWrapperHeight={this.changeWrapperHeight}
                    />
                </StyledTableWrapper>
            </Fragment>
        );
    }

    private changeWrapperHeight = (tableHeight: number) => {
        this.setState({ height: tableHeight + 100});
    }
}
