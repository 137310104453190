import React, {PureComponent} from 'react';
import styled from 'styled-components';

import {stylingVariables} from '@global';
import {FormatNumbers} from '@common';
import {intl} from '@global';
import {cloneDeep} from 'lodash';
import {EmployeePayElementDisplay} from '../../../../../api/employee.interfaces';
import {ApiObject} from '@api';

const StyledContainerWrapper = styled.div`
    display: flex;
    width: 100%;
    height: 60px;
    align-items: center;
    justify-content: flex-start;
    border-radius: 5px;
    background-color: ${stylingVariables.colorPalette.gray};
    margin: 10px 0;
    >div{
        display: flex;
        padding: 0 20px;
        color: ${stylingVariables.colorPalette.darkest};
        font-weight: bold;
        font-size: 14px;
        align-items: center;
        height: 100%;
        >div{
            background-color: ${stylingVariables.colorPalette.yellow};
            color: ${stylingVariables.colorPalette.darkest};
            border-radius: 30px;
            padding: 7px 15px;
            font-weight: bold;
            position: relative;
            margin: 0 5px;
        }
        >span{
            padding: 7px 0;
        }
    }
`;

const StyledTotalLabel = styled.div`
    flex: 0 1 200px;
    justify-content: flex-start;
`;

const StyledTotalValues = styled.div`
    flex: 1 1 480px;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
`;

interface IProps {
    rows: EmployeePayElementDisplay[];
    dataKey: string;
}

export default class TotalsRow extends PureComponent<IProps, any> {

    get groupedTotals(): EmployeePayElementDisplay[] {
        const {rows, dataKey} = this.props;
        const totals = cloneDeep((rows).filter(x =>
            x.payUnit !== ApiObject.PayUnitType.percentage &&
            (!x.changes || x.changes.length === 0))
        );

        return totals.reduce(
            (grouped: any[], current: any) => {
                const foundItem = grouped.find((item: any) => 
                    item.currency === current.currency
                );
                
                if (foundItem) {
                    foundItem[dataKey] += current[dataKey];
                } else {
                    grouped.push(current);
                }

                return grouped;
            }, []);
    }

    get getTotal() {
        const {dataKey} = this.props;
        return this.groupedTotals.map((item: any) => {
            if (!item[dataKey] && !item.currency){ return null; }

            const total = item[dataKey] ? <FormatNumbers value={item[dataKey]} /> : 0;
            return <div key={item.currency}>
                {total}
                {item.currency && ` / ${item.currency}`}
            </div>;
        });
    }

    public render() {
        const {dataKey} = this.props;
        return (
            <StyledContainerWrapper>
                <StyledTotalLabel>{intl.get((dataKey === 'annualAmount') ? 'total_annual' : 'total')}</StyledTotalLabel>
                <StyledTotalValues>
                    {this.getTotal}
                </StyledTotalValues>
            </StyledContainerWrapper>
        );
    }
}
