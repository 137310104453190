import React, { PureComponent } from "react";
import styled from "styled-components";
import { EmployeeInterfaces } from "@api";
import moment from "moment";
import { constants, stylingVariables, intl } from "@global";

const StyledContactsWrapper = styled.div`
  margin: 10px auto 0 auto;
`;

const StyledHeader = styled.div`
  font-size: 12px;
  font-weight: normal;
  color: ${stylingVariables.colorPalette.deepGray};
  padding: 5px 0;
`;

const StyledContact = styled.div`
  margin: 10px 0;
  font-size: 14px;
  color: ${stylingVariables.colorPalette.dark};
  span {
    display: inline-block;
    font-size: 14px;
    padding: 0;
  }
`;

interface IProps {
  employee: EmployeeInterfaces.Employee;
}
export default class LastAccess extends PureComponent<IProps> {
  public render() {
    const { lastEssAccessDate } = this.props.employee;

    if (!lastEssAccessDate) {
      return null;
    }
    const LastAccess = moment(lastEssAccessDate).format(
      constants.SYSTEM_DATE_HUMAN_FORMAT
    );

    return (
      <StyledContactsWrapper>
        <StyledHeader>{intl.get("ess_last_access_date")}</StyledHeader>
        <StyledContact>
          <span>{LastAccess}</span>
        </StyledContact>
      </StyledContactsWrapper>
    );
  }
}
