import React, {PureComponent} from 'react';

interface IProps {
    value: number | string | null;
    maximumDigits?: number;
    minimumDigits?: number;
    prefix?: string;
    showZero?: boolean;
}

export default class Numbers extends PureComponent<IProps> {
    static defaultProps = {
        maximumDigits: 2,
        minimumDigits: 2,
    };

    get formattedNumber() {
        let value = this.props.value;

        if (!value && !this.props.showZero) {
            return null;
        }

        if (value === 0 && this.props.showZero) {
            return 0;
        }

        if (typeof value === 'string'){
            value = parseFloat(value);
        }

        const options = {
            minimumFractionDigits: this.props.minimumDigits,
            maximumFractionDigits: this.props.maximumDigits,
        };

        return value!.toLocaleString(undefined, options);
    }

    render() {
        return (
            <>
                {this.props.prefix}{this.formattedNumber}
            </>
        );
    }
}
