import {ApiObject} from '@api';
import {store, intl, constants} from '@global';
import {isEqual} from 'lodash';

export default class ReportTemplatesHelper {
    public static isTemplateColumnsWasChanged(originalTemplate: ApiObject.ReportTemplate|null, template: ApiObject.ReportTemplate|null): boolean {
        if (!originalTemplate || !template) {
            return false;
        }

        if (originalTemplate.id !== template.id) {
            return false;
        }

        // When length of columns changed
        if (originalTemplate.columns.length !== template.columns.length) {
            return true;
        }

        // When columns changed
        return !isEqual(
          originalTemplate.columns.map((column: ApiObject.ReportColumn) => column.formula),
          template.columns.map((column: ApiObject.ReportColumn) => column.formula)
        )
    }

    public static isTemplateEmployeesWasChanged(
      originalTemplate: ApiObject.ReportTemplate|null,
      template: ApiObject.ReportTemplate|null
    ): boolean {
        if (!originalTemplate || !template) {
            return false;
        }

        if (originalTemplate.id !== template.id) {
            return false;
        }

        // When length of employees queries changed
        if (originalTemplate.employees?.queries?.length !== template.employees?.queries?.length) {
            return true;
        }

        // When columns changed
        return JSON.stringify(originalTemplate.employees?.queries) !== JSON.stringify(template.employees?.queries)
    }

    public static isCurrentUserOwnerOnTemplate(template: ApiObject.ReportTemplate|null) {
        if (!template) {
            return false;
        }

        if (template.id === -1) {
            return true;
        }

        const authorizedUser = store.getState().global.authorizedUser;
        if (!authorizedUser) {
            return false;
        }

        if (!template.ownerEmployee) {
            return false;
        }

        return template.ownerEmployee.id === authorizedUser.employee.id;
    }

    public static isTemplateTemporary(templates: ApiObject.ReportTemplate[] = [], templateId: number|null) {
        if (!templateId) {
            return true;
        }

        const template = templates.find(t => t.id === templateId);

        return !template || template.isTemporary;
    }

    public static pageTitleByMode(mode: constants.ReportProcessMode): string {
        switch (mode) {
            case constants.ReportProcessMode.generateReport: return intl.get('generate_report');
            case constants.ReportProcessMode.createReport: return intl.get('new_report');
            case constants.ReportProcessMode.viewReport: return intl.get('view_report');
            case constants.ReportProcessMode.editReport: return intl.get('update_report');
            case constants.ReportProcessMode.createTemplate: return intl.get('new_report_template');
            case constants.ReportProcessMode.viewTemplate: return intl.get('view_report_template');
            case constants.ReportProcessMode.editTemplate: return intl.get('update_report_template');
            default: return '';
        }
    }
}
