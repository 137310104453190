import React, { PureComponent } from 'react'
import styled from 'styled-components'

import { intl, stylingVariables } from '@global'
import { ApiObject, ApiSso } from '@api'
import ProductLogo from './ProductLogo'
import ProfileMenu from './Profile/ProfileMenu'
import { ModuleMenu } from 'hrb-common'
import { EnvironmentHelper, MenuLinks } from '@library'

const StyledHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${stylingVariables.colorPalette.white};
  height: ${stylingVariables.layout.height};
  border-bottom: 1px solid ${stylingVariables.colorPalette.darkGray};
  position: sticky;
  top: 0;
  z-index: 1000;
`

const StyledModuleMenuWrapper = styled.div`
  margin-right: 30px;
`

const StyledRightSideWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 64px;
`

interface IProps {
  authorizedUser: ApiObject.AuthorizedUser
  legalEntity: ApiObject.LegalEntity | null
  currentLegalEntity: ApiObject.LegalEntity | null
}

export default class Header extends PureComponent<IProps> {
  getAccessToken = async (sessionToken: string, clientId: string) => {
    const accessToken = await ApiSso.getAccessToken(sessionToken, clientId)
    return accessToken
  }

  render() {
    const appTiles = MenuLinks.appTiles(this.props.authorizedUser, this.props.legalEntity, this.props.currentLegalEntity)
    const customTiles = MenuLinks.customTiles(this.props.authorizedUser, this.props.legalEntity)

    const t = (text: string): string => {
      return intl.get(text.toLowerCase().replace(new RegExp('&', 'g'), 'and').replace(new RegExp(' ', 'g'), '_'))
    }

    return (
      <StyledHeaderWrapper>
        <ProductLogo />
        <StyledRightSideWrapper>
          <StyledModuleMenuWrapper>
            <ModuleMenu
              t={t}
              launchpad={EnvironmentHelper.getBackToLaunchpadUrl()}
              sessionToken={this.props.authorizedUser.sessionToken}
              currentModule={'pac'}
              appTiles={appTiles}
              customTiles={customTiles}
              legalEntity={this.props.legalEntity}
              getAccessToken={this.getAccessToken}
            />
          </StyledModuleMenuWrapper>
          <ProfileMenu />
        </StyledRightSideWrapper>
      </StyledHeaderWrapper>
    )
  }
}
