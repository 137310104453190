import React, {PureComponent} from 'react';
import styled from 'styled-components';
import {connect} from 'react-redux';

import {ApiObject, EmployeeInterfaces} from '@api';
import {Back} from '@common';
import {RootState} from '../../rootReducer';
import * as selectors from './selectors';
import {clear, getEmployee, getEmptyEmployeeForCreating, setIsEmployeeMasterdataEditAllowed} from './redux';
import {updateEmployeePermissions} from '../redux'

import Header from './Header';
import PersonalDetails from './PersonalDetails/PersonalDetails';
import Tabs from './Tabs/Tabs';

const StyledEmployeeWrapper = styled.div`
  padding: 0;
  position: relative;

  ${Back.StyledHeaderWrapper} {
    float: left;
  }
`;

const StyledRow = styled.div`
  width: 100%;
  padding: 20px 0;

  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start;
`;

const StyledFixedBackButton = styled.div`
    position: fixed;
    bottom: 30px;
    h4{
        font-size: 14px;
        font-weight: bold;
    }
`;

interface IProps {
    employee: EmployeeInterfaces.Employee | null;
    fields: ApiObject.Fields;
    fieldGroups: ApiObject.FieldGroup[];

    match: any;
    location: any;
    clear: () => void;
    getEmployee: (id: number) => void;
    getEmptyEmployeeForCreating: () => void;
    clearEmployee: () => void;
    setIsEmployeeMasterdataEditAllowed: (value: boolean) => void;
    updateEmployeePermissions: (value: boolean) => void;
}

interface IState {
    isCreateMode: boolean;
    matchId: number|string;
}

export class Employee extends PureComponent<IProps, IState> {
    public constructor(props: IProps) {
        super(props);

        props.clear();

        const {id} = props.match.params;
        const isCreateMode = id === 'create';

        this.state = {
            isCreateMode,
            matchId: id
        };

        this.initialize(isCreateMode, id)
    }

    private initialize = async (isCreateMode: boolean, id: any) => {
        if (isCreateMode) {
            this.props.getEmptyEmployeeForCreating();
        } else if (id) {
            await this.props.getEmployee(id);
            this.updatePermissions()
        }
      }

    static getDerivedStateFromProps(nextProps: any, prevState: any) {
        if (nextProps && nextProps.match.params.id !== prevState.matchId) {
            return {
                isCreateMode: nextProps.match.params.id === 'create',
            }
        }

        return null;
    }

    private updatePermissions = async() => {
        const  {employee} = this.props
        const allowed = employee?.cuttOffs?.masterdataEditAllowed || false;
        this.props.setIsEmployeeMasterdataEditAllowed(allowed)
        this.props.updateEmployeePermissions(allowed)
    }

    public componentDidUpdate = async() => {
        if (!this.state.isCreateMode) {
            const newEmployeeId = this.props.match.params?.id;
            if (newEmployeeId && Number(newEmployeeId) !== this.props.employee?.id) {
                await this.props.getEmployee(Number(newEmployeeId));
                this.updatePermissions()
            }
        }
    }

    public componentWillUnmount() {
        this.props.clear();
        this.props.updateEmployeePermissions(true)
    }

    public render() {
        if (!this.props.employee) {
            return null;
        }

        return (
            <StyledEmployeeWrapper>
                <Header />
                <StyledRow>
                    <PersonalDetails
                        employee={this.props.employee}
                        fields={this.props.fields}
                        isCreateMode={this.state.isCreateMode}
                    />

                    {!(this.props.fieldGroups.length === 0) && <Tabs
                        employee={this.props.employee}
                        fields={this.props.fields}
                        fieldGroups={this.props.fieldGroups}
                        isCreateMode={this.state.isCreateMode}
                    />}
                </StyledRow>

                <StyledFixedBackButton>
                    <Back.Header title={'back'}/>
                </StyledFixedBackButton>
            </StyledEmployeeWrapper>
        );
    }
}

const mapStateToProps = (state: RootState) => ({
    employee: selectors.getEmployee(state),
    fields: selectors.getFields(state),
    fieldGroups: selectors.getFieldGroups(state),
});

const mapDispatchToProps = {
    clear,
    getEmployee,
    getEmptyEmployeeForCreating,
    setIsEmployeeMasterdataEditAllowed,
    updateEmployeePermissions,
};

export default connect(mapStateToProps, mapDispatchToProps)(Employee);
