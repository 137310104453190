import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'

import { EmployeeInterfaces, ApiObject } from '@api'

import { RootState } from '../../../../../rootReducer'
import { intl, stylingVariables } from '@global'
import {
  createUserForPaymentProcessor,
  getCountriesAndCurrencies,
  getFieldsForBankAccount,
  createBankAccount,
} from '../../../redux'
import * as selectors from '../../../selectors'
import { EditableField, Actions } from '@common'
import { getInputType } from './fieldHelper'
import { LoaderDots } from './LoaderDots'

const Wrapper = styled.div`
`

const LeftSide = styled.div`
  padding-left: 37px;
  min-width: 600px;
`

const RightSide = styled.div`
  padding-bottom: 30px;
  button {
    padding: 10px 40px;
  }
`

export const ErrorRow = styled.div`
  font-size: 16px;
  margin: 10px 37px;
  display: flex;
`

export const ErrorLabel = styled.div`
  min-width: 200px;
`

export const ErrorMessage = styled.div`
  color: ${stylingVariables.colorPalette.red};
`

interface IProps {
  employee: EmployeeInterfaces.Employee
  getCountriesAndCurrencies: () => Promise<object>
  getFieldsForBankAccount: (id: number, params: object) => Promise<object>
  createBankAccount: (id: number, bankAccountFields: ApiObject.BankAccountField[]) => Promise<boolean>
  createUserForPaymentProcessor: (id: number) => Promise<ApiObject.BankAccount>
  countries: ApiObject.SelectOption[]
  bankData: ApiObject.BankData
  bankDetails: ApiObject.BankDetails
  bankDetailsErrors: ApiObject.UserErrors[],
  bankFields: ApiObject.BankField[]
  onClickCancel: (getFields: boolean) => void
  isLoading: boolean
}

interface IState {
  selectedCountry: string
  selectedCurrency: string
  gettingFields: boolean
  bankAccountFieldsData: ApiObject.BankAccountFields
  saveInitiated: boolean
  createUserErrors: ApiObject.UserErrors[]
  accountType: string
}

export class Edit extends PureComponent<IProps, IState> {
  public constructor(props: IProps) {
    super(props)

    props.getCountriesAndCurrencies()

    this.state = {
      selectedCountry: props.bankDetails.transferMethodCountry,
      selectedCurrency: props.bankDetails.transferMethodCurrency,
      gettingFields: false,
      bankAccountFieldsData: {} as ApiObject.BankAccountFields,
      saveInitiated: false,
      createUserErrors: [],
      accountType: ''
    }
  }

  private mapField = (field: ApiObject.BankField): string => {
    const name: ApiObject.BankField['name'] = field.name
    let output = this.props.bankDetails[name]

    return output
  }

  public async componentDidMount() {
    if (Object.keys(this.props.bankDetails).length === 0) {
      const response: ApiObject.BankAccount = await this.props.createUserForPaymentProcessor(this.props.employee.id)

      if (response?.errors) {
        this.setState({ createUserErrors: response.errors })
      }
    }

    let prefilled = {}
    for (let i = 0; i < this.props.bankFields.length; i = i + 1) {
      const field = this.props.bankFields[i]
      const fieldValue = this.mapField(field)

      const { name } = field
      const newField: ApiObject.BankAccountField = {
        fieldName: name,
        fieldValue: fieldValue,
      }

      prefilled = { ...prefilled, ...{ [name]: newField } }
      const bankAccountFieldsData = { ...this.state.bankAccountFieldsData, ...prefilled }
      this.setState({ bankAccountFieldsData })
    }
  }

  private currencyOptions = (): ApiObject.SelectOption[] => {
    if (this.state.selectedCountry) {
      const selected = this.props.countries.find((country) => country.key === this.state.selectedCountry)

      return selected?.currencies
        ? selected.currencies.map((currency) => {
            return {
              key: currency.code.toUpperCase(),
              label: currency.name,
              accountType: currency.accountType
            }
          })
        : []
    }

    return []
  }

  private updateCountry = async (value: string) => {
    await this.setState({ selectedCountry: value, bankAccountFieldsData: {} as ApiObject.BankAccountFields })

    if (this.currencyOptions().length === 1) {
      const curr = this.currencyOptions()[0]
      this.updateCurrency(curr.key)
    } else {
      this.setState({ selectedCurrency: '' })
    }
  }

  private updateCurrency = async (value: string) => {
    this.setState({ selectedCurrency: value, gettingFields: true })

    const params = {
      country: this.state.selectedCountry,
      currency: value,
    }

    await this.props.getFieldsForBankAccount(this.props.employee.id, params)

    const currencyObject = this.currencyOptions().find(c => c.key === this.state.selectedCurrency)

    if (currencyObject?.accountType) {
      this.setState({accountType: currencyObject.accountType })
    }
    
    this.setState({ gettingFields: false })
  }

  private getValueForFieldFromState = (
    name: ApiObject.BankField['name']
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): any => {
    const fieldExists = this.state.bankAccountFieldsData[name]

    if (!fieldExists) {
      return null
    }

    return fieldExists.fieldValue
  }

  private onUpdateField = (field: ApiObject.BankField, value: string): void => {
    const { name } = field
    const newField: ApiObject.BankAccountField = {
      fieldName: name,
      fieldValue: value,
    }

    const bankAccountFieldsData = { ...this.state.bankAccountFieldsData, ...{ [name]: newField } }

    this.setState({ bankAccountFieldsData })
  }

  private testRegex = (regularExpression: string, field: ApiObject.BankField, value: string): boolean => {
    let output = false
    const re = new RegExp(regularExpression)
    if ((field.required && !value) || (value && regularExpression && !re.test(value))) {
      output = true
    }

    return output
  }

  private save = (bankAccountFields: ApiObject.BankAccountField[]): Promise<boolean> => {
    return this.props.createBankAccount(this.props.employee.id, bankAccountFields)
  }

  private onSavePaymentMethod = async () => {
    this.setState({ saveInitiated: true })
    let errored = false
    if (this.props.bankFields) {
      Object.values(this.props.bankFields).every((field: ApiObject.BankField) => {
        const value = this.getValueForFieldFromState(field.name)
        const hasError = this.testRegex(field.regularExpression, field, value)

        if (hasError) {
          errored = true
          return false
        }

        errored = false
        return true
      })
    }

    const { selectedCountry, selectedCurrency } = this.state

    if (!errored) {
      const countryCurrency = [
        {
          fieldName: 'transferMethodCountry',
          fieldValue: selectedCountry,
        },
        {
          fieldName: 'transferMethodCurrency',
          fieldValue: selectedCurrency,
        },
        {
          fieldName: 'profileType',
          fieldValue: 'INDIVIDUAL',
        },
        {
          fieldName: 'type',
          fieldValue: this.state.accountType ? this.state.accountType : this.props.bankData.type,
        },
      ]

      const bankAccountFields = [...Object.values(this.state.bankAccountFieldsData), ...countryCurrency]

      const response = await this.save(bankAccountFields)

      this.setState({ saveInitiated: false })

      if (response) {
        this.props.onClickCancel(false)
      }
    }
  }

  private hasResponseError = (field: ApiObject.BankField): string => {
    const errorMessage = this.props.bankDetailsErrors.find(b => b.fieldName === field.name)
    return errorMessage ? errorMessage.message : ''
  }

  private renderAccountRow = (): Array<JSX.Element | null> => {
    return this.state.selectedCountry && this.state.selectedCurrency
      ? this.props.bankFields.map((field: ApiObject.BankField) => {
          const value = this.getValueForFieldFromState(field.name)
          const inputType = getInputType(field)

          const options = field.selectionChoices
            ? field.selectionChoices.map((c) => {
                return { key: c.value, label: c.label }
              })
            : []
        
          const errorMessage =
            this.state.saveInitiated && this.testRegex(field.regularExpression, field, value)
              ? intl.get('field_has_error')
              : this.hasResponseError(field)

          return (
            <div key={field.name}>
              <EditableField
                label={field.label}
                code={field.dataType}
                type={inputType}
                isRequired={field.required}
                errorMessage={errorMessage}
                defaultValue={value}
                options={options}
                onChange={(value: any) => this.onUpdateField(field, value)}
                disabled={this.state.gettingFields || this.props.isLoading}
              />
            </div>
          )
        })
      : [null]
  }

  private renderUserErrorsHeader = () => {
    return <ErrorRow>{intl.get('you_need_to_fix_errors_before_continue')}</ErrorRow>
  }

  private renderUserErrors = () => {
    return this.state.createUserErrors.map((e) => {
      return (
        <ErrorRow key={e.fieldName}>
          <ErrorLabel>{e.fieldName}</ErrorLabel>
          <ErrorMessage>{e.message}</ErrorMessage>
        </ErrorRow>
      )
    })
  }

  private renderBankData = () => {
    const { isLoading } = this.props
    const allowSave = !isLoading && this.state.selectedCountry && this.state.selectedCurrency

    return (
      <Wrapper>
        <LeftSide>
          <EditableField
            label="Country"
            code="countries"
            type={ApiObject.FieldType.dropdown}
            isRequired={true}
            defaultValue={this.state.selectedCountry}
            options={this.props.countries}
            onChange={(value: any) => {
              this.updateCountry(value)
            }}
            disabled={this.state.gettingFields || isLoading}
          />
          {this.state.selectedCountry && (
            <EditableField
              label="Currency"
              code="countries"
              type={ApiObject.FieldType.dropdown}
              isRequired={true}
              defaultValue={this.state.selectedCurrency}
              options={this.currencyOptions()}
              onChange={(value: any) => {
                this.updateCurrency(value)
              }}
              disabled={this.currencyOptions().length < 2 || this.state.gettingFields || isLoading}
            />
          )}
          {this.state.gettingFields && (
            <LoaderDots>
              <div className="loader-dots" />
            </LoaderDots>
          )}

          {!this.state.gettingFields && this.props.bankFields && this.renderAccountRow()}
        </LeftSide>
        <RightSide>
          <Actions.ActionRightBar>
            <Actions.GrayButton onClick={() =>this.props.onClickCancel(true)} isDisabled={isLoading}>
              {intl.get('cancel')}
            </Actions.GrayButton>

            <Actions.OrangeButton onClick={this.onSavePaymentMethod} isDisabled={!allowSave}>
              {intl.get('save')}
            </Actions.OrangeButton>
          </Actions.ActionRightBar>
        </RightSide>
      </Wrapper>
    )
  }

  public render() {
    return this.state.createUserErrors.length > 0 ? (
      <div>
        {this.renderUserErrorsHeader()}
        {this.renderUserErrors()}
      </div>
    ) : (
      this.renderBankData()
    )
  }
}

const mapStateToProps = (state: RootState) => ({
  countries: selectors.getCountryOptions(state),
  bankData: selectors.getBankData(state),
  bankDetails: selectors.getUserBankDetails(state),
  bankDetailsErrors: selectors.getUserBankDetailsErrors(state),
})

const mapDispatchToProps = {
  getCountriesAndCurrencies,
  getFieldsForBankAccount,
  createBankAccount,
  createUserForPaymentProcessor,
}

export default connect(mapStateToProps, mapDispatchToProps)(Edit)
