import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import moment, {Moment} from 'moment';

import {MonthPicker} from '@common';

import {RootState} from '../../../rootReducer';
import {setPeriodFilter, getLeaveTransactions} from '../redux';
import * as selectors from '../../Leaves/selectors';

interface IProps {
    from: Moment;
    till: Moment;
    isDisabled: boolean;

    setPeriodFilter: (from: Moment, till: Moment) => void;
    getLeaveTransactions: () => void;
}

export class Period extends PureComponent<IProps> {
    static defaultProps = {
        isDisabled: false,
    };

    public render() {
        return (
            <MonthPicker
                isDisabled={this.props.isDisabled}
                selected={this.props.from}
                onChangeDate={this.onChangeSelectedPeriod}
                popperPlacement={'bottom-end'}
            />
        );
    }

    private onChangeSelectedPeriod = (selected: Moment) => {
        const from = moment(selected).startOf('month');
        const till = moment(selected).endOf('month');
        this.props.setPeriodFilter(from, till);
        this.props.getLeaveTransactions();
    }
}

const mapStateToProps = (state: RootState, ownProps: any) => ({
    from: selectors.getPeriodFilterFrom(state, ownProps),
    till: selectors.getPeriodFilterTill(state, ownProps),
});

const mapDispatchToProps = {
    setPeriodFilter,
    getLeaveTransactions,
};

export default connect(mapStateToProps, mapDispatchToProps)(Period);
