import React from 'react';

import {interfaces} from '@global';
import {EmployeeInterfaces, PayrollInterfaces} from '@api';
import {RouteHelper} from '@library';

import Column from '../../../common/Employee/Table/Column/Employee';

interface IProps {
    currentPeriod: PayrollInterfaces.PayrollPeriod|null;

    orders: interfaces.ListingOrders;
    filters: interfaces.ListingFilters;
    tags: EmployeeInterfaces.Tag[];

    getEmployees: (query: string, tags: EmployeeInterfaces.Tag[], limit: number, page: number) => any;
    changeOrder: (column: string) => void;
    changeFilter: (params: interfaces.FilterParams) => void;
    setTags: (tags: EmployeeInterfaces.Tag[]) => void;
}

const EmployeeColumn: React.FC<IProps> = ({
    currentPeriod,

    filters,
    orders,
    tags,

    getEmployees,
    changeFilter,
    changeOrder,
    setTags,
}) => (
    Column({
        currentPeriod,

        filters,
        orders,
        tags,

        changeOrder,
        changeFilter,
        setTags,
        onClickLink: redirectToEmployeePage,
        getEmployees,
    })
);

function redirectToEmployeePage(event: any, id: number) {
    event.stopPropagation();

    RouteHelper.goToEmployeePage(id);
}

export default EmployeeColumn;
