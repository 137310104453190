import React, {PureComponent} from 'react';
import styled from 'styled-components';

import {stylingVariables} from '@global';

const StyledInputWrapper = styled.div<{hasError: boolean}>`
  display: flex;
  flex-direction: column;
  label {
    font-size: ${stylingVariables.fontSize.medium};
    font-weight: ${stylingVariables.fontWeight.regular};
    margin-bottom: 5px;
  }
  div {
    display: block;
    font-size: ${stylingVariables.fontSize.default};
    width: 100%;
    outline: none;
    border: 1px solid ${stylingVariables.colorPalette.darkerGray};
    border-radius: 4px;
    padding: 10px;
    resize: none;
    line-height: 20px;
  }

  ${props => props.hasError && `
    span {
      font-size: ${stylingVariables.fontSize.medium};
      font-weight: ${stylingVariables.fontWeight.regular};
      color: ${stylingVariables.colorPalette.red};
      margin-top: 10px;
      font-style: italic;
    }
    div {
      border: 1px solid ${stylingVariables.colorPalette.red};
    }
  `}
`;

const ContentEditable = styled.div<{suffix: string}>`
  font-size: ${stylingVariables.fontSize.default};
  color: ${stylingVariables.colorPalette.darkest};
  font-weight: ${stylingVariables.fontWeight.semibold};
  &:after {
      content: "${props => props.suffix}";
      font-size: ${stylingVariables.fontSize.default};
      font-weight: ${stylingVariables.fontWeight.semibold};
      color: ${stylingVariables.colorPalette.grayish};
  }
`;

interface InputProps {
    label: string;
    suffix: string;
    placeholder?: string;
    errorMessage?: string|null;

    type?: string;
    isRequired?: boolean;
    defaultValue?: string;
    autoFocus?: boolean;
    onChange: (value: string) => void;
    onKeyDown?: (event: any) => void;
    onKeyPress?: (event: any) => void;
}

export class InputWithSuffix extends PureComponent<InputProps> {
    static defaultProps = {
        label: '',
        onChange: () => {}
    }

    render() {
        return (
            <StyledInputWrapper hasError={!!this.props.errorMessage}>
                {
                    this.props.label &&
                    <label>{this.props.label} {this.props.isRequired && '*'}</label>
                }

                <ContentEditable contentEditable="true"
                                 suffix={this.props.suffix}
                                 placeholder={this.props.placeholder}
                                 onInput={(e: any) => this.props.onChange(e.currentTarget.textContent)}
                                 onKeyDown={this.props.onKeyDown}
                                 onKeyPress={this.props.onKeyPress}>
                    {this.props.defaultValue}
                </ContentEditable>

                {this.props.errorMessage && <span>{this.props.errorMessage}</span>}
            </StyledInputWrapper>
        );
    }
}

export default InputWithSuffix;
