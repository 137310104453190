import {ApiObject} from '@api';
import {stylingVariables, interfaces, constants} from '@global';
import React, {PureComponent} from 'react';
import {AutoSizer} from 'react-virtualized';
import {connect} from 'react-redux';

import {intl} from '@global';
import {
    StyledInfiniteTable as CommonStyledInfiniteTable,
    InfiniteTableColumn,
    InfiniteTableNoRows,
    InfiniteTableLoader,
} from '@common';
import {changeTemplate, openTemplateViewProcess, setMode as setProcessMode} from '../../Process/redux'
import {getTemplates, changeFilter, changeOrder} from '../redux'

import * as selectors from '../selectors';
import * as globalSelectors from '../../../selectors';
import {RootState} from '../../../../rootReducer';
import styled from 'styled-components';

import * as Column from './Column';
import {RouteHelper} from '@library'

const StyledInfiniteTable = styled(CommonStyledInfiniteTable)`
    .ReactVirtualized__Table__headerRow{
        overflow: visible!important;
        .ReactVirtualized__Table__headerColumn {
            padding: 5px 10px;
            &:first-child {
              padding-left: 20px;
            }
        }
        .ReactVirtualized__Table__headerColumn:nth-last-child(2) {
            border-right: none;
        }
    }

    .ReactVirtualized__Table__row{
        .ReactVirtualized__Table__rowColumn {
            padding: 5px 10px;
            &:first-child {
              padding-left: 20px;
            }
        }
        
        &.highlighted, &:hover {
            cursor: pointer;
            .ReactVirtualized__Table__rowColumn {
                border-top: 1px solid ${stylingVariables.hrb.colorPalette.green}; 
                border-bottom: 1px solid ${stylingVariables.hrb.colorPalette.green}; 
                &:first-child{
                    border-left: 1px solid ${stylingVariables.hrb.colorPalette.green}; 
                } 
                &:last-child{
                    border-right: 1px solid ${stylingVariables.hrb.colorPalette.green}; 
                }
                .checkbox {
                  display: flex;
                }
                .index {
                  display: none;
                }
            }
        }
    }
`;

interface IProps {
    isLoading: boolean;
    isMenuCollapsed: boolean;

    getTemplates: () => void;
    orders: interfaces.ListingOrders;
    filters: interfaces.ListingFilters;
    changeOrder: (column: string) => any;
    changeFilter: (params: interfaces.FilterParams) => any;

    templates: ApiObject.ReportTemplate[];

    openTemplateViewProcess: (template: ApiObject.ReportTemplate, mode?: constants.ReportProcessMode) => any;
    changeTemplate: (template: ApiObject.ReportTemplate) => any;
    setProcessMode: (mode: constants.ReportProcessMode) => any;
}

export class Table extends PureComponent<IProps> {
    private readonly NON_CLICKABLE_COLUMNS = [
        'actions',
    ];

    public constructor(props: IProps) {
        super(props);

        props.getTemplates()
    }

    get templates() {
        const {filters, orders} = this.props;

        const sortByKey: string = Object.keys(orders).length > 0 ? Object.keys(orders)[0] : 'id';
        const sortAsc = orders[sortByKey] ? orders[sortByKey].direction === ApiObject.OrderByOperator.asc : false;

        const filterByLabel = (template: ApiObject.ReportTemplate) => {
            if (!filters.label) {
                return true;
            }

            const filterValue: string = filters.label.value;

            return template.label.toLocaleLowerCase().includes(filterValue.toLocaleLowerCase());
        }
        const sort = (a: ApiObject.ReportTemplate, b: ApiObject.ReportTemplate) => {
            const collator = new Intl.Collator();
            if (sortAsc) {
                return collator.compare(`${b[sortByKey as keyof ApiObject.ReportTemplate]}`, `${a[sortByKey as keyof ApiObject.ReportTemplate]}`);
            }

            return collator.compare(`${a[sortByKey as keyof ApiObject.ReportTemplate]}`, `${b[sortByKey as keyof ApiObject.ReportTemplate]}`);
        }

        return this.props.templates
            .filter(filterByLabel)
            .sort(sort);
    }

    public render() {
        return (
            <AutoSizer>
                {({width, height}) => (
                    <StyledInfiniteTable
                        rowHeight={80}
                        headerHeight={40}
                        rowGetter={({ index }: {index: number}) => this.templates[index]}
                        rowCount={this.templates.length}
                        height={height - 100}
                        width={width}
                        isMenuCollapsed={this.props.isMenuCollapsed}
                        noRowsRenderer={this._renderNoRows}
                        onRowClick={({rowData}: any) => this.props.openTemplateViewProcess(rowData, constants.ReportProcessMode.viewTemplate)}
                        onColumnClick={({ dataKey, event }: any) => {
                            if (this.NON_CLICKABLE_COLUMNS.includes(dataKey)) {
                                event.stopPropagation();
                            }
                        }}
                    >
                        {InfiniteTableColumn.OrderedAndFiltered({
                            label: intl.get('template_name'),
                            dataKey: 'label',
                            type: ApiObject.FieldType.text,
                            width: 280,
                            orders: this.props.orders,
                            filters: this.props.filters,
                            changeOrder: this.props.changeOrder,
                            changeFilter: this.props.changeFilter,
                            style: {
                                color: stylingVariables.colorPalette.green
                            },
                        })}

                        {Column.User({
                            label: intl.get('created_by_user'),
                            width: 220,
                        })}

                        {Column.Visibility({
                            label: intl.get('visibility'),
                            width: 100,
                        })}

                        {Column.Actions({
                            onClickView: this.props.openTemplateViewProcess,
                            onClickEdit: this.onOpenEditTemplate
                        })}
                    </StyledInfiniteTable>
                )}
            </AutoSizer>
        );
    }

    private _renderNoRows = () => {
        if (this.props.isLoading) {
            return (<InfiniteTableLoader/>);
        }

        return (
            <InfiniteTableNoRows text={intl.get('no_report_templates_found')}/>
        );
    }

    public onOpenEditTemplate = (template: ApiObject.ReportTemplate) => {
        this.props.changeTemplate(template)
        this.props.setProcessMode(constants.ReportProcessMode.editTemplate)
        RouteHelper.goToReportSelectData();
    }
}

const mapStateToProps = (state: RootState) => ({
    isLoading: globalSelectors.isLoading(state),
    isMenuCollapsed: globalSelectors.isMenuCollapsed(state),

    templates: selectors.getTemplates(state),
    orders: selectors.getOrders(state),
    filters: selectors.getFilters(state),
});

const mapDispatchToProps = {
    openTemplateViewProcess,
    setProcessMode,
    changeTemplate,
    changeFilter,
    changeOrder,
    getTemplates
};

export default connect(mapStateToProps, mapDispatchToProps)(Table);
