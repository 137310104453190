import {AnyAction, Dispatch} from 'redux';
import {ThunkAction} from 'redux-thunk';
import {toast} from 'react-toastify';

import {constants, intl} from '@global';
import {ApiEmployee} from '@api';

import {RootState} from '../../../../rootReducer';
import {startLoading, stopLoading} from '../../../redux';
import {setErrorFields, setEmployeeFields} from '../../redux';

type ThunkResult<R> = ThunkAction<R, RootState, undefined, AnyAction> | AnyAction;

export function saveDependentFields(employeeId: number, forUpdateFields: any): ThunkResult<Promise<boolean>> {
    return async(dispatch: Dispatch, getState: () => RootState) => {
        const entityId = getState().global.currentLegalEntityId;
        if (!entityId) {
            return false;
        }

        dispatch(setErrorFields([]));
        dispatch(startLoading());

        try {
            const fields = await ApiEmployee.saveEmployeeFields(entityId, employeeId, forUpdateFields);
            if (!fields || fields?.errors) {
                dispatch(setErrorFields(fields.errors || []));
                return false;
            } else {
                dispatch(setEmployeeFields(fields));
                toast.success(intl.get('employee_fields_saved'));
            }
        } catch (e) {
            dispatch(setErrorFields(e?.response?.data?.data || []));
            return false;
        } finally {
            dispatch(stopLoading());
        }

        return true;
    };
}

export function deleteDependent(employeeId: number, fieldEntityId: string): ThunkResult<Promise<boolean>> {
    return async(dispatch: Dispatch, getState: () => RootState) => {
        const entityId = getState().global.currentLegalEntityId;
        if (!entityId) {
            return false;
        }

        dispatch(startLoading());

        try {
            const fields = await ApiEmployee.saveEmployeeFields(entityId, employeeId, [
                {
                    deleteEntity: true,
                    entity: constants.DEFAULT_DEPENDENT_CODE,
                    entityId: fieldEntityId,
                },
            ]);

            if (!fields || fields?.errors) {
                dispatch(setErrorFields(fields.errors || []));
                return false;
            } else {
                dispatch(setEmployeeFields(fields));
                toast.success(intl.get('dependent_deleted'));
            }
        } catch (e) {
            dispatch(setErrorFields(e?.response?.data?.data || []));
            return false;
        } finally {
            dispatch(stopLoading());
        }

        return true;
    };
}
