import {stylingVariablesGlobal as stylingVariables} from 'hrb-common';
import React, {PureComponent} from 'react';
import styled from 'styled-components';
import {constants, intl} from '@global';
import {Collapse, FormatNumbers, Icon} from '@common';
import moment from 'moment';
import {LeaveInterfaces} from '@api';

const LogHeadingWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: start;
    width: 100%;

    &:hover{
      span {
        color: ${stylingVariables.colorPalette.green} !important;
      }
      .logDot {
        border-color: ${stylingVariables.colorPalette.green} !important;
      }
      .logDot>span {
        background-color: ${stylingVariables.colorPalette.green} !important;
      }
    }
`;

const LogDotsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const LogDotWrapper = styled.div`
    background: transparent;
    border-radius: 50%;
    border: 1px solid #00000029;
    height: 17px;
    width: 17px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px 0;
`;

const LogDot = styled.span`
    height: 7px;
    width: 7px;
    background: #00000029;
    border-radius: 50%;
    display: inline-block;

    &:hover {
        background-color: ${stylingVariables.colorPalette.green} !important;
    }
`;

const ContentHeading = styled.span`
    color: ${stylingVariables.colorPalette.deepGray};
    font-size: ${stylingVariables.fontSize.large};
    flex-basis: 20%;
    flex-grow: 0;
`;

const DottedBorder = styled.div<{hide: boolean}>`
    height: 16px;
    width: 2px;
    margin: 3px 0;
    border-left: 3px dashed ${stylingVariables.colorPalette.gray};
    display: ${props => props.hide ? 'none' : 'inline-block'};
`;

const Triangle = styled.div<{negative: boolean}>`
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid ${props => props.negative ?
    stylingVariables.colorPalette.red : stylingVariables.colorPalette.green};
    transform: rotate(${props => props.negative ? 0 : 180}deg);
    margin-right: 5px;
`;

const AmountWrapper = styled.div<{negative: boolean, marginTop?: number}>`
    display: flex;
    align-items: center;
    padding: 2px 7px;
    font-size: ${stylingVariables.fontSize.large};
    margin-top: ${props => props.marginTop || 0}px;
`;

const HeaderContentWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
`;

const ChangeWrapper = styled.div`
    border-radius: 30px;
    background: ${stylingVariables.colorPalette.gray};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 5px 12px;
    margin: 0 8px;
`;

const LogContent = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 83px 7px;
`;

const ChangeBubbles = styled.div`
  flex-basis: 30%;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const CreatorWrapper = styled.div`
    color: ${stylingVariables.colorPalette.deepGray};
    font-size: ${stylingVariables.fontSize.mediumSmall};
    margin-right: 5px;
`;

const LogItemWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 15px;
    border-left: 3px dashed ${stylingVariables.colorPalette.gray};
    margin-bottom: 3px;
`;

const BubbleWrapper = styled.div<{marginRight?: number, marginLeft?: number}>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: ${stylingVariables.colorPalette.deepGray};
    font-size: ${stylingVariables.fontSize.mediumSmall};
    font-weight: ${stylingVariables.fontWeight.semibold};

    span {
        margin-bottom: 5px;
        margin-right: ${props => props.marginRight || 0}px;
        margin-left: ${props => props.marginLeft || 0}px;
    }
`;

const Bubble = styled.div<{marginRight?: number, marginLeft?: number}>`
  border-radius: 50%;
  border: 2px solid ${stylingVariables.colorPalette.gray};
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${stylingVariables.fontSize.largest};
  margin-right: ${props => props.marginRight || 0}px;
  margin-left: ${props => props.marginLeft || 0}px;
  color: ${stylingVariables.colorPalette.darkest};
  font-weight: ${stylingVariables.fontWeight.regular};
`;

const Arrow = styled.div`
    margin-right: 36px;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    margin-top: 12px;

    &:before {
      width: 22px;
      height: 1px;
      background: ${stylingVariables.colorPalette.deepGray};
      content: "";
      display: inline-block;
      vertical-align: middle;
      position: absolute;
    }

    svg {
        margin-left: 13px;
    }
`;

interface IProps {
    entries: LeaveInterfaces.LeaveTransitionalBalanceLogEntry[];
}

interface IState {
    isOpen: any;
}

export default class LeaveBalanceEditLog extends PureComponent<IProps, IState> {
    public constructor(props: IProps) {
        super(props);
        this.state = {
            isOpen: {},
        };
    }

    render() {
        return (
            <>
                {this.props.entries.map((entry, index) => (
                    <Collapse
                        key={index}
                        isOpen={this.state.isOpen[index] || false}
                        header={false}
                    >
                        <LogHeadingWrapper>
                            <LogDotsWrapper>
                                <LogDotWrapper className={'logDot'}><LogDot /></LogDotWrapper>
                                <DottedBorder hide={this.props.entries.length - index === 1} />
                            </LogDotsWrapper>
                            <HeaderContentWrapper>
                                <AmountWrapper negative={entry.amount < 0}>
                                    {entry.type === 'ytd_balance' && !entry.periodInitial && <span>{intl.get('leave_balance_changed')}</span>}
                                    {entry.type === 'ytd_balance' && entry.periodInitial &&
                                        <span>{intl.get('new_period_balances_set',
                                            { period: entry.periodName })}</span>}
                                    {entry.type === 'transaction' &&
                                        <span>{intl.get('leave_balance_transaction')}</span>}
                                    {entry.type === 'ytd_balance' && !entry.periodInitial &&
                                        <ChangeWrapper>
                                            {entry.amount !== 0 &&
                                            <Triangle negative={entry.amount < 0} />} {entry.amount < 0 ?
                                            (entry.amount * -1) : entry.amount}{intl.get('d')} = {entry.relativeBalance}{intl.get('d')}
                                        </ChangeWrapper>
                                    }
                                    {entry.type === 'ytd_balance' && entry.periodInitial &&
                                        <ChangeWrapper>
                                            {entry.amount !== 0 &&
                                            <Triangle negative={entry.amount < 0} />} {
                                                (entry.amount - entry.lastPeriodBalance) < 0 ?
                                                ((entry.amount - entry.lastPeriodBalance) * -1) :
                                                (entry.amount - entry.lastPeriodBalance) }{intl.get('d')} = {entry.relativeBalance}d
                                        </ChangeWrapper>
                                    }
                                    {entry.type !== 'ytd_balance' &&
                                        <ChangeWrapper>
                                            {entry.amount < 0 ?
                                                (entry.amount * -1) : entry.amount}{intl.get('d')}
                                        </ChangeWrapper>
                                    }
                                </AmountWrapper>
                                <CreatorWrapper>
                                    {entry.createTime && moment(entry.createTime, 'YYYY-MM-DDTHH:mm:ssZ').format('MMM DD, YYYY')}
                                    {entry.creatorEmployee &&
                                    <> {intl.get('by')} {entry.creatorEmployee.firstName} {entry.creatorEmployee.lastName}</>}
                                </CreatorWrapper>
                            </HeaderContentWrapper>
                        </LogHeadingWrapper>

                        <LogContent>
                            <DottedBorder hide={this.props.entries.length - index !== 1} />
                            <LogItemWrapper>
                                <ContentHeading>{intl.get('annual_total')}</ContentHeading>
                                <ChangeBubbles>
                                    <BubbleWrapper marginRight={45}>
                                        <span>{intl.get('before')}</span>
                                        <Bubble marginRight={45}>
                                            {entry.type === 'ytd_balance' && !entry.periodInitial &&
                                                <FormatNumbers
                                                  showZero={true}
                                                  value={entry.relativeBalance - entry.amount}
                                                />
                                            }
                                            {entry.type === 'ytd_balance' && entry.periodInitial &&
                                                <FormatNumbers
                                                  showZero={true}
                                                  value={entry.lastPeriodBalance}
                                                />
                                            }
                                            {entry.type === 'transaction' &&
                                                <FormatNumbers
                                                  showZero={true}
                                                  value={entry.relativeBalance + entry.amount}
                                                />
                                            }
                                        </Bubble>
                                    </BubbleWrapper>
                                    <Arrow>
                                        <Icon type={constants.IconTypes.ARROW_RIGHT}
                                              width={16}
                                              height={10}
                                              fillColor={stylingVariables.colorPalette.deepGray}
                                        />
                                    </Arrow>
                                    <BubbleWrapper marginLeft={12} marginRight={10}>
                                        <span>{intl.get('after')}</span>
                                        <Bubble marginLeft={12} marginRight={10}>
                                            <FormatNumbers
                                                showZero={true}
                                                value={entry.relativeBalance}
                                            />
                                        </Bubble>
                                    </BubbleWrapper>
                                    {entry.periodInitial &&
                                        <AmountWrapper
                                            negative={(entry.amount - entry.lastPeriodBalance) < 0}
                                            marginTop={12}
                                        >
                                            {(entry.amount - entry.lastPeriodBalance) !== 0 &&
                                            <Triangle
                                                negative={(entry.amount - entry.lastPeriodBalance) < 0}
                                            />} {(entry.amount - entry.lastPeriodBalance) < 0 ?
                                            ((entry.amount - entry.lastPeriodBalance) * -1) :
                                            (entry.amount - entry.lastPeriodBalance)}{intl.get('d')}
                                        </AmountWrapper>
                                    }
                                    {!entry.periodInitial &&
                                        <AmountWrapper negative={entry.amount < 0} marginTop={12}>
                                            {entry.amount !== 0 &&
                                             <Triangle
                                                negative={entry.amount < 0 || entry.type === 'transaction'}
                                            />} {entry.amount < 0 ?
                                            (entry.amount * -1) : entry.amount}{intl.get('d')}
                                        </AmountWrapper>
                                    }
                                </ChangeBubbles>
                                {entry.type !== 'ytd_balance' && <ChangeBubbles></ChangeBubbles>}
                            </LogItemWrapper>
                            <LogItemWrapper>
                                <ContentHeading>{intl.get('reason')}</ContentHeading> <div>{entry.comment}</div>
                            </LogItemWrapper>
                        </LogContent>
                    </Collapse>
                  ))
                }
            </>
        );
    }
}
