import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';
import {intl, stylingVariables} from '@global';
import * as globalSelectors from './selectors';
import {RootState} from '../rootReducer';
import logo from '../assets/images/hrblizz_logo.png';
import {RouteHelper} from '@library';

const Wrapper = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const Loader = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	img {
		height: 40px;
		margin-bottom: 20px;
	}
`;
const Dot = styled.div`
	height: 14px;
	width: 14px;
	margin: 10px;
	background-color: ${stylingVariables.hrb.colorPalette.green};
	border-radius: 50%;
	
`;
const Dots = styled.div`
	display: flex;
	align-items: flex-end;
	@keyframes blink {
	    0% { opacity: .15; }
	    20% { opacity: 1; }
	    100% { opacity: .15; }
	}

	${Dot} {
	  	font-size: 100px;
    	animation: blink 1.4s infinite both;
    	&:nth-child(2) {
    		animation-delay: .2s;
    	}
    	&:nth-child(3) {
    		animation-delay: .4s;
    	}
	}
`;
const StyledBackToLaunchpad = styled.span`
	color: ${stylingVariables.hrb.colorPalette.green};
	font-size: ${stylingVariables.hrb.fontSize.mediumSmall};
	font-weight: ${stylingVariables.hrb.fontWeight.semibold};
	cursor: pointer;
`;

const Label = styled.div`
	font-size: 30px;
	font-family: ${stylingVariables.hrb.fontFamily.roboto};
    font-weight: 400;
	text-align: center;
	margin-bottom: 20px;
    color: ${stylingVariables.hrb.colorPalette.darkGreen};
`;
const StyledLoadingMessage = styled.div`
	color: ${stylingVariables.hrb.colorPalette.deepGray};
	font-size: ${stylingVariables.hrb.fontSize.mediumSmall};
	font-weight: ${stylingVariables.hrb.fontWeight.semibold};
	animation: blink 2.8s infinite both;

	@keyframes blink {
	    0% { opacity: .6; }
	    20% { opacity: 1; }
	    100% { opacity: .6; }
	}
`;

interface IProps {
    loadingMessage: string;
}

class Welcome extends PureComponent<IProps> {
    public render() {
        return <Wrapper>
            <Loader>
                <img src={logo} alt='HR Blizz'/>
                <Label>
                    {intl.get('admin_console')}
                </Label>
                <StyledBackToLaunchpad onClick={RouteHelper.goToLaunchpad}>
                    {intl.get('back_to_launchpad')}
                </StyledBackToLaunchpad>
                <Dots><Dot /><Dot /><Dot /></Dots>

                <StyledLoadingMessage>
                    {this.props.loadingMessage ? intl.get(this.props.loadingMessage) : ''}
                </StyledLoadingMessage>
            </Loader>
        </Wrapper>;
    }
}

const mapStateToProps = (state: RootState) => ({
    loadingMessage: globalSelectors.getLoadingMessage(state),
});

export default connect(mapStateToProps)(Welcome);
