import {ApiObject} from '@api';
import {interfaces} from '@global';
import {createSelector} from 'reselect';
import {RootState} from '../../rootReducer';
import {BasicState} from './redux';
import {ReportsState} from './Reports/redux';
import {ProcessState} from './Process/redux';
import {ReportTemplatesState} from './ReportTemplates/redux';

export const getReportsBasicState = (state: RootState): BasicState => state.reports.basic;
export const getReportsState = (state: RootState): ReportsState => state.reports.reports;
export const getProcessState = (state: RootState): ProcessState => state.reports.process;
export const getReportTemplatesState = (state: RootState): ReportTemplatesState => state.reports.reportTemplates;

export const getGeneratingReportIds = (state: RootState): number[] => state.reports.basic.generatingReportIds;

export const getReportsModal = createSelector(
    [getReportsBasicState],
    (state: BasicState): boolean => {
        return state.chooseReportModal;
    }
);

export const getLegalEntityPeriods = createSelector(
    [getReportsBasicState],
    (state: BasicState): ApiObject.LegalEntityPeriod[] => {
        return state.legalEntityPeriods
    }
);

export const getMasterdataListFields = createSelector(
    [getReportsBasicState],
    (state: BasicState): ApiObject.SelectOption[] => state.masterDataListFields
);
export const getMasterdataQuickAdds = createSelector(
    [getReportsBasicState],
    (state: BasicState): interfaces.IGroupFieldQuickAdd[] => state.masterDataQuickAdds
);
export const getPayElementsListFields = createSelector(
    [getReportsBasicState],
    (state: BasicState): ApiObject.SelectOption[] => state.payElementsListFields
);
export const getPayElementsQuickAdds = createSelector(
    [getReportsBasicState],
    (state: BasicState): interfaces.IGroupFieldQuickAdd[] => state.payElementsQuickAdds
);
export const getFieldGroups = createSelector(
  [getReportsBasicState],
  (state: BasicState): ApiObject.FieldGroup[] => state.fieldGroups
);
