import styled from 'styled-components';
import {stylingVariables} from '@global';

export const CentredWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CardTitle = styled.span`
  font-size: ${stylingVariables.fontSize.large};
  font-weight: ${stylingVariables.fontWeight.regular};
  padding: 5px;
`;

export const Title = styled.span`
  font-size: ${stylingVariables.fontSize.medium};
  font-weight: ${stylingVariables.fontWeight.regular};
  color: ${stylingVariables.colorPalette.deepGray};
  padding: 5px;
`;

export const Subtitle = styled.span`
  font-size: ${stylingVariables.fontSize.mediumSmall};
  font-weight: ${stylingVariables.fontWeight.light};
  color: ${stylingVariables.colorPalette.deepGray};
  padding: 5px;
`;

export const RowLabel = styled.label<{percent?: number, color?: string, top?: number, fontSize?: number}>`
  flex: 0 0 ${props => props.percent || 30}%;
  white-space: nowrap;
  font-size: ${props => props.fontSize ? `${props.fontSize}px` : stylingVariables.fontSize.default};
  color: ${props => props.color || stylingVariables.colorPalette.deepGray};
  font-weight: ${stylingVariables.fontWeight.light};
  text-transform: capitalize;
  align-self: flex-start;
  margin: ${props => props.top ? `${props.top}px` : 'auto'} 10px auto;
`;

export const Row = styled.div<{hasError?: boolean}>`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  font-size: ${stylingVariables.fontSize.medium};
  white-space: nowrap;

  ${props => props.hasError && `
      ${RowLabel} {
        color: red;
      }
  `}
`;

export const RowValue = styled.span<{percent?: number, fontSize?: number}>`
  flex: 0 0 ${props => props.percent || 70}%;
  font-weight: ${stylingVariables.fontWeight.semibold};
  font-size: ${props => props.fontSize ? `${props.fontSize}px` : stylingVariables.fontSize.default};
  align-self: flex-start;
  white-space: pre-wrap;
`;

export const RowDateRangeValue = styled.div<{percent?: number, bordered?: boolean}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 0 0 ${props => props.percent || 70}%;
  font-weight: ${stylingVariables.fontWeight.semibold};
  align-self: flex-start;
  padding: 10px;
  ${props => props.bordered ? 'border: 1px solid' + stylingVariables.colorPalette.gray + ';' : ''}
  border-radius: ${stylingVariables.layout.borderRadius};
`;

export const Rows = styled.div`
  ${Row}:last-child {
     margin-bottom: 0;
  }
`;

export const Popover = styled.div<{top?: number, width?: string, height?: string}>`
  position: absolute;
  top: ${props => props.top || '35px'};
  left: -20px;
  width: ${props => props.width || 'auto'};
  padding: 20px;
  background-color: ${stylingVariables.colorPalette.white};
  box-shadow: ${stylingVariables.layout.boxShadow};
  border-radius: ${stylingVariables.layout.borderRadius};
  z-index: 10;
  &:before {
    content: ' ';
    position: absolute;
    top: -15px;
    left: 15px;
    right: 15px;
    display: block;
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 15px solid ${stylingVariables.colorPalette.lightGreen};
  }
`;
