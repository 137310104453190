import React from 'react';
import {Icon} from '@common';
import {constants, stylingVariables} from '@global';
import {intl} from '@global';
import styled from 'styled-components';

const StyledAddPaymentGroupBlock = styled.div`
     flex: 0 1 240px;
     min-width: 240px;
     flex-direction: column;
     align-content: center;
     justify-content: center;
     position: relative;
     margin: 10px;
     padding: 20px 20px 30px 20px;
     border-radius: 5px;
     background: ${stylingVariables.colorPalette.white};
     border: dashed 2px ${stylingVariables.colorPalette.gray};
     cursor: pointer;
     &:hover{
        border: dashed 2px ${stylingVariables.colorPalette.orange};
     }
`;

const StyledAddPaymentGroupWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 30px auto;
`;

const StyledAddPaymentGroupButton = styled.div`
    display: flex;
    align-content: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: ${stylingVariables.colorPalette.orange};
    box-shadow: ${stylingVariables.layout.boxShadow};
    cursor: pointer;
    border: none;
    outline: none;
    padding: 7px;
    margin: 15px 10px 15px 20px;
`;

const StyledAddPaymentGroupText = styled.div`
    font-size: ${stylingVariables.fontSize.medium};
    font-weight: ${stylingVariables.fontWeight.semibold};
    color: ${stylingVariables.colorPalette.orange};
`;

interface IProps {
    action: () => void;
}

const Block: React.FC<IProps> = ({action}: IProps) => {
   return <StyledAddPaymentGroupBlock onClick={action}>
        <StyledAddPaymentGroupWrapper>
            <StyledAddPaymentGroupButton>
                <Icon type={constants.IconTypes.BOLD_ADD}
                      width={15}
                      height={15}
                      fillColor={stylingVariables.colorPalette.white}/>
            </StyledAddPaymentGroupButton>
            <StyledAddPaymentGroupText>
                {intl.get('add_pay_element_type')}
            </StyledAddPaymentGroupText>
        </StyledAddPaymentGroupWrapper>
    </StyledAddPaymentGroupBlock>;
};

export default Block;
