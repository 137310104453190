import React, {PureComponent} from 'react';
import {intl, constants} from '@global';
import {stylingVariables} from '@global';

import {toast} from 'react-toastify';
import {DragAndDropFiles, Layout, Icon} from '@common';
import styled from 'styled-components';

const StyledEmptyRenderer = styled.div`
    margin: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    div {
      margin-top: 10px;
      font-size: 14px;
      font-weight: 600;
      color: ${stylingVariables.colorPalette.darkest};
    }
    span {
      margin-top: 10px;
      font-size: 12px;
      font-weight: 400;
      color: ${stylingVariables.colorPalette.darkestGray};
    }
`;

interface IProps {
    onChange: (file: File | null) => any;
    errors?: string[];
}

export default class FileRow extends PureComponent<IProps> {
    private validateFileName = (name: string): boolean => {
        // eslint-disable-next-line no-control-regex
        const nonAsciiPattern = /[^\x00-\x7F]/
        if (nonAsciiPattern.test(name)) {
            toast.error(intl.get('file_name_must_not_contain_non_ascii'))
            return false
        }

        return true
    }
    
    private removeFile = (files: File[], index: number): void => {
        if (files[index]) {
            files.splice(index, 1)
        }
    }
    
    private onDropFiles = (files: File[] | null) => {
        if (files && files.length > 0) {
            const isValidFileName = this.validateFileName(files[0].name)
            
            if (isValidFileName) {
                this.props.onChange(files[0])
            } else {
                this.removeFile(files, 0)
            }
        } else if (files && files.length === 0) {
            this.props.onChange(null)
        }
    }

    public render() {
        return (
            <Layout.Row>
                <Layout.RowLabel percent={30} color={this.props.errors ? stylingVariables.colorPalette.red : undefined}>
                    {intl.get('file')}*
                </Layout.RowLabel>
                <Layout.RowValue percent={70}>
                    <DragAndDropFiles
                        onChange={this.onDropFiles}
                        multiple={false}
                        gentTemplate={true}
                        emptyRenderer={() =>
                            <StyledEmptyRenderer>
                                <Icon
                                    type={constants.IconTypes.FILE_DOWNLOAD}
                                    height={20}
                                    width={20}
                                    fillColor={stylingVariables.colorPalette.green}
                                />
                                <div>
                                    {intl.get('drag_and_drop')}
                                </div>
                                <span>
                                    {intl.get('your_file_to_upload_or_browse')}
                                </span>
                            </StyledEmptyRenderer>
                        }
                    />
                </Layout.RowValue>
            </Layout.Row>
        );
    }
}
