import React, {PureComponent} from 'react';
import {intl} from '@global';

import {stylingVariables} from '@global';
import {InlineDateRangePicker, Layout} from '@common';
import styled from 'styled-components';

const ErrorMessage = styled.div`
    color: ${stylingVariables.colorPalette.red};
    width: 100%;
    white-space: pre-wrap;
    margin-top: 10px;
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

interface RowSelectDaysProps {
    defaultFrom?: string|null;
    defaultTill?: string|null;

    onChange: (from?: string|null, till?: string|null) => any;
    errors?: string[];
    isStartDateDisabled?: boolean;
}

export default class PeriodRow extends PureComponent<RowSelectDaysProps> {
    private onChange = (from?: string|null, till?: string|null): void => {
        this.props.onChange(from, till);
    }

    public render() {
        return (
            <Layout.Row>
                <Layout.RowLabel
                    percent={20}
                    top={14}
                    color={this.props.errors ? stylingVariables.colorPalette.red : undefined}
                >
                    {intl.get('select_days')}*
                </Layout.RowLabel>
                <Wrapper>
                    <Layout.RowDateRangeValue percent={70} bordered={true}>
                        <InlineDateRangePicker
                            from={this.props.defaultFrom}
                            till={this.props.defaultTill}
                            onChange={this.onChange}
                            isStartDateDisabled={this.props.isStartDateDisabled || false}
                        />
                    </Layout.RowDateRangeValue>
                    {this.props.errors ? <ErrorMessage>{this.props.errors[0]}</ErrorMessage> : null}
                </Wrapper>
            </Layout.Row>
        );
    }
}
