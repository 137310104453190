import {LeaveInterfaces} from '@api';
import moment, {Moment} from 'moment';

export const onLeave = (t: LeaveInterfaces.Transaction, start: Moment, end: Moment) => {
    if (LeaveInterfaces.TransactionStatus.done !== t.status) {
        return false;
    }

    return isInPeriod(t, start, end);
}

export const onLeaveByType = (
    t: LeaveInterfaces.Transaction,
    typeId: number,
    start: Moment,
    end: Moment,
) => {
    if (LeaveInterfaces.TransactionStatus.done !== t.status) {
        return false;
    }

    if (t.leaveTypeId !== typeId) {
        return false;
    }

    return isInPeriod(t, start, end);
}

export const isNotRejected = (t: LeaveInterfaces.Transaction, start: Moment, end: Moment) => {
    if (LeaveInterfaces.TransactionStatus.rejected === t.status) {
        return false;
    }

    return isInPeriod(t, start, end);
}

export const isInPeriod = (t: LeaveInterfaces.Transaction, start: Moment, end: Moment) => {
    if (moment(t.startDate, 'YYYY-MM-DD').isAfter(end)) {
        return false;
    }

    if (t.endDate && moment(t.endDate as string, 'YYYY-MM-DD').isBefore(start)) {
        return false;
    }

    return true;
};
