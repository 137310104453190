import {ApiObject} from '@api';
import React, {PureComponent} from 'react';
import styled from 'styled-components';

import {stylingVariables} from '@global';
import {FormatNumbers} from '@common';
import {DateHelper} from '@library';

export const StyledFieldRow = styled.div`
    margin: 8px 0;
    font-size: ${stylingVariables.fontSize.default};
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    label {
        display: inline-block;
        width: 250px;
        line-height: 25px;
    }
    label + span {
        font-weight: bold;
        color: ${stylingVariables.colorPalette.darkest};
    }
    .react-datepicker-wrapper input {
        border: none;
        width: 100%;
        padding: 0 15px;
        font-weight: bold;
    }
`;

const StyledLabel = styled.label`
    color: ${stylingVariables.colorPalette.deepGray};
`;

interface IProps {
    field: ApiObject.Field;
    value: any;
}

export default class Field extends PureComponent<IProps> {
    public render() {
        const label = this.props.field.label;
        return (
            <StyledFieldRow>
                <StyledLabel>{this.props.field.required ? `${label} *` : label}</StyledLabel>

                {this.renderValue(this.props.field)}
            </StyledFieldRow>
        );
    }

    private renderValue(field: ApiObject.Field) {
        if (field.options) {
            const option: ApiObject.SelectOption|undefined = field.options.find((x: ApiObject.SelectOption) => {
                return x.key === this.props.value;
            });

            if (option) {
                return <span>{option.label}</span>;
            }
        }

        if (field.type === ApiObject.FieldType.date) {
            return <span>{DateHelper.convertDateToHumanFormat(this.props.value) || ''}</span>;
        }

        if (field.type === ApiObject.FieldType.number || field.type === ApiObject.FieldType.decimal ) {
            return <span><FormatNumbers value={this.props.value|| null}/></span>
        }

        return <span>{this.props.value || '-'}</span>;
    }
}
