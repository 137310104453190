import { history, constants } from '@global'
import { EnvironmentHelper } from '@library'

const historyPushOrReload = (path: string) => {
  if (history.location.pathname === path) {
    history.go(0)
  } else {
    history.push(path)
  }
}

class RouteHelper {
  public static go(route: string): void {
    history.push(route)
  }

  public static goBack(): void {
    history.goBack()
  }

  public static goToEntitiesPage(): void {
    history.push(constants.ROUTE_ENTITIES)
  }

  public static goToEmployeesPage(): void {
    history.push(constants.ROUTE_EMPLOYEES)
  }

  public static goToEmployeeCreatePage(): void {
    historyPushOrReload(constants.ROUTE_EMPLOYEE.replace(':id', `create`))
  }

  public static goToEmployeePage(id: number, tab?: string): void {
    let route = constants.ROUTE_EMPLOYEE.replace(':id', `${id}`)
    if (tab) {
      route += `/${tab}`.replace('wizard_step:', '')
    }
    historyPushOrReload(route.replace(':', '_'))
  }

  public static goToLaunchpad(): void {
    window.location.replace(EnvironmentHelper.getLaunchpadUrl() + '/launchpad')
  }

  public static goToLogoutRoute(): void {
    window.location.replace(EnvironmentHelper.getLogoutUrl())
  }

  public static goToReports(): void {
    historyPushOrReload(constants.ROUTE_REPORTS)
  }

  public static goToReportTemplates(): void {
    historyPushOrReload(constants.ROUTE_REPORT_TEMPLATES)
  }

  public static goToReportSelectEmployees(): void {
    historyPushOrReload(constants.ROUTE_REPORTS_PROCESS_SELECT_EMPLOYEES)
  }

  public static goToReportSelectData(): void {
    historyPushOrReload(constants.ROUTE_REPORTS_PROCESS_SELECT_DATA)
  }

  public static goToReportPreview(): void {
    historyPushOrReload(constants.ROUTE_REPORTS_PROCESS_PREVIEW)
  }

  public static goToReportExport(): void {
    historyPushOrReload(constants.ROUTE_REPORTS_PROCESS_EXPORT)
  }
}

export default RouteHelper
