import React, {PureComponent, Fragment} from 'react';
import {connect} from 'react-redux';
import {LeaveInterfaces, EmployeeInterfaces} from '@api';
import {Actions as CommonActions, Modal, Can} from '@common';
import {constants, intl} from '@global';

interface IProps {
    employee: EmployeeInterfaces.Employee;
    transaction: LeaveInterfaces.Transaction;
    masterdataEditAllowed?: boolean;

    onClickApprove: (employeeId: number, transaction: LeaveInterfaces.Transaction) => void;
    method: string;
}

interface IState {
    isOpenConfirmationModal: boolean;
}

export class ApproveAction extends PureComponent<IProps, IState> {
    public constructor(props: IProps) {
        super(props);

        this.state = {
            isOpenConfirmationModal: false,
        };
    }

    private approve = () => {
        this.props.onClickApprove(this.props.employee.id, this.props.transaction);
        this.closeConfirmationModal();
    }

    private openConfirmationModal = () => {
        this.setState({isOpenConfirmationModal: true});
    }

    private closeConfirmationModal = () => {
        this.setState({isOpenConfirmationModal: false});
    }

    private approveLink =
        <CommonActions.Action onClick={this.openConfirmationModal}>
            {intl.get('approve')}
        </CommonActions.Action>

    private approveButton =
        <Modal.StyledApproveButton onClick={this.openConfirmationModal}>
            {intl.get('approve')}
        </Modal.StyledApproveButton>


    private approveAllButton =
        <Modal.StyledApproveButton onClick={this.openConfirmationModal}>
            {intl.get('approve_all')}
        </Modal.StyledApproveButton>

    render() {
        let approveAll = false;
        let approveMethod
        switch(this.props.method) {
            case 'approveLink':
                approveMethod = this.approveLink;
                break;
            case 'approveButton':
                approveMethod = this.approveButton;
                break;
            case 'approveAllButton':
                approveAll = true;
                approveMethod = this.approveAllButton;
                break;
            default:
                approveMethod = this.approveLink;

        }

        return (
            <Fragment>
                <Can run={constants.PermissionCode.LEAVES_EDIT_LEAVE} disabled={!this.props.masterdataEditAllowed}>
                    { approveMethod }
                </Can>

                <Modal.ConfirmationModal
                    title={'approve'}
                    confirmationMessage={approveAll ? 'approve_all_leave_transactions_confirmation' : 'approve_leave_transaction_confirmation'}
                    acceptBtnTitle={'approve'}
                    isOpen={this.state.isOpenConfirmationModal}
                    onClickAccept={this.approve}
                    onClickClose={this.closeConfirmationModal}
                />
            </Fragment>
        );
    }
};

const mapDispatchToProps = {};

export default connect(null, mapDispatchToProps)(ApproveAction);
