import React from 'react';
import styled from 'styled-components';

const StyledRow = styled.div`
  &.ReactVirtualized__Table__row{
    &.salary-child{
        align-items: center;
        padding: 0;
        >.ReactVirtualized__Table__rowColumn{
            height: 100%!important;
            padding: 5px 20px;
            background-color: transparent;
            box-sizing: padding-box;
            &:first-child{
                border-bottom: none;
                background-repeat: no-repeat;
                background-position: 100% -5%;
                background-size: 46px 46px;
            }
            &:last-child{
                border-radius: 0!important;
            }
        }
    }
    &.salary + .salary-child{
        >.ReactVirtualized__Table__rowColumn{
             &:first-child{
                border-top: none;
            }
        }
    }
  }
`;


export const Row = (action?: (values: any) => void) => ({ className, columns, key, style, rowData}: any) => (
    <StyledRow
        className={rowData.childElement ? `${className} salary-child` : `${className} salary`}
        key={key}
        role='row'
        style={{ ...style,height: style.height - 2}} //Gap between rows
        onClick={() => action && action(rowData)}
    >
        {columns}
    </StyledRow>
);
