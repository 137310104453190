import moment from 'moment'
import {ApiObject} from '@api';

function capitalize(string: string): string {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export function dateHumanFullFormat(date: string): string {
  if (!date) {
    return ''
  }
  return capitalize(moment(date).format('MMMM DD, YYYY'))
}

export function numberFormat(value: number | string, ignoreTrailing: boolean = false ): string {
  const number = (typeof value === 'string') ? parseFloat(value) : value
  const decimals = 2
  const decpoint = '.'
  const thousand = ','

  const splited = Math.abs(number).toFixed(decimals).split('.')

  splited[0] = splited[0].split('').reverse().map((c, i, a) =>
    i > 0 && i < a.length && i % 3 === 0 ? c + thousand : c
  ).reverse().join('')

  if (ignoreTrailing && splited[1] && splited[1] === '00') {
    splited.splice(splited.indexOf('00'), 1)
  }

  const final = (Math.sign(number) < 0 ? '-' : '') + splited.join(decpoint)

  return final
}

export const DATE_FORMAT = 'YYYY-MM-DD'

export const INPUT_TYPE = {
  TEXT: 'text',
  SELECT: 'select',
  DATE: 'date',
  NUMBER: 'number'
}

export const EDIT_MODE = {
  READ: 'read',
  WRITE: 'write'
}


export const getInputType = (field: ApiObject.BankField): string => {
  let inputType = ApiObject.FieldType.text

  if (field.dataType === 'SELECTION' && field.selectionChoices) {
    inputType = ApiObject.FieldType.dropdown
  }

  if (field.dataType === 'DATE') {
    inputType = ApiObject.FieldType.date
  }

  if (field.dataType === 'NUMBER') {
    inputType = ApiObject.FieldType.text
  }

  return inputType
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getReadableValue = (field: ApiObject.BankField, value: string): any => {
  const inputType = getInputType(field)

  switch (inputType) {
    case INPUT_TYPE.SELECT:
      return field.selectionChoices?.find(c => c.label === value)?.label
    case INPUT_TYPE.DATE:
      return dateHumanFullFormat(value)
    case INPUT_TYPE.NUMBER:
      return numberFormat(value)
    default:
      return value
  }
}
