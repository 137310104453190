import React, {PureComponent} from 'react';
import {intl, stylingVariables} from '@global';

import {ApiObject} from '@api';
import {Modal as CommonModal} from '@common';
import EditableField from './EditableField';
import {getErrorByEntityAndCode} from '../../../selectors';
import styled from 'styled-components';

const StyledHeader = styled(CommonModal.StyledHeader)`
  border: none;

  h4 {
    font-size: ${stylingVariables.fontSize.big};
    font-weight: ${stylingVariables.fontWeight.bold};
    color: ${stylingVariables.colorPalette.darkest};
  }
`;

export const StyledActionWrapper = styled(CommonModal.StyledActionBar)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  border: none;
`;

interface IProps {
    group: ApiObject.FieldGroup;
    errors: ApiObject.EntityFieldErrorMessage[];

    onClickSave: (fields: ApiObject.FieldValue[]) => void;
    onClickClose: () => void;
}

interface IState {
    updatedFields: ApiObject.FieldValue[];
}

export default class Modal extends PureComponent<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            updatedFields: [],
        };
    }

    render() {
        return (
            <CommonModal.CenterModal onClickClose={this.props.onClickClose} width={'600px'}>
                <StyledHeader>
                    <h4>{intl.get('add_dependent')}</h4>
                </StyledHeader>

                <CommonModal.OverflowableContent padding={'20px 40px'}>
                    {
                        this.props.group.fields.map((field: ApiObject.Field) => (
                            <EditableField
                                key={`${field.entity}_${field.code}`}
                                field={field}
                                errorMessage={
                                    getErrorByEntityAndCode(this.props.errors, field.entity, field.code)
                                }
                                onChange={this.handleChangeField}
                            />
                        ))
                    }
                </CommonModal.OverflowableContent>

                <StyledActionWrapper>
                    <CommonModal.StyledCancelButton onClick={this.props.onClickClose}>
                        {intl.get('cancel')}
                    </CommonModal.StyledCancelButton>

                    <CommonModal.StyledOkButton
                        onClick={this.handleClickSave}
                        isDisabled={this.validateRequiredFilled()}
                    >
                        {intl.get('save')}
                    </CommonModal.StyledOkButton>
                </StyledActionWrapper>
            </CommonModal.CenterModal>
        );
    }

    private handleClickSave = () => this.props.onClickSave(this.state.updatedFields);

    private validateRequiredFilled = () => {
        const requiredUnfilledFields = this.props.group.fields.find(field =>
            field.required &&
            !this.state.updatedFields.find(updatedField => updatedField.code === field.code),
        );
        return !!requiredUnfilledFields;
    }

    private handleChangeField = (field: ApiObject.Field, value: any) => {
        if (!value || value.length === 0) {
            value = null;
        }

        const updatedFields = [...this.state.updatedFields];
        const existedField = updatedFields.find(item => item.code === field.code);
        if (!existedField) {
            updatedFields.push({code: field.code, value});
        } else {
            existedField.value = value;
        }

        this.setState({updatedFields});
    }
}
