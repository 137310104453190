import {ApiObject} from '@api';
import {interfaces} from '@global';
import React, {PureComponent} from 'react';
import {Column} from 'react-virtualized';
import styled from 'styled-components';

import {defaultInfiniteTableCellDataGetter, InfiniteTableHeader} from '@common';

const StyledCellWrapper = styled.div`
    display: flex;
    flex-direction: row;
    margin-left: 15px;
`;

interface IProps {
    cellData?: ApiObject.Report;
    listTemplates: ApiObject.SelectOption[];

    orders: interfaces.ListingOrders;
    filters: interfaces.ListingFilters;
    changeOrder: (column: string) => any;
    changeFilter: (params: interfaces.FilterParams) => any;

    label: string;
    width?: number;
    flexGrow?: number;
}

interface ICellProps {
    cellData?: ApiObject.Report;
    listTemplates: ApiObject.SelectOption[];
}

class Cell extends PureComponent<ICellProps> {
    get templateLabel() {
        if (!this.props.cellData) {
            return '-';
        }
        return (
            this.props.listTemplates.find(
                    o => +o.key === (this.props.cellData as ApiObject.Report).templateId
                )||{label: '-'}).label;
    }

    public render() {
        if (!this.props.cellData) {
            return null;
        }
        return (
            <StyledCellWrapper>
                {this.templateLabel}
            </StyledCellWrapper>
        );
    }
};

const ReportTemplate: React.FC<IProps> = ({label, width = 200, flexGrow = 1, orders, changeOrder, filters, changeFilter, listTemplates}: IProps) => (
    <Column
        dataKey={`templateId`}
        label={label}
        width={width}
        flexGrow={flexGrow}
        cellDataGetter={defaultInfiniteTableCellDataGetter}
        headerRenderer={() => (
            <InfiniteTableHeader.OrderedAndFiltered
                label={label}
                type={ApiObject.FieldType.dropdown}
                options={listTemplates}
                dataKey={'templateId'}
                orders={orders}
                changeOrder={changeOrder}
                filters={filters}
                changeFilter={changeFilter}
            />
        )}
        cellRenderer={({cellData}) => <Cell cellData={cellData} listTemplates={listTemplates} />}
    />
);

export default ReportTemplate;
