import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';

import {constants, stylingVariables} from '@global';
import {Icon, BlankButton} from '@common';

import {openColumnSettingsModal} from '../../redux';

const ColumnSettingsButton = styled(props => <BlankButton {...props} />)`
  cursor: pointer;
  float: right;
`;

interface SettingsProps {
    openColumnSettingsModal: () => void;
}

export class Settings extends PureComponent<SettingsProps> {
    public render() {
        return (
            <ColumnSettingsButton onClick={this.props.openColumnSettingsModal}>
                <Icon type={constants.IconTypes.SETTINGS}
                      width={15} height={15}
                      fillColor={stylingVariables.colorPalette.secondary}
                />
            </ColumnSettingsButton>
        );
    }
}

const mapDispatchToProps = {
    openColumnSettingsModal,
};

export default connect(null, mapDispatchToProps)(Settings);
