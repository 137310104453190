import {DateHelper} from '@library';
import React, {PureComponent} from 'react';
import {Moment} from 'moment';
import styled from 'styled-components';

import {constants, stylingVariables, interfaces} from '@global';
import {Icon} from '@common';
import MultiMonthPicker from './Date/MultiMonthPicker';

const StyledArrow = styled.span`
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    float: right;
    right: 0;
    top: 0;
    bottom: 0;
    overflow: hidden;
    height: auto;
    padding: 11px 12px;
    border-radius: 0 ${stylingVariables.layout.inputBorderRadius} ${stylingVariables.layout.inputBorderRadius} 0;
    background: ${stylingVariables.colorPalette.gray};
    cursor: pointer;
`;

const Wrapper = styled.div<{ hasError: boolean }>`
    border: 1px solid ${stylingVariables.colorPalette.darkGray};
    ${props => props.hasError && `
        border: 1px solid ${stylingVariables.colorPalette.red};
    `}
    border-radius: ${stylingVariables.layout.inputBorderRadius};
    min-width: 320px;
    font-weight: bold;
    position: relative;
    display: inline-block;
    width: 100%;
    height: 42px;

    input{
      pointer-events: none;
      user-focus: none;
      user-select: none;
      width: 100%;
      height: 100%;
      border: none;
      padding: 0 20px;
      font-size: ${stylingVariables.fontSize.mediumLarge};
      font-weight: ${stylingVariables.fontWeight.semibold};
      color: ${stylingVariables.colorPalette.dark};
    }
`;
const StyledInput = styled.input``;

const MonthWrapper = styled.div`
   position: absolute;
   top: 40px;
   left: -1px;
   border: 1px solid ${stylingVariables.colorPalette.darkGray};
   z-index: 1;
`;

interface IProps {
    allowPeriods: interfaces.Periods;
    periods: interfaces.Periods;
    onChange: (periods: interfaces.Periods) => void;
    onCancel?: () => void;

    multiselect?: boolean;
    disableAfterDate?: string|Moment;
    disableBeforeDate?: string|Moment;

    hasError?: boolean;
}

interface IState {
    isOpen: boolean;
}

export default class MultiMonthField extends PureComponent<IProps, IState> {
    private wrapperRef: HTMLDivElement | null = null;
    private pickerRef: HTMLDivElement | null = null;

    static defaultProps = {
        allowPeriods: [],
        periods: []
    }

    public constructor(props: IProps) {
        super(props);

        this.state = {
            isOpen: false,
        };
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    public render() {
        return (
            <Wrapper hasError={!!this.props.hasError}
                     ref={_ref => this.wrapperRef = _ref}
                     onClick={this.toggle}
            >
                {this.state.isOpen &&
                    <MonthWrapper ref={_ref => this.pickerRef = _ref}>
                        <MultiMonthPicker
                            allowPeriods={this.props.allowPeriods}
                            disableBeforeDate={this.props.disableBeforeDate}
                            periods={this.props.periods}
                            onSave={this.save}
                            onCancel={this.cancel}
                            multiselect={this.props.multiselect}
                        />
                    </MonthWrapper>
                }

                <StyledInput
                    readOnly={true}
                    value={DateHelper.convertPeriodsToHumanFormat(this.props.periods)}
                />

                <StyledArrow>
                    <Icon type={constants.IconTypes.CALENDAR}
                          fillColor={stylingVariables.colorPalette.dark}
                          height={15}
                          width={15}
                    />
                </StyledArrow>
            </Wrapper>
        );
    }

    private save = (periods: interfaces.Periods) => {
        this.props.onChange(periods);
        this.setState({isOpen: false});
    }

    private cancel = () => {
        this.setState({isOpen: false});
    }

    private toggle = (event: any) => {
        if (this.state.isOpen && this.pickerRef && this.pickerRef.contains(event.target)) {
            return;
        }

        this.setState({isOpen: !this.state.isOpen});
    }

    handleClickOutside = (event: any) => {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.setState({isOpen: false});
        }
    }
}
