import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import moment, {Moment} from 'moment';

import {MonthPicker} from '@common';

import {RootState} from '../../rootReducer';
import {setPeriodFilter, clearAndGetEmployeeBalances} from './redux';
import * as selectors from './selectors';

interface IProps {
    from: Moment;
    till: Moment;

    setPeriodFilter: (from: Moment, till: Moment) => void;
    clearAndGetEmployeeBalances: () => void;
}

export class Period extends PureComponent<IProps> {
    public render() {
        return (
            <MonthPicker
                selected={this.props.from}
                onChangeDate={this.onChangeSelectedPeriod}
                popperPlacement={'bottom-end'}
            />
        );
    }

    private onChangeSelectedPeriod = (selected: Moment) => {
        const from = moment(selected).startOf('month');
        const till = moment(selected).endOf('month');
        this.props.setPeriodFilter(from, till);
        this.props.clearAndGetEmployeeBalances();
    }
}

const mapStateToProps = (state: RootState, ownProps: any) => ({
    from: selectors.getPeriodFilterFrom(state, ownProps),
    till: selectors.getPeriodFilterTill(state, ownProps),
});

const mapDispatchToProps = {
    setPeriodFilter,
    clearAndGetEmployeeBalances,
};

export default connect(mapStateToProps, mapDispatchToProps)(Period);
