import {EmployeeInterfaces} from '@api';
import {intl} from '@global';

export default class NamesHelper {
    public static convertEssStatus(status?: EmployeeInterfaces.EssStatus|null) {
        switch (status) {
            case EmployeeInterfaces.EssStatus.account_created:
                return intl.get('active');
            case EmployeeInterfaces.EssStatus.invitation_sent:
                return intl.get('pending');
            default:
                return intl.get('inactive');
        }
    }
}
