import {constants} from '@global';
import React, {PureComponent, Fragment} from 'react';
import {intl} from '@global';

import {Actions, Can, Modal} from '@common';

interface IProps {
    forEmployeeId: number;
    isLoading: boolean;
    cuttOffs?: Object|null;
    onClickRemovePendingTermination: (id: number) => void;
}

interface IState {
    isOpenConfirmationModal: boolean;
}

export default class RemovePendingTermination extends PureComponent<IProps, IState> {
    public constructor(props: any) {
        super(props);

        this.state = {
            isOpenConfirmationModal: false,
        };
    }

    render() {
        return (
            <Fragment>
                <Can cuttOffs={this.props.cuttOffs} run={constants.PermissionCode.EMPLOYEES_ACTION_SUSPEND}>
                    <Actions.Action onClick={this.openConfirmationModal} isDisabled={this.props.isLoading} >
                        {intl.get('remove_pending_termination')}
                    </Actions.Action>
                </Can>

                <Modal.ConfirmationModal
                    title={'remove_pending_termination'}
                    confirmationMessage={'remove_pending_termination_confirmation'}
                    acceptBtnTitle={'remove_pending_termination'}
                    isOpen={this.state.isOpenConfirmationModal}
                    onClickAccept={this.removeTermination}
                    onClickClose={this.closeConfirmationModal}
                />
            </Fragment>
        );
    }

    private removeTermination = () => {
        this.closeConfirmationModal();

        this.props.onClickRemovePendingTermination(this.props.forEmployeeId);
    }

    private openConfirmationModal = () => {
        this.setState({isOpenConfirmationModal: true});
    }

    private closeConfirmationModal = () => {
        this.setState({isOpenConfirmationModal: false});
    }
}
