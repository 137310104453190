import {ApiObject} from '@api';
import {interfaces} from '@global';
import {createSelector} from 'reselect';
import {ReportVisibility} from '../constants';
import {getReportsState} from '../selectors';
import {ReportsState} from './redux';

export const getFilterSearch = createSelector(
    [getReportsState],
    (state: ReportsState): string => state.filterSearch
);

export const getFilterYear = createSelector(
    [getReportsState],
    (state: ReportsState): number => state.filterYear
);
export const getFilterVisibility = createSelector(
    [getReportsState],
    (state: ReportsState): ReportVisibility => state.filterVisibility
);
export const getReports = createSelector(
    [getReportsState],
    (state: ReportsState): ApiObject.Report[] => {
        return state.reports.map((report, index) => {
            return {...report, index};
        })
    }
);
export const getReportsTotalCount = createSelector(
    [getReportsState],
    (state: ReportsState): number => state.reportsTotalCount
);

export const getFilters = createSelector(
    [getReportsState],
    (state: ReportsState): interfaces.ListingFilters => state.filters
);
export const getOrders = createSelector(
    [getReportsState],
    (state: ReportsState): interfaces.ListingOrders => state.orders
);

