import {ApiObject} from '@api';
import {Actions, Back} from '@common';
import {stylingVariables, constants, intl} from '@global';
import {RouteHelper, ReportTemplatesHelper} from '@library';
import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';
import {RootState} from '../../../../rootReducer';
import {isReportProcesses} from '../../utils';
import VisibilitySelector from '../common/VisibilitySelector/VisibilitySelector';
import {exportReport, displayReport} from '../redux';
import * as selectors from '../selectors';
import {SpreadsheetGridEditable, SpreadsheetGridNotEditable} from '../../../../common/SpreadsheetGrid';
import {AnimationStyles, SpinnerIcon} from '../../../Loader';
import {ToggleCheckbox} from 'hrb-common';
const Wrapper = styled.div`
	position: relative;
	height: 100%;
	display: flex;
	flex-direction: column;
`;
const Heading = styled.div`
  margin: 20px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const HeadingBreadCrumbs = styled.div`
	display: inline-flex;
  	align-items: center;
`;

const HeadingOptions = styled.div`
	display: inline-flex;
  	align-items: center;
`;

const Main = styled.div`
	background-color: #fff;
	border-radius: 6px;
	height: calc(100% - 120px);
	padding: 20px;
	z-index: 1;
`;
const ExcelPreview = styled.div`
	height: 100%;
	width: 100%;
	background-color: ${stylingVariables.colorPalette.darkGray};
	border-radius: 6px;

	display: flex;
	align-items: center;
	justify-content: center;

	color: ${stylingVariables.colorPalette.darkest};
	font-size: ${stylingVariables.fontSize.default};
	font-weight: ${stylingVariables.fontWeight.semibold};
`;

const Title = styled.div`
  margin-left: 10px;
  font-weight: ${stylingVariables.fontWeight.semibold};
  font-size: ${stylingVariables.fontSize.mediumLarge};
  .page_title {
    font-size: ${stylingVariables.fontSize.largest};
  }
`;
const Footer = styled.div`
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  bottom: -20px;
  left: 0;
  right: 0;
  height: 60px;
  border-radius: 10px 10px 0 0;
  box-shadow: ${stylingVariables.layout.boxShadow};
  background-color: #fff;
`;
const StyledBack = styled.div`
  display: flex;
  align-items: center;
  span {
    margin-left: 5px;
    font-size: ${stylingVariables.fontSize.mediumLarge};
    color: ${stylingVariables.colorPalette.green}
  }
`;

const StyledButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const StyledNotLoaded = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;

const StyledRefreshLabel = styled.div`
	font-size: 18px;
	font-family: ${stylingVariables.hrb.fontFamily.roboto};
    font-weight: 400;
	text-align: center;
	margin-bottom: 20px;
    color: ${stylingVariables.hrb.colorPalette.darkGreen};
`;

const StyledRefresh = styled.span`
    outline: none;
    box-shadow: none;
    cursor: pointer;
    color: ${stylingVariables.colorPalette.dark};
    padding: 10px 20px;
    border: 1px solid ${stylingVariables.colorPalette.dark};
    border-radius: 20px;
    font-size: ${stylingVariables.fontSize.default};
    margin: 0 10px;
    &:hover {
        box-shadow: ${stylingVariables.layout.boxShadow};
    }
`;

interface IProps {
    mode: constants.ReportProcessMode;
    report: ApiObject.Report|null;
    exportReport: (id: number, fileName?: string) => void;
    displayReport: (id: number) => void;
    spreadsheetData: any;
    isLoadingSpreadsheet: boolean;
}

interface IState {
    isSpreadsheetEdit: boolean;
}

export class Export extends PureComponent<IProps, IState> {
    public constructor(props: IProps) {
        super(props);

        this.state = {
            isSpreadsheetEdit: true,
        };
    }

    componentDidMount() {
       this.loadReport();
    }

    loadReport = () => {
        if (this.props.report) {
            this.props.displayReport(this.props.report.id);
        }
    }

    renderSpreadsheetGrid = () => {
        return this.state.isSpreadsheetEdit ?
            <SpreadsheetGridEditable data={this.props.spreadsheetData}/> :
            <SpreadsheetGridNotEditable data={this.props.spreadsheetData}/>;
    }

    renderLoader = () => {
        return this.props.isLoadingSpreadsheet ?
            <SpinnerIcon/> :
            <StyledNotLoaded>
                <StyledRefreshLabel>{intl.get('report_not_generated')}</StyledRefreshLabel>
                <StyledRefresh onClick={this.loadReport}>
                    {intl.get('please_try_again')}
                </StyledRefresh>
            </StyledNotLoaded>;
    }

    public render() {
        return (
            <Wrapper>
                <AnimationStyles />
                <Heading>
                    <HeadingBreadCrumbs>
                        <Back.Button />
                        <Title>
                            <span className={'page_title'}>{ReportTemplatesHelper.pageTitleByMode(this.props.mode)} / </span> {this.props.report?.label}
                        </Title>
                    </HeadingBreadCrumbs>

                    <HeadingOptions>
                        <ToggleCheckbox
                            isChecked={this.state.isSpreadsheetEdit}
                            labelOptions={{yes: intl.get('edit_mode'), no: intl.get('edit_mode')}}
                            onChange={(bool: boolean) => {
                                this.setState({isSpreadsheetEdit: bool});
                            }} />
                        {isReportProcesses(this.props.mode) && <VisibilitySelector forReport={true} />}
                    </HeadingOptions>
                </Heading>

                <Main>
                    <ExcelPreview>
                        {this.props.spreadsheetData ? this.renderSpreadsheetGrid() : this.renderLoader()}
                    </ExcelPreview>
                </Main>

                <Footer>
                    <div>
                        {this.props.mode === constants.ReportProcessMode.createReport && <StyledBack>
                            <Back.Button onGoBack={RouteHelper.goBack}/>
                            <span>{intl.get('back_to_edit')}</span>
                        </StyledBack>}
                    </div>
                    <StyledButtonsWrapper>
                        <Actions.GrayButton onClick={RouteHelper.goToReports}>
                            {intl.get('close')}
                        </Actions.GrayButton>
                        {this.props.isLoadingSpreadsheet && <SpinnerIcon size={20} />}
                        <Actions.OrangeButton onClick={
                            () => this.props.report
                              ? this.props.exportReport(this.props.report.id)
                              : null
                        }>
                            {intl.get('export')}
                        </Actions.OrangeButton>
                    </StyledButtonsWrapper>
                </Footer>
            </Wrapper>
        );
    }
}

const mapStateToProps = (state: RootState) => ({
    mode: selectors.getMode(state),
    report: selectors.getReport(state),
    spreadsheetData: selectors.getSpreadsheetData(state),
    isLoadingSpreadsheet: selectors.isLoadingSpreadsheet(state),
});

const mapDispatchToProps = {
    exportReport,
    displayReport,
};

export default connect(mapStateToProps, mapDispatchToProps)(Export);
