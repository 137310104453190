import {stylingVariables} from '@global';
import styled from 'styled-components';

export const Container = styled.div<{isExpanded: boolean}>`
  position: absolute;
  left: 55px;
  top: 5px;
  z-index: 5;
`;
export const DropdownContainer = styled.div<{isExpanded: boolean}>`
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  font-size: ${stylingVariables.fontSize.large};
  color: ${stylingVariables.colorPalette.darkest};
  font-weight: ${stylingVariables.fontWeight.semibold};
  background-color: transparent;
  cursor: pointer;
  padding: 5px 25px;
  border-radius: 14px;
  width: 100%;
  user-select: none;
  &:hover {
    background-color: rgba(255,255,255,0.6);
  }
`;

export const DropdownIcon = styled.div`
  margin-left: 25px;
`;
export const DropdownWrapper = styled.div<{isExpanded: boolean}>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 30px;
  background-color: transparent;
  min-width: 150px;
  transition: all .2s;
  ${props => props.isExpanded && `
    height: 600px;
    background-color: #fff;
    border-radius: 6px;
    box-shadow: ${stylingVariables.layout.boxShadow};
    min-width: 300px;
    padding-top: 10px;
  `}
`;
export const PopoverWrapper = styled.div`
  height: 100%;
  width: 100%;
  padding: 15px;
  overflow: hidden;
`;
export const PopoverContainer = styled.div<{isLoading: boolean}>`
    height: 100%;
    display: flex;
    flex-direction: column;
    
    ${props => props.isLoading && `
      opacity: .8;
      pointer-events: none;
      user-select: none;
    `}
`;

export const ContentFilters = styled.div`
  width: 100%;
`;
export const ContentFiltersStatuses = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
  flex-wrap: wrap;
`;
export const ContentFiltersStatus = styled.div<{isActive: boolean}>`
  height: 24px;
  min-width: 80px;
  border-radius: 10px;
  color: ${stylingVariables.colorPalette.darkest};
  font-size: ${stylingVariables.fontSize.medium};
  font-weight: ${stylingVariables.fontWeight.semibold};
  transition: all .3s;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0 10px;
  margin: 10px;
  user-select: none;
  
  ${props => props.isActive && `
    color: ${stylingVariables.colorPalette.orange};
    background-color: ${stylingVariables.colorPalette.gray};
  `}
`;
export const ContentFiltersSearch = styled.div`
  margin: 0 20px;
  input {
    border-color: ${stylingVariables.colorPalette.slateGray};
  }
  svg {
    fill: ${stylingVariables.colorPalette.slateGray};
  }
`;
export const ContentEmployeeList = styled.ul`
  height: 100%;
  overflow: auto;
  padding: 0 20px;
  margin: 20px 0;
`;
export const ContentEmployeeListItem = styled.li`
  padding: 8px 4px;
  color: ${stylingVariables.colorPalette.darkest};
  font-size: ${stylingVariables.fontSize.default};
  font-weight: ${stylingVariables.fontWeight.regular};
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: flex-end;
  &:hover {
    background-color: ${stylingVariables.colorPalette.lightGray};
  }
`;
export const ContentEmployeeListItemIndex = styled.div`
  color: ${stylingVariables.colorPalette.deepGray};
  font-size: ${stylingVariables.fontSize.mediumLarge};
  font-weight: ${stylingVariables.fontWeight.regular};
  margin-right: 5px;
`;
export const ContentEmployeeListEmpty = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${stylingVariables.colorPalette.deepGray};
  font-size: ${stylingVariables.fontSize.mediumLarge};
  font-weight: ${stylingVariables.fontWeight.regular};
`;
export const ContentEmployeeListLoadMore = styled.li`
  width: 100%;
  cursor: pointer;
  display: flex;justify-content: center;
  align-items: center;
  padding: 10px;
  color: ${stylingVariables.colorPalette.green};
  font-size: ${stylingVariables.fontSize.large};
  font-weight: ${stylingVariables.fontWeight.semibold};
  &:hover {
    background-color: ${stylingVariables.colorPalette.lightGreen};
  }
`;