import {createSelector} from 'reselect';

import {ApiObject} from '@api';
import {constants, intl} from '@global';

import {ADVANCED_SETTING_COLUMNS} from './constants';
import {getLocalPayElements, getCurrencies} from '../../../selectors';
import {RootState} from '../../../../../rootReducer';
import {CompensationTypes} from "../../../../../constants";

export const getSalaryModal = (state: RootState) => state.employee.salaryModal;

export const isSalaryModalOpen = createSelector(
    [getSalaryModal],
    modalState => modalState.isModalOpen,
);

export const getSalaryModalLabel = createSelector(
    [getSalaryModal],
    modalState => {
        if (modalState.payElementType === undefined) {
            return '';
        }
        return intl.get(modalState.payElementType);
    },
);

export const getPayElementType = createSelector(
    [getSalaryModal],
    modalState => modalState.payElementType,
);

const createField = (data: {
    code: string,
    type: ApiObject.FieldType,
    label: string,
    required: boolean,
    allowEditIfFilled: boolean,
    ordinal: number,
    options?: ApiObject.SelectOption[],
}): ApiObject.Field => {
    return {
        code: data.code,
        type: data.type,
        label: data.label,
        required: data.required,
        allowEditIfFilled: data.allowEditIfFilled,
        ordinal: data.ordinal,
        options: data.options ? data.options : [],
        defaultMssReportOutputFormat: null,
        entity: '',
        entityId: 0,
        id: '',
        index: 0,
        values: [],
    };
};

export const getPayElementFormFields = createSelector(
    [getLocalPayElements, getCurrencies, getSalaryModal],
    (payElements, currencies, salaryModalState) => {
        const result: ApiObject.Field[] = [];
        const payElementType = salaryModalState.payElementType;
        if (!payElementType) { return result; }

        const payElementOptions: ApiObject.SelectOption[] = payElements
            .filter(payElement => {
                let compensationType = `${payElement.occurrence}_${payElement.type}s` as CompensationTypes;

                if (compensationType === CompensationTypes.recurringRate) {
                    compensationType = CompensationTypes.recurringEarnings;
                }
                return payElementType === compensationType;
            })
            .map(payElement => {
                return {
                    key: `${payElement.account}`,
                    label: `${payElement.account} - ${payElement.name}`,
                };
            });
        result.push(createField({
            code: ApiObject.EmployeePayElementValues.account,
            type: ApiObject.FieldType.text,
            options: payElementOptions,
            label: intl.get('pay_element'),
            required: true,
            allowEditIfFilled: false,
            ordinal: 0,
        }));
        result.push(createField({
            code: ApiObject.EmployeePayElementValues.amount,
            type: ApiObject.FieldType.number,
            label: intl.get('periodic_amount'),
            required: true,
            allowEditIfFilled: true,
            ordinal: 1,
        }));
        if (payElementType !== constants.CompensationTypes.additionalUnits) {
            result.push(createField({
                code: ApiObject.EmployeePayElementValues.currency,
                type: ApiObject.FieldType.text,
                options: currencies,
                label: intl.get('currency'),
                required: true,
                allowEditIfFilled: false,
                ordinal: 2,
            }));
        }
        result.push(createField({
            code: ApiObject.EmployeePayElementValues.start_date,
            type: ApiObject.FieldType.date,
            label: intl.get('start_date'),
            required: true,
            allowEditIfFilled: true,
            ordinal: 3,
        }));
        const payUnitOptions: ApiObject.SelectOption[] = [];
        for (const payUnit in ApiObject.PayUnitType) {
            if (payUnit !== ApiObject.PayUnitType.percentage) {
                payUnitOptions.push({
                    key: payUnit,
                    label: payUnit.charAt(0).toUpperCase() + payUnit.slice(1),
                });
            }
        }
        result.push(createField({
            code: ApiObject.EmployeePayElementValues.pay_unit,
            type: ApiObject.FieldType.text,
            options: payUnitOptions,
            label: intl.get('pay_unit'),
            required: false,
            allowEditIfFilled: true,
            ordinal: 4,
        }));
        if (payElementType === constants.CompensationTypes.additionalEarnings ||
            payElementType === constants.CompensationTypes.additionalDeductions ||
            payElementType === constants.CompensationTypes.recurringUnits ||
            payElementType === constants.CompensationTypes.additionalUnits) {
            return result;
        }

        result.push(createField({
            code: ApiObject.EmployeePayElementValues.total,
            type: ApiObject.FieldType.number,
            label: intl.get('goal_amount'),
            required: payElementType === constants.CompensationTypes.recurringDeductions ? true : false,
            allowEditIfFilled: false,
            ordinal: 5,
        }));
        result.push(createField({
            code: ApiObject.EmployeePayElementValues.balance,
            type: ApiObject.FieldType.number,
            label: intl.get('balance'),
            required: false,
            allowEditIfFilled: false,
            ordinal: 6,
        }));
        result.push(createField({
            code: ApiObject.EmployeePayElementValues.gross_up,
            type: ApiObject.FieldType.text,
            options: [
                {key: 'true', label: intl.get('yes')},
                {key: 'false', label: intl.get('no')},
            ],
            label: intl.get('gross_up'),
            required: true,
            allowEditIfFilled: true,
            ordinal: 7,
        }));
        result.push(createField({
            code: ApiObject.EmployeePayElementValues.end_date,
            type: ApiObject.FieldType.date,
            label: intl.get('end_date'),
            required: payElementType === constants.CompensationTypes.recurringDeductions ? true : false,
            allowEditIfFilled: true,
            ordinal: 8,
        }));
        return result;
    },
);

export const getSalaryModalFields = createSelector(
    [getPayElementFormFields],
    (fields): ApiObject.Field[] => {

        return fields.sort((a: ApiObject.Field, b: ApiObject.Field) => {
            return a.ordinal - b.ordinal;
        });
    },
);

export const getSalaryModalBaseFields = createSelector(
    [getSalaryModalFields],
    (fields): ApiObject.Field[] => {

        return fields.filter(f => !ADVANCED_SETTING_COLUMNS.includes(f.code as ApiObject.EmployeePayElementValues));
    },
);

export const getSalaryModalAdvancedFields = createSelector(
    [getSalaryModalFields],
    (fields): ApiObject.Field[] => {
        return fields.filter(f => ADVANCED_SETTING_COLUMNS.includes(f.code as ApiObject.EmployeePayElementValues));
    },
);

export const getSalaryModalValues = createSelector(
    [getSalaryModal],
    settings => settings.values,
);
