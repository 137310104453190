import React, {PureComponent} from 'react';
import {SearchInput, StyledInputWrapper} from '@common';
import AlphabetListEntities from './AlphabetListEntities';
import styled from 'styled-components';

const AlphabetListEntitiesWithSearchWrapper = styled.div`
  overflow-y: auto;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  flex: 1 0 600px;

  ${StyledInputWrapper} {
    margin-bottom: 25px;
    input {
        border: 1px solid #657387;
    }
  }
`;

interface AlphabetEntitiesState {
    searchQuery: string;
    padding?: string;
    overflow?: string;
}

export default class AlphabetListEntitiesWithSearch extends PureComponent<any, AlphabetEntitiesState> {
    public constructor(props: any) {
        super(props);

        this.state = {
            searchQuery: '',
        }
    }

    public render() {
        return (
            <AlphabetListEntitiesWithSearchWrapper>
                <SearchInput onChange={this.onChangeSearch}/>

                <AlphabetListEntities padding={this.props.padding} overflow={this.props.overflow} searchQuery={this.state.searchQuery}/>
            </AlphabetListEntitiesWithSearchWrapper>
        );
    }

    public onChangeSearch = (value: string) => {
        this.setState({ searchQuery: value })
    }
}
