import React, {PureComponent} from 'react';
import styled from 'styled-components';
import logo from './../../assets/images/hrblizz_logo.png';
import {stylingVariables} from '@global';

const StyledLogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${stylingVariables.layout.menuWidth};
  height: ${stylingVariables.layout.height};
  min-height: ${stylingVariables.layout.height};
  max-height: ${stylingVariables.layout.height};
  border-bottom: 1px solid ${stylingVariables.colorPalette.darkGray};
  border-right: 1px solid ${stylingVariables.colorPalette.darkGray};
`;

export default class ProductLogo extends PureComponent {
    render() {
        return (
            <StyledLogoWrapper>
                <img src={logo} alt='HR Blizz' width='80px'/>
            </StyledLogoWrapper>
        );
    }
}
