import React from 'react';
import {Column} from 'react-virtualized';

import {defaultInfiniteTableCellDataGetter, Label} from '@common';
import styled from "styled-components";
import {intl, stylingVariables} from "@global";

const LabelWithStatusWrapper = styled.div`
    display: flex;
    flex-direction:row;
    font-size: ${stylingVariables.fontSize.default};
    >span{
        margin: 0 5px;
    }
`;


interface IndexColumnProps {
    label: string;
    dataKey: string;
}

const LabelWithStatus: React.FC<IndexColumnProps> = ({label, dataKey}) => (
    <Column
        label={label}
        dataKey={dataKey}
        width={200}
        flexGrow={1}
        style={{whiteSpace: 'normal', lineHeight: '15px'}}
        cellDataGetter={defaultInfiniteTableCellDataGetter}
        cellRenderer={({cellData}) => {
            const hasChanges = cellData.changes && cellData.changes.length > 0;
            return cellData.childElement ? '' : <LabelWithStatusWrapper>
                {cellData.label}
                {hasChanges && <Label.Tag color={stylingVariables.colorPalette.yellow}>{intl.get(`updated`)}</Label.Tag>}
            </LabelWithStatusWrapper>
        }}
    />
);
export default LabelWithStatus;
