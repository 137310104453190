import { interfaces } from '@global'
import React, { PureComponent } from 'react'
import { AutoSizer, InfiniteLoader } from 'react-virtualized'
import { connect } from 'react-redux'
import { RouteHelper } from '@library'

import { intl } from '@global'
import { ApiEmployee, LeaveInterfaces, EmployeeInterfaces, EmployeeQueryParams, PayrollInterfaces } from '@api'
import {
  StyledInfiniteTable as CommonStyledInfiniteTable,
  InfiniteTableColumn,
  InfiniteTableNoRows,
  InfiniteTableLoader,
} from '@common'
import {
  changeFilter,
  changeOrder,
  reloadLeaves,
  setTags,
  getEmployees,
  getLeaveTypes,
  clearAndGetEmployeeBalances,
} from '../../redux'

import * as selectors from '../../selectors'
import * as globalSelectors from '../../../selectors'
import { RootState } from '../../../../rootReducer'
import styled from 'styled-components'
import moment, { Moment } from 'moment'

import * as Column from './Column'

const StyledInfiniteTable = styled(CommonStyledInfiniteTable)`
  .ReactVirtualized__Table__headerRow {
    position: relative;
    .ReactVirtualized__Table__headerColumn:first-child {
      text-align: center;
    }
    .ReactVirtualized__Table__headerColumn {
      overflow: hidden;
      padding: 5px 10px;
    }
    .ReactVirtualized__Table__headerColumn:nth-last-child(2) {
      border-right: none;
    }
  }
  .ReactVirtualized__Table__row {
    .ReactVirtualized__Table__rowColumn {
      padding: 5px 10px;
    }
  }
`

interface IProps {
  from: Moment
  isLoading: boolean
  isMenuCollapsed: boolean
  currentLegalEntityId: number | null
  currentPayrollPeriod: PayrollInterfaces.PayrollPeriod | null

  reloadLeaves: () => void
  changeOrder: (column: string) => any
  changeFilter: (params: interfaces.FilterParams) => any
  setTags: (tags: EmployeeInterfaces.Tag[]) => void
  getEmployees: (offset: number, limit?: number) => void
  getLeaveTypes: () => void
  clearAndGetEmployeeBalances: () => void

  employeeLeaves: LeaveInterfaces.EmployeeLeave[]
  employeeIds: number[]
  totalCount: number
  orders: interfaces.ListingOrders
  filters: interfaces.ListingFilters
  tags: EmployeeInterfaces.Tag[]
  leaveTypes: LeaveInterfaces.Type[]
}

export class Table extends PureComponent<IProps> {
  public constructor(props: IProps) {
    super(props)

    props.getLeaveTypes()
    props.clearAndGetEmployeeBalances()
  }

  public render() {
    const balanceEditDisabled = this.props.currentPayrollPeriod
      ? moment(this.props.currentPayrollPeriod.beginDate).isSame(this.props.from)
      : true

    return (
      <InfiniteLoader
        isRowLoaded={this._isRowLoaded}
        loadMoreRows={this._loadMoreRows}
        rowCount={this.props.totalCount}
      >
        {({ onRowsRendered, registerChild }) => (
          <AutoSizer>
            {({ width, height }) => (
              <StyledInfiniteTable
                ref={registerChild}
                onRowsRendered={onRowsRendered}
                rowHeight={80}
                headerHeight={40}
                rowGetter={({ index }: { index: number }) => this.props.employeeLeaves[index]}
                rowCount={this.props.employeeLeaves.length}
                height={height - 180}
                width={this.calculateTableWidth(width)}
                isMenuCollapsed={this.props.isMenuCollapsed}
                noRowsRenderer={this._renderNoRows}
              >
                {InfiniteTableColumn.Default({
                  label: '#',
                  dataKey: 'index',
                  flexGrow: 0,
                  width: 70,
                  style: { justifyContent: 'center' },
                })}

                {Column.Employee({
                  currentPeriod: this.props.currentPayrollPeriod,

                  filters: this.props.filters,
                  orders: this.props.orders,
                  tags: this.props.tags,

                  changeFilter: this.onChangeFilter,
                  changeOrder: this.onChangeOrder,
                  setTags: this.props.setTags,
                  onClickLink: this.redirectToEmployeePage,
                  getEmployees: this.getEmployeesForFilter,
                })}

                {this.props.leaveTypes.map((type) => Column.Leave({ type }))}

                {Column.Actions(!balanceEditDisabled)}
              </StyledInfiniteTable>
            )}
          </AutoSizer>
        )}
      </InfiniteLoader>
    )
  }

  private calculateTableWidth = (screenWidth: number): number => {
    const calculatedWidth = this.props.leaveTypes.length * 105 + 400
    return calculatedWidth > screenWidth ? calculatedWidth : screenWidth
  }

  private _renderNoRows = () => {
    if (this.props.isLoading) {
      return <InfiniteTableLoader />
    }

    return <InfiniteTableNoRows text={intl.get('no_employees_found')} />
  }

  private _isRowLoaded = ({ index }: { index: number }): boolean => {
    return !!this.props.employeeLeaves[index]
  }

  private _loadMoreRows = async ({ startIndex }: { startIndex: number }) => {
    await this.props.getEmployees(startIndex)
  }

  private onChangeOrder = (column: string) => {
    this.props.changeOrder(column)
    this.props.reloadLeaves()
  }

  private onChangeFilter = (params: interfaces.FilterParams) => {
    this.props.changeFilter(params)
    this.props.reloadLeaves()
  }

  private getEmployeesForFilter = async (
    query: string,
    tags: EmployeeInterfaces.Tag[],
    limit: number,
    page: number
  ) => {
    if (!this.props.currentLegalEntityId) {
      return []
    }

    const params = new EmployeeQueryParams({
      tags,
      currentPeriod: this.props.currentPayrollPeriod,
      employeeIds: this.props.employeeIds,
    })
    return await ApiEmployee.findByQueryWithPage(
      this.props.currentLegalEntityId,
      query,
      limit,
      page,
      params.params(),
      true
    )
  }

  private redirectToEmployeePage(event: any, id: number) {
    event.stopPropagation()
    RouteHelper.goToEmployeePage(id)
  }
}

const mapStateToProps = (state: RootState, ownProps: any) => ({
  isLoading: globalSelectors.isLoading(state),
  isMenuCollapsed: globalSelectors.isMenuCollapsed(state),
  currentLegalEntityId: globalSelectors.getCurrentLegalEntityId(state),
  currentPayrollPeriod: globalSelectors.getCurrentPayrollPeriod(state),

  employeeLeaves: selectors.getEmployeeLeaves(state, ownProps),
  leaveTypes: selectors.getActiveLeaveTypes(state, ownProps),
  employeeIds: selectors.employeeIds(state),
  orders: selectors.getOrders(state, ownProps),
  filters: selectors.getFilters(state, ownProps),
  tags: selectors.getTags(state, ownProps),
  from: selectors.getPeriodFilterFrom(state, ownProps),

  totalCount: selectors.getTotalCount(state, ownProps),
})

const mapDispatchToProps = {
  changeOrder,
  changeFilter,
  reloadLeaves,
  setTags,
  getEmployees,
  getLeaveTypes,
  clearAndGetEmployeeBalances,
}

export default connect(mapStateToProps, mapDispatchToProps)(Table)
