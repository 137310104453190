import React, {PureComponent} from 'react';
import NumberFormat, {FormatInputValueFunction, NumberFormatValues} from 'react-number-format';

interface IProps {
    defaultValue?: string;
    disabled?: boolean;
    onChange: (value: any) => void;

    thousandSeparator?: boolean | string;
    decimalSeparator?: string;
    thousandsGroupStyle?: 'thousand' | 'lakh' | 'wan';
    decimalScale?: number;
    fixedDecimalScale?: boolean;
    displayType?: 'input' | 'text';
    prefix?: string;
    suffix?: string;
    format?: string | FormatInputValueFunction;
    removeFormatting?: (formattedValue: string) => string;
    mask?: string | string[];
    value?: number | string;
    isNumericString?: boolean;
    customInput?: React.ComponentType<any>;
    allowNegative?: boolean;
    allowEmptyFormatting?: boolean;
    type?: 'text' | 'tel'  | 'password';
    isAllowed?: (values: NumberFormatValues) => boolean;
    renderText?: (formattedValue: string) => React.ReactNode;
    getInputRef?: ((el: HTMLInputElement) => void) | React.Ref<any>;
    allowedDecimalSeparators?: string[];
}

interface IState {
    value: string;
}

export class FloatInput extends PureComponent<IProps, IState> {
    public static defaultProps = {
        disabled: false,
        defaultValue: '',
        thousandSeparator: ',',
        decimalSeparator: '.',
        decimalScale: 2,
        fixedDecimalScale: 2,
        type: 'text',
        allowNegative: true,
        isNumericString: true,
    };

    private onValueChanged = (values: NumberFormatValues) => {
        this.props.onChange(values.value);
    }

    public render() {
        return (
            <NumberFormat
                className={'common__input__float-input'}
                thousandSeparator={this.props.thousandSeparator}
                defaultValue={this.props.defaultValue}
                onValueChange={this.onValueChanged}

                decimalSeparator={this.props.decimalSeparator}
                thousandsGroupStyle={this.props.thousandsGroupStyle}
                decimalScale={this.props.decimalScale}
                fixedDecimalScale={this.props.fixedDecimalScale}
                displayType={this.props.displayType}
                prefix={this.props.prefix}
                suffix={this.props.suffix}
                format={this.props.format}
                removeFormatting={this.props.removeFormatting}
                mask={this.props.mask}
                value={this.props.value}
                isNumericString={this.props.isNumericString}
                customInput={this.props.customInput}
                allowNegative={this.props.allowNegative}
                allowEmptyFormatting={this.props.allowEmptyFormatting}
                type={this.props.type}
                isAllowed={this.props.isAllowed}
                renderText={this.props.renderText}
                getInputRef={this.props.getInputRef}
                allowedDecimalSeparators={this.props.allowedDecimalSeparators}
                disabled={this.props.disabled}
            />
        );
    }
}

export default FloatInput;
