import React, {PureComponent} from 'react';
import styled from 'styled-components';
import {stylingVariables} from '@global';
import {IconPopover} from '@common';

export const StyledRadioButtons = styled.div<{isDisabled?: boolean}>`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px;
    >div{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
    }
    ${props => props.isDisabled && `
        opacity: .7;
        pointer-events: none;
        cursor: not-allowed;
    `}
`;

export const StyledLabel = styled.div`
    display: flex;
    flex-direction;
    align-items: center;
    justify-content: flex-start;
    font-size: ${stylingVariables.fontSize.mediumLarge};
    color: ${stylingVariables.colorPalette.deepGray};
    >div{
        position: relative;
        top: -5px;
    }
`;

export const StyledOption = styled.div`
    display: flex;
    flex-direction;
    align-items: center;
    justify-content: center;
    margin: 5px 10px 5px 0;
`;

export const StyledRadioOption = styled.div<{isSelected?: boolean}>`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin: 0 10px;
    border-radius: 50%;
    border: 1px solid ${props => props.isSelected ? stylingVariables.colorPalette.green : stylingVariables.colorPalette.darkest};
    >span{
        width: 14px;
        min-width: 14px;
        height: 14px;
        min-height: 14px;
        background: ${props => props.isSelected ? stylingVariables.colorPalette.green : 'transparent'};
        border: 1px solid ${props => props.isSelected ? stylingVariables.colorPalette.white : 'transparent'};
        border-radius: 50%;
    }
`;

export const StyledOptionLabel = styled.div`
    font-size: ${stylingVariables.fontSize.medium};
`;

interface RadioButtonsProps {
    label?: string;
    explanation?: string;
    defaultValue?: string|number;
    isDisabled?: boolean;
    options: {label: string, value: string|number}[];
    onChange: (value: string|number) => void;
    className?: string;
}

interface RadioButtonsState {
    selected: string|number;
}

export default class RadioButtons extends PureComponent<RadioButtonsProps, RadioButtonsState> {
    static defaultProps = {
        options: [],
        isDisabled: false,
        defaultValue: '',
        onChange: (value: string|number) => {},
    };

    constructor(props: any) {
        super(props);
        this.state = {
            selected: props.defaultValue ? props.defaultValue : props.options.length > 0 ? props.options[0].value : '',
        };
    }

    render() {
        return <StyledRadioButtons
            isDisabled={this.props.isDisabled}
            className={this.props.className}
        >
            {this.props.label &&
            <StyledLabel>
                {this.props.label}
                {this.props.explanation && <IconPopover type={'help'} message={this.props.explanation} iconSize={14} />}
            </StyledLabel>}
            <div className={'radio-buttons-wrapper'}>{this.renderOptions()}</div>
        </StyledRadioButtons>;
    }

    renderOptions() {
        const {selected} = this.state;
        return this.props.options.map((option: {label: string, value: string|number}) => {
            return <StyledOption onClick={() => {this.onClick(option.value);}} key={option.value}>
                <StyledRadioOption isSelected={(selected === option.value)}>
                    <span></span>
                </StyledRadioOption>
                <StyledOptionLabel>{option.label}</StyledOptionLabel>
            </StyledOption>;
        });
    }

    onClick = (value: string|number) => {
        this.setState({selected: value});
        this.props.onChange(value);
    }
}
