import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {debounce, get} from 'lodash'
import styled from 'styled-components';

import {constants, intl, stylingVariables} from '@global'
import {ApiObject, ApiPayElement} from '@api';
import {EditableField as Default} from '@common';

import {RootState} from '../../../../../rootReducer';
import * as selectors from '../../../../selectors';
import {SingleToastMessage} from '@library'

export const StyledFieldRow = styled.div`
  margin: 8px 0;
  font-size: ${stylingVariables.fontSize.mediumLarge};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  height: 40px;
  label{
    display: inline-block;
    width: 200px;
    line-height: 25px;
  }
  label + span {
    font-weight: bold;
    color: ${stylingVariables.colorPalette.darkest};
  }
`;

const StyledLabel = styled.label`
  color: ${stylingVariables.colorPalette.deepGray};
`;

interface IProps {
    legalEntityId: number|null;

    group: ApiObject.FieldGroup;
    fields: any;
    errors: ApiObject.EntityFieldErrorMessage[];
    onChange: (field: ApiObject.Field, value: any) => void;
}

interface IState {
    requestedBankName: string|null;

    swift: ApiObject.Field|null;
    bankName: ApiObject.Field|null;
    mounted: boolean;
    defaultBankName: string;
}

export class BankSwiftAndName extends PureComponent<IProps, IState>  {
    public constructor(props: IProps) {
        super(props);
        const swift = this.props.group.fields
            .find(f => f.code === constants.DEFAULT_FIELD_CODE_SWIFT) || null;
        const bankName = this.props.group.fields
            .find(f => f.code === constants.DEFAULT_FIELD_CODE_BANK_NAME) || null;

        this.state = {
            requestedBankName: null,
            defaultBankName: bankName ? get(this.props.fields, `${bankName.entity}.${bankName.code}`) : '',

            swift,
            bankName,
            mounted: false,
        };

        if (this.state.swift) {
            const value = get(this.props.fields, `${this.state.swift.entity}.${this.state.swift.code}`);

            this.requestBankName(value);
        }
    }

    render() {
        return (
            <>
                {
                    this.state.swift &&
                    <Default
                        key={this.state.swift.label}
                        type={this.state.swift.type}
                        label={this.state.swift.label}
                        isRequired={this.state.swift.required}
                        code={this.state.swift.code}
                        options={this.state.swift.options}
                        defaultValue={
                            get(this.props.fields, `${this.state.swift.entity}.${this.state.swift.code}`)
                        }
                        onChange={this.handleChangeSwift}
                        errorMessage={this.swiftErrorMessage}
                    />
                }

                {
                    this.state.bankName && this.state.requestedBankName &&
                    <StyledFieldRow>
                        <StyledLabel>
                            {
                                this.state.bankName.required
                                    ? `${this.state.bankName.label} *`
                                    : this.state.bankName.label
                            }
                        </StyledLabel>

                        <span>{this.state.requestedBankName}</span>
                    </StyledFieldRow>
                }

                {
                    this.state.bankName && !this.state.requestedBankName &&
                    <Default
                        key={this.state.bankName.label}
                        type={this.state.bankName.type}
                        label={this.state.bankName.label}
                        isRequired={this.state.bankName.required}
                        code={this.state.bankName.code}
                        options={this.state.bankName.options}
                        defaultValue={this.state.defaultBankName}
                        onChange={this.handleChangeBankName}
                        errorMessage={this.bankErrorMessage}
                    />
                }
            </>
        );
    }

    public componentDidMount() {
        this.setState({
            mounted: true,
        });
    }

    public handleChangeSwift = async(value: any) => {
        if (!this.state.swift) {
            return;
        }

        this.props.onChange(this.state.swift, value);
        this.requestAndHandleBankName(value)
    }

    public handleChangeBankName = async(value: any) => {
        if (!this.state.bankName) {
            return;
        }

        this.setState({
            defaultBankName: value,
        });

        if (this.state.bankName.allowEditIfFilled) {
            this.props.onChange(this.state.bankName, value);
        }
    }

    /**
     * Request Bank Name and Set Into State
     *
     * @param swift
     */
    public requestBankName = async(swift?: string) => {
        if (!this.props.legalEntityId || swift === undefined || swift === null) {
            return null;
        }
        let requestedBankName: string | null = '';
        if (swift !== '') {
            requestedBankName = await ApiPayElement.getBankName(this.props.legalEntityId, swift) ?? '';
        }

        if (this.state.mounted) {
            const newState: any = {
                requestedBankName,
            };
            if ((this.state.requestedBankName?.length ?? 0) !== 0 && requestedBankName.length === 0) {
                newState.defaultBankName = '';
                requestedBankName = null;
            }
            this.setState(newState);
        } else {
            this.state = {
                ...this.state,
                requestedBankName,
            };
        }

        return requestedBankName;
    }

    /**
     * Debounced request bank name
     * If bank found, then run `handleChangeBankName`
     */
    private requestAndHandleBankName = debounce(async (swift?: string) => {
        const bankNotFoundSingleToast = new SingleToastMessage('toast_id_bank_not_found', intl.get('bank_not_found'), 'warning')
        if (!swift) {
            // If user removed swift number - close toast also
            bankNotFoundSingleToast.close()
            return
        }
        const bankName: string|null = await this.requestBankName(swift)
        if (!bankName) {
            bankNotFoundSingleToast.show()
            return
        }

        // If bank received then close toast with warning message
        bankNotFoundSingleToast.close()

        await this.handleChangeBankName(bankName)
    }, 1000)

    /**
     * Get Swift Error Message
     */
    private get swiftErrorMessage(): string|null {
        return this.getErrorMessageByField(this.state.swift);
    }

    /**
     * Get Swift Error Message
     */
    private get bankErrorMessage(): string|null {
        return this.getErrorMessageByField(this.state.bankName);
    }

    /**
     * Get Swift Error Message
     */
    private getErrorMessageByField(field: ApiObject.Field|null): string|null {
        const foundError = this.props.errors
            .find((error: ApiObject.EntityFieldErrorMessage) => {
                if (!field) {
                    return false;
                }

                return error.field === field.code && error.entity ===  field.entity;
            });

        return foundError ? foundError.message : null;
    }
}

const mapStateToProps = (state: RootState) => ({
    legalEntityId: selectors.getCurrentLegalEntityId(state),
});

export default connect(mapStateToProps)(BankSwiftAndName);
