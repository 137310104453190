import styled from 'styled-components';
import {stylingVariables} from '@global';

export enum CircleSizes {
    small = '20px',
    normal = '25px',
    large = '30px',
    big = '35px',
}

export enum SquareSizes {
    small = '20px',
    normal = '25px',
    large = '30px',
    big = '35px',
}

export const Circle = styled.span<{color?: string, size?: CircleSizes, textColor?: string}>`
	height: ${props => props.size || CircleSizes.normal};
	width: ${props => props.size || CircleSizes.normal};
	display: flex;
	align-items: center;
	justify-content: center;
	flex-shrink: 0;
	border-radius: 50%;
	white-space: nowrap;
	font-size: ${stylingVariables.fontSize.mediumSmall};
    background-color: ${props => props.color || stylingVariables.colorPalette.green};
    color: ${props => props.textColor || stylingVariables.colorPalette.dark};
`;

export const Square = styled.span<{color?: string, size?: SquareSizes, textColor?: string}>`
	height: ${props => props.size || SquareSizes.normal};
	width: ${props => props.size || SquareSizes.normal};
	display: flex;
	align-items: center;
	justify-content: center;
	white-space: nowrap;
	font-size: ${stylingVariables.fontSize.mediumSmall};
    background-color: ${props => props.color || stylingVariables.colorPalette.green};
    color: ${props => props.textColor || stylingVariables.colorPalette.dark};
`;
