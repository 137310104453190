import React, {PureComponent} from 'react';

import ColumnSettingsModal from './ColumnSettingsModal';
import {interfaces} from '@global';

interface ColumnSettingsProps {
    isModalOpen: boolean;
    selectedColumns: interfaces.EmployeeColumn[];
    unselectedColumns: interfaces.EmployeeColumn[];

    saveColumnSettings: (columns: string[]) => void;
    closeColumnSettingsModal: () => void;
}

export default class ColumnSettings extends PureComponent<ColumnSettingsProps> {
    render() {
        if (!this.props.isModalOpen) {
            return null;
        }

        return (
            <ColumnSettingsModal
                selectedColumns={this.props.selectedColumns}
                unselectedColumns={this.props.unselectedColumns}

                saveColumnSettings={this.props.saveColumnSettings}
                closeColumnSettingsModal={this.props.closeColumnSettingsModal}
            />
        );
    }
}

