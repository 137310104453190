import React, {PureComponent} from 'react';
import styled from 'styled-components';
import {intl, stylingVariables} from '@global';
import {StyledTitle} from './StyledCard';
import {ChartDisclaimer} from './TypeCard';
import {FormatNumbers} from '@common';

const Card = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    min-width: 200px;
    background: ${stylingVariables.colorPalette.white};
    border: 1px solid ${stylingVariables.colorPalette.darkGray};
    border-radius: 4px 4px 1px 1px;
    margin: 0 10px 18px;
    padding: 19px;
`;

const TotalCount = styled.div`
    text-align: center;
    font-size: 42px;
    font-weight: ${stylingVariables.fontWeight.light};
    color: ${stylingVariables.colorPalette.deepGray};
`;

interface CardProps {
    count: number;
    year: number;
}

export default class TotalCard extends PureComponent<CardProps> {
    render() {
        return (
            <Card>
                <StyledTitle>{intl.get('total')}</StyledTitle>
                <TotalCount>
                    <FormatNumbers showZero={true} value={this.props.count}/>
                </TotalCount>
                <ChartDisclaimer>{intl.get('leave_days_taken_in_year', {year: this.props.year}) }</ChartDisclaimer>
            </Card>
        );
    }
}
