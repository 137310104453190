import React, {PureComponent} from 'react';
import styled from 'styled-components';

import {stylingVariables} from '@global';
import {SpinnerIcon} from '../../entrypoints/Loader';

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 65px;
  background-color: ${stylingVariables.colorPalette.white};
  margin-top: 15px;
`;

export default class LoadingRow extends PureComponent {
    render() {
        return (
            <StyledWrapper>
                <SpinnerIcon/>
            </StyledWrapper>
        );
    }
}
