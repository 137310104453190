import React, {PureComponent} from 'react';
import styled from 'styled-components';

import {Icon, BlankButton} from '@common';
import {constants, stylingVariables} from '@global';

const ColumnSettingsButton = styled(props => <BlankButton {...props} />)`
  cursor: pointer;
  float: right;
`;

export default class Settings extends PureComponent {
    public render() {
        return (
            <ColumnSettingsButton>
                <Icon type={constants.IconTypes.SETTINGS}
                      width={15} height={15}
                      fillColor={stylingVariables.colorPalette.green}
                />
            </ColumnSettingsButton>
        );
    }
}
