import React, {PureComponent, Fragment} from 'react';
import {intl, constants} from '@global';
import {connect} from 'react-redux';
import {EmployeeInterfaces} from '@api';
import {Actions, Can} from '@common';

import {RootState} from '../../../../../rootReducer';
import {isLoading} from '../../../../selectors';
import InsertSeparationModal from './InsertSeparationModal';
import {resetSeparation} from '../../../redux';

interface IProps {
    forEmployeeIds: Set<number>;
    employee: EmployeeInterfaces.Employee;
    resetSeparation: () => void;
    cuttOffs?: Object|null;
    isLoading: boolean;
}

interface IState {
    isOpenModal: boolean;
}

export class InsertSeparation extends PureComponent<IProps, IState> {
    public constructor(props: IProps) {
        super(props);

        this.state = {
            isOpenModal: false,
        };
    }

    render() {
        return (
            <Fragment>
                <Can cuttOffs={this.props.cuttOffs} run={constants.PermissionCode.EMPLOYEE_SEPARATION}>
                    <Actions.Action onClick={this.openModal} isDisabled={this.props.isLoading}>
                        {intl.get('insert_separation')}
                    </Actions.Action>
                </Can>
                {
                    this.state.isOpenModal &&
                    <InsertSeparationModal
                        forEmployeeIds={this.props.forEmployeeIds}
                        onClickCloseModal={this.closeModal}
                        employee={this.props.employee}
                    />
                }
            </Fragment>
        );
    }

    private openModal = () => {
        this.props.resetSeparation();
        this.setState({isOpenModal: true});
    }

    private closeModal = () => {
        this.props.resetSeparation();
        this.setState({isOpenModal: false});
    }
}

const mapStateToProps = (state: RootState) => ({
    isLoading: isLoading(state),
});

const mapDispatchToProps = {
    resetSeparation,
};

export default connect(mapStateToProps, mapDispatchToProps)(InsertSeparation);
