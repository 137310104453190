import React, {PureComponent} from 'react';
import styled from 'styled-components';
import {intl} from '@global';

import {stylingVariables} from '@global';
import {Modal, Icon} from '@common';

const StyledConfirmationMessageWrapper = styled.div`
  padding: 30px 40px;
  color: ${stylingVariables.colorPalette.deepGray};
  font-size: ${stylingVariables.fontSize.mediumLarge};
  font-weight: ${stylingVariables.fontWeight.regular};
`;

interface ConfirmationModalProps {
    title: string;
    confirmationMessage?: string;

    acceptBtnTitle: string;
    cancelBtnTitle: string;

    isOpen: boolean;
    onClickAccept: () => void;
    onClickClose: () => void;

    headerIcon?: string;
    children?: any;
}

export default class ConfirmationModal extends PureComponent<ConfirmationModalProps> {
    static defaultProps = {
        acceptBtnTitle: 'ok',
        cancelBtnTitle: 'cancel',
    };

    render() {
        if (!this.props.isOpen) {
            return null;
        }

        const { headerIcon } = this.props;

        return (
            <Modal.CenterModal onClickClose={this.props.onClickClose} borderRadius={stylingVariables.layout.gentModalBorder}>
                <Modal.StyledHeader padding={headerIcon ? '20px' : '20px 40px'} noBorder={true}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        {headerIcon
                            ? <Modal.StyledHeaderIcon>
                                <Icon type={headerIcon} height={32} width={32} />
                            </Modal.StyledHeaderIcon>
                            : null
                        }
                        <Modal.StyledGentHeaderTitle>
                            {intl.get(this.props.title)}
                        </Modal.StyledGentHeaderTitle>
                    </div>
                </Modal.StyledHeader>

                <StyledConfirmationMessageWrapper>
                    {this.props.children || intl.get(this.props.confirmationMessage || '')}
                </StyledConfirmationMessageWrapper>

                <Modal.StyledActionBar noBorder={true} style={{justifyContent: 'flex-end'}}>
                    <Modal.StyledCancelButton className="StyledCancelButton" onClick={this.props.onClickClose}>
                        {intl.get(this.props.cancelBtnTitle)}
                    </Modal.StyledCancelButton>

                    <Modal.StyledOkButton onClick={this.props.onClickAccept}>
                        {intl.get(this.props.acceptBtnTitle)}
                    </Modal.StyledOkButton>
                </Modal.StyledActionBar>
            </Modal.CenterModal>
        );
    }
}
