import React from 'react';
import {Column} from 'react-virtualized';

import {interfaces, stylingVariables} from '@global';
import {defaultInfiniteTableCellDataGetter, InfiniteTableHeader} from '@common';

interface ColumnProps {
    dataKey: string;
    label: string;
    width?: number;
    flexGrow?: number;
    className?: string;
    type?: string;

    orders?: interfaces.ListingOrders;
    changeOrder?: (column: string) => any;
}

const Ordered: React.FC<ColumnProps> = ({
    className,
    dataKey,
    label,
    width = 200,
    flexGrow = 1,
    orders,
    changeOrder,
}: ColumnProps) => (
    <Column
        className={className}
        dataKey={dataKey}
        width={width}
        flexGrow={flexGrow}
        style={{
            whiteSpace: 'normal',
            lineHeight: '15px',
            color: stylingVariables.colorPalette.green,
            fontWeight: 400,
        }}
        cellDataGetter={defaultInfiniteTableCellDataGetter}
        headerRenderer={() => <InfiniteTableHeader.Ordered
            label={label}
            dataKey={dataKey}
            orders={orders}
            changeOrder={changeOrder}
        />}
        cellRenderer={({cellData}) => cellData[dataKey]}
    />
);

export default Ordered;
