import {ApiObject} from '@api';
import {Icon} from '@common';
import {constants, stylingVariables, intl} from '@global';
import {ReportHelper, ReportTemplatesHelper} from '@library';
import {ToggleCheckbox} from 'hrb-common';
import moment from 'moment';
import React, {PureComponent, CSSProperties} from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';
import {RootState} from '../../../../../rootReducer';

import {updateReport, updateTemplate} from '../../redux';
import * as selectors from '../../selectors';

const Wrapper = styled.div`
  margin-left: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

const OwnerInfo = styled.div`
  margin-left: 10px;
  display: inline-flex;
  flex-wrap: nowrap;
  align-items: center;
  span {
    margin-left: 10px;
    white-space: nowrap;
    font-size: ${stylingVariables.fontSize.medium};
  }
`;

const UserAvatar = styled.div`
  height: 32px;
  width: 32px;
  border-radius: 50%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface IProps {
    forReport: boolean;
    forTemplate: boolean;

    template: ApiObject.ReportTemplate|null;
    report: ApiObject.Report|null;

    updateReport: (report: ApiObject.Report, quiteMode?: boolean) => any;
    updateTemplate: (template: ApiObject.ReportTemplate, quiteMode?: boolean) => any;

    style: CSSProperties;
}

export class VisibilitySelector extends PureComponent<IProps> {
    static defaultProps = {
        forReport: false,
        forTemplate: false,
        style: {}
    }

    onChangeVisibility = (visibility: ApiObject.ReportVisibility) => {
        if (this.props.forReport && this.props.report) {
            this.props.updateReport({...this.props.report, visibility}, true);
        }

        if (this.props.forTemplate && this.props.template) {
            this.props.updateTemplate({...this.props.template, visibility}, true);
        }
    }

    get visibility() {
        const {forReport, report, forTemplate, template} = this.props;
        if (forReport && report) {
            return report.visibility;
        }

        if (forTemplate && template) {
            return template.visibility;
        }

        return null;
    }

    get createdDate() {
        const {forReport, report} = this.props;
        if (forReport && report && report.createdAt) {
            return moment(report.createdAt).format(constants.SYSTEM_DATE_HUMAN_FORMAT);
        }

        return null;
    }

    get byUser() {
        const {forReport, report, template} = this.props;
        if (forReport) {
            if (report && report.ownerEmployee) {
                return [report.ownerEmployee.firstName, report.ownerEmployee.lastName].filter(x => !!x).join(' ');
            }

            return null;
        }

        if (template && template.ownerEmployee) {
            return [template.ownerEmployee.firstName, template.ownerEmployee.lastName].filter(x => !!x).join(' ')
        }
        return null;
    }

    get isOwner() {
        const {forReport, forTemplate, report, template} = this.props;
        return (forReport && ReportHelper.isCurrentUserOwnerOnReport(report))
            || (forTemplate && ReportTemplatesHelper.isCurrentUserOwnerOnTemplate(template));
    }

    render() {
        const {forReport, forTemplate, report, template} = this.props;
        if (forReport && (!report || !report.id || report.id === -1)) {
            return null;
        }
        if (forTemplate && (!template || !template.id || template.id === -1)) {
            return null;
        }
        if (this.visibility === null) {
            return null;
        }

        const isOwner = this.isOwner;
        const createdDate = this.createdDate;
        const byUser = this.byUser;

        return <Wrapper style={this.props.style}>
            {isOwner &&
                <ToggleCheckbox
                    isChecked={this.visibility === ApiObject.ReportVisibility.public}
                    labelOptions={{yes: intl.get('common'), no: intl.get('personal')}}
                    onChange={(bool: boolean) => {
                        this.onChangeVisibility(
                            bool ? ApiObject.ReportVisibility.public : ApiObject.ReportVisibility.private
                        )
                }} />
            }
            {byUser &&
                <OwnerInfo>
                    {isOwner && createdDate &&
                        <span>{intl.get('created_at')} {createdDate}</span>
                    }
                    {!isOwner &&
                        <>
                            <UserAvatar>
                                <Icon type={constants.IconTypes.USER_SECONDARY} width={16} height={16} fillColor={stylingVariables.colorPalette.mediumGreen} />
                            </UserAvatar>
                            <span>
                                {createdDate} {intl.get('by')} {byUser}
                            </span>
                        </>
                    }
                </OwnerInfo>
            }
        </Wrapper>
    }
}

const mapStateToProps = (state: RootState) => ({
    template: selectors.getTemplate(state),
    report: selectors.getReport(state),
});

const mapDispatchToProps = {
    updateReport,
    updateTemplate
};

export default connect(mapStateToProps, mapDispatchToProps)(VisibilitySelector);
