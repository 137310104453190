import {ApiObject} from '@api';
import {Modal as CommonModal, Layout, Dropdown, Checkbox} from '@common';
import {stylingVariables, interfaces, globalSelectors, constants} from '@global';
import {RouteHelper} from '@library';
import React, {PureComponent} from 'react';
import {intl} from '@global';
import {connect} from 'react-redux';
import styled from 'styled-components';
import Validator from 'validatorjs';
import {RootState} from '../../../../../rootReducer'
import {getTemplates} from '../../../actions';

import * as reportsSelectors from '../../../selectors';
import {changePeriods, changeTemplate, changeReport, setMode as setProcessMode} from '../../redux';
import {GroupedQueriesBuilder,} from '../SelectEmployees/groupedQueries.builder';
import DateRangeRow from '../DateRangeRow';
import EmployeeRow from '../EmployeeRow';
import TemplateRow from '../TemplateRow';
import {getLegalEntityPeriods, canRegenerateMasterdata} from '../../../utils'

const ActionBar = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 30px 85px 50px 0;
    
    button {
        margin-left: 30px;
    }
`;

const StyledCheckbox = styled.div`
  display: flex;
  align-items: center;
  user-select: none;
  min-height: 20px;
`;

const StyledLabel = styled.div`
  display: inline-flex;
  align-items: center;
  position: relative;
  margin-left: 10px;
  font-size: ${stylingVariables.fontSize.default};
  font-weight: ${stylingVariables.fontWeight.regular};
  
  >div {
    margin: 0 0 0 10px;
  }
`

interface IProps {
    report?: ApiObject.Report;

    getTemplates: () => Promise<ApiObject.ReportTemplate[]>;
    legalEntityId: number|null;

    onClose: () => void;

    changeTemplate: (template: ApiObject.ReportTemplate) => any;
    changePeriods: (periods: interfaces.Periods) => any;
    changeReport: (report: ApiObject.Report) => any;

    legalEntityPeriods: ApiObject.LegalEntityPeriod[];
    setProcessMode: (mode: constants.ReportProcessMode) => any;
}

interface IState {
    templates: ApiObject.ReportTemplate[];
    template: ApiObject.ReportTemplate;
    periods: interfaces.Periods,
    templateId: number | string;

    allActiveEmployees: boolean;
    allInactiveEmployees: boolean;
    allDraftEmployees: boolean;
    legalEntityReportType: ApiObject.DataSource;
    isEmployeesSelected: boolean,
    regenerateMasterdata: boolean,
    showCurrencyForAmounts: boolean,
    errors: {
        [key: string]: string[],
    };
}

export class ReportModal extends PureComponent<IProps, IState> {
    private readonly customTemplate: ApiObject.ReportTemplate = {
        id: -1,
        label: intl.get('custom'),
        isTemporary: false,
        employees: {
            queries: []
        },
        columns: [],
        grouping: [
            '$employee.reference_code'
        ],
        visibility: ApiObject.ReportVisibility.public,
        labelFormula: '',
        ownerEmployee: {
            id: -1,
            firstName: '',
            lastName: '',
            referenceCode: ''
        },
    }

    public constructor(props: IProps) {
        super(props);

        this.state = {
            templates: [],
            template: {...this.customTemplate},
            templateId: props.report && props.report.templateId ? props.report.templateId : -1,

            periods: props.report ? props.legalEntityPeriods.filter(p => props.report?.legalEntityPeriodIds.includes(p.id)) : [],
            legalEntityReportType: props.report ? props.report.legalEntityReportType as ApiObject.DataSource ||  ApiObject.DataSource.payroll : ApiObject.DataSource.payroll,
            allActiveEmployees: false,
            allInactiveEmployees: false,
            allDraftEmployees: false,

            isEmployeesSelected: false,
            regenerateMasterdata: props.report ? !!props.report.regenerateMasterdata : false,
            showCurrencyForAmounts: false,

            errors: {}
        };
    }

    private readonly FIELD_TEMPLATE = 'templateId';
    private readonly FIELD_PERIODS = 'periods';
    private readonly FIELD_EMPLOYEES = 'isEmployeesSelected';

    private readonly baseValidationRules = {
        [this.FIELD_TEMPLATE]: 'required',
        [this.FIELD_PERIODS]: 'required',
    }

    private readonly validationRules = {
        ...this.baseValidationRules,
        [this.FIELD_EMPLOYEES]: 'not_in:false'
    };

    private readonly validationMessages = {
        required: 'Field can not be empty',
    };

    async componentDidMount() {
        const templates: ApiObject.ReportTemplate[] = await this.props.getTemplates();
        this.setState({templates}, () => {
            const template = this.state.templates.find(t => t.id === this.state.templateId);
            if (template) {
                this.onChangeTemplate(template.id);
            }
        });

        // Select report in store
        if (this.props.report) {
            this.props.setProcessMode(constants.ReportProcessMode.editReport);
            await this.props.changeReport(this.props.report);
        } else {
            this.props.setProcessMode(constants.ReportProcessMode.createReport);
        }
    }

    get templates() {
        return [
            this.customTemplate,
            ...this.state.templates
        ]
    }

    get legalEntityPeriods() {
        return this.state.legalEntityReportType === ApiObject.DataSource.payroll ?
          this.props.legalEntityPeriods.filter(period => period.periodStatus === 'done') :
          this.props.legalEntityPeriods.filter(period => period.periodStatus !== 'scheduled')
    }

    get selectedLegalEntityPeriods() {
        return getLegalEntityPeriods(this.state.periods, this.props.legalEntityPeriods)
    }

    get canRegenerateMasterdata() {
        return canRegenerateMasterdata(this.state.legalEntityReportType, this.selectedLegalEntityPeriods)
    }

    render() {
        return (
            <CommonModal.CenterModal width={'750px'} minWidth={'750px'} onClickClose={this.props.onClose}>
                <CommonModal.StyledHeader
                    style={{padding: '20px 80px'}}
                    color={stylingVariables.colorPalette.darkest}
                >
                    <h4>{intl.get(this.props.report && this.props.report.id !== -1 ? 'update_report' : 'new_report')}</h4>
                </CommonModal.StyledHeader>

                <CommonModal.OverflowableContent style={{padding: '20px 80px'}}>
                    <Layout.Rows>
                        <TemplateRow
                            defaultValue={`${this.state.templateId}`}
                            templates={this.templates}
                            onChange={this.onChangeTemplate}
                            errors={this.state.errors[this.FIELD_TEMPLATE]}
                        />
                        <Layout.Row>
                            <Layout.RowLabel percent={30}>
                                {intl.get('data_source')} *
                            </Layout.RowLabel>

                            <Layout.RowValue percent={70}>
                                <Dropdown
                                    selectedValue={this.state.legalEntityReportType}
                                    list={[
                                        {label: intl.get('payroll_calculation'), key: ApiObject.DataSource.payroll},
                                        {label: intl.get('employee_record'), key: ApiObject.DataSource.employee},
                                    ]}
                                    onChange={this.onChangeDataSource}
                                />
                            </Layout.RowValue>
                        </Layout.Row>
                        <DateRangeRow
                            allowPeriods={this.legalEntityPeriods.map(p => ({beginDate: p.beginDate, endDate: p.endDate}))}
                            periods={this.state.periods}
                            onChange={this.onChangePeriods}
                            errors={this.state.errors[this.FIELD_PERIODS]}
                            multiselect={false}
                        />

                        <Layout.Row>
                            <Layout.RowLabel percent={30} />
                            <Layout.RowValue percent={70}>
                                {this.canRegenerateMasterdata &&
                                <StyledCheckbox>
                                    <Checkbox
                                        isChecked={this.state.regenerateMasterdata}
                                        onClick={this.onChangeRegenerateMasterdata}
                                    />
                                    <StyledLabel>
                                        {intl.get('regenerate_masterdata')}
                                    </StyledLabel>
                                </StyledCheckbox>}
                            </Layout.RowValue>
                        </Layout.Row>

                        <Layout.Row>
                            <Layout.RowLabel percent={30}>
                                {intl.get('currency')}
                            </Layout.RowLabel>

                            <Layout.RowValue percent={70}>
                                <StyledCheckbox>
                                    <Checkbox
                                      isChecked={this.state.showCurrencyForAmounts}
                                      onClick={() =>
                                        this.setState({showCurrencyForAmounts: !this.state.showCurrencyForAmounts})}
                                    />
                                    <StyledLabel>
                                        {intl.get('show_currency')}
                                    </StyledLabel>
                                </StyledCheckbox>
                            </Layout.RowValue>
                        </Layout.Row>

                        <EmployeeRow
                            template={this.state.template}
                            allActiveEmployees={this.state.allActiveEmployees}
                            allInactiveEmployees={this.state.allInactiveEmployees}
                            allDraftEmployees={this.state.allDraftEmployees}
                            onClickCheckbox={this.toggleEmployees}
                            onManualSelect={this.onManualSelect}
                            errors={this.state.errors[this.FIELD_EMPLOYEES]}
                            isDraftAllowed={this.state.legalEntityReportType !== ApiObject.DataSource.payroll}
                        />
                    </Layout.Rows>
                </CommonModal.OverflowableContent>

                <ActionBar>
                    <CommonModal.StyledCancelButton onClick={this.props.onClose}>
                        {intl.get('cancel')}
                    </CommonModal.StyledCancelButton>

                    <CommonModal.StyledOkButton onClick={this.onContinue}>
                        {intl.get('continue')}
                    </CommonModal.StyledOkButton>

                </ActionBar>
            </CommonModal.CenterModal>
        );
    }

    private isValid = (rules: any = null) => {
        const validator = new Validator(
            this.state,
            rules ? rules : this.baseValidationRules,
            this.validationMessages
        );
        validator.check();
        const isOk = validator.passes();
        if (isOk) {
            this.setState({errors: {}});
        } else {
            this.setState({errors: validator.errors.errors});
        }

        return isOk;
    }

    private updateTemplateFromState = (): ApiObject.ReportTemplate|null => {
        if (!this.state.template) {
            return null;
        }
        const queries = (this.state.template as ApiObject.ReportTemplate).employees ? (this.state.template as ApiObject.ReportTemplate).employees.queries : [];
        let groupedQueriesBuilder = GroupedQueriesBuilder.build(queries);

        groupedQueriesBuilder.selectAll(this.state.allActiveEmployees, ApiObject.Status.active);
        groupedQueriesBuilder.selectAll(this.state.allInactiveEmployees, ApiObject.Status.inactive);
        groupedQueriesBuilder.selectAll(this.state.allDraftEmployees, ApiObject.Status.draft)

        return {
            ...this.state.template,
            employees: {
                ...(this.state.template as ApiObject.ReportTemplate).employees,
                queries: groupedQueriesBuilder.queries()
            }
        }
    }

    private save = async () => {
        const template: ApiObject.ReportTemplate|null = this.updateTemplateFromState();
        if (!template) {
            return null;
        }

        if (!this.props.report) {
            await this.props.changeReport({
                id: -1,
                label: '',
                employeeIds: [],
                ownerEmployee: {
                    firstName: null,
                    id: null,
                    lastName: null,
                    referenceCode: null
                },
                status: ApiObject.ReportStatus.draft,
                legalEntityReportType: this.state.legalEntityReportType,

                legalEntityPeriodIds: [],
                visibility: ApiObject.ReportVisibility.public,
                isTemporary: false,
                templateId: template.id,
                regenerateMasterdata: this.state.regenerateMasterdata && this.canRegenerateMasterdata,
                showCurrencyForAmounts: this.state.showCurrencyForAmounts,
            })
        } else {
            await this.props.changeReport({
                ...this.props.report,
                templateId: template.id,
                legalEntityReportType: this.state.legalEntityReportType,
                regenerateMasterdata: this.state.regenerateMasterdata && this.canRegenerateMasterdata,
                showCurrencyForAmounts: this.state.showCurrencyForAmounts
            })
        }

        await this.props.changeTemplate(template);
        await this.props.changePeriods(this.state.periods as interfaces.Periods);
    }

    private onManualSelect = async () => {
        if (this.isValid()) {
            await this.save();
            RouteHelper.goToReportSelectEmployees();
        }
    }

    private onContinue = async () => {
        if (this.isValid(this.validationRules)) {
            await this.save();
            RouteHelper.goToReportSelectData();
            this.props.onClose();
        }
    }

    private onChangeDataSource = (legalEntityReportType: ApiObject.DataSource) => {
        if (legalEntityReportType === ApiObject.DataSource.payroll) {
            this.setState({allDraftEmployees: false})
            const isPeriodsValid = this.state.periods.every(period =>
              this.props.legalEntityPeriods
                .filter(period => period.periodStatus === 'done')
                .find(legalEntityPeriod =>
                  legalEntityPeriod.beginDate === period.beginDate && legalEntityPeriod.endDate === period.endDate
                )
            )

            if(!isPeriodsValid){
                this.setState({periods: []});
            }
        }
        this.setState({legalEntityReportType});
    };

    private onChangePeriods = (periods: interfaces.Periods) => this.setState({periods});
    private onChangeRegenerateMasterdata = () => this.setState({regenerateMasterdata: !this.state.regenerateMasterdata});
    private onChangeTemplate = (templateId: number) => {
        const template: ApiObject.ReportTemplate|undefined = this.templates.find(t => t.id === templateId)
        if (!template) {
            return;
        }
        this.setState({
            templateId,
            template,
        })
    }
    private toggleEmployees = (key: string) => {
        this.setState({...this.state, [key as keyof IState]: !this.state[key as keyof IState]}, () => {
            const template = this.updateTemplateFromState() as ApiObject.ReportTemplate;
            this.setState({
                template,
                isEmployeesSelected: this.state.allActiveEmployees || this.state.allInactiveEmployees || this.state.allDraftEmployees
            })
        });
    }
}

const mapStateToProps = (state: RootState) => ({
    legalEntityPeriods: reportsSelectors.getLegalEntityPeriods(state),
    legalEntityId: globalSelectors.getCurrentLegalEntityId(state),
});

const mapDispatchToProps = {
    getTemplates,
    setProcessMode,
    changePeriods,
    changeTemplate,
    changeReport
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportModal);
