import {ApiObject} from '@api';
import React, {CSSProperties} from 'react';
import {Column} from 'react-virtualized';

import {interfaces} from '@global';
import {defaultInfiniteTableCellDataGetter, InfiniteTableHeader} from '@common';
import {cellRendererCreator} from '../functions';

interface ColumnProps {
    dataKey: string;
    label: string;
    width?: number;
    flexGrow?: number;
    flexShrink?: number;
    className?: string;
    type?: string;
    style?: CSSProperties;

    orders?: interfaces.ListingOrders;
    changeOrder?: (column: string) => any;
}

const Ordered: React.FC<ColumnProps> = ({
    className,
    dataKey,
    label,
    width = 150,
    flexGrow = 1,
    flexShrink = 1,
    type = ApiObject.FieldType.text,
    orders,
    changeOrder,
    style = {},
}: ColumnProps) => (
    <Column
        className={className}
        dataKey={dataKey}
        width={width}
        style={style}
        flexGrow={flexGrow}
        flexShrink={flexShrink}
        cellDataGetter={defaultInfiniteTableCellDataGetter}
        headerRenderer={() => <InfiniteTableHeader.Ordered
            label={label}
            dataKey={dataKey}
            orders={orders}
            changeOrder={changeOrder}
        />}
        cellRenderer={cellRendererCreator(type, dataKey)}
    />
);

export default Ordered;
