import {CollapseCircle} from '@common';
import {stylingVariables} from '@global';
import React, {PureComponent} from 'react';
import {connect} from 'react-redux';

import {ApiObject, EmployeeInterfaces} from '@api';
import styled from 'styled-components';
import {setEmployeeCardCollapsed} from '../redux'
import * as selectors from '../selectors';

import EosReason from './EosReason';
import ContactCard from './ContactCard';
import {RootState} from '../../../rootReducer';

const Wrapper = styled.div<{isCollapsed: boolean}>`
  min-width: ${props => props.isCollapsed ? 100 : 365}px;
  position: relative;
`;
const StyledCollapseCircle = styled.div<{isCollapsed: boolean}>`
  position: absolute;
  right: -13px;
  top: ${props => props.isCollapsed ? 45 : 150}px;
  padding: 6px;
  background: ${stylingVariables.colorPalette.gray};
  border-radius: 50%;
`;

interface IProps {
    employee: EmployeeInterfaces.Employee;
    fields: ApiObject.Fields;
    isCreateMode: boolean;
    isCollapsed: boolean;
    setEmployeeCardCollapsed: (isCollapsed: boolean) => void;
}
export class PersonalDetails extends PureComponent<IProps> {
    private toggleCollapsed (): void {
        const {isCollapsed} = this.props

        this.props.setEmployeeCardCollapsed(!isCollapsed)
    }

    public render() {
        const {isCollapsed} = this.props;

        return (
            <Wrapper isCollapsed={isCollapsed}>
                <ContactCard
                    employee={this.props.employee}
                    fields={this.props.fields}
                    isCreateMode={this.props.isCreateMode}
                    isCollapsed={isCollapsed}
                />

                <EosReason
                    employee={this.props.employee}
                    isCollapsed={isCollapsed}
                />

                <StyledCollapseCircle isCollapsed={isCollapsed}>
                    <CollapseCircle isCollapsed={isCollapsed} onToggle={
                        () => this.toggleCollapsed()
                    } />
                </StyledCollapseCircle>
            </Wrapper>
        );
    }
}


const mapStateToProps = (state: RootState) => ({
    isCollapsed: selectors.isEmployeeCardCollapsed(state),
  });
  
  const mapDispatchToProps = {
    setEmployeeCardCollapsed,
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(PersonalDetails);
