import {createSelector} from 'reselect';
import {orderBy, cloneDeep} from 'lodash';
import {constants} from '@global';
import {ApiObject} from '@api';
import {RootState} from '../rootReducer';
import Cookies from 'js-cookie'

export const getLocale = (state:RootState) => state.global.locale;
export const getErrorModalProps = (state:RootState) => state.global.errorModal;
export const isMenuCollapsed = (state:RootState): boolean => state.global.isMenuCollapsed;
export const isLoading = (state:RootState): boolean => !!state.global.isLoading;
export const getLoadingMessage = (state:RootState): string => state.global.loadingMessage;
export const getAuthorizedUser = (state:RootState): ApiObject.AuthorizedUser|null => state.global.authorizedUser;
export const getSessionToken = (state:RootState): string|null => {
    return state.global.authorizedUser
        ? state.global.authorizedUser.sessionToken
        : null;
};
export const getCurrentLegalEntityId = (state: RootState): number|null => state.global.currentLegalEntityId;
export const getCurrentPayrollPeriod = (state: RootState) => state.global.currentPayrollPeriod;
export const getSearchQuery = (state: RootState): string => state.global.searchQuery;

export const getLegalEntities = (state:RootState, props: any): ApiObject.LegalEntity[] =>
    state.global.legalEntities.map(item => {
        item.name = item.brandName || item.legalName;
        return item;
    }
);

export const getUserLegalEntity = (state: RootState): ApiObject.LegalEntity|null => {
    const userEntityId = state.global.authorizedUser?.employee.legalEntity.id || null

    if (userEntityId) {
        const entity = state.global.legalEntities.find((entity: ApiObject.LegalEntity) => {
            return entity.id === userEntityId
        })

        return entity || null
    }

    return null
}

export const getConsolidationGroups = (state:RootState): ApiObject.ConsolidationGroup[] => {
    let copied = cloneDeep(state.global.consolidationGroups)

    return copied.map((item: ApiObject.ConsolidationGroup) => {
        item.name = item.name || item.parentLegalEntity.name;
        if (item.legalEntities && item.parentLegalEntity) {
            item.legalEntities = [item.parentLegalEntity, ...item.legalEntities];
        }

        return item;
    });
}

export const currentConsolidationGroupId = (state: RootState): any => {
    return getCurrentConsolidationGroup(state)?.id
}

export const getCurrentConsolidationGroup  = createSelector(
    [getConsolidationGroups, getCurrentLegalEntityId],
    (groups, legalEntityId): any => {

        let copied = cloneDeep(groups)

        return copied
            .find((group: ApiObject.ConsolidationGroup) => {
                return group?.legalEntities?.find((entity: ApiObject.parentLegalEntity) =>
                    entity.id === legalEntityId
                )
            });
    },
);
export const getEntitySelectorList = (state: RootState, props: any): any => {
    return getGroupedConsolidationGroups(state, props)
}

export const getAlphabetEntitiesSearchQuery = (state:RootState, props: any): string => {
    return props.searchQuery.trim().toLowerCase();
};

export const getGroupedConsolidationGroups = createSelector(
    [getConsolidationGroups, getAlphabetEntitiesSearchQuery],
    (groups, searchQuery): any[] => {

        let copied = cloneDeep(groups)

        if (searchQuery !== '') {
            groups = copied
            .filter((group: ApiObject.ConsolidationGroup) => {
                let isMatch = false

                if (group.name.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1) {
                    isMatch = true
                }

                if (group.legalEntities) {
                    let matchingEntities = group.legalEntities.filter((entity: ApiObject.parentLegalEntity) =>
                        entity.name.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1
                    )

                    if (matchingEntities && matchingEntities.length > 0) {
                        group.legalEntities = matchingEntities
                        isMatch = true
                    }
                }
                return isMatch
            });
        }

        groups = orderBy(groups, ['name']);

        return groups.reduce((previous: any, current: any) => {
            const group = current.name ? current.name[0] : current.parentLegalEntity.name[0];

            if (!previous[group]) {
                previous[group] = {group, children: [current]};
            } else {
                previous[group].children.push(current);
            }

            return previous;
        }, {});
    },
);

export const getSearchedAlphabetEntities = createSelector(
    [getLegalEntities, getAlphabetEntitiesSearchQuery],
    (entities, searchQuery): any[] => {
        entities = entities
            .filter((entity: ApiObject.LegalEntity) => entity.name.toLowerCase().indexOf(searchQuery) > -1);
        entities = orderBy(entities, ['name']);

        return entities.reduce((previous: any, current: any) => {
            const group = current.name[0];

            if (!previous[group]) {
                previous[group] = {group, children: [current]};
            } else {
                previous[group].children.push(current);
            }

            return previous;
        }, {});
    },
);

export const getCurrentLegalEntity = createSelector(
    [getCurrentLegalEntityId, getLegalEntities],
    (currentLegalEntityId: number|null, legalEntities: ApiObject.LegalEntity[]): ApiObject.LegalEntity|null => {
        const entity = legalEntities.find(
            (legalEntity: ApiObject.LegalEntity) => legalEntity.id === currentLegalEntityId,
        );

        return entity || null;
    },
);


export const getDefaultLanguage = createSelector(
  [getCurrentLegalEntity],
  (legalEntity: ApiObject.LegalEntity | null): string => {
    return legalEntity?.defaultLanguage?.code || 'en'
  },
);

export const getUsingDefaultLanguage = (): boolean => {
    return Cookies.get('locale') !== 'en'
}

export const getCurrentRoles = (state:RootState, props: any): constants.Role[] => {
    if (state.global.authorizedUser &&
        state.global.authorizedUser.employee &&
        state.global.authorizedUser.employee.roles
    ) {
        return state.global.authorizedUser.employee.roles
            .filter((x: any) => x.legalEntity.id === state.global.currentLegalEntityId)
            .map((item: any) => item.role);
    }

    return [];
};

export const getWelcomeMessage = createSelector(
    [getCurrentLegalEntity],
    (legalEntity): string => {
        return legalEntity ? legalEntity.defaultWelcomeMessage || '' : '';
    },
);

export const getEmployeeMasterdataEditAllowed = (state:RootState) => state.employee.employeeMasterdataEditAllowed;

export const masterdataEditAllowed = createSelector(
    [getCurrentLegalEntity, getEmployeeMasterdataEditAllowed],
    (currentLegalEntity: ApiObject.LegalEntity|null, employeeMasterdataEditAllowed): boolean => {
        return !!currentLegalEntity?.masterdataEditAllowed && employeeMasterdataEditAllowed;
    },
);

export const getPaymentProcessor = createSelector(
    [getCurrentLegalEntity],
    (legalEntity): string | null => {
        const output = legalEntity && legalEntity.paymentProcessor ? legalEntity.paymentProcessor : null;
        return output
    },
);
