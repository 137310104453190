import {stylingVariables} from '@global';
import React, {PureComponent} from 'react';
import styled from 'styled-components';

const Wrapper = styled.div<{size: number}>`
	position: relative;
	height: ${props => props.size}px;
	width: ${props => props.size}px;
	border-radius: 50%;
	background-color: ${stylingVariables.colorPalette.deepGray};
	transform: rotate(45deg);
	color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: ${props => Math.round(props.size * 0.7)}px;
	cursor: pointer;
	
	opacity: .8;
	&:hover {
		transition: all .2s;
		background-color: ${stylingVariables.colorPalette.orange};
		opacity: 1;
	}
`;

interface IProps {
	size: number;
}

export class RemoveIcon extends PureComponent<IProps> {
	static defaultProps = {
		size: 14
	}

	public render() {
		return (
			<Wrapper size={this.props.size}>+</Wrapper>
		);
	}
}
export default RemoveIcon;
