import { ApiObject } from '@api'
import React, { PureComponent } from 'react'
import styled from 'styled-components'

import { constants, intl } from '@global'
import { Can, StyledEditButton } from '@common'
import { get } from 'lodash'

import Group from './Group'

const Wrapper = styled.div``
const LeftSide = styled.div`
  padding-left: 37px;
  flex: 0 0 60%;
  min-width: 690px;
`

interface IProps {
  fields: ApiObject.Fields
  fieldGroup: ApiObject.FieldGroup

  onClickEditButton: () => void
}

class View extends PureComponent<IProps> {
  private renderSecondaryBankAccount(subFieldGroup: ApiObject.FieldGroup) {
    if (subFieldGroup.code === constants.DEFAULT_CODE_SECONDARY_BANK_ACCOUNT) {
      return subFieldGroup.fields.map((field: ApiObject.Field) => {
        if (field.code === constants.DEFAULT_FIELD_CODE_SWIFT) {
          const value = get(this.props.fields, `${field.entity}.${field.code}`)
          if (value) {
            return <Group key={subFieldGroup.code} fields={this.props.fields} group={subFieldGroup} />
          }
          return null
        }
        return null
      })
    }

    return <Group key={subFieldGroup.code} fields={this.props.fields} group={subFieldGroup} />
  }

  public render() {
    return (
      <Wrapper>
        <LeftSide>
          <Group fields={this.props.fields} group={this.props.fieldGroup} />

          {this.props.fieldGroup.subgroups.map((subFieldGroup: ApiObject.FieldGroup) =>
            this.renderSecondaryBankAccount(subFieldGroup)
          )}
        </LeftSide>
        <Can run={constants.PermissionCode.EMPLOYEE_FIELDS_EDIT}>
          <StyledEditButton onClick={this.props.onClickEditButton}>{intl.get('edit')}</StyledEditButton>
        </Can>
      </Wrapper>
    )
  }
}

export default View
