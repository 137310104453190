import React, {PureComponent} from 'react';
import styled from 'styled-components';

import {constants, stylingVariables} from '@global';
import {ApiObject} from '@api';
import {Icon} from '@common';

import LegalEntitySelector from './LegalEntitySelector';

const StyledCompanyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  z-index: 100;
  @media (max-height: 800px) {
    min-height: 150px;
  }
`;

export const StyledLegalName = styled.span`
  line-height: 1.1rem;
  text-align: center;
  color: ${stylingVariables.colorPalette.dark};
  font-weight: ${stylingVariables.fontWeight.bold};
  font-size: ${stylingVariables.fontSize.medium};
  margin: 8px 15px 0;
`;

const StyledLogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: calc(100% - 30px);
  margin-top: 10px;
  max-height: 60px;

  img {
    max-width: 100%;
    max-height: 60px;
  }
`;

interface IProps {
    currentLegalEntity: ApiObject.LegalEntity;
}

export default class CurrentEntity extends PureComponent<IProps> {
    render() {
        return (
            <StyledCompanyWrapper>
                <StyledLogoWrapper>
                    {
                        this.props.currentLegalEntity.logo
                            ? <img src={this.props.currentLegalEntity.logo} alt={'entity'}/>
                            : <Icon type={constants.IconTypes.SKYLINE}
                                    fillColor={stylingVariables.colorPalette.green}
                                    width={30}
                                    height={30}
                            />
                    }
                </StyledLogoWrapper>

                <StyledLegalName>{this.props.currentLegalEntity.name}</StyledLegalName>

                <LegalEntitySelector/>
            </StyledCompanyWrapper>
        );
    }
}
