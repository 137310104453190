import {ApiObject} from '@api';
import React, {CSSProperties} from 'react';
import {Column} from 'react-virtualized';

import {defaultInfiniteTableCellDataGetter} from '@common';
import {cellRendererCreator} from '../functions';

interface DefaultColumnProps {
    dataKey: string;
    label: string;
    width?: number;
    flexGrow?: number;
    flexShrink?: number;
    style?: CSSProperties;
    type?: string;
    headerClassName?: string,
}

const Default: React.FC<DefaultColumnProps> = ({
    dataKey,
    label,
    width = 80,
    flexGrow = 1,
    flexShrink = 1,
    style = {},
    type = ApiObject.FieldType.text,
    headerClassName,
}: DefaultColumnProps) => (
    <Column
        label={label}
        dataKey={dataKey}
        width={width}
        style={style}
        flexGrow={flexGrow}
        flexShrink={flexShrink}
        cellDataGetter={defaultInfiniteTableCellDataGetter}
        cellRenderer={cellRendererCreator(type, dataKey)}
        headerClassName={headerClassName}
    />
);

export default Default;
