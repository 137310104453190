import { ApiObject } from '@api'

export default class FieldGroupHelper {
    public static extractFields(fieldGroups: ApiObject.FieldGroup[]): ApiObject.Field[] {
        let fields: ApiObject.Field[] = []

        const groupFields = (group: ApiObject.FieldGroup) => {
            fields = [...fields, ...group.fields]
            if (group.subgroups && group.subgroups.length > 0) {
                group.subgroups.forEach((s: ApiObject.FieldGroup) => groupFields(s))
            }
        }

        fieldGroups.forEach((group: any) => {
            groupFields(group)
        })

        return fields
    }

    public static getFieldFormulaFromMasterdataField = (field: ApiObject.Field) => {
        const basic = FieldGroupHelper.getBasicFieldKey(field)
        switch (field.defaultMssReportOutputFormat) {
            case ApiObject.MssReportOutputFormat.key:
                return `$${basic}::key`
            case ApiObject.MssReportOutputFormat.value:
                return `$${basic}::orLabel`
            case ApiObject.MssReportOutputFormat.key_value:
                return `$${basic}::orAndLabel`
            default:
                return `$${basic}`
        }
    }

    public static getFieldKeyFromMasterdataFormula = (formula: string): string => {
        return formula
          .replace('$', '')
          .replace('::key', '')
          .replace('::orLabel', '')
          .replace('::orAndLabel', '')
    }

    public static getFormulaForMasterdata = (key: string): string => {
        return `$${key}`
    }

    public static getBasicFieldKey = (field: ApiObject.Field): string => {
        return `${field.entity}.${field.code}`
    }
}
