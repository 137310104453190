import { ApiObject } from '@api'
import { AccessManager, EnvironmentHelper } from '@library'
import { intl, constants } from '@global'

export default class MenuLinks {
  public static customTiles(
    authorizedUser: ApiObject.AuthorizedUser,
    legalEntity: ApiObject.LegalEntity | null
  ) {
    let tiles = [
      {
        name: 'help',
        title: intl.get('help_and_support'),
        notCustom: true,
        icon: constants.hrb.IconTypes.helpSupport,
        disabled: !AccessManager.hasAtLeastOneModuleEnabled(
          ['help'],
          authorizedUser
        ),
        url: EnvironmentHelper.getHelpSupportUrl(),
      },
      {
        name: 'secureFileTransfer',
        title: intl.get('secure_file_transfer'),
        notCustom: true,
        icon: constants.hrb.IconTypes.SECURE_FILE_TRANSFER,
        disabled: !AccessManager.enabledApplication(
          'secureFileTransfer',
          authorizedUser,
          legalEntity
        ),
        url: EnvironmentHelper.getEdriveUrl(),
        clientId: EnvironmentHelper.getEdriveClientId(),
      },
    ]

    if (authorizedUser.employee.customModules) {
      const modules = Object.keys(constants.ModuleCodes).filter(
        (x) => !(parseInt(x) >= 0)
      )

      const filtered = authorizedUser.employee.customModules
        .filter((customModule) => {
          return !modules.includes(customModule.moduleCode)
        })
        .map((item) => {
          return {
            name: item.moduleCode,
            title: item.title,
            notCustom: false,
            icon: item.icon,
            disabled: false,
            url: item.url,
          }
        })

      tiles = tiles.concat(filtered)
    }

    return tiles.filter((tile) => {
      return !tile.disabled
    })
  }

  public static appTiles(
    authorizedUser: ApiObject.AuthorizedUser,
    legalEntity: ApiObject.LegalEntity | null,
    currentLegalEntity: ApiObject.LegalEntity | null
  ) {
    const buttons = [
      {
        icon: constants.hrb.IconTypes.PAC,
        name: 'pac',
        title: 'payroll_admin_console',
        description: 'pac_subtitle',
        isEnabled: AccessManager.enabledApplication(
          'pac',
          authorizedUser,
          legalEntity
        ),
      },
      {
        icon: constants.hrb.IconTypes.ESS,
        name: 'ess',
        title: 'employee_self_service',
        description: 'employee_subtitle',
        isEnabled: AccessManager.enabledApplication(
          'ess',
          authorizedUser,
          legalEntity
        ),
        url: EnvironmentHelper.getLaunchpadUrl() + '/ess/quick-access',
        clientId: EnvironmentHelper.getLaunchpadClientId(),
      },
      {
        icon: constants.hrb.IconTypes.ADMIN_SETTINGS,
        name: 'settings',
        title: intl.get('settings'),
        isEnabled: AccessManager.enabledApplication(
          'settings',
          authorizedUser,
          legalEntity
        ),
        url:
          EnvironmentHelper.getLaunchpadUrl() +
          '/settings/quick-access/' +
          currentLegalEntity?.id,
        clientId: EnvironmentHelper.getLaunchpadClientId(),
      },
    ]

    return buttons.filter((button) => {
      return button.isEnabled
    })
  }
}
