import {AnyAction, combineReducers} from 'redux';
import global, {
    GlobalState,
    defaultState as globalDefaultState,
    SELECT_LEGAL_ENTITY,
    SING_OUT,
} from './entrypoints/redux';
import employees, {EmployeesState, defaultState as employeesDefaultState} from './entrypoints/Employees/redux';
import employee, {EmployeeState, defaultState as employeeDefaultState} from './entrypoints/Employee/redux';
import leaves, {LeavesState, defaultState as leavesDefaultState} from './entrypoints/Leaves/redux';
import employeeLeaves, {EmployeeLeavesState, defaultState as employeeLeaveDefaultState} from './entrypoints/Leaves/EmployeeLeaves/redux';
import reports, {RootReportsState, defaultState as rootReportsState} from './entrypoints/Reports/redux';

export interface RootState {
    global: GlobalState;

    employees: EmployeesState;
    employee: EmployeeState;

    leaves: LeavesState;
    employeeLeaves: EmployeeLeavesState;

    reports: RootReportsState;
}

const appReducer = combineReducers({
    global,

    employees,
    employee,

    leaves,
    employeeLeaves,

    reports,
});

export const defaultState = {
    global: globalDefaultState,

    employees: employeesDefaultState,
    employee: employeeDefaultState,

    leaves: leavesDefaultState,
    employeeLeaves: employeeLeaveDefaultState,

    reports: rootReportsState,
};

export default (state: any, action: AnyAction) => {
    if ([SING_OUT].includes(action.type)) {
        return appReducer(undefined, action);
    }

    if (action.type === SELECT_LEGAL_ENTITY) {
        return appReducer(
            {...defaultState, global: state.global},
            action,
        );
    }

    return appReducer(state, action);
};
