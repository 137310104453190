import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';
import {intl} from '@global';
import ReactResizeDetector from 'react-resize-detector';

import {Actions, Can} from '@common';
import {constants} from '@global';
import {createDocumentModal, getDocuments} from '../../redux';

import Table from './Table/Table';
import Crud from './Crud/Crud';
import {StyledFieldsGroupHeader} from '../Content';

const StyledWrapper = styled.div`
    min-height: 400px;
    padding: 45px 57px 20px;
`;

const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 0 20px;
`;

interface IProps {
    getDocuments: () => any;
    createDocumentModal: () => any;
}

interface IState {
    rect: any;
}

class Documents extends PureComponent<IProps, IState> {
    public constructor(props: IProps) {
        super(props);

        props.getDocuments();
    }

    public render() {
        return (
            <StyledWrapper>
                <StyledHeader>
                    <StyledFieldsGroupHeader>{intl.get('documents')}</StyledFieldsGroupHeader>

                    <Can run={constants.PermissionCode.EMPLOYEE_DOCUMENTS_EDIT}>
                        <Actions.PlusAction onClick={this.props.createDocumentModal} size={30} fontSize={15}>
                            {intl.get('add')}
                        </Actions.PlusAction>
                    </Can>
                </StyledHeader>

                <ReactResizeDetector handleWidth={true}>
                    <Table />
                </ReactResizeDetector>
                <Crud />
            </StyledWrapper>
        );
    }
}

const mapDispatchToProps = {
    getDocuments,
    createDocumentModal,
};

export default connect(null, mapDispatchToProps)(Documents);
