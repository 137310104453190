import {ApiObject} from '@api';
import React, {PureComponent} from 'react';
import {Checkbox} from '@common';

import {stylingVariables, intl} from '@global';
import {Layout, IconPopover} from '@common';
import styled from 'styled-components';
import {AllowedStatus, GroupedQueriesBuilder} from './SelectEmployees/groupedQueries.builder';

const StyledColumnWrapper = styled.li`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: 7px 0;
  background-color: ${stylingVariables.colorPalette.white};
  margin-bottom: 5px;
  z-index: 3;

  >div {
    margin: 3px 0;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      cursor: pointer;
    }
  }

  svg {
    cursor: move;
  }
`;

const StyledCheckbox = styled.div<{isDisabled: boolean}>`
  user-select: none;
  min-height: 20px;
  ${props => props.isDisabled && `
    div {
        border-color: ${stylingVariables.colorPalette.darkGray};
    }
    span {
        color: ${stylingVariables.colorPalette.darkGray};
    }
    cursor: default;
    pointer-events: none;
  `}
`;

const ManualSelect = styled.span`
  font-weight: ${stylingVariables.fontWeight.bold};
  color: ${stylingVariables.colorPalette.green};
  font-size: ${stylingVariables.fontSize.default};
  margin-top: 15px;
  margin-left: -15px;
  border-radius: 15px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
  &:hover {
    background-color: ${stylingVariables.colorPalette.lightGreen};
  }
`

const StyledLabel = styled.div<{isChecked: boolean}>`
  display: inline-flex;
  align-items: center;
  position: relative;
  margin-left: 10px;
  font-size: ${stylingVariables.fontSize.default};
  font-weight: ${stylingVariables.fontWeight.regular};

  ${props => props.isChecked && `
    color: ${stylingVariables.colorPalette.green};
  `}
  
  >div {
    margin: 0 0 0 10px;
  }
`

interface IProps {
    template: ApiObject.ReportTemplate;
    defaultName?: string;
    errors?: string[];

    onClickCheckbox: (key: string) => any;
    onManualSelect: () => any;
    allActiveEmployees: boolean;
    allInactiveEmployees: boolean;
    allDraftEmployees: boolean;

    isDisabledQuickFilters: boolean;
    isDraftAllowed: boolean;
}

interface IState {
}

export default class EmployeeRow extends PureComponent<IProps, IState> {
    static defaultProps = {
        isDisabledQuickFilters: false,
        isDraftAllowed: true,
    }

    private onClickCheckbox = (key: string) => this.props.onClickCheckbox(key);

    isNotAllEmployeesSelectedByStatus = (status: AllowedStatus) => {
        const {template} = this.props;

        let groupedQueriesBuilder = GroupedQueriesBuilder.build(template.employees ? template.employees.queries : [], status);
        if (!groupedQueriesBuilder.isSelectedAll()) {
            return false;
        }
        return groupedQueriesBuilder.getIds().length > 0 || groupedQueriesBuilder.getNotIds().length > 0;
    }

    private renderRowValue() {

      return (
        <StyledColumnWrapper>
          <StyledCheckbox isDisabled={this.props.isDisabledQuickFilters}>
            <Checkbox
              isChecked={this.props.allActiveEmployees}
              onClick={() => this.onClickCheckbox('allActiveEmployees')}
            />
            <StyledLabel isChecked={this.props.allActiveEmployees}>
              {intl.get('select_active')}
              {this.isNotAllEmployeesSelectedByStatus(ApiObject.Status.active) &&
                <IconPopover type={'info'} message={intl.get('not_all_employees_with_this_status_have_been_selected')} iconSize={12} />
              }
            </StyledLabel>
          </StyledCheckbox>
          <StyledCheckbox isDisabled={this.props.isDisabledQuickFilters}>
            <Checkbox
              isChecked={this.props.allInactiveEmployees}
              onClick={() => this.onClickCheckbox('allInactiveEmployees')}
            />
            <StyledLabel isChecked={this.props.allInactiveEmployees}>
              {intl.get('select_inactive')}
              {this.isNotAllEmployeesSelectedByStatus(ApiObject.Status.inactive) &&
                <IconPopover type={'info'} message={intl.get('not_all_employees_with_this_status_have_been_selected')} iconSize={12} />
              }
            </StyledLabel>
          </StyledCheckbox>
          <StyledCheckbox isDisabled={this.props.isDisabledQuickFilters || !this.props.isDraftAllowed}>
            <Checkbox
              isChecked={this.props.allDraftEmployees}
              onClick={() => this.onClickCheckbox('allDraftEmployees')}
            />

            <StyledLabel isChecked={this.props.allDraftEmployees && this.props.isDraftAllowed}>
              {intl.get('select_draft')}
              {this.isNotAllEmployeesSelectedByStatus(ApiObject.Status.draft) &&
                <IconPopover type={'info'} message={intl.get('not_all_employees_with_this_status_have_been_selected')} iconSize={12} />
              }
            </StyledLabel>
          </StyledCheckbox>
          <ManualSelect onClick={() => this.props.onManualSelect()}>
            {intl.get('manual_employee_select')}
          </ManualSelect>
        </StyledColumnWrapper>
      );
    }

    public render() {
        return (
          <Layout.Row hasError={!!this.props.errors}>
            <Layout.RowLabel percent={30} top={14}>
                {intl.get('employees')} *
            </Layout.RowLabel>

            <Layout.RowValue percent={70}>
                {this.renderRowValue()}
            </Layout.RowValue>
        </Layout.Row>
        );
    }
}
