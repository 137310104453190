import {api} from '@global';
import {ApiObject} from '@api';

export default class ApiPayElement {
    public static async list(legalEntityId: number, params: PayElementQueryParams) {
        const { data } = await api.get(`/mss/legal-entity/${legalEntityId}/pay-elements/listing`, {
            params: params.params(),
        });

        return data.data || [];
    }

    public static async getBankName(legalEntityId: number, swift: string): Promise<string|null> {
        const {data} = await api.get(`/mss/legal-entity/${legalEntityId}/bank`, {
            params: {swift},
        });

        return data.data ? data.data.name : null;
    }

    public static async getPeriodPayElements(
        legalEntityId: number,
        employeeId: number,
        params: PeriodPayElementQueryParams,
    ) {
        const { data } = await api.post(
            `/mss/legal-entity/${legalEntityId}/employee/${employeeId}/period-pay-elements`,
            params,
        );

        return data.data || [];
    }
}

interface RequestParams {
    limit: number;
    offset: number;
    accounts?: string|null;
}

interface PayElementQueryParamsInterface {
    limit?:number;
    offset?:number;
    accounts?: number[];
}

export class PayElementQueryParams {
    public static DEFAULT_LIMIT = 1000;

    private readonly limit: number;
    private readonly offset: number;

    private accounts: number[];

    public constructor({limit, offset, accounts}: PayElementQueryParamsInterface = {}) {
        this.limit = limit || PayElementQueryParams.DEFAULT_LIMIT;
        this.offset = offset || 0;
        this.accounts = accounts || [];
    }

    public params(): RequestParams {
        const params: RequestParams = {
            limit: this.limit,
            offset: this.offset,
        };

        if (this.accounts.length > 0) {
            params.accounts = this.accounts.join(',');
        }

        return params;
    }
}

export interface PeriodPayElementQueryParams {
    beginDate?: string;
    endDate?: string;
    latest?: boolean;
    payElementTypes?: ApiObject.PayElementType[];
    occurrences?: ApiObject.PayElementOccurrence[];
    accountRanges?: {
        min: number,
        max?: number,
    }[];
}
