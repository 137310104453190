import { constants as C, interfaces as I, store } from '@global'
import { ApiObject } from '@api'

export default class AccessManager {
  private roles: C.Role[]
  private readonly masterDataEditAllowed: boolean
  private readonly permissions: I.Permissions

  constructor(roles: C.Role[] = [], masterDataEditAllowed: boolean = false) {
    this.roles = roles
    this.masterDataEditAllowed = masterDataEditAllowed
    this.permissions = AccessManager.defaultPermissions(C.PermissionLevel.Inactive)
    if (this.roles.length > 0) {
      this.initializePermissions()
    }
  }

  public getPermissions(): I.Permissions {
    return this.permissions
  }

  private hasOneOfRoles(roles: C.Role[]): boolean {
    return roles.some((r: C.Role) => this.roles.includes(r))
  }

  public static hasPermission(
    permissions: I.Permissions,
    code: C.PermissionCode,
    level: C.PermissionLevel = C.PermissionLevel.Active
  ): boolean {
    if (!permissions[code]) {
      return false
    }
    const currentLevel: C.PermissionLevel = permissions[code]
    if (!currentLevel) {
      return false
    }

    return AccessManager.getLevelPriority(level) <= AccessManager.getLevelPriority(currentLevel)
  }

  private initializePermissions(): void {
    if (!this.masterDataEditAllowed) {
      const activeCodes: C.PermissionCode[] = [
        C.PermissionCode.COMPANY,
        C.PermissionCode.EMPLOYEES,
        C.PermissionCode.PAYROLL,
        C.PermissionCode.REPORTS,
        C.PermissionCode.TEMPLATES,
        C.PermissionCode.CALENDAR,
        C.PermissionCode.SETTINGS,
        C.PermissionCode.HELP,

        C.PermissionCode.EMPLOYEES_ACTION_REVOKE_ACCESS_TO_ESS,
        C.PermissionCode.EMPLOYEES_ACTION_SEND_ESS_INVITATION,
        C.PermissionCode.EMPLOYEES_ACTION_ACTIVATE,
        C.PermissionCode.EMPLOYEE_CHANGE_STATUS,
        C.PermissionCode.EMPLOYEE,
        C.PermissionCode.REPORTS_REPORTS,
        C.PermissionCode.TEMPLATES_REPORT_TEMPLATES,
      ]

      if (this.hasOneOfRoles([C.Role.ROLE_MANAGER, C.Role.ROLE_MSS_PAYROLL_SUBMIT])) {
        activeCodes.push(
          C.PermissionCode.PAYROLL_CURRENT_PERIOD_SUBMIT,
          C.PermissionCode.PAYROLL_CURRENT_PERIOD_REVIEW_TABLE,
          C.PermissionCode.PAYROLL_CURRENT_PERIOD_REVIEW_TABLE_ACTION_REJECT,
          C.PermissionCode.PAYROLL_CURRENT_PERIOD_REVIEW_TABLE_ACTION_APPROVE
        )
      }

      // set active I.Permissions
      activeCodes.forEach((code) => (this.permissions[code] = C.PermissionLevel.Active))

      // add all I.Permissions from ROLE_VIEWER
      this.updatePermissionsByRole(C.Role.ROLE_VIEWER)
    } else {
      this.roles.map((role: C.Role) => this.updatePermissionsByRole(role))
    }
  }

  public static defaultPermissions(level: C.PermissionLevel = C.PermissionLevel.Inactive): I.Permissions {
    const permissions: I.Permissions = {}
    ;[
      C.PermissionCode.COMPANY,
      C.PermissionCode.COMPANY_ACTIONS,
      C.PermissionCode.COMPANY_EDIT_COST_CENTER,
      C.PermissionCode.COMPANY_EDIT_GROUPS,

      C.PermissionCode.EMPLOYEES,
      C.PermissionCode.EMPLOYEES_IMPORT,
      C.PermissionCode.EMPLOYEES_ACTIONS,
      C.PermissionCode.EMPLOYEES_ACTION_ADD_EMPLOYEE,
      C.PermissionCode.EMPLOYEES_ACTION_ADD_OR_REMOVE_COLUMNS,
      C.PermissionCode.EMPLOYEES_ACTION_REVOKE_ACCESS_TO_ESS,
      C.PermissionCode.EMPLOYEES_ACTION_SEND_ESS_INVITATION,
      C.PermissionCode.EMPLOYEES_ACTION_SUSPEND,
      C.PermissionCode.EMPLOYEES_ACTION_ACTIVATE,
      C.PermissionCode.EMPLOYEE,
      C.PermissionCode.EMPLOYEE_IMPORT,
      C.PermissionCode.EMPLOYEE_EXPORT,
      C.PermissionCode.EMPLOYEE_CHANGE_STATUS,
      C.PermissionCode.EMPLOYEE_DOCUMENTS_EDIT,
      C.PermissionCode.EMPLOYEE_FIELDS_EDIT,
      C.PermissionCode.EMPLOYEE_SEPARATION,

      C.PermissionCode.PAYROLL,
      C.PermissionCode.PAYROLL_CURRENT_PERIOD,
      C.PermissionCode.PAYROLL_CURRENT_PERIOD_SAVE,
      C.PermissionCode.PAYROLL_CURRENT_PERIOD_CANCEL,
      C.PermissionCode.PAYROLL_CURRENT_PERIOD_SUBMIT,
      C.PermissionCode.PAYROLL_CURRENT_PERIOD_OPEN_PERIOD,
      C.PermissionCode.PAYROLL_CURRENT_IMPORT_ONE_TIME,
      C.PermissionCode.PAYROLL_CURRENT_ADD_ONE_TIME,
      C.PermissionCode.PAYROLL_CURRENT_PERIOD_EXPORT,

      C.PermissionCode.PAYROLL_CURRENT_PERIOD_REPORTS,
      C.PermissionCode.PAYROLL_CURRENT_PERIOD_REVIEW_TABLE,
      C.PermissionCode.PAYROLL_CURRENT_PERIOD_REVIEW_TABLE_ACTION_REJECT,
      C.PermissionCode.PAYROLL_CURRENT_PERIOD_REVIEW_TABLE_ACTION_APPROVE,
      C.PermissionCode.PAYROLL_CURRENT_PERIOD_EARNINGS,
      C.PermissionCode.PAYROLL_CURRENT_PERIOD_UNITS,
      C.PermissionCode.PAYROLL_CURRENT_PERIOD_DOCUMENTS,
      C.PermissionCode.PAYROLL_CURRENT_PERIOD_DOCUMENTS_VIEW_DOCUMENT,
      C.PermissionCode.PAYROLL_CURRENT_PERIOD_DOCUMENTS_ADD_FILE,
      C.PermissionCode.PAYROLL_CURRENT_PERIOD_DOCUMENTS_ADD_INTEGRATION_FILE,
      C.PermissionCode.PAYROLL_CLOSED_PERIOD,
      C.PermissionCode.PAYROLL_CLOSED_PERIOD_ITEM,
      C.PermissionCode.PAYROLL_CLOSED_PERIOD_ITEM_FILES,

      C.PermissionCode.TEMPLATES,
      C.PermissionCode.TEMPLATES_REPORT_TEMPLATES,
      C.PermissionCode.TEMPLATES_REPORT_TEMPLATES_ACTION_DEFINE_NEW,
      C.PermissionCode.TEMPLATES_REPORT_TEMPLATES_VIEW,
      C.PermissionCode.TEMPLATES_REPORT_TEMPLATES_ACTION_DELETE,
      C.PermissionCode.TEMPLATES_EMPLOYEE_TEMPLATES,
      C.PermissionCode.TEMPLATES_EMPLOYEE_TEMPLATES_ACTION_DEFINE_NEW,
      C.PermissionCode.TEMPLATES_EMPLOYEE_TEMPLATES_VIEW,
      C.PermissionCode.TEMPLATES_EMPLOYEE_TEMPLATES_ACTION_DELETE,
      C.PermissionCode.TEMPLATES_PAYROLL_TEMPLATES,
      C.PermissionCode.TEMPLATES_PAYROLL_TEMPLATES_ACTION_DEFINE_NEW,
      C.PermissionCode.TEMPLATES_PAYROLL_TEMPLATES_VIEW,
      C.PermissionCode.TEMPLATES_PAYROLL_TEMPLATES_ACTION_DELETE,

      C.PermissionCode.REPORTS,
      C.PermissionCode.REPORTS_REPORTS,
      C.PermissionCode.REPORTS_REPORTS_ACTION_NEW,
      C.PermissionCode.REPORTS_REPORTS_VIEW,
      C.PermissionCode.REPORTS_REPORTS_VIEW_ACTION_EXPORT,
      C.PermissionCode.REPORTS_REPORTS_ACTION_DELETE,

      C.PermissionCode.SETTINGS,
      C.PermissionCode.SETTINGS_PAGES,
      C.PermissionCode.SETTINGS_PAGES_ACTION_EDIT_GROUP,
      C.PermissionCode.SETTINGS_PAGES_ACTION_SAVE_CANCEL,

      C.PermissionCode.CALENDAR,
      C.PermissionCode.CALENDAR_EVENTS,
      C.PermissionCode.CALENDAR_ACTION_EXPORT,

      C.PermissionCode.HELP,

      C.PermissionCode.LEAVES,
      C.PermissionCode.LEAVES_LEAVE,
      C.PermissionCode.LEAVES_EDIT_LEAVE,
    ].map((code: C.PermissionCode) => (permissions[code] = level))

    return permissions
  }

  private changePermission(code: C.PermissionCode, level: C.PermissionLevel, keepMinimum: boolean = false): boolean {
    const currentLevel: C.PermissionLevel = this.permissions[code]
    const isNewBigger: boolean = AccessManager.getLevelPriority(level) > AccessManager.getLevelPriority(currentLevel)

    if (currentLevel) {
      // keep minimum BUT new level bigger
      if (keepMinimum && isNewBigger) {
        return false
      }

      // don't keep minimum BUT new level smaller
      if (!keepMinimum && !isNewBigger) {
        return false
      }
    }

    this.permissions[code] = level

    return true
  }

  public static getLevelPriority(level: C.PermissionLevel): number {
    switch (level) {
      case C.PermissionLevel.Active:
        return C.PermissionLevelPriority.Active
      case C.PermissionLevel.Inactive:
        return C.PermissionLevelPriority.Inactive
      default:
        return 0
    }
  }

  updatePermissionsByRole(role: C.Role): void {
    const rolePermissions: I.Permissions = AccessManager.getPermissionsByRole(role)
    ;(Object.keys(rolePermissions) as C.PermissionCode[]).map((code: C.PermissionCode) =>
      this.changePermission(code, rolePermissions[code])
    )
  }

  public static getPermissionsByRole(role: C.Role): I.Permissions {
    switch (role) {
      case C.Role.ROLE_VIEWER:
        return AccessManager.getViewerPermissions()
      case C.Role.ROLE_MSS_EMPLOYEE_EDIT:
        return AccessManager.getEmployeeEditPermissions()
      case C.Role.ROLE_MSS_LEGAL_ENTITY_EDIT:
        return AccessManager.getLegalEntityEditPermissions()
      case C.Role.ROLE_MSS_FIELD_GROUP_EDIT:
        return AccessManager.getFieldGroupEditPermissions()
      case C.Role.ROLE_MANAGER:
        return AccessManager.getManagerPermissions()
      case C.Role.ROLE_MSS_PAYROLL_EDIT:
        return AccessManager.getPayrollEditPermissions()
      case C.Role.ROLE_MSS_PAYROLL_SUBMIT:
        return AccessManager.getPayrollSubmitPermissions()
      case C.Role.ROLE_ADVISOR:
        return AccessManager.getAdvisorPermissions()
      case C.Role.ROLE_ANALYST:
        return AccessManager.getAnalystPermissions()
      case C.Role.ROLE_SUPERVISOR:
        return AccessManager.getSupervisorPermissions()
      case C.Role.ROLE_DOCUMENT_CREATOR:
      case C.Role.ROLE_DOCUMENT_VIEWER:
        return AccessManager.getDocumentsPermissions()
      case C.Role.ROLE_TEMPLATE_EDITOR:
      case C.Role.ROLE_TEMPLATE_VIEWER:
        return AccessManager.getDocumentsTemplatesPermissions()
      default:
        return {}
    }
  }

  public static getViewerPermissions(): I.Permissions {
    const inactivePermissions = AccessManager.defaultPermissions(C.PermissionLevel.Inactive)
    const activePermissions: I.Permissions = {}
    ;[
      C.PermissionCode.COMPANY,

      C.PermissionCode.EMPLOYEES,
      C.PermissionCode.EMPLOYEE,

      C.PermissionCode.PAYROLL,
      C.PermissionCode.PAYROLL_CURRENT_PERIOD,
      C.PermissionCode.PAYROLL_CURRENT_PERIOD_REPORTS,
      C.PermissionCode.PAYROLL_CURRENT_PERIOD_REVIEW_TABLE,
      C.PermissionCode.PAYROLL_CURRENT_PERIOD_EARNINGS,
      C.PermissionCode.PAYROLL_CURRENT_PERIOD_UNITS,
      C.PermissionCode.PAYROLL_CURRENT_PERIOD_DOCUMENTS,
      C.PermissionCode.PAYROLL_CLOSED_PERIOD,
      C.PermissionCode.PAYROLL_CLOSED_PERIOD_ITEM,
      C.PermissionCode.PAYROLL_CLOSED_PERIOD_ITEM_FILES,

      C.PermissionCode.TEMPLATES,
      C.PermissionCode.TEMPLATES_REPORT_TEMPLATES,
      C.PermissionCode.TEMPLATES_REPORT_TEMPLATES_ACTION_DEFINE_NEW,
      C.PermissionCode.TEMPLATES_REPORT_TEMPLATES_VIEW,
      C.PermissionCode.TEMPLATES_REPORT_TEMPLATES_ACTION_DELETE,
      C.PermissionCode.TEMPLATES_EMPLOYEE_TEMPLATES,
      C.PermissionCode.TEMPLATES_EMPLOYEE_TEMPLATES_ACTION_DEFINE_NEW,
      C.PermissionCode.TEMPLATES_EMPLOYEE_TEMPLATES_VIEW,
      C.PermissionCode.TEMPLATES_EMPLOYEE_TEMPLATES_ACTION_DELETE,
      C.PermissionCode.TEMPLATES_PAYROLL_TEMPLATES,
      C.PermissionCode.TEMPLATES_PAYROLL_TEMPLATES_ACTION_DEFINE_NEW,
      C.PermissionCode.TEMPLATES_PAYROLL_TEMPLATES_VIEW,
      C.PermissionCode.TEMPLATES_PAYROLL_TEMPLATES_ACTION_DELETE,

      C.PermissionCode.REPORTS,
      C.PermissionCode.REPORTS_REPORTS,
      C.PermissionCode.REPORTS_REPORTS_ACTION_NEW,
      C.PermissionCode.REPORTS_REPORTS_VIEW,
      C.PermissionCode.REPORTS_REPORTS_VIEW_ACTION_EXPORT,
      C.PermissionCode.REPORTS_REPORTS_ACTION_DELETE,

      C.PermissionCode.SETTINGS,
      C.PermissionCode.SETTINGS_PAGES,

      C.PermissionCode.CALENDAR,
      C.PermissionCode.CALENDAR_EVENTS,
      C.PermissionCode.CALENDAR_ACTION_EXPORT,

      C.PermissionCode.HELP,
    ].map((code: C.PermissionCode) => (activePermissions[code] = C.PermissionLevel.Active))

    return Object.assign(inactivePermissions, activePermissions)
  }

  static getEmployeeEditPermissions(): I.Permissions {
    const inactivePermissions = AccessManager.defaultPermissions(C.PermissionLevel.Inactive)
    const activePermissions: I.Permissions = {}
    ;[
      C.PermissionCode.EMPLOYEES,
      C.PermissionCode.EMPLOYEES_IMPORT,
      C.PermissionCode.EMPLOYEES_ACTIONS,
      C.PermissionCode.EMPLOYEES_ACTION_ADD_EMPLOYEE,
      C.PermissionCode.EMPLOYEES_ACTION_SEND_ESS_INVITATION,
      C.PermissionCode.EMPLOYEES_ACTION_REVOKE_ACCESS_TO_ESS,
      C.PermissionCode.EMPLOYEES_ACTION_SUSPEND,
      C.PermissionCode.EMPLOYEES_ACTION_ACTIVATE,
      C.PermissionCode.EMPLOYEES_ACTION_ADD_OR_REMOVE_COLUMNS,
      C.PermissionCode.EMPLOYEE,
      C.PermissionCode.EMPLOYEE_IMPORT,
      C.PermissionCode.EMPLOYEE_EXPORT,
      C.PermissionCode.EMPLOYEE_CHANGE_STATUS,
      C.PermissionCode.EMPLOYEE_SEPARATION,
      C.PermissionCode.EMPLOYEE_FIELDS_EDIT,
      C.PermissionCode.EMPLOYEE_DOCUMENTS_EDIT,
    ].map((code: C.PermissionCode) => (activePermissions[code] = C.PermissionLevel.Active))

    return Object.assign(inactivePermissions, activePermissions)
  }

  public static getLegalEntityEditPermissions(): I.Permissions {
    const inactivePermissions: I.Permissions = AccessManager.defaultPermissions(C.PermissionLevel.Inactive)
    const activePermissions: I.Permissions = {}
    ;[
      C.PermissionCode.COMPANY,
      C.PermissionCode.COMPANY_ACTIONS,
      C.PermissionCode.COMPANY_EDIT_COST_CENTER,
      C.PermissionCode.COMPANY_EDIT_GROUPS,
    ].map((code: C.PermissionCode) => (activePermissions[code] = C.PermissionLevel.Active))

    return Object.assign(inactivePermissions, activePermissions)
  }

  public static getFieldGroupEditPermissions(): I.Permissions {
    const inactivePermissions = AccessManager.defaultPermissions(C.PermissionLevel.Inactive)
    const activePermissions: I.Permissions = {}
    ;[
      C.PermissionCode.SETTINGS,
      C.PermissionCode.SETTINGS_PAGES,
      C.PermissionCode.SETTINGS_PAGES_ACTION_EDIT_GROUP,
      C.PermissionCode.SETTINGS_PAGES_ACTION_SAVE_CANCEL,
    ].map((code) => (activePermissions[code] = C.PermissionLevel.Active))

    return Object.assign(inactivePermissions, activePermissions)
  }

  public static getManagerPermissions(): I.Permissions {
    return AccessManager.defaultPermissions(C.PermissionLevel.Active)
  }

  public static getPayrollEditPermissions(): I.Permissions {
    const inactivePermissions = AccessManager.defaultPermissions(C.PermissionLevel.Inactive)
    const activePermissions: I.Permissions = {}
    ;[
      C.PermissionCode.PAYROLL,
      C.PermissionCode.PAYROLL_CURRENT_PERIOD,
      C.PermissionCode.PAYROLL_CURRENT_PERIOD_SAVE,
      C.PermissionCode.PAYROLL_CURRENT_PERIOD_CANCEL,
      C.PermissionCode.PAYROLL_CURRENT_IMPORT_ONE_TIME,
      C.PermissionCode.PAYROLL_CURRENT_ADD_ONE_TIME,
      C.PermissionCode.PAYROLL_CURRENT_PERIOD_EXPORT,

      C.PermissionCode.PAYROLL_CURRENT_PERIOD_REPORTS,
      C.PermissionCode.PAYROLL_CURRENT_PERIOD_REVIEW_TABLE,
      C.PermissionCode.PAYROLL_CURRENT_PERIOD_EARNINGS,
      C.PermissionCode.PAYROLL_CURRENT_PERIOD_UNITS,
      C.PermissionCode.PAYROLL_CURRENT_PERIOD_DOCUMENTS,
      C.PermissionCode.PAYROLL_CURRENT_PERIOD_DOCUMENTS_VIEW_DOCUMENT,
      C.PermissionCode.PAYROLL_CURRENT_PERIOD_DOCUMENTS_ADD_FILE,
      C.PermissionCode.PAYROLL_CURRENT_PERIOD_DOCUMENTS_ADD_INTEGRATION_FILE,
      C.PermissionCode.PAYROLL_CLOSED_PERIOD,
      C.PermissionCode.PAYROLL_CLOSED_PERIOD_ITEM,
      C.PermissionCode.PAYROLL_CLOSED_PERIOD_ITEM_FILES,
    ].map((code: C.PermissionCode) => (activePermissions[code] = C.PermissionLevel.Active))

    return Object.assign(inactivePermissions, activePermissions)
  }

  public static getPayrollSubmitPermissions(): I.Permissions {
    const inactivePermissions: I.Permissions = AccessManager.defaultPermissions(C.PermissionLevel.Inactive)
    const activePermissions: I.Permissions = {}
    ;[
      C.PermissionCode.PAYROLL,
      C.PermissionCode.PAYROLL_CURRENT_PERIOD,
      C.PermissionCode.PAYROLL_CURRENT_PERIOD_SUBMIT,
      C.PermissionCode.PAYROLL_CURRENT_PERIOD_OPEN_PERIOD,
      C.PermissionCode.PAYROLL_CURRENT_PERIOD_REVIEW_TABLE,
      C.PermissionCode.PAYROLL_CURRENT_PERIOD_REVIEW_TABLE_ACTION_REJECT,
      C.PermissionCode.PAYROLL_CURRENT_PERIOD_REVIEW_TABLE_ACTION_APPROVE,
      C.PermissionCode.PAYROLL_CURRENT_PERIOD_EARNINGS,
      C.PermissionCode.PAYROLL_CURRENT_PERIOD_UNITS,
    ].map((code: C.PermissionCode) => (activePermissions[code] = C.PermissionLevel.Active))

    return Object.assign(inactivePermissions, activePermissions)
  }

  public static getAdvisorPermissions(): I.Permissions {
    const inactivePermissions: I.Permissions = AccessManager.defaultPermissions(C.PermissionLevel.Inactive)
    const activePermissions: I.Permissions = {}
    ;[
      C.PermissionCode.COMPANY,

      C.PermissionCode.EMPLOYEES,
      C.PermissionCode.EMPLOYEE,

      C.PermissionCode.PAYROLL,
      C.PermissionCode.PAYROLL_CURRENT_PERIOD,
      C.PermissionCode.PAYROLL_CURRENT_PERIOD_EXPORT,

      C.PermissionCode.PAYROLL_CURRENT_PERIOD_REPORTS,
      C.PermissionCode.PAYROLL_CURRENT_PERIOD_REVIEW_TABLE,
      C.PermissionCode.PAYROLL_CURRENT_PERIOD_EARNINGS,
      C.PermissionCode.PAYROLL_CURRENT_PERIOD_UNITS,
      C.PermissionCode.PAYROLL_CURRENT_PERIOD_DOCUMENTS,
      C.PermissionCode.PAYROLL_CURRENT_PERIOD_DOCUMENTS_VIEW_DOCUMENT,
      C.PermissionCode.PAYROLL_CLOSED_PERIOD,
      C.PermissionCode.PAYROLL_CLOSED_PERIOD_ITEM,
      C.PermissionCode.PAYROLL_CLOSED_PERIOD_ITEM_FILES,

      C.PermissionCode.TEMPLATES,
      C.PermissionCode.TEMPLATES_REPORT_TEMPLATES,
      C.PermissionCode.TEMPLATES_REPORT_TEMPLATES_ACTION_DEFINE_NEW,
      C.PermissionCode.TEMPLATES_REPORT_TEMPLATES_VIEW,
      C.PermissionCode.TEMPLATES_REPORT_TEMPLATES_ACTION_DELETE,
      C.PermissionCode.TEMPLATES_EMPLOYEE_TEMPLATES,
      C.PermissionCode.TEMPLATES_EMPLOYEE_TEMPLATES_ACTION_DEFINE_NEW,
      C.PermissionCode.TEMPLATES_EMPLOYEE_TEMPLATES_VIEW,
      C.PermissionCode.TEMPLATES_EMPLOYEE_TEMPLATES_ACTION_DELETE,
      C.PermissionCode.TEMPLATES_PAYROLL_TEMPLATES,
      C.PermissionCode.TEMPLATES_PAYROLL_TEMPLATES_ACTION_DEFINE_NEW,
      C.PermissionCode.TEMPLATES_PAYROLL_TEMPLATES_VIEW,
      C.PermissionCode.TEMPLATES_PAYROLL_TEMPLATES_ACTION_DELETE,

      C.PermissionCode.REPORTS,
      C.PermissionCode.REPORTS_REPORTS,
      C.PermissionCode.REPORTS_REPORTS_ACTION_NEW,
      C.PermissionCode.REPORTS_REPORTS_VIEW,
      C.PermissionCode.REPORTS_REPORTS_VIEW_ACTION_EXPORT,
      C.PermissionCode.REPORTS_REPORTS_ACTION_DELETE,

      C.PermissionCode.SETTINGS,
      C.PermissionCode.SETTINGS_PAGES,
      C.PermissionCode.SETTINGS_PAGES_ACTION_EDIT_GROUP,
      C.PermissionCode.SETTINGS_PAGES_ACTION_SAVE_CANCEL,

      C.PermissionCode.CALENDAR,
      C.PermissionCode.CALENDAR_EVENTS,
      C.PermissionCode.CALENDAR_ACTION_EXPORT,

      C.PermissionCode.HELP,
    ].map((code: C.PermissionCode) => (activePermissions[code] = C.PermissionLevel.Active))

    return Object.assign(inactivePermissions, activePermissions)
  }

  public static getAnalystPermissions(): I.Permissions {
    const inactivePermissions: I.Permissions = AccessManager.defaultPermissions(C.PermissionLevel.Inactive)
    const activePermissions: I.Permissions = {}
    ;[
      C.PermissionCode.COMPANY,
      C.PermissionCode.COMPANY_ACTIONS,
      C.PermissionCode.COMPANY_EDIT_COST_CENTER,
      C.PermissionCode.COMPANY_EDIT_GROUPS,

      C.PermissionCode.EMPLOYEES,
      C.PermissionCode.EMPLOYEES_IMPORT,
      C.PermissionCode.EMPLOYEES_ACTIONS,
      C.PermissionCode.EMPLOYEES_ACTION_ADD_EMPLOYEE,
      C.PermissionCode.EMPLOYEES_ACTION_ADD_OR_REMOVE_COLUMNS,
      C.PermissionCode.EMPLOYEES_ACTION_REVOKE_ACCESS_TO_ESS,
      C.PermissionCode.EMPLOYEES_ACTION_SEND_ESS_INVITATION,
      C.PermissionCode.EMPLOYEES_ACTION_SUSPEND,
      C.PermissionCode.EMPLOYEES_ACTION_ACTIVATE,
      C.PermissionCode.EMPLOYEE,
      C.PermissionCode.EMPLOYEE_IMPORT,
      C.PermissionCode.EMPLOYEE_EXPORT,
      C.PermissionCode.EMPLOYEE_CHANGE_STATUS,
      C.PermissionCode.EMPLOYEE_DOCUMENTS_EDIT,
      C.PermissionCode.EMPLOYEE_FIELDS_EDIT,
      C.PermissionCode.EMPLOYEE_SEPARATION,

      C.PermissionCode.PAYROLL,
      C.PermissionCode.PAYROLL_CURRENT_PERIOD,
      C.PermissionCode.PAYROLL_CURRENT_PERIOD_SAVE,
      C.PermissionCode.PAYROLL_CURRENT_PERIOD_CANCEL,
      C.PermissionCode.PAYROLL_CURRENT_IMPORT_ONE_TIME,
      C.PermissionCode.PAYROLL_CURRENT_ADD_ONE_TIME,
      C.PermissionCode.PAYROLL_CURRENT_PERIOD_EXPORT,

      C.PermissionCode.PAYROLL_CURRENT_PERIOD_REPORTS,
      C.PermissionCode.PAYROLL_CURRENT_PERIOD_REVIEW_TABLE,
      C.PermissionCode.PAYROLL_CURRENT_PERIOD_EARNINGS,
      C.PermissionCode.PAYROLL_CURRENT_PERIOD_UNITS,
      C.PermissionCode.PAYROLL_CURRENT_PERIOD_DOCUMENTS,
      C.PermissionCode.PAYROLL_CURRENT_PERIOD_DOCUMENTS_VIEW_DOCUMENT,
      C.PermissionCode.PAYROLL_CLOSED_PERIOD,
      C.PermissionCode.PAYROLL_CLOSED_PERIOD_ITEM,
      C.PermissionCode.PAYROLL_CLOSED_PERIOD_ITEM_FILES,

      C.PermissionCode.TEMPLATES,
      C.PermissionCode.TEMPLATES_REPORT_TEMPLATES,
      C.PermissionCode.TEMPLATES_REPORT_TEMPLATES_ACTION_DEFINE_NEW,
      C.PermissionCode.TEMPLATES_REPORT_TEMPLATES_VIEW,
      C.PermissionCode.TEMPLATES_REPORT_TEMPLATES_ACTION_DELETE,
      C.PermissionCode.TEMPLATES_EMPLOYEE_TEMPLATES,
      C.PermissionCode.TEMPLATES_EMPLOYEE_TEMPLATES_ACTION_DEFINE_NEW,
      C.PermissionCode.TEMPLATES_EMPLOYEE_TEMPLATES_VIEW,
      C.PermissionCode.TEMPLATES_EMPLOYEE_TEMPLATES_ACTION_DELETE,
      C.PermissionCode.TEMPLATES_PAYROLL_TEMPLATES,
      C.PermissionCode.TEMPLATES_PAYROLL_TEMPLATES_ACTION_DEFINE_NEW,
      C.PermissionCode.TEMPLATES_PAYROLL_TEMPLATES_VIEW,
      C.PermissionCode.TEMPLATES_PAYROLL_TEMPLATES_ACTION_DELETE,

      C.PermissionCode.REPORTS,
      C.PermissionCode.REPORTS_REPORTS,
      C.PermissionCode.REPORTS_REPORTS_ACTION_NEW,
      C.PermissionCode.REPORTS_REPORTS_VIEW,
      C.PermissionCode.REPORTS_REPORTS_VIEW_ACTION_EXPORT,
      C.PermissionCode.REPORTS_REPORTS_ACTION_DELETE,

      C.PermissionCode.SETTINGS,
      C.PermissionCode.SETTINGS_PAGES,

      C.PermissionCode.CALENDAR,
      C.PermissionCode.CALENDAR_EVENTS,
      C.PermissionCode.CALENDAR_ACTION_EXPORT,

      C.PermissionCode.HELP,
    ].map((code: C.PermissionCode) => (activePermissions[code] = C.PermissionLevel.Active))

    return Object.assign(inactivePermissions, activePermissions)
  }

  public static getSupervisorPermissions(): I.Permissions {
    const inactivePermissions: I.Permissions = AccessManager.defaultPermissions(C.PermissionLevel.Inactive)
    const activePermissions: I.Permissions = {}
    ;[
      C.PermissionCode.COMPANY,

      C.PermissionCode.EMPLOYEES,
      C.PermissionCode.EMPLOYEE,

      C.PermissionCode.PAYROLL,
      C.PermissionCode.PAYROLL_CURRENT_PERIOD,
      C.PermissionCode.PAYROLL_CURRENT_PERIOD_SUBMIT,
      C.PermissionCode.PAYROLL_CURRENT_PERIOD_OPEN_PERIOD,
      C.PermissionCode.PAYROLL_CURRENT_PERIOD_EXPORT,

      C.PermissionCode.PAYROLL_CURRENT_PERIOD_REPORTS,
      C.PermissionCode.PAYROLL_CURRENT_PERIOD_REVIEW_TABLE,
      C.PermissionCode.PAYROLL_CURRENT_PERIOD_REVIEW_TABLE_ACTION_REJECT,
      C.PermissionCode.PAYROLL_CURRENT_PERIOD_REVIEW_TABLE_ACTION_APPROVE,
      C.PermissionCode.PAYROLL_CURRENT_PERIOD_EARNINGS,
      C.PermissionCode.PAYROLL_CURRENT_PERIOD_UNITS,
      C.PermissionCode.PAYROLL_CURRENT_PERIOD_DOCUMENTS,
      C.PermissionCode.PAYROLL_CURRENT_PERIOD_DOCUMENTS_VIEW_DOCUMENT,
      C.PermissionCode.PAYROLL_CURRENT_PERIOD_DOCUMENTS_ADD_FILE,
      C.PermissionCode.PAYROLL_CURRENT_PERIOD_DOCUMENTS_ADD_INTEGRATION_FILE,
      C.PermissionCode.PAYROLL_CLOSED_PERIOD,
      C.PermissionCode.PAYROLL_CLOSED_PERIOD_ITEM,
      C.PermissionCode.PAYROLL_CLOSED_PERIOD_ITEM_FILES,

      C.PermissionCode.TEMPLATES,
      C.PermissionCode.TEMPLATES_REPORT_TEMPLATES,
      C.PermissionCode.TEMPLATES_REPORT_TEMPLATES_ACTION_DEFINE_NEW,
      C.PermissionCode.TEMPLATES_REPORT_TEMPLATES_VIEW,
      C.PermissionCode.TEMPLATES_REPORT_TEMPLATES_ACTION_DELETE,
      C.PermissionCode.TEMPLATES_EMPLOYEE_TEMPLATES,
      C.PermissionCode.TEMPLATES_EMPLOYEE_TEMPLATES_ACTION_DEFINE_NEW,
      C.PermissionCode.TEMPLATES_EMPLOYEE_TEMPLATES_VIEW,
      C.PermissionCode.TEMPLATES_EMPLOYEE_TEMPLATES_ACTION_DELETE,
      C.PermissionCode.TEMPLATES_PAYROLL_TEMPLATES,
      C.PermissionCode.TEMPLATES_PAYROLL_TEMPLATES_ACTION_DEFINE_NEW,
      C.PermissionCode.TEMPLATES_PAYROLL_TEMPLATES_VIEW,
      C.PermissionCode.TEMPLATES_PAYROLL_TEMPLATES_ACTION_DELETE,

      C.PermissionCode.REPORTS,
      C.PermissionCode.REPORTS_REPORTS,
      C.PermissionCode.REPORTS_REPORTS_ACTION_NEW,
      C.PermissionCode.REPORTS_REPORTS_VIEW,
      C.PermissionCode.REPORTS_REPORTS_VIEW_ACTION_EXPORT,
      C.PermissionCode.REPORTS_REPORTS_ACTION_DELETE,

      C.PermissionCode.SETTINGS,
      C.PermissionCode.SETTINGS_PAGES,

      C.PermissionCode.CALENDAR,
      C.PermissionCode.CALENDAR_EVENTS,
      C.PermissionCode.CALENDAR_ACTION_EXPORT,

      C.PermissionCode.HELP,
    ].map((code: C.PermissionCode) => (activePermissions[code] = C.PermissionLevel.Active))

    return Object.assign(inactivePermissions, activePermissions)
  }

  public static getDocumentsPermissions(): I.Permissions {
    const inactivePermissions: I.Permissions = AccessManager.defaultPermissions(C.PermissionLevel.Inactive)
    const activePermissions: I.Permissions = {}
    ;[
      C.PermissionCode.DOCUMENTS,
    ].map((code: C.PermissionCode) => (activePermissions[code] = C.PermissionLevel.Active))

    return Object.assign(inactivePermissions, activePermissions)
  }

  public static getDocumentsTemplatesPermissions(): I.Permissions {
    const inactivePermissions: I.Permissions = AccessManager.defaultPermissions(C.PermissionLevel.Inactive)
    const activePermissions: I.Permissions = {}
    ;[
      C.PermissionCode.DOCUMENTS_TEMPLATES,
    ].map((code: C.PermissionCode) => (activePermissions[code] = C.PermissionLevel.Active))

    return Object.assign(inactivePermissions, activePermissions)
  }

  public static hasModule(legalEntity: ApiObject.LegalEntity | null, module: string): boolean {
    if (!legalEntity) {
      return false
    }

    return legalEntity.enabledModules.includes(module)
  }

  public static hasAtLeastOneModuleEnabled(modules: Array<string>, authorizedUser: ApiObject.AuthorizedUser) {
    if (!authorizedUser) {
      return false
    }

    let hasAtLeastOneModuleEnabled = false

    if (modules.length) {
      for (let i = 0; i < modules.length; i = i + 1) {
        const element = modules[i]
        if (authorizedUser.employee.enabledModules.includes(element)) {
          hasAtLeastOneModuleEnabled = true
          return true
        }
      }
    }

    return hasAtLeastOneModuleEnabled
  }

  public static userHasAtLeastOneRole(
    authorizedUser: ApiObject.AuthorizedUser,
    roles: string[],
    legalEntityId: number| null
  ): boolean {
    if (!authorizedUser) {
      return false
    }

    for (let i = 0; i < authorizedUser.employee.roles.length; i = i + 1) {
      const userRole = authorizedUser.employee.roles[i]
      for (let j = 0; j < roles.length; j = j + 1) {
        const roleString = roles[j]

        if (legalEntityId) {
          if (roleString === userRole.role && userRole.legalEntity.id === legalEntityId) {
            return true
          }
        } else if (roleString === userRole.role) {
          return true
        }
      }
    }
    return false
  }

  public static userHasEnabledModule(user: ApiObject.AuthorizedUser, module: string): boolean {
    return user.employee.enabledModules.includes(module)
  }

  public static enabledApplication(
    name: keyof ApiObject.userRolesAndModules,
    authorizedUser: ApiObject.AuthorizedUser,
    legalEntity: ApiObject.LegalEntity | null
  ) {

    const payrollRoles = {
      ROLE_MANAGER: 'ROLE_MANAGER',
      ROLE_VIEWER: 'ROLE_VIEWER',
      ROLE_MSS_PAYROLL_EDIT: 'ROLE_MSS_PAYROLL_EDIT',
      ROLE_MSS_PAYROLL_SUBMIT: 'ROLE_MSS_PAYROLL_SUBMIT',
      ROLE_MSS_PAYROLL_REVIEW: 'ROLE_MSS_PAYROLL_REVIEW',
      ROLE_MSS_PAYROLL_APPROVE: 'ROLE_MSS_PAYROLL_APPROVE',
      ROLE_MSS_PAYROLL_POST_REVIEW: 'ROLE_MSS_PAYROLL_POST_REVIEW',
      ROLE_MSS_PAYROLL_POST_APPROVE: 'ROLE_MSS_PAYROLL_POST_APPROVE',
    }

    if (!authorizedUser) {
      return false
    }

    if (name === 'dashboard') {
      return true
    }

    const userRolesAndModules: ApiObject.userRolesAndModules = {
      pac: {
        modules: [],
        roles: Object.keys(payrollRoles),
      },
      ess: {
        modules: [
          'benefit',
          'leave',
          'my-information',
          'payslips',
          'organizational-chart',
          'team-management',
        ],
        roles: [],
      },
      mss: {
        modules: ['leave'],
        roles: [],
      },
      settings: {
        modules: [],
        roles: ['ROLE_REQUESTS_ADMIN', 'ROLE_MANAGER', 'ROLE_VIEWER'],
      },
      secureFileTransfer: {
        modules: [],
        roles: ['ROLE_PAYROLL', 'ROLE_FINANCE'],
      },
    }

    const { modules, roles } = userRolesAndModules[name]

    return (
      this.userHasAtLeastOneRole(authorizedUser, roles, legalEntity ? legalEntity.id : null) ||
      this.hasAtLeastOneModuleEnabled(modules, authorizedUser)
    )
  }

  public static itIsMe(employeeId: number | null = null) {
    if (!employeeId) {
      return false
    }

    const authorizedUser = store.getState().global.authorizedUser
    if (!authorizedUser) {
      return false
    }

    return authorizedUser.employee.id === employeeId
  }
}
