import React, {PureComponent} from 'react';
import {connect} from 'react-redux';

import {LeaveInterfaces} from '@api';

import {RootState} from '../../rootReducer';
import * as selectors from './selectors';
import * as globalSelectors from '../selectors';
import {create, createAndApprove} from './redux';

import AddAction from '../common/Leave/Transaction/Actions/Add';

interface IProps {
    currentLegalEntityId: number|null;
    leaveTypes: LeaveInterfaces.Type[];

    create: (employeeId: number, transaction: LeaveInterfaces.Transaction) => void;
    createAndApprove: (employeeId: number, transaction: LeaveInterfaces.Transaction) => void;
}

export class AddLeave extends PureComponent<IProps> {
    public render() {
        if (!this.props.currentLegalEntityId) {
            return null;
        }

        return (
            <AddAction
                method={'withPlusAdd'}
                legalEntityId={this.props.currentLegalEntityId}
                leaveTypes={this.props.leaveTypes}
                onClickCreate={this.props.create}
                onClickCreateAndApprove={this.props.createAndApprove}
            />
        );
    }
}

const mapStateToProps = (state: RootState, ownProps: any) => ({
    currentLegalEntityId: globalSelectors.getCurrentLegalEntityId(state),
    leaveTypes: selectors.getActiveLeaveTypes(state, ownProps),
});

const mapDispatchToProps = {
    create,
    createAndApprove,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddLeave);
