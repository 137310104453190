import React, {PureComponent} from 'react';
import styled from 'styled-components';
import {stylingVariables} from '@global';
import CloseButton from './CloseButton';
import ReactDOM from 'react-dom';

interface RightSideModalProps {
    children: any;
    shouldSupportCloseWhenClickOutside?: boolean;

    onClickClose: () => void;
}

export const StyledBackground = styled.div`
  width: 100vw;
  height: 100vh;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10000;
  background-color: rgba(0, 0, 0, .6);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  overflow: hidden;
`;

const StyledDetailsModalWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 40%;
  min-width: 650px;
  height: calc(100vh - 40px);
  background-color: ${stylingVariables.colorPalette.white};
  box-shadow: ${stylingVariables.layout.boxShadow};
  border-radius: ${stylingVariables.layout.borderRadius};
  z-index: 10000;
  margin-right: 30px;
`;

export default class InnerModal extends PureComponent<RightSideModalProps> {
    private wrapperRef: any;

    static defaultProps = {
        shouldSupportCloseWhenClickOutside: true,
    };

    public componentDidMount() {
        if (this.props.shouldSupportCloseWhenClickOutside) {
            document.addEventListener('mousedown', this.closeWhenClickOutside);
        }
    }

    public componentWillUnmount() {
        if (this.props.shouldSupportCloseWhenClickOutside) {
            document.removeEventListener('mousedown', this.closeWhenClickOutside);
        }
    }

    public render() {
        return ReactDOM.createPortal(
            <StyledBackground>
                <StyledDetailsModalWrapper ref={this.setWrapperRef}>
                    <CloseButton onClick={this.props.onClickClose}/>

                    {this.props.children}
                </StyledDetailsModalWrapper>
            </StyledBackground>,
            document.body,
        );
    }

    public setWrapperRef = (node: any) => {
        this.wrapperRef = node;
    }

    public closeWhenClickOutside = (event: Event) => {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.props.onClickClose();
        }
    }
}
