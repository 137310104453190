import React, {PureComponent} from 'react';
import styled from 'styled-components';
import {get} from 'lodash';

import {stylingVariables, constants} from '@global';
import {ApiObject} from '@api';

import Field from './Field';
import BankSwiftAndName from './BankSwiftAndName';
import {Actions as CommonActions, Icon} from '@common';

const StyledFieldsGroupEdit = styled.div`
    margin: 20px 0;
    border-bottom: 1px solid ${stylingVariables.colorPalette.gray};
    padding-bottom: 30px;
    &:last-child {
        border-bottom: 0;
    }
`;

export const StyledFieldsGroupHeader = styled.h4`
    color: ${stylingVariables.colorPalette.darkest};
    font-size: ${stylingVariables.fontSize.largeMedium};
    font-weight: ${stylingVariables.fontWeight.bold};
    padding: 0;
    margin: 30px 0;
    display: flex;
    justify-content: space-between;
`;

interface IProps {
    fields: any;
    group: ApiObject.FieldGroup;
    errors: ApiObject.EntityFieldErrorMessage[];
    showTrash?: boolean;

    onChange: (field: ApiObject.Field, value: any) => void;
    deleteAccount: (group: ApiObject.FieldGroup) => void;
}

export default class Group extends PureComponent<IProps> {
    static defaultProps = {
        deleteAccount: () => ({})
    }

    private removeAccount = () => {
        this.props.deleteAccount(this.props.group)
    };

    render() {
        return (
            <StyledFieldsGroupEdit>
                <StyledFieldsGroupHeader>
                    {this.props.group.name}
                    {this.props.showTrash && 
                        (
                            <CommonActions.Action style={{marginRight: 15}} onClick={this.removeAccount}>
                            <Icon type={constants.IconTypes.TRASH}
                                  width={15} height={15}
                                  fillColor={stylingVariables.colorPalette.orange}
                            />
                        </CommonActions.Action>
                        )
                    }
                </StyledFieldsGroupHeader>
                {
                    this.renderFields()
                }
            </StyledFieldsGroupEdit>
        );
    }

    private renderFields() {
        return this.props.group.fields
            .sort(function(a,b) { return a.ordinal - b.ordinal })
            .map((field: ApiObject.Field) => {
            if (field.code === constants.DEFAULT_FIELD_CODE_SWIFT) {
                return (
                    <BankSwiftAndName
                        key={`${constants.DEFAULT_FIELD_CODE_SWIFT}_${constants.DEFAULT_FIELD_CODE_BANK_NAME}`}
                        group={this.props.group}
                        fields={this.props.fields}
                        errors={this.props.errors}
                        onChange={this.props.onChange}
                    />
                );
            }

            if (field.code === constants.DEFAULT_FIELD_CODE_BANK_NAME) {
                return null;
            }

            return (
                <Field
                    key={`${field.entity}_${field.code}`}
                    field={field}
                    default={
                        get(this.props.fields, `${field.entity}.${field.code}`)
                    }
                    onChange={this.props.onChange}
                    errorMessage={this.getErrorMessageByField(field)}
                />
            );
        });
    }

    private getErrorMessageByField = (field: any): string | null => {
        const foundError = this.props.errors
            .find((error: ApiObject.EntityFieldErrorMessage) => {
                return error.field === field.code && error.entity === field.entity
            });

        return foundError ? foundError.message : null;
    }
}
