import {AnyAction, Dispatch} from 'redux';
import {ThunkAction} from 'redux-thunk';
import {toast} from 'react-toastify';
import {saveAs} from 'file-saver';
import XLSX from 'xlsx';

import {ReportTemplatesHelper, RouteHelper, ReportHelper} from '@library';
import {ApiObject, ApiEmployee, ApiReportTemplate, ApiReport, EmployeeQueryParams} from '@api';
import {interfaces, constants, intl} from '@global';

import {RootState} from '../../../rootReducer';
import {startLoading, stopLoading} from '../../redux';
import {getTemplates as rootGetTemplates, checkGeneratedReport} from '../actions';
import {EVENT_REPORT_WAS_UPDATED} from '../constants';
import {addGeneratingReportId} from '../redux';
import {
  canRegenerateMasterdata,
  getLegalEntityPeriods
} from '../utils';
import * as selectors from './selectors';
import * as reportsSelectors from '../selectors';

type ThunkResult<R> = ThunkAction<R, RootState, undefined, AnyAction>;

export const SET_SPREADSHEET = 'reports/report/setSpreadsheet';
export const START_SPREADSHEET_LOADING = 'global/startSpreadsheetLoading';
export const STOP_SPREADSHEET_LOADING = 'global/stopSpreadsheetLoading';
export const SET_MODE = 'reports/report/setMode';
export const SET_REPORT = 'reports/report/setReport';
export const SET_TEMPLATE = 'reports/report/setTemplate';
export const SET_TEMPLATES = 'reports/report/setTemplates';
export const SET_PERIODS = 'reports/report/setPeriods';
export const SET_TOTAL_EMPLOYEES = 'reports/report/setTotalEmployees';
export const SET_EMPLOYEE_GROUPS = 'reports/report/setEmployeeGroups';

export interface ProcessState {
    report: null|ApiObject.Report;
    template: null|ApiObject.ReportTemplate;
    periods: interfaces.Periods;
    totalEmployees: number;

    mode: constants.ReportProcessMode;
    templates: ApiObject.ReportTemplate[];
    employeeGroups: interfaces.EmployeeGroups;

    isLoadingSpreadsheet: boolean;
    spreadsheet: any;
}

export const defaultState: ProcessState = {
    report: null,
    template: null,
    templates: [],
    mode: constants.ReportProcessMode.createReport,
    periods: [],
    totalEmployees: 0,
    employeeGroups: {},

    isLoadingSpreadsheet: false,
    spreadsheet: null,
};

export default function reducer(
    state = defaultState,
    action: AnyAction,
): ProcessState {
    switch (action.type) {
        case SET_SPREADSHEET:
            return {
                ...state,
                spreadsheet: action.payload,
            };
        case START_SPREADSHEET_LOADING:
            return {
                ...state,
                isLoadingSpreadsheet: true,
            };
        case STOP_SPREADSHEET_LOADING:
            return {
                ...state,
                isLoadingSpreadsheet: false,
            };
        case SET_MODE:
            return {
                ...state,
                mode: action.payload,
            };
        case SET_EMPLOYEE_GROUPS:
            return {
                ...state,
                employeeGroups: action.payload,
            };
        case SET_REPORT:
            return {
                ...state,
                report: action.payload,
            };

        case SET_TEMPLATE:
            return {
                ...state,
                template: action.payload,
            };

        case SET_TEMPLATES:
            return {
                ...state,
                templates: action.payload,
            };

        case SET_PERIODS:
            return {
                ...state,
                periods: action.payload,
            };

        case SET_TOTAL_EMPLOYEES:
            return {
                ...state,
                totalEmployees: action.payload,
            };

        case EVENT_REPORT_WAS_UPDATED:
            const report: ApiObject.Report|null = action.payload;
            return {
                ...state,
                report: report && state.report && state.report.id === report.id
                    ? report
                    : state.report
            };

        default:
            return state;
    }
}

export function getTemplates(): ThunkResult<Promise<void>> {
    return async(dispatch: Dispatch) => {
        dispatch(setTemplates(await dispatch(rootGetTemplates() as any)));

        return;
    };
}

export function getEmployeeGroups(): ThunkResult<Promise<void>> {
    return async(dispatch: Dispatch, getState: () => RootState) => {
        const currentLegalEntityId = getState().global.currentLegalEntityId;
        if (!currentLegalEntityId) {
            return;
        }

        dispatch(setEmployeeGroups(await ApiEmployee.getEmployeeGroups(currentLegalEntityId)));

        return;
    };
}

export function createTemplate(template: ApiObject.ReportTemplate, quietMode: boolean = false): ThunkResult<Promise<ApiObject.ReportTemplate|null>> {
    return async(dispatch: Dispatch, getState: () => RootState) => {
        const state = getState();

        const currentLegalEntityId = state.global.currentLegalEntityId;
        const authorizedUser = state.global.authorizedUser;

        if (!currentLegalEntityId || !authorizedUser || !template) {
            return null;
        }

        const createdTemplate: ApiObject.ReportTemplate|null = await ApiReportTemplate.createTemplate(
          currentLegalEntityId,
          {
            ...template,
            labelFormula: `CONCAT('${template.label}')`,
          },
          selectors.getReport(state),
          reportsSelectors.getFieldGroups(state),
          reportsSelectors.getLegalEntityPeriods(state)
        );
        if (createdTemplate) {
            if (!quietMode) {
                toast.success(intl.get('report_template_created_ok'));
            }

            if (!createdTemplate.isTemporary) {
                dispatch(setTemplates(
                    [...selectors.getTemplates(state), createdTemplate],
                ));
            }

            dispatch(setTemplate(createdTemplate));
            const storedReport = selectors.getReport(state);
            if (storedReport) {
                dispatch(setReport({...storedReport, templateId: createdTemplate.id} as any));
            }

            return createdTemplate;
        }

        if (!quietMode) {
            toast.error(intl.get('report_template_created_nok'));
        }

        return null;
    };
}

export function updateTemplate(template: ApiObject.ReportTemplate, quietMode: boolean = false): ThunkResult<Promise<ApiObject.ReportTemplate|null>> {
    return async(dispatch: Dispatch, getState: () => RootState) => {
        const state = getState();

        const currentLegalEntityId = state.global.currentLegalEntityId;
        const authorizedUser = state.global.authorizedUser;

        if (!currentLegalEntityId || !authorizedUser || !template) {
            return null;
        }

        if (!ReportTemplatesHelper.isCurrentUserOwnerOnTemplate(template)) {
            if (!quietMode) {
                toast.warn(intl.get('report_update_warning'));
            }
            return null;
        }

        dispatch(startLoading());
        const updatedTemplate: ApiObject.ReportTemplate|null = await ApiReportTemplate.editTemplate(
          currentLegalEntityId,
          {
            ...template,
            labelFormula: `CONCAT('${template.label}')`,
          },
          selectors.getReport(state),
          reportsSelectors.getFieldGroups(state),
          reportsSelectors.getLegalEntityPeriods(state)
        );
        dispatch(stopLoading());
        if (updatedTemplate) {
            if (!quietMode) {
                toast.success(intl.get('report_template_update_ok'));
            }

            if (!updatedTemplate.isTemporary) {
                const storedTemplates = [...selectors.getTemplates(state)];
                const storedTemplateIndex = storedTemplates.findIndex(t => t.id === updatedTemplate.id);
                if (storedTemplateIndex !== -1) {
                    storedTemplates.splice(storedTemplateIndex, 1, updatedTemplate);
                    dispatch(setTemplates(storedTemplates));
                }
            }

            dispatch(setTemplate(updatedTemplate));

            return updatedTemplate;
        }

        if (!quietMode) {
            toast.error(intl.get('report_template_update_nok'));
        }

        return null;
    };
}

export function createReport({label, visibility, legalEntityReportType, regenerateMasterdata}: { label: string, visibility?: ApiObject.ReportVisibility, legalEntityReportType?: ApiObject.DataSource, regenerateMasterdata?: boolean}): ThunkResult<Promise<void>> {
    return async(dispatch: Dispatch, getState: () => RootState) => {
        const state = getState();
        const template = selectors.getTemplate(state);
        const authorizedUser = state.global.authorizedUser;
        const currentLegalEntityId = state.global.currentLegalEntityId as number;
        const fullReportLabel = [label, selectors.getReportSuffix(state)].join('_');
        let report = selectors.getReport(state);

        if (!report) {
            toast.error(intl.get('report_doesnt_exist'));
            return;
        }

        if (!template) {
          toast.error(intl.get('report_template_not_set'));
          return;
        }

        // Update label in store, will be used for creating/updating template (Headers section)
        await dispatch(setReport({...report, label: fullReportLabel}))

        report = {
            ...report,
            label: fullReportLabel,
            visibility: visibility || ApiObject.ReportVisibility.private,
            legalEntityPeriodIds: getLegalEntityPeriods(
              selectors.getPeriods(state),
              reportsSelectors.getLegalEntityPeriods(state)
            ).map(p => p.id),
            legalEntityReportType: legalEntityReportType || ApiObject.DataSource.payroll,
            regenerateMasterdata: regenerateMasterdata || false
        };

        const originTemplate = template ? selectors.getTemplates(state).find(t => t.id === template.id) : null;
        const isTemporaryTemplate = template && template.id === -1;
        const isTemplateColumnsWasChanged = originTemplate && ReportTemplatesHelper.isTemplateColumnsWasChanged(originTemplate, template);
        const isTemplateEmployeesWasChanged = originTemplate && ReportTemplatesHelper.isTemplateEmployeesWasChanged(originTemplate, template);
        const isOwnerForTemplate = ReportTemplatesHelper.isCurrentUserOwnerOnTemplate(template);
        const isTemplateWithCurrencies = report.showCurrencyForAmounts;

        if (!isOwnerForTemplate || isTemporaryTemplate || isTemplateColumnsWasChanged || isTemplateWithCurrencies) {
            // If template custom and we just need to save report, then save template as temporary
            const createdTemplate = await dispatch(createTemplate({
                ...template,
                label,
                labelFormula: `CONCAT('${label}')`,
                isTemporary: true,
            }, true) as any);

            if (createdTemplate) {
                report = {...report, templateId: createdTemplate.id};
                dispatch(setReport(report));
            } else {
                toast.error(intl.get('report_save_error'));
                return;
            }
        } else if (isTemplateEmployeesWasChanged) {
            // If template employees changed update template
            // New defined employees will apply to template
            const updatedTemplate: ApiObject.ReportTemplate|null =
              await dispatch(updateTemplate(template, true) as any);
            if (!updatedTemplate) {
                return
            }
        }

        // Create report
        dispatch(startLoading());
        let createdReport = await ApiReport.createReport(currentLegalEntityId, report);
        dispatch(stopLoading());

        if (!createdReport) {
            toast.error(intl.get('report_not_created'));
            return;
        }

        // Update report label
        dispatch(startLoading());
        createdReport = await ApiReport.updateReportName(currentLegalEntityId, createdReport, fullReportLabel);
        dispatch(stopLoading());

        // Trigger for Generate report
        dispatch(startLoading());
        const jsonReportResponse = await ApiReport.generateReport(currentLegalEntityId, createdReport);
        dispatch(stopLoading());

        if (!createdReport || !jsonReportResponse || jsonReportResponse.status === 'errored') {
            toast.error(intl.get('report_not_created'));
            return;
        }

        if (authorizedUser && !createdReport.ownerEmployee) {
            createdReport.ownerEmployee = {
                id: authorizedUser.employee.id,
                firstName: authorizedUser.employee.name,
                lastName: '',
                referenceCode: authorizedUser.employee.referenceCode,
            };
        }

        toast.success(intl.get('report_created'));

        // Check report and update in store when will be generated
        dispatch(addGeneratingReportId(createdReport.id) as any);
        dispatch(checkGeneratedReport(createdReport.id) as any);

        return createdReport;
    };
}

export function updateReport(report: ApiObject.Report, quietMode: boolean = false): ThunkResult<Promise<ApiObject.Report|null>> {
    return async(dispatch: Dispatch, getState: () => RootState) => {
        const state = getState();
        const currentLegalEntityId = state.global.currentLegalEntityId;
        const authorizedUser = state.global.authorizedUser;

        if (!currentLegalEntityId || !authorizedUser || !report) {
            return null;
        }

        if (!ReportHelper.isCurrentUserOwnerOnReport(report)) {
            if (!quietMode) {
                toast.warn(intl.get('report_update_warning'));
            }
            return null;
        }

        const reportBeforeUpdating: ApiObject.Report|null = selectors.getReport(state);
        if (!reportBeforeUpdating) {
            return null;
        }

        dispatch(startLoading());
        let updatedReport: ApiObject.Report|null = await ApiReport.editReport(currentLegalEntityId, report);
        dispatch(stopLoading());
        if (updatedReport) {
            if (!quietMode) {
                toast.success(intl.get('report_updated_ok'));
            }

            // API combine updated fields with previous report object
            updatedReport = {
                ...reportBeforeUpdating,
                visibility: updatedReport.visibility,
                label: updatedReport.label,
                isTemporary: updatedReport.isTemporary,
            };

            dispatch(setReport(updatedReport));

            return updatedReport;
        }

        if (!quietMode) {
            toast.error(intl.get('report_updated_nok'));
        }

        return null;
    };
}

export function changeTemplate(nextTemplate: ApiObject.ReportTemplate|null = null): ThunkResult<Promise<void>> {
    return async(dispatch: Dispatch, getState: () => RootState) => {
        const prevTemplate: ApiObject.ReportTemplate|null = selectors.getTemplate(getState());
        dispatch(setTemplate(nextTemplate ? {...nextTemplate} : null));
        dispatch(templateWasChanged(prevTemplate, nextTemplate) as any);
        return;
    };
}

export function changeReport(nextReport: ApiObject.Report|null = null): ThunkResult<Promise<void>> {
    return async(dispatch: Dispatch, getState: () => RootState) => {
        const prevReport: ApiObject.Report|null = selectors.getReport(getState());
        await dispatch(setReport(nextReport ? {...nextReport} : null));
        dispatch(reportWasChanged(prevReport, nextReport) as any);
        dispatch(setSpreadsheet(null) as any);

        return;
    };
}

export function changePeriods(periods: interfaces.Periods = []): ThunkResult<Promise<void>> {
    return async(dispatch: Dispatch) => {
        dispatch(setPeriods(periods) as any);
        dispatch(periodsWasChanged(periods) as any);

        return;
    };
}

function reportWasChanged(prevReport: ApiObject.Report|null, nextReport: ApiObject.Report|null = null): ThunkResult<Promise<void>> {
    return async(dispatch: Dispatch, getState: () => RootState) => {
        const state = getState();
        const legalEntityPeriods: ApiObject.LegalEntityPeriod[] = reportsSelectors.getLegalEntityPeriods(state);

        if (nextReport) {
            const reportIdWasChanged = !prevReport || prevReport.id !== nextReport.id;
            // Update stored periods from report.legalEntityPeriodIds
            if (reportIdWasChanged) {
                dispatch(setPeriods(
                  legalEntityPeriods.filter(p => nextReport.legalEntityPeriodIds.includes(p.id))
                ));
            }
        }

        return;
    };
}

function templateWasChanged(prevTemplate: ApiObject.ReportTemplate|null, nextTemplate: ApiObject.ReportTemplate|null = null): ThunkResult<Promise<void>> {
    return async(dispatch: Dispatch, getState: () => RootState) => {
        if (!nextTemplate) {
            dispatch(setTotalEmployees(0));
            return
        }

        const legalEntityId = getState().global.currentLegalEntityId as number
        const templateIdWasChanged = !prevTemplate || prevTemplate.id !== nextTemplate.id;
        const templateQueriesWasChanged = !prevTemplate || JSON.stringify(prevTemplate.employees) !== JSON.stringify(nextTemplate.employees);
        const isEmployeesEmpty = !nextTemplate.employees || nextTemplate.employees?.queries?.length === 0

        if (isEmployeesEmpty) {
            dispatch(setTotalEmployees(0));
        } else if (templateIdWasChanged || templateQueriesWasChanged) {
            // Get employees and store total
            const params = new EmployeeQueryParams({
                groupedQueries: nextTemplate.employees?.queries || [],
                limit: 1,
            })
            const {total} = await ApiEmployee.list(legalEntityId, params)
            dispatch(setTotalEmployees(total || 0));
        }
    };
}

function periodsWasChanged(periods: interfaces.Periods = []): ThunkResult<Promise<void>> {
    return async(dispatch: Dispatch, getState: () => RootState) => {
        const state = getState();
        const currentReport: ApiObject.Report|null = selectors.getReport(state);
        const legalEntityPeriods: ApiObject.LegalEntityPeriod[] = reportsSelectors.getLegalEntityPeriods(state);

        if (currentReport) {
            // Update report legalEntityPeriods after `periods` was changed
            const selectedLegalEntityPeriods: ApiObject.LegalEntityPeriod[] = getLegalEntityPeriods(periods, legalEntityPeriods)
            const canRegenerate = canRegenerateMasterdata(currentReport.legalEntityReportType, selectedLegalEntityPeriods)
            dispatch(changeReport(
                {
                    ...currentReport,
                    regenerateMasterdata: currentReport.regenerateMasterdata && canRegenerate,
                    legalEntityPeriodIds: getLegalEntityPeriods(periods, legalEntityPeriods).map(p => p.id),
                },
            ) as any);
        }

        return;
    };
}

export function openTemplateViewProcess(template: ApiObject.ReportTemplate, mode: constants.ReportProcessMode|null = null): ThunkResult<Promise<void>> {
    return async(dispatch: Dispatch) => {
        await dispatch(changeTemplate(template) as any);
        await dispatch(changePeriods() as any);
        await dispatch(setMode(
            mode ? mode : constants.ReportProcessMode.viewTemplate,
        ));

        RouteHelper.goToReportPreview();

        return;
    };
}

export function clearStateForGenerateReport(template: ApiObject.ReportTemplate): ThunkResult<Promise<void>> {
    return async(dispatch: Dispatch) => {
        // Clear employees
        const pureTemplate = {
            ...template,
            employees: {
                queries: [],
                orderBy: []
            }
        }
        await dispatch(changeTemplate(pureTemplate) as any);
        await dispatch(changePeriods() as any);
        await dispatch(changeReport({
            id: -1,
            label: '',
            employeeIds: [],
            ownerEmployee: {
                firstName: null,
                id: null,
                lastName: null,
                referenceCode: null,
            },
            status: ApiObject.ReportStatus.draft,

            legalEntityPeriodIds: [],
            visibility: template.visibility,
            isTemporary: false,
            templateId: template.id,
        }) as any);

        return;
    };
}

export function openReportExportProcess(report: ApiObject.Report, mode: constants.ReportProcessMode|null = null): ThunkResult<Promise<void>> {
    return async(dispatch: Dispatch) => {
        dispatch(changeReport(report) as any);
        if (mode) {
            dispatch(setMode(mode) as any);
        }
        RouteHelper.goToReportExport();

        return;
    };
}

function getReportXlsxAndDoSomething(onSuccess: ({data, status, headers}: any) => any, id: number, attempts: number[] = [...constants.defaultGetReportAttempts], locationHref: string|null = null) {
    return async(dispatch: Dispatch, getState: () => RootState) => {
        if (!locationHref) {
            locationHref = window.location.href;
        } else {
            // Break loading when user leave export page
            if (locationHref !== window.location.href) {
                return;
            }
        }

        dispatch(startSpreadsheetLoading());
        const entityId = getState().global.currentLegalEntityId;
        if (!entityId || !id) {
            dispatch(stopSpreadsheetLoading());
            return;
        }

        if (attempts.length === 0) {
            toast.warn(intl.get('report_generated_nok'));
            dispatch(stopSpreadsheetLoading());
            return;
        }

        const {data, status, headers} = await ApiReport.getReportXlsx(entityId, id);

        if (status === 204) {
            const delay = attempts.shift();
            setTimeout(() => dispatch(getReportXlsxAndDoSomething(onSuccess, id, attempts, locationHref) as any), delay);
            return;
        }

        if (!data || status !== 200) {
            toast.error(intl.get('sth_went_wrong'));
            dispatch(stopSpreadsheetLoading());
            return;
        }

        onSuccess({data, status, headers});

        dispatch(stopSpreadsheetLoading());
    }
}


export function exportReport(id: number): ThunkResult<Promise<void>> {
    return async(dispatch: Dispatch) => {
        dispatch(getReportXlsxAndDoSomething(({data, headers}: any) => {
            const blob = new Blob([data], {type: headers['content-type']});
            saveAs(blob, headers['x-filename']);
        }, id) as any);

        return;
    };
}

export function displayReport(id: number): ThunkResult<Promise<void>> {
    return async(dispatch: Dispatch) => {
        dispatch(getReportXlsxAndDoSomething(({data}: any) => {
            dispatch(setSpreadsheet(
                XLSX.read(new Uint8Array(data), {type:'array'})
            ));
        }, id) as any);

        return;
    };
}

export function setEmployeeGroups(employeeGroups: interfaces.EmployeeGroups = {}): AnyAction {
    return {
        type: SET_EMPLOYEE_GROUPS,
        payload: employeeGroups,
    };
}

function setPeriods(periods: interfaces.Periods = []): AnyAction {
    return {
        type: SET_PERIODS,
        payload: periods,
    };
}

function setTemplates(templates: ApiObject.ReportTemplate[] = []): AnyAction {
    return {
        type: SET_TEMPLATES,
        payload: templates,
    };
}

function setReport(report: ApiObject.Report|null = null): AnyAction {
    return {
        type: SET_REPORT,
        payload: report,
    };
}

function setTemplate(template: ApiObject.ReportTemplate|null = null): AnyAction {
    return {
        type: SET_TEMPLATE,
        payload: template,
    };
}

export function setMode(mode: constants.ReportProcessMode): AnyAction {
    return {
        type: SET_MODE,
        payload: mode,
    };
}

export function setSpreadsheet(spreadsheet: any): AnyAction {
    return {
        type: SET_SPREADSHEET,
        payload: spreadsheet,
    };
}

export function startSpreadsheetLoading(): AnyAction {
    return { type: START_SPREADSHEET_LOADING };
}

export function stopSpreadsheetLoading(): AnyAction {
    return { type: STOP_SPREADSHEET_LOADING };
}

export function setTotalEmployees(count: number = 0): AnyAction {
    return {
        type: SET_TOTAL_EMPLOYEES,
        payload: count,
    };
}
