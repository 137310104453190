import React, {Fragment, PureComponent} from 'react';
import {connect} from 'react-redux';

import {constants, intl} from '@global';
import {EmployeeInterfaces} from '@api';
import {Actions, Switch} from '@common';

import Activate from '../../../common/Employee/Activate';
import Suspend from '../../../common/Employee/Suspend';

import {
    activate,
    suspend,
    clearOldAndGetEmployees,
    getEmployeeCounts,
} from '../../redux';

interface IProps {
    employees: EmployeeInterfaces.Employee[];
    ids: Set<number>;
    totalCount: number;
    chosenStatus: string;
    isLoading: boolean;
    defaultWelcomeMessage: string;

    activate: (ids: Set<number>) => any;
    suspend: (ids: Set<number>) => any;
    clearOldAndGetEmployees: () => void;
    getEmployeeCounts: () => void;
}

export class BulkActions extends PureComponent<IProps> {
    render() {
        return (
            <Actions.DropdownActions
                label={intl.get('bulk_actions') + this.props.ids.size + intl.get('out_of') + this.props.totalCount}
            >
                <Switch
                    current={this.props.chosenStatus}
                    options={new Map([
                            [
                                constants.EmployeeStatuses.inactive,
                                (
                                    <Fragment>
                                        {this.renderActivateAction()}
                                    </Fragment>
                                ),
                            ],
                            [
                                constants.EmployeeStatuses.draft,
                                (
                                    <Fragment>
                                        {this.renderActivateAction()}
                                    </Fragment>
                                ),
                            ],
                        ])}
                />
            </Actions.DropdownActions>
        );
    }

    private renderActivateAction() {
        return (
            <Activate
                forEmployeeIds={this.props.ids}
                isLoading={this.props.isLoading}
                onClickActivate={(ids: Set<number>) => {
                    this.props.activate(ids).then(() => {
                        this.props.clearOldAndGetEmployees();
                        this.props.getEmployeeCounts();
                    })
                }}
            />
        );
    }

    private renderSuspendAction() {
        return (
            <Suspend
                forEmployeeIds={this.props.ids}
                isLoading={this.props.isLoading}
                onClickSuspend={(ids: Set<number>) => {
                    this.props.suspend(ids).then(() => {
                        this.props.clearOldAndGetEmployees();
                        this.props.getEmployeeCounts();
                    })
                }}
            />
        );
    }
}

const mapDispatchToProps = {
    activate,
    suspend,
    clearOldAndGetEmployees,
    getEmployeeCounts,
};

export default connect(null, mapDispatchToProps)(BulkActions);
