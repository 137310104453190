
import React, {PureComponent} from 'react';
import styled from 'styled-components';

import {interfaces} from '@global';

import Order from './Order';

interface IProps {
    label: string;
    dataKey?: string;
    orders?: interfaces.ListingOrders;
    changeOrder?: (column: string) => any;
}

const StyledHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export default class Ordered extends PureComponent<IProps> {
    render() {
        return (
            <StyledHeaderWrapper>
                {this.props.label}

                <Order column={this.props.dataKey} orders={this.props.orders} changeOrder={this.props.changeOrder}/>
            </StyledHeaderWrapper>
        );
    }
}
