import {toast, TypeOptions} from 'react-toastify'

export default class SingleToastMessage {
    private readonly toastId: string
    private readonly message: string
    private readonly type: TypeOptions

    constructor(toastId: string, message: string, type: TypeOptions) {
        this.toastId = toastId;
        this.message = message;
        this.type = type;
    }

    public show(): void {
        // If toast is active already just reset
        if (toast.isActive(this.toastId)) {
            toast.update(this.toastId)
            return
        }

        toast(this.message, {toastId: this.toastId, type: this.type})
    }

    public close(): void {
        toast.dismiss(this.toastId)
    }
}
