import React, {PureComponent} from 'react';

import {LeaveInterfaces} from '@api';
import styled from 'styled-components';
import { stylingVariables, constants, intl, numberFormat } from '@global';
import {FormatNumbers, Icon, Layout} from '@common';

const Wrapper = styled.div`
  margin: 10px 0 0 77px;
`;

const ContentWrapper = styled.div`

`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 14px;
`;

const TextContent = styled.div<{marginRight?: number}>`
  font-size: ${stylingVariables.fontSize.small};
  color: ${stylingVariables.colorPalette.deepGray};
  margin-right: ${props => props.marginRight || 0}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  
  span {
      margin-top: 2px;
  }
`;


const Bubble = styled.div<{marginRight?: number, marginLeft?: number}>`
  border-radius: 50%;
  border: 2px solid ${stylingVariables.colorPalette.yellow};
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${stylingVariables.fontSize.largest};
  margin-right: ${props => props.marginRight || 0}px;
  margin-left: ${props => props.marginLeft || 0}px;
`;

const Triangle = styled.div`
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    
    border-top: 5px solid ${stylingVariables.colorPalette.red};
`;

const LeaveDays = styled.span`
    color: ${stylingVariables.colorPalette.red};
    font-size: ${stylingVariables.fontSize.mediumLarge};
    margin-left: 6px;
`;

const Arrow = styled.div`
    margin-right: 36px;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    
    &:before {
      width: 22px;
      height: 1px;
      background: ${stylingVariables.colorPalette.darkest};
      content: "";
      display: inline-block;
      vertical-align: middle;
      position: absolute;
    }
    
    svg {
        margin-left: 13px;
    }
`;


interface IProps {
    balance: LeaveInterfaces.Balance;
    leaveDays?: number;
    type?: LeaveInterfaces.Type;
}

export default class Balance extends PureComponent<IProps> {
    public render() {
        const beforeTransaction = (this.props.balance.ytdBalance || 0) - (this.props.balance.ytdTaken || 0);
        const afterTransaction = beforeTransaction - (this.props.leaveDays || 0);
        const daysLeftMessage = this.getUnitType();
        return (
            <Wrapper>
                <Layout.Row>
                    <Layout.RowLabel percent={15} top={20}>
                        {intl.get('balance')}
                    </Layout.RowLabel>

                    <ContentWrapper>
                        <TextWrapper>
                            <TextContent marginRight={117}>
                                <b>{intl.get('before_transaction')}</b>{daysLeftMessage}
                            </TextContent>
                            <TextContent>
                                <b>{intl.get('after_transaction')}</b>{daysLeftMessage}
                            </TextContent>
                        </TextWrapper>
                        <Content>
                            <Bubble marginRight={45} marginLeft={17}>
                                <FormatNumbers showZero={true} value={numberFormat(beforeTransaction)}/>
                            </Bubble>
                            <Arrow>
                                <Icon type={constants.IconTypes.ARROW_RIGHT}
                                      width={16}
                                      height={10}
                                />
                            </Arrow>
                            <Triangle /><LeaveDays>{this.props.leaveDays ? numberFormat(this.props.leaveDays) : '0'}</LeaveDays>
                            <Bubble marginLeft={12}>
                                <FormatNumbers showZero={true} value={numberFormat(afterTransaction)}/>
                            </Bubble>
                        </Content>
                    </ContentWrapper>
                </Layout.Row>
            </Wrapper>
        );
    }

    private getUnitType = () => {
        if (this.props.type?.amountType === LeaveInterfaces.AmountType.workDays ) {
            return intl.get('business_days_left');
        }
        if (this.props.type?.amountType === LeaveInterfaces.AmountType.hours ) {
            return intl.get('hours_left');
        }
        return intl.get('calendar_days_left');
    }
}
