import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';
import {ApiObject, PayrollInterfaces} from '@api';
import {
    openSalaryModal,
    setSalaryModalGroups,
    setSalaryModalValues,
    openSalaryPaymentGroupModal,
    getPayElements,
    getPeriodPayElements,
} from '../../redux';
import {RootState} from '../../../../rootReducer';
import * as selectors from '../../selectors';
import SalarySummary from './SalarySummary/SalarySummary';
import EditModal from './Modal/Edit/Edit';
import SalarySection from './SalarySection/SalarySection';
import AddPayElementGroupModal from './Modal/AddPayElementGroupModal';
import * as AddPaymentGroup from './AddPaymentGroup/index';
import TypesBar from './TypesBar';
import {SalarySectionData} from '../../../../api/employee.interfaces';
import {EmployeePayElement} from '../../../../api/api.object';
import {MonthPicker} from '@common';
import moment, {Moment} from 'moment';
import {intl, stylingVariables} from '@global';
import UnitsSection from './UnitsSection/UnitsSection';
import * as globalSelectors from '../../../selectors';
import {CompensationTypes} from '../../../../constants';

const StyledSalaryTabWrapper = styled.div``;

const StyledSalarySummaryWrapper = styled.div`
  padding: 20px 40px;
  background-color: ${stylingVariables.colorPalette.white};
  margin-bottom: 20px;
  box-shadow: 0px 6px 16px #0000001A;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`;

const StyledSalaryTableWrapper = styled.div`
  padding-bottom: 40px;
  background-color: ${stylingVariables.colorPalette.white};
  box-shadow: 0px 6px 16px #0000001A;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`;

interface IProps {
    salaryEnabledGroups: string[];
    enableableSalarySections: ApiObject.SelectOption[];
    salarySections: SalarySectionData[];

    openSalaryModal: () => void;
    openSalaryPaymentGroupModal: () => void;
    setSalaryModalGroups: (payElementType: string | undefined) => void;
    setSalaryModalValues: (values: EmployeePayElement | undefined) => void;
    salaryTabTypeCodes: string[];
    getPayElements: (accounts?: number[]) => void;
    getPeriodPayElements: () => void;
    currentPeriod: PayrollInterfaces.PayrollPeriod | null;
    periodPayElements: any;
    masterdataEditAllowed: boolean;
}

interface IState {
    from?: string;
    till?: string;
}

const StyledHeader = styled.div`
    display: flex;
    justify-content: space-between;
    h4 {
        padding: 30px 0 26px 77px;
    }
`;

export class Salary extends PureComponent<IProps, IState> {
    public constructor(props: IProps) {
        super(props);

        this.props.getPayElements();
        this.props.getPeriodPayElements();
        this.state = {
            from: props.currentPeriod?.beginDate,
            till: props.currentPeriod?.endDate,
        };
    }

    private onChangeSelectedPeriod = (selected: Moment) => {
        this.setState({
            from: moment(selected).startOf('month').format('YYYY-MM-DD'),
            till: moment(selected).endOf('month').format('YYYY-MM-DD'),
        });
    }

    private groupPayElements = (salarySections: SalarySectionData[], period: string) => {
        const unitsMap = new Map();

        salarySections.forEach(payElType => {
            if (!payElType.code.includes(CompensationTypes.additionalUnits)) {
                return;
            }
            const payElements = period === 'current' ? payElType.payElements : payElType.historyElements;

            payElements.forEach((payData: any) => {
                if (period !== 'current' && payData.period !== period) {
                    return;
                }
                const groupKey = payData.rootEmployeePayElementId;

                if (unitsMap.has(groupKey) && period !== 'current') {
                    unitsMap.get(groupKey).payElements.push(payData);
                    unitsMap.get(groupKey).totalAmount += payData.amount;

                    if (moment(payData.startDate).isBefore(moment(unitsMap.get(groupKey).startDate))) {
                        unitsMap.get(groupKey).startDate = payData.startDate;
                    }
                    if (payData.endDate &&
                        unitsMap.get(groupKey).endDate &&
                        moment(payData.endDate).isAfter(moment(unitsMap.get(groupKey).endDate))
                    ) {
                        unitsMap.get(groupKey).endDate = payData.endDate;
                    }
                    if (!payData.source === unitsMap.get(groupKey).source) {
                        unitsMap.get(groupKey).source = '';
                    }
                } else {
                    unitsMap.set(groupKey, {
                        ...payData,
                        payElements: [payData],
                        totalAmount: payData.amount,
                        source: payData.source || '',
                        allowEdit: period === 'current',
                    });
                }
            });
        });

        const groupedUnitsData: any[] = [...unitsMap.values()]
            .map(value => {
                return {
                    ...value,
                    payElements: value.payElements
                        .sort((a: { startDate: string; }, b: { startDate: string; }) => {
                            return (moment(a.startDate, 'YYYY-MM-DD')
                                .isAfter(moment(b.startDate, 'YYYY-MM-DD'))) ? 1 : -1;
                        }),
                };
            }).sort((a, b) => {
                const collator = new Intl.Collator();
                return collator.compare(`${a.payElements[0].label}`, `${b.payElements[0].label}`);
            });
        return groupedUnitsData;
    }

    private getUnitsData = () => {
        if (!this.props.salarySections) {
            return;
        }

        const period = this.state.from === this.props.currentPeriod?.beginDate ?
            'current' : this.props.periodPayElements
            .find((el: { beginDate: string | undefined; }) => el.beginDate === this.state.from);

        if (!period) {
            return [];
        }

        if (period === 'current') {
            return this.groupPayElements(this.props.salarySections, 'current');
        }

        return this.groupPayElements(this.props.salarySections, period.name);
    }

    public render() {
        return <StyledSalaryTabWrapper>
            <StyledSalarySummaryWrapper>
                <SalarySummary salarySections={this.props.salarySections}
                    action={(code: string) => this.add(code)}
                    openSalaryPaymentGroupModalAction={this.props.openSalaryPaymentGroupModal}
                    salaryEnabledGroups={this.props.salaryEnabledGroups}
                    enableableSalarySections={this.props.enableableSalarySections}
                    masterdataEditAllowed={this.props.masterdataEditAllowed}
                />
            </StyledSalarySummaryWrapper>
            <StyledSalaryTableWrapper>
                <TypesBar />

                {!this.props.salaryTabTypeCodes.includes(CompensationTypes.additionalUnits) &&
                    this.props.salarySections && this.props.salarySections.map((data: SalarySectionData) => {
                        if (
                            this.props.salaryTabTypeCodes.length > 0 &&
                            !this.props.salaryTabTypeCodes.includes(data.code)
                        ) {
                            return null;
                        }

                        return <SalarySection data={data}
                            key={data.label}
                            enabledSalaryGroups={this.props.salaryEnabledGroups}
                        />;
                    })}

                {this.props.salaryTabTypeCodes.includes(CompensationTypes.additionalUnits) && (<>
                    <StyledHeader>
                        <h4>{intl.get('units')}</h4>
                        <MonthPicker
                            selected={moment(this.state.from)}
                            onChangeDate={this.onChangeSelectedPeriod}
                            popperPlacement={'bottom-end'}
                            maxDate={moment(this.props.currentPeriod?.beginDate)}
                        />
                    </StyledHeader>
                    <UnitsSection
                        data={{
                            label: intl.get('units'),
                            code: CompensationTypes.additionalUnits,
                            elements: this.getUnitsData(),
                        }}
                        isCurrentPeriod={this.state.from === this.props.currentPeriod?.beginDate}
                        enabledSalaryGroups={this.props.salaryEnabledGroups}
                    />
                </>
                )}

                {!!(this.props.enableableSalarySections && this.props.enableableSalarySections.length &&
                    !this.props.salaryTabTypeCodes.includes(CompensationTypes.additionalUnits)) &&
                    this.props.masterdataEditAllowed &&
                    <AddPaymentGroup.Link action={this.props.openSalaryPaymentGroupModal} />}

                <EditModal />
                <AddPayElementGroupModal />
            </StyledSalaryTableWrapper>
        </StyledSalaryTabWrapper>;
    }

    private add(code: string) {
        this.props.setSalaryModalGroups(code);
        this.props.setSalaryModalValues(undefined);
        this.props.openSalaryModal();
    }
}

const mapStateToProps = (state: RootState) => ({
    salaryTabTypeCodes: selectors.getSalaryTabTypeCodes(state),
    salaryEnabledGroups: selectors.getSalaryEnabledPaymentGroups(state),
    enableableSalarySections: selectors.getEnableableSalarySections(state),
    salarySections: selectors.getEmployeeSalarySections(state),
    periodPayElements: selectors.getPeriodPayElements(state),
    currentPeriod: globalSelectors.getCurrentPayrollPeriod(state),
    masterdataEditAllowed: globalSelectors.masterdataEditAllowed(state, null)
});

const mapDispatchToProps = {
    openSalaryModal,
    openSalaryPaymentGroupModal,
    setSalaryModalGroups,
    setSalaryModalValues,
    getPayElements,
    getPeriodPayElements,
};

export default connect(mapStateToProps, mapDispatchToProps)(Salary);
