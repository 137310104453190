import {ApiObject} from '@api';
import {store} from '@global';
import moment from 'moment'
import {SYSTEM_DATE_FORMAT} from '../constants'

export default class ReportHelper {
    public static isCurrentUserOwnerOnReport(report: ApiObject.Report|null) {
        if (!report) {
            return false;
        }

        if (report.id === -1) {
            return true;
        }

        const authorizedUser = store.getState().global.authorizedUser;
        if (!authorizedUser) {
            return false;
        }

        // hack, API doesn't response owner on create/edit report
        if (!report.ownerEmployee || !report.ownerEmployee.id) {
            return true;
        }

        return report.ownerEmployee.id === authorizedUser.employee.id;
    }

    public static reportPeriodLabel(
      report: ApiObject.Report|null,
      legalEntityPeriods: ApiObject.LegalEntityPeriod[] = []
    ): string {
        if (!report) {
            return ''
        }
        const df = (date: string): string => moment(date).format(SYSTEM_DATE_FORMAT)
        return legalEntityPeriods
            .filter(p => report.legalEntityPeriodIds.includes(p.id))
            .map(period => `${df(period.beginDate)} - ${df(period.endDate)}`)
            .join(', ')
    }
}
