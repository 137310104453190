import {ApiObject} from '@api';
import {interfaces, stylingVariables, intl} from '@global';
import {DateHelper, AccessManager} from '@library';
import React, {PureComponent} from 'react';
import {Column} from 'react-virtualized';
import styled from 'styled-components';

import {defaultInfiniteTableCellDataGetter, InfiniteTableHeader} from '@common';

const StyledCellWrapper = styled.div`
    display: flex;
    flex-direction: column;
    font-size: ${stylingVariables.fontSize.default};
    span {
      font-size: ${stylingVariables.fontSize.medium};
    }
`;
const You = styled.span`
  font-weight: ${stylingVariables.fontWeight.semibold};
  font-size: ${stylingVariables.fontSize.default};
  color: ${stylingVariables.colorPalette.orange};
`;

interface IProps {
    cellData?: ApiObject.Report;

    orders: interfaces.ListingOrders;
    filters: interfaces.ListingFilters;
    changeOrder: (column: string) => any;
    changeFilter: (params: interfaces.FilterParams) => any;

    label: string;
    width?: number;
    flexGrow?: number;
}

interface ICellProps {
    cellData?: ApiObject.Report;
}

class Cell extends PureComponent<ICellProps> {
    public render() {
        if (!this.props.cellData) {
            return null;
        }
        const ownerEmployee = this.props.cellData.ownerEmployee;
        const employeeName = [ownerEmployee.firstName, ownerEmployee.lastName].filter(n=>!!n).join(' ');

        const itIsMe = AccessManager.itIsMe(ownerEmployee.id);

        return (
            <StyledCellWrapper>
                {DateHelper.convertDateToHumanFormat(this.props.cellData.createdAt)}
                {employeeName && <span> {intl.get('by')} {employeeName} {itIsMe && <You>{intl.get('you')}</You>}</span>}
            </StyledCellWrapper>
        );
    }
};

const ReportCreationDateAndUser: React.FC<IProps> = ({label, width = 200, flexGrow = 1, orders, changeOrder, filters, changeFilter}: IProps) => (
    <Column
        dataKey={`report_creation_date_and_user`}
        label={label}
        width={width}
        flexGrow={flexGrow}
        cellDataGetter={defaultInfiniteTableCellDataGetter}
        headerRenderer={() => (
            <InfiniteTableHeader.OrderedAndFiltered
                label={label}
                type={ApiObject.FieldType.date}
                dataKey={'createdAt'}
                orders={orders}
                changeOrder={changeOrder}
                filters={filters}
                changeFilter={changeFilter}
            />
        )}
        cellRenderer={({cellData}) => <Cell cellData={cellData} />}
    />
);

export default ReportCreationDateAndUser;
