import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { EmployeeInterfaces, ApiObject } from '@api'

import { RootState } from '../../../../../rootReducer'
import {
  createUserForPaymentProcessor,
  getCountriesAndCurrencies,
  getFieldsForBankAccount,
  createBankAccount,
  clearPaymentProcessor,
  getBankAccount,
} from '../../../redux'
import * as selectors from '../../../selectors'
import View from './View'
import Edit from './Edit'
import styled from 'styled-components'
import { stylingVariables } from '@global'

export const StyledWrapper = styled.div`
  border-bottom: 1px solid ${stylingVariables.colorPalette.gray};
  &:last-child {
    border-bottom: 0;
  }
`

export const StyledFieldsGroupHeader = styled.h4`
  color: ${stylingVariables.colorPalette.darkest};
  font-size: 16px;
  font-weight: ${stylingVariables.fontWeight.bold};
  padding: 0;
  margin: 30px 37px;
  text-transform: capitalize;
`

interface IProps {
  employee: EmployeeInterfaces.Employee
  isEdit: boolean
  createUserForPaymentProcessor: (id: number) => Promise<ApiObject.BankAccount>
  getFieldsForBankAccount: (id: number, params: object) => Promise<object>
  bankData: ApiObject.BankData
  bankDetails: ApiObject.BankDetails
  clearPaymentProcessor: () => void
  getBankAccount: (id: number) => Promise<ApiObject.BankAccount>
  isLoading: boolean
}

interface IState {
  isEdit: boolean
  bankAccountFieldsData: ApiObject.BankAccountFields
  transferMethodCountry: string
  transferMethodCurrency: string
}

export class PaymentProcessor extends PureComponent<IProps, IState> {
  public constructor(props: IProps) {
    super(props)

    props.clearPaymentProcessor()

    this.state = {
      isEdit: false,
      bankAccountFieldsData: {} as ApiObject.BankAccountFields,
      transferMethodCurrency: '',
      transferMethodCountry: '',
    }
  }

  public async componentDidMount() {
    await this.props.getBankAccount(this.props.employee.id)

    this.fetchBankFields()
  }

  private async fetchBankFields() {
    if (this.props.bankDetails) {
      const { transferMethodCurrency, transferMethodCountry } = this.props.bankDetails

      if (transferMethodCurrency && transferMethodCountry) {
        this.setState({ transferMethodCurrency, transferMethodCountry })

        const params = {
          country: transferMethodCountry,
          currency: transferMethodCurrency,
        }

        await this.props.getFieldsForBankAccount(this.props.employee.id, params)
      }
    }
  }

  private disableEditMode = (getFields: boolean) => {
    this.setState({ isEdit: false })

    if (getFields) {
      this.fetchBankFields()
    }
  }

  private allowEditMode = () => {
    this.setState({ isEdit: true })
  }

  private bankFields = (): ApiObject.BankField[] => {
    return this.props.bankData?.fields || []
  }

  private renderBankData = () => {
    return this.state.isEdit ? (
      <Edit
        bankFields={this.bankFields()}
        employee={this.props.employee}
        onClickCancel={(getFields: boolean) => this.disableEditMode(getFields)}
        isLoading={this.props.isLoading}
      />
    ) : (
      <View
        fields={this.bankFields()}
        bankDetails={this.props.bankDetails}
        onClickEditButton={this.allowEditMode}
        isLoading={this.props.isLoading}
      />
    )
  }

  public render() {
    return (
      <StyledWrapper>
        <StyledFieldsGroupHeader>{this.props.employee.paymentProcessor}</StyledFieldsGroupHeader>
        {this.renderBankData()}
      </StyledWrapper>
    )
  }
}

const mapStateToProps = (state: RootState) => ({
  countries: selectors.getCountryOptions(state),
  bankData: selectors.getBankData(state),
  bankDetails: selectors.getUserBankDetails(state),
  isLoading: selectors.paymentLoading(state),
})

const mapDispatchToProps = {
  createUserForPaymentProcessor,
  getCountriesAndCurrencies,
  getFieldsForBankAccount,
  createBankAccount,
  clearPaymentProcessor,
  getBankAccount,
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentProcessor)
