import React, {PureComponent} from 'react';
import {intl} from '@global';
import {connect} from 'react-redux';

import {ApiObject} from '@api';
import {Actions} from '@common';
import styled from 'styled-components';

import {RootState} from '../../../../../rootReducer';
import * as selectors from '../../../selectors';
import {constants} from '@global';
import {get} from 'lodash';

import Group from './Group';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const LeftSide = styled.div`
  padding-left: 37px;
  min-width: 690px;
`;

const RightSide = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  button {
    padding: 10px 40px;
  }
`;

interface IProps {
    fields: any;
    fieldGroup: ApiObject.FieldGroup;

    errors: ApiObject.EntityFieldErrorMessage[];

    onClickCancel: () => void;
    onClickSave: (fields: ApiObject.FieldEntity[]) => Promise<boolean>;
}

interface IState {
    updatedFields: ApiObject.FieldEntity[];
    secondaryAccount: boolean;
}

class Edit extends PureComponent<IProps, IState> {
    public constructor(props: IProps) {
        super(props);

        this.state = {
            updatedFields: [],
            secondaryAccount: false,
        };
    }

    componentDidMount() {
        this.props.fieldGroup.subgroups.forEach((subFieldGroup: ApiObject.FieldGroup) => {
            if (subFieldGroup.code === constants.DEFAULT_CODE_SECONDARY_BANK_ACCOUNT) {
                const swiftField = subFieldGroup.fields.find((field: ApiObject.Field) =>
                    field.code === constants.DEFAULT_FIELD_CODE_SWIFT
                )

                if (swiftField) {
                    const value = get(this.props.fields, `${swiftField.entity}.${swiftField.code}`)
                    if (value) {
                        this.setState({secondaryAccount: true})
                    }
                }
            }
        })
    }

    public toggleAddAccount = () => {
        this.setState({secondaryAccount: !this.state.secondaryAccount})

        if (!this.state.secondaryAccount) {
            this.setState({updatedFields: []});
        }
    }

    private renderSecondaryBankAccount(subFieldGroup: ApiObject.FieldGroup) {
        if (subFieldGroup.code === constants.DEFAULT_CODE_SECONDARY_BANK_ACCOUNT) {
            return subFieldGroup.fields.map((field: ApiObject.Field) => {
                if (field.code === constants.DEFAULT_FIELD_CODE_SWIFT) {
                    if (this.state.secondaryAccount) {
                        return (
                            <Group key={'field_group' + subFieldGroup.code}
                                group={subFieldGroup}
                                fields={this.props.fields}
                                errors={this.props.errors}
                                onChange={this.updateField}
                                showTrash={true}
                                deleteAccount={this.deleteAccount}
                            />
                        )
                    }
                    return (
                        <Actions.PlusAction
                            onClick={this.toggleAddAccount}
                            size={30}
                            fontSize={14}
                            key={'add_account'}
                        >
                            {intl.get('add_account')}
                        </Actions.PlusAction>
                    )
                }

                return null
            });
        }

        return (
            <Group key={'field_group' + subFieldGroup.code}
                group={subFieldGroup}
                fields={this.props.fields}
                errors={this.props.errors}
                onChange={this.updateField}
            />
        )
    }

    public render() {
        return (
            <Wrapper>
                <LeftSide>
                    <Group
                        group={this.props.fieldGroup}
                        fields={this.props.fields}
                        errors={this.props.errors}
                        onChange={this.updateField}
                    />

                    {
                        this.props.fieldGroup.subgroups.map((subFieldGroup: ApiObject.FieldGroup) =>
                            this.renderSecondaryBankAccount(subFieldGroup)
                        )
                    }
                </LeftSide>
                <RightSide>
                    <Actions.ActionRightBar>
                        <Actions.GrayButton onClick={this.props.onClickCancel}>
                            {intl.get('cancel')}
                        </Actions.GrayButton>

                        <Actions.OrangeButton onClick={this.save} isDisabled={this.state.updatedFields.length === 0}>
                            {intl.get('save')}
                        </Actions.OrangeButton>
                    </Actions.ActionRightBar>
                </RightSide>
            </Wrapper>
        );
    }

    private save = async() => {
        const updatedFields = [...this.state.updatedFields];

        const isSuccess = await this.props.onClickSave(updatedFields);
        if (isSuccess) {
            this.setState({updatedFields: []});
        }
    }

    private updateHelper = (updatedFields: ApiObject.FieldEntity[], field: ApiObject.Field, value: any) => {
        const entity = updatedFields.find((item: any) => item.entity === field.entity);

        if (!entity) {
            updatedFields.push({entity: field.entity, values: [{value: value, code: field.code}]});
        } else {
            const entityValue = entity.values.find((item: any) => item.code === field.code);
            if (!entityValue) {
                entity.values.push({value, code: field.code});
            } else {
                entityValue.value = value;
            }
        }
    }

    private deleteAccount = (subFieldGroup: ApiObject.FieldGroup) => {
        const updatedFields = [...this.state.updatedFields];
        const preDefinedFields = [
          "start_date",
          "end_date",
          "account_currency",
          "allocation_type",
        ];

        subFieldGroup.fields.forEach((field: ApiObject.Field) => {
          if (preDefinedFields.includes(field.code)) {
            return;
          }
            
            let value

            if (field.code === 'iban') {
                value = null
            } else {
                value = field.type === "number" ? "0.00" : "";
            }
          this.updateHelper(updatedFields, field, value);
        });

        this.toggleAddAccount();
        this.setState({ updatedFields });
    }

    /**
     * Update default field group
     * @param field
     * @param value
     */
    private updateField = (field: ApiObject.Field, value: any) => {
        const updatedFields = [...this.state.updatedFields];

        this.updateHelper(updatedFields, field, value)

        this.setState({updatedFields});
        return;
    }
}

const mapStateToProps = (state: RootState) => ({
    errors: selectors.getErrorsFields(state),
});

export default connect(mapStateToProps)(Edit);
