import React, {memo} from 'react';
import {Column} from 'react-virtualized';
import styled from 'styled-components';

import {interfaces, stylingVariables, intl} from '@global';
import {
    defaultInfiniteTableCellDataGetter,
    InfiniteTableHeader,
} from '@common';

import {ColorHelper} from "@library";

const StyledEmployeeWrapper = styled.div`
  display: flex;
  cursor: pointer;
  width: 100%;
  align-items: center;
`;

const StyledContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

interface IProps {
    orders?: interfaces.ListingOrders;
    changeOrder?: (column: string) => any;
}

const StyledHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
`;

const StyledNameWrapper = styled.span<{color: string}>`
  cursor: pointer;
  padding: 5px 0;
  font-size: ${stylingVariables.fontSize.mediumLarge};
  font-weight: ${stylingVariables.fontWeight.regular};
  outline: none;

  :before {
      content: '';
      display: inline-block;
      vertical-align: middle;
      margin-right: 10px;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background: ${(props): string => props.color};
    }
`;

const Cell: React.FC<{cellData: any}> = memo(({cellData}) => {
    return (
        <StyledEmployeeWrapper>
            <StyledContentWrapper>
                {cellData.transactions[0].leaveTypeName && (
                    <StyledNameWrapper color={ColorHelper.stringToColour(cellData.transactions[0].leaveTypeName )}>
                        {cellData.transactions[0].leaveTypeName }
                    </StyledNameWrapper>
                )}
                {!cellData.transactions[0].leaveTypeName  && intl.get('view')}
            </StyledContentWrapper>
        </StyledEmployeeWrapper>
    );
});

const LeaveType: React.FC<IProps> = ({
    orders,
    changeOrder,
}) => (
    <Column
        dataKey='leaveTypeName'
        width={250}
        flexGrow={1}
        cellDataGetter={defaultInfiniteTableCellDataGetter}
        headerRenderer={() => (
            <StyledHeaderWrapper>
                {intl.get('leave_type')}
                <StyledActionsWrapper>
                    <InfiniteTableHeader.Order
                       column={'leaveTypeName'}
                       orders={orders}
                       changeOrder={changeOrder}
                    />
                </StyledActionsWrapper>
            </StyledHeaderWrapper>
        )}
        cellRenderer={({cellData}) => (
            <Cell
                cellData={cellData}
            />
        )}
    />
);
export default LeaveType;
