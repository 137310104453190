import React, {PureComponent} from 'react';
import styled from 'styled-components';

import {constants, stylingVariables, intl} from '@global';
import {FormatNumbers, Icon} from '@common';
import {cloneDeep} from 'lodash';

const StyledSummaryBlock = styled.div<{color: string}>`
     flex: 0 1 240px;
     min-width: 240px;
     position: relative;
     margin: 10px;
     padding: 20px 20px 30px 20px;
     border-radius: 5px;
     background: ${stylingVariables.colorPalette.white};
     box-shadow: 0 0 10px 3px rgba(0,0,0,0.05);
     h4{
        font-weight: ${stylingVariables.fontWeight.bold};
        font-size: ${stylingVariables.fontSize.default};
        color: ${stylingVariables.colorPalette.darkest};
        margin-bottom: 30px;
        text-align: center;
     }
     &:after{
        position: absolute;
        content: ' ';
        bottom: 0;
        left: 0;
        right: 0;
        width: auto;
        height: 4px;
        background: ${props => props.color};
        border-radius: 4px;
     }
`;

const StyledAmountTitle = styled.div`
    color: ${stylingVariables.colorPalette.deepGray};
    font-size: ${stylingVariables.fontSize.small};
    font-weight: ${stylingVariables.fontWeight.semibold};
    text-align: center;
    margin: 5px 0;
`;

const StyledAmount = styled.div<{color: string}>`
    color: ${stylingVariables.colorPalette.white};
    background: ${props => props.color};
    font-size: 14px;
    font-weight: bold;
    border-radius: 30px;
    text-align: center;
    margin: 5px 15px 15px 15px;
    padding: 7px 15px;
`;

const StyledDetailsRow = styled.div`
     display: flex;
     justify-content: space-between;
     font-size: ${stylingVariables.fontSize.mediumSmall};
     color: ${stylingVariables.colorPalette.darkest};
     font-weight: bold;
     margin: 7px 0;
     >div{
        max-width: 50%;
     }
`;

const StyledAddButton = styled.div`
     display: flex;
      align-content: center;
      justify-content: center;
      position: absolute;
      right: -10px;
      top: -10px;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: ${stylingVariables.colorPalette.orange};
      box-shadow: ${stylingVariables.layout.boxShadow};
      cursor: pointer;
      border: none;
      outline: none;
      padding: 5px;
`;

interface EmployeeSalarySummaryBlockProps {
    title: string;
    color: string;
    amountTitle: string;
    action?: () => void;
    details: EmployeeSalarySummaryDetail[];
    masterdataEditAllowed: boolean;
}

export interface EmployeeSalarySummaryDetail {
    label: string;
    value: number;
    annualValue: number;
    currency: string;
}

export default class SalarySummaryBlock extends PureComponent<EmployeeSalarySummaryBlockProps> {

    public static defaultProps = {
        color: stylingVariables.colorPalette.gray,
        details: [],
        amountValue: null,
        amountTitle: '',
    };

    get groupedDetails(): EmployeeSalarySummaryDetail[] {
        const details: EmployeeSalarySummaryDetail[] = cloneDeep(this.props.details);

        return details.reduce(
            (grouped: EmployeeSalarySummaryDetail[], current: EmployeeSalarySummaryDetail) => {
                const foundItem = grouped
                    .find((item: EmployeeSalarySummaryDetail) => {
                        return item.label === current.label && item.currency === current.currency;
                    });

                if (foundItem) {
                    foundItem.value += current.value;
                } else {
                    grouped.push(current);
                }
                return grouped;
            }, []);
    }

    get groupedAmounts(): EmployeeSalarySummaryDetail[] {
        const details: EmployeeSalarySummaryDetail[] = cloneDeep(this.props.details);

        return details.reduce(
            (grouped: EmployeeSalarySummaryDetail[], current: EmployeeSalarySummaryDetail) => {
                const foundItem = grouped
                    .find((item: EmployeeSalarySummaryDetail) => item.currency === current.currency);
                
                if (foundItem) {
                    foundItem.annualValue += current.annualValue;
                } else {
                    grouped.push(current);
                }
                return grouped;
            }, []);
    }

    private renderAmountTitle = () => {
        if (this.groupedDetails.length === 0) {
            return <StyledAmountTitle>
                {intl.get('no')} {this.props.title} {intl.get('added_this_month')}
            </StyledAmountTitle>;
        }

        return <StyledAmountTitle>{this.props.amountTitle}</StyledAmountTitle>;
    }

    private renderDetails = () => {
        return this.groupedDetails.map((item: EmployeeSalarySummaryDetail) =>
            (
                <StyledDetailsRow key={item.label + item.value + item.currency}>
                    <div>{item.label}</div>
                    <div>{item.value ? <FormatNumbers value={item.value}/> : 0}{' ' + item.currency}</div>
                </StyledDetailsRow>
            ),
        );
    }

    private renderAmount = () => {
        if (this.groupedDetails.length === 0) {
            return null;
        }

        return this.groupedAmounts.map((item: EmployeeSalarySummaryDetail) =>
            (
                <StyledAmount key={item.annualValue + item.currency} color={this.props.color}>
                    {item.annualValue ? <FormatNumbers value={item.annualValue}/> : 0}{' ' + item.currency}
                </StyledAmount>
            ),
        );
    }

    private renderActions = () => {
        const action = this.props.action;

        if (!action) {
            return null;
        }

        return (
            <StyledAddButton onClick={this.props.action}>
                <Icon type={constants.IconTypes.BOLD_ADD}
                      width={10}
                      height={10}
                      fillColor={stylingVariables.colorPalette.white} />
            </StyledAddButton>
        );
    }

    private renderTitle = () => {
        return this.props.title
            ? <h4>{this.props.title}</h4>
            : null;
    }

    public render() {
        return (
            <StyledSummaryBlock color={this.props.color}>
                {this.props.masterdataEditAllowed && this.renderActions()}
                {this.renderTitle()}
                {this.renderAmountTitle()}
                {this.renderAmount()}
                {this.renderDetails()}
            </StyledSummaryBlock>
        );
    }
}
