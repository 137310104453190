import React, {PureComponent} from "react";
import styled from 'styled-components';
import {stylingVariables} from '@global';

interface AlphabetListProps {
    letter: string,
    items: any[],
    itemRenderer: (item: any) => any
}

const StyledGroupWrapper = styled.div`
  display: flex; 
  border-bottom: 1px solid ${stylingVariables.colorPalette.darkGray};
`;

const StyledGroupLetter = styled.div`
  width: 80px;
  min-width: 80px;
  max-width: 80px;
  font-weight: 400;
  font-size: ${stylingVariables.fontSize.large}; 
  margin-top: 10px;
  text-align: center;
  color: ${stylingVariables.colorPalette.deepGray}; 
`;

const StyledGroupItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  
  button {
    margin: 10px 0;
    font-size: ${stylingVariables.fontSize.mediumLarge};
    font-weight: ${stylingVariables.fontWeight.semibold};
    color: ${stylingVariables.colorPalette.dark};
    background-color: transparent;
    border: none;
    text-align: left;
    cursor: pointer; 
    padding: 0; 
  }
`;

export default class Group extends PureComponent<AlphabetListProps> {
    render() {
        return (
            <StyledGroupWrapper>
                <StyledGroupLetter>{this.props.letter}</StyledGroupLetter>

                <StyledGroupItemsWrapper>
                    {this.props.items.map(this.props.itemRenderer)}
                </StyledGroupItemsWrapper>
            </StyledGroupWrapper>
        );
    }
}
