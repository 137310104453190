import {AnyAction} from 'redux';
import moment from 'moment';

import {LeaveInterfaces} from '@api';

import {SET_PERIOD_FILTER} from '../redux';

export const VIEW_EMPLOYEE_LEAVES = 'employeeLeaves/viewEmployeeLeaves';
export const SET_PERIOD = 'employeeLeaves/setPeriod';
export const SET_LEAVES = 'employeeLeaves/setHistoricalLeaves';
export const CLOSE_MODAL = 'employeeLeaves/closeModal';

export interface EmployeeLeavesState {
    employeeId: number | null;
    isModalOpen: boolean;

    from: string;
    till: string;
    leaves: LeaveInterfaces.EmployeeLeaves;
}

export const defaultState: EmployeeLeavesState = {
    employeeId: null,
    isModalOpen: false,

    from: moment().startOf('month').format(),
    till: moment().endOf('month').format(),
    leaves: {
        balances: {},
        transactions: [],
    },
};

export default function reducer(
    state: EmployeeLeavesState = defaultState,
    action: AnyAction,
): EmployeeLeavesState {
    switch (action.type) {
        case SET_PERIOD_FILTER:
        case SET_PERIOD:
            return {
                ...state,
                from: action.payload.from,
                till: action.payload.till,
            };
        case SET_LEAVES:
            return {
                ...state,
                leaves: action.payload,
            };
        case VIEW_EMPLOYEE_LEAVES:
            return {
                ...state,
                employeeId: action.payload,
                isModalOpen: true,
            };
        case CLOSE_MODAL:
            return {
                ...state,
                employeeId: null,
                isModalOpen: false,
                leaves: {
                    balances: {},
                    transactions: [],
                },
            };
        default:
            return state;
    }
}

export function setLeaves(leaves: LeaveInterfaces.EmployeeLeaves) {
    return {
        type: SET_LEAVES,
        payload: leaves,
    };
}
