export default class DomHelper {
    public static hasSomeParentTheClass(element: any, className: string): boolean {
        if (
            !!element.className
            && typeof element.className === 'string'
            && element.className.split(' ').indexOf(className) >= 0
        ) {
            return true;
        }

        return !!element.parentNode && DomHelper.hasSomeParentTheClass(element.parentNode, className);
    }
}
