import React, {PureComponent} from 'react';
import styled from 'styled-components';

import {stylingVariables, constants} from '@global';
import {Icon} from '@common';

const sizePx = (size?: string|number, def?: string):string|null|undefined => {
    return size ? (`${size}`.includes('px') ? `${size}` : `${size}px`) : def;
};

const StyledButton = styled.button<{color?: string, size?: string|number, fontSize?: string|number, isDisabled?: boolean}>`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props => props.color || stylingVariables.colorPalette.orange};
  font-size: ${props => sizePx(props.fontSize, stylingVariables.fontSize.large)};
  font-weight: ${stylingVariables.fontWeight.semibold};
  border: none;
  box-shadow: none;
  background-color: transparent;
  position: relative;
  cursor: pointer;
  
  ${props => props.isDisabled && `
    pointer-events: none;
    cursor: default;
    opacity: .7;
  `}

  &:hover {
    color: ${stylingVariables.colorPalette.darkOrange}
  }
`;

const Circle = styled.div<{color?: string, size?: string|number, fontSize?: string|number, shadow?: boolean}>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
  width: ${props => sizePx(props.size, '40px')};
  height: ${props => sizePx(props.size, '40px')};
  min-width: ${props => sizePx(props.size, '40px')};
  min-height: ${props => sizePx(props.size, '40px')};
  background-color: ${props => props.color || stylingVariables.colorPalette.orange};
  border-radius: 50%;
  color: ${stylingVariables.colorPalette.white};

  &:hover {
    background-color: ${stylingVariables.colorPalette.darkOrange}
  }
`;

interface PlusActionProps {
    children?: any;
    onClick?: () => void;
    color?: string;
    size?: string|number;
    iconSize?: number;
    fontSize?: number|string;
    shadow?: boolean;
    isDisabled?: boolean;
}

export default class PlusAction extends PureComponent<PlusActionProps> {
    public render() {
        return (
            <StyledButton {...this.props}>
                <Circle {...this.props}>
                    <Icon type={constants.IconTypes.BOLD_ADD}
                          fillColor={stylingVariables.colorPalette.white}
                          width={this.props.iconSize || (parseInt(sizePx(this.props.fontSize, '20px') || '20'))}
                          height={this.props.iconSize || (parseInt(sizePx(this.props.fontSize, '20px') || '20'))}
                    />
                </Circle>

                {this.props.children}
            </StyledButton>
        );
    }
}
