import React, {PureComponent} from 'react';
import styled from 'styled-components';
import {Column} from 'react-virtualized';
import {stylingVariables} from '@global';

import {defaultInfiniteTableCellDataGetter} from '@common';
import {intl} from '@global';
import LeaveSourceHelper from '../../../../common/Leave/source.helper';
import moment from 'moment';

const StyledTransactionRow = styled.div`
  display: flex;
  align-items: center;
  span {
    font-weight: ${stylingVariables.fontWeight.regular};
  }
`;

const StyledTypeWrapper = styled.div<{color: string}>`
  background: ${stylingVariables.colorPalette.gray};
  font-size: ${stylingVariables.fontSize.medium};
  color: ${props => props.color};
  border-radius: 4px;
  padding: 4px 8px;
`;

const InformationTabWrapper = styled.div`
  position: relative;
`;

const InformationTab = styled.span<{colourStyles: any}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  background: ${props => props.colourStyles.color};
  min-width: 180px;
  min-height: 50px;
  border: 1px solid ${stylingVariables.colorPalette.lightGray};
  box-shadow: 0 0 10px 1 ${stylingVariables.colorPalette.darkGray};
  color: ${props => props.colourStyles.text};

  transform: translateY(-50%);
  left: 8px;

  position: absolute;
  z-index: 5;
  border-radius: 5px;
  padding: 13px 18px;
  vertical-align: top;

  :after {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-right-color: ${props => props.colourStyles.color};
    border-left: 0;
    margin-top: -10px;
    margin-left: -10px;
  }
`;

const Dot = styled.div<{colourStyles: any}>`
  width: 8px;
  height: 8px;
  margin: 8px;
  border-radius: 50%;
  background-color:  ${props => props.colourStyles.color};
  pointer-events: none;
`;

const HoverDot = styled.div`
  margin: auto 0 auto 20px;
  height: 24px;
  width: 24px;
  border-radius: 50%;

  :hover {
    background-color: ${stylingVariables.colorPalette.gray};
  }
`;

interface Props {
  cellData?: any;
}

interface IState {
  infoVisible: boolean;
  creatorInfoVisible: boolean;
}

class Cell extends PureComponent<Props, IState> {
  public constructor(props: Props) {
      super(props);

      this.state = {
          infoVisible: false,
          creatorInfoVisible: false,
      };
  }

  private toggleAdditionalInfo = (e: any) => {
      this.setState({infoVisible: !this.state.infoVisible});
  }

  private toggleCreatorInfo = (e: any) => {
      this.setState({creatorInfoVisible: !this.state.creatorInfoVisible});
  }

  public render() {

      const creatorColor = {
          color: stylingVariables.colorPalette.gray,
          text: this.props.cellData.transactions[0].source === 'ess'
              ? stylingVariables.colorPalette.green
              : stylingVariables.colorPalette.violet,
      };
      const createDate = moment(this.props.cellData.transactions[0].createTime, 'YYYY-MM-DD HH:mm:ss')
          .format('MMM DD, YYYY');
      const creatorEmployeeName = `${this.props.cellData.transactions[0].creatorEmployee.firstName} ${this.props.cellData.transactions[0].creatorEmployee.lastName}`;

      return (
        <div style={{width: '100%'}}>
        <StyledTransactionRow key={this.props.cellData.id} >
            <StyledTypeWrapper
                color={creatorColor.text}
                onMouseOver={this.toggleCreatorInfo}
                onMouseLeave={this.toggleCreatorInfo}
            >
              {this.props.cellData.transactions[0].source === 'ess' ? 'REQ' : 'DT'}
            </StyledTypeWrapper>
            {this.state.creatorInfoVisible ?
                <InformationTabWrapper>
                    <InformationTab colourStyles={creatorColor}>
                        {createDate} {intl.get('by')} {creatorEmployeeName}
                    </InformationTab>
                </InformationTabWrapper>
                : null
            }
            <HoverDot
              onMouseOver={this.toggleAdditionalInfo}
              onMouseLeave={this.toggleAdditionalInfo}
            >
              <Dot colourStyles={LeaveSourceHelper.getTransactionStatusColour(
                  this.props.cellData.transactions[0].status)
              }/>
            </HoverDot>
            {this.state.infoVisible ?
                <InformationTabWrapper>
                  <InformationTab colourStyles={
                      LeaveSourceHelper.getTransactionStatusColour(this.props.cellData.transactions[0].status)
                  }>
                    {intl.get(this.props.cellData.transactions[0].status)}
                    &nbsp;
                    {intl.get('transaction')}
                  </InformationTab>
                </InformationTabWrapper>
              : null
            }
        </StyledTransactionRow>
    </div>
      );
  }
}

const StyledHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const TransactionType: React.FC = () => (
    <Column
        dataKey={'source'}
        width={350}
        flexGrow={1}
        cellDataGetter={defaultInfiniteTableCellDataGetter}
        headerRenderer={() => (
            <StyledHeaderWrapper>
                {intl.get('source')}
            </StyledHeaderWrapper>
        )}
        cellRenderer={({cellData}) => <Cell cellData={cellData}/>}
    />
);

export default TransactionType;
