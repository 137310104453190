import {ApiObject} from '@api';
import {Modal as CommonModal, Layout, Dropdown, Checkbox} from '@common';
import {interfaces, constants, stylingVariables} from '@global';
import {RouteHelper} from '@library';
import React, {PureComponent} from 'react';
import {intl} from '@global';
import {connect} from 'react-redux';
import styled from 'styled-components';
import Validator from 'validatorjs';
import {RootState} from '../../../../../../rootReducer'

import * as reportsSelectors from '../../../../selectors';
import {setMode as setProcessMode, changeReport} from '../../../redux';
import {GroupedQueriesBuilder,} from '../../SelectEmployees/groupedQueries.builder';
import DateRangeRow from '../../DateRangeRow';
import EmployeeRow from '../../EmployeeRow';
import {canRegenerateMasterdata, getLegalEntityPeriods} from '../../../../utils'

const ActionBar = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 30px 85px 50px 0;
    
    button {
        margin-left: 30px;
    }
`;

const StyledCheckbox = styled.div`
  display: flex;
  align-items: center;
  user-select: none;
  min-height: 20px;
`;

const StyledLabel = styled.div`
  display: inline-flex;
  align-items: center;
  position: relative;
  margin-left: 10px;
  font-size: ${stylingVariables.fontSize.default};
  font-weight: ${stylingVariables.fontWeight.regular};
  
  >div {
    margin: 0 0 0 10px;
  }
`

interface IProps {
    template: ApiObject.ReportTemplate;
    periods: interfaces.Periods;

    onClose: () => void;
    onContinue: () => void;

    changeTemplate: (template: ApiObject.ReportTemplate) => any;
    changePeriods: (periods: interfaces.Periods) => any;

    setProcessMode: (mode: constants.ReportProcessMode) => any;
    legalEntityPeriods: ApiObject.LegalEntityPeriod[];
    changeReport: (report: ApiObject.Report) => any;
}

interface IState {
    template: ApiObject.ReportTemplate;
    periods: interfaces.Periods,

    legalEntityReportType: ApiObject.DataSource;
    allActiveEmployees: boolean;
    allInactiveEmployees: boolean;
    allDraftEmployees: boolean;

    isEmployeesSelected: boolean,
    regenerateMasterdata: boolean,
    showCurrencyForAmounts: boolean,

    errors: {
        [key: string]: string[],
    };
}

export class SelectDataContent extends PureComponent<IProps, IState> {
    public constructor(props: IProps) {
        super(props);

        this.state = {
            template: props.template,
            periods: props.periods,
            legalEntityReportType: ApiObject.DataSource.payroll,
            allActiveEmployees: false,
            allInactiveEmployees: false,
            allDraftEmployees: false,

            isEmployeesSelected: false,
            regenerateMasterdata: false,
            showCurrencyForAmounts: false,

            errors: {}
        };
    }

    private readonly FIELD_DATE_RANGE = 'periods';
    private readonly FIELD_EMPLOYEES = 'isEmployeesSelected';

    private readonly baseValidationRules = {
        [this.FIELD_DATE_RANGE]: 'required',
    }

    private readonly validationRules = {
        ...this.baseValidationRules,
        [this.FIELD_EMPLOYEES]: 'not_in:false'
    };

    private readonly validationMessages = {
        required: 'Field can not be empty',
    };

    static getDerivedStateFromProps(nextProps: IProps, prevState: IState) {
        if (
          nextProps.template &&
          prevState.template &&
          nextProps.template.visibility !== prevState.template.visibility
        ) {
            return {
                template: {
                    ...prevState.template,
                    visibility: nextProps.template.visibility
                }
            }
        }

        return null;
    }

    componentDidMount() {
        const {template} = this.props;

        const queries = (template as ApiObject.ReportTemplate).employees ? (template as ApiObject.ReportTemplate).employees.queries : [];
        const groupedQueriesBuilder = GroupedQueriesBuilder.build(queries);

        this.setState({
            allActiveEmployees: groupedQueriesBuilder.isSelectedAll(ApiObject.Status.active),
            allInactiveEmployees: groupedQueriesBuilder.isSelectedAll(ApiObject.Status.inactive),
            allDraftEmployees: groupedQueriesBuilder.isSelectedAll(ApiObject.Status.draft),

        }, () => {
            this.setState({
                isEmployeesSelected: this.state.allActiveEmployees || this.state.allInactiveEmployees || this.state.allDraftEmployees
            })
        })
    }

    get legalEntityPeriods() {
        return this.state.legalEntityReportType === ApiObject.DataSource.payroll ?
            this.props.legalEntityPeriods.filter(period => period.periodStatus === 'done') :
            this.props.legalEntityPeriods.filter(period => period.periodStatus !== 'scheduled')
    }

    get selectedLegalEntityPeriods() {
        return getLegalEntityPeriods(this.state.periods, this.props.legalEntityPeriods)
    }

    get canRegenerateMasterdata() {
        return canRegenerateMasterdata(this.state.legalEntityReportType, this.selectedLegalEntityPeriods)
    }

    render() {
        return (
            <>
                <CommonModal.OverflowableContent style={{padding: '20px 80px'}}>
                    <Layout.Rows>
                        <Layout.Row>
                            <Layout.RowLabel percent={30}>
                                {intl.get('data_source')} *
                            </Layout.RowLabel>

                            <Layout.RowValue percent={70}>
                                <Dropdown
                                    selectedValue={ApiObject.DataSource.payroll}
                                    list={[
                                        {label: intl.get('payroll_calculation'), key: ApiObject.DataSource.payroll},
                                        {label: intl.get('employee_record'), key: ApiObject.DataSource.employee},
                                    ]}
                                    onChange={this.onChangeDataSource}
                                />
                            </Layout.RowValue>
                        </Layout.Row>
                        <DateRangeRow
                            allowPeriods={this.legalEntityPeriods}
                            periods={this.state.periods}
                            onChange={this.onChangePeriods}
                            errors={this.state.errors[this.FIELD_DATE_RANGE]}
                            multiselect={false}
                        />

                        <Layout.Row>
                            <Layout.RowLabel percent={30}>

                            </Layout.RowLabel>

                            <Layout.RowValue percent={70}>
                                {this.canRegenerateMasterdata &&
                                <StyledCheckbox>
                                    <Checkbox
                                        isChecked={this.state.regenerateMasterdata}
                                        onClick={this.onChangeRegenerateMasterdata}
                                    />
                                    <StyledLabel>
                                        {intl.get('regenerate_masterdata')}
                                    </StyledLabel>
                                </StyledCheckbox>}
                            </Layout.RowValue>
                        </Layout.Row>

                        <Layout.Row>
                            <Layout.RowLabel percent={30}>
                                {intl.get('currency')}
                            </Layout.RowLabel>

                            <Layout.RowValue percent={70}>
                                <StyledCheckbox>
                                    <Checkbox
                                      isChecked={this.state.showCurrencyForAmounts}
                                      onClick={() =>
                                        this.setState({showCurrencyForAmounts: !this.state.showCurrencyForAmounts})}
                                    />
                                    <StyledLabel>
                                        {intl.get('show_currency')}
                                    </StyledLabel>
                                </StyledCheckbox>
                            </Layout.RowValue>
                        </Layout.Row>

                        <EmployeeRow
                            template={this.state.template}
                            allActiveEmployees={this.state.allActiveEmployees}
                            allInactiveEmployees={this.state.allInactiveEmployees}
                            allDraftEmployees={this.state.allDraftEmployees}
                            onClickCheckbox={this.toggleEmployees}
                            onManualSelect={this.onManualSelect}
                            errors={this.state.errors[this.FIELD_EMPLOYEES]}
                            isDraftAllowed={this.state.legalEntityReportType !== ApiObject.DataSource.payroll}
                        />
                    </Layout.Rows>
                </CommonModal.OverflowableContent>

                <ActionBar>
                    <CommonModal.StyledCancelButton onClick={this.props.onClose}>
                        {intl.get('cancel')}
                    </CommonModal.StyledCancelButton>

                    <CommonModal.StyledOkButton onClick={this.onContinue}>
                        {intl.get('continue')}
                    </CommonModal.StyledOkButton>

                </ActionBar>
            </>
        );
    }

    private isValid = (rules: any = null) => {
        const validator = new Validator(
            this.state,
            rules ? rules : this.baseValidationRules,
            this.validationMessages
        );
        validator.check();
        const isOk = validator.passes();
        if (isOk) {
            this.setState({errors: {}});
        } else {
            this.setState({errors: validator.errors.errors});
        }

        return isOk;
    }

    private updateTemplateFromState = (): ApiObject.ReportTemplate|null => {
        if (!this.state.template) {
            return null;
        }


        const queries = (this.state.template as ApiObject.ReportTemplate).employees ? (this.state.template as ApiObject.ReportTemplate).employees.queries : [];
        const groupedQueriesBuilder = GroupedQueriesBuilder.build(queries);

        groupedQueriesBuilder.selectAll(this.state.allActiveEmployees, ApiObject.Status.active);
        groupedQueriesBuilder.selectAll(this.state.allInactiveEmployees, ApiObject.Status.inactive);
        groupedQueriesBuilder.selectAll(this.state.allDraftEmployees, ApiObject.Status.draft)

        const updatedTemplate: ApiObject.ReportTemplate = {
            ...this.state.template,
            employees: {
                ...(this.state.template as ApiObject.ReportTemplate).employees,
                queries: groupedQueriesBuilder.queries()
            }
        }

        return updatedTemplate;
    }

    private save = async () => {
        const template: ApiObject.ReportTemplate|null = this.updateTemplateFromState();
        if (!template) {
            return null;
        }
        await this.props.changeReport({
            id: -1,
            label: '',
            employeeIds: [],
            ownerEmployee: {
                firstName: null,
                id: null,
                lastName: null,
                referenceCode: null
            },
            status: ApiObject.ReportStatus.draft,
            legalEntityReportType: this.state.legalEntityReportType,

            legalEntityPeriodIds: [],
            visibility: template.visibility,
            isTemporary: false,
            templateId: template.id,
            regenerateMasterdata: this.state.regenerateMasterdata && this.canRegenerateMasterdata,
            showCurrencyForAmounts: this.state.showCurrencyForAmounts,
        })
        this.props.changeTemplate(template);
        this.props.changePeriods(this.state.periods as interfaces.Periods);
    }

    private onManualSelect = async () => {
        if (this.isValid()) {
            await this.save();
            this.props.setProcessMode(constants.ReportProcessMode.generateReport);
            RouteHelper.goToReportSelectEmployees();
        }
    }

    private onContinue = async () => {
        if (this.isValid(this.validationRules)) {
            await this.save();
            this.props.onContinue();
        }
    }

    private onChangeDataSource = (legalEntityReportType: ApiObject.DataSource) => {
        if (legalEntityReportType === ApiObject.DataSource.payroll){
            this.setState({allDraftEmployees: false})
            const isPeriodsValid = this.state.periods.every(period =>
              this.props.legalEntityPeriods
                .filter(period => period.periodStatus === 'done')
                .find(legalEntityPeriod =>
                  legalEntityPeriod.beginDate === period.beginDate && legalEntityPeriod.endDate === period.endDate
                )
            )

            if(!isPeriodsValid){
                this.setState({periods: []});
            }
        }
        this.setState({legalEntityReportType});
    };

    private onChangePeriods = (periods: interfaces.Periods) => this.setState({periods});
    private onChangeRegenerateMasterdata = () => this.setState({regenerateMasterdata: !this.state.regenerateMasterdata});

    private toggleEmployees = (key: string) => {
        this.setState({...this.state, [key as keyof IState]: !this.state[key as keyof IState]}, () => {
            const template = this.updateTemplateFromState() as ApiObject.ReportTemplate;
            this.setState({
                template,
                isEmployeesSelected: this.state.allActiveEmployees || this.state.allInactiveEmployees || this.state.allDraftEmployees
            })
        });
    }
}

const mapStateToProps = (state: RootState) => ({
    legalEntityPeriods: reportsSelectors.getLegalEntityPeriods(state),
});

const mapDispatchToProps = {
    setProcessMode,
    changeReport
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectDataContent);
