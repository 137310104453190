import {ApiObject} from '@api';
import {interfaces} from '@global';
import React from 'react';
import {Column} from 'react-virtualized';

import {defaultInfiniteTableCellDataGetter, InfiniteTableHeader} from '@common';
import {cellRendererCreator} from '../functions';

interface ColumnProps {
    dataKey: string;
    label: string;
    width?: number;
    flexGrow?: number;
    className?: string;
    type?: string;

    options?: ApiObject.SelectOption[];
    filters?: interfaces.ListingFilters;
    changeFilter?: (params: interfaces.FilterParams) => any;
}

const Filtered: React.FC<ColumnProps> = ({
    className,
    dataKey,
    label,
    width = 150,
    flexGrow = 1,
    options = [],
    filters,
    changeFilter,
    type = ApiObject.FieldType.text,
}: ColumnProps) => (
    <Column
        className={className}
        dataKey={dataKey}
        width={width}
        flexGrow={flexGrow}
        cellDataGetter={defaultInfiniteTableCellDataGetter}
        headerRenderer={() => <InfiniteTableHeader.Filtered
            label={label}
            type={type}
            dataKey={dataKey}
            options={options}
            filters={filters}
            changeFilter={changeFilter}
        />}
        cellRenderer={cellRendererCreator(type, dataKey, options)}
    />
);

export default Filtered;
