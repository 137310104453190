import {constants} from '@global';
import React, {PureComponent, Fragment} from 'react';
import {intl} from '@global';

import {Actions, Can, Modal} from '@common';

interface IProps {
    forEmployeeIds: Set<number>;
    isLoading: boolean;
    cuttOffs?: Object|null;
    onClickSuspend: (ids: Set<number>) => void;
}

interface IState {
    isOpenConfirmationModal: boolean;
}

export default class Suspend extends PureComponent<IProps, IState> {
    public constructor(props: any) {
        super(props);

        this.state = {
            isOpenConfirmationModal: false,
        };
    }

    render() {
        return (
            <Fragment>
                <Can cuttOffs={this.props.cuttOffs} run={constants.PermissionCode.EMPLOYEES_ACTION_SUSPEND}>
                    <Actions.Action onClick={this.openConfirmationModal} isDisabled={this.props.isLoading} >
                        {intl.get('suspend')}
                    </Actions.Action>
                </Can>

                <Modal.ConfirmationModal
                    title={'suspend'}
                    confirmationMessage={'suspend_confirmation'}
                    acceptBtnTitle={'suspend'}
                    isOpen={this.state.isOpenConfirmationModal}
                    onClickAccept={this.suspend}
                    onClickClose={this.closeConfirmationModal}
                />
            </Fragment>
        );
    }

    private suspend = () => {
        this.closeConfirmationModal();

        this.props.onClickSuspend(this.props.forEmployeeIds);
    }

    private openConfirmationModal = () => {
        this.setState({isOpenConfirmationModal: true});
    }

    private closeConfirmationModal = () => {
        this.setState({isOpenConfirmationModal: false});
    }
}
