import React, {PureComponent} from 'react';
import moment, {Moment} from 'moment';
import styled from 'styled-components';

import {constants, stylingVariables} from '@global';
import {Icon, DatePicker} from '@common';

const StyledArrow = styled.span`
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    float: right;
    right: 0;
    top: 0;
    bottom: 0;
    overflow: hidden;
    height: auto;
    padding: 11px 12px;
    border-radius: 0 ${stylingVariables.layout.inputBorderRadius} ${stylingVariables.layout.inputBorderRadius} 0;
    background: ${stylingVariables.colorPalette.gray};
    cursor: pointer;
`;

const StyledDatePickerField = styled.div<{ hasError: boolean }>`
    border: 1px solid ${stylingVariables.colorPalette.darkestGray};
    ${props => props.hasError && `
        border: 1px solid ${stylingVariables.colorPalette.red};
    `};
    border-radius: ${stylingVariables.layout.inputBorderRadius};
    min-width: 320px;
    font-weight: bold;
    position: relative;
    display: inline-block;
    width: 100%;

    &:hover {
      border: 1px solid ${stylingVariables.colorPalette.green};
    }

    input{
      width: 100%;
      height: 100%;
      border: none;
      padding: 0 20px;
      font-size: ${stylingVariables.fontSize.mediumLarge};
      font-weight: ${stylingVariables.fontWeight.semibold};
      color: ${stylingVariables.colorPalette.dark};
    }
`;

interface IProps {
    value?: string;
    onChange: (value: string) => void;
    hasError?: boolean;
    defaultValue?: Date | string | Moment;
    disabled: boolean;
}

interface IState {
    value?: Moment;
    isOpen: boolean;
}

export default class DateField extends PureComponent<IProps, IState> {
    public static defaultProps = {
        disabled: false,
    };

    private wrapperRef: HTMLDivElement | null = null;

    public constructor(props: IProps) {
        super(props);

        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);

        this.state = {
            isOpen: false,
            value: this.props.value
                ? moment(this.props.value, constants.SYSTEM_DATE_FORMAT)
                : undefined,
        };
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    public render() {
        if (this.props.disabled) {
            return this.renderDisabledField();
        }

        return (
            <StyledDatePickerField hasError={!!this.props.hasError} ref={this.setWrapperRef}>
                <DatePicker
                    selected={this.state.value}
                    onChange={this.onChange}
                    onFocus={this.toggle}
                    onBlur={this.onBlur}
                    defaultValue={this.props.defaultValue}
                    isOpen={this.state.isOpen}
                />

                <StyledArrow onClick={() => {this.toggle();}}>
                    <Icon type={constants.IconTypes.CALENDAR}
                          fillColor={stylingVariables.colorPalette.dark}
                          height={15}
                          width={15}
                    />
                </StyledArrow>
            </StyledDatePickerField>
        );
    }

    private renderDisabledField() {
        const { value } = this.state;
        return (
            <input
                type={'text'}
                defaultValue={value ? value.format(constants.SYSTEM_DATE_FORMAT) : ''}
                disabled={true}
            />);
    }

    private onChange = (value: Moment|string) => {
        this.setState({
            value: typeof value === 'string'
                ? moment(this.props.value, constants.SYSTEM_DATE_FORMAT)
                : value,
        });

        this.props.onChange(moment(value).format(constants.SYSTEM_DATE_FORMAT));
    }

    private onBlur = (momemt: Moment) => {
        this.setState({isOpen: false});
    }

    private toggle = () => {
        this.setState({isOpen: !this.state.isOpen});
    }

    private setWrapperRef(node: HTMLDivElement | null) {
        this.wrapperRef = node;
    }

    private handleClickOutside = (event: any) => {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.setState({isOpen: false});
        }
    }
}
