import React from 'react';
import {Column} from 'react-virtualized';
import styled from 'styled-components';

import {ApiObject} from '@api';
import {defaultInfiniteTableCellDataGetter, InfiniteTableHeader} from '@common';
import {interfaces, intl} from '@global';

const StyledEmployeeWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  svg {
    flex-shrink: 0;
  }
`;

interface IProps {
    label: string;
    dataKey: string;
    orders?: interfaces.ListingOrders;
    changeOrder?: (column: string) => any;
    width?: number;
}

const Visibility: React.FC<IProps> = ({label, dataKey, orders, changeOrder}: IProps) => (
    <Column
        dataKey={dataKey}
        width={100}
        flexGrow={0}
        cellDataGetter={defaultInfiniteTableCellDataGetter}
        headerRenderer={() => <InfiniteTableHeader.Ordered
            label={label}
            dataKey={dataKey}
            orders={orders}
            changeOrder={changeOrder}
        />}
        cellRenderer={({cellData}: {cellData?: ApiObject.FileHandle}) => {
            if (!cellData) {
                return null;
            }
            return (
                <StyledEmployeeWrapper>
                    {cellData.visibility === 'mss' ? intl.get('no') : intl.get('yes')}
                </StyledEmployeeWrapper>
            );
        }}
    />
);

export default Visibility;
