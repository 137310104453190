import {ApiObject} from '@api';
import React, {PureComponent} from 'react';
import {connect} from 'react-redux';

import { saveFields, clearErrorFields } from "../../../redux";

import {RootState} from '../../../../../rootReducer';
import * as selectors from '../../../selectors';

import View from '../View/View';
import Edit from '../Edit/Edit';

interface IProps {
    employeeId: number;
    fields: ApiObject.Fields;
    fieldGroup: ApiObject.FieldGroup | null;
    clearErrorFields: () => void;
    saveFields: (id: number, updatedFields: ApiObject.FieldEntity[]) => Promise<boolean>;
    isEdit: boolean;
}

interface IState {
    isEdit: boolean;
}

class Default extends PureComponent<IProps, IState> {
    static defaultProps = {
        isEdit: false
    }

    public constructor(props: IProps) {
        super(props);

        this.state = {
            isEdit: props.isEdit,
        };
    }

    public render() {
        if (!this.props.fieldGroup) {
            return null;
        }

        if (this.state.isEdit) {
            return (
                <Edit fieldGroup={this.props.fieldGroup}
                      fields={this.props.fields}

                      onClickSave={this.save}
                      onClickCancel={this.disableEditMode}
                />
            );
        }

        return (
            <View
                fieldGroup={this.props.fieldGroup}
                fields={this.props.fields}

                onClickEditButton={this.enableEditMode}
            />
        );
    }

    private disableEditMode = () => {
        this.props.clearErrorFields();
        this.setState({isEdit: false});
    }

    private enableEditMode = () => {
        this.props.clearErrorFields();
        this.setState({isEdit: true});
    }

    private save = (fields: ApiObject.FieldEntity[]): Promise<boolean> => {
        return this.props.saveFields(this.props.employeeId, fields).then((result) => {
            if(result){
                this.disableEditMode();
            }
            return result;
        });
    }
}

const mapStateToProps = (state: RootState) => ({
    employeeId: selectors.getCurrentEmployeeId(state),
    fields: selectors.getFields(state),
});

const mapDispatchToProps = {
  saveFields,
  clearErrorFields,
};

export default connect(mapStateToProps, mapDispatchToProps)(Default);
