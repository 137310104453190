const polish = {
  mercans: 'Mercans',
  term_of_use: 'Warunki użytkowania',
  sla: 'SLA',
  privacy_policy: 'Polityka prywatności',
  admin_console: 'Konsola administratora',
  secure_file_transfer: 'Bezpieczne przesyłanie plików',
  payslips: 'Moje paski wypłaty',
  my_information: 'Moje informacje',
  my_team: 'Mój zespół',
  my_apps: 'Moje aplikacje',
  apply_for_leave: 'Złóż wniosek o urlop',
  add_leave_transaction: 'Dodaj działanie związane z urlopem',
  add_and_approve: 'Dodaj i zatwierdź',
  cannot_approve_payroll_processing: 'Lista płac jest przetwarzana, wnioski nie są w tym momencie zatwierdzane',
  enter_my_time_and_attendance_data: 'Wprowadź moje dane dotyczące czasu i obecności',
  help_and_support: 'Pomoc i wsparcie',
  select_entity: 'Wybierz podmiot do analizy',
  back_to_launchpad: 'Powrót do panelu głównego',
  workflow: 'Przepływ pracy',
  employees: 'Pracownicy',
  no_employees_found: 'Nie znaleziono pracowników',
  no_leaves_found: 'Obecnie nie ma żadnego urlopu',
  no_transactions: 'Obecnie nie ma żadnego działania związanego z urlopem',
  name: 'Imię i nazwisko',
  pay_unit: 'Jednostka płatności',
  amount: 'Kwota',
  currency: 'Waluta',
  no_details_found: 'Brak szczegółów',
  ess_access: 'Dostęp do ESS ',
  add_or_remove_columns: 'Dodaj lub usuń kolumny',
  columns_selected: 'Wybrano kolumny',
  unselected_columns: 'Niewybrane kolumny',
  filter_by: 'Filtruj według',
  apply: 'Zastosuj',
  reset: 'Wyzeruj',
  filter_by_employee_header: 'Filtruj według pracownika i ID',
  filter_by_employee: 'Filtruj według pracownika',
  filter_by_tags: 'Filtruj według tagów',
  employee: 'Pracownik',
  add_employee: 'Dodaj pracownika',
  add_employee_s: 'Dodaj pracownika/pracowników',
  save_and_next: 'Zapisz i przejdź dalej',
  save_and_add: 'Zapisz i dodaj',
  import_employee_data_from_excel: 'Importuj dane pracownika z programu Excel',
  employee_separated: 'Podział pracowników',
  advanced_settings: 'Ustawienia zaawansowane',
  separation_no_pay_elements: 'Brak ustawionych elementów wynagrodzenia po zakończeniu stosunku pracy',
  view_saved_separations: 'Zobacz zapisany podział pracowników',
  insert_separation: 'Wstaw podział',
  send_ess_invitation: 'Wyślij zaproszenie ESS',
  send_ess_invitation_success: 'Zaproszenie ESS zostało wysłane',
  send_ess_invitation_failed: 'Nie udało się wysłać zaproszenia ESS...',
  revoke: 'Cofnij',
  revoke_ess_access: 'Cofnij dostęp do ESS',
  revoke_ess_access_confirmation: 'Czy na pewno chcesz cofnąć dostęp do ESS?',
  revoke_ess_success: 'Cofnięto dostęp do ESS',
  insert_vacation: 'Wstaw urlop wypoczynkowy',
  insert_expense: 'Wstaw wydatek',
  insert_one_time_payment: 'Wstaw płatność jednorazową',
  insert_absence: 'Wstaw nieobecność',
  reason: 'Uzasadnienie',
  annual_total: 'Roczna suma',
  before: 'Przed',
  after: 'Po',
  payrolls: 'Listy płac',
  payroll: 'Lista płac',
  new_payroll: 'Nowa lista płac',
  current: 'Bieżące',
  previous: 'Poprzednie',
  open: 'Otwarte',
  input: 'Dane wejściowe',
  preinput: 'Poprawianie danych wejściowych',
  processing: 'Przetwarzanie',
  authorization_form: 'Formularz autoryzacyjny',
  calculated: 'Obliczone',
  calculating: 'Obliczanie',
  cancelled: 'Anulowano',
  cancelling: 'Anulowanie',
  close: 'Zamknij',
  closed: 'Zamknięte',
  closing: 'Zamykanie',
  generating: 'Generowanie',
  invalid: 'Nieprawidłowy',
  add_terminations: 'Dodaj zakończenia stosunku pracy',
  pending_termination: 'Oczekujące na zakończenie stosunku pracy',
  starters: 'Osoby rozpoczynające współpracę ',
  starter: 'Osoba rozpoczynająca współpracę',
  leavers: 'Osoby kończące współpracę ',
  leaver: 'Osoba kończąca współpracę ',
  add_new_hire: 'Dodaj nowo zatrudnionego pracownika',
  hire_date: 'Data zatrudnienia',
  last_working_date: 'Ostatni dzień roboczy',
  review_and_approve_changed_inputs: 'Przejrzyj i zatwierdź zmienione dane wejściowe',
  approve_and_continue: 'Zatwierdź i kontynuuj',
  active: 'Aktywny',
  active_employee_success: 'Pomyślnie aktywowano pracownika!',
  active_employees_success: 'Pomyślnie aktywowano pracowników!',
  activate_employee_failed: 'Nie udało się aktywować pracownika {name}',
  activate_employees_failed: 'Nie udało się aktywować pracowników',
  inactive: 'Nieaktywny',
  draft: 'Szkic',
  leaves: 'Urlopy',
  leave_transactions: 'Działania związane z urlopem',
  leave_history_not_loaded: 'Nie załadowano historii urlopu',
  leave_details: 'Szczegóły urlopu',
  leave_balance: 'Bilans urlopu',
  leave_balances: 'Bilanse dni urlopowych',
  balances_details: 'Szczegóły bilansów',
  edit_log: 'Edytuj wpis',
  leave_balances_in_year: 'Bilanse dni urlopowych w roku {year}',
  leave_balance_changed: 'Zmiana bilansu urlopu',
  new_period_balances_set: '{period} — nowe bilanse',
  leave_balance_transaction: 'Rozpoczęto działanie związane z urlopem',
  open_ended_leave: 'Urlop na czas nieokreślony',
  open_ended: 'Na czas nieokreślony',
  new_leave: 'Nowy urlop',
  new_leave_transaction: 'Nowe działanie związane z urlopem',
  edit_leave: 'Edytuj urlop',
  upcoming: 'Nadchodzące',
  view_details_history: 'Zobacz szczegóły i historię',
  leave_type: 'Rodzaj urlopu',
  leave_days: 'Dni urlopu',
  selected_days: 'Wybrane dni',
  requested_leave_time: 'Wnioskowany czas urlopu',
  balance: 'Saldo',
  transactions: 'Transakcje',
  entry_date_and_user: 'Data wpisu i użytkownik',
  before_transaction: 'Przed transakcją',
  after_transaction: 'Po transakcji',
  calendar_days_left: 'Pozostałe dni kalendarzowe',
  calendar_days_taken: 'Wykorzystane dni kalendarzowe',
  business_days_left: 'Pozostałe dni robocze',
  business_days_taken: 'Wykorzystane dni robocze',
  leave_days_taken_in_year: 'Dni urlopowe wykorzystane w roku {year}',
  view_annual_balance: 'Zobacz saldo roczne',
  view_current_balance: 'Zobacz bieżące saldo',
  employees_on_leave: 'Pracownicy na urlopie',
  approve_leave_transaction_confirmation: 'Czy na pewno chcesz zatwierdzić działanie związane z urlopem?',
  reject_leave_transaction_confirmation: 'Czy na pewno chcesz odrzucić działanie związane z urlopem?',
  approve_all_leave_transactions_confirmation: 'Czy na pewno chcesz zatwierdzić wszystkie działania związane z urlopem w tej grupie?',
  reject_all_leave_transactions_confirmation: 'Czy na pewno chcesz odrzucić wszystkie działania związane z urlopem w tej grupie?',
  used: 'wykorzystane',
  taken: 'wykorzystane',
  left: 'pozostało',
  invalid_adjustments_present: 'W innych zakładkach występują nieprawidłowe korekty salda ({leaveTypes})',
  edit_balance: 'Edytuj bilans urlopu',
  edit_balances: 'Edytuj bilanse dni urlopowych',
  view_employee_card: 'Wyświetl kartę pracownika',
  in_: 'w ',
  as_of_: 'na dzień',
  days_left: 'Pozostało dni',
  current_balance: 'Bieżące saldo',
  add_or_remove_days: 'Dodaj lub usuń saldo',
  new_balance: 'Nowe saldo',
  calendar_days: 'Dni kalendarzowe',
  workdays: 'Dni robocze',
  reject_all: 'Odrzuć wszystkie',
  view_details: 'Pokaż szczegóły',
  close_details: 'Zamknij szczegóły',
  postpone: ' Przełóż',
  expand_row: 'Rozwiń wiersz',
  transaction: 'Transakcja',
  this_month: 'Bieżący miesiąc',
  this_year: 'Bieżący rok',
  payment_schedule: 'Harmonogram płatności',
  view: 'Widok',
  approve: 'Zatwierdź',
  approve_all: 'Zatwierdź wszystko',
  reject: 'Odrzuć',
  import: 'Import',
  export: 'Eksport',
  dates: 'Daty',
  status: 'Status',
  pending: 'Oczekujący ',
  open_ended_pending: 'Oczekujący na czas nieokreślony',
  activate: 'Aktywuj',
  suspend: 'Zawieś',
  suspend_employee_success: 'Pomyślnie zawieszono pracownika!',
  suspend_employees_success: 'Pomyślnie zawieszono pracowników!',
  activate_confirmation: 'Czy na pewno chcesz aktywować?',
  suspend_confirmation: 'Czy na pewno chcesz zawiesić?',
  terminate: 'Zakończ stosunek pracy',
  repayment: 'Spłata',
  submitted: 'Złożone',
  rejected: 'Odrzucone',
  done: 'Gotowe',
  approved: 'Zatwierdzone',
  waiting_for_approval: 'Oczekuje na zatwierdzenie',
  send: 'Wyślij',
  select_all: 'Zaznacz wszystko',
  select: 'Zaznacz',
  edit: 'Edytuj',
  sign_out: 'Wyloguj się',
  settings: 'Ustawienia',
  back: 'Powrót',
  save: 'Zapisz',
  cancel: 'Anuluj',
  contact: 'Kontakt',
  history: 'Historia',
  taken_this_year: 'Wykorzystane w tym roku',
  future: 'Przyszłe',
  attachments: 'Załączniki',
  attachment: 'Załącznik',
  total: 'Ogółem',
  total_annual: 'Rocznie ogółem',
  request: 'Wniosek',
  requests: 'Wnioski',
  view_rejected_transaction: 'Wyświetl odrzuconą transakcję',
  close_view: 'Zamknij widok',
  new: 'Nowe',
  on: 'Na',
  add: 'Dodaj',
  add_file: 'Dodaj plik',
  new_file: 'Nowy plik',
  days: 'dni',
  Days: 'Dni',
  for: 'na',
  not_found: 'Nie znaleziono',
  start: 'Początek',
  end: 'Koniec',
  to: 'do',
  select_date: 'Wybierz datę',
  load_more: 'Załaduj więcej',
  select_days: 'Wybierz dni',
  ID: 'ID',
  clear_filters_and_sorts: 'Wyczyść filtry i sortowanie',
  new_design: 'Nowy projekt',
  documents: 'Dokumenty',
  new_document: 'Nowy dokument',
  remove_document: 'Usuń dokument',
  compensations: 'Odszkodowania',
  display_name: 'Nazwa wyświetlana',
  description: 'Opis',
  visible_in_ess: 'Widoczne w ESS',
  file: 'Plik',
  size: 'Rozmiar',
  upload_date: 'Data wysłania',
  remove_document_confirmation: 'Czy na pewno chcesz usunąć dokument?',
  no_document_found: 'Nie znaleziono dokumentów',
  drop_here: 'Upuść tutaj',
  remove: 'Usuń',
  delete: 'Usuń',
  added_this_month: 'dodano w tym miesiącu',
  no: 'Nie',
  yes: 'Tak',
  last_activity: 'Ostatnia aktywność',
  termination_date: 'Data zakończenia stosunku pracy',
  choose: 'Wybierz ...',
  employee_group: 'Grupa pracowników',
  dates_transaction_count: 'Daty/liczba transakcji',
  source: 'Źródło',
  leaveTypeName: 'Rodzaj urlopu',
  payroll_period: 'Okres płacowy',
  recurring_earnings: 'Dochody okresowe',
  recurring_deductions: 'Potrącenia okresowe',
  recurring_units: 'Jednostki okresowe',
  additional_earnings: 'Dochody dodatkowe',
  additional_deductions: 'Potrącenia dodatkowe',
  additional_units: 'Jednostki dodatkowe',
  warning: 'Uwaga!',
  amount_and_date_empty: 'Potrącenia okresowe powinny mieć datę zakończenia lub kwotę docelową',
  recurring_deductions_save_confirmation: 'Potrącenia okresowe powinny mieć datę zakończenia lub kwotę docelową. Czy na pewno chcesz to zapisać?',
  pay_element_change_delete_confirmation: 'Czy na pewno chcesz usunąć tę zmianę elementu wynagrodzenia?',
  view_history: 'Wyświetl historię',
  close_history: 'Zamknij historię',
  view_future: 'Oczekujące zmiany',
  close_future: 'Zamknij oczekujące zmiany',
  add_pay_element_type: 'Dodaj element wynagrodzenia grupy',
  pay_element_type: 'Typ elementu wynagrodzenia',
  all: 'Wszystkie',
  recurrings: 'Okresowe',
  one_times: 'Jednorazowe',
  units: 'Jednostki',
  expand_all: 'Rozwiń wszystko',
  collapse_all: 'Zwiń wszystkie',
  percentage_symbol: '%',
  values: 'Wartości',
  dependents: 'Osoby pozostające na utrzymaniu',
  dependent: 'Osoba pozostające na utrzymaniu',
  add_dependent: 'Dodaj osobę pozostającą na utrzymaniu',
  edit_dependent: 'Edytuj osobę pozostającą na utrzymaniu',
  delete_dependent: 'Usuń osobę pozostającą na utrzymaniu',
  delete_dependent_confirmation: 'Czy na pewno chcesz usunąć osobę pozostającą na utrzymaniu?',
  no_dependents_found: 'Nie znaleziono osób pozostających na utrzymaniu',
  employee_fields_saved: 'Pola danych pracownika zostały zapisane!',
  employee_fields_not_saved: 'Nie udało się zapisać pola danych pracownika',
  dependent_deleted: 'Usunięto osobę pozostającą na utrzymaniu',
  dependent_not_deleted: 'Nie udało się usunąć osoby pozostającej na utrzymaniu',
  cookie_disabled_message: 'Ciasteczka są zablokowane lub nie są obsługiwane przez Twoją przeglądarkę. Należy wyrazić zgodę na ciasteczka, by aplikacja mogła działać poprawnie.',
  no_employees: 'Brak pracownków',
  reports: 'Raporty',
  report_templates: 'Szablony raportów',
  personal: 'Osobiste',
  common: 'Ogólne',
  search: 'Szukaj',
  report_template: 'Szablon raportu',
  report_status: 'Status raportu',
  report_generation_date: 'Data wygenerowania raportu',
  report_name: 'Tytuł raportu',
  user: 'Użytkownik',
  no_reports_found: 'Nie znaleziono raportów',
  template_name: 'Nazwa szablonu',
  view_template: 'Przeglądaj szablony',
  edit_template: 'Edytuj szablon',
  generate_report: 'Generuj raport',
  no_report_templates_found: 'Nie znaleziono szablonów raportów',
  create_new_report: 'Stwórz nowy raport',
  report_from_existing_template: 'Stwórz raport na podstawie istniejącego szablonu',
  continue: 'Kontynuuj',
  date_range: 'Zakres dat',
  select_active: 'Wybierz aktywne',
  select_inactive: 'Wybierz nieaktywne',
  select_draft: 'Wybierz szkic',
  manual_employee_select: ' lub wybierz pracowników ręcznie i kontynuuj',
  delete_report: 'Usuń raport',
  delete_report_confirmation: 'Czy na pewno chcesz usunąć ten raport?',
  delete_template: 'Usuń szablon',
  delete_template_confirmation: 'Czy na pewno chcesz usunąć ten szablon raportu?',
  january: 'Styczeń',
  february: 'Luty',
  march: 'Marzec',
  april: 'Kwiecień',
  may: 'Maj',
  june: 'Czerwiec',
  july: 'Lipiec',
  august: 'Sierpień',
  september: 'Wrzesień',
  october: 'Październik',
  november: 'Listopad',
  december: 'Grudzień',
  selected_employees: 'Wybrani pracownicy',
  available_employees: 'Dostępni pracownicy',
  add_field: 'Dodaj pole danych',
  add_group: 'Dodaj grupę',
  quick_adding: 'Szybkie dodawanie',
  group_name: 'Nazwa grupy',
  save_and_generate_report: 'Zapisz i generuj raport',
  save_as_a_template: 'Zapisz jako szablon',
  update_template: 'Zaktualizuj szablon',
  select_data_fields_to_report: 'Wybierz pola danych do raportu',
  not_all_employees_with_this_status_have_been_selected: 'Nie wszyscy pracownicy o tym statusie zostali wybrani',
  preview_report: 'Podgląd raportu',
  save_and_generate: 'Zapisz i generuj',
  report_date_range: 'Zakres dat raportu',
  report_date: 'Data raportu',
  you: 'Ty',
  new_report: 'Nowy raport',
  edit_report: 'Edytuj raport',
  update_report: 'Zaktualizuj raport',
  view_report: 'Przeglądaj raport',
  new_report_template: 'Nowy szablon raportu',
  new_template: 'Nowy szablon',
  edit_report_template: 'Edytuj szablon raportu',
  update_report_template: 'Zaktualizuj szablon raportu',
  view_report_template: 'Przeglądaj szablon raportu',
  save_as: 'Zapisz jako ...',
  report_not_generated: 'Przepraszamy, raport nie został wygenerowany',
  please_try_again: 'Spróbuj ponownie',
  mandatory_field: 'To pole jest obowiązkowe',
  data_source: 'Źródło danych',
  data_source_explanation: 'Dane płacowe będą obejmować kwoty, które zostały przetworzone w liście płac (wartości proporcjonalne, wartości obliczone itp.). Opcja karty pracownika będzie zawierać kwoty zapisane na karcie pracownika.',
  master_data: 'Dane podstawowe',
  employee_id: 'ID pracownika',
  payroll_calculation: 'Obliczanie listy płac',
  employee_record: 'Akta pracownika',
  pay_element: 'Element wynagrodzenia',
  created_by_user: 'Utworzone przez użytkownika',
  visibility: 'Widoczność',
  back_to_edit: 'Powrót do edycji',
  add_account: 'Dodaj konto',
  employee_and_id: 'Pracownik i ID',
  bulk_actions: 'Działania zbiorcze ',
  out_of: ' z ',
  email_address_for_employee: 'Adres e-mail {employee}',
  enter_employee_email: ' Wprowadź adres e-mail pracownika...',
  welcome_message: 'Wiadomość powitalna',
  enter_welcome_message: 'Wprowadź wiadomość powitalną',
  no_attachment: 'Brak załącznika',
  log: 'Wpis',
  rejected_transactions: 'Odrzucone transakcje',
  pending_transactions: 'Oczekujące transakcje',
  id_and_name: 'ID i nazwisko',
  actions: 'Działania',
  select_employees: 'Wybierz pracowników',
  start_dropping_employees: 'Zacznij upuszczać pracowników tutaj',
  unselect_employees: 'Niewybrani pracownicy',
  company: 'Firma',
  help: 'Pomoc',
  templates: 'Szablony',
  calendar: 'Kalendarz',
  start_typing_to_filter: 'Zacznij pisać, aby zastosować filtr',
  position: 'Stanowisko',
  hireDate: 'Data zatrudnienia',
  currency_frequency: 'Waluta/częstotliwość',
  annual: 'Roczne',
  per_amount: 'kwota',
  per_annual: 'roczne',
  quarter: 'kwartał',
  month: 'miesiąc',
  biweek: 'dwa tygodnie',
  week: 'tydzień',
  day: 'dzień',
  hour: 'godzina',
  unit: 'jednostka',
  percentage: 'odsetek',
  choose_and_drop: 'Wybierz plik lub przeciągnij go tutaj',
  balance_taken: 'Saldo/odebrane',
  change: 'Zmiana',
  action: 'Działanie',
  comment: 'Komentarz',
  date: 'Data',
  trans: ' trans',
  D: 'D',
  balances: 'Bilanse',
  days_taken: 'dni odebrane',
  leave_a_comment_reason: 'Zostaw komentarz lub podaj powód aktualizacji',
  sth_went_wrong_redirect: 'Coś poszło nie tak. Przekierowanie nastąpi za 5 sekund',
  sth_went_wrong_redirect_launchpad: 'Coś poszło nie tak. Nastąpi przekierowanie do panelu głównego.',
  sth_went_wrong: 'Coś poszło nie tak',
  no_employees_selected: 'Nie wybrano pracowników',
  column_settings_saved_ok: 'Ustawienia kolumny zostały pomyślnie zapisane!',
  document_not_created: 'Nie utworzono dokumentu',
  document_created_ok: 'Pomyślnie utworzono dokument!',
  document_removed_ok: 'Pomyślnie usunięto dokument!',
  document_removed_nok: 'Dokument nie został usunięty',
  employee_created_ok: 'Pomyślnie utworzono pracownika!',
  employee_fields_saved_ok: 'Pola pracowników zostały pomyślnie zapisane!',
  employee_fields_nok: 'Nie udało się zapisać pól pracownika',
  pay_element_save_nok: 'Nie udało się zapisać elementu wynagrodzenia',
  pay_element_delete_nok: 'Nie udało się usunąć elementu wynagrodzenia',
  leave_transaction_created_ok: 'Pomyślnie utworzono działanie związane z urlopem!',
  leave_transaction_created_nok: 'Nie udało się dodać działania związanego z urlopem.',
  leave_transaction_created_approved_ok: 'Działanie związane z urlopem zostało pomyślnie utworzone i zatwierdzone!',
  leave_transaction_updated_ok: 'Pomyślnie zaktualizowano działanie związane z urlopem!',
  leave_transaction_update_nok: 'Nie udało się zaktualizować działania związanego z urlopem',
  leave_transaction_approved_ok: 'Działanie związane z urlopem zostało pomyślnie zatwierdzone!',
  leave_transaction_rejected_ok: 'Działanie związane z urlopem zostało pomyślnie odrzucone!',
  separation_sent_ok: 'Pomyślnie wysłano podział!',
  separation_sent_nok: 'Nie udało się zapisać podziału...',
  oops_sth_went_wrong: 'Ups! Coś poszło nie tak!',
  leave_balance_update_ok: 'Pomyślnie zaktualizowano bilans urlopu!',
  leave_balance_update_nok: 'Nie udało się zaktualizować bilansu urlopu',
  report_template_created_ok: 'Pomyślnie utworzono szablon raportu!',
  report_template_created_nok: 'Nie udało się utworzyć szablonu raportu!',
  report_update_warning: 'Nie możesz zaktualizować tego szablonu raportu, ponieważ nie jesteś jego właścicielem',
  report_template_update_ok: 'Pomyślnie zaktualizowano szablon raportu!',
  report_template_update_nok: 'Nie zaktualizowano szablonu raportu!',
  report_doesnt_exist: 'Raport nie istnieje.',
  report_template_not_set: 'Nie ustawiono szablonu',
  report_save_error: 'Podczas zapisywania raportu wystąpił błąd',
  report_not_created: 'Nie utworzono raportu',
  report_created: 'Raport został utworzony!',
  report_updated_ok: 'Pomyślnie zaktualizowano raport!',
  report_updated_nok: 'Nie zaktualizowano raportu!',
  report_generated_nok: 'Raport nie został wygenerowany. Spróbuj później',
  template_def_columns_required: 'Wymagane są grupy kolumn definicji szablonu. Proszę sprawdzić formularz ',
  report_removed_ok: 'Raport został pomyślnie usunięty!',
  report_removed_nok: 'Nie usunięto raportu',
  report_template_removed_ok: 'Pomyślnie usunięto szablon raportu!',
  report_template_removed_nok: 'Nie usunięto szablonu raportu',
  unknown: 'Nieznane',
  new_employee: 'Nowy pracownik',
  getting_legal_entities: 'Pobieranie podmiotów prawnych',
  getting_consolidation_groups: 'Pobieranie grup skonsolidowanych',
  user_auth: 'Autoryzacja użytkownika',
  getting_user_photo: 'Pobieranie zdjęcia użytkownika',
  getting_company_logo: 'Pobieranie logo firmy',
  getting_current_period_info: 'Pobieranie informacji na temat bieżącego okresu',
  ongoing: 'Trwające',
  by: ' przez',
  created_at: 'Utworzono w',
  d: 'd',
  have_been_used: 'odebrano',
  process_details: 'Przetwarzaj szczegóły',
  choose_value: 'Wybierz wartość',
  custom: 'Dostosuj',
  field_cannot_be_empty: 'Pole nie może być puste',
  calculate_retroactive: 'Czy obliczyć element z mocą wsteczną?',
  retroactive_calculating: 'Obliczanie kwoty z mocą wsteczną.',
  you_need_to_set_effective_date: 'Należy ustawić nową datę wejścia w życie zmiany elementu wynagrodzenia.',
  periodic_amount: 'Kwota okresowa',
  start_date: 'Data rozpoczęcia',
  effective_date: 'Data wejścia w życie',
  end_date: 'Data końcowa',
  goal_amount: 'Kwota docelowa',
  gross_up: 'Ubruttowienie',
  partial_period_treatment: 'Okres częściowy leczenia',
  lump_sum: 'Ryczałt',
  pro_rata: 'Proporcjonalnie',
  payroll_admin_console: 'Konsola administratora listy płac',
  employee_self_service: 'Samoobsługa pracowników',
  manager_self_service: 'Samoobsługa menedżera',
  h: 'godz.',
  hours: 'godziny',
  hours_left: 'godzin pozostało',
  hours_taken: 'godziny odebrano',
  search_employee: 'Szukaj pracownika',
  report_modal_title: 'Wybierz raport, który chcesz wygenerować',
  employee_master_data_report: 'Raport danych podstawowych pracownika',
  employee_master_data_report_exp: 'Raporty generowane na podstawie danych podstawowych pracowników',
  payroll_results_report: 'Raport wyników listy płac',
  payroll_results_report_exp: 'Raporty generowane na podstawie wyników listy płac',
  report_generated_ok: 'Pomyślnie wygenerowano raport!',
  report_not_error: 'Raport nie został wygenerowany na czas, spróbuj odświeżyć stronę za kilka minut',
  no_active_period_for_entity: 'Wybrany podmiot nie posiada aktywnego okresu rozliczeniowego, działania są ograniczone.',
  edit_mode: 'Edytuj moduł',
  report_draft: 'szkic',
  report_generating: 'generowanie',
  report_generated: 'wygenerowano',
  report_errored: 'błąd',
  your_file_to_upload_or_browse: 'Twój plik do przesłania lub przeglądania',
  drag_and_drop: 'Przeciągnij i upuść',
  field_has_error: 'Sprawdź dane',
  bank_account_created: 'Pomyślnie utworzono konto bankowe!',
  you_need_to_fix_errors_before_continue: 'Aby kontynuować, należy naprawić błędy',
  start_typing_to_display_employees: 'Zacznij pisać, aby wyświetlić pracowników',
  create_hyperwallet_account: 'Utwórz konto Hyperwallet',
  search_employee_fields: 'Wyszukaj pracownika według nazwiska, ID, adresu e-mail, stanowiska i działu',
  masterdata_edit_not_allowed: 'Edycja danych podstawowych jest obecnie niedostępna dla wybranego pracownika, nie można podjąć działań.',
  updated: 'Zaktualizowano',
  regenerate_masterdata: 'Odnowienie danych podstawowych',
  transitional_balance: 'Bilans przejściowy',
  transitional_taken: 'Przejściowe wykorzystane',
  cashout: 'Wypłata',
  base_entry: 'Bilanse początku okresu/pobrane kwoty',
  payroll_results: 'Obliczenia wynagrodzeń',
  employee_masterdata: 'Akta pracownika',
  failed_to_load_bank_data: 'Nie udało się wczytać danych bankowych',
  bank_not_found: 'Podane dane bankowe nie pasują do skonfigurowanych banków systemowych',
  field_selected_but_not_exist_in_list: 'Pole zostało wybrane, ale nie ma go na liście',
  wrong_fields_selected_please_review: 'Część z wybranych plików nie istnieje, należy to sprawdzić',

  organization: 'Organization',
  country: 'Country',
  total_headcount: 'Total headcount',
  report: 'Report',
  period: 'Period',
  OK: 'OK',
  pay_element_has_error: '',
  show_currency: 'Show currency',
}

export default polish
