import {ApiObject} from '@api';
import {interfaces, intl, constants} from '@global';
import {DateHelper} from '@library';
import {createSelector} from 'reselect';
import {getProcessState} from '../selectors';
import {isTemplateProcesses} from '../utils';
import {ProcessState} from './redux';

export const getSpreadsheetData = createSelector(
    [getProcessState],
    (state: ProcessState): any => state.spreadsheet
);
export const isLoadingSpreadsheet = createSelector(
    [getProcessState],
    (state: ProcessState): any => state.isLoadingSpreadsheet
);
export const getReport = createSelector(
    [getProcessState],
    (state: ProcessState): ApiObject.Report|null => state.report
);
export const getTemplate = createSelector(
    [getProcessState],
    (state: ProcessState): ApiObject.ReportTemplate|null => state.template
);
export const getTemplates = createSelector(
    [getProcessState],
    (state: ProcessState): ApiObject.ReportTemplate[] => state.templates
);
export const getMode = createSelector(
    [getProcessState],
    (state: ProcessState): constants.ReportProcessMode => state.mode
);
export const getPeriods = createSelector(
    [getProcessState],
    (state: ProcessState): interfaces.Periods => state.periods
);
export const getTotalEmployees = createSelector(
    [getProcessState],
    (state: ProcessState): number => state.totalEmployees
);
export const getEmployeeGroups = createSelector(
    [getProcessState],
    (state: ProcessState): interfaces.EmployeeGroups => state.employeeGroups
);
export const getListOptionsOfEmployeeGroups = createSelector(
    [getProcessState],
    (state: ProcessState): ApiObject.SelectOption[] => {
        const employeeGroups = state.employeeGroups;
        return [
            ({key: '', label: intl.get('choose')} as ApiObject.SelectOption),
            ...(Object.keys(employeeGroups)
                .map(key => ({key: `${key}`, label: employeeGroups[key].name} as ApiObject.SelectOption))),
        ];
    }
);
export const getTemplateLabel = createSelector(
    [getProcessState],
    (state: ProcessState): string => state.template ? state.template.label : ''
);

export const getReportSuffix = createSelector(
    [getProcessState, getTotalEmployees, getPeriods],
    (state: ProcessState, totalEmployees: number, periods: interfaces.Periods): string => {
        const periodsLabel = periods.length > 0 ? DateHelper.convertPeriodsToHumanFormat(periods) : '';
        return [`${totalEmployees || '0'}_Employees`, periodsLabel]
            .join('_')
            .replace(/,/gi, '_').replace(/ /gi, '');
    }
);
export const getReportName = createSelector(
    [getProcessState, getTemplateLabel, getReportSuffix, getMode],
    (state: ProcessState, templateLabel, reportSuffix, mode): string => {
        if (isTemplateProcesses(mode)) {
            return templateLabel;
        }

        return [templateLabel, reportSuffix].join('_');
    }
);
