export const EVENT_REPORT_WAS_UPDATED = 'event/reportWasUpdated';

export enum ReportsTab {
    reports = 'reports',
	report_templates = 'report_templates',
}

export enum ReportVisibility {
    private = 'private',
    public = 'public'
}

export const URL_VISIBILITY = 'visibility';
