import React, {memo} from 'react';
import {Column} from 'react-virtualized';
import styled from 'styled-components';

import { constants, interfaces, stylingVariables, intl } from '@global';
import { EmployeeInterfaces, PayrollInterfaces } from '@api';
import {
    Can,
    ChartersPreview,
    defaultInfiniteTableCellDataGetter,
} from '@common';

import Header from '../Header/Employee';

const StyledEmployeeWrapper = styled.div`
    display: flex;
    cursor: pointer;
    width: 100%;
    align-items: center;
`;

const StyledContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 15px;
    width: 100%;
`;

const StyledNameWrapper = styled.div`
    padding: 5px 0;

    span {
        cursor: pointer;
        font-size: ${stylingVariables.fontSize.mediumLarge};
        font-weight: ${stylingVariables.fontWeight.regular};
        color: ${stylingVariables.colorPalette.primary};
        outline: none;
        &:hover, &:focus {
            text-decoration: underline;
        }
    }
`;

const StyledIdWrapper = styled.span`
  padding: 3px 0;
  font-size: ${stylingVariables.fontSize.medium};

  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

const Cell: React.FC<{
    cellData: EmployeeInterfaces.Employee,
    currentPeriod?: PayrollInterfaces.PayrollPeriod|null;
    onClickLink?: (event: any, id: number) => void,
}> = memo(({cellData, onClickLink}) => {
    const fullName = cellData.person ? `${cellData.person.firstName} ${cellData.person.lastName}` : '';
    const photoAccessToken = cellData.photoAccessToken;
    const legalEntityId = cellData.legalEntityId;

    return (
        <StyledEmployeeWrapper>
            <ChartersPreview text={fullName} photo={photoAccessToken} legalEntityId={legalEntityId}/>
            <StyledContentWrapper>
            <Can run={constants.PermissionCode.EMPLOYEE}>
                    <StyledNameWrapper>
                        <span onClick={(event: any) => onClickLink && onClickLink(event, cellData.id)}>{fullName}</span>
                    </StyledNameWrapper>
                </Can>

                <StyledIdWrapper>{cellData.referenceCode}</StyledIdWrapper>

            </StyledContentWrapper>
        </StyledEmployeeWrapper>
    );
});

interface IProps {
    query?: string;
    orders?: interfaces.ListingOrders;
    filters?: interfaces.ListingFilters;
    tags?: EmployeeInterfaces.Tag[];
    currentPeriod?: PayrollInterfaces.PayrollPeriod|null;

    onClickLink?: (event: any, id: number) => void;

    changeOrder?: (column: string) => any;
    changeFilter?: (params: interfaces.FilterParams) => any;
    setTags?: (tags: EmployeeInterfaces.Tag[]) => void;

    getEmployees?: (
        query: string,
        tags: EmployeeInterfaces.Tag[],
        limit: number,
        page: number,
    ) => any;
}

const Employee: React.FC<IProps> = ({
    currentPeriod,

    filters,
    orders,
    tags,

    changeOrder,
    changeFilter,
    setTags,

    getEmployees,
    onClickLink,
}) => (
    <Column
        dataKey='employee'
        width={250}
        flexGrow={1}
        flexShrink={0}
        cellDataGetter={defaultInfiniteTableCellDataGetter}
        headerRenderer={() => (
            <Header
                label={intl.get('id_and_name')}
                currentPeriod={currentPeriod}

                filters={filters}
                orders={orders}
                tags={tags}

                changeOrder={changeOrder}
                changeFilter={changeFilter}
                setTags={setTags}
                getEmployees={getEmployees}
            />
        )}
        cellRenderer={({cellData}) => (
            <Cell cellData={cellData.employee} currentPeriod={currentPeriod} onClickLink={onClickLink}/>
        )}
    />
);

export default Employee;
