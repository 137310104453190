import {AnyAction, Dispatch} from 'redux';
import {ThunkAction} from 'redux-thunk';
import {toast} from 'react-toastify';

import {ReportTemplateQueryParams, ApiObject, ApiReportTemplate, ApiReport} from '@api';
import {intl, constants} from '@global';

import {RootState} from '../../rootReducer';
import {startLoading, stopLoading} from '../redux';
import {EVENT_REPORT_WAS_UPDATED} from './constants';
import * as globalSelectors from '../selectors';
import {removeGeneratingReportId} from './redux';

type ThunkResult<R> = ThunkAction<R, RootState, undefined, AnyAction>;

export function getTemplates(): ThunkResult<Promise<ApiObject.ReportTemplate[]>> {
    return async(dispatch: Dispatch, getState: () => RootState) => {
        const state = getState()
        const currentLegalEntityId = state.global.currentLegalEntityId;
        if (!currentLegalEntityId) {
            return []
        }

        dispatch(startLoading())
        const params = new ReportTemplateQueryParams({
            visibilities: [ApiObject.ReportVisibility.private, ApiObject.ReportVisibility.public],
            isTemporary: false
        })
        let templates = await ApiReportTemplate.list(currentLegalEntityId, params)
        dispatch(stopLoading())

        return templates
          .map((template: ApiObject.ReportTemplate) => ({...template, employees: {queries: [], orderBy: []}}))
    };
}

export function getAllTemplates(): ThunkResult<Promise<ApiObject.ReportTemplate[]>> {
    return async(dispatch: Dispatch, getState: () => RootState) => {
        const state = getState()
        const currentLegalEntityId = state.global.currentLegalEntityId;
        if (!currentLegalEntityId) {
            return []
        }

        const getTemplatesAction = async (isTemporary: boolean): Promise<ApiObject.ReportTemplate[]> => {
            dispatch(startLoading())
            const templates = await ApiReportTemplate.list(
              currentLegalEntityId,
              new ReportTemplateQueryParams({
                  visibilities: [ApiObject.ReportVisibility.private, ApiObject.ReportVisibility.public],
                  isTemporary
              }))
            dispatch(stopLoading())

            return templates
        }

        const [nonTemporaryTemplates, temporaryTemplates]: [ApiObject.ReportTemplate[], ApiObject.ReportTemplate[]] =
          await Promise.all([
              getTemplatesAction(false),
              getTemplatesAction(true)
          ])

        return [...nonTemporaryTemplates, ...temporaryTemplates]
          .map((template: ApiObject.ReportTemplate) => ({...template, employees: {queries: [], orderBy: []}}))
    };
}

export function checkGeneratedReport(reportId: number, attempts: number[] = [...constants.defaultGetReportAttempts]) {
    return async (dispatch: Dispatch, getState: () => RootState) => {
        const report: ApiObject.Report|null = await ApiReport.findById(globalSelectors.getCurrentLegalEntityId(getState()), reportId);
        if (!report) {
            toast.error(intl.get('sth_went_wrong'));
            return;
        }

        if (report.status === ApiObject.ReportStatus.generating) {
            if (attempts.length === 0) {
                dispatch(removeGeneratingReportId(reportId) as any);
                toast.info(intl.get('report_not_error'));
                return;
            }
            const nextTimeout = attempts.shift();
            setTimeout(() => {
                dispatch(checkGeneratedReport(reportId, attempts) as any);
            }, nextTimeout);

            return;
        }

        if (report.status === ApiObject.ReportStatus.generated) {
            toast.success(intl.get('report_generated_ok'));
        } else {
            toast.error(intl.get('report_generated_nok'));
        }

        dispatch(removeGeneratingReportId(reportId) as any);
        dispatch(reportWasUpdated(report) as any);

        return;
    };
}

export function reportWasUpdated(report: ApiObject.Report) {
    return async (dispatch: Dispatch) => {
        dispatch({
            type: EVENT_REPORT_WAS_UPDATED,
            payload: report
        })
    };
}
