import {stylingVariables} from "@global";
import styled from "styled-components";

export const Simple = styled.div<{color?: string}>`
	height: 100%;
	min-height: 100px;
	min-width: 100px;
    border-radius: ${stylingVariables.layout.borderRadius};
    background-color: ${props => props.color || '#fff'};
    box-shadow: 0px 0px 6px 1px #ece7e7;
`;

export const Heading = styled.div`
	padding: 0 15px;
	display: flex;
	align-items: center;
    height: 60px;
	border-bottom: 1px solid ${stylingVariables.colorPalette.gray};
    background-color: transparent;
    font-size: ${stylingVariables.fontSize.mediumLarge};
    font-weight: ${stylingVariables.fontWeight.semibold};
`;

export const HorizontalFlex = styled.div`
	display: flex;
	align-items: center;
`;

export const VerticalFlex = styled.div`
	display: flex;
	flex-direction: column
`;
