import React, {PureComponent} from 'react';
import {intl} from '@global';
import {Modal as CommonModal, Layout} from '@common';
import styled from 'styled-components';
import Validator from 'validatorjs';

import {EmployeeInterfaces, LeaveInterfaces} from '@api';
import {stylingVariables} from '@global';

import EmployeeRow from './EmployeeRow';
import LeaveTypeRow from './LeaveTypeRow';
import PeriodRow from './PeriodRow';

const StyledFormHeader = styled.div`
    color: ${stylingVariables.colorPalette.darkGreen};
    font-size: ${stylingVariables.fontSize.mediumLarge};
    font-weight: ${stylingVariables.fontWeight.semibold};
    margin-bottom: 30px;
`;

interface IProps {
    employee: EmployeeInterfaces.Employee;
    transaction: LeaveInterfaces.Transaction;
    types: LeaveInterfaces.Type[];
    legalEntityId: number|null;

    onClickClose: () => void;
    onClickSave: (employeeId: number, transaction: LeaveInterfaces.Transaction) => void;
}

interface IState {
    employeeId: number | null;
    leaveTypeId: number | null;
    from: string | null | undefined;
    till: string | null | undefined;

    errors: {
        [key: string]: string[],
    };
}

export default class EditModal extends PureComponent<IProps, IState> {
    private readonly validationRules = {
        employeeId: 'required',
        leaveTypeId: 'required',
        from: 'required',
        till: 'required',
    };

    private readonly validationRulesOpenEnded = {
        employeeId: 'required',
        leaveTypeId: 'required',
        from: 'required',
    };

    private readonly FIELD_EMPLOYEE = 'employeeId';
    private readonly FIELD_LEAVE_TYPE = 'leaveTypeId';
    private readonly FIELD_DAYS = 'till';

    public constructor(props: IProps) {
        super(props);

        this.state = {
            employeeId: props.employee.id,
            leaveTypeId: props.transaction.leaveTypeId,
            from: props.transaction.startDate,
            till: props.transaction.endDate,
            errors: {},
        };
    }

    render() {
        const originalTransaction = this.props.transaction.originalTransaction;
        return (
            <CommonModal.CenterModal width={'550px'} minWidth={'550px'} onClickClose={this.props.onClickClose}>
                <CommonModal.StyledHeader color={stylingVariables.colorPalette.darkest}>
                    <h4>{intl.get('edit_leave')}</h4>
                </CommonModal.StyledHeader>

                <CommonModal.OverflowableContent style={{padding: '20px 40px'}}>
                    <StyledFormHeader>
                        {intl.get('leave_details')}
                    </StyledFormHeader>

                    <Layout.Rows>
                        <EmployeeRow
                            employee={this.props.employee}
                            currentLegalEntityId={this.props.legalEntityId}
                            onChange={this.onChangeEmployee}
                            errors={this.state.errors[this.FIELD_EMPLOYEE]}
                        />

                        <LeaveTypeRow
                            default={this.props.transaction.leaveTypeId}
                            defaultName={this.props.transaction.leaveTypeName}
                            leaveTypes={this.props.types}
                            onChange={this.onChangeLeaveType}
                            errors={this.state.errors[this.FIELD_LEAVE_TYPE]}
                        />

                        <PeriodRow
                            defaultFrom={this.props.transaction.startDate}
                            defaultTill={this.props.transaction.endDate}
                            onChange={this.onChangePeriod}
                            errors={this.state.errors[this.FIELD_DAYS]}
                            isStartDateDisabled={
                                !!originalTransaction ||
                                this.props.transaction.status === LeaveInterfaces.TransactionStatus.open_ended_pending ||
                                this.props.transaction.status === LeaveInterfaces.TransactionStatus.done
                            }
                        />
                    </Layout.Rows>
                </CommonModal.OverflowableContent>

                <CommonModal.StyledActionBar style={{paddingTop: 20}}>
                    <CommonModal.StyledCancelButton onClick={this.props.onClickClose}>
                        {intl.get('cancel')}
                    </CommonModal.StyledCancelButton>

                    <CommonModal.StyledOkButton onClick={this.save}>
                        {intl.get('save')}
                    </CommonModal.StyledOkButton>
                </CommonModal.StyledActionBar>
            </CommonModal.CenterModal>
        );
    }

    private save = () => {
        const leaveType = this.props.types.find(x => x.id === this.state.leaveTypeId);
        const validator = new Validator(
            this.state,
            !leaveType?.hasBalance ? this.validationRulesOpenEnded : this.validationRules,
        );

        validator.check();
        if (validator.passes()) {
            this.props.onClickSave(
                this.props.employee.id,
                {
                    ...this.props.transaction,
                    id: this.props.transaction.id,
                    leaveTypeId: this.state.leaveTypeId,
                    startDate: this.state.from,
                    endDate: this.state.till,
                } as LeaveInterfaces.Transaction,
            );

            this.props.onClickClose();
        } else {
            this.setState({errors: validator.errors.errors});
        }
    }

    private onChangeEmployee = (employeeId: number | null) => this.setState({employeeId});
    private onChangeLeaveType = (leaveTypeId: number | null) => this.setState({leaveTypeId});
    private onChangePeriod = (from?: string|null, till?: string|null) => this.setState({from, till});
}
