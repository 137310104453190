import {ApiObject} from '@api';
import React from 'react';

import {FormatNumbers} from '@common';
import {DateHelper} from '@library';
import {get} from 'lodash';

export const defaultInfiniteTableCellDataGetter = ({rowData}: { rowData: any }) => rowData;

export const cellRendererCreator = (type: string, dataKey: string, options?: ApiObject.SelectOption[]) => {
    switch (type) {
        case ApiObject.FieldType.dropdown:
            return ({cellData}: any) => {
                const key = get(cellData, dataKey, null);
                return options ? (options.find(x => `${x.key}` === `${key}`) || {label: ''}).label : key;
            };
        case ApiObject.FieldType.text:
            return ({cellData}: any) => get(cellData, dataKey, null);
        case ApiObject.FieldType.number:
            return ({cellData}: any) => {
                const value = get(cellData, dataKey, null);
                if (!value && value !== 0) {
                    return '-';
                }

                return (
                    <FormatNumbers value={value}/>
                );
            };
        case ApiObject.FieldType.date:
            return ({cellData}: any) => {
                return DateHelper.convertDateToHumanFormat(
                    get(cellData, dataKey, null),
                );
            };
        case ApiObject.FieldType.datetime:
            return ({cellData}: any) => {
                return DateHelper.convertDateTimeToHumanFormat(
                    get(cellData, dataKey, null),
                );
            };
        case ApiObject.FieldType.memorySizeBytes:
            return ({cellData}: any) => {
                const bytes = get(cellData, dataKey, null);
                const i = Math.floor(Math.log(bytes) / Math.log(1024));
                const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
                const num: string = (bytes / Math.pow(1024, i)).toFixed(2);

                return `${num} ${sizes[i]}`;
            };
        default:
            return ({cellData}: any) => get(cellData, dataKey, null);
    }
};

export const handleEnterPressEvent = (callback: () => any) => (event: any): void => {
    if (event.key === 'Enter') {
        callback();
    }
};

export const handleEscDownEvent = (callback: () => any) => (event: any): void => {
    if (event.key === 'Esc' || event.key === 'Escape') {
        callback();
    }
};
