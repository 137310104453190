import React, {PureComponent} from 'react';
import {intl} from '@global';

import {stylingVariables} from '@global';
import {InputWithSuffix, Layout} from '@common';

interface IProps {
    suffix: string;
    reportName?: string;
    onChange: (name: string) => any;
    errors?: string[];
}

export default class NameRow extends PureComponent<IProps> {
    public render() {
        return (
            <Layout.Row>
                <Layout.RowLabel percent={30} color={this.props.errors ? stylingVariables.colorPalette.red : undefined}
                                 style={{margin: 'auto', fontSize: '14px'}}
                >
                    {intl.get('report_name')}*
                </Layout.RowLabel>
                <Layout.RowValue percent={70}>
                    <InputWithSuffix
                        suffix={this.props.suffix}
                        onChange={this.props.onChange}
                        defaultValue={this.props.reportName}
                    />
                </Layout.RowValue>
            </Layout.Row>
        );
    }
}
