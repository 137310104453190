import { LeaveInterfaces } from '@api';
import moment, { Moment } from 'moment';

import { api } from '@global';

export default class ApiLeave {
    public static async getTypes(legalEntityId: number) {
        const { data } = await api.get(`/mss/legal-entity/${legalEntityId}/leave/regulations`);

        return data.data || [];
    }

    // should use getEmployeeLeaveBalances
    public static async getLeaves(legalEntityId: number, params: LeaveQueryParams) {
        const res = await api.post(`/mss/legal-entity/${legalEntityId}/leave/balances`, params.params);
        if (res) {
            const { data } = res
            return data?.data?.periodEmployeeBalances[0]?.employeeBalances || [];
        }
        return []
    }

    // should use getEmployeeLeaveTransactions
    public static async getTransactions(legalEntityId: number, params: LeaveQueryParams) {
        const { data } = await api.post(`/mss/legal-entity/${legalEntityId}/leave/transactions`, params.params);
        return data?.data?.periodEmployeetransactions[0]?.employeeTransactions || [];
    }

    // Returns Map of leave regulation entries
    public static async getEmployeeTransitionalBalances(
        entityId: number,
        employeeId: number,
        from: string,
        to: string,
    ): Promise<LeaveInterfaces.TransitionalBalances> {
        const { data } = await api.post(`/mss/legal-entity/${entityId}/leave/employee/${employeeId}/balances`, {
            startDate: from,
            endDate: to,
        });

        if (!data.data) {
            return [];
        }

        return data.data.regulationEntries;
    }

    public static async getEmployeeLeaveBalances(
        entityId: number,
        employeeId: number,
        from: string,
        to: string,
    ): Promise<LeaveInterfaces.PeriodEmployeeBalance[]> {
        const { data } = await api.post(`/mss/legal-entity/${entityId}/leave/balances`, {
            employees: [employeeId],
            startDate: from,
            endDate: to,
        });

        if (!data.data) {
            return [];
        }

        return data.data.periodEmployeeBalances;
    }

    public static async getEmployeeLeaveTransactions(
        entityId: number,
        params: LeaveQueryParams,
    ): Promise<LeaveInterfaces.PeriodEmployeeTransaction[]> {
        const { data } = await api.post(`/mss/legal-entity/${entityId}/leave/transactions`, params.params);
        if (!data.data) {
            throw Error(data.errors[0]?.message ?? 'Could not fetch leave transactions')
        }
        return data.data.periodEmployeetransactions ?? [];
    }

    public static async balanceAdjustment(entityId: number, adjustments: LeaveInterfaces.BalanceAdjustment[]) {
        await api.put(`/mss/legal-entity/${entityId}/leave/balances`, {
            employeeBalances: adjustments,
        });

        return;
    }

    public static async approve(entityId: number, transactionId: string) {
        await api.put(`/mss/legal-entity/${entityId}/leave/transactions/submit`, [
            { id: transactionId },
        ]);

        return;
    }

    public static async reject(entityId: number, transactionId: string) {
        await api.delete(`/mss/legal-entity/${entityId}/leave/transactions`, {
            data: [
                { id: transactionId },
            ],
        });

        return;
    }

    public static async create(
        entityId: number,
        employeeId: number,
        transaction: LeaveInterfaces.Transaction,
    ) {
        const { data } = await api.post(`/mss/legal-entity/${entityId}/leave/employee/${employeeId}`, {
            ...transaction,
        });

        data.data.forEach((obj: any) => {
            if (!obj.hasOwnProperty("createTime")) {
                obj["createTime"] = moment().format('YYYY-MM-DDTHH:mm:ss[Z]');
            }
        });

        return ApiLeave.handleResponse(data);
    }

    public static async store(
        entityId: number,
        transaction: LeaveInterfaces.Transaction,
    ) {
        const { data } = await api.put(`/mss/legal-entity/${entityId}/leave/transactions`, [
            transaction,
        ]);

        return data.data || [];
    }

    private static handleResponse(res: Response) :any {
        if (res.errors.length > 0) {
            throw new Error(res.errors[0].message);
        }

        return res.data || [];
    }
}

interface Response {
    data?: Object,
    errors: Array<Error>;
    status?: number;
}

interface Error {
    message?: string;
    code?: string|null;
}

interface RequestParams {
    startDate?: string;
    endDate?: string;
    leaveTypes?: number[];
    statuses?: string[];
    employees?: Array<number> | null;
    leaveTypeIds?: Array<number> | null;
}

interface LeaveQueryParamsInterface {
    from?: Moment,
    till?: Moment,
    typeId?: number | null,
    status?: string | null,
    employeeIds?: number[],
    leaveTypeIds?: number[],
}

export class LeaveQueryParams {
    private readonly from?: Moment;
    private readonly till?: Moment;
    private readonly typeId?: number | null;
    private readonly status?: string | null;
    private readonly employeeIds: number[];
    private readonly leaveTypeIds?: number[];

    public constructor({
        from,
        till,
        typeId,
        status,
        employeeIds,
        leaveTypeIds,

    }: LeaveQueryParamsInterface = {}) {
        this.from = from;
        this.till = till;
        this.typeId = typeId;
        this.status = status;
        this.employeeIds = employeeIds || [];
        this.leaveTypeIds = leaveTypeIds || [];

    }

    public get params(): RequestParams {
        const params: RequestParams = {};
        if (this.from) {
            params.startDate = this.from.format('YYYY-MM-DD');
        }

        if (this.till) {
            params.endDate = this.till.format('YYYY-MM-DD');
        }

        if (this.typeId) {
            params.leaveTypes = [this.typeId];
        }

        if (this.status) {
            params.statuses = [this.status];
        }

        if (this.employeeIds.length > 0) {
            params.employees = this.employeeIds;
        }

        if (this.leaveTypeIds && this.leaveTypeIds.length > 0) {
            params.leaveTypeIds = this.leaveTypeIds;
        }

        return params;
    }
}
