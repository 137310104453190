import React, {CSSProperties, PureComponent} from 'react';
import styled from 'styled-components';
import {intl} from '@global';

import {stylingVariables} from '@global';

interface IStatusBarItem {
    isActive: boolean;
    isDisabled: boolean;
    styles?: CSSProperties;
    activeStyles?: CSSProperties;
}

const StatusBarItem = styled.button<IStatusBarItem>`
  margin-right: 5px;
  border: none;
  color: ${stylingVariables.colorPalette.dark};
  font-size: ${stylingVariables.fontSize.default};
  font-weight: ${stylingVariables.fontWeight.bold};
  cursor: pointer;
  position: relative;
  border-radius: 15px;
  padding: 5px 15px;
  background-color: transparent;
  height: 30px;

  ${props => props.styles && props.styles.fontWeight && `
    font-weight: ${props.styles.fontWeight};
  `};

  ${props => props.isActive && `
    color: ${props.activeStyles && props.activeStyles.color ? props.activeStyles.color : stylingVariables.colorPalette.orange};
    background-color: ${props.activeStyles && props.activeStyles.backgroundColor ? props.activeStyles.backgroundColor : stylingVariables.colorPalette.gray};
  `};

  ${props => props.isDisabled && `
    color: ${stylingVariables.colorPalette.darkGray};
    cursor: default;
    pointer-events: none;
  `};

  &:hover {
    color: ${stylingVariables.colorPalette.orange};
  }
`;

interface ItemProps {
    status: string;
    count?: number | null;
    isActive: boolean;
    isDisabled: boolean;

    onClick: (status: string) => void;

    activeStyles?: CSSProperties;
    styles?: CSSProperties;
}

export default class Item extends PureComponent<ItemProps> {
    static defaultProps = {
        isActive: false,
        isDisabled: false,
        count: null,
    };

    public render() {
        const {count} = this.props;

        return (
            <StatusBarItem
                activeStyles={this.props.activeStyles}
                styles={this.props.styles}
                isActive={this.props.isActive}
                isDisabled={this.props.isDisabled}
                onClick={() => this.props.onClick(this.props.status)}
            >
                {intl.get(this.props.status )}
                {(count !== null && count !== 0) ? ` (${count}) ` : null}
            </StatusBarItem>
        );
    }
}
