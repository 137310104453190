import React, {PureComponent} from 'react';
import {Moment} from 'moment';

import {stylingVariables, constants} from '@global';
import {Table as CommonTable, Icon, Layout} from '@common';
import {intl} from '@global';
import styled from 'styled-components';

const DownloadButton = styled.a`
  display: flex;
  align-items: center;
  cursor: pointer;
  color: ${stylingVariables.colorPalette.green};
  svg {
    margin-right: 5px;
  }
  &:hover {
    text-decoration: underline;
  }
`;

interface AttachmentsProps {
    attachments: {
        id: number,
        name: string,
        downloadUrl?: string,
        createdAt?: Moment,
        createdBy?: string
    }[]
}

export default class Attachments extends PureComponent<AttachmentsProps> {
    render() {
        if (0 === this.props.attachments.length) {
            return null;
        }

        return (
            <CommonTable.table>
                <CommonTable.thead>
                    <CommonTable.tr>
                        <CommonTable.th>{intl.get('attachments')}</CommonTable.th>
                        <CommonTable.th />
                    </CommonTable.tr>
                </CommonTable.thead>

                <CommonTable.tbody>
                    {
                        this.props.attachments.map(attachment => (
                            <CommonTable.tr key={attachment.id}>
                                <CommonTable.td>
                                    <DownloadButton href={attachment.downloadUrl}>
                                        <Icon type={constants.IconTypes.FILE}
                                              width={20}
                                              height={20}
                                              fillColor={stylingVariables.colorPalette.green}
                                        />
                                        {attachment.name}
                                    </DownloadButton>
                                </CommonTable.td>
                                <CommonTable.td>
                                    <Layout.Subtitle>
                                        {attachment.createdAt && attachment.createdAt.format('MMMM DD, YYYY')}
                                        {attachment.createdBy && ` ${intl.get('by')} ${attachment.createdBy}`}
                                    </Layout.Subtitle>
                                </CommonTable.td>
                            </CommonTable.tr>
                        ))
                    }
                </CommonTable.tbody>
            </CommonTable.table>
        )
    }
}
