import React, {PureComponent} from 'react';
import {connect} from 'react-redux';

import {constants, intl} from '@global';
import {EmployeeQueryParams} from '@api';
import {StatusBar as CommonStatusBar} from '@common';

import {RootState} from '../../rootReducer';
import * as selectors from './selectors';
import {choseStatus, clearOldAndGetEmployees, getEmployeeCounts} from './redux';
import {get} from 'lodash';
import {setSearchQuery} from "../redux";
import * as globalSelectors from "../selectors";

interface StatusBarProps {
    chosenStatus: string;
    counts: {
        active: number | null;
        inactive: number | null;
        draft: number | null;
    };

    choseStatus: (status: string) => void;
    clearOldAndGetEmployees: (params: EmployeeQueryParams) => void;
    getEmployeeCounts: () => void;
    setSearchQuery: (searchQuery: string) => void;
    searchQuery: string;
}

export class StatusBar extends PureComponent<StatusBarProps> {
    public onChangeSearch = (value: string) => {
        this.props.setSearchQuery(value);
    }

    public render() {
        return (
            <CommonStatusBar.StatusBar
                onSearch={this.onChangeSearch}
                searchPlaceholder={intl.get('search_employee')}
                searchQuery={this.props.searchQuery}
            >
                {
                    Object.keys(constants.EmployeeStatuses).map(status => (
                        <CommonStatusBar.Item
                            key={status}
                            status={status}
                            count={get(this.props.counts, status)}
                            onClick={this.changeStatus} isActive={status === this.props.chosenStatus}
                        />
                    ))
                }
            </CommonStatusBar.StatusBar>
        );
    }

    private changeStatus = (status: string) => {
        this.props.choseStatus(status);
        this.props.clearOldAndGetEmployees(
            new EmployeeQueryParams({status}),
        );
        this.props.getEmployeeCounts();
    }
}

const mapStateToProps = (state: RootState, ownProps: any) => ({
    chosenStatus: selectors.getChosenStatus(state, ownProps),
    counts: selectors.getCounts(state, ownProps),
    filters: selectors.getFilters(state, ownProps),
    searchQuery: globalSelectors.getSearchQuery(state),
});

const mapDispatchToProps = {
    choseStatus,
    clearOldAndGetEmployees,
    getEmployeeCounts,
    setSearchQuery,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StatusBar);
