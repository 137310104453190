import {stylingVariables} from '@global';
import {ReportTemplatesHelper} from '@library';
import React, {PureComponent} from 'react';
import {Column} from "react-virtualized";
import {intl} from '@global';
import {Actions as CommonActions, defaultInfiniteTableCellDataGetter} from "@common";
import {ApiObject} from '@api';
import styled from 'styled-components';
import GenerateReportModal from '../../../Process/common/GenerateReportModal/GenerateReportModal';
import Delete from './Delete'

const StyledHoveredLink = styled(CommonActions.Link)`
  padding: 5px 10px;
  border-radius: 10px;
  &:hover {
    color: ${stylingVariables.colorPalette.green};
    background-color: ${stylingVariables.colorPalette.lightGreen};
  }
`;

interface IProps {
    onClickView: (cellData: ApiObject.ReportTemplate) => any;
    onClickEdit: (cellData: ApiObject.ReportTemplate) => any;
}

interface ICellProps {
    template: ApiObject.ReportTemplate;
    onClickView: (cellData: ApiObject.ReportTemplate) => any;
    onClickEdit: (cellData: ApiObject.ReportTemplate) => any;
}

interface ICellState {
    deleteModal: boolean;
    updateModal: boolean;
    generateModal: boolean;
}

class Cell extends PureComponent<ICellProps, ICellState> {
    public constructor(props: ICellProps) {
        super(props);

        this.state = {
            deleteModal: false,
            updateModal: false,
            generateModal: false
        };
    }

    private toggleModal(modalName: keyof ICellState) {
        this.setState({...this.state, [modalName]: !this.state[modalName]})
    }

    public render() {
        return (
            <>
                <StyledHoveredLink  onClick={() => this.toggleModal('generateModal')}>
                    {intl.get('generate_report')}
                </StyledHoveredLink>

                <CommonActions.MenuActions>
                    <CommonActions.Action onClick={() => this.props.onClickView(this.props.template)}>
                        {intl.get('view_template')}
                    </CommonActions.Action>

                    {this.renderUpdateAction()}
                    {this.renderDeleteReportAction()}

                    {this.state.deleteModal ? this.renderDeleteModal() : null}
                    {this.state.generateModal && this.renderGenerateModal()}
                </CommonActions.MenuActions>
            </>
        );
    }

    renderUpdateAction = () => {
        let isDisabled = false;

        if (!this.props.template) {
            isDisabled = true;
        }

        return <CommonActions.Action onClick={() => this.props.onClickEdit(this.props.template)} isDisabled={isDisabled}>
            {intl.get('update_template')}
        </CommonActions.Action>;
    }

    renderDeleteReportAction = () => {
        if (!ReportTemplatesHelper.isCurrentUserOwnerOnTemplate(this.props.template)) {
            return null;
        }

        return <CommonActions.Action onClick={() => this.toggleModal('deleteModal')}>
            {intl.get('delete')}
        </CommonActions.Action>;
    }


    private renderGenerateModal() {
        return (
            <GenerateReportModal
                template={this.props.template}
                onClose={() => this.toggleModal('generateModal')}
            />
        );
    }

    private renderDeleteModal() {
        return (
            <Delete
                isOpen={this.state.deleteModal}
                reportId={this.props.template.id}
                onClose={() => this.toggleModal('deleteModal')}
            />
        );
    }
}

const Actions = ({onClickView, onClickEdit}: IProps) => (
    <Column
        dataKey="actions"
        width={200}
        cellDataGetter={defaultInfiniteTableCellDataGetter}
        cellRenderer={({cellData}) => <Cell template={cellData} onClickView={onClickView} onClickEdit={onClickEdit} />}
    />
);

export default Actions;
