import * as Cookies from 'js-cookie'

const EnvironmentModes = {
  DEV: 'development',
  PROD: 'production',
  ACC: 'acceptance',
}

//Should be same BUILD_NUMBER with OLD version of MSS: `frontend\src\library\loadConfiguration.js`
const BUILD_NUMBER = 3

const COOKIE_POSTFIX = '_cookie_V_' + BUILD_NUMBER
const COOKIE_EXPIRES = 7 //days

export const IS_SUPPORT_NEW_MSS = 'isSupportNewMSS' + COOKIE_POSTFIX
export const LAUNCHPAD_URL_COOKIE = 'LAUNCHPAD_URL_COOKIE' + COOKIE_POSTFIX

export default class EnvironmentHelper {
  public static getLaunchpadUrl(): string {
    return this.getLaunchpadUrlCookie() || process.env.REACT_APP_LAUNCHPAD_WEBAPP_URL || ''
  }

  public static getBackToLaunchpadUrl(): string {
    return this.getLaunchpadUrl() + '/launchpad'
  }

  public static getLogoutUrl(): string {
    return this.getLaunchpadUrl() + '/logout'
  }

  public static getApiUrl(): string {
    return process.env.REACT_APP_API_BASE_URL || ''
  }

  public static getServiceName(): string {
    return process.env.REACT_APP_SERVICE_NAME || ''
  }

  public static getHrbFreshDeskUrl(): string {
    return process.env.REACT_APP_HRB_FRESHDESK_URL || ''
  }

  public static getOldMssUrl(): string {
    return process.env.REACT_APP_OLD_MSS_WEBAPP_URL || ''
  }

  public static getSupportEmail(): string {
    return process.env.REACT_APP_SUPPORT_EMAIL || ''
  }

  public static isDevelopmentMode(): boolean {
    return process.env.NODE_ENV === EnvironmentModes.DEV
  }

  public static isProductionMode(): boolean {
    return process.env.NODE_ENV === EnvironmentModes.PROD
  }

  public static isAcceptanceMode(): boolean {
    return process.env.NODE_ENV === EnvironmentModes.ACC
  }

  public static getEdriveUrl(): string {
    return process.env.REACT_APP_EDRIVE_URL || ''
  }

  public static getEdriveClientId(): string {
    return process.env.REACT_APP_EDRIVE_CLIENT_ID || ''
  }

  public static cookieDomainUrl(): string {
    return process.env.REACT_APP_COOKIES_DOMAIN_URL || window.location.hostname
  }

  public static getHelpSupportUrl(): string {
    return 'https://hrblizz.freshdesk.com/support/solutions'
  }

  public static getLaunchpadClientId(): string {
    return process.env.REACT_APP_LAUNCHPAD_CLIENT_ID || ''
  }

  public static isSupportNewMSS(): boolean {
    const isSupport = Cookies.get(IS_SUPPORT_NEW_MSS)
    if (typeof isSupport !== 'undefined') {
      return JSON.parse(isSupport)
    } else {
      this.setSupportNewMSS(false)
    }

    return false
  }

  public static setSupportNewMSS = (isSupport: boolean = false, redirectHref: string | null = null) => {
    Cookies.set(IS_SUPPORT_NEW_MSS, JSON.stringify(isSupport), {
      expires: COOKIE_EXPIRES,
      domain: EnvironmentHelper.cookieDomainUrl(),
    })
    if (redirectHref) {
      window.location.href = redirectHref
    }
  }

  public static setLaunchpadUrlCookie = () => {
    const pathArray = document.referrer.split('/')
    const launchpadUrl = `${pathArray[0]}//${pathArray[2]}`
    Cookies.set(LAUNCHPAD_URL_COOKIE, JSON.stringify(launchpadUrl), {
      expires: COOKIE_EXPIRES,
      domain: EnvironmentHelper.cookieDomainUrl(),
    })
  }

  public static getLaunchpadUrlCookie = () => {
    const launchpadUrl = Cookies.get(LAUNCHPAD_URL_COOKIE)
    if (typeof launchpadUrl !== 'undefined') {
      return JSON.parse(launchpadUrl)
    }

    return false
  }

  public static isCookieEnabled() {
    const cookieEnabled = navigator.cookieEnabled
    if (!cookieEnabled) {
      document.cookie = COOKIE_POSTFIX
      return document.cookie.indexOf(COOKIE_POSTFIX) !== -1
    }
    return cookieEnabled
  }

  public static getHookUrl(): string {
    return process.env.REACT_APP_HOOK_URL ? process.env.REACT_APP_HOOK_URL + '/fe-hook' : ''
  }

  public static getHookAuthorization(): string {
    const buffer = Buffer.from(
      `${process.env.REACT_APP_HOOK_USERNAME || ''}:${process.env.REACT_APP_HOOK_PASSWORD || ''}`
    )
    return `Basic ${buffer.toString('base64')}`
  }
}
