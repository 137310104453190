import React, {PureComponent} from 'react';

import {ApiObject, EmployeeInterfaces} from '@api';
import {EditableField as Default} from '@common';
import ManagerDropdown from './ManagerDropdown';

interface IProps {
    field: ApiObject.Field;
    default: any;
    errorMessage?: string | null;
    onChange: (field: ApiObject.Field, value: any) => void;
    employee: EmployeeInterfaces.Employee;
}

export default class Field extends PureComponent<IProps> {
    public render() {
        const error = this.props.errorMessage === 'Required' ?
            `${this.props.field.label} is ${this.props.errorMessage}` : this.props.errorMessage;

        const editForbidden = this.props.default && !this.props.field.allowEditIfFilled;

        return (
            <>
            {this.props.field.code === 'manager_employee_id' &&
                <ManagerDropdown
                  key={`${this.props.field.entity}_${this.props.field.code}`}
                  label={this.props.field.label}
                  mssFormat={this.props.field.defaultMssReportOutputFormat}
                  isRequired={this.props.field.required}
                  defaultValue={this.props.default}
                  onChange={this.onChange}
                  errorMessage={error}
                  disabled={editForbidden}
                  forEmployee={this.props.employee}
                />
            }
            {this.props.field.code !== 'manager_employee_id' &&
                <Default
                  key={`${this.props.field.entity}_${this.props.field.code}`}
                  type={this.props.field.type}
                  label={this.props.field.label}
                  isRequired={this.props.field.required}
                  code={this.props.field.code}
                  options={this.props.field.options}
                  defaultValue={this.props.default}
                  onChange={this.onChange}
                  errorMessage={error}
                  disabled={editForbidden}
                />
            }
            </>
        );
    }

    private onChange = (value: any) => this.props.onChange(this.props.field, value);
}
