import React, {PureComponent} from 'react';
import styled from 'styled-components';
import {stylingVariables, constants} from '@global';
import {Icon} from '@common';

export const StyledActionsWrapper = styled.div`
  position: relative;
  z-index: 2;
`;

export const StyledActionsContentWrapper = styled.div`
  flex-direction: column;
  align-items: flex-start;
  background-color: ${stylingVariables.colorPalette.white};
  padding: 20px 30px;
  box-shadow: ${stylingVariables.layout.boxShadow};
  min-width: 220px;
  border-radius: 5px;
`;

const StyledActionsContentAbsoluteWrapper = styled.div<{isOpen: boolean}>`
  display: ${props => props.isOpen ? 'flex' : 'none'};
  position: absolute;
  top: 40px;
  right: 0;
  padding: 10px;
`;

export const StyledActionsButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${stylingVariables.colorPalette.orange};
  font-size: ${stylingVariables.fontSize.default};
  font-weight: ${stylingVariables.fontWeight.regular};
  border: none;
  box-shadow: none;
  background-color: transparent;
  position: relative;
  cursor: pointer;
  white-space: nowrap;
`;

export const StyledIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: ${stylingVariables.colorPalette.orange};
  border-radius: 50%;
  margin-right: 10px;
  box-shadow: ${stylingVariables.layout.boxShadow};
`;

interface ActionsProps {
    label: string;
    onIconRender: null|(({isOpened}: {isOpened: boolean}) => any);
    toggleByHovering: boolean;
    children?: any;
}

interface ActionsState {
    isOpen: boolean;
}

export default class DropdownActions extends PureComponent<ActionsProps, ActionsState> {
    private wrapperRef: any;

    static defaultProps = {
        onIconRender: null,
        toggleByHovering: false
    }

    public constructor(props: any) {
        super(props);

        this.state = {
            isOpen: false,
        };
    }

    public componentDidMount() {
        document.addEventListener('mousedown', this.closeWhenClickOutside);
        if (this.props.toggleByHovering) {
            document.addEventListener('mouseover', this.toggleByHovering);
        }
    }

    public componentWillUnmount() {
        document.removeEventListener('mousedown', this.closeWhenClickOutside);
        if (this.props.toggleByHovering) {
            document.removeEventListener('mouseover', this.toggleByHovering);
        }
    }

    public render() {
        return (
            <StyledActionsWrapper ref={this.setWrapperRef} className={'dropdown_actions__wrapper'}>
                <StyledActionsButton onClick={this.toggle} className={'dropdown_actions__button'}>
                    <StyledIconWrapper className={'dropdown_actions__icon_wrapper'}>
                    {this.props.onIconRender
                        ? this.props.onIconRender({
                            isOpened: this.state.isOpen
                        })
                        :
                            <Icon type={this.state.isOpen ? constants.IconTypes.ARROW_UP : constants.IconTypes.ARROW_DOWN}
                                  width={10}
                                  height={10}
                                  fillColor={stylingVariables.colorPalette.white}
                            />
                    }
                    </StyledIconWrapper>

                    {this.props.label}
                </StyledActionsButton>
                <StyledActionsContentAbsoluteWrapper isOpen={this.state.isOpen} className={'dropdown_actions__absolute_wrapper'}>
                    <StyledActionsContentWrapper className={'dropdown_actions__absolute_content'}>
                        {this.props.children}
                    </StyledActionsContentWrapper>
                </StyledActionsContentAbsoluteWrapper>
            </StyledActionsWrapper>
        );
    }

    private toggle = () => {
        this.setState({isOpen: !this.state.isOpen});
    }

    public close = () => {
        this.setState({isOpen: false});
    }

    public open = () => {
        this.setState({isOpen: true});
    }

    public setWrapperRef = (node: any) => {
        this.wrapperRef = node;
    }

    public closeWhenClickOutside = (event: Event) => {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.close();
        }
    }

    public toggleByHovering = (event: Event) => {
        if (this.wrapperRef) {
            if (this.wrapperRef.contains(event.target)) {
                this.open();
            } else {
                this.close();
            }
        }
    }
}
