import {LeaveInterfaces} from '@api';

export default class LeaveSourceHelper {
    public static getTransactionType(transaction: LeaveInterfaces.Transaction, short?: boolean): string {
        const stringLength = short ? 'short' : 'full';
        if (transaction.source === 'ess') {
            return LeaveInterfaces.TransactionTypes.ess[stringLength];
        }

        return LeaveInterfaces.TransactionTypes.other[stringLength] ;
    }

    public static getTransactionTypeColor(transaction: LeaveInterfaces.Transaction): string {
        if (transaction.source === 'ess') {
            return LeaveInterfaces.TransactionTypes.ess.color;
        }

        return LeaveInterfaces.TransactionTypes.other.color;
    }

    public static getTransactionStatusColour(status: LeaveInterfaces.TransactionStatus): Object {
        return LeaveInterfaces.StatusColours[status];
    }
}
