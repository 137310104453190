import {ApiObject} from '@api';
import React, {PureComponent} from 'react';
import {interfaces} from '@global';
import Filter from './Filter';
import styled from 'styled-components';
import Order from './Order';

const StyledHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  position: relative;
`;

interface HeaderProps {
    label: string;
    dataKey?: string;
    type?: string;
    options?: ApiObject.SelectOption[];
    orders?: interfaces.ListingOrders;
    filters?: interfaces.ListingFilters;
    changeFilter?: (params: interfaces.FilterParams) => any;
    changeOrder?: (column: string) => any;
}

export default class OrderedAndFiltered extends PureComponent<HeaderProps> {
    private readonly unsupportedCodes: string[] = [
        'person.gender',
        'person.address.country',
        'person.address.city',
        'person.address.addressLine1',
        'person.address.addressLine2',
    ];

    render() {
        return (
            <StyledHeaderWrapper>
                {this.props.label}

                <StyledActionsWrapper>
                    {
                        this.props.dataKey && !this.unsupportedCodes.includes(this.props.dataKey) &&
                        <>
                            <Filter column={this.props.dataKey}
                                    type={this.props.type}
                                    label={this.props.label}
                                    options={this.props.options}
                                    filters={this.props.filters}
                                    changeFilter={this.props.changeFilter}
                            />
                            <Order column={this.props.dataKey}
                                   orders={this.props.orders}
                                   changeOrder={this.props.changeOrder}/>
                        </>
                    }
                </StyledActionsWrapper>
            </StyledHeaderWrapper>
        );
    }
}
