import {stylingVariablesGlobal as stylingVariables} from 'hrb-common';
import React, {ChangeEvent, Fragment, PureComponent} from 'react';
import styled from 'styled-components';
import {connect} from 'react-redux';

import {BorderedTextInput, Collapse, FormatNumbers, Icon, Modal} from '@common';
import {EmployeeInterfaces, LeaveInterfaces} from '@api';
import {constants, interfaces, intl} from '@global';
import Validator from 'validatorjs';
import {ModalCloseReason, ModalLocation} from './EditBalances';
import LeaveBalanceEditLog from './LeaveBalanceEditLog';
import moment from 'moment';

import {RootState} from '../../../../rootReducer';
import * as selectors from '../../../Employee/selectors';
import * as leaveSelectors from '../../../Leaves/selectors'

const Section = styled.div`
    display: flex;
    align-items: center;
    margin-top: 20px;
    color: ${stylingVariables.colorPalette.deepGray}
`;

const LabelWrapper = styled.div`
    display: flex;
    flex: 1;
    font-size: ${stylingVariables.fontSize.mediumLarge};
    font-weight: ${stylingVariables.fontWeight.regular};

    &.error {
        color: ${stylingVariables.colorPalette.red};
    }
`;

const Bubble = styled.div`
    border-radius: 50%;
    border: 2px solid ${stylingVariables.colorPalette.yellow};
    height: 54px;
    width: 54px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: ${stylingVariables.fontSize.largest};
    margin-top: 8px;
`;

const WrapColumn = styled.div`
    display: flex;
    flex-direction: column;
    font-size: ${stylingVariables.fontSize.small};
    align-items: center;
    justify-content: center;
`;

const WrapRow = styled.div`
    min-width: 110px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: ${stylingVariables.fontSize.medium};
`;

const DaysLeft = styled.div`
    margin-top: 21px;
`;

const Incrementer = styled.div`
    border: 1px solid ${stylingVariables.colorPalette.darkGray};
    border-radius: 4px;
    width: 100px;
    margin-top: 16px;
    display: flex;
    flex: 1;
    flex-direction: row;
`;

const IncrementValue = styled.div`
    flex: 1;
    align-items: center;
    justify-content: center;
    display: flex;

    input {
        width: 64px;
        text-align: center;
        font-size: ${stylingVariables.fontSize.largest};
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type=number] {
      -moz-appearance: textfield;
    }
`;

const IncrementButtons = styled.div`
    border-radius: 4px;
    background-color: ${stylingVariables.colorPalette.lightGray};
    cursor: pointer;

    div:first-child {
        border-bottom: 1px solid ${stylingVariables.colorPalette.darkGray};
    }
`;

const IncrementButton = styled.div`
    width: 30px;
    height: 19px;
    align-items: center;
    justify-content: center;
    display: flex;
`;

const ModalButtons = styled.div`
    padding: 50px 58px;
    display: flex;
    justify-content: flex-end;

    button {
        margin-left: 30px;
    }
`;

const BalanceBubbles = styled.div`
    display: flex;
    flex: 4;
    justify-content: space-around;
`;


const WarningText = styled.div`
  padding: 30px 20px !important;
  display: flex;
  justify-content: center;
  font-size: ${stylingVariables.fontSize.large};
  color: ${stylingVariables.colorPalette.red};
`;

interface IProps {
    employee: EmployeeInterfaces.Employee;
    leave: LeaveInterfaces.Balance|null;
    currentLeave: number;
    adjustment: LeaveInterfaces.BalanceAdjustment;
    storeAdjustment: (adjustment: LeaveInterfaces.BalanceAdjustment) => void;
    saveAdjustments: () => void;
    invalidAdjustments: string[];
    onClose: (closeReason?: ModalCloseReason) => void;
    modalLocation: ModalLocation;
    employeeGroups: interfaces.EmployeeGroups;
    leaveTypes: LeaveInterfaces.Type[];
}

interface IState {
    balanceAdjustment: number;
    errors: any;
    comment: string;
    invalidAdjustmentNames: string[];
    logOpen: boolean;
}

export class LeaveBalanceEdit extends PureComponent<IProps, IState> {
    private readonly validationRules = {
        comment: 'required',
        balanceAdjustment: 'required|not_in:0',
    };

    public constructor(props: IProps) {
        super(props);
        this.state = {
            balanceAdjustment: 0,
            comment: '',
            errors: {},
            invalidAdjustmentNames: [],
            logOpen: false,
        };
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        if (prevProps.currentLeave !== this.props.currentLeave) {
            this.setState({
                balanceAdjustment: this.props.adjustment?.balanceAdjustment || 0,
                comment: this.props.adjustment?.comment || '',
                errors: {},
                invalidAdjustmentNames: [],
            });
        }
    }

    private get masterdataEditAllowed(): boolean {
        const {employeeGroups} = this.props
        const employeeGroupId = this.props.employee?.employeeGroupId || null
        const matchingGroup = employeeGroupId && employeeGroups ? Object.values(employeeGroups).find((group: any) => {
            return group.id === employeeGroupId}) : false

        const allowed = matchingGroup ? matchingGroup.masterdataEditAllowed : true
        return allowed
    }

    private setReason = (event: ChangeEvent<HTMLTextAreaElement>) => {
        this.setState({ comment: event.target.value });
        this.props.storeAdjustment({
            ...this.props.adjustment,
            comment: event.target.value,
        });
    }

    private changeBalance = (changeValue: string) => {
        const adjustmentValue = changeValue ? parseFloat(changeValue) : 0;
        this.setState({ balanceAdjustment: adjustmentValue });
        this.props.storeAdjustment({
            ...this.props.adjustment,
            balanceAdjustment: adjustmentValue,
        });
    }

    private increase = () => {
        this.setState({ balanceAdjustment: this.state.balanceAdjustment + 1 });
        this.props.storeAdjustment({
            ...this.props.adjustment,
            balanceAdjustment: this.state.balanceAdjustment + 1,
        });
    }

    private decrease = () => {
        this.setState({ balanceAdjustment: this.state.balanceAdjustment - 1 });
        this.props.storeAdjustment({
            ...this.props.adjustment,
            balanceAdjustment: this.state.balanceAdjustment - 1,
        });
    }

    private formValidator = () => {
        return new Validator(this.state, this.validationRules);
    }

    private save = () => {
        const formValidator = this.formValidator();
        let failValidation = false;
        const errorState: any = {
            errors: {},
            invalidAdjustmentNames: [],
        };

        if (!formValidator.passes()) {
            errorState.errors = formValidator.errors.errors;
            failValidation = true;
        }

        if (this.props.invalidAdjustments.length > 0) {
            errorState.invalidAdjustmentNames = this.props.invalidAdjustments;
            failValidation = true;
        }

        this.setState(errorState);
        if (failValidation) {
            return;
        }

        this.props.saveAdjustments();
        this.props.onClose(ModalCloseReason.SaveAction);
    }

    private amountType() {
        const leaveType = this.props.leaveTypes.find(lt => lt.id === this.props.currentLeave)
        const amountType = leaveType?.amountType === 'hours' ? 'hours' : 'days'

        return amountType
    }

    private renderNewBalance(newBalance: number) {
        return (
            <WrapRow>
                <WrapColumn>
                    {intl.get('new_balance')}
                    <Bubble>
                        <FormatNumbers showZero={true} value={newBalance}/>
                    </Bubble>
                </WrapColumn>
                <DaysLeft style={{marginLeft: '10px'}}>
                    {intl.get(`${this.amountType()}_left`)}
                </DaysLeft>
            </WrapRow>
        );
    }

    private logEntriesToArray = () => {
        let logEntries: any[] = [];
        if (!this.props.adjustment?.log) {
            return [];
        }

        let relativeBalance = 0;
        this.props.adjustment.log.forEach(item => {
            const lastPeriodBalance = relativeBalance;
            relativeBalance = item.startingYtdBalance;
            logEntries = logEntries.concat(item.entries.map((entry, idx) => {
                const initPeriod = idx === 0;
                if (!initPeriod) {
                    if (entry.type === 'transaction') {
                        relativeBalance -= entry.amount;
                    } else {
                        relativeBalance += entry.amount;
                    }
                }
                return {
                    ...entry,
                    createTime : entry.createTime || entry.date,
                    relativeBalance,
                    lastPeriodBalance,
                    periodInitial: initPeriod,
                    leaveRegulationId: item.leaveRegulationId,
                    startingYtdBalance: item.startingYtdBalance,
                    startingYtdTaken: item.startingYtdTaken,
                    periodName: item.periodName,
                };
            }));
        });

        return logEntries.sort((a: { createTime: string; }, b: { createTime: string; }) => {
            return (moment(a.createTime, 'YYYY-MM-DD')
                .isBefore(moment(b.createTime, 'YYYY-MM-DD'))) ? 1 : -1;
        });
    }

    render() {
        const balance = this.props.leave && this.props.leave.ytdBalance ? this.props.leave.ytdBalance : 0;
        const newBalance = balance + this.state.balanceAdjustment;
        const amountType = this.amountType()
        return (
            <Fragment>
                {this.state.invalidAdjustmentNames.length > 0 && <Section>
                    <LabelWrapper className={'error'}>
                        {intl.get('invalid_adjustments_present', {leaveTypes: this.state.invalidAdjustmentNames.join(', ')}) }
                    </LabelWrapper>
                </Section>}
                <Section>
                    <LabelWrapper className={this.state.errors.balanceAdjustment ? 'error' : ''}>
                        <DaysLeft>
                            {intl.get('edit_balance')}*
                        </DaysLeft>
                    </LabelWrapper>
                    <BalanceBubbles>
                        <WrapRow>
                            <WrapColumn>
                                {intl.get('current_balance')}
                                <Bubble>
                                    <FormatNumbers showZero={true} value={balance}/>
                                </Bubble>
                            </WrapColumn>
                            <DaysLeft>
                                {intl.get(`${amountType}_left`)}
                            </DaysLeft>
                        </WrapRow>
                        <WrapColumn>
                            {intl.get('add_or_remove_days')}
                            <Incrementer>
                                <IncrementValue>
                                    <input
                                       type={'number'}
                                       step={1}
                                       value={this.state.balanceAdjustment || ''}
                                       onChange={(event: any) => this.changeBalance(event.target.value)}
                                    />
                                </IncrementValue>
                                <IncrementButtons>
                                    <IncrementButton onClick={this.increase}>
                                        <Icon type={constants.IconTypes.ARROW_UP}
                                            width={10}
                                            height={10}
                                            fillColor={stylingVariables.colorPalette.deepGray}
                                        />
                                    </IncrementButton>
                                    <IncrementButton onClick={this.decrease}>
                                        <Icon type={constants.IconTypes.ARROW_DOWN}
                                            width={10}
                                            height={10}
                                            fillColor={stylingVariables.colorPalette.deepGray}
                                        />
                                    </IncrementButton>
                                </IncrementButtons>
                            </Incrementer>
                        </WrapColumn>
                        { this.state.balanceAdjustment !== 0 ?
                            this.renderNewBalance(newBalance) : <WrapRow/>
                        }
                    </BalanceBubbles>
                </Section>
                <Section>
                    <LabelWrapper className={this.state.errors.comment ? 'error' : ''}>
                        {intl.get('reason')}*
                    </LabelWrapper>
                    <BorderedTextInput
                        placeholder={intl.get('leave_a_comment_reason')}
                        onChange={this.setReason}
                        value={this.state.comment}
                        height={'80px'}
                        flex={4}
                    />
                </Section>

                {this.masterdataEditAllowed ? (
                    <ModalButtons className='modalButtons'>
                        <Modal.StyledWhiteButton onClick={() => this.props.onClose(ModalCloseReason.ModalCloseAction)}>
                            {intl.get('cancel')}
                        </Modal.StyledWhiteButton>

                        <Modal.StyledRejectButton onClick={this.save}>
                            {intl.get('save')}
                        </Modal.StyledRejectButton>
                    </ModalButtons>
                ) : (
                    <WarningText>
                        {intl.get('masterdata_edit_not_allowed')}
                    </WarningText>
                )}


                {this.props.modalLocation === ModalLocation.LeavesView &&
                    <Collapse label={intl.get('edit_log')} isOpen={this.state.logOpen || false}>
                      <LeaveBalanceEditLog entries={this.logEntriesToArray()} />
                    </Collapse>
                }
            </Fragment>
        );
    }
}

const mapStateToProps = (state: RootState) => ({
    employeeGroups: selectors.getEmployeeGroups(state),
    leaveTypes: leaveSelectors.getLeaveTypesWithBalance(state, null),
});


const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(LeaveBalanceEdit);
