export default class ArrayHelper {
    public static replaceObjectByKey(elements: any[], key: string, object: any, orAdd: boolean = true) {
        const index = elements.findIndex((e) => e[key] === object[key]);
        if (index !== -1) {
            elements.splice(index, 1, object);
        } else {
            if (orAdd) {
                elements.push(object);
            }
        }

        return elements;
    }

    public static removeObjectByKey(elements: any[], key: string, value: any) {
        const index = elements.findIndex((e) => e[key] === value);
        if (index !== -1) {
            elements.splice(index, 1);
        }

        return elements;
    }

    public static toggleElement(elements: any[], item: any) {
        const index = elements.findIndex(e => e === item);
        if (index === -1) {
            elements.push(item);
        } else {
            elements.splice(index, 1);
        }

        return elements;
    }

    public static notUndefined = <T>(x: T | undefined): x is T => x !== undefined;
}
