import React, {PureComponent} from 'react';
import styled from 'styled-components';

import {stylingVariables} from '@global';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-top: 15px;
`;

const Note = styled.div`
  padding: 35px 20px;
  border: 1px solid ${stylingVariables.colorPalette.blue};
  border-radius: 6px;
  position: relative;
  width: 300px;
  display: flex;
  justify-content: center;
  font-size: ${stylingVariables.fontSize.mediumLarge};
  font-style: italic;
  opacity: .7;
  &:before {
    content: '';
    position: absolute;
    top: -18px;
    left: calc(150px - 18px);
    height: 28px;
    width: 28px;
    background-color: rgb(145 187 242);
    border-radius: 50%;
    border: 4px solid ${stylingVariables.colorPalette.gray};
    box-sizing: content-box;
  }
  &:after {
    content: '!';
    font-style: normal;
    position: absolute;
    color: #fff;
    top: -10px;
    left: calc(150px - 10px);
    height: 20px;
    width: 20px;
    background-color: ${stylingVariables.colorPalette.violet};
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 600;
  }
`;

interface IProps {
    text: string;
}

export default class NoRowsNote extends PureComponent<IProps> {
    render() {
        return (
            <Wrapper>
                <Note>
                    {this.props.text}
                </Note>
            </Wrapper>
        );
    }
}
