import { ApiObject, EmployeeInterfaces } from "@api";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import get from 'lodash/get'
import orderBy from 'lodash/orderBy'

import { RootState } from "../../../../../rootReducer";
import { isLoading } from "../../../../selectors";
import { Modal, EditableField } from "@common";
import { intl, stylingVariables } from "@global";
import {
  getFieldGroupsForTerminationPayout,
  getValuesForTerminationPayout,
  getSeparationErrors,
} from "../../../selectors";
import {
  getTerminationPayoutFieldGroups,
  getTerminationPayoutValues,
  clearSeparation,
  saveTerminationPayout,
} from "../../../redux";
import styled from "styled-components";

interface TerminationPayoutProps {
  forEmployeeIds: Set<number>;
  onClickClose: () => void;
  isLoading: boolean;
  fieldGroups: any;
  values: any;
  errors: any;
  getTerminationPayoutFieldGroups: () => void;
  getTerminationPayoutValues: (ids: Set<number>) => void;
  saveTerminationPayout: (values: [], ids: Set<number>) => void;
  clearSeparation: () => void;
  employee: EmployeeInterfaces.Employee;
}

interface TerminationPayoutState {
  loaded: boolean;
  updatedValues: [];
}

const StyledFieldsGroupHeader = styled.h4`
  color: ${stylingVariables.colorPalette.darkest};
  font-size: 14px;
  font-weight: ${stylingVariables.fontWeight.bold};
  padding: 0;
`;
const StyledCentredText = styled.h4`
  text-align: center;
`;

const SpinnerIcon = styled.div`
  margin: 50px auto;
  width: 30px;
  height: 30px;
  box-sizing: border-box;

  border: solid 4px transparent;
  border-top-color: ${stylingVariables.colorPalette.green};
  border-left-color: ${stylingVariables.colorPalette.green};
  border-radius: 50%;

  -webkit-animation: nprogress-spinner 600ms linear infinite;
  animation: nprogress-spinner 600ms linear infinite;
`;

interface TerminationPayoutEntry {
  payElement: ApiObject.PayElement;
  currency?: string;
  amount?: string;
  entity: string;
  entityId: string;
}

export class TerminationPayout extends PureComponent<
  TerminationPayoutProps,
  TerminationPayoutState
> {
  public constructor(props: any) {
    super(props);

    this.state = {
      loaded: false,
      updatedValues: [],
    };

    this.getFormData();
  }

  private getFormData = async () => {
    this.props.clearSeparation();
    await this.props.getTerminationPayoutFieldGroups();
    await this.props.getTerminationPayoutValues(this.props.forEmployeeIds);
    this.setState({ loaded: true });
  };

  private mapTerminationValues = () => {
    let output: TerminationPayoutEntry[] = []
    const terminationFieldEntities: EmployeeInterfaces.TerminationFieldEntity[] = this.props.values.terminationFieldEntities || []
    const terminationPayElements: { [code: string]: ApiObject.PayElement } = this.props.values.terminationPayElements || {}
    
    terminationFieldEntities.forEach(entry => {
      const accountCode = entry.values.find(v => v.code === 'account')?.value || null
      const currency = entry.values.find(v => v.code === 'currency')?.value || ''
      const amount = entry.values.find(v => v.code === 'amount')?.value || '0'
      if (accountCode) {
        const payElement: ApiObject.PayElement = terminationPayElements[Number(accountCode)]
  
        output.push(
          {
            payElement,
            currency,
            amount,
            entity: entry.entity,
            entityId: entry.entityId,
          }
        )
      }
    });

    return orderBy(output, 'payElement.account')
  } 

  render() {
    const hasError = typeof this.props.values === "undefined";

    const terminationPayoutValues = this.mapTerminationValues()

    return (
      <Modal.CenterModal onClickClose={this.props.onClickClose}>
        <Modal.StyledHeader>
          <h4>{`${this.props.employee.referenceCode} ${this.props.employee.person.firstName} ${this.props.employee.person.lastName}`}</h4>
        </Modal.StyledHeader>

        <Modal.OverflowableContent padding={"20px 40px"}>
          {this.props.fieldGroups && (
            <StyledFieldsGroupHeader>
              {this.props.fieldGroups.name}
            </StyledFieldsGroupHeader>
          )}
          {this.state.loaded &&
            !!terminationPayoutValues &&
            !this.props.isLoading &&
            terminationPayoutValues.map((value: TerminationPayoutEntry) => {
              const label = `${value.payElement.account} - ${value.payElement.name}`
              return (
                <EditableField
                  key={value.payElement.account}
                  label={label}
                  code={this.props.fieldGroups.code}
                  type={ApiObject.FieldType.number}
                  isRequired={true}
                  errorMessage={get(
                    this.props.errors,
                    `${value.entity}.${"amount"}`
                  )}
                  defaultValue={value.amount}
                  afterText={value.currency}
                  onChange={(v: any) => {
                    this.updateField(Number(value.entityId), v);
                  }}
                />
              );
            })}
          {!hasError &&
            this.state.loaded &&
            (!terminationPayoutValues || !terminationPayoutValues.length) && (
              <StyledCentredText>
                {intl.get("separation_no_pay_elements")}
              </StyledCentredText>
            )}
          {hasError && (
            <StyledCentredText>
              {intl.get("pay_element_has_error")}
            </StyledCentredText>
          )}

          {(!this.state.loaded || this.props.isLoading) && <SpinnerIcon />}
        </Modal.OverflowableContent>

        <Modal.StyledActionBar>
          <Modal.StyledCancelButton onClick={this.close}>
            {intl.get("cancel")}
          </Modal.StyledCancelButton>

          {this.state.loaded && !terminationPayoutValues && (
            <Modal.StyledOkButton onClick={this.close}>
              {intl.get("OK")}
            </Modal.StyledOkButton>
          )}
          
          {this.state.loaded &&
            !!terminationPayoutValues && (
              <Modal.StyledOkButton
                onClick={this.save}
                isDisabled={this.props.isLoading}
              >
                {intl.get("save")}
              </Modal.StyledOkButton>
            )}
        </Modal.StyledActionBar>
      </Modal.CenterModal>
    );
  }

  private updateField = (entityId: number, value: any) => {
    const updatedValues: any = this.state.updatedValues;
    updatedValues[entityId] = value;
    this.setState({ updatedValues: updatedValues });
  };

  private close = () => {
    this.props.clearSeparation();
    this.props.onClickClose();
  };

  private save = async () => {
    await this.props.saveTerminationPayout(
      this.state.updatedValues,
      this.props.forEmployeeIds
    );
    if (!Object.keys(this.props.errors).length) {
      this.props.onClickClose();
    }
  };
}

const mapStateToProps = (state: RootState) => ({
  isLoading: isLoading(state),
  fieldGroups: getFieldGroupsForTerminationPayout(state),
  values: getValuesForTerminationPayout(state),
  errors: getSeparationErrors(state),
});

const mapDispatchToProps = {
  getTerminationPayoutFieldGroups,
  getTerminationPayoutValues,
  saveTerminationPayout,
  clearSeparation,
};

export default connect(mapStateToProps, mapDispatchToProps)(TerminationPayout);
