import React, {PureComponent} from 'react';
import {stylingVariables, intl} from '@global';

import {ApiEmployee, ApiObject, EmployeeInterfaces} from '@api';
import {Dropdown} from '@common';
import { debounce } from 'lodash';
import styled from 'styled-components';
import {RootState} from '../../../../../rootReducer';
import * as globalSelectors from '../../../../selectors';
import {connect} from 'react-redux';

interface ManagerDropdownProps {
    employee?: EmployeeInterfaces.ConsolidationEmployee|null;
    key?: string;
    label?: string;
    isRequired?: boolean;
    defaultValue?: string;
    errorMessage?: string | null;
    mssFormat?: string | null;
    disabled: boolean;
    forEmployee: EmployeeInterfaces.Employee;

    onChange: (employeeId: number | null) => any;
    currentLegalEntityId: number | null;
    currentConsolidationGroup: any;
    errors?: string[];
}

interface ManagerDropdownState {
    listEmployees: EmployeeInterfaces.ConsolidationEmployee[];
    useLoadMoreEmployees: boolean;
    queryEmployee: string;
    employeePointer?: string | null;
}

const StyledFieldRow = styled.div`
    margin: 8px 0;
    font-size: ${stylingVariables.fontSize.default};
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    label{
        color: ${stylingVariables.colorPalette.deepGray};
        line-height: 25px;
        display: inline-block;
        margin: 5px 0;
        flex-basis: 200px;
    }
    `;

const StyledInputWrapper = styled.div<{ hasError: boolean, disabled: boolean }>`
    >input {
        padding: 12px 20px;
        border: 1px solid ${stylingVariables.colorPalette.darkestGray};
        border-radius: ${stylingVariables.layout.inputBorderRadius};
        font-size: ${stylingVariables.fontSize.default};
        font-weight: ${stylingVariables.fontWeight.semibold};
        color: ${stylingVariables.colorPalette.dark};
        width: 100%;

        &:hover {
            border: 1px solid ${stylingVariables.colorPalette.green};
        }

        &:focus {
            box-shadow: 1px 1px 5px 3px #C2EBEB;
            border: 1px solid ${stylingVariables.colorPalette.green};
        }

    }
    ${props => props.hasError && `
        >input {
            border: 1px solid ${stylingVariables.colorPalette.red};
        }
    `}

    ${props => props.disabled && `
        >input {
            cursor: cross;
            background-color: ${stylingVariables.colorPalette.gray};
        }
    `}

    display: inline-block;
    flex-basis: 200px;
    flex-grow: 2;
`;

const StyledFieldError = styled.div`
    margin-left: 200px;
    padding: 5px 0;
    color: ${stylingVariables.colorPalette.red};
`;

export class ManagerDropdown extends PureComponent<ManagerDropdownProps, ManagerDropdownState> {
    static defaultProps = {
        disabled: false,
    };
    public constructor(props: ManagerDropdownProps) {
        super(props);

        this.state = {
            listEmployees: [],
            useLoadMoreEmployees: true,
            employeePointer: null,
            queryEmployee: '',
        };

        if (!props.defaultValue) {
            this.onEmployeeSearch('');
        }
    }

    async componentDidMount() {
        if (this.props.currentLegalEntityId && this.props.defaultValue) {
            const employeeData =  await ApiEmployee.findConsolidationGroupEmployeeById(
                this.props.currentLegalEntityId,
                parseInt(this.props.defaultValue),
            ) || [];
            let employees: any;
            employees = new Set(employeeData);
            this.setState(() => ({
                listEmployees: [...employees],
                useLoadMoreEmployees: false,
            }));
        }
    }

    private get listEmployees(): ApiObject.SelectOption[] {
        return this.state.listEmployees.map((employee: EmployeeInterfaces.ConsolidationEmployee) => {
            return {
                key: `${employee.id}`,
                label: this.formatEmployee(employee),
                subLabel: employee.email,
            };
        });
    }

    private formatEmployee = (employee: any): string => {
        switch (this.props.mssFormat) {
            case ApiObject.MssReportOutputFormat.key:
                return `${employee.referenceCode} (${employee.legalEntityName})`;
            case ApiObject.MssReportOutputFormat.value:
                return `${[employee.firstName || employee.person?.firstName, ' ',
                    employee.lastName || employee.person?.lastName].join('').trim()} (${employee.legalEntityName})`;
            default:
                return `${employee.referenceCode} - ${[employee.firstName || employee.person?.firstName, ' ',
                    employee.lastName || employee.person?.lastName].join('').trim()} (${employee.legalEntityName})`;
        }
    }

    private onLoadMoreEmployees = () => {
        this.onEmployeeSearch(this.state.queryEmployee, true)
    }

    private onEmployeeSearch = async(value: string, loadMore: boolean = false) => {
        const LIMIT = 20;

        const employeeResponse = (this.props.currentLegalEntityId)
            ? await ApiEmployee.findConsolidationGroupEmployees(
                this.props.currentLegalEntityId,
                value,
                LIMIT,
                loadMore ? this.state.employeePointer : null,
                {
                    queries: [
                        {
                            field: 'id',
                            entity: 'employee',
                            operator: 'nin',
                            value: `${this.props.forEmployee.id}`,
                        },
                    ],
                })
            : {};

        const listEmployees = employeeResponse.content || [];
        let employees: any;
        if (loadMore) {
            employees = new Set([...this.state.listEmployees, ...listEmployees]);
        } else {
            employees = new Set(listEmployees);
        }
        this.setState(() => ({
            queryEmployee: value,
            listEmployees: [...employees],
            employeePointer: employeeResponse.nextPointer,
            useLoadMoreEmployees: listEmployees.length === LIMIT,
        }));

        this.props.onChange(null);
    }

    private debouncedSearch = debounce(this.onEmployeeSearch, 300);

    private onChangeEmployeeId = (employeeId: string): void => {
        this.props.onChange(+employeeId);
    }

    private renderRowValue = () => {
        if (this.props.employee) {
            return (
                <>{this.formatEmployee(this.props.employee)}</>
            );
        }

        return (
            <Dropdown
                externalFilter={true}
                onFilter={this.debouncedSearch}
                useLoadMore={this.state.useLoadMoreEmployees}
                onLoadMore={this.onLoadMoreEmployees}
                onChange={this.onChangeEmployeeId}
                list={this.listEmployees}
                searchValue={this.state.queryEmployee}
                selectedValue={this.props.defaultValue}
                placeholder={intl.get('start_typing_to_display_employees')}
            />
        );
    }

    public render() {
        return (
            <StyledFieldRow>
                <label>
                    {this.props.label} {this.props.isRequired && '*'}
                </label>

                <StyledInputWrapper hasError={!!this.props.errorMessage} disabled={this.props.disabled}>
                    {this.renderRowValue()}
                </StyledInputWrapper>

                {
                    this.props.errorMessage &&
                    <StyledFieldError>{this.props.errorMessage}</StyledFieldError>
                }
            </StyledFieldRow>
        );
    }
}
const mapStateToProps = (state: RootState) => ({
    currentLegalEntityId: globalSelectors.getCurrentLegalEntityId(state),
    currentConsolidationGroup: globalSelectors.currentConsolidationGroupId(state),
});

export default connect(mapStateToProps)(ManagerDropdown);
