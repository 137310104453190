import {KeyboardHelper} from '@library';
import React, {PureComponent} from 'react';
import {Icon} from '@common';
import {constants, stylingVariables} from '@global';
import styled from 'styled-components';

const Wrapper = styled.div<{isExpanded: boolean, hasValue: boolean, customStyles: ICustomStyles}>`
  position: relative;
  height: ${props => props.customStyles.closedHeight};
  width: ${props => props.customStyles.closedHeight};
  border-radius: 20px;
  border: 1px solid ${stylingVariables.colorPalette.secondary};
  background-color: ${stylingVariables.colorPalette.secondary};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;

  input {
    height: 38px;
    display: none;
    width: 100%;
    border: none;
    background-color: ${stylingVariables.colorPalette.secondary};
    transition: background-color .3s;

    &::-webkit-input-placeholder {
        font-style: italic;
        font-family: ${stylingVariables.hrb.fontFamily.sourceSansPro};
        color: ${stylingVariables.colorPalette.darkest};
        font-size: ${stylingVariables.fontSize.default};
    }
    &::-moz-placeholder {
        font-style: italic;
        font-family: ${stylingVariables.hrb.fontFamily.sourceSansPro};
        color: ${stylingVariables.colorPalette.darkest};
        font-size: ${stylingVariables.fontSize.default};
    }
    &:-ms-input-placeholder {
        font-style: italic;
        font-family: ${stylingVariables.hrb.fontFamily.sourceSansPro};
        color: ${stylingVariables.colorPalette.darkest};
        font-size: ${stylingVariables.fontSize.default};
    }
    &:-moz-placeholder {
        font-style: italic;
        font-family: ${stylingVariables.hrb.fontFamily.sourceSansPro};
        color: ${stylingVariables.colorPalette.darkest};
        font-size: ${stylingVariables.fontSize.default};
    }
  }

  transition: width .3s, height .3s;

  ${props => !props.isExpanded && `
        &:hover {
            svg {
                transition: all 0.3s;
                transform: scale(1.05);
            }
        }
  `};

  ${props => props.isExpanded && `
    overflow: hidden;
    cursor: auto;
    width: 250px;
    height: 40px;
    padding: 0 4px 0 15px;
    background-color: ${stylingVariables.colorPalette.white};
    transition: width .3s, height .3s;
    input {
        display: block;
        background-color: ${stylingVariables.colorPalette.white};
    }
  `};

  ${props => !props.isExpanded && props.hasValue && `
     &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        height: 10px;
        width: 10px;
        border-radius: 50%;
        background-color: ${stylingVariables.colorPalette.primary};
     }
  `}
`;

const IconClose = styled.div<{size: number}>`
  background-color: ${stylingVariables.colorPalette.secondary};
  height: ${props => props.size}px;
  width: ${props => props.size}px;
  border-radius: 50%;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  transform: rotate(45deg);
`;

const IconSearch = styled.div``;

export enum ITheme {
    standard = 'standard',
    minimal = 'minimal',
}

interface ICustomStyles {
    closedHeight: string|number;
    closedIconSize: number;
    expandedIconSize: number;
}

interface IProps {
    placeholder?: string;
    autoFocus?: boolean;
    value: string;
    onChange: (value: any) => void;
    theme: ITheme;
}

interface IState {
    isExpanded: boolean;
}

class FoldingSearchInput extends PureComponent<IProps, IState> {
    public static defaultProps = {
        theme: ITheme.standard,
    };

    private wrapperRef: any;
    private _input: any;
    constructor(props: IProps) {
        super(props);

        this.state = {
            isExpanded: false,
        };
    }

    get customStyles(): ICustomStyles {
        switch (this.props.theme) {
            case ITheme.minimal:
                return {
                    closedHeight: '30px',
                    closedIconSize: 14,
                    expandedIconSize: 30,
                };
            default:
                return {
                    closedHeight: '40px',
                    closedIconSize: 18,
                    expandedIconSize: 20,
                };

        }
    }

    public componentDidMount() {
        document.addEventListener('mousedown', this.closeWhenClickOutside);
    }

    public componentWillUnmount() {
        document.removeEventListener('mousedown', this.closeWhenClickOutside);
    }

    componentDidUpdate(prevProps: IProps, prevState: IState) {
        if (this._input && !prevState.isExpanded && this.state.isExpanded && this.props.autoFocus) {
            this._input.focus();
        }
    }

    render() {
        const {isExpanded} = this.state;
        const {value} = this.props;

        const customStyles: ICustomStyles = this.customStyles;

        return (
            <Wrapper
                ref={_ref => this.wrapperRef = _ref}
                isExpanded={isExpanded}
                hasValue={!!this.props.value}
                onClick={isExpanded ? () => {} : this.open}
                customStyles={customStyles}
            >
                <input type='text'
                       ref={_ref => this._input = _ref}
                       autoFocus={this.props.autoFocus}
                       placeholder={this.props.placeholder}
                       onChange={this.onChange}
                       value={value}
                       onKeyPress={KeyboardHelper.handleEnterPressEvent(this.close)}
                       onKeyDown={KeyboardHelper.handleEscDownEvent(this.close)}
                />

                {this.state.isExpanded
                    ?  <IconClose onClick={this.close} size={customStyles.expandedIconSize}>
                           <Icon type={constants.IconTypes.BOLD_ADD}
                                 width={Math.round(customStyles.expandedIconSize / 2.5)}
                                 height={Math.round(customStyles.expandedIconSize / 2.5)}
                                 fillColor={stylingVariables.colorPalette.white}
                           />
                       </IconClose>
                    : <IconSearch>
                        <Icon type={constants.IconTypes.SEARCH}
                              width={customStyles.closedIconSize}
                              height={customStyles.closedIconSize}
                              fillColor={stylingVariables.colorPalette.white}
                        />
                    </IconSearch>
                }
            </Wrapper>
        );
    }

    private onChange = (e: any) => {
        this.props.onChange(e.target.value);
    }

    private open = () => {
        this.setState({isExpanded: true});
    }

    private close = () => {
        this.props.onChange('');
        this.setState({isExpanded: false});
    }

    public closeWhenClickOutside = (event: Event) => {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.setState({isExpanded: false});
        }
    }
}

export default FoldingSearchInput;
