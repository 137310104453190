import React, {PureComponent} from 'react';
import {intl} from '@global';

import {stylingVariables} from '@global';
import {Input, Layout} from '@common';

interface IProps {
    defaultValue?: string;
    onChange: (name: string) => any;
    errors?: string[];
}

export default class DisplayNameRow extends PureComponent<IProps> {
    public render() {
        return (
            <Layout.Row>
                <Layout.RowLabel percent={30} top={14} color={this.props.errors ? stylingVariables.colorPalette.red : undefined}>
                    {intl.get('template_name')}*
                </Layout.RowLabel>
                <Layout.RowValue percent={70}>
                    <Input label={''} onChange={(event: any) => this.props.onChange(event.target.value)} defaultValue={this.props.defaultValue} />
                </Layout.RowValue>
            </Layout.Row>
        );
    }
}
