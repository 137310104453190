import {ApiObject} from '@api';
import {RouteHelper} from '@library';
import {ToggleCheckbox} from 'hrb-common';
import React, {PureComponent} from 'react';
import {intl} from '@global';
import {Modal as CommonModal, Layout} from '@common';
import styled from 'styled-components';

import {stylingVariables, constants} from '@global';
import {RootState} from '../../../../../rootReducer'
import {isTemplateProcesses} from '../../../utils';
import {createTemplate} from '../../redux';
import * as selectors from '../../selectors';
import NameRow from './NameRow';
import {connect} from 'react-redux';

const ActionBar = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 25px 70px 0 0;
    
    button {
        margin-left: 30px;
    }
`;

interface IProps {
    onClose: () => void;
    template: ApiObject.ReportTemplate;
    createTemplate: (template: ApiObject.ReportTemplate) => any;
    processMode: constants.ReportProcessMode;
}

interface IState {
    name: string;
    visibility: ApiObject.ReportVisibility;
    hasError: boolean;
    isSaving: boolean;
}

export class SaveTemplateModal extends PureComponent<IProps, IState> {
    public constructor(props: IProps) {
        super(props);
        
        this.state = {
            name: props.template.label,
            visibility: props.template.visibility || ApiObject.ReportVisibility.public,
            hasError: false,
            isSaving: false
        };
    }

    render() {
        if (!this.props.template) {
            return null;
        }

        return (
            <CommonModal.CenterModal width={'750px'} minWidth={'750px'} onClickClose={this.props.onClose}>
                <CommonModal.StyledHeader
                    style={{padding: '20px 80px'}}
                    color={stylingVariables.colorPalette.darkest}
                >
                    <h4>{intl.get('save_as_a_template')}</h4>

                    <ToggleCheckbox
                        isChecked={this.state.visibility === ApiObject.ReportVisibility.public}
                        labelOptions={{yes: intl.get('common'), no: intl.get('personal')}}
                        onChange={(bool: boolean) => {
                            this.onChangeVisibility(
                                bool ? ApiObject.ReportVisibility.public : ApiObject.ReportVisibility.private
                            )
                        }} />
                </CommonModal.StyledHeader>

                <CommonModal.OverflowableContent style={{padding: '20px 80px'}}>
                    <Layout.Rows>
                        <NameRow
                            defaultValue={this.props.template.label}
                            onChange={this.onChangeName}
                            errors={this.state.hasError ? [intl.get('field_cannot_be_empty')] : undefined}
                        />
                    </Layout.Rows>
                </CommonModal.OverflowableContent>

                <ActionBar>
                    <CommonModal.StyledCancelButton onClick={this.props.onClose}>
                        {intl.get('cancel')}
                    </CommonModal.StyledCancelButton>

                    <CommonModal.StyledOkButton isDisabled={this.state.isSaving} onClick={this.onSave}>
                        {intl.get('save')}
                    </CommonModal.StyledOkButton>

                </ActionBar>
            </CommonModal.CenterModal>
        );
    }

    private isValid = () => {
        const isOk = !!this.state.name
        this.setState({
            hasError: !isOk
        })

        return isOk;
    }

    private onSave = async () => {
        if (!this.props.template) {
            return null;
        }
        if (this.isValid()) {
            this.setState({isSaving: true});
            await this.props.createTemplate({
                ...this.props.template,
                label: this.state.name,
                visibility: this.state.visibility
            })
            this.props.onClose();

            if (isTemplateProcesses(this.props.processMode)) {
                RouteHelper.goToReportTemplates();
            }
            this.setState({isSaving: false});
        }
    }

    private onChangeName = (name: string) => this.setState({name});
    private onChangeVisibility = (visibility: ApiObject.ReportVisibility) => this.setState({visibility});
}

const mapStateToProps = (state: RootState) => ({
    processMode: selectors.getMode(state),
    template: selectors.getTemplate(state) as ApiObject.ReportTemplate
});

const mapDispatchToProps = {
    createTemplate
};

export default connect(mapStateToProps, mapDispatchToProps)(SaveTemplateModal);
