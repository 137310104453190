import React, {PureComponent} from 'react';
import styled from 'styled-components';
import {Moment} from 'moment';

import {stylingVariables, constants} from '@global';
import {Icon, BlankButton} from '@common';
import {DateHelper} from '@library';

import DateRangeCalendarContainer from './DateRangeCalendarContainer';

const StyledDateRangePickerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 2;
`;

const StyledPreviewWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 30px;
  min-width: 180px;
  border: 1px solid ${stylingVariables.colorPalette.green};
  padding: 0 10px;
  cursor: pointer;
  background-color: ${stylingVariables.colorPalette.white};
  span {
    margin-right: 5px;
    font-weight: ${stylingVariables.fontWeight.semibold};
    font-size: ${stylingVariables.fontSize.mediumSmall};
  }
  svg {
    margin-left: 15px;
  }
`;

const PrevButton = styled(props => <BlankButton {...props} />)`
    width: 30px;
    border: 1px solid ${stylingVariables.colorPalette.green};
    height: 30px;
    background-color: ${stylingVariables.colorPalette.white};
    border-right: 0;
    border-radius: 50% 0 0 50%;

    &:disabled{
        background-color: ${stylingVariables.colorPalette.darkGray};
    }
`;

const NextButton = styled(props => <BlankButton {...props} />)`
    width: 30px;
    border: 1px solid ${stylingVariables.colorPalette.green};
    height: 30px;
    background-color: ${stylingVariables.colorPalette.white};
    border-left: 0;
    border-radius:  0 50% 50% 0;

    &:disabled{
        background-color: ${stylingVariables.colorPalette.darkGray};
    }
`;

interface DateRangePickerProps {
    from: Moment;
    till: Moment;
    fidelity: DateRangePickerFidelity;
    minValue?: number;

    onApply: (from: Moment, till: Moment) => void;
}

interface DateRangePickerState {
    isOpen: boolean;
}

export enum DateRangePickerFidelity {
    dayToDayRange,
    onlyYear,
}

export default class DateRangePicker extends PureComponent<DateRangePickerProps, DateRangePickerState> {
    private wrapperRef: any;
    static defaultProps = {
        fidelity: DateRangePickerFidelity.dayToDayRange,
    };

    public constructor(props: DateRangePickerProps) {
        super(props);

        this.state = {
            isOpen: false,
        };
    }

    public componentDidMount() {
        document.addEventListener('mousedown', this.closeWhenClickOutside);
    }

    public componentWillUnmount() {
        document.removeEventListener('mousedown', this.closeWhenClickOutside);
    }

    public render() {
        const period = this.getMomentPeriod();

        return (
            <StyledDateRangePickerWrapper ref={(node: any) => this.wrapperRef = node}>
                <PrevButton
                    disabled={this.renderDateField() === this.props.minValue}
                    onClick={() => this.move(-1, period)}
                >
                    <Icon type={constants.IconTypes.ARROW_LEFT}
                          width={10}
                          height={10}
                          fillColor={stylingVariables.colorPalette.green}
                    />
                </PrevButton>

                <StyledPreviewWrapper onClick={this.open}>
                    <span>
                        {this.renderDateField()}
                    </span>

                    <Icon
                        type={constants.IconTypes.CALENDAR}
                        width={20}
                        height={20}
                    />
                </StyledPreviewWrapper>

                <NextButton onClick={() => this.move(1, period)}>
                    <Icon type={constants.IconTypes.ARROW_RIGHT}
                          width={10}
                          height={10}
                          fillColor={stylingVariables.colorPalette.green}
                    />
                </NextButton>

                {
                    this.state.isOpen &&
                    <DateRangeCalendarContainer from={this.props.from} till={this.props.till} onApply={this.apply}/>
                }
            </StyledDateRangePickerWrapper>
        );
    }

    private apply = (from: Moment, till: Moment) => {
        this.close();

        this.props.onApply(from, till);
    }

    private move = (directionAndAmount: number, period: 'month' | 'year') => {
        const from = this.props.from.clone();
        const till = this.props.till.clone();

        if (from.format('D') === from.startOf(period).format('D') &&
            till.format('D') === till.endOf(period).format('D')) {
            this.apply(
                this.props.from.add(directionAndAmount, period).startOf(period),
                this.props.till.add(directionAndAmount, period).endOf(period),
            );
        } else {
            this.apply(
                this.props.from.add(directionAndAmount, period),
                this.props.till.add(directionAndAmount, period),
            );
        }
    }

    private open = () => {
        if (this.props.fidelity === DateRangePickerFidelity.dayToDayRange) {
            this.setState({isOpen: true});
        }
    }

    private close = () => {
        this.setState({isOpen: false});
    }

    public closeWhenClickOutside = (event: Event) => {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.close();
        }
    }

    private renderDateField = () => {
        switch (this.props.fidelity) {
            case DateRangePickerFidelity.dayToDayRange:
                return <>
                    {DateHelper.convertDateToHumanFormat(this.props.from)}
                    &nbsp;-&nbsp;
                    {DateHelper.convertDateToHumanFormat(this.props.till)}
                </>;
            case DateRangePickerFidelity.onlyYear:
                return this.props.from.year();
        }
    }

    private getMomentPeriod = (): 'month' | 'year' => {
        switch (this.props.fidelity) {
            case DateRangePickerFidelity.dayToDayRange:
                return 'month';
            case DateRangePickerFidelity.onlyYear:
                return 'year';
            default:
                return 'month';
        }
    }
}
