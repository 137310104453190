import React, {PureComponent} from 'react';
import styled from 'styled-components';
import Spreadsheet from 'x-data-spreadsheet';
import 'x-data-spreadsheet/dist/xspreadsheet.css';
import XLSX from 'xlsx';

const StyledExcel = styled.div`
  width: 100%;
  height: 100%;
`;

interface IProps {
    data: any;
    isEdit: boolean;
}

export class SpreadsheetGridEditable extends PureComponent<{data: any}> {
    render() {
        return <SpreadsheetGrid data={this.props.data} isEdit={true}/>;
    }
}

export class SpreadsheetGridNotEditable extends PureComponent<{data: any}> {
    render() {
        return <SpreadsheetGrid data={this.props.data} isEdit={false}/>;
    }
}

export default class SpreadsheetGrid extends PureComponent<IProps>{

    private datagridRef: any = React.createRef();
    private noRows: number = 0;
    private noCols: number = 0;

    public constructor(props: IProps) {
        super(props);
    }

    prepareData = (data: any) => {
        const out: any = [];
        if (data.SheetNames) {
            data.SheetNames.forEach((name: string) => {
                const o: any = {name, rows: {}};
                const ws = data.Sheets[name];
                const range = XLSX.utils.decode_range(ws['!ref']);
                this.noRows = range.e.r;
                this.noCols = range.e.c;
                const aoa = XLSX.utils.sheet_to_json(ws, {raw: false, header: 1});
                aoa.forEach((r: any, i) => {
                    const cells: any = {};
                    r.forEach((c: any, j: any) => {
                        cells[j] = {text: c};
                    });
                    o.rows[i] = {
                        cells,
                    };
                });
                out.push(o);
                if (ws['!merges'] && Array.isArray(ws['!merges'])) {
                    ws['!merges'].forEach((r: any) => {
                        o.rows[r.s.r].cells[r.s.c].merge = [r.e.r - r.s.r, r.e.c - r.s.c];
                    });
                }
            });
        }
        return out;
    }

    componentDidMount() {
        if (this.datagridRef.current && this.props.data){
            const spreadsheetData = this.prepareData(this.props.data);
            new Spreadsheet(this.datagridRef.current, {
                mode: this.props.isEdit ? 'edit' : 'read',
                showToolbar: true,
                showGrid: true,
                showContextmenu: false,
                view: {
                    height: () => this.datagridRef.current ? this.datagridRef.current.clientHeight : 0,
                    width: () => this.datagridRef.current ? this.datagridRef.current.clientWidth : 0,
                },
                row: {
                    len: this.noRows + 1,
                    height: 25,
                },
                col: {
                    len: this.noCols + 1,
                    width: 180,
                    indexWidth: 60,
                    minWidth: 100,
                },
            }).loadData(spreadsheetData);
        }
    }

    render() {
        return (<StyledExcel id={'spreadsheet-grid'} ref={this.datagridRef} />);
    }
}
