import React, {PureComponent} from 'react';
import {AutoSizer} from 'react-virtualized';
import styled from 'styled-components';
import {InfiniteTableColumn, StyledInfiniteTable as CommonStyledInfiniteTable} from '@common';
import {intl, stylingVariables} from '@global';

import * as Columns from './Column';
import {COLUMN_WIDTH_ACTIONS, COLUMN_WIDTH_DEFAULT, COLUMN_WIDTH_LABEL} from './constants';
import {Row} from './Row';

const StyledInfiniteTable = styled(CommonStyledInfiniteTable)`
    .ReactVirtualized__Table__row{
        .ReactVirtualized__Table__rowColumn {
            background-color: ${stylingVariables.colorPalette.lightGray};
        }
    }
    .ReactVirtualized__Table__headerColumn {
        white-space: normal;
    }

    .ReactVirtualized__Table__row_salary {
        padding-right: 0 !important;
    }
`;
const NoRowsTable = styled.div`
      padding-bottom: 50px;
      overflow: overlay;
`;

const NoRowsWrapper = styled.div`
      display: flex;
      align-items: center;
      justify-content: flex-start;
      height: 50px;
      background-color: ${stylingVariables.colorPalette.lightGray};
      border-radius: ${stylingVariables.layout.borderRadius};
      font-size: ${stylingVariables.fontSize.mediumLarge};
      font-weight: ${stylingVariables.fontWeight.regular};
      margin: 15px 0;
      span {
         font-style: italic;
         margin-left: 20px;
      }
`;

interface TableProps {
    width?: number;
    rows: any[];
    changeWrapperHeight?: (tableHeight: number) => void;
    editAction: (values: any) => void;
    addAction: () => void;
    isCurrentPeriod: boolean;
    masterdataEditAllowed: boolean;
}

interface TableState {
    rows: any[];
    order: number;
    openRows: number[];
}

export class UnitsTable extends PureComponent<TableProps, TableState> {
    private table: any;

    public constructor(props: TableProps) {
        super(props);

        this.state = {
            rows: this.props.rows,
            order: 1,
            openRows: [],
        };
    }

    private setRef = (ref: any) => {
        this.table = ref;
    }

    private get realTableWidth() {
        return 7 * COLUMN_WIDTH_DEFAULT + COLUMN_WIDTH_LABEL + COLUMN_WIDTH_ACTIONS;
    }

    componentDidUpdate(prevProps: Readonly<TableProps>, prevState: Readonly<TableState>) {
        if (this.props.rows.length !== prevProps.rows.length ||
            (this.props.rows.length &&  JSON.stringify(prevProps.rows) !== JSON.stringify(this.props.rows))
        ) {
            this.setState({ rows: this.props.rows, openRows: []}, () => this.afterRowHeightChange());
        }
    }

    private afterRowHeightChange = (index?: number) => {
        if (this.props.changeWrapperHeight) {
            let tableHeight = 0;
            this.state.rows.forEach((item, idx) => {
                if (typeof this.state.openRows.find((x: number) => x === idx) === 'number') {
                    tableHeight += (item.payElements.length * 50) + 90;
                } else {
                    tableHeight += 70;
                }
            });
            this.props.changeWrapperHeight(tableHeight || 70);
        }
        this.table.recomputeRowHeights(index);
    }

    private toggleRowHeight = (index: number) => {
        this.setState(
            ({ openRows }) => ({
                openRows: openRows.includes(index)
                    ? openRows.filter(tallIndex => tallIndex !== index)
                    : [...openRows, index],
            }),
            () => this.afterRowHeightChange(index),
        );
    }

    private getRowHeight = ({ index }: {index: number}) => {
        if (!this.state.rows[index]) {
            return 70;
        }
        const groupedItems = this.state.rows[index].payElements.length > 1;
        const openLength = (this.state.rows[index].payElements.length * 41) + 90;

        return this.state.openRows.includes(index) && groupedItems ? openLength : 70;
    }

    private _renderNoRows = () => {
        return (
            <NoRowsTable>
                <NoRowsWrapper><span>{intl.get('no_transactions')}</span></NoRowsWrapper>
            </NoRowsTable>
        );
    }

    public render() {
        return (
            this.props.rows && <AutoSizer>
                {({width, height}) => (
                    <StyledInfiniteTable
                        ref={this.setRef}
                        rowClassName={'ReactVirtualized__Table__row_salary'}
                        overscanRowCount={5}
                        rowHeight={this.getRowHeight}
                        headerHeight={40}
                        rowRenderer={Row()}
                        rowGetter={({ index }: {index: number}) => this.props.rows[index]}
                        rowCount={this.props.rows.length}
                        maxWidth={this.props.width}
                        height={height}
                        width={width > this.realTableWidth ? width : this.realTableWidth}
                        noRowsRenderer={this._renderNoRows}
                    >
                        {Columns.UnitsGroupLabel({
                            toggleRowHeight: this.toggleRowHeight,
                            openRows: this.state.openRows,
                        })}
                        {InfiniteTableColumn.Default({
                            label: intl.get('pay_unit'),
                            dataKey: 'payUnit',
                        })}
                        {InfiniteTableColumn.Amount({
                            label: intl.get('amount'),
                            dataKey: 'totalAmount',
                        })}
                        {Columns.Dates({
                            label: intl.get('dates'),
                            dataKey: 'payElements',
                        })}
                        {InfiniteTableColumn.Default({
                            label: intl.get('source'),
                            dataKey: 'source',
                        })}
                        {this.props.masterdataEditAllowed &&
                            Columns.Edit({action: this.props.editAction})
                        }
                    </StyledInfiniteTable>
                )}
            </AutoSizer>
        );
    }
}
