import {constants} from '@global';
import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {Route} from 'react-router-dom';
import {initializeBasicData} from './redux';

import {default as ReportListing} from './Reports/Reports';
import {default as ReportTemplateListing} from './ReportTemplates/ReportTemplates';
import {default as ProcessRoutes} from './Process/Routes';

interface IProps {
    initializeBasicData: () => any;
}

export class Routes extends PureComponent<IProps> {
    componentDidMount() {
        this.props.initializeBasicData();
    }

    public render() {
        return <>
            <Route exact path={constants.ROUTE_REPORTS} component={ReportListing} />
            <Route exact path={constants.ROUTE_REPORT_TEMPLATES} component={ReportTemplateListing} />
            <Route path={constants.ROUTE_REPORTS_PROCESS} component={ProcessRoutes} />
        </>
    }
}

const mapDispatchToProps = {
    initializeBasicData
};

export default connect(
    null,
    mapDispatchToProps,
)(Routes);
