import React, {PureComponent, Fragment} from 'react';
import {intl} from '@global';
import styled from 'styled-components';

import {stylingVariables} from '@global';
import {Label} from '@common';
import {EmployeeInterfaces, ApiObject} from '@api';

import EditStatus from './EditStatus';

const StyledStatusWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
`;

const StyledHeader = styled.div`
    font-size: 12px;
    font-weight: normal;
    color: ${stylingVariables.colorPalette.deepGray};
    padding: 5px 0;
`;

interface IProps {
    employee: EmployeeInterfaces.Employee;
}

export default class Status extends PureComponent<IProps> {
    public render() {
        return (
            <Fragment>
                <StyledHeader>
                    {intl.get('status')}
                </StyledHeader>
                <StyledStatusWrapper>
                    <Label.FatTag color={this.color()}>
                        {intl.get(this.props.employee.status)}
                    </Label.FatTag>

                    <EditStatus employee={this.props.employee}/>
                </StyledStatusWrapper>
            </Fragment>
        );
    }

    private color = () => {
        switch (this.props.employee.status) {
            case ApiObject.Status.active:
                return stylingVariables.colorPalette.brightGreen;
            case ApiObject.Status.inactive:
                return stylingVariables.colorPalette.orange;
            case ApiObject.Status.draft:
                return stylingVariables.colorPalette.deepGray;
        }
    }
}
