import React from 'react';
import styled from 'styled-components';
import {stylingVariables} from '@global';
import {BlankButton} from '@common';

export const Action = styled(props => <BlankButton {...props} />)`
  display: flex;
  align-items: center;
  white-space: nowrap;
  padding: 10px 0!important;
  font-size: ${stylingVariables.fontSize.medium};
  font-weight: ${stylingVariables.fontWeight.medium};

  ${props => props.isDisabled && `
    cursor: not-allowed;
    pointer-events: none;
    opacity: .7;
  `}

  span {
    margin-left: 10px;
  }

  &:hover {
    color: ${stylingVariables.colorPalette.orange};
  }
`;

export const OrangeAction = styled(Action)`
  color: ${stylingVariables.colorPalette.orange};
  &:hover {
    color: ${stylingVariables.colorPalette.orange};
  }
`;

export const GreenAction = styled(Action)`
  color: ${stylingVariables.colorPalette.green};
  &:hover {
    color: ${stylingVariables.colorPalette.green};
  }
`;

export const Link = styled(props => <BlankButton {...props} />)`
  display: flex;
  align-items: center;
  white-space: nowrap;
  font-size: ${stylingVariables.fontSize.medium};
  font-weight: ${stylingVariables.fontWeight.medium};
  color: ${stylingVariables.colorPalette.green};
  cursor: pointer;

  ${props => props.isDisabled && `
    cursor: not-allowed;
    pointer-events: none;
    opacity: .7;
  `}

  &:hover {
    color: ${stylingVariables.colorPalette.orange};
  }
`;

export const ActionBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 40px;
  border-top: 1px solid ${stylingVariables.colorPalette.darkGray};
`;

export const ActionRightBar = styled.div`
  float: right;
  padding: 10px 0;
`;

export const OrangeButton = styled.button<{isDisabled?: boolean}>`
  outline: none;
  box-shadow: none;
  cursor: pointer;
  color: ${stylingVariables.colorPalette.dark};
  padding: 10px 20px;
  background-color: ${stylingVariables.colorPalette.orange};
  color: ${stylingVariables.colorPalette.white};
  border-radius: 20px;
  font-size: ${stylingVariables.fontSize.default};
  font-weight: ${stylingVariables.fontWeight.regular};
  margin: 0 10px;
  &:hover {
    box-shadow: ${stylingVariables.layout.boxShadow};
  }
  ${props => props.isDisabled && `
    pointer-events: none;
    opacity: .6;
  `}
`;

export const GrayButton = styled.button<{isDisabled?: boolean}>`
  outline: none;
  box-shadow: none;
  cursor: pointer;
  color: ${stylingVariables.colorPalette.dark};
  padding: 10px 20px;
  border: 1px solid ${stylingVariables.colorPalette.dark};
  background-color: ${stylingVariables.colorPalette.white};
  border-radius: 20px;
  font-size: ${stylingVariables.fontSize.default};
  margin: 0 10px;
  &:hover {
    box-shadow: ${stylingVariables.layout.boxShadow};
  }
  ${props => props.isDisabled && `
    pointer-events: none;
    opacity: .6;
  `}
`;

export const ColorButton = styled.button<{isDisabled?: boolean, color: string}>`
  outline: none;
  box-shadow: none;
  cursor: pointer;
  color: ${stylingVariables.colorPalette.dark};
  padding: 10px 20px;
  background-color: ${props => props.color};
  color: ${stylingVariables.colorPalette.white};
  border-radius: 20px;
  font-size: ${stylingVariables.fontSize.default};
  font-weight: ${stylingVariables.fontWeight.regular};
  margin: 0 10px;
  &:hover {
    box-shadow: ${stylingVariables.layout.boxShadow};
  }
  ${props => props.isDisabled && `
    pointer-events: none;
    opacity: .6;
  `}
`;
