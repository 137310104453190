import {ApiObject} from '@api';
import React, {CSSProperties} from 'react';
import {Column} from 'react-virtualized';

import {interfaces} from '@global';
import {defaultInfiniteTableCellDataGetter, InfiniteTableHeader} from '@common';
import {cellRendererCreator} from '../functions';

interface IProps {
    dataKey: string;
    label: string;
    width?: number;
    flexGrow?: number;
    className?: string;
    style?: CSSProperties;
    type?: string;
    options?: ApiObject.SelectOption[];
    orders?: interfaces.ListingOrders;
    filters?: interfaces.ListingFilters;
    changeOrder?: (column: string) => any;
    changeFilter?: (params: interfaces.FilterParams) => any;
}

const OrderedAndFiltered: React.FC<IProps> = ({
    className,
    dataKey,
    label,
    width = 150,
    flexGrow = 1,
    type = ApiObject.FieldType.text,
    options = [],
    orders,
    changeOrder,
    filters,
    changeFilter,
    style = {},
}: IProps) => (
    <Column
        className={className}
        key={dataKey}
        dataKey={dataKey}
        width={width}
        flexGrow={flexGrow}
        style={style}
        cellDataGetter={defaultInfiniteTableCellDataGetter}
        headerRenderer={() => (
            <InfiniteTableHeader.OrderedAndFiltered
                label={label}
                type={type}
                dataKey={dataKey}
                orders={orders}
                options={options}
                changeOrder={changeOrder}
                filters={filters}
                changeFilter={changeFilter}
            />
        )}
        cellRenderer={cellRendererCreator(type, dataKey, options)}
    />
);

export default OrderedAndFiltered;
