import {ApiObject} from '@api';
import {stylingVariables, constants} from '@global';
import React, {PureComponent} from 'react';
import {intl} from '@global';
import {Modal as CommonModal, Layout, Back} from '@common';
import styled from 'styled-components';

import {RootState} from '../../../../../../rootReducer'
import {createReport, openReportExportProcess} from '../../../redux';
import * as selectors from '../../../selectors';
import NameRow from '../../NameRow';
import {connect} from 'react-redux';

const ActionBar = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 25px 70px 0 25px;
    
    button {
        margin-left: 30px;
    }
`;
const StyledBack = styled.div`
  display: flex;
  align-items: center;
  span {
    margin-left: 5px;
    font-size: ${stylingVariables.fontSize.mediumLarge};
    color: ${stylingVariables.colorPalette.green}
  }
`;

interface IProps {
    report: ApiObject.Report|null;
    onClose: () => void;
    onBack: () => void;
    templateLabel: string;
    template: ApiObject.ReportTemplate | null;
    reportSuffix: string;
    createReport: (partOfReport: { label: string, visibility?: ApiObject.ReportVisibility, legalEntityReportType?: ApiObject.DataSource}) => any;
    openReportExportProcess: (report: ApiObject.Report, mode?: constants.ReportProcessMode) => void;
}

interface IState {
    reportName: string;
    hasError: boolean;
    isSaving: boolean;
}

export class SaveReportModal extends PureComponent<IProps, IState> {
    public constructor(props: IProps) {
        super(props);

        this.state = {
            reportName: props.templateLabel,
            hasError: false,
            isSaving: false,
        };
    }

    render() {
        return (
            <>
                <CommonModal.OverflowableContent style={{padding: '20px 80px'}}>
                    <Layout.Rows>
                        <NameRow
                            reportName={this.props.templateLabel}
                            suffix={`_${this.props.reportSuffix}`}
                            onChange={this.onChangeName}
                            errors={this.state.hasError ? ['Field cannot be empty'] : undefined}
                        />
                    </Layout.Rows>
                </CommonModal.OverflowableContent>

                <ActionBar>
                    <StyledBack>
                        <Back.Button onGoBack={() => this.props.onBack()}/>
                        <span>Back</span>
                    </StyledBack>

                    <div>
                        <CommonModal.StyledCancelButton onClick={this.props.onClose}>
                            {intl.get('cancel')}
                        </CommonModal.StyledCancelButton>

                        <CommonModal.StyledOkButton isDisabled={this.state.isSaving} onClick={this.onSave}>
                            {intl.get('save_and_generate')}
                        </CommonModal.StyledOkButton>
                    </div>
                </ActionBar>
            </>
        );
    }

    private isValid = () => {
        const isOk = !!this.state.reportName
        this.setState({
            hasError: !isOk
        })

        return isOk;
    }

    private onSave = async () => {
        this.setState({isSaving: true});
        if (this.isValid()) {
            const reportType = this.props.report && this.props.report.legalEntityReportType ? this.props.report.legalEntityReportType : ApiObject.DataSource.payroll;
            const createdReport = await this.props.createReport({label: this.state.reportName, visibility: this.props.template ? this.props.template.visibility : undefined, legalEntityReportType: reportType});
            if (createdReport) {
                this.props.openReportExportProcess(createdReport, constants.ReportProcessMode.viewReport);
                this.props.onClose();
            }
        }

        this.setState({isSaving: false});
    }

    private onChangeName = (reportName: string) => this.setState({reportName});
}

const mapStateToProps = (state: RootState) => ({
    report: selectors.getReport(state),
    templateLabel: selectors.getTemplateLabel(state),
    template: selectors.getTemplate(state),
    reportSuffix: selectors.getReportSuffix(state)
});

const mapDispatchToProps = {
    createReport,
    openReportExportProcess
};

export default connect(mapStateToProps, mapDispatchToProps)(SaveReportModal);
