import React, {PureComponent} from 'react';
import moment from 'moment';
import {LeaveInterfaces} from '@api';
import {Layout, Collapse} from '@common';
import styled from "styled-components";
import {stylingVariables, intl} from "@global";


const Wrapper = styled.div`
  margin: 10px 0 0 77px;
`;

const Content = styled.div`
  margin-top: 20px;
  font-weight: ${stylingVariables.fontWeight.bold};
`;

interface IProps {
    leave: LeaveInterfaces.Transaction;
    isOpen?: boolean;
}

export default class ProcessDetails extends PureComponent<IProps> {
    public render() {
        const createDate = moment(this.props.leave.createTime, 'YYYY-MM-DD HH:mm:ss').format('MMM DD, YYYY');
        const creatorEmployeeName = this.props.leave.creatorEmployee.firstName + ' ' + this.props.leave.creatorEmployee.lastName;

        return (
            <Collapse label={intl.get('process_details')} isOpen={this.props.isOpen}>
                <Wrapper>
                    <Layout.Row>
                        <Layout.RowLabel percent={15} top={20}>
                            {intl.get('entry_date_and_user')}
                        </Layout.RowLabel>
                        <Content>
                            {this.props.leave.createTime ? createDate : null} {intl.get('by')} {creatorEmployeeName}
                        </Content>
                    </Layout.Row>
                </Wrapper>
            </Collapse>
        );
    }
}
