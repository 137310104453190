import React, {PureComponent} from 'react';
import {get} from 'lodash';
import styled from 'styled-components';

import {stylingVariables} from '@global';
import {ApiObject} from '@api';

import Field from './Field';

export const StyledFieldsGroupHeader = styled.h4`
  color: ${stylingVariables.colorPalette.darkest};
  font-size: 16px;
  font-weight: ${stylingVariables.fontWeight.bold};
  padding: 0;
  margin: 30px 0;
`;

export const StyledFieldsGroup = styled.div`
  margin: 20px 0;
  border-bottom: 1px solid ${stylingVariables.colorPalette.gray};
  &:last-child {
    border-bottom: 0;
  }
`;

interface IProps {
    fields: any;
    group: ApiObject.FieldGroup;
}

export default class Group extends PureComponent<IProps> {
    render() {
        return (
            <StyledFieldsGroup>
                <StyledFieldsGroupHeader>
                    {this.props.group.name}
                </StyledFieldsGroupHeader>

                {
                    this.renderFields()
                }
            </StyledFieldsGroup>
        );
    }

    private renderFields() {
        return this.props.group.fields
            .sort(function(a,b) { return a.ordinal - b.ordinal })
            .map((field: ApiObject.Field) => {
                const value = get(this.props.fields, `${field.entity}.${field.code}`, '-') || '-';
                return (
                    <Field
                        key={`${field.entity}_${field.code}`}
                        field={field}
                        value={value}
                    />
                );
        });
    }
}
