import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {EmployeeInterfaces} from '@api';
import {RootState} from '../../../../../rootReducer';
import {isLoading} from '../../../../selectors';
import {getSeparationAction} from '../../../selectors';
import TerminationDetails from './TerminationDetails';
import TerminationPayout from './TerminationPayout';
import {constants} from '@global';

interface IProps {
    forEmployeeIds: Set<number>;
    onClickCloseModal: () => void;
    employee: EmployeeInterfaces.Employee;

    action: string;
}

export class InsertSeparationModal extends PureComponent<IProps> {

    render() {
        return constants.TerminationTypes.payout === this.props.action
            ? <TerminationPayout forEmployeeIds={this.props.forEmployeeIds}
                                 onClickClose={this.props.onClickCloseModal}
                                 employee={this.props.employee}/>
            : <TerminationDetails forEmployeeIds={this.props.forEmployeeIds}
                                  onClickClose={this.props.onClickCloseModal}
                                  employee={this.props.employee}/>;
    }
}

const mapStateToProps = (state: RootState) => ({
    isLoading: isLoading(state),

    action: getSeparationAction(state),
});

export default connect(mapStateToProps)(InsertSeparationModal);
