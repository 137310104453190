import React, {PureComponent} from 'react';
import { InfiniteLoader } from 'react-virtualized';
import {connect} from "react-redux";
import styled from "styled-components";

import {EmployeeInterfaces, ApiObject, ApiEmployee} from '@api';
import {
	InfiniteTableColumn,
	StyledInfiniteTable,
	InfiniteTableNoRowsNote, Actions
} from '@common';
import {interfaces} from "@global";

import {RootState} from "../../../../../../rootReducer";
import * as globalSelectors from "../../../../../selectors";
import * as Columns from "./Column";
import {
	DEFAULT_NAME_WIDTH,
	REAL_TABLE_WIDTH,
	DEFAULT_PAY_GROUP_WIDTH, DEFAULT_ACTIONS_WIDTH
} from './constants';
import {Row} from './Row';

const TABLE_HEADER_HEIGHT = 40;
const TABLE_ROW_HEIGHT = 70;

const InfiniteTable = styled(StyledInfiniteTable)`
	overflow-x: visible;
  .ReactVirtualized__Table__row {
    .ReactVirtualized__Table__rowColumn {
      .checkbox {
        display: none;
      }
    }

    &.highlighted, &:hover {
      .ReactVirtualized__Table__rowColumn {
        .checkbox {
          display: flex;
        }
        .index {
          display: none;
        }
      }
    }
  }
`;

const TableContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow-x: auto;
`;

interface IProps {
	height: number|string;
	width: number|string;

	listOptionsOfEmployeeGroups: ApiObject.SelectOption[];
	filters: interfaces.ListingFilters;
	orders: interfaces.ListingOrders;
	changeOrder: (column: string) => any;
	changeFilter: (params: interfaces.FilterParams) => any;

	employees: EmployeeInterfaces.Employee[];
	totalCount: number;

	selectEmployee?: (employee: EmployeeInterfaces.Employee) => void;
	unselectEmployee?: (employee: EmployeeInterfaces.Employee) => void;

	onLoadMoreEmployees: () => any;
	emptyListText: string;

	bulkActionMoveEmployeeLabel: string;
	selectedIds: Set<number>;
	setSelectedIds: (ids: Set<number>) => void;
	onSelectAll: () => any;
	onUnselectAll: () => any;
	onMoveEmployeesBulkAction: () => any;

	currentLegalEntityId: number|null;
	isMenuCollapsed: boolean;
}

export class Table extends PureComponent<IProps> {
	static defaultProps = {
		employees: [],
		height: '100%',
		width: REAL_TABLE_WIDTH,
		filters: {},
		changeFilter: () => {},
	};

	public render() {
		return (
			<TableContainer>
				<InfiniteLoader
					isRowLoaded={this._isRowLoaded}
					loadMoreRows={(props) => this.props.onLoadMoreEmployees()}
					rowCount={this.props.totalCount}
					minimumBatchSize={20}
				>
					{({onRowsRendered, registerChild}) => (
						<InfiniteTable
							ref={registerChild}
							onRowsRendered={onRowsRendered}
							noRowsRenderer={() => <InfiniteTableNoRowsNote text={this.props.emptyListText}/>}
							overscanRowCount={10}
							rowHeight={TABLE_ROW_HEIGHT}
							headerHeight={TABLE_HEADER_HEIGHT}
							rowGetter={({ index }: {index: number}) => this.props.employees[index]}
							rowCount={this.props.employees.length}
							rowRenderer={Row({selectedIds: this.props.selectedIds})}
							height={+this.props.height}
							width={this.props.width < REAL_TABLE_WIDTH ? REAL_TABLE_WIDTH : this.props.width}
							realWidth={this.props.width < REAL_TABLE_WIDTH ? REAL_TABLE_WIDTH : this.props.width}
						>
							{
								Columns.SelectedIndex({
									bulkAction: <Actions.Action onClick={this.props.onMoveEmployeesBulkAction}>
										{this.props.bulkActionMoveEmployeeLabel}
									</Actions.Action>,
									totalCount: this.props.totalCount,
									onSelectAll: this.props.onSelectAll,
									onUnselectAll: this.props.onUnselectAll,
									selectedIds: this.props.selectedIds,
									onChangeSelection: this.changeRowSelection,
								})
							}

							{Columns.EmployeeColumn({
								width: DEFAULT_NAME_WIDTH,
								orders: this.props.orders,
								changeOrder: this.props.changeOrder,
								getEmployees: this.getEmployeeForFilter,
								filters: this.props.filters,
								changeFilter: this.props.changeFilter,
							})}
							{InfiniteTableColumn.Filtered({
								label: 'Pay Group',
								dataKey: 'employeeGroupId',
								width: DEFAULT_PAY_GROUP_WIDTH,
								type: ApiObject.FieldType.dropdown,
								options: this.props.listOptionsOfEmployeeGroups,
								filters: this.props.filters,
								changeFilter: this.props.changeFilter,
							})}
							{Columns.Status()}
							{Columns.Actions({
								width: DEFAULT_ACTIONS_WIDTH,
								onAddEmployee: this.props.selectEmployee,
								onRemoveEmployee: this.props.unselectEmployee
							})}
						</InfiniteTable>
					)}
				</InfiniteLoader>
			</TableContainer>
		);
	}

	private changeRowSelection = (id: number): void => {
		const selectedIds = new Set(this.props.selectedIds);
		if (selectedIds.has(id)) {
			selectedIds.delete(id);
		} else {
			selectedIds.add(id);
		}

		this.props.setSelectedIds(selectedIds);
	}

	private _isRowLoaded = ({ index }: { index: number}): boolean => {
		return !!this.props.employees[index];
	}

	private getEmployeeForFilter = async(
		query: string, limit: number, page: number
	) => {
		if (!this.props.currentLegalEntityId) {
			return [];
		}

		return await ApiEmployee.findByQueryWithPage(
			this.props.currentLegalEntityId,
			query,
			limit,
			page,
			[],
			true,
		);
	}
}

const mapStateToProps = (state: RootState) => ({
	currentLegalEntityId: globalSelectors.getCurrentLegalEntityId(state),
	isMenuCollapsed: globalSelectors.isMenuCollapsed(state),
});

export default connect(mapStateToProps)(Table);
