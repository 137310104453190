import React, {PureComponent} from 'react';
import Datetime from 'react-datetime';
import styled from 'styled-components';
import 'react-datetime/css/react-datetime.css';

import {constants, stylingVariables} from '@global';
import moment, {Moment} from 'moment';
import * as Cookies from 'js-cookie';

const StyledDatePickerWrapper = styled.div`
  height: 42px;;
  .rdt {
    height: 100%;
    .rdtPicker {
      width: 320px;
      padding: 20px;
      box-shadow: ${stylingVariables.layout.boxShadow};
      border-radius: ${stylingVariables.layout.borderRadius};
      .rdtSwitch {
        text-transform: capitalize;
        padding: 10px 0;
        &:hover {
          background-color: ${stylingVariables.colorPalette.lightGreen};
        }
      }
      .rdtNext {
        padding: 5px 0;
        width: 40px;
        &:hover {
          background-color: ${stylingVariables.colorPalette.lightGreen};
        }
      }
      .rdtPrev {
        padding: 5px 0;
        width: 40px;
        &:hover {
          background-color: ${stylingVariables.colorPalette.lightGreen};
        }
      }
      .rdtMonth, .rdtYear {
        padding: 20px 0;
        font-weight: ${stylingVariables.fontWeight.light};
        font-size: ${stylingVariables.fontSize.mediumSmall};
        &:hover {
          background-color: ${stylingVariables.colorPalette.lightGreen};
        }
        &.rdtActive {
          font-weight: ${stylingVariables.fontWeight.bold};
          color: ${stylingVariables.colorPalette.white};
          background-color: ${stylingVariables.colorPalette.green};
        }
      }
      .rdtDays {
        .dow {
          padding: 20px 0;
          color: ${stylingVariables.colorPalette.deepGray};
        }
        .rdtDay {
          padding: 2px;
          font-weight: ${stylingVariables.fontWeight.light};
          font-size: ${stylingVariables.fontSize.mediumSmall};
          &:hover {
            background-color: transparent;
          }
          div {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 30px;
            height: 30px;
            margin: auto;
            border-radius: 50%;
            &:hover {
              background-color: ${stylingVariables.colorPalette.lightGreen};
            }
          }
          &.rdtActive {
            background-color: transparent;
            div {
              font-weight: ${stylingVariables.fontWeight.bold};
              color: ${stylingVariables.colorPalette.white};
              background-color: ${stylingVariables.colorPalette.green};
            }
          }
          &.rdtToday {
            &:before {
               border-bottom: 7px solid ${stylingVariables.colorPalette.green};
            }
          }
        }
      }
    }
  }
`;

interface IProps {
    selected?: Moment;
    dateFormat: string;
    onChange?: (value: Moment|string) => void;
    onBlur?: any;
    onFocus?: () => void;
    defaultValue?: Date | string | Moment;
    isOpen: boolean;
}

export default class DatePicker extends PureComponent<IProps> {
    static defaultProps = {
        dateFormat: constants.SYSTEM_DATE_HUMAN_FORMAT,
    };

    private locale = Cookies.get('locale');

    render() {
        const viewDate = ((typeof this.props.defaultValue === 'string')
            ? moment(this.props.defaultValue, constants.SYSTEM_DATE_FORMAT)
            : this.props.defaultValue) || undefined;
        return (
            <StyledDatePickerWrapper>
                <Datetime
                    locale={this.locale}
                    value={this.props.selected}
                    viewDate={viewDate}
                    dateFormat={this.props.dateFormat}
                    onChange={this.props.onChange}
                    onBlur={this.props.onBlur}
                    onFocus={this.props.onFocus}
                    timeFormat={false}
                    closeOnSelect={true}
                    open={this.props.isOpen}
                    disableOnClickOutside={false}
                    renderDay={(props: any, currentDate: any) => {
                        return (
                            <td {...props}>
                                <div>{currentDate.date()}</div>
                            </td>
                        );
                    }}
                />
            </StyledDatePickerWrapper>
        );
    }
}
