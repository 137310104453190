import styled from 'styled-components';

import {stylingVariables} from '@global';

export const StyledCard = styled.div<{borderColor?: string}>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 14px;
  border-radius: 4px 4px 1px 1px;
  box-shadow: ${stylingVariables.layout.boxShadowLight};
  min-width: 150px;
  margin: 0 10px 18px;
  background: ${stylingVariables.colorPalette.lightGray};

  &:after {
    content: '';
    height: 3px;
    width: 100%;
    position: absolute;
    bottom: 0;
    border-radius: 4px;

    ${props => props.borderColor && `
      background: ${props.borderColor};
    `}
  }
`;

export const StyledTitle = styled.span`
  margin-bottom: 10px;
  font-size: ${stylingVariables.fontSize.medium};
  font-weight: ${stylingVariables.fontWeight.bold};
  text-transform: capitalize;
`;
