import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'fonts.css';

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import thunk from 'redux-thunk';
import { createStore, applyMiddleware, compose } from 'redux';
import intl from 'react-intl-universal';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { PersistGate } from 'redux-persist/integration/react';
import * as stylingVariables from './stylingVariables';
import * as constants from './constants';
import * as interfaces from './interfaces';

import { EnvironmentHelper } from '@library';
import locales from '@locales';

import App from './entrypoints/App';
import rootReducer, {defaultState} from './rootReducer';
import api from './apiProvider';
import {createBrowserHistory} from 'history';
import {Provider} from 'react-redux';
import * as globalSelectors from './entrypoints/selectors';
import { numberFormat } from './entrypoints/Employee/Tabs/Payment/PaymentProcessor/fieldHelper';
import {setLocale} from './entrypoints/redux'

import * as Cookies from 'js-cookie';
import moment from 'moment';
import 'moment/locale/et';
import 'moment/locale/es';
import 'moment/locale/en-gb';
import 'moment/locale/pl';

// @ts-ignore
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const persistConfig = {
    key: 'root',
    storage,
    version: 10,
    blacklist: [
      'employees.items',
      'accessManagement',
      'payrolls',
      'payroll',
    ],
};

const store = createStore(
    persistReducer(persistConfig, rootReducer),
    defaultState,
    composeEnhancers(
        applyMiddleware(thunk),
    ),
);
const persistor = persistStore(store);
const history = createBrowserHistory();

function getLocaleCookie() {
    return Cookies.get('locale')
}

function getStartingLocale() {
    const cookie = getLocaleCookie() || 'en'

    moment.locale(cookie)
    setLocale(cookie)
    return cookie
}

const startingLocale = getStartingLocale()

ReactDOM.render(
    <Provider store={store}>
        <PersistGate persistor={persistor}>
            <App />
        </PersistGate>
    </Provider>,
    document.getElementById('root'),
);

// https://github.com/rt2zz/redux-persist/blob/master/docs/hot-module-replacement.md
if (EnvironmentHelper.isDevelopmentMode()) {
    if ((module as any).hot) {
        (module as any).hot.accept('./rootReducer', () => {
            store.replaceReducer(persistReducer(persistConfig, rootReducer));
        });
    }
}

intl.init({
    currentLocale: startingLocale,
    fallbackLocale: 'en',
    locales
});

export {
    api,
    store,
    history,
    stylingVariables,
    constants,
    interfaces,
    intl,

    rootReducer,
    globalSelectors,
    numberFormat,
};
