import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';
import {startsWith} from 'lodash';
import {Location, UnregisterCallback} from 'history';

import {constants, stylingVariables, history, intl, interfaces} from '@global';
import {RouteHelper, AccessManager} from '@library';
import {Icon} from '@common';
import {ApiObject, PayrollInterfaces} from '@api';

import {goToOldMssRoute, getMfRoute} from '../../redux';
import {toggleReportsModal} from "../../Reports/redux";
import {RootState} from '../../../rootReducer';
import * as globalSelectors from '../../selectors';

const StyledNavigationWrapper = styled.ul`
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
`;

const StyledLink = styled.li<{ isActive: boolean }>`
  display: flex;
  align-items: center;
  padding-left: 40px;
  height: 50px;
  @media (max-height: 800px) {
    height: 40px;
  }
  cursor: pointer;
  position: relative;
  color: ${props => props.isActive ? stylingVariables.colorPalette.orange : stylingVariables.colorPalette.dark};
  background-color: ${props => props.isActive ? stylingVariables.colorPalette.gray : stylingVariables.colorPalette.white};
  font-size: ${stylingVariables.fontSize.default};
  width: ${props => props.isActive ? stylingVariables.colorPalette.orange : stylingVariables.colorPalette.dark};
  user-select: none;
  &:hover {
    background-color: ${stylingVariables.colorPalette.gray};
    &:before {
       background-color: ${stylingVariables.colorPalette.orange};
    }
  }
  &:before {
    content: ' ';
    width: 4px;
    height: 100%;
    background-color: ${props => props.isActive ? stylingVariables.colorPalette.orange : 'transparent'};
    position: absolute;
    left: 0;
    top: 0;
  }

  @media (max-height: 800px) {
    svg{
      width: 30px;
      height: 30px;
    }
  }

  span {
    margin-left: 10px;
  }
`;

interface MenuLink {
    icon: string;
    title: string;
    path: string;
    isOld?: boolean;
    external?: boolean;
    isMf?: boolean;
}

interface IProps {
    currentLegalEntity: ApiObject.LegalEntity;
    goToOldMssRoute: (path: string) => void;
    currentPayrollPeriod: PayrollInterfaces.PayrollPeriod|null;
    toggleReportsModal: () => void;
    getMfRoute: (path: string) => void;
    permissions: interfaces.Permissions
}

interface IState {
    menuLinks: MenuLink[];
    locationPathName: string;
}

export class Navigation extends PureComponent<IProps, IState> {
    private unlisten: UnregisterCallback;

    public constructor(props: IProps) {
        super(props);

        this.state = {
            menuLinks: [],
            locationPathName: window.location.pathname,
        };

        this.unlisten = history.listen((location: Location) => {
            this.setState({locationPathName: location.pathname});
        });
    }

    static getDerivedStateFromProps(props: IProps) {
        const aclModule = (module: constants.Modules): boolean =>
            AccessManager.hasModule(props.currentLegalEntity, module);
        
        const aclCustomModule = (module: constants.ModuleCodes): boolean =>
          AccessManager.hasModule(props.currentLegalEntity, module);

        const aclHasPermission = (code: constants.PermissionCode) => {
            return AccessManager.hasPermission(props.permissions, code);
        }

        const employees: MenuLink = {
            icon: constants.IconTypes.EMPLOYEES,
            title: intl.get('employees'),
            path: constants.ROUTE_EMPLOYEES,
        };
        const employeesV2: MenuLink = {
            icon: constants.IconTypes.EMPLOYEES,
            title: `${intl.get('employees')} V2`,
            path: `${constants.ROUTE_EMPLOYEES_V2}/${props.currentLegalEntity?.id}/listing`,
            isMf: true,
        };
        const payroll: MenuLink = {
            icon: constants.IconTypes.PAYROLL,
            title: intl.get('payroll'),
            path: `${constants.ROUTE_PAYROLL_FLOW}/${props.currentLegalEntity?.id}`,
            isMf: true,
        };
        const payrollV2: MenuLink = {
            icon: constants.IconTypes.PAYROLL,
            title: intl.get('payroll'),
            path: `${constants.ROUTE_PAYROLL}/${props.currentLegalEntity?.id}/open`,
            isMf: true,
        };
        const leaves: MenuLink = {
            icon: constants.IconTypes.TRAVEL,
            title: intl.get('leaves'),
            path: constants.ROUTE_LEAVES,
        };
        const reports: MenuLink = {
            icon: constants.IconTypes.REPORTS,
            title: intl.get('reports'),
            path: constants.ROUTE_REPORTS,
        };
        const oldMssTemplates = {
            icon: constants.IconTypes.TEMPLATES,
            title: intl.get('templates'),
            path: constants.ROUTE_TEMPLATES_OLD,
            isOld: true
        };
        const calendar: MenuLink = {
            icon: constants.IconTypes.CALENDAR,
            title: intl.get('calendar'),
            path: `${constants.ROUTE_CALENDAR}/${props.currentLegalEntity?.id}`,
            isMf: true,
        };
        const help: MenuLink = {
            icon: constants.IconTypes.HELP,
            title: intl.get('help'),
            path: constants.ROUTE_HELP,
            external: true,
        };

        const documentsSublink = aclHasPermission(constants.PermissionCode.DOCUMENTS) ? 'documents': 'templates'
        const documents: MenuLink = {
            icon: constants.IconTypes.FILE_DOC,
            title: intl.get('documents'),
            path: `${constants.ROUTE_DOCUMENTS}/${props.currentLegalEntity?.id}/${documentsSublink}`,
            isMf: true,
        };
        const hasDocuments: boolean = aclHasPermission(constants.PermissionCode.DOCUMENTS)
          || aclHasPermission(constants.PermissionCode.DOCUMENTS_TEMPLATES)

        const menuLinks: MenuLink[] = [
            aclModule(constants.Modules.employees) ? employees : null,
            aclCustomModule(constants.ModuleCodes.pac_employees_v2) ? employeesV2 : null,
            aclModule(constants.Modules.payroll) ? payroll : null,
            aclModule(constants.Modules.payrollV2) ? payrollV2 : null,
            aclModule(constants.Modules.pac_leave) ? leaves : null,
            aclModule(constants.Modules.templates) ? oldMssTemplates : null,
            aclModule(constants.Modules.reports) ? reports : null,
            aclModule(constants.Modules.calendar) ? calendar : null,
            aclModule(constants.Modules.documents) && hasDocuments ? documents : null,
            aclModule(constants.Modules.help) ? help : null,
        ].filter(x => !!x) as MenuLink[];

        return {menuLinks};
    }

    render() {
        return (
            <StyledNavigationWrapper>
                {
                    this.state.menuLinks.map(route =>
                        <StyledLink
                            key={route.path}
                            isActive={
                                startsWith(this.state.locationPathName, route.path)
                            }
                            onClick={() => this.go(route)}
                        >
                            <Icon type={route.icon}
                                  width={25}
                                  height={25}
                            />
                            <span>{route.title}</span>
                        </StyledLink>,
                    )
                }
            </StyledNavigationWrapper>
        );
    }

    private go = (route: any) => {

        if (route.isMf) {
            this.props.getMfRoute(route.path)
            return
        }

        if (route.isOld) {
            this.props.goToOldMssRoute(route.path);

            return;
        }

        if (route.external) {
            window.location.replace(route.path);

            return;
        }

        RouteHelper.go(route.path);
    }

    public componentWillUnmount() {
        this.unlisten();
    }
}

const mapStateToProps = (state: RootState, ownProps: any) => ({
    currentPayrollPeriod: globalSelectors.getCurrentPayrollPeriod(state),
    permissions: state.global.permissions,
});

const mapDispatchToProps = {
    goToOldMssRoute,
    getMfRoute,
    toggleReportsModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
