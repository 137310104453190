import React, {PureComponent} from 'react';
import {intl} from '@global';

import {stylingVariables} from '@global';
import {EmployeeInterfaces} from '@api';
import {Label, Switch} from '@common';

interface IProps {
    employee: EmployeeInterfaces.Employee;
}

export default class EssStatus extends PureComponent<IProps> {
    render() {
        return (
            <Switch current={this.props.employee.essStatus as string}
                    options={new Map([
                        [
                            EmployeeInterfaces.EssStatus.invitation_sent,
                            <Label.FatTag color={stylingVariables.colorPalette.yellow}>
                                {intl.get('pending')}
                            </Label.FatTag>,
                        ],
                        [
                            EmployeeInterfaces.EssStatus.account_created,
                            <Label.FatTag color={stylingVariables.colorPalette.green}>
                                {intl.get('active')}
                            </Label.FatTag>,
                        ],
                    ])}
                    default={
                        <Label.FatTag color={stylingVariables.colorPalette.orange}>
                            {intl.get('inactive')}
                        </Label.FatTag>
                    }
            />
        );
    }
}
