import React, {PureComponent} from 'react';
import {intl} from '@global';
import {Modal as CommonModal, Layout} from '@common';
import {connect} from 'react-redux';
import Validator from 'validatorjs';

import {ApiObject} from '@api';
import {stylingVariables} from '@global';

import {closeDocumentModal, createDocument} from '../../../../redux';
import Actions from './Actions';

import DescriptionRow from './DescriptionRow';
import FileRow from './FileRow';
import ESSVisibilityRow from './ESSVisibilityRow';

interface IProps {
    closeDocumentModal: () => void;
    createDocument: (document: ApiObject.FileHandle) => any;
}

interface IState {
    document: ApiObject.FileHandle;

    errors: {
        [key: string]: string[],
    };
}

export class Modal extends PureComponent<IProps, IState> {
    private readonly FIELD_DESCRIPTION = 'description';
    private readonly FIELD_FILE = 'file';

    private readonly validationRules = {
        [this.FIELD_FILE]: 'required',
    };

    private readonly validationMessages = {
        required: 'Field can not be empty',
    };

    public constructor(props: IProps) {
        super(props);

        this.state = {
            document: {
                id: '',
                visibility: 'ess_mss',
                description: '',
                file: null,
            },
            errors: {},
        };
    }

    render() {
        return (
            <CommonModal.CenterModal onClickClose={this.props.closeDocumentModal} minWidth={'775px'}>
                <CommonModal.StyledHeader color={stylingVariables.colorPalette.darkest} noBorder={true} padding={'36px 80px'}>
                    <CommonModal.StyledGentHeaderTitle>{intl.get('new_document')}</CommonModal.StyledGentHeaderTitle>
                </CommonModal.StyledHeader>

                <CommonModal.OverflowableContent style={{padding: '20px 80px'}}>
                    <Layout.Rows>
                        <DescriptionRow
                            onChange={this.onChangeDescription}
                            errors={this.state.errors[this.FIELD_DESCRIPTION]}/>
                        <FileRow
                            onChange={this.onChangeFile}
                            errors={this.state.errors[this.FIELD_FILE]}/>
                        <ESSVisibilityRow
                            visible={
                                this.state.document.visibility === 'ess_mss'
                            }
                            onChange={this.onChangeESSVisibility}
                        />
                    </Layout.Rows>
                </CommonModal.OverflowableContent>

                <Actions onSave={this.save} onCancel={this.props.closeDocumentModal} />
            </CommonModal.CenterModal>
        );
    }

    private save = async() => {
        const validator = new Validator(this.state.document, this.validationRules, this.validationMessages);

        validator.check();
        if (validator.passes()) {
            await this.props.createDocument(this.state.document);
            this.props.closeDocumentModal();
        } else {
            this.setState({errors: validator.errors.errors});
        }
    }

    private onChangeESSVisibility = (visibleInESS: boolean) => {
        const visibility = visibleInESS ? 'ess_mss' : 'mss';
        this.setState((state: IState) => ({...state, document: {...state.document, visibility}}));
    }

    private onChangeDescription = (description: string) => {
        this.setState((state: IState) => ({...state, document: {...state.document, description}}));
    }

    private onChangeFile = (file: File | null) => {
        this.setState((state: IState) => ({...state, document: {...state.document, file}}));
    }
}

const mapDispatchToProps = {
    createDocument,
    closeDocumentModal,
};

export default connect(null, mapDispatchToProps)(Modal);
