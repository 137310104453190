import React, {PureComponent} from "react";
import styled from 'styled-components';
import {intl} from '@global';

import {constants, stylingVariables} from '@global';
import {Moment} from 'moment';
import {Icon} from "@common";

const StyledActivityLogWrapper = styled.div`
  flex-grow: 1;
  background-color: ${stylingVariables.colorPalette.lightGray};
  border-radius: ${stylingVariables.layout.cardBorderRadius};
  color: ${stylingVariables.colorPalette.darkest};  
`;

const StyledActivityLogHeader = styled.div`
  padding: 20px 30px;
  font-size: 14px;
  font-weight: ${stylingVariables.fontWeight.semibold};
`;

const StyledEventWrapper = styled.div`
    font-size: ${stylingVariables.fontSize.mediumLarge};
    position: relative;
    margin-bottom: 40px; 
    height: 30px;
    &:before {
        content: ' ';
        display: inline-block;
        height: 44px;
        position: absolute;
        top: 22px;
        left: 8px;
        border: 1px dashed ${stylingVariables.colorPalette.darkGray};  
        clear:both;
    } 
    &:after {
        content: '';
        display: block;
        clear:both;
    }
    &:last-child {
        margin-bottom: 0;
        &:before {
          display: none;
        }
    } 
`;

const StyledEventHeader = styled.div`
      font-size: 13px;  
      svg{
        float: left;        
      }
      &:after {
        content: '';
        display: block;
        clear:both;
    }  
`;

const StyledEventTitle = styled.span`
    float: left;
    margin: 3px 5px;
    font-weight: bold;
`;

const StyledEventTime = styled.span`
    float: right;
    margin: 3px 5px;
    font-weight: normal;
    color: ${stylingVariables.colorPalette.deepGray};   
`;

const StyledSubstring = styled.div`
  font-size: 12px;
  font-weight: bold;
  margin: 5px 5px 5px 24px;
  color: ${stylingVariables.colorPalette.deepGray};  
`;

interface ActivityLogProps {
    title: string,
    items: { event: string, happened_at: Moment, by?: string, substring?: string }[]
}

export default class HistoryLog extends PureComponent<ActivityLogProps> {
    static defaultProps = {
        items: []
    };

    public render() {
        return (
            <StyledActivityLogWrapper>
                <StyledActivityLogHeader>{intl.get(this.props.title)}</StyledActivityLogHeader>

                <div style={{padding: '10px 25px 50px 25px'}}>
                    {
                        this.props.items.map((item, index) => (
                            <StyledEventWrapper key={index}>
                                <StyledEventHeader>
                                    <Icon type={constants.IconTypes.DOT_AND_CIRCLE}
                                          fillColor={stylingVariables.colorPalette.darkGray}
                                          height={19}
                                          width={19}
                                    />
                                    <StyledEventTitle>{item.event}</StyledEventTitle>
                                    <StyledEventTime>
                                        {item.happened_at.format('DD MMMM, YYYY')}
                                        {item.by && ` by ${item.by}`}
                                    </StyledEventTime>
                                </StyledEventHeader>

                                <StyledSubstring>{item.substring && `${item.substring}`}</StyledSubstring>
                            </StyledEventWrapper>
                        ))
                    }
                </div>
            </StyledActivityLogWrapper>
        );
    }
}
