import React, {PureComponent} from 'react';

import {ApiObject} from '@api';
import {EditableField as CommonEditableField} from '@common';

interface IProps {
    field: ApiObject.Field;
    errorMessage?: string|null;

    onChange: (field: ApiObject.Field, value: any) => void;
}

export default class EditableField extends PureComponent<IProps> {
    render() {
        return (
            <CommonEditableField
                key={`${this.props.field.entity}_${this.props.field.code}`}
                type={this.props.field.type}
                label={this.props.field.label}
                code={this.props.field.code}
                options={this.props.field.options}
                isRequired={this.props.field.required}
                errorMessage={this.props.errorMessage}
                onChange={this.handleChangeField}
            />
        );
    }

    private handleChangeField = (value: any) => this.props.onChange(this.props.field, value);
}
