import {AnyAction, Dispatch} from 'redux';

import {api, constants, interfaces} from '@global';
import {ApiLegalEntity, ApiSso, ApiObject, ApiPayroll, PayrollInterfaces, ApiEmployee} from '@api';
import {AccessManager, EnvironmentHelper, RouteHelper} from '@library';

import * as selectors from './selectors';
import {RootState} from '../rootReducer';
import UrlHelper from '../library/url.helper';
import {setFieldGroups} from './Employee/redux';
import * as Cookies from 'js-cookie';
import {ThunkAction} from 'redux-thunk';

type ThunkResult<R> = ThunkAction<R, RootState, undefined, AnyAction> | AnyAction;

export interface GlobalState {
    locale: string;
    isLoading: number;
    loadingMessage: string;
    isMenuCollapsed: boolean;
    currentLegalEntityId: number|null;
    accessToken: string|null;
    authorizedUser: ApiObject.AuthorizedUser|null;
    legalEntities: ApiObject.LegalEntity[];
    consolidationGroups: ApiObject.ConsolidationGroup[];
    roles: constants.Role[];
    permissions: interfaces.Permissions;
    currentPayrollPeriod: PayrollInterfaces.PayrollPeriod|null;
    errorModal: {
        isOpen: boolean,
        title: string|null,
        errors: string[],
    };
    searchQuery: string,
    employeeGroups: interfaces.EmployeeGroups;
}

export const defaultState: GlobalState = {
    locale: Cookies.get('locale') || 'en',
    isLoading: 0,
    loadingMessage: '',
    isMenuCollapsed: false,
    currentLegalEntityId: null,
    accessToken: null,
    authorizedUser: null,
    legalEntities: [],
    consolidationGroups: [],
    roles: [],
    permissions: {},
    currentPayrollPeriod: null,
    errorModal: {
        isOpen: false,
        title: null,
        errors: [],
    },
    searchQuery: '',
    employeeGroups: {},
};

export const SET_LOCALE = 'global/setLocale';
export const SING_OUT = 'global/singOut';
export const SET_IS_MENU_COLLAPSED = 'global/setIsMenuCollapsed';
export const START_LOADING = 'global/startLoading';
export const STOP_LOADING = 'global/stopLoading';
export const SET_LOADING_MESSAGE = 'global/setLoadingMessage';
export const OPEN_ERROR_MODAL = 'global/openErrorModal';
export const CLOSE_ERROR_MODAL = 'global/closeErrorModal';
export const STORE_AUTHORIZED_USER = 'global/storeAuthorizedUser';
export const SET_ACCESS_TOKEN = 'global/setAccessToken';
export const SELECT_LEGAL_ENTITY = 'global/selectLegalEntity';
export const SET_LEGAL_ENTITIES = 'global/setLegalEntities';
export const SET_CONSOLIDATION_GROUPS = 'global/setConsolidationGroups';
export const SET_CURRENT_PAYROLL_PERIOD = 'global/setCurrentPayrollPeriod';
export const SET_ROLES = 'global/setRoles';
export const SET_PERMISSIONS = 'global/setPermissions';
export const SET_SEARCH_QUERY = 'global/setSearchQuery';
export const SET_EMPLOYEE_GROUPS = 'global/setEmployeeGroups';

export default function reducer(
    state: GlobalState = defaultState,
    action: AnyAction,
): GlobalState {
    switch (action.type) {
        case SET_LOCALE:
            return {
                ...state,
                locale: action.payload,
            };
        case SET_IS_MENU_COLLAPSED:
            return {
                ...state,
                isMenuCollapsed: action.payload,
            };
        case START_LOADING:
            return {
                ...state,
                isLoading: state.isLoading + 1,
            };
        case STOP_LOADING:
            return {
                ...state,
                isLoading: state.isLoading > 0 ? state.isLoading - 1 : 0,
            };
        case SET_LOADING_MESSAGE:
            return {
                ...state,
                loadingMessage: action.payload,
            };
        case OPEN_ERROR_MODAL:
            return {
                ...state,
                errorModal: {
                    isOpen: true,
                    ...action.payload,
                },
            };
        case CLOSE_ERROR_MODAL:
            return {
                ...state,
                errorModal: {
                    ...defaultState.errorModal,
                },
            };
        case STORE_AUTHORIZED_USER:
            return {
                ...state,
                authorizedUser: action.payload,
            };
        case SET_ACCESS_TOKEN:
            return {
                ...state,
                accessToken: action.payload,
            };
        case SET_LEGAL_ENTITIES:
            return {
                ...state,
                legalEntities: action.payload,
            };
        case SET_CONSOLIDATION_GROUPS:
            return {
                ...state,
                consolidationGroups: action.payload,
            };
        case SELECT_LEGAL_ENTITY:
            return {
                ...state,
                currentLegalEntityId: action.payload,
            };
        case SET_CURRENT_PAYROLL_PERIOD:
            return {
                ...state,
                currentPayrollPeriod: action.payload,
            };
        case SET_ROLES:
            return {
                ...state,
                roles: action.payload,
            };
        case SET_PERMISSIONS:
            return {
                ...state,
                permissions: action.payload,
            };
        case SET_SEARCH_QUERY:
            return {
                ...state,
                searchQuery: action.payload,
            };
        case SET_EMPLOYEE_GROUPS:
            return {
                ...state,
                employeeGroups: action.payload,
            };
        default:
            return state;
    }
}

export function setLocale(locale: string) :AnyAction {
    return {
        type: SET_LOCALE,
        payload: locale,
    };
}

export function setSearchQuery(searchQuery: string) :AnyAction {
    return {
        type: SET_SEARCH_QUERY,
        payload: searchQuery,
    };
}

export function setIsMenuCollapsed(isMenuCollapsed: boolean): AnyAction {
    return {
        type: SET_IS_MENU_COLLAPSED,
        payload: isMenuCollapsed,
    };
}

export function startLoading(): AnyAction {
    return { type: START_LOADING };
}

export function setLoadingMessage(loadingMessage: string): AnyAction {
    return {
        type: SET_LOADING_MESSAGE,
        payload: loadingMessage,
    };
}

export function stopLoading(): AnyAction {
    return { type: STOP_LOADING };
}

export function openErrorModal(errors: string[], title: string|null = null) {
    return {
        type: OPEN_ERROR_MODAL,
        payload: { title, errors },
    };
}

export function closeErrorModal() {
    return {
        type: CLOSE_ERROR_MODAL,
    };
}

export function setEmployeeGroups(data: any): AnyAction {
    return {
        type: SET_EMPLOYEE_GROUPS,
        payload: data,
    };
}

export function authorizeAndLoadEntities(accessToken: string | null) {
    return async(dispatch: Dispatch, getState: () => RootState) => {
        dispatch(startLoading());

        if (accessToken && accessToken !== getState().global.accessToken) {
            dispatch({ type: SING_OUT });

            dispatch(setLoadingMessage('user_auth'));
            const user = await ApiSso.login(accessToken);

            dispatch(setLoadingMessage('getting_user_photo'));
            const config = {
                headers: {
                    [constants.HttpHeaders.X_SESSION_TOKEN]: user.sessionToken,
                },
            };
            const photoResponse = await api.get('ess/employee/photo', config);
            if (photoResponse.data instanceof Object && photoResponse.data.accessToken) {
                const {data} = await api.get(`ess/employee/file/${photoResponse.data.accessToken}`, {
                    responseType: 'arraybuffer',
                    ...config,
                });

                const mimeType = photoResponse.data.file.mimeType || 'image/png';
                user.photo = `data:${mimeType};base64,`
                    + Buffer.from(data, 'binary').toString('base64');
            }

            dispatch(storeAuthorizedUser(user));
            dispatch(setAccessToken(accessToken));
        }

        const authorizedUser = selectors.getAuthorizedUser(getState());
        if (!authorizedUser) {
            dispatch({ type: SING_OUT });

            RouteHelper.goToLaunchpad();
        }

        dispatch(setLoadingMessage('getting_legal_entities'));
        dispatch(
            setLegalEntities(await ApiLegalEntity.list()),
        );

        dispatch(setLoadingMessage('getting_consolidation_groups'));
        dispatch(
            setConsolidationGroups(await ApiLegalEntity.consolidationGroups()),
        );

        dispatch(updatePermissionDepends() as any);

        dispatch(stopLoading());

        return;
    };
}

export function authorizeAndLoadGlobalProps(accessToken: string | null, currentLegalEntityId: number | null) {
    return async(dispatch: Dispatch, getState: () => RootState) => {
        dispatch(startLoading());

        if (accessToken && accessToken !== getState().global.accessToken) {
            dispatch({ type: SING_OUT });

            dispatch(setLoadingMessage('user_auth'));
            const user = await ApiSso.login(accessToken);

            if (!user) {
                dispatch({ type: SING_OUT });

                RouteHelper.goToLogoutRoute();
                return;
            }

            dispatch(setLoadingMessage('getting_user_photo'));
            const config = {
                headers: {
                    [constants.HttpHeaders.X_SESSION_TOKEN]: user.sessionToken,
                },
            };
            const photoResponse = await api.get('ess/employee/photo', config);
            if (photoResponse.data instanceof Object && photoResponse.data.accessToken) {
                const {data} = await api.get(`ess/employee/file/${photoResponse.data.accessToken}`, {
                    responseType: 'arraybuffer',
                    ...config,
                });

                const mimeType = photoResponse.data.file.mimeType || 'image/png';
                user.photo = `data:${mimeType};base64,`
                    + Buffer.from(data, 'binary').toString('base64');
            }

            dispatch(storeAuthorizedUser(user));
            dispatch(setAccessToken(accessToken));
        }

        const authorizedUser = selectors.getAuthorizedUser(getState());
        if (!authorizedUser) {
            dispatch({ type: SING_OUT });

            RouteHelper.goToLaunchpad();
        }

        dispatch(setLoadingMessage('getting_legal_entities'));
        let entities: ApiObject.LegalEntity[] = await ApiLegalEntity.list();
        const current = entities.find(
            (legalEntity: ApiObject.LegalEntity) => legalEntity.id === currentLegalEntityId,
        );

        if (currentLegalEntityId && current) {
            if (current.logoFileId) {
                dispatch(setLoadingMessage('getting_company_logo'));
                const logo = await ApiLegalEntity.getLogo(current.id, current.logoFileId);

                entities = entities.map(entity => {
                    if (current && entity.id === current.id) {
                        return {...current, logo};
                    }

                    return entity;
                });
            }
            if (currentLegalEntityId !== selectors.getCurrentLegalEntityId(getState())){
                dispatch(selectLegalEntity(currentLegalEntityId));
            }
            dispatch(setFieldGroups([]));
            dispatch(setLoadingMessage('getting_current_period_info'));
            dispatch(setCurrentPayrollPeriod(
                await ApiPayroll.getCurrentPayrollPeriod(currentLegalEntityId),
            ));
        }

        dispatch(setLegalEntities(entities));

        dispatch(setLoadingMessage('getting_consolidation_groups'));
        dispatch(
            setConsolidationGroups(await ApiLegalEntity.consolidationGroups()),
        );

        dispatch(updatePermissionDepends() as any);

        dispatch(stopLoading());

        return;
    };
}

export function singOut() {
    return async(dispatch: Dispatch) => {
        try {
            await RouteHelper.goToLogoutRoute()
        } catch (e) {
            console.error(e.response.status);
        }

        dispatch({ type: SING_OUT });

        return;
    };
}

export function storeAuthorizedUser(authorizedUser: ApiObject.AuthorizedUser): AnyAction {
    return {
        type: STORE_AUTHORIZED_USER,
        payload: authorizedUser,
    };
}

export function selectLegalEntity(id: number|null): AnyAction {
    return {
        type: SELECT_LEGAL_ENTITY,
        payload: id,
    };
}

export function setLegalEntities(entities: ApiObject.LegalEntity[]): AnyAction {
    return {
        type: SET_LEGAL_ENTITIES,
        payload: entities,
    };
}

export function setCurrentPayrollPeriod(period: PayrollInterfaces.PayrollPeriod|null) {
    return {
        type: SET_CURRENT_PAYROLL_PERIOD,
        payload: period,
    };
}

export function setAccessToken(accessToken: string) {
    return {
        type: SET_ACCESS_TOKEN,
        payload: accessToken,
    };
}

export function setConsolidationGroups(groups: ApiObject.ConsolidationGroup[]): AnyAction {
    return {
        type: SET_CONSOLIDATION_GROUPS,
        payload: groups,
    };
}

/**
 * Update ROLES, PERMISSIONS by current legal entity
 * @returns {Function}
 */
export const updatePermissionDepends = () => {
    return async(dispatch: Dispatch, getState: () => RootState) => {
        try {
            let permissions: interfaces.Permissions = {};
            let roles: constants.Role[] = [];

            const currentLegalEntity = selectors.getCurrentLegalEntity(getState(), null);
            if (currentLegalEntity) {
                roles = selectors.getCurrentRoles(getState(), null);
                permissions = (new AccessManager(roles, currentLegalEntity.masterdataEditAllowed || false))
                    .getPermissions();
            }

            dispatch({type: SET_ROLES, payload: roles });
            dispatch({type: SET_PERMISSIONS, payload: permissions });
        } catch (error) {
            console.log(error); // eslint-disable-line no-console
        }
    };
};


export const updateEmployeePermissions = (masterdataEditAllowed: boolean) => {
    return async(dispatch: Dispatch, getState: () => RootState) => {
        try {
            let permissions: interfaces.Permissions = {};
            let roles: constants.Role[] = [];

            roles = selectors.getCurrentRoles(getState(), null);
            permissions = (new AccessManager(roles, masterdataEditAllowed))
                .getPermissions();

            dispatch({type: SET_ROLES, payload: roles });
            dispatch({type: SET_PERMISSIONS, payload: permissions });
        } catch (error) {
            console.log(error); // eslint-disable-line no-console
        }
    };
};


export function goToOldMssRoute(path: string) {
    return async(dispatch: Dispatch, getState: () => RootState) => {
        if (!EnvironmentHelper.isCookieEnabled()) {
            return false;
        }

        const sessionToken = selectors.getSessionToken(getState());
        const entityId = selectors.getCurrentLegalEntityId(getState() );
        if (!sessionToken) {
            return;
        }

        const accessToken = await ApiSso.getAccessToken(sessionToken);
        let href = `${EnvironmentHelper.getOldMssUrl()}${path}`
            + `?${UrlHelper.ACCESS_TOKEN}=${accessToken}`;
        if (entityId) {
            href += `&${UrlHelper.LEGAL_ENTITY_ID}=${entityId}`;
        }
        href += `&${UrlHelper.SUPPORT_NEW_MSS}=${EnvironmentHelper.isSupportNewMSS()}`;

        window.location.replace(href);
    };
}

export function getMfRoute(route: string) {
    return async(dispatch: Dispatch, getState: () => RootState) => {
        const sessionToken = selectors.getSessionToken(getState());
        const entityId = selectors.getCurrentLegalEntityId(getState());
        if (!sessionToken) {
            return;
        }

        const accessToken = await ApiSso.getAccessToken(sessionToken, EnvironmentHelper.getLaunchpadClientId());

        let href = `${EnvironmentHelper.getLaunchpadUrl()}`
            + route
            + `?${UrlHelper.ACCESS_TOKEN}=${accessToken}`;
        if (entityId) {
            href += `&${UrlHelper.LEGAL_ENTITY_ID}=${entityId}`;
        }

        window.location.replace(href);
    };
}

export function getCurrentPayrollPeriod() {
    return async(dispatch: Dispatch, getState: () => RootState) => {
        const currentLegalEntityId = selectors.getCurrentLegalEntityId(getState());
        if (!currentLegalEntityId) {
            return;
        }

        dispatch(setCurrentPayrollPeriod(
            await ApiPayroll.getCurrentPayrollPeriod(currentLegalEntityId),
        ));
    };
}

export function getEmployeeGroups(): ThunkResult<Promise<void>> {
    return async(dispatch: Dispatch, getState: () => RootState) => {
        const currentLegalEntityId = getState().global.currentLegalEntityId;
        if (!currentLegalEntityId) {
            return;
        }

        dispatch(
          setEmployeeGroups(await ApiEmployee.getEmployeeGroups(currentLegalEntityId)),
        );

        return;
    };
}
