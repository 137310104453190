import React, {Fragment, memo} from 'react';
import {Column} from 'react-virtualized';

import {defaultInfiniteTableCellDataGetter, Checkbox} from '@common';

import * as Headers from '../Header';

interface IndexColumnProps {
    selectedIds: Set<number>;

    onChangeSelection: (id: number) => void;
}

const Cell: React.FC<{cellData: any} & IndexColumnProps> = memo(({cellData, selectedIds, onChangeSelection}) => {
    const isChecked = selectedIds.has(cellData.id);

    return (
        <Fragment>
            <span className={'index'}>{cellData.index}</span>

            <Checkbox isChecked={isChecked}
                      onClick={(e: any) => {
                        e.stopPropagation()
                        onChangeSelection(cellData.id)
                      }}
                      className={isChecked ? 'checkbox checked' : 'checkbox'}
            />
        </Fragment>
    );
});

const SelectedIndex: React.FC<IndexColumnProps> = ({selectedIds, onChangeSelection}) => (
    <Column
        label={'#'}
        dataKey='index'
        width={60}
        cellDataGetter={defaultInfiniteTableCellDataGetter}
        headerRenderer={() => <Headers.SelectedIndex/>}
        cellRenderer={({cellData}) => (
            <Cell cellData={cellData} selectedIds={selectedIds} onChangeSelection={onChangeSelection}/>
        )}
    />
);

export default SelectedIndex;
