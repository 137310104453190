import styled from 'styled-components';
import {stylingVariables} from '@global';

export const th = styled.th`
  padding: 10px 25px;
  font-weight: ${stylingVariables.fontWeight.regular};
  font-size: ${stylingVariables.fontSize.medium};
  color: ${stylingVariables.colorPalette.deepGray};
  text-align: left;
`;

export const tr = styled.tr`
`;

export const table = styled.table`
  width: 100%;
  border-collapse: separate;
`;

export const thead = styled.thead`
  ${tr} {
    border-bottom: 0;
    border-top: 0;
  }
`;

export const tbody = styled.tbody`
`;

export const td = styled.td`
  white-space: nowrap;
  font-weight: ${stylingVariables.fontWeight.regular};
  font-size: ${stylingVariables.fontSize.medium};
  background-color: ${stylingVariables.colorPalette.gray};
  text-align: left;
  padding: 10px 25px;
  vertical-align: middle;
  position: relative;
  border-top: 5px solid ${stylingVariables.colorPalette.white};
  border-bottom: 5px solid ${stylingVariables.colorPalette.white};
`;
