import React, {PureComponent} from 'react';
import styled from 'styled-components';

import {interfaces} from '@global';
import {InfiniteTableHeader} from '@common';

import FilterContent from './FilterContent';

const StyledHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
`;

interface IProps {
    label: string;

    orders?: interfaces.ListingOrders;
    filters?: interfaces.ListingFilters;
    changeOrder?: (column: string) => void;
    changeFilter?: (params: interfaces.FilterParams) => void;
    getEmployees?: (
        query: string,
        limit: number,
        page: number,
    ) => any;
}

export default class Employee extends PureComponent<IProps> {
    render() {
        return (
            <StyledHeaderWrapper>
                {this.props.label}

                <StyledActionsWrapper>
                    <InfiniteTableHeader.Filter column={'id'} filters={this.props.filters}>
                        <FilterContent
                            filters={this.props.filters}
                            changeFilter={this.props.changeFilter}
                            getEmployees={this.props.getEmployees}
                        />
                    </InfiniteTableHeader.Filter>

                    <InfiniteTableHeader.Order column={'id'}
                           orders={this.props.orders}
                           changeOrder={this.props.changeOrder}
                    />
                </StyledActionsWrapper>
            </StyledHeaderWrapper>
        );
    }
}
