import {api} from '@global';

const PageSlugs = {
    EMPLOYEES: 'employees',
    ACCESS_MANAGEMENT: 'access-management',
    LEAVES: 'leaves',
}

export default class ApiColumnSettings {
    public static async getSelectedColumns(legalEntityId: number, pageSlug: string): Promise<{
        userId: number,
        legalEntityId: number,
        columns: string[],
    }> {
        const {data} = await api.get(`/api/settings/${legalEntityId}/${pageSlug}-columns`);

        return data.data;
    }

    public static async saveColumnSettings(legalEntityId: number, columns: string[], pageSlug: string): Promise<void> {
        await api.post(`/api/settings/${legalEntityId}/${pageSlug}-columns`, { columns });

        return;
    }

    public static async getSelectedEmployeesColumns(legalEntityId: number): Promise<{
        userId: number,
        legalEntityId: number,
        columns: string[],
    }> {
        return this.getSelectedColumns(legalEntityId, PageSlugs.EMPLOYEES);
    }

    public static async saveEmployeesColumnSettings(legalEntityId: number, columns: string[]): Promise<void> {
        await this.saveColumnSettings(legalEntityId, columns, PageSlugs.EMPLOYEES);

        return;
    }

    public static async getSelectedLeavesColumns(legalEntityId: number): Promise<{
        userId: number,
        legalEntityId: number,
        columns: string[],
    }> {
        return this.getSelectedColumns(legalEntityId, PageSlugs.LEAVES);
    }

    public static async saveLeavesColumnSettings(legalEntityId: number, columns: string[]): Promise<void> {
        await this.saveColumnSettings(legalEntityId, columns, PageSlugs.LEAVES);

        return;
    }
}
