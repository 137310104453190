import { ApiObject } from '@api'
import React, { PureComponent } from 'react'
import { intl } from '@global'
import styled from 'styled-components'

import { constants } from '@global'
import { Can, StyledEditButton } from '@common'

import Group from './Group'

const Wrapper = styled.div``
const LeftSide = styled.div`
  padding-left: 37px;
  flex: 0 0 60%;
  min-width: 690px;
`

interface IProps {
  fields: ApiObject.Fields
  fieldGroup: ApiObject.FieldGroup
  cuttOffs?: Object|null;
  onClickEditButton: () => void
}

class View extends PureComponent<IProps> {
  public render() {
    return (
      <Wrapper>
        <LeftSide>
          <Group fields={this.props.fields} group={this.props.fieldGroup} />

          {this.props.fieldGroup.subgroups.map((subFieldGroup: ApiObject.FieldGroup) => (
            <Group key={subFieldGroup.code} fields={this.props.fields} group={subFieldGroup} />
          ))}
        </LeftSide>

        <Can cuttOffs={this.props.cuttOffs} run={constants.PermissionCode.EMPLOYEE_FIELDS_EDIT}>
          <StyledEditButton onClick={this.props.onClickEditButton}>{intl.get('edit')}</StyledEditButton>
        </Can>
      </Wrapper>
    )
  }
}

export default View
