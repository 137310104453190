import {constants} from '@global';
import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {Route} from 'react-router-dom';
import {getEmployeeGroups, getTemplates} from './redux';
import SelectData from './SelectData/SelectData';
import SelectEmployees from './SelectEmployees/SelectEmployees';
import Preview from './Preview/Preview';
import Export from './Export/Export';

interface IProps {
    getEmployeeGroups: () => any;
    getTemplates: () => void;
}

export class Routes extends PureComponent<IProps> {
    componentDidMount() {
        this.props.getEmployeeGroups();
        this.props.getTemplates();
    }

    public render() {
        return <>
           <Route exact path={constants.ROUTE_REPORTS_PROCESS_SELECT_DATA} component={SelectData} />
           <Route exact path={constants.ROUTE_REPORTS_PROCESS_SELECT_EMPLOYEES} component={SelectEmployees} />
           <Route exact path={constants.ROUTE_REPORTS_PROCESS_PREVIEW} component={Preview} />
           <Route exact path={constants.ROUTE_REPORTS_PROCESS_EXPORT} component={Export} />
        </>
    }
}

const mapDispatchToProps = {
    getEmployeeGroups,
    getTemplates,
};

export default connect(
    null,
    mapDispatchToProps,
)(Routes);
