import styled from 'styled-components';

const BlankButton = styled.button<{isDisabled?: boolean}>`
  padding: 0;
  outline: none;
  background: transparent;
  border: none;
  box-shadow: none;
  cursor: pointer;

  ${props => !props.onClick && `
    pointer-events: none;
  `}

  ${props => props.isDisabled && `
    pointer-events: none;
    opacity: .6;
  `}
`;

export default BlankButton;
