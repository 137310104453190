import {saveAs} from 'file-saver';
import {ApiObject, EmployeeInterfaces, PayrollInterfaces} from '@api';
import {api, constants, interfaces, intl} from '@global';
import {EmployeeListingHelper, SingleToastMessage} from '@library';
import {QueryOperator} from './api.object';

export default class ApiEmployee {
  public static async list(legalEntityId: number, params: EmployeeQueryParams) {
    const { data } = await api.post(`/masterdata/public/legal-entity/${legalEntityId}/employees`, params.params());

    if (!data.data) {
      return { employees: [], total: 0, nextPointer: null };
    }

    return {
      employees: data.data.content || [],
      total: data.data.total || 0,
      nextPointer: data.data.nextPointer,
    };
  }

  public static async findByIds(legalEntityId: number, ids: number[]) {
    if (ids.length === 0) {
      return [];
    }

    const { data } = await api.post(`/masterdata/public/legal-entity/${legalEntityId}/employees`, {
      employeeIds: ids,
    });

    return data.data ? data.data.content || [] : [];
  }

  public static async findByQuery(
    legalEntityId: number,
    query: string,
    limit?: number,
    offset?: number,
    params: any = {},
    withTotal: boolean = false,
  ) {
    params = {
      ...params,
      search: query,
    };
    if (limit) {
      params = { ...params, limit };
    }
    if (offset) {
      params = { ...params, offset };
    }
    const { data } = await api.post(`/masterdata/public/legal-entity/${legalEntityId}/employees`, params);

    return data.data ? (withTotal ? data.data : data.data.content || []) : [];
  }

  public static async findByQueryWithPage(
    legalEntityId: number,
    query: string,
    limit: number = 100,
    page: number = 0,
    params: any = {},
    withTotal: boolean = false,
  ) {
    return ApiEmployee.findByQuery(legalEntityId, query, limit, limit * page, params, withTotal);
  }

  public static async findConsolidationGroupEmployeeById(
    legalEntityId: number,
    employeeId: number,
  ) {
    if (!employeeId) {
      return;
    }
    const { data } = await api.post(
        `/mss/legal-entity/${legalEntityId}/employees/listing`,
        { employeeIds: [employeeId], includeExternal: true, searchInConsolidationGroup: true },
    );
    return data?.data?.content || [];
  }

  public static async findConsolidationGroupEmployees(
    legalEntityId: number,
    query: string,
    limit: number = 20,
    pointer?: string | null,
    params: any = {},
  ) {

    params = {
      ...params,
      search: query,
      searchInConsolidationGroup: true,
      orderBy: [{
        entity: 'employee',
        field: 'reference_code',
        order: 'asc',
      }],
    };
    if (limit) {
      params = { ...params, pageSize: limit };
    }
    if (pointer) {
      params = { pointer };
    }
    const { data } = await api.post(
      `/mss/legal-entity/${legalEntityId}/employees/listing`,
      params,
    );

    return data?.data;
  }

  public static async getFieldGroups(legalEntityId: number) {
    const { data } = await api.get(`/mss/legal-entity/${legalEntityId}/field-groups`);

    return data.data;
  }

  public static async getEmployeeGroups(legalEntityId: number) {
    const { data } = await api.get(`/mss/legal-entity/${legalEntityId}/employee-groups`);

    return data.data;
  }

  public static async getEmployeeCutOffs(legalEntityId: number, ids: number[]) {
    const result = await api.post(`/masterdata/public/legal-entity/${legalEntityId}/employees/cut-offs`, {
      employeeIds: ids,
    });
    const { data } = result;

    return data ? data.data : result;
  }

  public static async getEmployeeFields(legalEntityId: number, employeeId: number) {
    const { data } = await api.get(`/mss/legal-entity/${legalEntityId}/employee/${employeeId}/fields`);

    return data.data;
  }

  public static async saveEmployeeFields(legalEntityId: number, employeeId: number, fields: any) {
    const result = await api.put(`/mss/legal-entity/${legalEntityId}/employee/${employeeId}/`, fields);
    const { data } = result;

    return data ? data.data : result;
  }

  public static async createEmployeeFields(legalEntityId: number, fields: any) {
    const result = await api.post(`/mss/legal-entity/${legalEntityId}/employees`, fields);
    const { data } = result;

    return data ? data.data : result;
  }

  public static async getEmployeePayElements(legalEntityId: number, employeeId: number) {
    const { data } = await api.get(`/mss/legal-entity/${legalEntityId}/employee/${employeeId}/pay-elements`);

    return data.data;
  }

  public static async saveEmployeePayElement(legalEntityId: number, employeeId: number, requestBody: any) {
    const { data } = await api.post(
      `/mss/legal-entity/${legalEntityId}/employee/${employeeId}/pay-elements`,
      requestBody,
    );

    return data.data;
  }

  public static async deleteEmployeePayElement(legalEntityId: number, employeeId: number, requestBody: any) {
    const { data } = await api.delete(`/mss/legal-entity/${legalEntityId}/employee/${employeeId}/pay-elements`, {
      data: requestBody,
    });

    return data.data;
  }

  public static async activate(legalEntityId: number, ids: Set<number>): Promise<ActivationError[]> {
    const response = await api.put(`/mss/legal-entity/${legalEntityId}/employees/activate`, Array.from(ids));
    if (!response.data.data || !response.data.data.jobId) {
      return [];
    }

    const checkResponse = await ApiEmployee.checkActivationStatus(legalEntityId, response.data.data.jobId);

    return checkResponse.data.data || [];
  }

  public static async checkActivationStatus(legalEntityId: number, jobId: string) {
    const response = await api.get(`/mss/legal-entity/${legalEntityId}/employees/activate/check/${jobId}`);
    const activating = new SingleToastMessage(
      'toast_id_employees_activating',
      intl.get('employees_are_being_activated'),
      'info',
    );
    if (202 === response.data.status) {
      activating.show();
      return new Promise(resolve => {
        setTimeout(async() => resolve(await ApiEmployee.checkActivationStatus(legalEntityId, jobId)), 1000);
      });
    }
    activating.close();

    return response;
  }

  public static async suspend(legalEntityId: number, id: number) {
    return await api.put(`/mss/legal-entity/${legalEntityId}/employee/${id}/inactivate`);
  }

  public static async removeTermination(legalEntityId: number, id: number) {
    const response = await api.post(
      `/masterdata/public/legal-entity/${legalEntityId}/employee/${id}/remove-termination`
    );
    return !!(response && response.status === 200);
  }

  public static async sendEssInvitation(legalEntityId: number, payload: EssInvitationPayload) {
    return await api.post(`/mss/legal-entity/${legalEntityId}/employees/ess`, payload);
  }

  public static async revokeEssAccess(legalEntityId: number, ids: Set<number>) {
    return await api.delete(`/mss/legal-entity/${legalEntityId}/employees/ess`, {
      data: Array.from(ids),
    });
  }

  public static async getSeparationFieldGroups(legalEntityId: number, action: constants.TerminationTypes) {
    const { data } = await api.get(`/mss/legal-entity/${legalEntityId}/field-groups/termination-${action}`);

    return data.data;
  }

  public static async getTerminationTask(
    legalEntityId: number,
    id: number,
    action: constants.TerminationTypes,
    defaultTimeOuts: number[] = [],
  ) {
    const response = await api.get(
      `/mss/legal-entity/${legalEntityId}/employee/${id}/termination-${action}`,
    );
    const attempts = [...defaultTimeOuts];

    if (204 === response.status && attempts.length > 0) {
      const timeout = attempts.shift();
      return new Promise(resolve => {
        setTimeout(
          async() => resolve(await ApiEmployee.getTerminationTask(legalEntityId, id, action, attempts)),
          timeout,
        );
      });
    }

    return response;
  }
  
  public static async getSeparationDetails(
    legalEntityId: number,
    id: number,
  ) {
    const attempts = [
      ...new Array(10).fill(200),
    ];

    const response = await ApiEmployee.getTerminationTask(legalEntityId, id, constants.TerminationTypes.details, attempts);
    return response?.data?.data || [];
  }

  public static async getSeparationPayout(
    legalEntityId: number,
    id: number,
  ) {

    const attempts = [
      ...new Array(50).fill(500),
      ...new Array(40).fill(1000),
    ] 

    const response = await ApiEmployee.getTerminationTask(legalEntityId, id, constants.TerminationTypes.payout, attempts);

    return response?.data?.data || {terminationFieldEntities: [], terminationPayElements: []}
  }

  public static async saveSeparation(entityId: number, id: number, payload: any, action: constants.TerminationTypes) {
    return await api.post(`/mss/legal-entity/${entityId}/employee/${id}/termination-${action}`, payload);
  }

  /** DOCUMENTS */
  public static async getDocuments(legalEntityId: number, employeeId: number) {
    const { data } = await api.get(`/mss/legal-entity/${legalEntityId}/employee/${employeeId}/documents`);

    return data.data;
  }

  public static async saveDocument(legalEntityId: number, employeeId: number, document: ApiObject.FileHandle) {
    if (!document.file) {
      return false;
    }

    const formData = new FormData();
    formData.append('file', document.file);
    formData.append(
      'fileHandle',
      JSON.stringify({
        visibility: document.visibility || 'mss',
        description: document.description,
        displayName: document.file.name,
      }),
    );

    return await api.post(`mss/legal-entity/${legalEntityId}/employee/${employeeId}/documents`, formData, {
      headers: {
        'content-type': 'multipart/form-data',
      },
    });
  }

  public static async deleteDocument(legalEntityId: number, employeeId: number, id: string) {
    return await api.delete(`/mss/legal-entity/${legalEntityId}/employee/${employeeId}/documents/${id}`);
  }

  public static async downloadDocument(legalEntityId: number, employeeId: number, id: string) {
    const { data, headers } = await api.get(
      `mss/legal-entity/${legalEntityId}/employee/${employeeId}/documents/${id}`,
      { responseType: 'arraybuffer' },
    );

    const blob = new Blob([data], { type: headers['content-type'] });
    saveAs(blob, headers['x-filename']);
  }

  public static async getPhoto(legalEntityId: number, photoAccessToken: string) {
    const response = await api.get(`/mss/legal-entity/${legalEntityId}/file/${photoAccessToken}`, {
      responseType: 'arraybuffer',
    });

    if (!response?.data) {
      return '';
    }

    return 'data:image/png;base64,' + Buffer.from(response.data, 'binary').toString('base64');
  }

  public static async createUserForPaymentProcessor(legalEntityId: number, employeeId: number) {
    const data = await api.post(
      `/masterdata/public/legal-entity/${legalEntityId}/employee/${employeeId}/payment-processor/user`,
    );

    if (data?.errors) {
      return { errors: data?.errors };
    }

    return data?.data?.data || {};
  }

  public static async getCountriesAndCurrencies() {
    const data = await api.get('/masterdata/public/payment-processor/country-and-currency-details');

    return data?.data?.data || {};
  }

  public static async getFieldsForBankAccount(legalEntityId: number, employeeId: number, params: object) {
    const data = await api.get(
      `/masterdata/public/legal-entity/${legalEntityId}/employee/${employeeId}/payment-processor/fields`,
      { params },
    );

    return data?.data?.data || {};
  }

  public static async createBankAccount(
    legalEntityId: number,
    employeeId: number,
    bankAccountFields: ApiObject.BankAccountField[],
  ) {
    const data = await api.post(
      `/masterdata/public/legal-entity/${legalEntityId}/employee/${employeeId}/payment-processor/bank-details`,
      { bankAccountFields },
    );

    return {
      data: data?.data?.data,
      errors: data?.errors || [],
    };
  }

  public static async getBankAccount(legalEntityId: number, employeeId: number) {
    const data = await api.get(
      `/masterdata/public/legal-entity/${legalEntityId}/employee/${employeeId}/payment-processor/bank-details`,
    );

    return data?.data?.data || {};
  }
}

interface IRequestPointerParams {
  pointer: string;
}

interface IRequestParams {
  limit?: number;
  offset: number;
  employeeStatus?: string | null;
  managerEmployeeIds?: number[];
  terminationStatus?: ApiObject.TerminationStatus | null;
  orderBy?: ApiObject.EntityFieldOrderBy[] | null;
  pageSize?: number;
  pointer?: string;
  queries?: ApiObject.Queries | null;
  groupedQueries?: ApiObject.GrouperQueries | null;
  search?: string | null;
  employeeIds?: number[] | null;
}

type RequestParams = IRequestParams | IRequestPointerParams;

interface EmployeeQueryParamsInterface {
  nextPointer?: string;

  pageSize?: number;
  limit?: number;
  offset?: number;
  status?: string | null;
  orders?: interfaces.ListingOrders | null;
  filters?: interfaces.ListingFilters | null;
  tags?: EmployeeInterfaces.Tag[];
  employeeIds?: number[];
  currentPeriod?: PayrollInterfaces.PayrollPeriod | null;
  searchQuery?: string | null;
  queries?: ApiObject.Queries | null;
  groupedQueries?: ApiObject.GrouperQueries | null;
}

export class EmployeeQueryParams {
  public static DEFAULT_LIMIT = 20;

  private readonly limit: number;
  private readonly offset: number;
  private readonly pageSize: number | undefined;
  private employeeIds: number[];
  public status: string | null;
  public tags: EmployeeInterfaces.Tag[];
  public orders: interfaces.ListingOrders | null;
  public filters: interfaces.ListingFilters;
  public currentPeriod: PayrollInterfaces.PayrollPeriod | null;
  public searchQuery: string | null;
  public queries: ApiObject.Queries;
  public groupedQueries: ApiObject.GrouperQueries;
  public nextPointer: string | null;

  public constructor({
    pageSize,
    limit,
    offset,
    status,
    orders,
    filters,
    tags,
    employeeIds,
    currentPeriod,
    searchQuery,
    queries,
    groupedQueries,
    nextPointer,
  }: EmployeeQueryParamsInterface = {}) {
    this.limit = limit || EmployeeQueryParams.DEFAULT_LIMIT;
    this.pageSize = pageSize;
    this.offset = offset || 0;
    this.status = status || null;
    this.orders = orders || null;
    this.filters = filters || {};
    this.employeeIds = employeeIds || [];
    this.tags = tags || [];
    this.currentPeriod = currentPeriod || null;
    this.searchQuery = searchQuery || null;
    this.queries = queries || [];
    this.groupedQueries = groupedQueries || [];
    this.nextPointer = nextPointer || null;
  }

  public params(): RequestParams {
    if (this.nextPointer) {
      return {
        pointer: this.nextPointer,
      };
    }

    const params: RequestParams = {
      ...(this.pageSize ? {pageSize: this.pageSize} : {limit: this.limit}),
      offset: this.offset,
    };

    const filters = { ...this.filters };
    const queries = [...this.queries];
    const groupedQueries = [...this.groupedQueries];

    if (this.status) {
      params.queries = [...this.queries, {
        entity: 'employee',
        field: 'status',
        operator: QueryOperator.eq,
        value: this.status,
      }];
    }
    if (this.employeeIds.length > 0) {
      params.employeeIds = this.employeeIds;
    }

    if (this.tags.includes(EmployeeInterfaces.Tag.pending_termination)) {
      params.queries = [...this.queries, {
        entity: 'employee_termination_event',
        field: 'status',
        operator: QueryOperator.eq,
        value: ApiObject.TerminationStatus.draft,
      }];
    }

    if (this.currentPeriod && this.tags.includes(EmployeeInterfaces.Tag.starters)) {
      filters.hireDate = {
        column: 'hireDate',
        expression: ApiObject.QueryOperator.eq,
        type: ApiObject.FieldType.date,
        value: [this.currentPeriod.beginDate, this.currentPeriod.endDate],
      };
    }

    if (this.currentPeriod && this.tags.includes(EmployeeInterfaces.Tag.leavers)) {
      filters.lastWorkingDate = {
        column: 'lastWorkingDate',
        expression: ApiObject.QueryOperator.eq,
        type: ApiObject.FieldType.date,
        value: [this.currentPeriod.beginDate, this.currentPeriod.endDate],
      };
    }

    if (this.orders && Object.keys(this.orders).length > 0) {
      params.orderBy = EmployeeListingHelper.getQueryOrders(this.orders);
    }

    const hasFilters: boolean = filters && Object.keys(filters).length > 0;
    if (hasFilters) {
      const queries = EmployeeListingHelper.getQueryFilters(filters);
      if (queries.length > 0) {
        params.queries = EmployeeListingHelper.getQueryFilters(filters);
      }

      const employeeFilter: interfaces.FilterParams | null = filters.id;
      if (employeeFilter && employeeFilter.value.length === 0 && employeeFilter.searchQuery !== '') {
        params.search = employeeFilter.searchQuery;
      }
    }

    if (queries && queries.length > 0) {
      params.queries = [...(params.queries || []), ...queries]; // merge with filters
    }

    if (groupedQueries && groupedQueries.length > 0) {
      params.groupedQueries = groupedQueries.map(gQ => {
        return [...gQ, ...(params.queries || [])]; // merge with filters for each grouped queries
      });
    }

    if (this.searchQuery) {
      params.search = this.searchQuery;
    }

    return params;
  }
}

interface ActivationError {
  employeeId: number;
  referenceCode: string;
  errorMessages: {
    message: string,
  }[];
}

interface EssInvitationPayload {
  welcomeMessage: string;
  employees: {
    id: number
    loginUsername: string,
  };
}
