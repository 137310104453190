import React, {PureComponent, Fragment} from 'react';
import styled from 'styled-components';

import {stylingVariables} from '@global';

export const StyledTagsWrapper = styled.div<{hasRouterLinks?: boolean}>`
  width: 100%;
  margin-bottom: 20px;
  border-bottom: 1px solid ${stylingVariables.colorPalette.darkGray};

  ${props => props.hasRouterLinks && `
    a.router-link {
      margin-right: 2px;
      &:last-child {
        margin-right: 0;
      }
    }
  `};
`;

export const StyledTabSelectorButton = styled.button<{isActive?: boolean, background?: string; height?: number}>`
  cursor: pointer;
  height: ${props => props.height ? props.height : '50'}px;
  min-width: 120px;
  font-weight: ${stylingVariables.fontWeight.semibold};
  font-size: ${stylingVariables.fontSize.mediumLarge};
  text-decoration: none;
  text-transform: uppercase;
  border: none;
  box-shadow: none;
  background-color: ${stylingVariables.colorPalette.white};

  ${props => props.background && `
    background-color: ${props.background};
  `};

  margin-right: 2px;
  border-radius: 5px 5px 0 0;
  &:last-child {
    margin-right: 0;
  }
  &:hover {
    color: ${stylingVariables.colorPalette.orange};
    background-color: ${stylingVariables.colorPalette.darkGray};
  }

  ${props => props.isActive && `
    color: ${stylingVariables.colorPalette.orange};
    background-color: ${stylingVariables.colorPalette.darkGray};
  `}

   a.active & {
    color: ${stylingVariables.colorPalette.orange};
    background-color: ${stylingVariables.colorPalette.darkGray};
  }
`;

interface Tab {
    key: string;
    label: string;
    onSelect?: (activeTabKey: string) => any;
    renderContent?: () => JSX.Element;
}

interface NavTabsProps {
    backgroundColor?: any;
    tabs: Tab[];
    height?: number;
}

interface NavTabsState {
    activeTabIndex: number;
}

export class NavTabs extends PureComponent<NavTabsProps, NavTabsState> {
    public constructor(props: any) {
        super(props);

        this.state = {
            activeTabIndex: 0,
        };
    }

    render() {
        return (
            <Fragment>
                <StyledTagsWrapper>
                    {
                        this.props.tabs.map((tab, index) => (
                            <StyledTabSelectorButton
                                height={this.props.height}
                                background={this.props.backgroundColor}
                                key={tab.key}
                                isActive={this.state.activeTabIndex === index}
                                onClick={() => this.chooseTab(index)}
                            >
                                {tab.label}
                            </StyledTabSelectorButton>
                        ))
                    }
                </StyledTagsWrapper>

                {this.activeTabContent}
            </Fragment>
        );
    }

    private getTab = (index: number) => {
        return this.props.tabs[index];
    }

    private chooseTab = (index: number) => {
        const tab = this.getTab(index);
        if (tab.onSelect && index !== this.state.activeTabIndex) {
            tab.onSelect(tab.key);
        }

        this.setState({activeTabIndex: index});
    }

    get activeTabContent(): JSX.Element|null {
        const tab = this.getTab(this.state.activeTabIndex);
        if (!tab || !tab.renderContent) {
            return null;
        }

        return tab.renderContent();
    }
}
