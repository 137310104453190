import {ApiObject, ApiReportTemplate} from '@api';
import {interfaces, intl} from '@global';
import {DefaultListingHelper} from '@library';
import {AnyAction, Dispatch} from "redux";
import {ThunkAction} from "redux-thunk";

import {RootState} from '../../../rootReducer';
import * as selectors from './selectors';
import {getTemplates as rootGetTemplates} from '../actions';
import {toast} from 'react-toastify';

type ThunkResult<R> = ThunkAction<R, RootState, undefined, AnyAction>;

export const SET_TEMPLATES = 'reports/reportTemplates/setTemplates';
export const SET_ORDERS = 'reports/reportTemplates/setOrders';
export const SET_FILTERS = 'reports/reportTemplates/setFilters';

export interface ReportTemplatesState {
	templates: ApiObject.ReportTemplate[];
	filters: interfaces.ListingFilters;
	orders: interfaces.ListingOrders;
}

export const defaultState: ReportTemplatesState = {
	templates: [],
	filters: {},
	orders: {}
};

export default function reducer(
	state = defaultState,
	action: AnyAction,
): ReportTemplatesState {
	switch (action.type) {
		case SET_TEMPLATES:
			return {
				...state,
				templates: action.payload.templates,
			}

		case SET_ORDERS:
			return {
				...state,
				orders: action.payload,
			};

		case SET_FILTERS:
			return {
				...state,
				filters: action.payload,
			};

		default:
			return state;
	}
}

export function getTemplates(): ThunkResult<Promise<void>> {
	return async(dispatch: Dispatch) => {
		dispatch(setTemplates(await dispatch(rootGetTemplates() as any)));

		return;
	};
}

export function changeOrder(column: string): ThunkResult<void> {
	return async (dispatch: Dispatch, getState: () => RootState) => {
		const orders: interfaces.ListingOrders = {...selectors.getOrders(getState())};
		await dispatch(setOrders(DefaultListingHelper.updatedOrders(column, orders, false)));
	};
}

export function changeFilter(params: interfaces.FilterParams): ThunkResult<void> {
	return async (dispatch: Dispatch, getState: () => RootState) => {
		const filters: interfaces.ListingFilters = {...selectors.getFilters(getState())};
		await dispatch(setFilters(DefaultListingHelper.updatedFilters(params, filters)));
	};
}

function setTemplates(templates: ApiObject.ReportTemplate[] = []): AnyAction {
	return {
		type: SET_TEMPLATES,
		payload: {
			templates,
		}
	}
}

export function deleteReportTemplate(id: number): ThunkResult<Promise<void>> {
	return async(dispatch: Dispatch, getState: () => RootState) => {
		const entityId = getState().global.currentLegalEntityId;
		if (!entityId) {
			return;
		}

		const response = await ApiReportTemplate.deleteReportTemplate(entityId, id);

		if (response.status === 200) {
			const state = getState();
			const templates: ApiObject.ReportTemplate[] = [...selectors.getTemplates(state)];

			const oldTemplates = templates
				.findIndex((t: ApiObject.ReportTemplate) => t.id === id);

			if (oldTemplates !== -1) {
				templates.splice(oldTemplates, 1);
			}

			dispatch(setTemplates(templates));
			toast.success(intl.get('report_template_removed_ok'));
		} else {
			toast.error(intl.get('report_template_removed_nok'));
		}

		return;
	};
}

function setOrders(orders: interfaces.ListingOrders = {}): AnyAction {
	return {
		type: SET_ORDERS,
		payload: orders
	}
}
function setFilters(filters: interfaces.ListingFilters = {}): AnyAction {
	return {
		type: SET_FILTERS,
		payload: filters
	}
}
