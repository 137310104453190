import React, {PureComponent} from 'react';
import styled from 'styled-components';

import {Icon} from '@common';
import {constants, stylingVariables, intl} from '@global';
import {FileHelper} from '@library';

const StyledWrapper = styled.div<{gentTemplate?: boolean}>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: auto;
  min-height: 120px;
  width: 100%;
  background-color: ${props => props.gentTemplate ? 'none' : stylingVariables.colorPalette.lightGray};
  color: ${stylingVariables.colorPalette.deepGray};
  padding: ${props => props.gentTemplate ? 'none' : '10px'};
  overflow-y: hidden;
`;

const StyledContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  font-size: ${stylingVariables.fontSize.mediumLarge};
  overflow-y: hidden;
`;

const StyledEmpty = styled(StyledContent)`
  border: 1px dashed ${stylingVariables.colorPalette.darkestGray};
  border-radius: 4px;
  cursor: pointer;
`;

const StyledFiles = styled(StyledContent)`
  border: 1px dashed ${stylingVariables.colorPalette.brightBlue};
  border-radius: 4px;
`;

const StyledFile = styled.div`
  padding: 15px;
  width: 100%;
  display: flex;
  align-items: center;
  font-weight: ${stylingVariables.fontWeight.regular};
`;

const StyledFileIcon = styled.div`
  margin-right: 10px;
`;
const StyledFileName = styled.span`
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
  color: ${stylingVariables.colorPalette.deepGray};
  height: 20px;
  display: flex;
  align-items: center;
`;
const StyledFileActions = styled.div`
  margin-left: 10px;
  background-color: ${stylingVariables.colorPalette.deepGray};
  opacity: .6;
  cursor: pointer;
  height: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
`;

const StyledDrop = styled(StyledContent)`
  border: 2px dashed ${stylingVariables.colorPalette.deepGray};
  cursor: auto;
`;

interface IProps {
    onChange: (files: File[] | null) => any;
    multiple?: boolean;
    emptyRenderer: (() => any)|null;
    files: File[]|null;
    gentTemplate?: boolean;
}

interface IState {
    drag: boolean;
    files: File[];
}

export class DragAndDropFiles extends PureComponent<IProps, IState> {
    private readonly dropRef: any;
    private inputRef: any;
    private dragOver: boolean;

    static defaultProps = {
        multiple: false,
        emptyRenderer: null,
        files: null,
        gentTemplate: false
    };

    public constructor(props: IProps) {
        super(props);

        this.state = {
            drag: false,
            files: [],
        };

        this.dropRef = React.createRef();
        this.dragOver = false;
    }

    static getDerivedStateFromProps(nextProps: any, prevState: any) {
        if (nextProps && nextProps.files !== null && JSON.stringify(nextProps.files) !== JSON.stringify(prevState.files)) {
            return {
                files: nextProps.files,
            }
        }

        return null;
    }

    private handleDrag = (e: any): void => {
        e.preventDefault();
        e.stopPropagation();
    }

    private handleDragIn = (e: any): void => {
        e.preventDefault();
        e.stopPropagation();

        if (this.dragOver) {
            return;
        }
        this.dragOver = true;
        if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
            this.setState({drag: true});
        }
    }

    private handleDragOut = (e: any): void => {
        e.preventDefault();
        e.stopPropagation();

        if (!this.dragOver) {
            return;
        }

        this.dragOver = false;
        this.setState({drag: false});
    }

    private handleDrop = (e: any): void => {
        e.preventDefault();
        e.stopPropagation();

        this.setState({drag: false});

        let files: File[] = [...e.dataTransfer.files];
        if ((!this.props.multiple && files.length > 1)) {
            files = [files[0]];
        }

        this.changeFiles(files);
        e.dataTransfer.clearData();
        this.dragOver = false;
    }

    public componentDidMount() {
        const current = this.dropRef.current;
        current.addEventListener('dragenter', this.handleDragIn);
        current.addEventListener('dragleave', this.handleDragOut);
        current.addEventListener('dragover', this.handleDrag);
        current.addEventListener('drop', this.handleDrop);
    }

    public componentWillUnmount() {
        const current = this.dropRef.current;
        current.removeEventListener('dragenter', this.handleDragIn);
        current.removeEventListener('dragleave', this.handleDragOut);
        current.removeEventListener('dragover', this.handleDrag);
        current.removeEventListener('drop', this.handleDrop);
    }

    private onClickInput = (e: any) => {
        if (this.inputRef) {
            this.inputRef.click(e);
        }
    }

    private onChangeInput = (e: any) => {
        this.changeFiles([...e.target.files]);
    }

    private changeFiles = (files: File[]): void => {
        this.setState({files}, this.props.onChange(files));
    }

    private removeFile = (index: number): void => {
        const files = [...this.state.files];
        if (files[index]) {
            files.splice(index, 1);
        }

        this.changeFiles(files);
    }

    get hasFiles() {
        return this.state.files && this.state.files.length > 0;
    }

    render() {
        return (
            <StyledWrapper ref={this.dropRef} gentTemplate={this.props.gentTemplate}>
                <input type='file'
                       multiple={this.props.multiple}
                       style={{display: 'none'}}
                       ref={input => this.inputRef = input}
                       onChange={this.onChangeInput}
                />

                {!this.state.drag && !this.hasFiles && (
                    <StyledEmpty onClick={this.onClickInput}>
                        {this.props.emptyRenderer
                            ? this.props.emptyRenderer()
                            : <>
                                <Icon type={constants.IconTypes.CLOUD_STORAGE_UPLOAD}
                                      width={40}
                                      height={40}
                                      fillColor={stylingVariables.colorPalette.deepGray}
                                />
                                {intl.get('choose_and_drop')}
                            </>
                        }
                    </StyledEmpty>
                )}

                {!this.state.drag && this.hasFiles && (
                    <StyledFiles>
                        {this.state.files && this.state.files.map((file: File, index: number) => (
                            <StyledFile key={index}>
                                <StyledFileIcon>
                                    <Icon type={FileHelper.getIconType(file.name)}
                                          fillColor={stylingVariables.colorPalette.green}
                                          height={20}
                                          width={20}
                                    />
                                </StyledFileIcon>
                                <StyledFileName>
                                    {file.name}
                                </StyledFileName>
                                <StyledFileActions onClick={() => this.removeFile(index)}>
                                    <Icon type={constants.IconTypes.CLOSE}
                                          height={8}
                                          width={8}
                                          fillColor={stylingVariables.colorPalette.white}
                                    />
                                </StyledFileActions>
                            </StyledFile>
                        ))}
                    </StyledFiles>
                )}

                {this.state.drag &&
                    <StyledDrop>
                        {intl.get('drop_here')}
                    </StyledDrop>
                }
            </StyledWrapper>
        );
    }
}

export default DragAndDropFiles;
