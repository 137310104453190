import React, {PureComponent} from 'react';
import {intl} from '@global';

import {constants} from '@global';
import {RouteHelper} from '@library';
import {Icon} from '@common';
import styled from 'styled-components';
import * as stylingVariables from '../../../../stylingVariables';

export const SignOutWrapper = styled.div`
    padding: 0 30px;
    height: 66px;
    display: flex;
    flex: 1;
    align-items: center;
    cursor: pointer;
    background: ${stylingVariables.colorPalette.gray};
    font-size: 14px;
    font-weight: ${stylingVariables.fontWeight.semibold};

    svg {
        margin-right: 10px;
    }

    &:hover {
        background: ${stylingVariables.colorPalette.darkerGray};
        color: ${stylingVariables.colorPalette.orange};

        svg {
            fill: ${stylingVariables.colorPalette.orange};
        }
    }
`

export class SingOut extends PureComponent<{}>{
    render() {
        return (
            <SignOutWrapper onClick={this.singOut}>
                <Icon type={constants.IconTypes.LOG_OUT} width={20} height={20}/>
                {intl.get('sign_out')}
            </SignOutWrapper>
        );
    }

    private singOut = async() => {
        RouteHelper.goToLogoutRoute()
    }
}

export default SingOut;
