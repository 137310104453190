import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { StyledFieldsGroupHeader } from '../Content';
import { intl, stylingVariables } from '@global';
import styled from 'styled-components';

import { EmployeeInterfaces, ApiObject } from '@api';

import Create from './Create/Create';
import Edit from './Edit/Edit';

import { RootState } from '../../../../rootReducer';
import * as selectors from './selectors';
import Table from './Table/Table';
import ReactResizeDetector from 'react-resize-detector';

const StyledWrapper = styled.div`
    min-height: 400px;
    padding: 20px 0 0 17px;
`;

const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;

  h4 {
      font-size: ${stylingVariables.fontSize.largeMedium};
      font-weight: ${stylingVariables.fontWeight.bold};
      padding-left: 15px;
  }
`;

interface IProps {
    employee: EmployeeInterfaces.Employee;
    group: ApiObject.FieldGroup | null;
}

interface IState {
    isEditModalOpen: boolean;
    selectedEntityId: string | null;
}

class Dependents extends PureComponent<IProps, IState> {

    public constructor(props: IProps) {
        super(props);

        this.state = {
            isEditModalOpen: false,
            selectedEntityId: null,
        };
    }

    public open = () => {
        this.setState({ isEditModalOpen: true });
    }

    public close = () => {
        this.setState({ isEditModalOpen: false, selectedEntityId: null });
    }

    public getEntityId = (entityId: string) => {
        this.setState({ selectedEntityId: entityId })
    }

    public render() {
        if (!this.props.group) {
            return null;
        }

        return (
            <StyledWrapper>
                <StyledHeader>
                    <StyledFieldsGroupHeader>{intl.get('dependents')}</StyledFieldsGroupHeader>

                    <Create employee={this.props.employee} group={this.props.group} />
                    <Edit
                        isEditModalOpen={this.state.isEditModalOpen}
                        close={this.close}
                        entityId={this.state.selectedEntityId}
                        employee={this.props.employee}
                        group={this.props.group}
                    />

                </StyledHeader>
                <ReactResizeDetector handleWidth={true}>
                    <Table employee={this.props.employee} group={this.props.group} open={this.open} close={this.close} getEntityId={this.getEntityId} />
                </ReactResizeDetector>
            </StyledWrapper>
        );
    }
}

const mapStateToProps = (state: RootState) => ({
    group: selectors.getDependentGroup(state),
});

export default connect(mapStateToProps)(Dependents);
