import React, {PureComponent} from 'react';
import {ReportsTab} from '../constants';
import {default as RootNavigation} from '../Navigation';

import styled from 'styled-components';
import Navigation from './Navigation';
import Table from './Table/Table';

const StyledWrapper = styled.div`
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  overflow: hidden;
  height: calc(100vh - 100px);
`;

export class Reports extends PureComponent {
    public render() {
        return (
            <StyledWrapper>
                <RootNavigation activeTab={ReportsTab.reports} />
                <Navigation />
                <Table />
            </StyledWrapper>
        );
    }
}

export default Reports;
