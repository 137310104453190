import React, {PureComponent} from 'react';
import styled from 'styled-components';
import {constants, stylingVariables} from '@global';
import {intl} from '@global';
import {Icon} from '@common';

const StyledContainerWrapper = styled.div`
    display: flex;
    width: 100%;
    height: 60px;
    align-content: center;
    justify-content: flex-start;
    border-radius: 5px;
    background-color: ${stylingVariables.colorPalette.lightGray};
    margin: 5px 0 10px 0;
    span{
        margin: auto 0;
        color: ${stylingVariables.colorPalette.orange};
        font-size: 12px;
        font-weight: bold;
    }
`;

const StyledAddButtonWrapper = styled.div`
    display: flex;
    align-content: center;
    justify-content: center;
    cursor: pointer;
    color: ${stylingVariables.colorPalette.orange};
    font-weight: ${stylingVariables.fontSize.medium};
    font-weight: ${stylingVariables.fontWeight.regular};

    &:hover {
        color: ${stylingVariables.colorPalette.darkOrange};

        div:first-child {
            background-color: ${stylingVariables.colorPalette.darkOrange};
        }
    }
`;

const StyledAddButton = styled.div`
    display: flex;
    align-content: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: ${stylingVariables.colorPalette.orange};
    box-shadow: ${stylingVariables.layout.boxShadow};
    cursor: pointer;
    border: none;
    outline: none;
    padding: 5px;
    margin: auto 10px auto 20px;
`;

interface IProps {
    action: () => void;
}

export default class AddButtonRow extends PureComponent<IProps, any> {

    public render() {
        return <StyledContainerWrapper>
                <StyledAddButtonWrapper onClick={this.props.action}>
                    <StyledAddButton>
                        <Icon type={constants.IconTypes.BOLD_ADD}
                          width={10}
                          height={10}
                          fillColor={stylingVariables.colorPalette.white}/>
                    </StyledAddButton>
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>{intl.get('add')}</div>
                </StyledAddButtonWrapper>
        </StyledContainerWrapper>;
    }
}
