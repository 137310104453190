import React, {PureComponent} from 'react';
import {intl} from '@global';
import {connect} from 'react-redux';

import {ApiObject, EmployeeInterfaces} from '@api';
import {Actions} from '@common';
import styled from 'styled-components';

import {RootState} from '../../../../../rootReducer';
import * as selectors from '../../../selectors';

import Group from './Group';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const LeftSide = styled.div`
  padding-left: 37px;
  min-width: 690px;
`;
const RightSide = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  button {
    padding: 10px 40px;
  }
`;

interface IProps {
    employee: EmployeeInterfaces.Employee;

    fields: any;
    fieldGroup: ApiObject.FieldGroup;

    errors: ApiObject.EntityFieldErrorMessage[];

    onClickCancel: () => void;
    onClickSave: (fields: ApiObject.FieldEntity[]) => Promise<boolean>;
}

interface IState {
    updatedFields: ApiObject.FieldEntity[];
}

class Edit extends PureComponent<IProps, IState> {
    public constructor(props: IProps) {
        super(props);

        this.state = {
            updatedFields: [],
        };
    }

    public render() {
        return (
            <Wrapper>
                <LeftSide>
                    <Group
                        group={this.props.fieldGroup}
                        fields={this.props.fields}
                        employee={this.props.employee}
                        errors={this.props.errors}
                        onChange={this.updateField}
                    />

                    {
                        this.props.fieldGroup.subgroups.map((subFieldGroup: ApiObject.FieldGroup) => (
                            <Group key={'field_group' + subFieldGroup.code}
                                   group={subFieldGroup}
                                   fields={this.props.fields}
                                   employee={this.props.employee}
                                   errors={this.props.errors}
                                   onChange={this.updateField}
                            />
                        ))
                    }

                </LeftSide>
                <RightSide>
                    <Actions.ActionRightBar>
                        <Actions.GrayButton onClick={this.props.onClickCancel}>
                            {intl.get('cancel')}
                        </Actions.GrayButton>

                        <Actions.OrangeButton onClick={this.save} isDisabled={this.state.updatedFields.length === 0}>
                            {intl.get('save')}
                        </Actions.OrangeButton>
                    </Actions.ActionRightBar>
                </RightSide>
            </Wrapper>
        );
    }

    private save = async() => {
        const updatedFields = [...this.state.updatedFields];

        const isSuccess = await this.props.onClickSave(updatedFields);
        if (isSuccess) {
            this.setState({updatedFields: []});
            this.props.onClickCancel();
        }
    }

    /**
     * Update default field group
     * @param field
     * @param value
     */
    private updateField = (field: ApiObject.Field, value: any) => {
        const updatedFields = [...this.state.updatedFields];
        const entity = updatedFields.find((item: any) => item.entity === field.entity);

        if (!entity) {
            updatedFields.push({entity: field.entity, values: [{value: value, code: field.code}]});
        } else {
            const entityValue = entity.values.find((item: any) => item.code === field.code);
            if (!entityValue) {
                entity.values.push({value, code: field.code});
            } else {
                entityValue.value = value;
            }
        }
        this.setState({updatedFields});
    }
}

const mapStateToProps = (state: RootState) => ({
    errors: selectors.getErrorsFields(state),
});

export default connect(mapStateToProps)(Edit);
