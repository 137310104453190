import React, {memo, PureComponent} from 'react';
import {Column} from 'react-virtualized';
import styled from 'styled-components';
import {get} from 'lodash';

import {stylingVariables, intl} from '@global';
import {defaultInfiniteTableCellDataGetter, FormatNumbers} from '@common';
import { LeaveInterfaces } from '@api';
import { getTransitionalAmountsSum } from 'library/transitionalAmounts.helper';

interface LeaveColumnProps {
    cellData?: any;
    type: LeaveInterfaces.Type;
}

const StyledCellDataWrapper = styled.div`
    display: flex;
    flex-direction: row;
    margin-left: 15px;
`;

const StyledCellData = styled.div`
    font-size: ${stylingVariables.fontSize.mediumLarge};
    font-weight: ${stylingVariables.fontWeight.medium};
    text-align: center;
    width: 35px;
    line-height: 35px;

    span {
        font-weight: ${stylingVariables.fontWeight.regular};
    }

    :hover {
        border-radius: 50%;
        background-color: ${stylingVariables.colorPalette.lightGray};
    }
`;

export const InformationTab = styled.span`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  top: 0;
  margin-left: 55px;
  background-color: ${stylingVariables.colorPalette.white};
  min-width: 180px;
  min-height: 80px;
  border: 1px solid ${stylingVariables.colorPalette.lightGray};
  box-shadow: 0px 0px 10px 1px ${stylingVariables.colorPalette.darkGray};

  position: absolute;
  z-index: 5;
  border-radius: 5px;
  padding: 13px 18px;
  vertical-align: top;

  :after, :before {
    right: 100%;
    top: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  :after {
    border-color: transparent;
    border-right-color: #ffffff;
    border-width: 11px;
    margin-top: -11px;
  }
  :before {
    border-color: transparent;
    border-right-color: ${stylingVariables.colorPalette.lightGray};
    border-width: 13px;
    margin-top: -13px;
  }
`;

export const HeaderInformationTab = styled.span`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  font-size: ${stylingVariables.fontSize.small};
  top: 0;
  margin-left: 55px;
  background-color: ${stylingVariables.colorPalette.white};
  min-width: 100px;
  min-height: 35px;
  border: 1px solid ${stylingVariables.colorPalette.lightGray};
  box-shadow: 0px 0px 10px 1px ${stylingVariables.colorPalette.darkGray};
  transition: 0.3s;

  position: absolute;
  z-index: 5;
  border-radius: 5px;
  padding: 10px 12px;
  vertical-align: top;

  :after, :before {
    right: 100%;
    top: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  :after {
    border-color: transparent;
    border-right-color: #ffffff;
    border-width: 11px;
    margin-top: -11px;
  }
  :before {
    border-color: transparent;
    border-right-color: ${stylingVariables.colorPalette.lightGray};
    border-width: 13px;
    margin-top: -13px;
  }
`;

const Label = styled.div`
    cursor: pointer;
    height: 30px;
    font-size: ${stylingVariables.fontSize.medium};
    font-weight: ${stylingVariables.fontWeight.bold};
    line-height: ${stylingVariables.fontSize.large};

    div:not(:first-child) {
        font-size: ${stylingVariables.fontSize.mediumSmall};
        font-weight: ${stylingVariables.fontWeight.regular};
    }
`;

const LeaveTypeName = styled.div`
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
`;

interface IState {
    infoVisible: boolean;
}

const CellInfo: React.FC<{
    cellData: any,
    infoVisible: boolean,
    onMouseOver: (event: any) => void,
} & LeaveColumnProps> = memo(({ cellData, type, infoVisible, onMouseOver }) => {
    const transitionalBalance = getTransitionalAmountsSum(get(cellData, `balances.${type.id}`), 'balance')
    const transitionalTaken = getTransitionalAmountsSum(get(cellData, `balances.${type.id}`), 'taken')

    const balance =
        get(cellData, `balances.${type.id}.ytdBalance`, 0) +
        get(cellData, `balances.${type.id}.year1TransitionalBalance`, 0) -
        get(cellData, `balances.${type.id}.year1TransitionalTaken`, 0) +
        transitionalBalance;

    const taken =
        get(cellData, `balances.${type.id}.ytdTaken`, 0) +
        get(cellData, `balances.${type.id}.year1TransitionalTaken`, 0) +
        transitionalTaken;
    
    const total = balance ? balance + taken : 0;

    const translationString = type.amountType === 'hours' ? 'hours' : 'days'
    return (
        <StyledCellDataWrapper>
            <StyledCellData
                onMouseOver={onMouseOver}
                onMouseLeave={onMouseOver}
            >
            <FormatNumbers showZero={true} value={type.hasBalance ? balance : taken}/>
            </StyledCellData>
            {infoVisible && type.hasBalance ?
                <InformationTab>
                    <div>
                        <FormatNumbers showZero={true} value={taken}/> {intl.get(translationString)} {intl.get('have_been_used')}
                    </div>
                    <div>
                        <FormatNumbers showZero={true} value={total}/> {intl.get(translationString)} {intl.get('total')}
                    </div>
                </InformationTab>
            : null
            }
        </StyledCellDataWrapper>
    );
});

class Cell extends PureComponent<LeaveColumnProps, IState> {
    public constructor(props: LeaveColumnProps) {
        super(props);

        this.state = {
            infoVisible: false,
        };
    }

    private toggleAdditionalInfo = (e: any) => {
        this.setState({infoVisible: !this.state.infoVisible});
    }

    public render() {
        return (
            <CellInfo
                onMouseOver={this.toggleAdditionalInfo}
                infoVisible={this.state.infoVisible}
                cellData={this.props.cellData}
                type={this.props.type}
            />
        );
    }
}

class LeaveLabel extends PureComponent<LeaveColumnProps, IState> {
    public constructor(props: LeaveColumnProps) {
        super(props);

        this.state = {
            infoVisible: false,
        };
    }

    private toggleAdditionalInfo = (e: any) => {
        this.setState({infoVisible: true});
    }

    private hideInfo = (e: any) => {
        this.setState({infoVisible: false});
    }

    public render() {
        return (
            <Label
                onMouseOver={this.toggleAdditionalInfo}
                onMouseLeave={this.hideInfo}
            >
                <LeaveTypeName>
                    {this.props.type.name}
                </LeaveTypeName>
                {this.state.infoVisible &&
                    <HeaderInformationTab>
                        {this.props.type.name}
                    </HeaderInformationTab>
                }
                <div>
                    {intl.get(this.props.type.amountType)}
                    {this.props.type.hasBalance ? ' ' + intl.get('left') : ' ' + intl.get('taken')}
                </div>
            </Label>
        );
    }
}

const Leave: React.FC<LeaveColumnProps> = ({type}: LeaveColumnProps) => (
    <Column
        key={`leave_${type.id}`}
        dataKey={`leave_${type.id}`}
        label={<LeaveLabel type={type}/>}
        width={105}
        flexGrow={1}
        flexShrink={0}
        cellDataGetter={defaultInfiniteTableCellDataGetter}
        cellRenderer={({cellData}) => <Cell type={type} cellData={cellData} />}
    />
);

export default Leave;
