import {ApiObject} from '@api';
import React from 'react';
import {Column} from 'react-virtualized';

import {defaultInfiniteTableCellDataGetter, FormatNumbers} from '@common';
import {get} from 'lodash';
import {intl} from '@global';

interface DefaultColumnProps {
    dataKey: string;
    label: string;
    width?: number;
    flexGrow?: number;
}

const Amount: React.FC<DefaultColumnProps> = ({
    dataKey,
    label,
    width = 80,
    flexGrow = 1,
}: DefaultColumnProps) => (
    <Column
        label={label}
        dataKey={dataKey}
        width={width}
        style={{justifyContent: 'flex-end'}}
        flexGrow={flexGrow}
        cellDataGetter={defaultInfiniteTableCellDataGetter}
        headerRenderer={() => (
            <div style={{textAlign: 'right'}}>{label}</div>
        )}
        cellRenderer={({cellData}: any) => {
            const value = get(cellData, dataKey, null);
            if (!value && value !== 0) {
                return '-';
            }

            return (cellData.pay_unit === ApiObject.PayUnitType.percentage && dataKey === 'amount')
                ? <>{cellData[dataKey]}{intl.get('percentage_symbol')}</>
                : <FormatNumbers showZero={true} value={value}/>;
        }}
    />
);

export default Amount;
