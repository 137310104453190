import React, {PureComponent} from 'react';

import {ApiObject} from '@api';
import {EditableField as Default} from '@common';

interface IProps {
    field: ApiObject.Field;
    default: any;
    errorMessage?: string | null;
    onChange: (field: ApiObject.Field, value: any) => void;
}

export default class Field extends PureComponent<IProps> {
    public render() {
        return (
            <Default
                key={`${this.props.field.entity}_${this.props.field.code}`}
                type={this.props.field.type}
                label={this.props.field.label}
                isRequired={this.props.field.required}
                code={this.props.field.code}
                options={this.props.field.options}
                defaultValue={this.props.default}
                onChange={this.onChange}
                errorMessage={this.props.errorMessage}
            />
        );
    }

    private onChange = (value: any) => this.props.onChange(this.props.field, value);
}
