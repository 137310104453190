import React, {PureComponent} from 'react';
import {Icon, StyledInputWrapper} from '@common';
import {constants, stylingVariables} from '@global';
import styled from 'styled-components';
import AlphabetListEntitiesWithSearch from '../../Entities/AlphabetListEntitiesWithSearch';

interface LegalEntitySelectorState {
    isOpen: boolean;
}

export const StyledToggleButton = styled.button<{isOpen: boolean}>`
  background-color: ${props => props.isOpen ? stylingVariables.colorPalette.lightGreen : 'transparent'};

  :hover {
    background-color: ${stylingVariables.colorPalette.lightGreen};
  }

  border: none;
  cursor: pointer;
  width: 30px;
  min-width: 30px;
  height: 30px;
  min-height: 30px;
  border-radius: 50%;
  z-index: 500;
  padding: 0;
  @media (max-height: 800px) {
    width: 30px;
    min-width: 30px;
    height: 30px;
    min-height: 30px;
  }
`;

export const StyledEntitiesWrapper = styled.div`
  position: absolute;
  box-shadow: ${stylingVariables.layout.boxShadow};
  top: 15px;
  left: 5px;
  padding: 20px 0;
  background-color: ${stylingVariables.colorPalette.white};
  width: 500px;
  max-height: calc(100vh - 250px);
  overflow-y: auto;
  border-radius: 5px;
  z-index: 400;

  ${StyledInputWrapper} {
    width: 372px;
    margin: 15px auto;
  }
`;

export const StyledArrowWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 5px;
`

export default class LegalEntitySelector extends PureComponent<any, LegalEntitySelectorState> {
    private wrapperRef: any;
    private buttonRef: any;

    public constructor(props: any) {
        super(props);

        this.state = {
            isOpen: false,
        };
    }

    public componentDidMount() {
        document.addEventListener('mousedown', this.closeWhenClickOutside);
    }

    public componentWillUnmount() {
        document.removeEventListener('mousedown', this.closeWhenClickOutside);
    }

    render() {
        return (
            <StyledArrowWrapper>
                <StyledToggleButton isOpen={this.state.isOpen} onClick={this.toggle} ref={this.setButtonRef}>
                    <Icon type={this.state.isOpen ? constants.IconTypes.ARROW_UP : constants.IconTypes.ARROW_DOWN}
                          fillColor={stylingVariables.colorPalette.darkest}
                          height={8}
                          width={10}
                    />
                </StyledToggleButton>

                {
                    this.state.isOpen &&
                    <StyledEntitiesWrapper ref={this.setWrapperRef}>
                        <AlphabetListEntitiesWithSearch overflow="scroll" padding="10px 20px"/>
                    </StyledEntitiesWrapper>
                }
            </StyledArrowWrapper>
        );
    }

    public toggle = () => {
        this.setState({isOpen: !this.state.isOpen});
    }

    public close = () => {
        this.setState({isOpen: false});
    }

    public setWrapperRef = (node: any) => {
        this.wrapperRef = node;
    }

    public setButtonRef = (node: any) => {
        this.buttonRef = node;
    }

    public closeWhenClickOutside = (event: Event) => {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target) && !this.buttonRef.contains(event.target)) {
            this.close();
        }
    }
}
