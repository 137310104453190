import {ApiObject} from '@api';
import {interfaces} from '@global';

export const convertEntityAndFieldNames = (entity: string, field: string) => {
    if (entity === 'employee' && field === 'full_name') {
        return ['person', 'first_name'];
    }

    return [entity, field];
}

export const getQueryOrders = (orders: interfaces.ListingOrders): ApiObject.EntityFieldOrderBy[] => {
    const params: ApiObject.EntityFieldOrderBy[] = [];
    Object.keys(orders).forEach((key: string) => {
        const order = orders[key];
        let [entity, field] = prepareColumnForListing(order.column);
        if (!entity || !field) {
            return;
        }

        const [preparedEntity, preparedField] = convertEntityAndFieldNames(entity, field);
        params.push({
            entity: preparedEntity,
            field: preparedField,
            order: order.direction as ApiObject.OrderByOperator
        })
    });

    return params;
};

export const getQueryFilters = (filters: interfaces.ListingFilters): ApiObject.Queries => {
    const params: ApiObject.Queries = [];
    Object.keys(filters).forEach((key: string) => {
        const filter = filters[key];
        let [entity, field] = prepareColumnForListing(filter.column);
        let [operator, value, additionalOperator, additionalValue] = getQueryFilter(filter);
        if (!entity || !field || !operator) {
            return;
        }
        params.push({entity, field, operator, value})

        if (additionalOperator && additionalValue) {
            params.push({entity, field, operator: additionalOperator, value: additionalValue})
        }
    });

    return params;
};

const getQueryFilter = (filter: interfaces.FilterParams) => {
    // when filter is date range (value: [from, till])
    if (
        (filter.type === ApiObject.FieldType.date || filter.type === ApiObject.FieldType.datetime)
        && Array.isArray(filter.value)
    ) {
        const [from, till] = filter.value;
        if (from && till) {
            // one day
            if (from === till) {
                return  [filter.expression, from];
            } else {
                return  [ApiObject.QueryOperator.gte, from, ApiObject.QueryOperator.lte, till];
            }
        } else {
            if (from) {
                // only from
                return  [ApiObject.QueryOperator.gte, from];
            }
            if (till) {
                // only till
                return  [ApiObject.QueryOperator.lte, till];
            }
        }
    }

    if (Array.isArray(filter.value)) {
        if (filter.value.length > 0) {
            const expression = filter.expression || ApiObject.QueryOperator.in;

            return [expression, filter.value.join(',')];
        }

        return [];
    }

    if (ApiObject.QueryOperator.null === filter.expression || ApiObject.QueryOperator.nnull === filter.expression) {
        return [filter.expression, ''];
    }

    return [filter.expression, `${filter.value}`];
};

const prepareColumnForListing = (column: string) => {
    if (column === 'employeeGroupId') {
        return ['employee', 'group_id'];
    }

    const columnName = toSnake(column);
    if (columnName.includes('.')) {
        return columnName.split('.');
    }

    return ['employee', columnName];
};

export const updatedOrders = (column: string, orders: interfaces.ListingOrders): interfaces.ListingOrders => {
    const newOrders: interfaces.ListingOrders = {...orders};

    if (!newOrders[column]) {
        return {
            ...newOrders,
            [column]: {column, direction: ApiObject.OrderByOperator.asc},
        };
    }

    if (newOrders[column].direction === ApiObject.OrderByOperator.asc) {
        return {
            ...newOrders,
            [column]: {
                ...newOrders[column],
                direction: ApiObject.OrderByOperator.desc,
            },
        };
    }

    delete newOrders[column];

    return newOrders;
};

const prepareFilterParams = (params: interfaces.FilterParams): interfaces.FilterParams => {
    const newParams: interfaces.FilterParams = {...params};
    if (!newParams.expression) {
        switch (newParams.type) {
            case ApiObject.FieldType.text:
                newParams.expression = ApiObject.QueryOperator.like;
                break;
            default:
                newParams.expression = ApiObject.QueryOperator.eq;
                break;
        }
    }

    return newParams;
};

export const updatedFilters = (
    params: interfaces.FilterParams,
    filters: interfaces.ListingFilters,
): interfaces.ListingFilters => {
    const newFilters: interfaces.ListingFilters = {...filters};
    params = prepareFilterParams(params);

    const EMPLOYEE_FILTER_COLUMN_ID = 'id';
    const isExistValue = (params.value && params.value.length > 0)
        || (params.column === EMPLOYEE_FILTER_COLUMN_ID && params.searchQuery);

    if (!newFilters[params.column]) {
        return {
            ...newFilters,
            [params.column]: {
                ...params,
                type: params.type || ApiObject.FieldType.text,
            },
        };
    }

    if (isExistValue) {
        return {
            ...newFilters,
            [params.column]: {...newFilters[params.column], ...params},
        };
    }

    delete newFilters[params.column];

    return newFilters;
};

export const toCamel = (str: string): string => {
    return str.replace(
        /([_][a-z])/ig,
        $1 => $1.toUpperCase().replace('_', '')
    );
};

export const toSnake = (str: string): string => {
    return str.replace(/([A-Z])/g, (x,y) => `_${y.toLowerCase()}`).replace(/^_/, '');
};
