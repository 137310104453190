import React, {PureComponent} from 'react';
import styled from 'styled-components';

import {stylingVariables} from '@global';

export const Tag = styled.span<{color?: string, isClickable?: boolean, fontColor?: string}>`
  white-space: nowrap;
  padding: 2px 10px;
  font-size: ${stylingVariables.fontSize.small};
  background-color: ${props => props.color || stylingVariables.colorPalette.green};
  color: ${props => props.fontColor || stylingVariables.colorPalette.white};
  cursor: ${props => props.isClickable ? 'pointer' : 'default'};
  border-radius: 4px;
  height: 18px;
  font-weight: ${stylingVariables.fontWeight.semibold};
  display: flex;
  align-items: center;
`;

export const FatTag = styled(Tag)`
  padding: 5px 10px;
`;

export const DotLabelWrapper = styled.span<{color?: string}>`
  display: flex;
  align-items: center;
  font-weight: ${stylingVariables.fontWeight.light};
  font-size: ${stylingVariables.fontSize.medium};
  padding: 5px;
  position: relative;
  span {
    margin-left: 5px;
  }
`;

const Dot = styled.div<{color?: string}>`
  display: inline-block;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: ${props => props.color};
  margin-right: 5px;
`;

interface DotLabelProps {
    color?: string;
    children: any;
}

export class DotLabel extends PureComponent<DotLabelProps> {
    render() {
        return (
            <DotLabelWrapper>
                <Dot color={this.props.color}/>

                {this.props.children}
            </DotLabelWrapper>
        );
    }
}
