import { ApiObject } from '@api';
import {intl, stylingVariables} from '@global';
import React, { PureComponent } from 'react';
import { Column } from 'react-virtualized';
import styled from 'styled-components';
import { Label } from '@common';

import { defaultInfiniteTableCellDataGetter } from '@common';
import { ReportStatus } from 'api/api.object';

const StyledCellWrapper = styled.div`
    display: flex;
    flex-direction: column;
    font-size: ${stylingVariables.fontSize.default};
    span {
      font-size: ${stylingVariables.fontSize.medium};
    }
`;


interface IProps {
    cellData?: ApiObject.Report;
    label: string;
    width: number;
    flexGrow?: number;
}

interface ICellProps {
    cellData?: ApiObject.Report;
}

class Cell extends PureComponent<ICellProps> {
    public render() {
        if (!this.props.cellData) {
            return null;
        }
        const labelColor = this.props.cellData.status === ReportStatus.generated ? stylingVariables.colorPalette.green : stylingVariables.colorPalette.yellow
        return (
            <StyledCellWrapper>
                {this.props.cellData.status && <Label.Tag color={labelColor}>{intl.get(`report_${this.props.cellData.status}`)}</Label.Tag>}
            </StyledCellWrapper>
        );
    }
};

const Status: React.FC<IProps> = ({ label, width }: IProps) => (
    <Column
        dataKey={`status`}
        label={label}
        width={width}
        cellDataGetter={defaultInfiniteTableCellDataGetter}
        cellRenderer={({ cellData }) => <Cell cellData={cellData} />}
    />
);

export default Status;
