import {ApiObject} from '@api';
import {interfaces} from '@global';

export const updatedOrders = (column: string, orders: interfaces.ListingOrders, multiSort: boolean = true): interfaces.ListingOrders => {
    const newOrders: interfaces.ListingOrders = {...orders};

    if (!newOrders[column]) {
        return {
            ...(multiSort ? newOrders : {}),
            [column]: {column, direction: ApiObject.OrderByOperator.asc},
        };
    }

    if (newOrders[column].direction === ApiObject.OrderByOperator.asc) {
        return {
            ...newOrders,
            [column]: {
                ...newOrders[column],
                direction: ApiObject.OrderByOperator.desc,
            },
        };
    }

    delete newOrders[column];

    return newOrders;
};

const prepareFilterParams = (params: interfaces.FilterParams): interfaces.FilterParams => {
    const newParams: interfaces.FilterParams = {...params};
    if (!newParams.expression) {
        switch (newParams.type) {
            case ApiObject.FieldType.text:
                newParams.expression = ApiObject.QueryOperator.like;
                break;
            default:
                newParams.expression = ApiObject.QueryOperator.eq;
                break;
        }
    }

    return newParams;
};

export const updatedFilters = (
    params: interfaces.FilterParams,
    filters: interfaces.ListingFilters,
): interfaces.ListingFilters => {
    const newFilters: interfaces.ListingFilters = {...filters};
    params = prepareFilterParams(params);

    const isExistValue = (params.value && params.value.length > 0);

    if (!newFilters[params.column]) {
        return {
            ...newFilters,
            [params.column]: {
                ...params,
                type: params.type || ApiObject.FieldType.text,
            },
        };
    }

    if (isExistValue) {
        return {
            ...newFilters,
            [params.column]: {...newFilters[params.column], ...params},
        };
    }

    delete newFilters[params.column];

    return newFilters;
};
