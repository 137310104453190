import moment, {Moment} from 'moment';

import {constants, interfaces} from '@global';

export default class DateHelper {
    static convertDateToHumanFormat(date?: string|Moment|Date) {
        if (!date) {
            return null;
        }

        if (typeof date === 'string' || date instanceof Date) {
            date =  moment(date, constants.SYSTEM_DATE_FORMAT);
        }

        if(!date.isValid()){
            return '';
        }

        return date.format(constants.SYSTEM_DATE_HUMAN_FORMAT);
    }

    static convertDateTimeToHumanFormat(dateTime?: string) {
        if (!dateTime) {
            return null;
        }

        return moment(dateTime, constants.SYSTEM_DATE_TIME_FORMAT).format(
            constants.SYSTEM_DATE_TIME_HUMAN_FORMAT,
        );
    }

    static convertPeriodsToHumanFormat(periods: interfaces.Periods): string {
        return periods.map(period => DateHelper.convertPeriodToHumanFormat(period)).join(', ')
    }

    static convertPeriodToHumanFormat(period: interfaces.Period): string {
        const beginDate = moment(period.beginDate)
        const endDate = moment(period.endDate)

        if (beginDate.isSame(endDate)) {
            return `${moment(beginDate).format('MMMM D, YYYY')}`
        }

        const firstDayInMonth = moment(beginDate).startOf('month').format('DD')
        const lastDayInMonth = moment(beginDate).endOf('month').format('DD')

        const diffY = moment(beginDate).format('YYYY') !== moment(endDate).format('YYYY');
        const diffM = moment(beginDate).format('MMM') !== moment(endDate).format('MMM');

        if (diffY) {
            return `${moment(beginDate).format('MMMM D YYYY')} - ${moment(endDate).format('MMMM D YYYY')}`;
        }
        if (diffM) {
            return `${moment(beginDate).format('MMMM D')} - ${moment(endDate).format('MMMM D, YYYY')}`;
        }
        if (moment(beginDate).format('DD') === firstDayInMonth && moment(endDate).format('DD') === lastDayInMonth) {
            return moment(beginDate).format('MMMM YYYY');
        }

        return `${moment(beginDate).format('MMMM D')} - ${moment(endDate).format('D, YYYY')}`;
    }
}
