import React, {PureComponent} from 'react';
import {intl} from '@global';
import styled from 'styled-components';

import {stylingVariables, constants} from '@global';
import {ApiObject} from '@api';
import {EditableField, Icon} from '@common';

import {getErrorByEntityAndCode} from '../../../../selectors';

const Wrapper = styled.div<{isOpen: boolean}>`
  margin-top: 20px;
  margin-bottom: ${props => props.isOpen ? '20px' : '0'};
  position: relative;
`;

const AdvancedSettingsHeader = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: ${stylingVariables.fontWeight.semibold};
  font-size: ${stylingVariables.fontSize.default};
  color: ${stylingVariables.colorPalette.darkest};
  cursor: pointer;
	margin-bottom: 15px;
`;

const IconWrapper = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${stylingVariables.colorPalette.darkGray};

  &:hover {
    background-color: ${stylingVariables.colorPalette.lightGreen};

		svg {
			fill: ${stylingVariables.colorPalette.green};
		}
  }
`

const HeaderTitle = styled.span`
	font-weight: ${stylingVariables.fontWeight.bold};
  font-size: ${stylingVariables.fontSize.default};
  color: ${stylingVariables.colorPalette.darkest};
`

interface IProps {
    fields: ApiObject.Field[];
    errors: ApiObject.EntityFieldErrorMessage[];
    showRetroactive: Boolean;

    updateField: (field: ApiObject.Field, value: any) => void;
    isFieldDisabled: (field: ApiObject.Field) => boolean;
    getDefaultValueByField: (field: ApiObject.Field) => any;
    updateRetroactive: (value: Boolean) => void;
    retroactiveField: any;
    isEditModal: boolean;
    isOpened: boolean;
}

interface IState {
    isOpen: boolean;
}

export default class AdvancedFields extends PureComponent<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            isOpen: this.props.isOpened,
        };
    }

    render() {
        if (!this.props.fields.length){
            return null;
        }

        return (
            <Wrapper isOpen={this.state.isOpen}>
                <AdvancedSettingsHeader onClick={this.toggle}>
                    <HeaderTitle>
                        {intl.get('advanced_settings')}
                    </HeaderTitle>
                    <IconWrapper>
                        <Icon
                            type={this.state.isOpen ? constants.IconTypes.ARROW_UP : constants.IconTypes.ARROW_DOWN}
                            width={12}
                            height={12}
                            fillColor={stylingVariables.colorPalette.dark}
                        />
                    </IconWrapper>
                </AdvancedSettingsHeader>

                {
                    this.state.isOpen &&
                    this.props.fields.map((field: ApiObject.Field) => (
                        <EditableField
                            key={`${field.entity}_${field.code}`}
                            type={field.type}
                            label={field.label}
                            code={field.code}
                            options={field.options}
                            isRequired={field.required}
                            errorMessage={getErrorByEntityAndCode(this.props.errors, field.entity, field.code)}
                            disabled={this.props.isFieldDisabled(field)}
                            defaultValue={this.props.getDefaultValueByField(field)}
                            onChange={(value: any) => this.props.updateField(field, value)}
                        />
                    ))

                }
                {   this.state.isOpen && this.props.showRetroactive && 
                    <EditableField
                        key={`use_retroactive`}
                        type={'text'}
                        label={intl.get('calculate_retroactive')}
                        code={this.props.retroactiveField.code}
                        options={this.props.retroactiveField.options}
                        isRequired={false}
                        disabled={false}
                        defaultValue={'false'}
                        onChange={(value: any) => this.props.updateRetroactive(value)}
                    />
                }
            </Wrapper>
        );
    }

    public toggle = () => {
        this.setState({isOpen: !this.state.isOpen});
    }
}
