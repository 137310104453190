import React, {PureComponent, ChangeEvent} from 'react';
import styled from 'styled-components';

import {stylingVariablesGlobal as stylingVariables} from 'hrb-common';

const StyledTextAreaWrapper = styled.div<{height?: string, flex?: number}>`
  display: flex;
  flex-direction: column;

  flex: ${props => props.flex || 1};

  label {
    font-size: ${stylingVariables.fontSize.medium};
    font-weight: ${stylingVariables.fontWeight.regular};
    margin-bottom: 5px;
  }

  textarea {
    display: block;
    font-size: ${stylingVariables.fontSize.mediumLarge};
    font-weight: ${stylingVariables.fontWeight.bold};
    width: 100%;
    height: ${props => props.height || '150px'};
    border: 1px solid ${stylingVariables.colorPalette.gray};
    border-radius: 4px;

    line-height: 1.1rem;
    outline: none;
    padding: 14px;
    resize: none;

    &::-webkit-input-placeholder {
        font-size: ${stylingVariables.fontSize.medium};
        font-weight: ${stylingVariables.fontWeight.regular};
        font-style: italic;
    }
    &::-moz-placeholder {
        font-size: ${stylingVariables.fontSize.medium};
        font-weight: ${stylingVariables.fontWeight.regular};
        font-style: italic;
    }
    &:-ms-input-placeholder {
        font-size: ${stylingVariables.fontSize.medium};
        font-weight: ${stylingVariables.fontWeight.regular};
        font-style: italic;
    }
    &:-moz-placeholder {
        font-size: ${stylingVariables.fontSize.medium};
        font-weight: ${stylingVariables.fontWeight.regular};
        font-style: italic;
    }
  }
`;

interface TextAreaProps {
    label?: string;
    placeholder?: string;

    isRequired?: boolean;
    defaultValue?: string;
    onChange?: (event: ChangeEvent<HTMLTextAreaElement>) => void;
    value?: string;

    height?: string;
    flex?: number;
}

export default class TextArea extends PureComponent<TextAreaProps> {
    render() {
        return (
            <StyledTextAreaWrapper height={this.props.height} flex={this.props.flex}>
                <label>
                    {this.props.label} {this.props.isRequired && '*'}
                </label>

                <textarea
                    placeholder={this.props.placeholder}
                    defaultValue={this.props.defaultValue}
                    onChange={this.props.onChange}
                    value={this.props.value}
                />
            </StyledTextAreaWrapper>
        );
    }
}
