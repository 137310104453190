import { Table as InfiniteTable } from 'react-virtualized';
import styled from 'styled-components';
import {stylingVariables} from '@global';

export const StyledInfiniteTable = styled(InfiniteTable)<{isMenuCollapsed?: boolean, maxWidth?: string; realWidth?: number}>`
  overflow-y: auto;
  width: calc(100vw - 40px - ${props => props.isMenuCollapsed ? stylingVariables.layout.menuCollapsedWidth : stylingVariables.layout.menuWidth });
  ${props => props.maxWidth ? `max-width: ${props.maxWidth}px` : null};
  
  ${props => props.realWidth ? `width: ${props.realWidth}px` : null};

  .ReactVirtualized__Table__Grid {
    outline: none;
  }

  .ReactVirtualized__Table__headerRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow: unset!important;
  }

  .ReactVirtualized__Table__headerColumn {
    white-space: nowrap;
    font-size: ${stylingVariables.fontSize.medium};
    font-weight: ${stylingVariables.fontWeight.bold};
    color: ${stylingVariables.colorPalette.deepGray};
    padding: 5px 20px;
    border-right: 1px solid ${stylingVariables.colorPalette.deepGray};
    &:last-child {
      border-right: none;
    }
  }

  .ReactVirtualized__Table__row {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: transparent;
    border-radius: ${stylingVariables.layout.borderRadius};
    outline: none;
    overflow: visible!important;
    margin: 1px 0;

    &.ReactVirtualized__Table__row__clickable {
      cursor: pointer;
    }

    .ReactVirtualized__Table__rowColumn {
        display: flex;
        align-items: center;
        background-color: ${stylingVariables.colorPalette.white};
        padding: 0 20px;
        font-size: ${stylingVariables.fontSize.default};
        font-weight: ${stylingVariables.fontWeight.regular};
        height: calc(100% - 10px);
        white-space: nowrap;
        text-overflow: ellipsis;
        &:first-child {
          border-radius: 5px 0 0 5px;
        }
        &:last-child {
          border-radius: 0 5px 5px 0;
        }

        &.ReactVirtualized__Table__rowColumn__no_padding {
          padding: 0;
        }

        &.ReactVirtualized__Table__rowColumn__center {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        &.ReactVirtualized__Table__rowColumn__bold {
          font-weight: ${stylingVariables.fontWeight.bold};
        }
    }

    &.highlighted {
      .ReactVirtualized__Table__rowColumn {
        background-color: ${stylingVariables.colorPalette.primaryHover};
      }
    }
  }

  .ReactVirtualized__Grid__innerScrollContainer {
    margin-bottom: 30px;
    overflow: visible!important;
  }
`;

export default StyledInfiniteTable;
