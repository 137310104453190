import React, {memo} from 'react';
import {constants} from '@global';
import { ReactComponent as IconEmployees } from '../assets/images/employees.svg';
import { ReactComponent as IconPayroll } from '../assets/images/payroll.svg';
import { ReactComponent as IconCompany } from '../assets/images/company.svg';
import { ReactComponent as IconReports } from '../assets/images/reports.svg';
import { ReactComponent as IconCalendar } from '../assets/images/calendar.svg';
import { ReactComponent as IconHelp } from '../assets/images/help.svg';
import { ReactComponent as IconTravel } from '../assets/images/travel.svg';
import { ReactComponent as IconMss } from '../assets/images/mss.svg';
import { ReactComponent as IconSearch } from '../assets/images/search.svg';
import { ReactComponent as IconSkyline } from '../assets/images/skyline.svg';
import { ReactComponent as IconArrowDown } from '../assets/images/arrow-down.svg';
import { ReactComponent as IconArrowUp } from '../assets/images/arrow-up.svg';
import { ReactComponent as IconArrowLeft } from '../assets/images/arrow-left.svg';
import { ReactComponent as IconArrowRight } from '../assets/images/arrow-right.svg';
import { ReactComponent as IconArrowBack } from '../assets/images/arrow-back.svg';
import { ReactComponent as IconFilledArrowDown } from '../assets/images/filled-arrow-down.svg';
import { ReactComponent as IconFilledArrowUp } from '../assets/images/filled-arrow-up.svg';
import { ReactComponent as IconMenu } from '../assets/images/menu.svg';
import { ReactComponent as IconClose } from '../assets/images/close.svg';
import { ReactComponent as IconSettings } from '../assets/images/settings.svg';
import { ReactComponent as IconCheckMark } from '../assets/images/check-mark.svg';
import { ReactComponent as IconSkype } from '../assets/images/skype.svg';
import { ReactComponent as IconPhone } from '../assets/images/phone.svg';
import { ReactComponent as IconMail } from '../assets/images/mail.svg';
import { ReactComponent as IconDebt } from '../assets/images/debt.svg';
import { ReactComponent as IconFilter } from '../assets/images/filter.svg';
import { ReactComponent as IconSolidFilter } from '../assets/images/solid-filter.svg';
import { ReactComponent as IconAdd } from '../assets/images/add.svg';
import { ReactComponent as IconBoldAdd } from '../assets/images/bold-add.svg';
import { ReactComponent as IconExport } from '../assets/images/export.svg';
import { ReactComponent as IconDotAndCircle } from '../assets/images/dot-and-circle.svg';
import { ReactComponent as IconTimeLeft } from '../assets/images/time-left.svg';
import { ReactComponent as IconFile } from '../assets/images/file.svg';
import { ReactComponent as IconWarning } from '../assets/images/warning.svg';
import { ReactComponent as IconInfoCircle } from '../assets/images/info-circle.svg';
import { ReactComponent as IconFilePNG } from '../assets/images/png.svg';
import { ReactComponent as IconFilePDF } from '../assets/images/pdf.svg';
import { ReactComponent as IconFileDOC } from '../assets/images/doc.svg';
import { ReactComponent as IconLogout } from '../assets/images/logout.svg';
import { ReactComponent as IconGrid } from '../assets/images/grid.svg';
import { ReactComponent as IconUser } from '../assets/images/user.svg';
import { ReactComponent as IconMove } from '../assets/images/move.svg';
import { ReactComponent as IconTemplates } from '../assets/images/templates.svg';
import { ReactComponent as IconFileDownload } from '../assets/images/file-download.svg';
import { ReactComponent as IconFileUpload } from '../assets/images/file-upload.svg';
import { ReactComponent as IconPencilEdit } from '../assets/images/pencil-edit.svg';
import { ReactComponent as IconTrash } from '../assets/images/trash.svg';
import { ReactComponent as IconCloudStorageUpload } from '../assets/images/cloud-storage-upload.svg';
import { ReactComponent as IconKey } from '../assets/images/key.svg';
import { ReactComponent as IconEdrive } from '../assets/images/edrive.svg';
import { ReactComponent as IconPayslips } from '../assets/images/payslips.svg';
import { ReactComponent as IconMyInfo } from '../assets/images/myinfo.svg';
import { ReactComponent as IconLeave } from '../assets/images/leave.svg';
import { ReactComponent as IconTime } from '../assets/images/time.svg';
import { ReactComponent as IconExpense } from '../assets/images/expense.svg';
import { ReactComponent as IconSupport } from '../assets/images/help_and_support.svg';
import { ReactComponent as Cookie } from '../assets/images/cookie.svg';
import { ReactComponent as Interview } from '../assets/images/interview.svg';
import { ReactComponent as IconDrag } from '../assets/images/drag.svg';
import { ReactComponent as IconDragVertical } from '../assets/images/drag-vertical.svg';
import { ReactComponent as Dashboard } from '../assets/images/dashboard.svg';
import { ReactComponent as IconEye } from '../assets/images/eye.svg';
import { ReactComponent as IconThinArrowRight } from '../assets/images/thin-arrow-right.svg';
import { ReactComponent as IconUserSecondary } from '../assets/images/user-secondary.svg';
import { ReactComponent as IconRevoke } from '../assets/images/revoke.svg';
import { ReactComponent as IconWarningAlert } from '../assets/images/warning-alert.svg';

export interface IconProps {
    type: string;
    fillColor?: string;
    width?: number;
    height?: number;
}

const Icon: React.FC<IconProps> = ({ type, fillColor, width, height }) => {
    switch (type) {
        case constants.IconTypes.EMPLOYEES:
            return <IconEmployees width={width} height={height} fill={fillColor}/>;
        case constants.IconTypes.PAYROLL:
            return <IconPayroll width={width} height={height} fill={fillColor}/>;
        case constants.IconTypes.COMPANY:
            return <IconCompany width={width} height={height} fill={fillColor}/>;
        case constants.IconTypes.TRAVEL:
            return <IconTravel width={width} height={height} fill={fillColor}/>;
        case constants.IconTypes.REPORTS:
            return <IconReports width={width} height={height} fill={fillColor}/>;
        case constants.IconTypes.CALENDAR:
            return <IconCalendar width={width} height={height} fill={fillColor}/>;
        case constants.IconTypes.HELP:
            return <IconHelp width={width} height={height} fill={fillColor}/>;
        case constants.IconTypes.MSS:
            return <IconMss width={width} height={height} fill={fillColor}/>;
        case constants.IconTypes.SEARCH:
            return <IconSearch width={width} height={height} fill={fillColor}/>;
        case constants.IconTypes.SKYLINE:
            return <IconSkyline width={width} height={height} fill={fillColor}/>;
        case constants.IconTypes.ARROW_DOWN:
            return <IconArrowDown width={width} height={height} fill={fillColor}/>;
        case constants.IconTypes.ARROW_UP:
            return <IconArrowUp width={width} height={height} fill={fillColor}/>;
        case constants.IconTypes.ARROW_LEFT:
            return <IconArrowLeft width={width} height={height} fill={fillColor}/>;
        case constants.IconTypes.ARROW_RIGHT:
            return <IconArrowRight width={width} height={height} fill={fillColor}/>;
        case constants.IconTypes.ARROW_BACK:
            return <IconArrowBack width={width} height={height} fill={fillColor}/>;
        case constants.IconTypes.FILLED_ARROW_DOWN:
            return <IconFilledArrowDown width={width} height={height} fill={fillColor}/>;
        case constants.IconTypes.FILLED_ARROW_UP:
            return <IconFilledArrowUp width={width} height={height} fill={fillColor}/>;
        case constants.IconTypes.MENU:
            return <IconMenu width={width} height={height} fill={fillColor}/>;
        case constants.IconTypes.CLOSE:
            return <IconClose width={width} height={height} fill={fillColor}/>;
        case constants.IconTypes.SETTINGS:
            return <IconSettings width={width} height={height} fill={fillColor}/>;
        case constants.IconTypes.FILTER:
            return <IconFilter width={width} height={height} fill={fillColor}/>;
        case constants.IconTypes.SOLID_FILTER:
            return <IconSolidFilter width={width} height={height} fill={fillColor}/>;
        case constants.IconTypes.CHECK_MARK:
            return <IconCheckMark width={width} height={height} fill={fillColor}/>;
        case constants.IconTypes.MAIL:
            return <IconMail width={width} height={height} fill={fillColor}/>;
        case constants.IconTypes.PHONE:
            return <IconPhone width={width} height={height} fill={fillColor}/>;
        case constants.IconTypes.SKYPE:
            return <IconSkype width={width} height={height} fill={fillColor}/>;
        case constants.IconTypes.DEBT:
            return <IconDebt width={width} height={height} fill={fillColor}/>;
        case constants.IconTypes.ADD:
            return <IconAdd width={width} height={height} fill={fillColor}/>;
        case constants.IconTypes.BOLD_ADD:
            return <IconBoldAdd width={width} height={height} fill={fillColor}/>;
        case constants.IconTypes.EXPORT:
            return <IconExport width={width} height={height} fill={fillColor}/>;
        case constants.IconTypes.TIME_LEFT:
            return <IconTimeLeft width={width} height={height} fill={fillColor}/>;
        case constants.IconTypes.DOT_AND_CIRCLE:
            return <IconDotAndCircle width={width} height={height} fill={fillColor}/>;
        case constants.IconTypes.FILE:
            return <IconFile width={width} height={height} fill={fillColor}/>;
        case constants.IconTypes.FILE_PDF:
            return <IconFilePDF width={width} height={height} fill={fillColor}/>;
        case constants.IconTypes.FILE_PNG:
            return <IconFilePNG width={width} height={height} fill={fillColor}/>;
        case constants.IconTypes.FILE_DOC:
            return <IconFileDOC width={width} height={height} fill={fillColor}/>;
        case constants.IconTypes.WARNING:
            return <IconWarning width={width} height={height} fill={fillColor}/>;
        case constants.IconTypes.INFO_CIRCLE:
            return <IconInfoCircle width={width} height={height} fill={fillColor}/>;
        case constants.IconTypes.LOG_OUT:
            return <IconLogout width={width} height={height} fill={fillColor}/>;
        case constants.IconTypes.GRID:
            return <IconGrid width={width} height={height} fill={fillColor}/>;
        case constants.IconTypes.USER:
            return <IconUser width={width} height={height} fill={fillColor}/>;
        case constants.IconTypes.MOVE:
            return <IconMove width={width} height={height} fill={fillColor}/>;
        case constants.IconTypes.TEMPLATES:
            return <IconTemplates width={width} height={height} fill={fillColor}/>;
        case constants.IconTypes.FILE_DOWNLOAD:
            return <IconFileDownload width={width} height={height} fill={fillColor}/>;
        case constants.IconTypes.FILE_UPLOAD:
            return <IconFileUpload width={width} height={height} fill={fillColor}/>;
        case constants.IconTypes.PENCIL_EDIT:
            return <IconPencilEdit width={width} height={height} fill={fillColor}/>;
        case constants.IconTypes.TRASH:
            return <IconTrash width={width} height={height} fill={fillColor}/>;
        case constants.IconTypes.CLOUD_STORAGE_UPLOAD:
            return <IconCloudStorageUpload width={width} height={height} fill={fillColor}/>;
        case constants.IconTypes.KEY:
            return <IconKey width={width} height={height} fill={fillColor}/>;
        case constants.IconTypes.EDRIVE:
            return <IconEdrive width={width} height={height} fill={fillColor}/>;
        case constants.IconTypes.PAYSLIPS:
            return <IconPayslips width={width} height={height} fill={fillColor}/>;
        case constants.IconTypes.MY_INFO:
            return <IconMyInfo width={width} height={height} fill={fillColor}/>;
        case constants.IconTypes.LEAVE:
            return <IconLeave width={width} height={height} fill={fillColor}/>;
        case constants.IconTypes.TIME:
            return <IconTime width={width} height={height} fill={fillColor}/>;
        case constants.IconTypes.EXPENSE:
            return <IconExpense width={width} height={height} fill={fillColor}/>;
        case constants.IconTypes.SUPPORT:
            return <IconSupport width={width} height={height} fill={fillColor}/>;
        case constants.IconTypes.COOKIE:
            return <Cookie width={width} height={height} fill={fillColor}/>;
        case constants.IconTypes.INTERVIEW:
            return <Interview width={width} height={height} fill={fillColor}/>;
        case constants.IconTypes.DRAG:
            return <IconDrag width={width} height={height} fill={fillColor}/>;
        case constants.IconTypes.DRAG_VERTICAL:
            return <IconDragVertical width={width} height={height} fill={fillColor}/>;
        case constants.IconTypes.DASHBOARD:
            return <Dashboard width={width} height={height} fill={fillColor}/>;
        case constants.IconTypes.EYE:
            return <IconEye width={width} height={height} fill={fillColor}/>;
        case constants.IconTypes.THIN_ARROW_RIGHT:
            return <IconThinArrowRight width={width} height={height} fill={fillColor}/>;
        case constants.IconTypes.USER_SECONDARY:
            return <IconUserSecondary width={width} height={height} fill={fillColor}/>;
        case constants.IconTypes.REVOKE:
            return <IconRevoke width={width} height={height} fill={fillColor}/>;
        case constants.IconTypes.WARNING_ALERT:
            return <IconWarningAlert width={width} height={height} fill={fillColor}/>;
        default:
            console.error('Wrong icon name was passed!', type);
            return null;
    }
};

export default memo(Icon);
