import {ApiObject} from '@api';
import {DateRangeField, Dropdown} from '@common'
import {intl, interfaces, constants, stylingVariables} from '@global'
import React, {PureComponent} from 'react';
import {connect} from 'react-redux';

import {RootState} from '../../../../../rootReducer';
import * as reportsSelectors from '../../../selectors';
import {isMonthlyPeriods, isReportProcesses} from '../../../utils'
import {changePeriods} from '../../redux';
import * as selectors from '../../selectors';
import {DateHelper} from '@library'
import styled from 'styled-components'

const StyledLabel = styled.span`
  font-size: ${stylingVariables.fontSize.default};
  font-weight: ${stylingVariables.fontWeight.regular};
  color: ${stylingVariables.colorPalette.slateGray};
  margin-right: 5px;
  white-space: nowrap;
`;

const StyledDropdownWrapper = styled.div`
  display: flex;
  align-items: center;
  > div > div {
    min-width: 200px;
  } 
`

interface IProps {
    processMode: constants.ReportProcessMode;
    legalEntityPeriods: ApiObject.LegalEntityPeriod[];
    periods: interfaces.Periods;
    changePeriods: (periods: interfaces.Periods) => any;
    report: ApiObject.Report|null;
}

export class DateRangeSelector extends PureComponent<IProps> {
    public onChange = (periods: interfaces.Periods) => {
        this.props.changePeriods(periods as interfaces.Periods);
    }

    get legalEntityPeriods() {
        if (this.props.report) {
            return this.props.report.legalEntityReportType === ApiObject.DataSource.payroll ?
              this.props.legalEntityPeriods.filter(period => period.periodStatus === 'done') :
              this.props.legalEntityPeriods.filter(period => period.periodStatus !== 'scheduled')
        }
        return this.props.legalEntityPeriods
    }

    renderMonthPicker() {
        return <DateRangeField
          label={intl.get('report_date')}
          allowPeriods={this.legalEntityPeriods}
          periods={ this.props.periods || []}
          onChange={this.onChange}
          multiselect={false}
        />
    }

    renderDropdownPicker() {
        const keyDelimiter: string = '_'
        const period = this.props.periods.length > 0 ? this.props.periods[0] : null
        const allowPeriods = this.legalEntityPeriods.sort((a, b) => a.beginDate > b.beginDate ? 1 : -1)

        const selectedValue = period ? `${period.beginDate}${keyDelimiter}${period.endDate}` : undefined
        const options = allowPeriods.map(p => {
            return {key: `${p.beginDate}${keyDelimiter}${p.endDate}`, label: DateHelper.convertPeriodToHumanFormat(p)}
        })
        const onChange = (key: string) => {
            const [beginDate, endDate]: string[] = key.split(keyDelimiter)
            this.onChange([{beginDate, endDate}])
        }
        // Allow only single selecting now
        return <StyledDropdownWrapper>
            <StyledLabel>{intl.get('report_date')}</StyledLabel>
            <Dropdown
              selectedValue={selectedValue}
              list={options}
              onChange={onChange}
              disabled={options.length === 0}
            />
        </StyledDropdownWrapper>;
    }

    render() {
        if (!isReportProcesses(this.props.processMode)) {
            return null;
        }

        return isMonthlyPeriods(this.legalEntityPeriods)
          ? this.renderMonthPicker()
          : this.renderDropdownPicker()
    }
}

const mapStateToProps = (state: RootState) => ({
    processMode: selectors.getMode(state),
    periods: selectors.getPeriods(state),
    legalEntityPeriods: reportsSelectors.getLegalEntityPeriods(state),
    report: selectors.getReport(state),
});

const mapDispatchToProps = {
    changePeriods,
};

export default connect(mapStateToProps, mapDispatchToProps)(DateRangeSelector);
