import {AnyAction, Dispatch} from 'redux';
import {ThunkAction} from 'redux-thunk';
import moment from 'moment';
import {toast} from 'react-toastify';

import {ApiObject, ReportQueryParams, ApiReport} from '@api';
import {interfaces, intl} from '@global';
import {DefaultListingHelper, ArrayHelper} from '@library';

import {RootState} from '../../../rootReducer';
import {startLoading, stopLoading} from '../../redux';
import {ReportVisibility, EVENT_REPORT_WAS_UPDATED} from '../constants';

import * as globalSelectors from '../../selectors';
import * as basicSelectors from '../selectors';
import * as selectors from './selectors';

type ThunkResult<R> = ThunkAction<R, RootState, undefined, AnyAction>;

export const SET_FILTER_SEARCH = 'reports/reports/setFilterSearch';
export const SET_FILTER_YEAR = 'reports/reports/setFilterYear';
export const SET_FILTER_VISIBILITY = 'reports/reports/setFilterVisibility';
export const SET_REPORTS = 'reports/reports/setReports';
export const SET_ORDERS = 'reports/reports/setOrders';
export const SET_FILTERS = 'reports/reports/setFilters';

export interface ReportsState {
	filterSearch: string;
	filterYear: number;
	filterVisibility: ReportVisibility;

	reports: ApiObject.Report[];
	reportsTotalCount: number;
	filters: interfaces.ListingFilters;
	orders: interfaces.ListingOrders;
}

export const defaultState: ReportsState = {
	filterSearch: '',
	filterYear: parseInt(moment().format('YYYY')),
	filterVisibility: ReportVisibility.private,
	reports: [],
	reportsTotalCount: 0,
	filters: {},
	orders: {}
};

export default function reducer(
	state = defaultState,
	action: AnyAction,
): ReportsState {
	switch (action.type) {
		case SET_FILTER_SEARCH:
			return {
				...state,
				filterSearch: action.payload,
			};

		case SET_FILTER_YEAR:
			return {
				...state,
				filterYear: action.payload,
			};

		case SET_FILTER_VISIBILITY:
			return {
				...state,
				filterVisibility: action.payload,
			};

		case SET_REPORTS:
			return {
				...state,
				reports: action.payload.reports,
				reportsTotalCount: action.payload.totalCount
			}

		case SET_ORDERS:
			return {
				...state,
				orders: action.payload,
			};

		case SET_FILTERS:
			return {
				...state,
				filters: action.payload,
			};

		case EVENT_REPORT_WAS_UPDATED:
			const report = action.payload;
			return {
				...state,
				reports: report
					? ArrayHelper.replaceObjectByKey([...state.reports], 'id', report, false)
					: state.reports
			};

		default:
			return state;
	}
}
export function clearOldAndGetReports(): ThunkResult<Promise<void>> {
	return async(dispatch: Dispatch, getState: () => RootState) => {
		const state = getState();
		const currentLegalEntityId = globalSelectors.getCurrentLegalEntityId(state);
		if (!currentLegalEntityId) {
			return;
		}

		await dispatch(setReports([]));

		dispatch(startLoading());
		let {reports, totalCount} = await ApiReport.list(currentLegalEntityId, new ReportQueryParams({
			year: selectors.getFilterYear(state),
			visibilities: [selectors.getFilterVisibility(state)]
		}));
		dispatch(stopLoading());

		let generatingReports: ApiObject.Report[] = [];
		const loadedReportIds: number[] = reports.map((r: ApiObject.Report) => r.id)
		const generatingReportIds: number[] = basicSelectors.getGeneratingReportIds(getState())
			.filter(id => !loadedReportIds.includes(id));

		if (generatingReportIds.length > 0) {
			const response = await ApiReport.list(currentLegalEntityId, new ReportQueryParams({
				ids: generatingReportIds,
				visibilities: [selectors.getFilterVisibility(state)]
			}));
			generatingReports = response.reports;
		}

		reports = [...generatingReports, ...reports];

		if (reports.length === 0) {
			return;
		}

		dispatch(setReports(reports, totalCount));

		return;
	};
}

export function resetFiltersAndSorts(): ThunkResult<void> {
	return async (dispatch: Dispatch) => {
		await dispatch(setOrders({}));
		await dispatch(setFilters({}));
		await dispatch(setFilterSearch(''));
		await dispatch(setFilterYear(moment().year()));
		dispatch(clearOldAndGetReports() as any);
	};
}

export function changeOrder(column: string): ThunkResult<void> {
	return async (dispatch: Dispatch, getState: () => RootState) => {
		const orders: interfaces.ListingOrders = {...selectors.getOrders(getState())};
		await dispatch(setOrders(DefaultListingHelper.updatedOrders(column, orders, false)));
	};
}

export function changeFilter(params: interfaces.FilterParams): ThunkResult<void> {
	return async (dispatch: Dispatch, getState: () => RootState) => {
		const filters: interfaces.ListingFilters = {...selectors.getFilters(getState())};
		await dispatch(setFilters(DefaultListingHelper.updatedFilters(params, filters)));
	};
}

export function changeFilterSearch(search: string): ThunkResult<Promise<void>>  {
	return async (dispatch: Dispatch) => {
		await dispatch(setFilterSearch(search));
	}
}
export function changeFilterYear(year: number): ThunkResult<Promise<void>>  {
	return async (dispatch: Dispatch) => {
		await dispatch(setFilterYear(year));
		dispatch(clearOldAndGetReports() as any);
	}
}
export function changeFilterVisibility(visibility: ReportVisibility): ThunkResult<Promise<void>>  {
	return async (dispatch: Dispatch) => {
		await dispatch(setFilterVisibility(visibility));
		dispatch(clearOldAndGetReports() as any);
	}
}

export function deleteReport(id: number): ThunkResult<Promise<void>> {
	return async(dispatch: Dispatch, getState: () => RootState) => {
		const entityId = getState().global.currentLegalEntityId;
		if (!entityId) {
			return;
		}

		const response = await ApiReport.deleteReport(entityId, id);

		if (response.status === 200) {
			const state = getState();
			const reports: ApiObject.Report[] = [...selectors.getReports(state)];
			const totalCount = selectors.getReportsTotalCount(state);

			const oldReports = reports
				.findIndex((t: ApiObject.Report) => t.id === id);

			if (oldReports !== -1) {
				reports.splice(oldReports, 1);
			}

			dispatch(setReports(reports, totalCount - 1));
			toast.success(intl.get('report_removed_ok'));
		} else {
			toast.error(intl.get('report_removed_nok'));
		}

		return;
	};
}

function setFilterSearch(search: string = ''): AnyAction {
	return {
		type: SET_FILTER_SEARCH,
		payload: search
	}
}
function setFilterYear(year: number): AnyAction {
	return {
		type: SET_FILTER_YEAR,
		payload: year
	}
}
function setFilterVisibility(visibility: ReportVisibility): AnyAction {
	return {
		type: SET_FILTER_VISIBILITY,
		payload: visibility
	}
}
function setReports(reports: ApiObject.Report[] = [], totalCount: number = 0): AnyAction {
	return {
		type: SET_REPORTS,
		payload: {
			reports,
			totalCount
		}
	}
}
export function setOrders(orders: interfaces.ListingOrders = {}): AnyAction {
	return {
		type: SET_ORDERS,
		payload: orders
	}
}
function setFilters(filters: interfaces.ListingFilters = {}): AnyAction {
	return {
		type: SET_FILTERS,
		payload: filters
	}
}
