import {ApiObject, EmployeeInterfaces, PayrollInterfaces} from '@api';
import React, {PureComponent} from 'react';
import {AutoSizer } from 'react-virtualized';
import styled from 'styled-components';

import {InfiniteTableColumn, StyledInfiniteTable as CommonStyledInfiniteTable} from '@common';
import {stylingVariables, intl} from '@global';
import * as Columns from './Column/index';
import {COLUMN_WIDTH_ACTIONS, COLUMN_WIDTH_DEFAULT, COLUMN_WIDTH_LABEL, COLUMN_WIDTH_IS_ACTIVE} from './constants';
import {Row} from './Row';

const StyledInfiniteTable = styled(CommonStyledInfiniteTable)`
    .ReactVirtualized__Table__row{
        background-color: ${stylingVariables.colorPalette.gray};
        .ReactVirtualized__Table__rowColumn {
            background-color: ${stylingVariables.colorPalette.gray};
        }
    }
    .ReactVirtualized__Table__headerColumn {
        white-space: normal;
    }
`;

interface TableProps {
    width?: number;
    rows: EmployeeInterfaces.GroupedEmployeePayElementHistory[];
    currentPayrollPeriod: PayrollInterfaces.PayrollPeriod|null;
    changeWrapperHeight?: (tableHeight: number) => void;
}

interface TableState {
    rows: EmployeeInterfaces.GroupedEmployeePayElementHistory[];
    order: number;
    openRows: number[];
}

export class HistoryTable extends PureComponent<TableProps, TableState> {
    private table: any;

    public constructor(props: TableProps) {
        super(props);

        this.state = {
            rows: this.props.rows,
            order: 1,
            openRows: [],
        };
    }

    private setRef = (ref: any) => {
        this.table = ref;
    }

    private get realTableWidth() {
        return 5 * COLUMN_WIDTH_DEFAULT + COLUMN_WIDTH_LABEL + COLUMN_WIDTH_ACTIONS + COLUMN_WIDTH_IS_ACTIVE;
    }

    componentDidUpdate(prevProps: Readonly<TableProps>, prevState: Readonly<TableState>) {
        if (this.props.rows.length !== prevProps.rows.length ||
            (this.props.rows.length &&  JSON.stringify(prevProps.rows) !== JSON.stringify(this.props.rows))
        ) {
            this.setState({ rows: this.props.rows}, () => this.afterRowHeightChange());
        }
    }

    private afterRowHeightChange = (index?: number) => {
        if (this.props.changeWrapperHeight) {
            let tableHeight = 0;
            this.state.rows.forEach((item, idx) => {
                if (typeof this.state.openRows.find((x: number) => x === idx) === 'number') {
                    tableHeight += (item.history.length * 50) + 90;
                } else {
                    tableHeight += 50;
                }
            });
            this.props.changeWrapperHeight(tableHeight || 50);
        }
        this.table.recomputeRowHeights(index);
    }

    private toggleRowHeight = (index: number) => {
        this.setState(
            ({ openRows }) => ({
                openRows: openRows.includes(index)
                    ? openRows.filter(tallIndex => tallIndex !== index)
                    : [...openRows, index],
            }),
            () => this.afterRowHeightChange(index),
        );
    }

    private getRowHeight = ({ index }: {index: number}) => {
        const groupedItems = this.state.rows[index]?.history?.length > 1;
        const openLength = (this.state.rows[index]?.history?.length * 50) + 90;

        return this.state.openRows.includes(index) && groupedItems ? openLength : (groupedItems ? 70 : 50);
    }

    public render() {
        return (
            this.props.rows && <AutoSizer>
                {({width, height}) => (
                    <StyledInfiniteTable
                        ref={this.setRef}
                        rowClassName={'ReactVirtualized__Table__row_salary'}
                        overscanRowCount={5}
                        rowHeight={this.getRowHeight}
                        headerHeight={40}
                        rowRenderer={Row()}
                        rowGetter={({ index }: {index: number}) => this.props.rows[index]}
                        rowCount={this.props.rows.length}
                        maxWidth={this.props.width}
                        height={height}
                        width={width > this.realTableWidth ? width : this.realTableWidth}
                    >
                        {Columns.GroupLabelWithStatus({
                            toggleRowHeight: this.toggleRowHeight,
                            openRows: this.state.openRows,
                        })}
                        {InfiniteTableColumn.Amount({
                            label: intl.get('amount'),
                            dataKey: 'totalAmount',
                        })}
                        {InfiniteTableColumn.Default({
                            label: intl.get('currency_frequency'),
                            dataKey: 'currencyAndFrequency',
                        })}
                        {InfiniteTableColumn.Default({
                            label: intl.get('start'),
                            dataKey: 'history[0].startDate',
                            type: ApiObject.FieldType.date,
                            width: 120,
                        })}
                        {InfiniteTableColumn.Default({
                            label: intl.get('end'),
                            dataKey: 'history[0].endDate',
                            type: ApiObject.FieldType.date,
                            width: 120,
                        })}
                    </StyledInfiniteTable>
                )}
            </AutoSizer>
        );
    }
}
