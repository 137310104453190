import {stylingVariables} from '@global';
import styled from "styled-components";

export const Wrapper = styled.div<{readOnly: boolean}>`
  padding: 10px;
  margin: 10px;
  background-color: #fff;
  
  width: 100%;
  li {
    list-style: none;
  }
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  
  min-width: 350px;
  
  &, *, *:hover {
    transition: background-color .3s, border .3s, opacity .3s;
  }
  
  >div {
    width: 100%;
  }
  
  ${props => props.readOnly && `
    pointer-events: none!important;
    cursor: default!important;
    
    input, .simple_dropdown__header>span {
        font-weight: 400!important;  
    }
  `};
`;
export const BuilderHeading = styled.div`
  position: relative;
  width: 100%;
  height: 20px;
  min-height: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0;
  &:after {
    content: '';
    height: 1px;
    background-color: ${stylingVariables.colorPalette.darkGray};
    width: 100%;
    position: absolute;
    top: 12px;
    left: 0;
  }
`;

export const BuilderHeadingLabel = styled.div`
  margin: 0 15px;
  padding: 0 15px;
  background-color: #fff;
  z-index: 1;
  position: relative;
  font-size: 13px;
  font-weight: ${stylingVariables.fontWeight.semibold};
  >div {
    pointer-events: auto;
    position: absolute;
    top: -10px;
    right: -13px;
    margin: 0;
  }
  .icon-popover-warning-left {
    right: inherit;
    left: -15px;
    top: -14px;
  }
`;
export const BuilderHeadingQuickAdding = styled.div`
  padding: 0 15px;
  margin: 0 15px;
  background-color: #fff;
  z-index: 2;
`;

export const Groups = styled.div<{readOnly: boolean}>`
  padding-right: 20px;
  ${props => props.readOnly && `
    opacity: .6;
  `};
`;
export const GroupContainer = styled.div`
  padding-top: 30px;
  padding-bottom: 20px;
  z-index: 2;
  width: 100%;
`;
export const GroupWrapper = styled.div<{hasError: boolean}>`
  position: relative;
  background-color: #fff;
  border-radius: 10px;
  min-height: 60px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  
  &:hover {
    transition: all .3s;
  }
  
  ${props => props.hasError && `
    border-color: ${stylingVariables.colorPalette.red};
    ${GroupHeader} {
      box-shadow: 0 0 4px 0 ${stylingVariables.colorPalette.red};
    }
  `}
`;

export const FieldContainer = styled.li`
  position: relative;
  padding: 5px 0;
`;
export const FieldWrapper = styled.div<{hasError: boolean}>`
  position: relative;
  height: 40px;
  display: flex;
  background-color: transparent;
  border-radius: 20px;
  ${props => props.hasError && `
    box-shadow: 0 0 4px 0 ${stylingVariables.colorPalette.red};
    border-color: ${stylingVariables.colorPalette.red};
  `}
`;

export const GroupHeader = styled.div`
  position: absolute;
  top: -20px;
  left: 10px;
  height: 40px;
  margin: 0 10px;
  background-color: #fff;
  overflow: hidden;
  display: flex;
  width: calc(100% - 40px);
  border-radius: 20px;
`;
export const GroupHeaderInputBox = styled.div`
  width: 100%;
  height: 40px;
  input {
    &, &:focus {
      border: 1px solid ${stylingVariables.colorPalette.darkerGray};
    }
    font-weight: 600;
    padding: 10px;
    height: 40px;
    border-left: none!important;
    border-radius: 0 20px 20px 0;
  }
`;

export const SortIcon = styled.div<{isOpened?: boolean}>`
  min-height: 40px;
  min-width: 40px;
  background-color: ${stylingVariables.colorPalette.lightGray};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: move;
  user-select: none;
  border-radius: 20px 0 0 20px;
  border: 1px solid ${stylingVariables.colorPalette.darkerGray};
  border-right: none!important;
  
  ${props => props.isOpened && `
    z-index: 11;
    border-color: ${stylingVariables.colorPalette.mediumGreen};
  `};
`;

export const QuickAddingIcon = styled.div<{isOpened: boolean}>`
  display: flex;
  height: 12px;
  width: 12px;
  align-items: center;
  justify-content: center;
  svg {
    ${props => props.isOpened && `
        transform: rotate(45deg);
    `};
    
    transition: all .3s;
  }
`;

export const DropdownActionsWrapper = styled.div`
  .dropdown_actions__button {
    font-size: ${stylingVariables.fontSize.mediumLarge};
    color: ${stylingVariables.colorPalette.green};
  }
  .dropdown_actions__icon_wrapper {
    height: 18px;
    width: 18px;
    background-color: ${stylingVariables.colorPalette.green};
  }
  
  .dropdown_actions__absolute_wrapper {
    top: 25px;
    padding: 0;
    .dropdown_actions__absolute_content {
      padding: 10px;
      box-shadow: 0 4px 10px 0 rgba(0,0,0,0.26)
    }
  }
`;

export const RemoveIconContainer = styled.div`
  position: absolute;
  right: -14px;
  top: -10px;
  z-index: 1;
  padding: 4px;
  background-color: #fff;
  border-radius: 50%;
`;
export const RemoveFieldIconContainer = styled.div`
  position: absolute;
  right: -30px;
  top: 15px;
  z-index: 1;
  padding: 3px;
  background-color: #fff;
  border-radius: 50%;
`;

export const FieldDropdown = styled.div`
  width: 100%;
  height: 40px;
  .simple_dropdown__wrapper {
    height: 100%;
    
    
    .simple_dropdown__content__search_input {
      border: 1px solid ${stylingVariables.colorPalette.darkerGray};
      border-radius: 21px;
      
      display: inline-flex;
      flex-direction: row-reverse;
      width: 100%;

      input {
        border: none!important;
        background-color: transparent;
      }
    }
    
    &.opened {
      .simple_dropdown__header {
        border-color: ${stylingVariables.colorPalette.mediumGreen};
        .simple_dropdown__header_arrow {
          background-color: ${stylingVariables.colorPalette.lightGreen};
        }
      }
    
      .simple_dropdown__content {
        border-color: ${stylingVariables.colorPalette.mediumGreen};
      }
    }
    
    .simple_dropdown__content {
        left: -40px;
        top: 20px;
        padding: 40px 20px 20px;
    }
    .simple_dropdown__header {
      height: 100%;
      background-color: #fff;
      border: 1px solid ${stylingVariables.colorPalette.darkerGray};
      padding: 0 40px 0 10px;
      border-left: none!important;
      border-radius: 0 20px 20px 0;
      &>span {
        font-weight: 600;
      }
      .simple_dropdown__header_arrow {
        width: 40px;
        height: 38px;
        border-radius: 0 20px 20px 0;
      }
    }
  }
`;

export const GroupAddButton = styled.div`
  margin-top: 10px;
  margin-left: 15px;
  button {
    font-weight: 400;
  }
`;

export const FieldAddButton = styled.div`
  margin-top: 10px;
  margin-left: 15px;
  button {
    font-weight: 400;
  }
`;
