import React, {PureComponent, Fragment} from 'react';
import {NavTabs} from '@common';
import {connect} from 'react-redux';
import {intl} from '@global';
import styled from 'styled-components';
import moment, {Moment} from 'moment';

import {
    getLeaveTypes,
    reloadLeaves,
    setPeriodFilter,
} from './redux';
import {RootState} from '../../rootReducer';

import * as globalSelectors from '../selectors';
import {constants} from '@global';
import {PayrollInterfaces} from '@api';

import ClearFiltersAndSorts from './ClearFiltersAndSorts';
import AddLeave from './AddLeave';
import Balances from './Balances/Balances';
import Details from './Details/Details';
import {NavLink, Route, Switch} from 'react-router-dom';

const StyledHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const StyledLeavesTitle = styled.div`
  display: flex;
  align-items: center;
  h3 {
    padding: 0 5px;
    margin: 0 10px 30px 20px;
  }
`;

const StyledHeaderAction = styled.div`
  display: flex;
  span {
    margin-right: 20px;
  }
`;

interface IProps {
    currentPayrollPeriod: PayrollInterfaces.PayrollPeriod|null;

    getLeaveTypes: () => void;
    reloadLeaves: () => void;
    setPeriodFilter: (from: Moment, till: Moment) => void;
}

export class Leaves extends PureComponent<IProps> {
    public constructor(props: IProps) {
        super(props);

        if (props.currentPayrollPeriod) {
            props.setPeriodFilter(
                moment(props.currentPayrollPeriod.beginDate, constants.SYSTEM_DATE_FORMAT),
                moment(props.currentPayrollPeriod.endDate, constants.SYSTEM_DATE_FORMAT),
            );
        }
    }

    public render() {
        return (
            <Fragment>
                <StyledHeaderWrapper>
                    <StyledLeavesTitle>
                        <h3>{intl.get('leave_transactions' )}</h3>
                    </StyledLeavesTitle>

                    <StyledHeaderAction>
                        <ClearFiltersAndSorts />

                        <AddLeave/>
                    </StyledHeaderAction>
                </StyledHeaderWrapper>
                <NavTabs.StyledTagsWrapper hasRouterLinks={true}>
                    <NavLink className={'router-link'} exact to={constants.ROUTE_LEAVES}>
                        <NavTabs.StyledTabSelectorButton>
                            {intl.get('leaves')}
                        </NavTabs.StyledTabSelectorButton>
                    </NavLink>
                    <NavLink className={'router-link'} exact to={constants.ROUTE_LEAVES_BALANCES}>
                        <NavTabs.StyledTabSelectorButton>
                            {intl.get('balances')}
                        </NavTabs.StyledTabSelectorButton>
                    </NavLink>
                </NavTabs.StyledTagsWrapper>
                <Switch>
                    <Route exact path={constants.ROUTE_LEAVES}>
                        <Details/>
                    </Route>
                    <Route exact path={constants.ROUTE_LEAVES_BALANCES}>
                        <Balances/>
                    </Route>
                </Switch>
            </Fragment>
        );
    }
}

const mapStateToProps = (state: RootState) => ({
    currentPayrollPeriod: globalSelectors.getCurrentPayrollPeriod(state),
});

const mapDispatchToProps = {
    getLeaveTypes,
    reloadLeaves,
    setPeriodFilter,
};

export default connect(mapStateToProps, mapDispatchToProps)(Leaves);
