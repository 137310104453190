import {Dispatch} from 'redux';
import {Moment} from 'moment';
import {toast} from 'react-toastify';

import {LeaveInterfaces, ApiLeave, LeaveQueryParams} from '@api';

import {RootState} from '../../../../rootReducer';

import * as selectors from './selectors';
import {openErrorModal, startLoading, stopLoading} from '../../../redux';
import {intl} from '@global';

export const SET_PERIOD = 'employee/setPeriod';
export const SET_PERIOD_LEAVE_TRANSACTIONS = 'employee/setPeriodLeaveTransactions';
export const SET_PERIOD_LEAVE_BALANCES = 'employee/setPeriodLeaveBalances';
export const SET_TRANSITIONAL_LEAVE_BALANCES = 'employee/setTransitionalLeaveBalances';

export function setLeaves(leaves: LeaveInterfaces.Transaction[]) {
    return {
        type: SET_PERIOD_LEAVE_TRANSACTIONS,
        payload: leaves,
    };
}

export function setPeriodLeaveBalances(balances: LeaveInterfaces.PeriodEmployeeBalance[]) {
    return {
        type: SET_PERIOD_LEAVE_BALANCES,
        payload: balances,
    };
}

export function setTransitionalLeaveBalances(balances: LeaveInterfaces.TransitionalBalances) {
    return {
        type: SET_TRANSITIONAL_LEAVE_BALANCES,
        payload: balances,
    };
}

export function setPeriod(from: Moment, till: Moment) {
    return {
        type: SET_PERIOD,
        payload: {
            from: from.format(),
            till: till.format(),
        },
    };
}

export function getLeaves() {
    return async(dispatch: Dispatch, getState: () => RootState) => {
        const entityId = getState().global.currentLegalEntityId;
        const employeeId = getState().employee.id;
        if (!entityId || !employeeId) {
            return;
        }

        dispatch(startLoading());

        try {
            const leaves = await ApiLeave.getEmployeeLeaveTransactions(
                entityId,
                new LeaveQueryParams({
                    from: selectors.getPeriodFrom(getState(), null).clone(),
                    till: selectors.getPeriodTill(getState(), null).clone(),
                    employeeIds: [employeeId],
                }),
            );
            const leavesWithPeriod: LeaveInterfaces.Transaction[] = leaves.flatMap(periodWithTransactions => {
                const transactions = periodWithTransactions.employeeTransactions[employeeId];
                if (transactions === undefined) {
                    return [];
                }
                const period: LeaveInterfaces.LegalEntityPeriod = {
                    endDate: periodWithTransactions.endDate,
                    periodId: periodWithTransactions.periodId,
                    periodName: periodWithTransactions.periodName,
                    startDate: periodWithTransactions.startDate,
                };

                return transactions.transactions.map(transaction => {
                    return {
                        ...transaction,
                        period,
                    };
                });
            });

            const transactionsMap = new Map();

            leavesWithPeriod.forEach(transaction => {
                if (!transactionsMap.has(transaction.id)) {
                    transactionsMap.set(transaction.id, {...transaction});
                }
            });

            dispatch(setLeaves([...transactionsMap.values()]));

            const balances = await ApiLeave.getEmployeeLeaveBalances(
                entityId,
                employeeId,
                selectors.getPeriodFrom(getState(), null).format('YYYY-MM-DD'),
                selectors.getPeriodTill(getState(), null).format('YYYY-MM-DD'),
            );

            dispatch(setPeriodLeaveBalances(balances));
        } catch (e) {
            const errors = e.response?.data?.errors || [];
            if (errors[0] && errors[0].message) {
                dispatch(openErrorModal(
                    [errors[0].message],
                    intl.get('oops_sth_went_wrong'),
                ));
            }
        }

        dispatch(stopLoading());

        return;
    };
}

export function getTransitionalBalances() {
    return async(dispatch: Dispatch, getState: () => RootState) => {
        const entityId = getState().global.currentLegalEntityId;
        const employeeId = getState().employee.id;
        if (!entityId || !employeeId) {
            return;
        }

        dispatch(startLoading());
        try {
            const transitionalBalances = await ApiLeave.getEmployeeTransitionalBalances(
                entityId,
                employeeId,
                selectors.getPeriodFrom(getState(), null).format('YYYY-MM-DD'),
                selectors.getPeriodTill(getState(), null).format('YYYY-MM-DD'),
            );

            dispatch(setTransitionalLeaveBalances(transitionalBalances));
        } catch (e) {
            const errors = e.response.data.errors || [];
            if (errors[0] && errors[0].message) {
                dispatch(openErrorModal(
                    [errors[0].message],
                    intl.get('oops_sth_went_wrong'),
                ));
            }
        } finally {
            dispatch(stopLoading());
        }

        return;
    };
}

export function create(employeeId: number, transaction: LeaveInterfaces.Transaction) {
    return async(dispatch: Dispatch, getState: () => RootState) => {
        const state = getState();
        const entityId = state.global.currentLegalEntityId;
        if (!entityId) {
            return;
        }

        dispatch(startLoading());

        try {
            const responseTransaction = await ApiLeave.create(entityId, employeeId, transaction);

            dispatch(
                updateLeaves(responseTransaction[0]) as any,
            );

            toast.success(intl.get('leave_transaction_created_ok'));
        } catch (e) {
            toast.error(e.message || intl.get('leave_transaction_created_nok'));

            return false;
        } finally {
            dispatch(stopLoading());
        }

        return;
    };
}

export function createAndApprove(employeeId: number, transaction: LeaveInterfaces.Transaction) {
    return async(dispatch: Dispatch, getState: () => RootState) => {
        const state = getState();
        const entityId = state.global.currentLegalEntityId;
        if (!entityId) {
            return;
        }

        dispatch(startLoading());

        const responseTransaction = await ApiLeave.create(entityId, employeeId, transaction);

        if (!responseTransaction) {
            return;
        }

        await ApiLeave.approve(entityId, responseTransaction[0].id);

        dispatch(updateLeaves({
            ...responseTransaction[0],
            status: LeaveInterfaces.TransactionStatus.pending,
        }) as any);

        toast.success(intl.get('leave_transaction_created_approved_ok'));

        dispatch(stopLoading());

        return;
    };
}

export function store(employeeId: number, transaction: LeaveInterfaces.Transaction) {
    return async(dispatch: Dispatch, getState: () => RootState) => {
        const state = getState();
        const entityId = state.global.currentLegalEntityId;
        if (!entityId) {
            return;
        }

        dispatch(startLoading());

        await ApiLeave.store(entityId, transaction);

        dispatch(updateLeaves(transaction) as any);

        dispatch(stopLoading());

        toast.success(intl.get('leave_transaction_updated_ok'));
        return;
    };
}

export function approve(employeeId: number, transaction: LeaveInterfaces.Transaction) {
    return async(dispatch: Dispatch, getState: () => RootState) => {
        const entityId = getState().global.currentLegalEntityId;
        if (!entityId) {
            return;
        }

        dispatch(startLoading());

        await ApiLeave.approve(entityId, transaction.id);
        dispatch(updateLeaves({
            ...transaction,
            status: LeaveInterfaces.TransactionStatus.pending,
        }) as any);

        toast.success(intl.get('leave_transaction_approved_ok'));
        dispatch(stopLoading());

        return;
    };
}

export function reject(employeeId: number, transaction: LeaveInterfaces.Transaction) {
    return async(dispatch: Dispatch, getState: () => RootState) => {
        const entityId = getState().global.currentLegalEntityId;
        if (!entityId) {
            return;
        }

        dispatch(startLoading());

        await ApiLeave.reject(entityId, transaction.id);
        dispatch(updateLeaves(
            {...transaction, status: LeaveInterfaces.TransactionStatus.rejected},
        ) as any);

        toast.success(intl.get('leave_transaction_rejected_ok'));
        dispatch(stopLoading());

        return;
    };
}

function updateLeaves(transaction: LeaveInterfaces.Transaction) {
    return (dispatch: Dispatch, getState: () => RootState) => {
        const state = getState();
        const leaves: LeaveInterfaces.Transaction[] = [...state.employee.leave.transactions];

        const oldTransactionIndex = leaves
            .findIndex((t: LeaveInterfaces.Transaction) => t.id === transaction.id);

        if (oldTransactionIndex !== -1) {
            leaves.splice(oldTransactionIndex, 1);
        }

        leaves.push(transaction);

        dispatch(setLeaves(leaves));

        return;
    };
}
