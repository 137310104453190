import {ApiObject} from '@api';
import {constants, stylingVariables} from '@global';
import React, {PureComponent} from 'react';
import {connect} from 'react-redux';

import {Actions as CommonActions, Icon} from '@common';
import {downloadDocument} from '../../../../../redux';

interface IProps {
    document: ApiObject.FileHandle;
    downloadDocument: (id: string) => any;
}

class Delete extends PureComponent<IProps> {
    private onDownload = () => {
        this.props.downloadDocument(this.props.document.id);
    }

    public render() {
        return (
            <CommonActions.Action style={{marginRight: 15}} onClick={this.onDownload}>
                <Icon type={constants.IconTypes.FILE_DOWNLOAD}
                      width={15} height={15}
                      fillColor={stylingVariables.colorPalette.green}
                />
            </CommonActions.Action>
        );
    }
}

const mapDispatchToProps = {
    downloadDocument,
};

export default connect(null, mapDispatchToProps)(Delete);
