import {ApiObject} from '@api';
import {stylingVariables} from '@global';
import {ColorHelper} from '@library';
import React, {PureComponent} from 'react';
import styled from 'styled-components';

const DEFAULT_TD_WIDTH = 125;
const DEFAULT_TR_HEIGHT = 40;

const WrapperTable = styled.table`
  box-sizing: content-box;
  border-spacing: 0;
  border-collapse: inherit;
  
  color: ${stylingVariables.colorPalette.darkest};
  font-size: ${stylingVariables.fontSize.default};
  
  td {
    border: 2px solid ${stylingVariables.colorPalette.gray};
  }
  
  td:not(:last-child) {
    margin-right: 4px;
  }
`;

const DefaultTr = styled.tr`
  height: ${DEFAULT_TR_HEIGHT}px;
  display: table-row;
  white-space: nowrap;
`;
const DefaultTd = styled.td`
  display: table-cell;
  height: ${DEFAULT_TR_HEIGHT}px;
  vertical-align: middle;
  padding: 0 10px;
  border-radius: 6px;
  color: ${stylingVariables.colorPalette.darkest};
  font-weight: ${stylingVariables.fontWeight.semibold};
  overflow: hidden;
`;
const FieldTd = styled((props) => <DefaultTd {...props}/>)`
  min-width: ${DEFAULT_TD_WIDTH}px;
  width: ${DEFAULT_TD_WIDTH}px;
  background-color: ${ColorHelper.hexToRgbA(stylingVariables.colorPalette.grayish, 0.6)};
`;
const DataSampleTd = styled((props) => <DefaultTd {...props}/>)`
  min-width: ${DEFAULT_TD_WIDTH}px;
  width: ${DEFAULT_TD_WIDTH}px;
  background-color: ${stylingVariables.colorPalette.white};
  padding: 0;
`;
const Cell = styled.div<{flex: number}>`
  width: ${DEFAULT_TD_WIDTH + 20 + 4}px; // paddings and borders
  padding-left: 10px;
`;
const DataSampleCell = styled.div`
  width: 100%;
  display: inline-flex;
`;

interface IProps {
    columns: ApiObject.ReportColumn[];
    months: string[];
}

export default class Table extends PureComponent<IProps> {
    render() {
        return <WrapperTable>
            <tbody>
                <DefaultTr>
                    {this.props.columns.map((column, index) => {
                        return <FieldTd key={index} colSpan={this.props.months.length}>
                            {column.label}
                        </FieldTd>;
                    })}
                </DefaultTr>
                <DefaultTr>
                    {this.props.columns.map((column, iIndex) =>
                        <DataSampleTd key={iIndex} colSpan={this.props.months.length}>
                            <DataSampleCell>
                                {this.props.months.map((_, jIndex) =>
                                    <Cell key={jIndex} flex={100/this.props.months.length}>
                                        0,000.0 AED
                                    </Cell>
                                )}
                            </DataSampleCell>
                        </DataSampleTd>
                    )}
                </DefaultTr>
            </tbody>
        </WrapperTable>;
    }
}
