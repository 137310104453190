import React, {PureComponent} from 'react';
import { AutoSizer } from 'react-virtualized';
import styled from 'styled-components';

import {InfiniteTableColumn, StyledInfiniteTable as CommonStyledInfiniteTable} from '@common';
import {EmployeeInterfaces, LeaveInterfaces} from '@api';
import {
    COLUMN_WIDTH_ACTIONS,
    COLUMN_WIDTH_DEFAULT,
    COLUMN_WIDTH_LABEL
} from '../../../../Employee/Tabs/Salary/Table/constants';
import {interfaces, intl, stylingVariables} from '@global';
import * as Columns from './Column';
import {RootState} from '../../../../../rootReducer';
import * as selectors from '../../selectors';
import {
    changeFilter,
    changeOrder,
} from '../../redux';
import {connect} from 'react-redux';
import {openLeaveTransactionModal} from '../../../../Employee/redux';

const StyledInfiniteTable = styled(CommonStyledInfiniteTable)`
    .ReactVirtualized__Table__row{
        .ReactVirtualized__Table__rowColumn {
            background-color: ${stylingVariables.colorPalette.lightGray};
        }
    }
    .ReactVirtualized__Table__headerColumn {
        white-space: normal;
        &.ReactVirtualized__Table__headerColumn-borderless {
            border-right: none;
        }
    }
`;

const NoRowsTable = styled.div`
      padding-bottom: 50px;
      overflow: overlay;
`;

const NoRowsWrapper = styled.div`
      display: flex;
      align-items: center;
      justify-content: flex-start;
      height: 50px;
      background-color: ${stylingVariables.colorPalette.lightGray};
      border-radius: ${stylingVariables.layout.borderRadius};
      font-size: ${stylingVariables.fontSize.mediumLarge};
      font-weight: ${stylingVariables.fontWeight.regular};
      margin: 15px 0;
      span {
         font-style: italic;
         font-size: ${stylingVariables.fontSize.default};
         margin-left: 20px;
      }
`;

const RowWrapper = styled.div`
    cursor: pointer;
`;

interface TableProps {
    items: LeaveInterfaces.GroupedTransactions[];
    showPayrollPeriod?: boolean;
    employee: EmployeeInterfaces.Employee;

    orders: interfaces.ListingOrders;
    openLeaveTransactionModal: (transaction: LeaveInterfaces.Transaction[]) => void;
    changeWrapperHeight?: (tableHeight: number) => void;
}

interface IState {
    items: LeaveInterfaces.GroupedTransactions[];
    order: number;
    openRows: number[];
}

export class Table extends PureComponent<TableProps, IState> {
    private table: any;

    public constructor(props: TableProps) {
        super(props);

        this.state = {
            items: this.props.items,
            order: 1,
            openRows: [],
        };
    }

    private get realTableWidth() {
        return 5 * COLUMN_WIDTH_DEFAULT + COLUMN_WIDTH_LABEL + COLUMN_WIDTH_ACTIONS;
    }

    componentDidUpdate(prevProps: Readonly<TableProps>, prevState: Readonly<IState>) {
        if (this.props.items.length !== prevProps.items.length ||
            (this.props.items.length &&  JSON.stringify(prevProps.items) !== JSON.stringify(this.props.items))
        ) {
            this.setState({ items: this.props.items}, () => this.afterRowHeightChange());
        }
    }

    public render() {
        return (
            <AutoSizer>
                {({width, height}) => (
                    <StyledInfiniteTable
                        ref={this.setRef}
                        rowHeight={this.getRowHeight}
                        rowClassName={'ReactVirtualized__Table__row_salary'}
                        overscanRowCount={5}
                        headerHeight={40}
                        rowGetter={({ index }: {index: number}) => this.state.items[index]}
                        rowRenderer={({ columns, key, style, className, rowData }: any ) => (
                            <RowWrapper
                                onClick={() => this.onOpen(rowData.transactions)}
                                key={key}
                                style={style}
                                className={className}
                            >
                                {columns}
                            </RowWrapper>
                        )}
                        rowCount={this.state.items.length}
                        height={this.state.items.length * 50 + 105}
                        width={width > this.realTableWidth ? width : this.realTableWidth}
                        maxWidth={width}
                        noRowsRenderer={this._renderNoRows}
                    >
                        {Columns.LeaveType({
                            orders: this.props.orders,
                            changeOrder: this.onChangeOrder,
                            onOpen: this.onOpen,
                        })}
                        {Columns.Dates({
                            openLeaveTransactionModal: this.props.openLeaveTransactionModal,
                            toggleRowHeight: this.toggleRowHeight,
                            openRows: this.state.openRows,
                        })}
                        {Columns.TransactionType({})}
                        {this.props.showPayrollPeriod
                            ? InfiniteTableColumn.Default({
                                label: intl.get('payroll_period'),
                                dataKey: 'transactions[0].period.periodName',
                                flexGrow: 1,
                                width: 200,
                            })
                            : null
                        }
                        {Columns.Actions(this.props.employee)}
                    </StyledInfiniteTable>
                )}
            </AutoSizer>
        );
    }

    private sortColumn(column: string, order: number) {
        const key = 'transactionInfo';
        const sortOrder = order;
        return (a: any, b: any) => {
            const result = (a[key][column] < b[key][column]) ? -1 : (a[key][column] > b[key][column]) ? 1 : 0;
            this.setState({
                openRows: [],
            }, () => this.afterRowHeightChange());
            return result * sortOrder;
        };
    }

    private onChangeOrder = (column: string) => {
        const { items, order } = this.state;
        let newItems = [...items];
        const newOrder = order * -1;
        newItems = newItems.sort(this.sortColumn(column, newOrder));
        this.setState({ items: newItems, order: newOrder });
    }

    private setRef = (ref: any) => {
        this.table = ref;
    }

    private toggleRowHeight = (index: number) => {
        this.setState(
            ({ openRows }) => ({
                openRows: openRows.includes(index)
                    ? openRows.filter(tallIndex => tallIndex !== index)
                    : [...openRows, index],
            }),
            () => this.afterRowHeightChange(index),
        );
    }

    private afterRowHeightChange = (index?: number) => {
        if (this.props.changeWrapperHeight) {
            let tableHeight = 0;
            this.state.items.forEach((item, idx) => {
                if (typeof this.state.openRows.find((x: number) => x === idx) === 'number') {
                    tableHeight += (item.transactions.length * 50) + 70;
                } else {
                    tableHeight += 50;
                }
            });
            this.props.changeWrapperHeight(tableHeight || 50);
        }
        this.table.recomputeRowHeights(index);
    }

    private getRowHeight = ({ index }: {index: number}) => {
        const groupedTransaction = this.state.items[index].transactions.length > 1;
        const openLength = (this.state.items[index].transactions.length * 50) + 70;

        return this.state.openRows.includes(index) && groupedTransaction ? openLength : 50;
    }

    private _renderNoRows = () => {
        return (
            <NoRowsTable>
                <NoRowsWrapper><span>{intl.get('no_leaves_found')}</span></NoRowsWrapper>
            </NoRowsTable>
        );
    }

    private onOpen = (transactions: LeaveInterfaces.Transaction[]) => {
        this.props.openLeaveTransactionModal(transactions);
    }
}
const mapStateToProps = (state: RootState, ownProps: any) => ({
    orders: selectors.getOrders(state, ownProps),
    filters: selectors.getFilters(state, ownProps),
    tags: selectors.getTags(state, ownProps),
});

const mapDispatchToProps = {
    changeOrder,
    changeFilter,
    openLeaveTransactionModal,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Table);
