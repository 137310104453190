import {ApiObject} from '@api';
import React, {PureComponent} from 'react';
import {intl} from '@global';
import styled from 'styled-components';

import {constants, interfaces, stylingVariables} from '@global';
import {Icon, BlankButton, Input, InlineDateRangePicker, SimpleDropdown} from '@common';
import {handleEnterPressEvent, handleEscDownEvent} from '../functions';

const StyledFilterWrapper = styled.div`
   z-index: 1;
`;

const StyledAbsoluteWrapper = styled.div`
   position: absolute;
   z-index: 1;
`;

export const StyledFilterContent = styled.div<{toRight?: boolean}>`
  position: absolute;
  top: 20px;
  ${props => props.toRight ? 'left: -24px' : 'right: -38px'};
  width: 300px;
  padding: 20px;
  background-color: ${stylingVariables.colorPalette.white};
  box-shadow: ${stylingVariables.layout.boxShadow};
  border-radius: ${stylingVariables.layout.borderRadius};
  &:before {
    content: ' ';
    position: absolute;
    top: -15px;
    ${props => props.toRight ? 'left: 15px' : 'right: 15px'};
    right: 15px;
    display: block;
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 15px solid ${stylingVariables.colorPalette.white};
  }
`;

export const StyledFilterHeader = styled.div`
  margin-bottom: 10px;
  padding-bottom: 10px;
  font-size: ${stylingVariables.fontSize.mediumLarge};
  font-weight: ${stylingVariables.fontWeight.semibold};
  color: ${stylingVariables.colorPalette.dark};
  border-bottom: 1px solid ${stylingVariables.colorPalette.gray};
`;

export const StyledAction = styled(props => <BlankButton {...props} />)<{isDisabled: boolean}>`
  font-size: ${stylingVariables.fontSize.mediumLarge};
  font-weight: ${stylingVariables.fontWeight.semibold};
  color: ${stylingVariables.colorPalette.deepGray};

  ${props => props.isDisabled && `
    opacity: .7;
    cursor: not-allowed!important;
  `}
`;

export const StyledApplyAction = styled(StyledAction)`
  color: ${stylingVariables.colorPalette.green};
`;

interface FilterProps {
    children?: any;
    label: string;
    column: string;
    type: string;
    options?: ApiObject.SelectOption[];
    filters: interfaces.ListingFilters;
    terminationStatus?: ApiObject.TerminationStatus | null;
    changeFilter: (params: interfaces.FilterParams) => any;
    outsideFilters?: any[];
}

interface FilterState {
    isOpen: boolean;
    value: string|any[];
}

export default class Filter extends PureComponent<FilterProps, FilterState> {
    private wrapperRef: any;

    static defaultProps: FilterProps = {
        label: '',
        column: '',
        type: '',
        filters: {},
        options: [],
        outsideFilters: [],
        changeFilter: (params: interfaces.FilterParams): any => null,
    };

    public constructor(props: any) {
        super(props);

        this.state = {
            isOpen: false,
            value: '',
        };
    }

    public componentDidMount() {
        document.addEventListener('mousedown', this.closeWhenClickOutside);
    }

    public componentWillUnmount() {
        document.removeEventListener('mousedown', this.closeWhenClickOutside);
    }

    private getFilterContent = () => {
        if (this.props.children) {
            // add onClose prop
            return React.Children.map(this.props.children, (child: any): any => {
                return React.cloneElement(child, { onClose: this.close });
            });
        }

        return this.getDefaultFilterContent();
    }

    private onChangeFilterValue = (value: string|any[]) => {
        this.setState({value});
    }

    private onChangeFilterDateValue = (from?: string|null, till?: string|null) => {
        this.onChangeFilterValue([from, till]);
    }

    private onFilter = () => {
        const filterParams: interfaces.FilterParams = {
            column: this.props.column,
            value: this.state.value,
            type: this.props.type,
        };
        this.props.changeFilter(filterParams);
        this.close();
    }

    private onReset = () => {
        this.onChangeFilterValue('');
        const filterParams: interfaces.FilterParams = {
            column: this.props.column,
            value: '',
            searchQuery: '',
        };
        this.props.changeFilter(filterParams);
        this.close();
    }

    private renderInputByType = () => {
        switch (this.props.type) {
            case ApiObject.FieldType.date:
            case ApiObject.FieldType.datetime: {
                let from = null;
                let till = null;
                if (this.activeFilter) {
                    [from, till] = (this.activeFilter as interfaces.FilterParams).value;
                }
                return <InlineDateRangePicker onChange={this.onChangeFilterDateValue} from={from} till={till} />;
            }
            case ApiObject.FieldType.dropdown: {
                let selectedValue = '';
                if (this.activeFilter) {
                    selectedValue = (this.activeFilter as interfaces.FilterParams).value as string;
                }
                return (
                    <SimpleDropdown list={this.props.options}
                                    onChange={this.onChangeFilterValue}
                                    selectedValue={selectedValue}
                    />
                );
            }
            default: {
                return <Input label={''}
                    defaultValue={this.state.value as string}
                    onChange={(event: any) => this.onChangeFilterValue(event.target.value)}
                    placeholder={intl.get('start_typing_to_filter')}
                    onKeyPress={handleEnterPressEvent(this.onFilter)}
                    onKeyDown={handleEscDownEvent(this.onFilter)}
                    autoFocus={true}
                />;
            }
        }
    }

    private getDefaultFilterContent = () => {
        return (
            <StyledFilterContent>
                <StyledFilterHeader>
                    {intl.get('filter_by')} {intl.get(this.props.column) ? intl.get(this.props.column) : this.props.label }
                    </StyledFilterHeader>

                {this.renderInputByType()}

                <div style={{display: 'flex', justifyContent: 'space-between', marginTop: 20}}>
                    <StyledAction onClick={this.onReset} isDisabled={this.isActionDisabled}>
                        {intl.get('reset')}
                    </StyledAction>
                    <StyledApplyAction onClick={this.onFilter} isDisabled={this.isActionDisabled}>
                        {intl.get('apply')}
                    </StyledApplyAction>
                </div>
            </StyledFilterContent>
        );
    }

    private get activeFilter(): string|interfaces.FilterParams|null|boolean|undefined {
        return this.props.column && this.props.filters && this.props.filters[this.props.column];
    }

    private get hasActiveFilter(): boolean {
        return !!(this.activeFilter && (
            this.props.filters[this.props.column].value.length > 0
            || this.props.filters[this.props.column].searchQuery
        ))
    }

    private get isTerminationStatusSet(): boolean {
        return !!(this.props.column && this.props.terminationStatus);
    }

    private get isActive(): boolean {
        return this.hasActiveFilter || this.isTerminationStatusSet || (this.props.outsideFilters?.length ?? 0) > 0;
    }

    private get isActionDisabled(): boolean {
        if (
            (ApiObject.FieldType.date === this.props.type || this.props.type === ApiObject.FieldType.datetime)
            && typeof this.state.value === 'object'
        ) {
            return this.state.value.some(function(el: any ) {
                return el === null;
            });
        }
        return !this.state.value.length;
    }

    render() {
        return (
            <StyledFilterWrapper ref={(node: any) => this.wrapperRef = node}>
                <BlankButton onClick={this.toggle}>
                    <Icon type={this.isActive ? constants.IconTypes.SOLID_FILTER : constants.IconTypes.FILTER}
                          width={15}
                          height={15}
                          fillColor={this.state.isOpen || this.isActive || this.state.value
                              ? stylingVariables.colorPalette.green
                              : stylingVariables.colorPalette.deepGray
                          }
                    />
                </BlankButton>

                {
                    this.state.isOpen &&
                    <StyledAbsoluteWrapper>
                        {this.getFilterContent()}
                    </StyledAbsoluteWrapper>
                }
            </StyledFilterWrapper>
        );
    }

    public toggle = () => {
        this.setState({isOpen: !this.state.isOpen});
    }

    public close = () => {
        this.setState({isOpen: false});
    }

    public closeWhenClickOutside = (event: Event) => {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.close();
        }
    }
}
