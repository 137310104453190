import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { EmployeeInterfaces, ApiObject } from '@api';
import { RootState } from '../../../../../rootReducer';
import * as selectors from '../selectors';
import * as employeeSelectors from '../../../selectors';
import { saveDependentFields } from '../redux';
import Modal from './Modal';



interface IProps {
    entityId: string;
    employee: EmployeeInterfaces.Employee;
    group: ApiObject.FieldGroup;
    values: ApiObject.EntityValues | null;
    errors: ApiObject.EntityFieldErrorMessage[];
    isEditModalOpen: boolean;

    saveDependentFields: (employeeId: number, fields: any) => void;
    close: () => void;
    open: () => void;
}

export class Edit extends PureComponent<IProps> {
    public constructor(props: IProps) {
        super(props);
    }

    render() {
        if (!this.props.values) {
            return null;
        }

        return (
            this.props.isEditModalOpen &&
            <Modal
                employee={this.props.employee}
                entityId={this.props.entityId}
                group={this.props.group}
                values={this.props.values}
                errors={this.props.errors}
                onClickSave={this.save}
                onClickClose={this.props.close}
            />
        );
    }

    public save = async (fields: ApiObject.FieldValue[]) => {
        await this.props.saveDependentFields(this.props.employee.id, [{
            deleteEntity: false,
            entity: this.props.group.code,
            entityId: this.props.entityId,
            values: fields,
        }]);

        if (0 === this.props.errors.length) {
            this.props.close();
        }
    }
}

const mapStateToProps = (state: RootState, ownProps: any) => ({
    values: selectors.getDependentValuesByEntityId(state, ownProps),
    errors: employeeSelectors.getErrorsFields(state),
});

const mapDispatchToProps = {
    saveDependentFields,
};

export default connect(mapStateToProps, mapDispatchToProps)(Edit);
