import {constants, stylingVariables} from '@global';
import {Icon} from 'hrb-common';
import React, {PureComponent} from 'react';
import {Column} from 'react-virtualized';

import {EmployeeInterfaces} from '@api';
import {
    defaultInfiniteTableCellDataGetter,
} from '@common';
import styled from 'styled-components';

const StyledAddEmployee = styled.div`
  cursor: pointer;
  background-color: ${stylingVariables.hrb.colorPalette.green};
  border-radius: 50%;
  height: 14px;
  width: 14px;
  
  display: flex;
  align-items: center;
  justify-content: center;
  
  transition: .2s all;
  &:hover {
    transition: .2s all;
    background-color: ${stylingVariables.hrb.colorPalette.green};
  }
  user-select: none;
`;

const StyledRemoveEmployee = styled.div`
  cursor: pointer;
  background-color: ${stylingVariables.hrb.colorPalette.deepGray};
  border-radius: 50%;
  height: 14px;
  width: 14px;
  
  display: flex;
  align-items: center;
  justify-content: center;
  
  transition: .2s all;
  &:hover {
    transition: .2s all;
    background-color: ${stylingVariables.hrb.colorPalette.orange};
  }
  transform: rotate(45deg);
  user-select: none;
`;

interface IActionsProps {
    onAddEmployee?: (employee: EmployeeInterfaces.Employee) => void;
    onRemoveEmployee?: (employee: EmployeeInterfaces.Employee) => void;
    width?: number;
}

interface IProps {
    cellData: EmployeeInterfaces.Employee;
    onAddEmployee?: (employee: EmployeeInterfaces.Employee) => void;
    onRemoveEmployee?: (employee: EmployeeInterfaces.Employee) => void;
}

interface IState {
    employeeIds: Set<number>;
}

class Cell extends PureComponent<IProps, IState> {
    public render() {
        const {onAddEmployee, onRemoveEmployee, cellData} = this.props;

        return <>
            {onAddEmployee &&
                <StyledAddEmployee onClick={() => onAddEmployee(cellData)}>
                    <Icon
                        type={constants.hrb.IconTypes.BOLD_ADD}
                        width={8}
                        height={8}
                        fillColor={stylingVariables.hrb.colorPalette.white}
                    />
                </StyledAddEmployee>
            }

            {onRemoveEmployee &&
                <StyledRemoveEmployee onClick={() => onRemoveEmployee(cellData)}>
                    <Icon
                        type={constants.hrb.IconTypes.BOLD_ADD}
                        width={8}
                        height={8}
                        fillColor={stylingVariables.hrb.colorPalette.white}
                    />
                </StyledRemoveEmployee>
            }
        </>;
    }
}

const Actions = ({onAddEmployee, onRemoveEmployee, width = 60}: IActionsProps) => (
    <Column
        dataKey='actions'
        width={width}
        cellDataGetter={defaultInfiniteTableCellDataGetter}
        headerRenderer={() => <></>}
        cellRenderer={({cellData}) =>
            <Cell cellData={cellData} onAddEmployee={onAddEmployee} onRemoveEmployee={onRemoveEmployee} />}
    />
);

export default Actions;
