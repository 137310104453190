import React from 'react';
import styled from 'styled-components';
import {Icon} from '@common';
import {constants, stylingVariables} from '@global';

const Wrapper = styled.div`
  transition: all 0.5s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 24px;
  width: 24px;
  background: ${stylingVariables.colorPalette.white};
  border-radius: 50%;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.161);

  :hover {
    background: ${stylingVariables.colorPalette.lightGreen};
  }
`;

interface IProps {
    isCollapsed: boolean;
    onToggle: (value?: boolean) => void;
}

const CollapseCircle: React.FC<IProps> = ({isCollapsed, onToggle}: IProps) => {
    return <Wrapper onClick={() => onToggle()}>
        <Icon
            type={
                isCollapsed ? constants.IconTypes.ARROW_RIGHT : constants.IconTypes.ARROW_LEFT
            }
            fillColor={stylingVariables.colorPalette.dark}
            height={7}
            width={9}
        />
    </Wrapper>
}

export default CollapseCircle;
