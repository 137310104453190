import React, {PureComponent} from 'react';
import styled from 'styled-components';

import {stylingVariables} from '@global';

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 65px;
  background-color: ${stylingVariables.colorPalette.white};
  border-radius: ${stylingVariables.layout.borderRadius};
  font-size: ${stylingVariables.fontSize.mediumLarge};
  font-weight: ${stylingVariables.fontWeight.regular};
  margin-top: 15px;
`;

interface IProps {
    text: string;
}

export default class NoRows extends PureComponent<IProps> {
    render() {
        return (
            <StyledWrapper>
                {this.props.text}
            </StyledWrapper>
        );
    }
}
