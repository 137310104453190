import {constants} from '@global';

export default class FileHelper {
    public static getIconType(fileName: string = '') {
        const fileExtension = fileName.split('.').pop();
        switch (fileExtension) {
            case 'pdf':
                return constants.IconTypes.FILE_PDF;
            case 'png':
                return constants.IconTypes.FILE_PNG;
            case 'doc':
                return constants.IconTypes.FILE_DOC;
            default:
                return constants.IconTypes.FILE;
        }
    }
}
