import React from 'react';
import {Icon} from '@common';
import {constants, stylingVariables} from '@global';
import {intl} from '@global';
import styled from 'styled-components';

const StyledAddPaymentGroupButton = styled.div`
    display: flex;
    align-content: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: ${stylingVariables.colorPalette.orange};
    box-shadow: ${stylingVariables.layout.boxShadow};
    cursor: pointer;
    border: none;
    outline: none;
    padding: 7px;
    margin: auto 10px auto 20px;
`;

const StyledAddButtonWrapper = styled.div`
    padding: 10px 30px;
    display: flex;
    align-content: center;
    justify-content: flex-start;
    cursor: pointer;
    font-size: ${stylingVariables.fontSize.medium};
    font-weight: ${stylingVariables.fontWeight.semibold};
`;

interface IProps {
    action: () => void;
}

const Link: React.FC<IProps> = ({action}: IProps) => {
    return <StyledAddButtonWrapper onClick={action}>
        <StyledAddPaymentGroupButton>
            <Icon type={constants.IconTypes.BOLD_ADD}
                  width={15}
                  height={15}
                  fillColor={stylingVariables.colorPalette.white}/>
        </StyledAddPaymentGroupButton>
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: stylingVariables.colorPalette.orange,
        }}>
            {intl.get('add_pay_element_type')}
        </div>
    </StyledAddButtonWrapper>;
};

export default Link;
