export const DEFAULT_INDEX_WIDTH = 60;
export const DEFAULT_NAME_WIDTH = 180;
export const DEFAULT_PAY_GROUP_WIDTH = 100;
export const DEFAULT_STATUS_WIDTH = 80;
export const DEFAULT_ACTIONS_WIDTH = 60;

export const TABLE_HEADER_HEIGHT = 40;
export const TABLE_ROW_HEIGHT = 70;

export const REAL_TABLE_WIDTH =
	DEFAULT_INDEX_WIDTH +
	DEFAULT_NAME_WIDTH +
	DEFAULT_PAY_GROUP_WIDTH +
	DEFAULT_ACTIONS_WIDTH
;
