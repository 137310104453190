const estonian = {
  mercans: 'Mercans',
  term_of_use: 'Kasutustingimused',
  sla: 'Turvalisuspoliitika',
  privacy_policy: 'Privaatsuspoliitika',

  admin_console: 'Admini vaade',
  secure_file_transfer: 'Turvaline failide saatmine',
  payslips: 'Palgalehed',
  my_information: 'Minu informatsioon',
  my_team: 'Minu meeskond',
  my_apps: 'Minu äpid',
  apply_for_leave: 'Taotle puhkust',
  add_leave_transaction: 'Add Leave',
  add_and_approve: 'Lisa ja kinnita',
  cannot_approve_payroll_processing: 'Payroll on protsessimises, puhkusi ei saa kinnitada',
  enter_my_time_and_attendance_data: 'Sisesta aja ja kohaoleku andmed',
  help_and_support: 'Abi ja tugi',
  select_entity: 'Vali ettevõte',
  back_to_launchpad: 'Tagasi Launchpadi',
  workflow: 'Töövoog',

  employees: 'Töötajad',
  no_employees_found: 'Töötajaid ei leitud',
  no_leaves_found: 'Antud hetkel pole puhkusi',
  no_transactions: 'Antud hetkel pole tehinguid',
  name: 'Nimi',
  pay_unit: 'Palgaühik',
  amount: 'Summa',
  currency: 'Valuuta',
  no_details_found: 'Puuduvad detailid',
  ess_access: 'ESS ligipääs',
  add_or_remove_columns: 'Lisa või eemalda veerge',
  columns_selected: ' veergu on valitud',
  unselected_columns: 'Tühista veergude valik',
  filter_by: 'Filterda',
  apply: 'Rakenda',
  reset: 'Taasta',
  filter_by_employee_header: 'Filterda töötaja ID ja nime järgi',
  filter_by_employee: 'Töötaja järgi',
  filter_by_tags: 'Siltide järgi',
  employee: 'Töötaja',
  add_employee: 'Lisa töötaja',
  add_employee_s: 'Lisa töötaja(d)',
  save_and_next: 'Salvesta ja järgmine',
  save_and_add: 'Salvesta ja lisa',
  import_employee_data_from_excel: 'Impordi töötajate andmed Excelist',
  employee_separated: 'Töötaja on töölt eemaldatud',
  advanced_settings: 'Täpsemad seaded',
  separation_no_pay_elements: 'Töölt lahkumise elemente pole seadistautd',
  view_saved_separations: 'Vaata salvestatud töölt eemaldamine',
  insert_separation: 'Lisa töölt eemaldamine',
  send_ess_invitation: 'Saada ESSi kutse',
  send_ess_invitation_success: 'ESSi kutse on saadetud',
  send_ess_invitation_failed: 'ESSi kutse saatmine ebaõnnestus',
  revoke: 'Tühista',
  revoke_ess_access: 'Tühista ESSi ligipääs',
  revoke_ess_access_confirmation: 'Kas oled kindel, et soovid ESSi ligipääsu tühistada',
  revoke_ess_success: 'Ligipääs ESSi on tühistatud',
  insert_vacation: 'Sisest puhkus',
  insert_expense: 'Sisesta kulu',
  insert_one_time_payment: 'Sisesta ühekordne makse',
  insert_absence: 'Sisesta puudumine',
  reason: 'Põhjus',
  annual_total: 'Aastane kogusumma',
  before: 'Enne',
  after: 'Pärast',

  payrolls: 'Palgaarvestused',
  payroll: 'Palgaarvestus',
  new_payroll: 'Uus palgaarvestus',
  current: 'Praegune',
  previous: 'Eelmine',
  open: 'Avatud',
  input: 'Sisent',
  preinput: 'Parandan sisendit',
  processing: 'Protsessing',
  authorization_form: 'Autoriseerimise vorm',
  calculated: 'Arvutatud',
  calculating: 'Arvutan',
  cancelled: 'Tühistatud',
  cancelling: 'Tühistan',
  close: 'Sulge',
  closed: 'Suled',
  closing: 'Sulgemises',
  generating: 'Genereerimises',
  invalid: 'Kehtetu',
  add_terminations: 'Lisa lõpetamine',
  pending_termination: 'Lõpetamise ootel',
  starters: 'Alustajad',
  starter: 'Alustaja',
  leavers: 'Lahkujad',
  leaver: 'Lahkuja',
  add_new_hire: 'Lisa uus töötaja',
  hire_date: 'Töölevõtmise kuupäev',
  last_working_date: 'Viimane töötamise kuupäev',
  review_and_approve_changed_inputs: 'Vaata üle ja kiida heaks muudetud sisendid',
  approve_and_continue: 'Kinnita ja jätka',

  active: 'Aktiivne',
  active_employee_success: 'Töötaja on aktiveeriutd',
  active_employees_success: 'Töötajad on aktiveeritud',
  activate_employee_failed: 'Töötaja {name} aktiveerimine ebaõnnestus',
  activate_employees_failed: 'Töötajate aktiveerimine ebaõnnestus',
  inactive: 'Mitteaktiivne',
  draft: 'Mustand',

  leaves: 'Puhkused',
  leave_transactions: 'Puhkuste kanded',
  leave_history_not_loaded: 'Puhkuste ajalugu ei õnnestunud laadida',
  leave_details: 'Puhkuste detailid',
  leave_balance: 'Puhkuste saldod',
  leave_balances: 'Puhkuste saldo',
  balances_details: 'Saldo detailid',
  edit_log: 'Muudatuste logi',
  leave_balances_in_year: 'Puhkused {year}',
  leave_balance_changed: 'Puhkuste muudatused',
  new_period_balances_set: 'Uued saldod {period}',
  leave_balance_transaction: 'Tehti puhkuse kanne',
  open_ended_leave: 'Avatud lõpuga puhkus',
  open_ended: 'Avatud lõpuga',
  new_leave: 'Uus puhkus',
  new_leave_transaction: 'Uus puhkuse tehing',
  edit_leave: 'Muuda puhkust',
  upcoming: 'Eesootavad',
  view_details_history: 'Vaata detaile ja ajalugu',
  leave_type: 'Puhkuse tüüp',
  leave_days: 'Puhkuse periood',
  selected_days: 'Valitud päevad',
  requested_leave_time: 'Taotletud puhkusepäevad',
  balance: 'Saldo',
  transactions: 'Tehingud',
  entry_date_and_user: 'Sisestamise kuupäev ja kasutaja',
  before_transaction: 'Enne tehingut',
  after_transaction: 'Pärast tehingut',
  calendar_days_left: 'Kalendripäevi järel',
  calendar_days_taken: 'Kalendripäevi võetud',
  business_days_left: 'Tööpäevi järel',
  business_days_taken: 'Tööpäevi võetud',
  leave_days_taken_in_year: 'Tööpäevi võetud aastas {year}',
  view_annual_balance: 'Vaata aastast saldot',
  view_current_balance: 'Vaata praegust saldot',
  employees_on_leave: 'Töötajad puhkusel',
  approve_leave_transaction_confirmation: 'Kas oled kindel, et soovid puhkuse avalduse kinnitada?',
  reject_leave_transaction_confirmation: 'Kas oled kindel, et soovid puhkuse avalduse tagasi lükata?',
  approve_all_leave_transactions_confirmation: 'Kas oled kindel, et soovid kõik selle grupi puhkuseavaldused kinnitada?',
  reject_all_leave_transactions_confirmation: 'Kas oled kindel, et soovid kõik selle grupi puhkuseavaldused tagasi lükata?',
  used: 'kasutatud',
  taken: 'võetud',
  left: 'järgi',
  invalid_adjustments_present: 'Teistes kohtades on ebaadekvaatsed ({leaveTypes}) muudatused',
  edit_balance: 'Muuda saldot',
  edit_balances: 'Muuda saldosid',
  view_employee_card: 'Vaata töötaja kaarti',
  in_: ' ',
  as_of_: 'seisuga ',
  days_left: 'Päeva järel',
  current_balance: 'Praegune saldo',
  add_or_remove_days: 'Lisa või eemalda päevi',
  new_balance: 'Uus saldo',
  calendar_days: 'Kalendripäevad',
  workdays: 'Tööpäevad',
  reject_all: 'Lükka kõik tagasi',
  view_details: 'Vaata detaile',
  close_details: 'Sulge detailid',
  postpone: 'Lükka edasi',
  expand_row: 'Laienda rida',
  transaction: 'Tehing',
  this_month: 'See kuu',
  this_year: 'See aasta',
  payment_schedule: 'Maksete graafik',

  view: 'Vaata',
  approve: 'Kiida heaks',
  approve_all: 'Kiida kõik heaks',
  reject: 'Lükka tagasi',
  import: 'Impordi',
  export: 'Ekspordi',
  dates: 'Kuupäevad',
  status: 'Staatus',
  pending: 'Ootel',
  open_ended_pending: 'Ootel avatud lõpuga',
  activate: 'Aktiveeri',
  suspend: 'Eemalda töölt',
  suspend_employee_success: 'Töötaja on töölt eemaldatud!',
  suspend_employees_success: 'Töötajad on töölt eemaldatud!',
  activate_confirmation: 'Kas oled kindel, et soovid aktiveerida?',
  suspend_confirmation: 'Kas oled kindel, et soovid töölt eemaldada?',
  terminate: 'Lõpeta töösuhe',
  repayment: 'Tagasimakse',
  submitted: 'Esitatud',
  rejected: 'Tagasi lükatud',
  done: 'Tehtud',
  approved: 'Kinnitatud',
  waiting_for_approval: 'Ootab kinnitamist',
  send: 'Saada',

  select_all: 'Vali kõik',
  select: 'Vali',

  edit: 'Muuda',
  sign_out: 'Logi välja',
  settings: 'Seaded',
  back: 'Tagasi',
  save: 'Salvesta',
  cancel: 'Katkesta',
  contact: 'Kontakt',
  history: 'Ajalugu',
  taken_this_year: 'See aasta võetud',
  future: 'Tulevik',
  attachments: 'Manused',

  total: 'Kokku',
  total_annual: 'Kokku aastas',
  request: 'Avaldus',
  requests: 'Avaldused',
  view_rejected_transaction: 'Vaata tagasi lükatud avaldusi',
  close_view: 'Sulge vaade',
  new: 'Uus',
  on: '',
  add: 'Lisa',
  add_file: 'Lisa fail',
  new_file: 'Uus fail',
  days: 'päeva',
  Days: 'Päeva',
  for: ' ',
  not_found: 'Ei leitud',

  start: 'Algus',
  end: 'Lõpp',
  to: 'kuni',
  select_date: 'Vali kuupäev',
  load_more: 'Lae juurde',
  select_days: 'Vali päevad',
  ID: 'ID',
  clear_filters_and_sorts: 'Taasta filtrid ja sorteerimine',
  new_design: 'Uus disain',

  documents: 'Dokumendid',
  new_document: 'Uus dokument',
  remove_document: 'Eemalda dokument',
  compensations: 'Kompensatsioonid',
  display_name: 'Kuvanimi',
  description: 'Kirjeldus',
  visible_in_ess: 'ESSis nähtav',
  file: 'Fail',
  size: 'Suurus',
  upload_date: 'Üleslaadimise kuupäev',
  remove_document_confirmation: 'Kas oled kindel, et soovid dokumendi eemaldada?',
  no_document_found: 'Dokumenti ei leitud',
  drop_here: 'Pane siia',
  remove: 'Eemalda',
  delete: 'Kustuta',
  added_this_month: 'lisatud see kuu',
  no: 'Ei',
  yes: 'Jah',

  last_activity: 'Viimane toiming',

  termination_date: 'Lahkumise kuupäev',
  choose: 'Vali',
  employee_group: 'Töötaja grupp',
  dates_transaction_count: 'Kuupäevad / tehingute hulk',
  source: 'Allikas',
  leaveTypeName: 'Puhkuse liik',
  payroll_period: 'Palgaarvestuse periood',

  recurring_earnings: 'Korduvad sissetulekud',
  recurring_deductions: 'Korduvad mahaarvamised',
  recurring_units: 'Korduvad ühikud',
  additional_earnings: 'Lisanduvad sissetulekud',
  additional_deductions: 'Lisanduvad mahaarvamised',
  additional_units: 'Lisanduvad ühikud',
  warning: 'Hoiatus!',
  amount_and_date_empty: 'Korduvatel mahaarvamistel peaks olema lõpukuupäev või eesmärgi summa',
  recurring_deductions_save_confirmation:' Korduvatel mahaarvamistel peaks olema lõpukuupäev või eesmärgi summa. Kas oled kindel, et soovid seda salvestada?',
  pay_element_change_delete_confirmation: 'Oled kindel, et soovid selle palgaelemendi muudatuse kustutada?',

  view_history: 'Vaata ajalugu',
  close_history: 'Sulge ajalugu',
  view_future: 'Ootel muudatused',
  close_future: 'Sulge ootel muudatused',
  add_pay_element_type: 'Lisa palgaelementide grupp',
  pay_element_type: 'Palgaelemendi liik',

  all: 'Kõik',
  recurrings: 'Korduvad',
  one_times: 'Ühekordsed',
  units: 'Ühikud',

  expand_all: 'Ava kõik',
  collapse_all: 'Sulge kõik',
  percentage_symbol: '%',
  values: 'Väärtused',

  dependents: 'Ülalpeetavad',
  dependent: 'Ülalpeetav',
  add_dependent: 'Lisa ülalpeetav',
  edit_dependent: 'Muuda ülalpeetavad',
  delete_dependent: 'Kustuta ülalpeetav',
  delete_dependent_confirmation: 'Kas oled kindel, et soovid ülalpeetavat kustutada?',
  no_dependents_found: 'Ülalpeetavaid ei leitud',

  employee_fields_saved: 'Töötaja väljad on edukalt salvestatud',
  employee_fields_not_saved: 'Töötaja väljade salvestamine ebaõnnestus',

  dependent_deleted: 'Ülalpeetav on kustutatud.',
  dependent_not_deleted: 'Ülalpeetava kustutamine ebaõnnestus',

  cookie_disabled_message: 'Küpsised',
  no_employees: 'Töötajaid pole',

  reports: 'Raportid',
  report_templates: 'Raportite mallid',
  personal: 'Personaalne',
  common: 'Ühine',
  search: 'Otsi',
  report_template: 'Raporti mall',
  report_status: 'Raporti staatus',
  report_generation_date: 'Raporti loomise kuupäev',
  report_name: 'Raporti nimi',
  user: 'Kasutaja',
  no_reports_found: 'Raporteid pole',
  template_name: 'Malli nimi',
  view_template: 'Vaata malli',
  edit_template: 'Muuda malli',
  generate_report: 'Genereeri raport',
  no_report_templates_found: 'Raporti malle pole',
  create_new_report: 'Loo uus raport',
  report_from_existing_template: 'Loo raport olemasolevast mallist',
  continue: 'Jätka',
  date_range: 'Ajavahemik',
  select_active: 'Vali aktiivsed',
  select_inactive: 'Vali mitteaktiivsed',
  select_draft: 'Vali mustandid',
  manual_employee_select: ' Või vali töötajad käsitsi ja jätka',
  delete_report: 'Kustuta raport',
  delete_report_confirmation: 'Kas oled kindel, et soovid selle raporti kustutada?',
  delete_template: 'Kustuta mall',
  delete_template_confirmation: 'Kas oled kindel et soovid selle raporti malli kustutada?',

  january: 'Jaanuar',
  february: 'Veebruar',
  march: 'Märts',
  april: 'Aprill',
  may: 'Mai',
  june: 'Juuni',
  july: 'Juuli',
  august: 'August',
  september: 'September',
  october: 'Oktoober',
  november: 'November',
  december: 'Detsember',

  selected_employees: 'Valitud töötajad',
  available_employees: 'Saadaolevad töötajad',

  add_field: 'Lisa väli',
  add_group: 'Lisa grupp',
  quick_adding: 'Kiirlisamine',
  group_name: 'Grupi nimi',

  save_and_generate_report: 'Salvesta ja genereeri raport',
  save_as_a_template: 'Salvesta mallina',
  update_template: 'Uuenda malli',
  select_data_fields_to_report: 'Vali andmeväljad raporti jaoks',
  not_all_employees_with_this_status_have_been_selected: 'Kõik antud staatusega töötajad pole valitud',
  preview_report: 'Eelvaata raportit',
  save_and_generate: 'Salvesta ja genereeri',

  report_date_range: 'Raporti ajavahemik',
  report_date: 'Aruande kuupäev',
  you: 'Sina',

  new_report: 'Uus raport',
  edit_report: 'Muuda raportit',
  update_report: 'Uuenda raportit',
  view_report: 'Vaata raportit',
  new_report_template: 'Uus raporti mall',
  new_template: 'Uus mall',
  edit_report_template: 'Muuda raporti malli',
  update_report_template: 'Uuenda raporti malli',
  view_report_template: 'Vaata raporti malli',
  save_as: 'Salvesta kui..',
  report_not_generated: "Vabandust, raportit ei genereeritud",
  please_try_again: 'Palun proovi uuesti',
  mandatory_field: 'Tegemist on kohustusliku väljaga',
  data_source: 'Andmete allikas',
  data_source_explanation: 'Mingi hästi pikk seletus',

  master_data: 'Masterdata',
  employee_id: 'Töötajad ID',
  payroll_calculation: 'Palgaarvestuse kalkuleerimine',
  employee_record: 'Töötaja kirje',
  pay_element: 'Palgaelement',

  created_by_user: 'Töötaja',
  visibility: 'Nähtavus',
  back_to_edit: 'Tagasi muutma',

  add_account: 'Lisa konto',

  employee_and_id: 'Töötaja & ID',
  bulk_actions: 'Masstoimingud ',
  out_of: ' / ',
  email_address_for_employee: 'Emaili aadress töötajale {employee}',
  enter_employee_email: 'Sisesta töötaja e-mail..',
  welcome_message: 'Tervitussõnum',
  enter_welcome_message: 'Sisesta tervitussõnum',
  no_attachment: 'Manuseid pole',
  log: 'Logi',
  rejected_transactions: 'Tagasilükatud avaldused',
  pending_transactions: 'Ootel avaldused',
  id_and_name: 'ID & nimi',
  actions: 'Tegevused',
  select_employees: 'Vali töötajad',
  start_dropping_employees: 'Alusta töötajate valimist siia',
  unselect_employees: 'Tühista töötajate valik',
  company: 'Ettevõte',
  help: 'Tugi',
  templates: 'Mallid',
  calendar: 'Kalender',

  start_typing_to_filter: 'Filtreerimiseks hakka kirjutama',
  position: 'Ametikoht',
  hireDate: 'Töölevõtu kuupäev',
  currency_frequency: 'Valuuta / Perioodilisus',
  annual: 'Aastas',

  per_amount: 'summa',
  per_annual: 'aasta',
  quarter: 'kvartal',
  month: 'kuu',
  biweek: 'kaks nädalat',
  week: 'nädal',
  day: 'päev',
  hour: 'tund',
  unit: 'ühik',
  percentage: 'protsent',

  choose_and_drop: 'Vali fail või lohista see siia',
  balance_taken: 'Saldo/Võetud',
  change: 'Muudatus',
  action: 'Tegevus',
  comment: 'Kommentaar',
  date: 'Kuupäev',
  trans: ' tehing(ut)',
  D: 'P',
  balances: 'Saldod',
  days_taken: 'päev võetud',
  leave_a_comment_reason: 'Palun kirjuta põhjus või kommentaar',
  sth_went_wrong_redirect: 'Midagi läks valesti, teid suunatakse 5 sekundi pärast ümber.',
  sth_went_wrong: 'Midagi läks valesti',
  no_employees_selected: 'Töötajaid pole valitud',
  column_settings_saved_ok: 'Veergude seaded on salvestatud!',
  document_not_created: 'Dokument pole loodud!',
  document_created_ok: 'Dokument on loodud!',
  document_removed_ok: 'Dokument on eemaldatud!',
  document_removed_nok: 'Dokument pole eemaldatud!',
  employee_created_ok: 'Töötaja on loodud!',
  employee_fields_saved_ok: 'Töötaja väljad on salvestatud!',
  employee_fields_nok: 'Töötaja väljade salvestamine ebaõnnestus',
  pay_element_save_nok: 'Palgaelemendi salvestamine ebaõnnestus: {errorMessage}',
  pay_element_delete_nok: 'Palgaelemendi muudatuse kustutamine ebaõnnestus: {errorMessage}',
  leave_transaction_created_ok: 'Puhkusekanne loodi!',
  leave_transaction_created_nok: 'Puhkusekande loomine ebaõnnestus',
  leave_transaction_created_approved_ok: 'Puhkusekanne loodi ja kinnitati!',
  leave_transaction_update_nok: 'Puhkusekande uuendamine ebaõnnestus',
  leave_transaction_updated_ok: 'Puhkusekanne on uuendatud!',
  leave_transaction_approved_ok: 'Puhkuseavaldus kinnitati!',
  leave_transaction_rejected_ok: 'Puhkuseavaldus lükati tagasi!',

  separation_sent_ok: 'Töölt lahkumine on edukalt saadetud',
  separation_sent_nok: 'Andmete salvestamine ebaõnnestus',
  oops_sth_went_wrong: 'Oops! Midagi läks valesti',
  leave_balance_update_ok: 'Puhkusesaldo on uuendatud',
  leave_balance_update_nok: 'Puhkusesaldo muutmine ebaõnnestus',
  report_template_created_ok: 'Raporti mall on loodud',
  report_template_created_nok: 'Raporti mall pole loodud',
  report_update_warning: 'Sa ei saa seda uuendada sest sa pole omanik',
  report_template_update_ok: 'Raporti mall on uuendatud',
  report_template_update_nok: 'Raporti mall pol uuendatud',
  report_doesnt_exist: 'Raport ei eksisteeri',
  report_template_not_set: 'Mall pole seadistatud',
  report_save_error: 'Rapporti salvestamisel tekkis viga',
  report_not_created: 'Raport pole loodud',
  report_created: 'Raport on loodud',
  report_updated_ok: 'Raport on uuendatud',
  report_updated_nok: 'Raport pole uuendatud',
  report_generated_nok: 'Raport pole loodud, palun proovi hiljem uuesti',
  template_def_columns_required: 'Malli definitsiooni veerud on kohustuslikud, palun kontrolli oma andmeid.',
  report_removed_ok: 'Raport on eemaldatud',
  report_removed_nok: 'Raport pole eemaldatud',
  report_template_removed_ok: 'Raporti mall on eemaldatud',
  report_template_removed_nok: 'Raporti mall pole eemaldatud',
  unknown: 'Teadmata',
  new_employee: 'Uus töötaja',

  getting_legal_entities: 'Ettevõtete pärimine',
  getting_consolidation_groups: 'Konsolideerimisgruppide pärimine',
  user_auth: 'Kasutaja autoriseerimine',
  getting_user_photo: 'Kasutaja foto pärimine',
  getting_company_logo: 'Ettevõtte logo pärimine',
  getting_current_period_info: 'Käimasoleva perioodi pärimine',
  ongoing: 'Käimasolevad',
  by: ' ',
  created_at: 'Loodud',
  d: 'p',
  days_have_been_used: ' - võetud päevi kokku',
  days_totel: ' - päevi kokku',
  process_details: 'Protsessi detailid',
  choose_value: 'Vali väärtus',
  custom: 'Kohandatud',
  field_cannot_be_empty: 'Väli ei või olla tühi',

  calculate_retroactive: 'Arvuta retro summa',
  retroactive_calculating: 'Retroaktiivne summa arvutatakse.',
  you_need_to_set_effective_date: 'Muutuse salvestamiseks on vajalik alguskuupäeva määramine.',
  periodic_amount: 'Perioodi summa',
  start_date: 'Alguskuupäev',
  effective_date: 'Kohaldamise kuupäev',
  end_date: 'Lõpukuupäev',
  goal_amount: 'Eesmärksumma',
  gross_up: 'Brutosumma',
  partial_period_treatment: 'Osaline periood',
  lump_sum: 'Ühekordne summa',
  pro_rata: 'Proportsionaalne',

  report_draft: 'mustand',
  report_generating: 'genereerimine',
  report_generated: 'loodud',
  report_errored: 'vigane',
  your_file_to_upload_or_browse: 'Fail üleslaadimiseks või sirvi',
  drag_and_drop: 'Drag & Drop',
  updated: 'Updated',
  regenerate_masterdata: 'Regenerate Masterdata',
  bank_not_found: 'Provided bank details do not match with configured system banks',

  organization: 'Organization',
  country: 'Country',
  total_headcount: 'Total headcount',
  report: 'Report',
  period: 'Period',
  OK: 'OK',
  pay_element_has_error: '',
  show_currency: 'Show currency',
}

export default estonian;
