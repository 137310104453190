import {EmployeeQueryParams, ApiEmployee, PayrollInterfaces, EmployeeInterfaces} from '@api';
import {SearchInput} from '@common';
import {constants, intl} from '@global';
import {RouteHelper} from '@library';
import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {RootState} from '../../../../rootReducer';
import {startLoading, stopLoading} from '../../../redux';
import * as globalSelectors from '../../../selectors';
import {
    PopoverContainer,
    ContentFiltersStatus,
    ContentFiltersStatuses,
    ContentFilters,
    ContentFiltersSearch, ContentEmployeeListLoadMore, ContentEmployeeListItem, ContentEmployeeList, ContentEmployeeListItemIndex, ContentEmployeeListEmpty
} from './Styles';
import {employeeNamWithRef} from './Utils';

interface StatisticByStatuses {
    [constants.EmployeeStatuses.active]: number;
    [constants.EmployeeStatuses.inactive]: number;
    [constants.EmployeeStatuses.draft]: number;
}

interface IProps {
    toggleModal: (isOpen: boolean|null) => void;

    currentLegalEntityId: number|null;
    currentPeriod: PayrollInterfaces.PayrollPeriod|null;

    startLoading: () => any;
    stopLoading: () => any;
}

interface IState {
    search: string;
    selectedStatus: constants.EmployeeStatuses;
    statisticByStatuses: StatisticByStatuses;

    employees: EmployeeInterfaces.Employee[];
    offset: number;
    isLoading: boolean;
    uniqueRequestKey: string|null;
}

export class EmployeeSelectorContent extends PureComponent<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            search: '',
            selectedStatus: constants.EmployeeStatuses.active,
            statisticByStatuses: {
                [constants.EmployeeStatuses.active]: 0,
                [constants.EmployeeStatuses.inactive]: 0,
                [constants.EmployeeStatuses.draft]: 0,
            },
            employees: [],
            offset: 0,
            isLoading: true,
            uniqueRequestKey: null
        }
    }

    componentDidMount() {
        this.allReload();
    }

    allReload = async () => {
        await this.reloadStatistic();
        this.reloadEmployees();
    }

    reloadStatistic = async () => {
        await Promise.all([
            this.reloadStatisticItem(constants.EmployeeStatuses.active),
            this.reloadStatisticItem(constants.EmployeeStatuses.inactive),
            this.reloadStatisticItem(constants.EmployeeStatuses.draft)
        ]);
    }
    reloadStatisticItem = async (status: constants.EmployeeStatuses) => {
        const total = await this.getStatisticByStatus(status);
        await this.setState(state => ({
            ...state,
            statisticByStatuses: {
                ...state.statisticByStatuses,
                [status]: total
            }
        }))
    }

    getStatisticByStatus = async (status: constants.EmployeeStatuses) => {
        const { currentPeriod, currentLegalEntityId } = this.props;
        const params = new EmployeeQueryParams({
            status,
            currentPeriod,
            offset: 0,
            limit: 1,
            searchQuery: this.state.search
        });
        const {employees, total} = await ApiEmployee.list(currentLegalEntityId as number, params);
        if (employees.length === 0) {
            return 0;
        }

        return total;
    }

    reloadEmployees = () => {
        this.setState({
            employees: [],
            offset: 0
        }, this.getEmployees)
    }

    getEmployees = async () => {
        const { selectedStatus } = this.state;
        const { currentPeriod, currentLegalEntityId } = this.props;

        if (this.isLoadedAllEmployees) {
            return;
        }

        const uniqueRequestKey = '_' + Math.random().toString(36).substr(2, 9);
        this.setState(state => ({
            ...state,
            isLoading: true,
            uniqueRequestKey
        }));

        const params = new EmployeeQueryParams({
            status: selectedStatus,
            currentPeriod,
            offset: this.state.offset,
            searchQuery: this.state.search
        });
        this.props.startLoading();
        const {employees} = await ApiEmployee.list(currentLegalEntityId as number, params);
        this.props.stopLoading();
        if (this.state.selectedStatus !== selectedStatus
            || (this.state.uniqueRequestKey && uniqueRequestKey !== this.state.uniqueRequestKey)
        ) {
            this.setState(state => ({
                ...state,
                isLoading: false,
            }))
            return;
        }

        this.setState(state => ({
            ...state,
            employees: [...state.employees, ...employees],
            limit: EmployeeQueryParams.DEFAULT_LIMIT,
            offset: state.offset + EmployeeQueryParams.DEFAULT_LIMIT,
            uniqueRequestKey: null
        }), () => {
            this.setState(state => ({...state, isLoading: false}))
        });
    }

    get isLoadedAllEmployees() {
        const { selectedStatus } = this.state;
        return this.state.employees.length === this.state.statisticByStatuses[selectedStatus];
    }

    get statuses() {
        return [
            {key: constants.EmployeeStatuses.active, label: intl.get(constants.EmployeeStatuses.active)},
            {key: constants.EmployeeStatuses.inactive, label: intl.get(constants.EmployeeStatuses.inactive)},
            {key: constants.EmployeeStatuses.draft, label: intl.get(constants.EmployeeStatuses.draft)},
        ];
    }

    onSelectFilterStatus = (status: constants.EmployeeStatuses) => {
        this.setState({selectedStatus: status}, this.reloadEmployees);
    }

    onChangeSearch = (search: string) => {
        this.setState({search}, this.allReload);
    }

    onSelectEmployee = (employee: EmployeeInterfaces.Employee) => {
        RouteHelper.goToEmployeePage(employee.id);
        this.props.toggleModal(false);
    }

    render() {
        const { selectedStatus, statisticByStatuses } = this.state;

        return <PopoverContainer isLoading={this.state.isLoading}>
            <ContentFilters>
                <ContentFiltersStatuses>
                    {this.statuses.map(status =>
                        <ContentFiltersStatus
                            isActive={status.key === selectedStatus}
                            key={status.key}
                            onClick={() => this.onSelectFilterStatus(status.key)}
                        >
                            {
                                statisticByStatuses[status.key] > 0
                                    ? `${status.label} (${statisticByStatuses[status.key]})`
                                    : status.label
                            }
                        </ContentFiltersStatus>
                    )}
                </ContentFiltersStatuses>
                <ContentFiltersSearch>
                    <SearchInput onChange={this.onChangeSearch} autoFocus={true} />
                </ContentFiltersSearch>
            </ContentFilters>

            <ContentEmployeeList>
                {this.state.employees.map((employee, index) =>
                    <ContentEmployeeListItem key={index} onClick={() => this.onSelectEmployee(employee)}>
                        <ContentEmployeeListItemIndex>
                            {index + 1}.
                        </ContentEmployeeListItemIndex>
                        {employeeNamWithRef(employee)}
                    </ContentEmployeeListItem>
                )}
                {!this.state.isLoading && this.state.employees.length === 0 &&
                    <ContentEmployeeListEmpty>
                        {intl.get('no_employees')}
                    </ContentEmployeeListEmpty>
                }
                {!this.state.isLoading && this.state.employees.length > 0 && !this.isLoadedAllEmployees &&
                    <ContentEmployeeListLoadMore onClick={() => this.getEmployees()}>
                        {intl.get('load_more')}
                    </ContentEmployeeListLoadMore>
                }
            </ContentEmployeeList>
        </PopoverContainer>
    }
}

const mapStateToProps = (state: RootState) => ({
    currentPeriod: globalSelectors.getCurrentPayrollPeriod(state),
    currentLegalEntityId: globalSelectors.getCurrentLegalEntityId(state)
});

const mapDispatchToProps = {
    startLoading,
    stopLoading,
};

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeSelectorContent);
