import {ApiObject} from '@api';

export interface IQueryFieldBuilder {
    queries: ApiObject.Queries;
    add: (entity: string, field: string, operator: ApiObject.QueryOperator, value: string) => void;
    remove: (entity: string, field: string, operator?: ApiObject.QueryOperator) => void;
    modify: (entity: string, field: string, operator: ApiObject.QueryOperator, value: string) => void;
    getQuery: (entity: string, field: string, operator?: ApiObject.QueryOperator, value?: string) => ApiObject.EntityFieldQuery|undefined;
    isExist: (entity: string, field: string, operator?: ApiObject.QueryOperator, value?: string) => boolean;
    clear: () => void;
    getQueries: () => ApiObject.Queries;
}

export class QueryFieldBuilder implements IQueryFieldBuilder{
    public queries: ApiObject.Queries;

    public constructor(queries: ApiObject.Queries = []) {
        this.queries = queries;
    }

    public add = (entity: string, field: string, operator: ApiObject.QueryOperator, value: string): void => {
        if (this.isExist(entity, field, operator)) {
            this.modify(entity, field, operator, value);
            return;
        }

        this.queries.push({
            entity,
            field,
            operator,
            value
        });
    }

    public remove = (entity: string, field: string, operator?: ApiObject.QueryOperator): void => {
        const index = this.queries.findIndex(query =>
            query.entity === entity
            && query.field === field
            && (operator !== undefined ? query.operator === operator : true)
        );
        if (index !== -1) {
            this.queries.splice(index, 1);
        }
    }

    public modify = (entity: string, field: string, operator: ApiObject.QueryOperator, value: string): void => {
        const index = this.queries.findIndex(query =>
            query.entity === entity && query.field === field && query.operator === operator
        );
        if (index !== -1) {
            this.queries.splice(index, 1, {entity, field, operator, value});
        }
    }

    public getQuery = (entity: string, field: string, operator?: ApiObject.QueryOperator, value?: string): ApiObject.EntityFieldQuery|undefined => {
        return this.queries.find(query =>
            query.entity === entity
            && query.field === field
            && (operator !== undefined ? query.operator === operator : true)
            && (value !== undefined ? query.value === value : true)
        );
    }

    public isExist = (entity: string, field: string, operator?: ApiObject.QueryOperator, value?: string): boolean => {
        return !!(this.getQuery(entity, field, operator, value));
    }

    public clear = () => {
        this.queries = [];
    }

    public getQueries = (): ApiObject.Queries => {
        return this.queries;
    }
}