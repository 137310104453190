import React, {PureComponent} from 'react';
import styled from 'styled-components';

import {constants, stylingVariables} from '@global';
import {ApiObject, EmployeeInterfaces} from '@api';

import Default from './Default/Default';
import Leave from './Leave/Leave';
import Documents from './Documents/Documents';
import Salary from './Salary/Salary';
import Dependents from './Dependents/Dependents';
import Payment from './Payment/Payment';
import {Route, RouteComponentProps, Switch, withRouter} from 'react-router';

const StyledTab = styled.div<{ignoreDefaultSpacing: boolean}>`
    position: relative;
    max-width: 100%;
    ${props => props.ignoreDefaultSpacing ? '' : 'margin: 20px 0;'}
    ${props => props.ignoreDefaultSpacing ? '' : 'padding: 0 40px;'}
`;

export const StyledFieldsGroupHeader = styled.h4`
    color: ${stylingVariables.colorPalette.darkest};
    font-size: 16px;
    font-weight: ${stylingVariables.fontWeight.bold};
    padding: 0;
`;

interface IProps extends RouteComponentProps<any> {
    ignoreDefaultSpacing: boolean;
    employee: EmployeeInterfaces.Employee;
    fields: ApiObject.Fields;
    fieldGroups: ApiObject.FieldGroup[];
    isCreateMode: boolean;
    tabs: {
        code: string;
        name: string;
    }[];
}

class Content extends PureComponent<IProps> {
    public constructor(props: IProps) {
        super(props);
    }

    public render() {
        return (
            <StyledTab ignoreDefaultSpacing={this.props.ignoreDefaultSpacing}>
                <Switch>
                    {this.props.tabs.map((tab, i) => {
                        const code = tab.code.replace('wizard_step:', '');
                        const path = [`${this.props.match.path}/${code}`];
                        if (i === 0) {
                            path.push(`${this.props.match.path}/`);
                        }
                        return (
                            <Route key={i} exact path={path}>
                                {this.getRouteComponent(tab.code)}
                            </Route>
                        );
                    })}

                </Switch>
            </StyledTab>
        );
    }

    private getRouteComponent = (code: string) => {
        switch (code) {
            case constants.DEFAULT_FIELD_GROUP_DOCUMENTS:
                return <Documents/>;
            case constants.DEFAULT_FIELD_GROUP_SALARY_NEW:
                return <Salary/>;
            case constants.DEFAULT_FIELD_GROUP_LEAVE:
                return <Leave employee={this.props.employee}/>;
            case constants.DEFAULT_FIELD_GROUP_DEPENDENTS:
                return <Dependents employee={this.props.employee}/>;
            case constants.DEFAULT_FIELD_GROUP_PAYMENT:
                return <Payment
                    isEdit={this.props.isCreateMode}
                    employee={this.props.employee}
                    group={this.props.fieldGroups.find(fieldGroup => fieldGroup.code === code) ?? null}
                />;
            default:
                return <Default
                    cuttOffs={this.props.employee?.cuttOffs}
                    fieldGroup={this.props.fieldGroups.find(fieldGroup => fieldGroup.code === code) ?? null}
                    isEdit={this.props.isCreateMode}
                    employee={this.props.employee}
                />;
        }
    }
}

export default withRouter(Content);
