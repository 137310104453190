import {ApiObject, EmployeeInterfaces} from '@api';
import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';

import {saveFields, clearErrorFields} from '../../redux';

import {RootState} from '../../../../rootReducer';
import * as selectors from '../../selectors';

import View from './View/View';
import Edit from './Edit/Edit';

const Wrapper = styled.div`
  display: flex;
  min-height: 400px;
`;

interface IProps {
    employee: EmployeeInterfaces.Employee;
    employeeId: number;
    fields: ApiObject.Fields;
    fieldGroup: ApiObject.FieldGroup | null;
    cuttOffs?: Object|null;
    saveFields: (id: number, updatedFields: ApiObject.FieldEntity[]) => Promise<boolean>;
    clearErrorFields: () => void;
    isEdit: boolean;
}

interface IState {
    isEdit: boolean;
}

class Default extends PureComponent<IProps, IState> {
    static defaultProps = {
        isEdit: false,
    };

    public constructor(props: IProps) {
        super(props);

        this.state = {
            isEdit: props.isEdit,
        };
    }

    public render() {
        const {isEdit} = this.state;

        if (!this.props.fieldGroup) {
            return null;
        }

        return (
            <Wrapper>
                {isEdit
                    ? <Edit fieldGroup={this.props.fieldGroup}
                            fields={this.props.fields}

                            onClickSave={this.save}
                            onClickCancel={this.disableEditMode}
                            employee={this.props.employee}
                    />
                    : <View
                        fieldGroup={this.props.fieldGroup}
                        fields={this.props.fields}
                        cuttOffs={this.props.cuttOffs}
                        onClickEditButton={this.enableEditMode}
                    />
                }
            </Wrapper>
        );
    }

    private disableEditMode = () => {
        this.props.clearErrorFields();
        this.setState({isEdit: false});
    }

    private enableEditMode = () => {
        this.props.clearErrorFields();
        this.setState({isEdit: true});
    }

    private save = (fields: ApiObject.FieldEntity[]): Promise<boolean> => {
        return this.props.saveFields(this.props.employeeId, fields);
    }
}

const mapStateToProps = (state: RootState) => ({
    employeeId: selectors.getCurrentEmployeeId(state),
    fields: selectors.getFields(state),
});

const mapDispatchToProps = {
    saveFields,
    clearErrorFields,
};

export default connect(mapStateToProps, mapDispatchToProps)(Default);
