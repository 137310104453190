import { constants, interfaces } from '@global'
import { AccessManager } from '@library'
import React, { ReactElement } from 'react'
import { connect } from 'react-redux'
import { RootState } from '../../rootReducer'
import { EmployeeInterfaces} from '@api';

interface CanProps {
  run: constants.PermissionCode
  permissions: interfaces.Permissions
  children: ReactElement
  disabled?: boolean
  cuttOffs?:EmployeeInterfaces.MasterdataEditAllowed | any
}

class Can extends React.Component<CanProps> {
  private disabledRender() {
    return (
      <>
        {React.cloneElement(this.props.children, {
          onClick: (event: any) => {
            event.preventDefault()
            event.stopPropagation()
          },
          style: {
            cursor: 'not-allowed',
            pointerEvents: 'none',
            opacity: '.7',
          },
        })}
      </>
    )
  }

  public render() {
    if (!this.props.children) {
      return null
    }
    const hasAccess = AccessManager.hasPermission(this.props.permissions, this.props.run)
   
    if (!hasAccess || this.props.disabled) {
      return this.disabledRender()
    }
    if (this.props.cuttOffs && !this.props.cuttOffs.masterdataEditAllowed){
      return this.disabledRender()
    }
 
    return <>{this.props.children}</>
  }
}

const mapStateToProps = (state: RootState) => ({
  permissions: state.global.permissions,
})

export default connect(mapStateToProps)(Can)
