import React, { PureComponent } from 'react'
import styled from 'styled-components'

import { stylingVariables } from '@global'
import { Input } from '@common'

import { Invitation } from './SendEssInvitation'
import { intl } from '@global'

const StyledEmailWrapper = styled.div`
  border-left: 1px dashed ${stylingVariables.colorPalette.green};
  padding-left: 15px;
  margin-bottom: 15px;
`

interface EmployeeEmailsProps {
  invitations: Map<number, Invitation>

  onChangeEmail: (id: number, loginUsername: string) => void
}

export default class Emails extends PureComponent<EmployeeEmailsProps> {
  render() {
    return Array.from(this.props.invitations.values()).map((invitation: Invitation) => (
      <StyledEmailWrapper key={invitation.id}>
        <Input
          label={intl.get('email_address_for_employee', { employee: invitation.fullName })}
          placeholder={intl.get('enter_employee_email')}
          errorMessage={invitation.validationError}
          defaultValue={invitation.loginUsername || ''}
          onChange={(event) => this.props.onChangeEmail(invitation.id, event.target.value)}
        />
      </StyledEmailWrapper>
    ))
  }
}
