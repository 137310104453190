import {ApiObject, EmployeeInterfaces} from '@api';
import {constants, stylingVariables} from '@global';
export default class ColorHelper {
    static readonly COLORS = [
        '#1db66d', '#abe4f5', '#e9d45a', '#e97f6e', '#30b6a2', '#3f85b6', '#7f59b6', '#90b667', '#5a82b6',
        '#a1a1b6',
    ];

    public static stringToColour(text: string): string {
        let hash = 0, i, chr;
        if (text.length === 0) {
            return this.COLORS[0];
        }
        for (i = 0; i < text.length; i++) {
            chr   = text.charCodeAt(i);
            hash  = ((hash << 5) - hash) + chr;
            hash |= 0;
        }

        return this.COLORS[Math.abs(hash) % this.COLORS.length];
    }

    public static hexToRgbA = (hex: string, opacity: number = 1) => {
        let c: any;
        if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
            c = hex.substring(1).split('');
            if (c.length === 3) {
                c = [c[0], c[0], c[1], c[1], c[2], c[2]];
            }
            c = '0x'+c.join('');
            let [r,g,b] = [(c>>16)&255, (c>>8)&255, c&255];
            return `rgba(${r}, ${g}, ${b}, ${opacity})`;
        }
        throw new Error('Bad Hex');
    }

    public static getCompensationTypeColor(key: string): string{
        switch (key) {
            case constants.CompensationTypes.recurringEarnings:
                return stylingVariables.colorPalette.brightGreen;
            case constants.CompensationTypes.recurringDeductions:
                return stylingVariables.colorPalette.red;
            case constants.CompensationTypes.additionalEarnings:
                return stylingVariables.colorPalette.brightBlue;
            case constants.CompensationTypes.additionalDeductions:
                return stylingVariables.colorPalette.yellow;
            default:
                return stylingVariables.colorPalette.gray;
        }
    }

    public static employeeStatusColor(status: ApiObject.Status): string{
        switch (status) {
            case ApiObject.Status.active:
                return stylingVariables.colorPalette.brightGreen;
            case ApiObject.Status.inactive:
                return stylingVariables.colorPalette.orange;
            case ApiObject.Status.draft:
                return stylingVariables.colorPalette.deepGray;
            default:
                return stylingVariables.colorPalette.gray;
        }
    }

    public static employeeEssStatusColor(status?: EmployeeInterfaces.EssStatus|null): string{
        switch (status) {
            case EmployeeInterfaces.EssStatus.invitation_sent:
                return stylingVariables.colorPalette.yellow;
            case EmployeeInterfaces.EssStatus.account_created:
                return stylingVariables.colorPalette.green;
            default:
                return stylingVariables.colorPalette.orange;
        }
    }
}
