import {EmployeeInterfaces} from '@api';
import {Icon} from '@common';
import {constants, stylingVariables, intl} from '@global';
import React, {PureComponent} from 'react';
import EmployeeSelectorContent from './EmployeeSelectorContent';
import {Container, DropdownContainer, DropdownIcon, PopoverWrapper, DropdownWrapper} from './Styles';
import {employeeName} from './Utils';

interface IProps {
    currentEmployee: EmployeeInterfaces.Employee|null;
}

interface IState {
    isExpanded: boolean;
}

export class EmployeeSelector extends PureComponent<IProps, IState> {
    private wrapperRef: any;

    constructor(props: IProps) {
        super(props);

        this.state = {
            isExpanded: false
        }
    }

    public componentDidMount() {
        document.addEventListener('mousedown', this.closeWhenClickOutside);
    }

    public componentWillUnmount() {
        document.removeEventListener('mousedown', this.closeWhenClickOutside);
    }

    toggleExpanding = (strictValue: boolean|null = null) => {
        this.setState(state => ({
            ...state,
            isExpanded: strictValue !== null ? strictValue : !state.isExpanded
        }));
    }

    render() {
        return <Container isExpanded={this.state.isExpanded} ref={(node: any) => this.wrapperRef = node}>
                <DropdownWrapper isExpanded={this.state.isExpanded}>
                    <DropdownContainer onClick={() => this.toggleExpanding()} isExpanded={this.state.isExpanded}>
                        <span>
                            {intl.get('employee')} / {employeeName(this.props.currentEmployee, intl.get('new_employee'))}
                        </span>

                        <DropdownIcon>
                            <Icon type={this.state.isExpanded ? constants.IconTypes.ARROW_UP : constants.IconTypes.ARROW_DOWN}
                                  height={8}
                                  width={8}
                                  fillColor={stylingVariables.colorPalette.green}
                            />
                        </DropdownIcon>
                    </DropdownContainer>
                    {this.state.isExpanded &&
                    <PopoverWrapper>
                        <EmployeeSelectorContent toggleModal={this.toggleExpanding} />
                    </PopoverWrapper>
                    }
                </DropdownWrapper>
        </Container>
    }

    public closeWhenClickOutside = (event: Event) => {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.toggleExpanding(false);
        }
    }
}

export default EmployeeSelector;

