import React from 'react';
import {Column} from 'react-virtualized';
import {defaultInfiniteTableCellDataGetter, Icon} from '@common';
import {constants, stylingVariables} from '@global';
import styled from 'styled-components';

const StyledActionWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    flex-grow: 1;
`;

export const StyledEditAction = styled.div`
    cursor: pointer;
    padding: 10px;
    border-radius: 5px;
`;

interface IProps {
    action: (values: any) => void;
}

const Edit: React.FC<IProps> = ({action}) => (
    <Column
        dataKey='actions'
        width={70}
        cellDataGetter={defaultInfiniteTableCellDataGetter}
        cellRenderer={({cellData}) => {
            if (!cellData.allowEdit) {
                return null;
            }
            return (
                <StyledActionWrapper>
                    <StyledEditAction onClick={() => action(cellData)}>
                        <Icon type={constants.IconTypes.PENCIL_EDIT}
                          fillColor={stylingVariables.colorPalette.secondary}
                          height={14}
                          width={14}
                        />
                    </StyledEditAction>
                </StyledActionWrapper>
            );
        }}
    />
);

export default Edit;
