import React, {PureComponent} from 'react';
import styled from 'styled-components';

import SalarySummaryBlock, {EmployeeSalarySummaryDetail} from './SalarySummaryBlock';
import {constants, intl} from '@global';
import {ApiObject, EmployeeInterfaces} from '@api';
import * as AddPaymentGroup from '../AddPaymentGroup/index';
import {ColorHelper} from '@library';
import {SalarySectionData} from '../../../../../api/employee.interfaces';
import {groupBy, orderBy, last} from 'lodash'

const StyledEmployeeSummary = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 0 -20px 30px -20px;
`;

interface IProps {
    salarySections: SalarySectionData[];
    action: (code: string) => void;
    openSalaryPaymentGroupModalAction: () => void;
    salaryEnabledGroups: string[];
    enableableSalarySections: ApiObject.SelectOption[];
    masterdataEditAllowed: boolean;
}

export default class SalarySummary extends PureComponent<IProps> {

    get allowedPaymentGroups(): string[] {
        return [
            constants.CompensationTypes.recurringDeductions,
            constants.CompensationTypes.recurringEarnings,
            constants.CompensationTypes.additionalEarnings,
            constants.CompensationTypes.additionalDeductions,
        ];
    }

    protected getDetails(section: SalarySectionData, dataKey: string): EmployeeSalarySummaryDetail[] {
        let helper: EmployeeInterfaces.EmployeePayElementDetails[] = []
        let grouped = groupBy(section.payElements, 'rootEmployeePayElementId')

        Object.values(grouped).forEach(group => {
            if (group.length === 1) {
                helper = [...helper, ...group]
            }
            else {
                group.forEach(element => {
                    const changes = element.changes
                    if (changes && changes.length > 0) {
                        const withLatestStart = last(orderBy(changes, 'startDate'))
                        const matchingElement = withLatestStart ? group.find(el => el.id === withLatestStart.id) : null

                        if (matchingElement) {
                            helper = [...helper, matchingElement]
                        }

                    }
                    else if (!changes && !element.childElement) {
                        helper = [...helper, element]
                    }
                })
            }
        })

        return helper
            .map(element => {
                let value = 0;
                let annualValue = 0

                if (dataKey === 'annual') {
                    if (element.annualAmount) {
                        value = element.annualAmount;
                        annualValue = element.annualAmount
                    } else if (element.payUnit !== ApiObject.PayUnitType.percentage){
                        value = element.amount || 0
                    }
                } else if (dataKey === 'amount' && element.amount) {
                    value = element.amount;
                    annualValue = element.amount
                } 
                
                return {
                    payUnit: element.payUnit,
                    label: element.payElementName,
                    value,
                    annualValue,
                    currency: element.currency ? element.currency : '',
                };
            });
    }

    protected isBlockVisible(section: SalarySectionData): boolean {
        if (!this.allowedPaymentGroups.includes(section.code)) {
            return false;
        }

        if (section.payElements.length > 0) {
            return true;
        }

        return this.props.salaryEnabledGroups.indexOf(section.code) > -1;
    }

    protected getTotalDataKey(section: SalarySectionData): string {
        const recurringPaymentGroups : string[] = [
            constants.CompensationTypes.recurringEarnings,
            constants.CompensationTypes.recurringDeductions,
        ];

        if (recurringPaymentGroups.includes(section.code)){
            return 'annual';
        }

        return 'amount';
    }

    public render() {
        return (
            <StyledEmployeeSummary>
                {this.props.salarySections.map(section => {

                    if (!this.isBlockVisible(section)) {
                        return null;
                    }

                    const dataKey = this.getTotalDataKey(section);
                    const amountTitle = intl.get((dataKey === 'annual') ? 'total_annual' : 'total') + ' ' + intl.get('amount');

                    return <SalarySummaryBlock
                        key={section.code}
                        color={ColorHelper.getCompensationTypeColor(section.code)}
                        title={section.label}
                        amountTitle={amountTitle}
                        action={() => this.props.action(section.code)}
                        details={this.getDetails(section, dataKey)}
                        masterdataEditAllowed={this.props.masterdataEditAllowed}
                    />;
                })}

                {!!(this.props.enableableSalarySections && this.props.enableableSalarySections.length) &&
                    this.props.masterdataEditAllowed &&
                    <AddPaymentGroup.Block action={this.props.openSalaryPaymentGroupModalAction}
                />}
            </StyledEmployeeSummary>
        );
    }

}
