const english = {
  mercans: 'Mercans',
  term_of_use: 'Terms of Use',
  sla: 'SLA',
  privacy_policy: 'Privacy Policy',

  admin_console: 'Admin Console',
  secure_file_transfer: 'Secure File Transfer',
  payslips: 'Payslips',
  my_information: 'My Information',
  my_team: 'My Team',
  my_apps: 'My Apps',
  apply_for_leave: 'Apply for leave',
  add_leave_transaction: 'Add leave transaction',
  add_and_approve: 'Add & Approve',
  cannot_approve_payroll_processing: 'Payroll is processing, cannot approve requests at the moment',
  enter_my_time_and_attendance_data: 'Enter My Time and Attendance Data',
  help_and_support: 'Help & Support',
  select_entity: 'Select an entity to work on',
  back_to_launchpad: 'Back to Launchpad',
  workflow: 'Workflow',

  employees: 'Employees',
  no_employees_found: 'No Employees Found',
  no_leaves_found: 'There is currently no leave',
  no_transactions: 'There is currently no transactions',
  name: 'Name',
  pay_unit: 'Pay unit',
  amount: 'Amount',
  currency: 'Currency',
  no_details_found: 'There is currently no details',
  ess_access: 'ESS Access',
  add_or_remove_columns: 'Add or Remove Columns',
  columns_selected: 'Columns are selected',
  unselected_columns: 'Unselected Columns',
  filter_by: 'Filter by',
  apply: 'Apply',
  reset: 'Reset',
  filter_by_employee_header: 'Filter by Employee & ID',
  filter_by_employee: 'By Employee',
  filter_by_tags: 'By Tags',
  employee: 'Employee',
  add_employee: 'Add Employee',
  add_employee_s: 'Add Employee(s)',
  save_and_next: 'Save and next',
  save_and_add: 'Save and Add',
  import_employee_data_from_excel: 'Import Employee Data from Excel',
  employee_separated: 'Employee Separated',
  advanced_settings: 'Advanced Settings',
  separation_no_pay_elements: 'No termination pay elements set up',
  view_saved_separations: 'View Saved Separations',
  insert_separation: 'Insert Separation',
  send_ess_invitation: 'Send ESS Invitation',
  send_ess_invitation_success: 'ESS Invitations has been successfully sent!',
  send_ess_invitation_failed: 'Failed to send ESS Invitation...',
  revoke: 'Revoke',
  revoke_ess_access: 'Revoke access to ESS',
  revoke_ess_access_confirmation: 'Are you sure you want to revoke access to ESS?',
  revoke_ess_success: 'Access to ESS has been successfully revoked!',
  insert_vacation: 'Insert Vacation',
  insert_expense: 'Insert Expense',
  insert_one_time_payment: 'Insert One-Time Payment',
  insert_absence: 'Insert Absence',
  reason: 'Reason',
  annual_total: 'Annual total',
  before: 'Before',
  after: 'After',

  payrolls: 'Payrolls',
  payroll: 'Payroll',
  new_payroll: 'New payroll',
  current: 'Current',
  previous: 'Previous',
  open: 'Open',
  input: 'Input',
  preinput: 'Correcting input',
  processing: 'Processing',
  authorization_form: 'Authorization form',
  calculated: 'Calculated',
  calculating: 'Calculating',
  cancelled: 'Cancelled',
  cancelling: 'Cancelling',
  close: 'Close',
  closed: 'Closed',
  closing: 'Closing',
  generating: 'Generating',
  invalid: 'Invalid',
  add_terminations: 'Add Terminations',
  pending_termination: 'Pending Termination',
  starters: 'Starters',
  starter: 'Starter',
  leavers: 'Leavers',
  leaver: 'Leaver',
  add_new_hire: 'Add New Hire',
  hire_date: 'Hire Date',
  last_working_date: 'Last Working Date',
  review_and_approve_changed_inputs: 'Review & Approve Changed Inputs',
  approve_and_continue: 'Approve & continue',

  active: 'Active',
  active_employee_success: 'The employee has been successfully activated!',
  active_employees_success: 'The employees have been successfully activated!',
  activate_employee_failed: 'Failed to activate employee {name}',
  activate_employees_failed: 'Failed to activate employees',
  inactive: 'Inactive',
  draft: 'Draft',

  leaves: 'Leaves',
  leave_transactions: 'Leave transactions',
  leave_history_not_loaded: 'Leave History were not loaded',
  leave_details: 'Leave Details',
  leave_balance: 'Leave Balance',
  leave_balances: 'Leave Balances',
  balances_details: 'Balances Details',
  edit_log: 'Edit Log',
  leave_balances_in_year: 'Leave Balances {year}',
  leave_balance_changed: 'Leave balance change',
  new_period_balances_set: '{period} - new balances',
  leave_balance_transaction: 'Leave transaction was made',
  open_ended_leave: 'Open-Ended Leave',
  open_ended: 'Open-Ended',
  new_leave: 'New Leave',
  new_leave_transaction: 'New Leave Transaction',
  edit_leave: 'Edit Leave',
  upcoming: 'Upcoming',
  view_details_history: 'View details & history',
  leave_type: 'Leave Type',
  leave_days: 'Leave Days',
  selected_days: 'Selected Days',
  requested_leave_time: 'Requested leave time',
  balance: 'Balance',
  transactions: 'Transactions',
  entry_date_and_user: 'Entry date & User',
  before_transaction: 'Before transaction',
  after_transaction: 'After transaction',
  calendar_days_left: 'Calendar days left',
  calendar_days_taken: 'Calendar days taken',
  business_days_left: 'Business days left',
  business_days_taken: 'Business days taken',
  leave_days_taken_in_year: 'Leave days taken in {year}',
  view_annual_balance: 'View annual balance',
  view_current_balance: 'View current balance',
  employees_on_leave: 'Employees on Leave',
  approve_leave_transaction_confirmation: 'Are you sure you want to approve leave transaction?',
  reject_leave_transaction_confirmation: 'Are you sure you want to reject leave transaction?',
  approve_all_leave_transactions_confirmation: 'Are you sure you want to approve all leave transactions in this group?',
  reject_all_leave_transactions_confirmation: 'Are you sure you want to reject all leave transactions in this group?',
  used: 'used',
  taken: 'taken',
  left: 'left',
  invalid_adjustments_present: 'There are invalid balance adjustments ({leaveTypes}) in other tabs',
  edit_balance: 'Edit balance',
  edit_balances: 'Edit balances',
  view_employee_card: 'View employee card',
  in_: 'in ',
  as_of_: 'as of ',
  days_left: 'Days left',
  current_balance: 'Current balance',
  add_or_remove_days: 'Add or remove balance',
  new_balance: 'New balance',
  calendar_days: 'Calendar days',
  workdays: 'Workdays',
  reject_all: 'Reject all',
  view_details: 'View details',
  close_details: 'Close details',
  postpone: 'Postpone',
  expand_row: 'Expand row',
  transaction: 'Transaction',
  this_month: 'This month',
  this_year: 'This year',
  payment_schedule: 'Payment Schedule',

  view: 'View',
  approve: 'Approve',
  approve_all: 'Approve all',
  reject: 'Reject',
  import: 'Import',
  export: 'Export',
  dates: 'Dates',
  status: 'Status',
  pending: 'Pending',
  open_ended_pending: 'Pending open-ended',
  activate: 'Activate',
  suspend: 'Suspend',
  suspend_employee_success: 'The employee has been successfully suspended!',
  suspend_employees_success: 'The employees have been successfully suspended!',
  activate_confirmation: 'Are you sure you want to activate?',
  suspend_confirmation: 'Are you sure you want to suspend?',
  terminate: 'Terminate',
  repayment: 'Repayment',
  submitted: 'Submitted',
  rejected: 'Rejected',
  done: 'Done',
  approved: 'Approved',
  waiting_for_approval: 'Waiting for approval',
  send: 'Send',

  select_all: 'Select All',
  select: 'Select',

  edit: 'Edit',
  sign_out: 'Sign out',
  settings: 'Settings',
  back: 'Back',
  save: 'Save',
  cancel: 'Cancel',
  contact: 'Contact',
  history: 'History',
  taken_this_year: 'Taken this year',
  future: 'Future',
  attachments: 'Attachments',
  attachment: 'Attachment',

  total: 'Total',
  total_annual: 'Total Annual',
  request: 'Request',
  requests: 'Requests',
  view_rejected_transaction: 'View rejected transaction',
  close_view: 'Close View',
  new: 'New',
  on: 'On',
  add: 'Add',
  add_file: 'Add File',
  new_file: 'New File',
  days: 'days',
  Days: 'Days',
  for: 'for',
  not_found: 'Not Found',

  start: 'Start',
  end: 'End',
  to: 'to',
  select_date: 'Select Date',
  load_more: 'Load more',
  select_days: 'Select Days',
  ID: 'ID',
  clear_filters_and_sorts: 'Clear filters & sorts',
  new_design: 'New Design',

  documents: 'Documents',
  new_document: 'New document',
  remove_document: 'Remove document',
  compensations: 'Compensations',
  display_name: 'Display name',
  description: 'Description',
  visible_in_ess: 'Visible in ESS',
  file: 'File',
  size: 'Size',
  upload_date: 'Upload date',
  remove_document_confirmation: 'Are you sure you want to remove document?',
  no_document_found: 'No document found',
  drop_here: 'Drop here',
  remove: 'Remove',
  delete: 'Delete',
  added_this_month: 'added this Month',
  no: 'No',
  yes: 'Yes',

  access_management: 'Access Management',
  last_activity: 'Last Activity',

  termination_date: 'Termination date',
  choose: 'Choose ...',
  employee_group: 'Pay group',
  dates_transaction_count: 'Dates /Transaction Count',
  source: 'Source',
  leaveTypeName: 'Leave Type',
  payroll_period: 'Payroll Period',

  recurring_earnings: 'Recurring Earnings',
  recurring_deductions: 'Recurring Deductions',
  recurring_units: 'Recurring Units',
  additional_earnings: 'Additional Earnings',
  additional_deductions: 'Additional Deductions',
  additional_units: 'Additional Units',
  warning: 'Warning!',
  amount_and_date_empty: 'Recurring deductions should have End Date or a Goal Amount',
  recurring_deductions_save_confirmation:
    ' Recurring deductions should have End Date or a Goal Amount. Are you sure you want to save it?',
  pay_element_change_delete_confirmation: 'Are you sure you want to delete this pay element change?',

  view_history: 'View History',
  close_history: 'Close History',
  view_future: 'Pending Changes',
  close_future: 'Close Pending Changes',
  add_pay_element_type: 'Add Pay Element Group',
  pay_element_type: 'Pay Element Type',

  all: 'All',
  recurrings: 'Recurrings',
  one_times: 'One Times',
  units: 'Units',

  expand_all: 'Expand all',
  collapse_all: 'Collapse all',
  percentage_symbol: '%',
  values: 'Values',

  dependents: 'Dependents',
  dependent: 'Dependent',
  add_dependent: 'Add Dependent',
  edit_dependent: 'Edit Dependent',
  delete_dependent: 'Delete Dependent',
  delete_dependent_confirmation: 'Are you sure you want to delete dependent?',
  no_dependents_found: 'No Dependents Found',

  employee_fields_saved: 'Employee fields have been saved successfully!',
  employee_fields_not_saved: 'Failed to save Employee fields',

  dependent_deleted: 'Dependent has been deleted successfully!',
  dependent_not_deleted: 'Failed to delete Dependent',

  cookie_disabled_message:
    'Cookies are blocked or not supported by your browser. You must enable cookies for the proper work of the application.',
  no_employees: 'No employees',

  reports: 'Reports',
  report_templates: 'Report Templates',
  personal: 'Personal',
  common: 'Common',
  search: 'Search',
  report_template: 'Report Template',
  report_status: 'Report Status',
  report_generation_date: 'Report Generation Date',
  report_name: 'Report Name',
  user: 'User',
  no_reports_found: 'No Reports Found',
  template_name: 'Template Name',
  view_template: 'View Template',
  edit_template: 'Edit Template',
  generate_report: 'Generate Report',
  no_report_templates_found: 'No Report Templates found',
  create_new_report: 'Create a new report',
  report_from_existing_template: 'Create a report from an existing template',
  continue: 'Continue',
  date_range: 'Date range',
  select_active: 'Select Active',
  select_inactive: 'Select Inactive',
  select_draft: 'Select Draft',
  manual_employee_select: ' Or select employees manually and continue',
  delete_report: 'Delete report',
  delete_report_confirmation: 'Are you sure you want to delete this report?',
  delete_template: 'Delete template',
  delete_template_confirmation: 'Are you sure you want to delete this report template?',

  january: 'January',
  february: 'February',
  march: 'March',
  april: 'April',
  may: 'May',
  june: 'June',
  july: 'July',
  august: 'August',
  september: 'September',
  october: 'October',
  november: 'November',
  december: 'December',

  selected_employees: 'Selected Employees',
  available_employees: 'Available Employees',

  add_field: 'Add Field',
  add_group: 'Add Group',
  quick_adding: 'Quick Adding',
  group_name: 'Group name',

  save_and_generate_report: 'Save & Generate Report',
  save_as_a_template: 'Save as a template',
  update_template: 'Update Template',
  select_data_fields_to_report: 'Select data fields to Report',
  not_all_employees_with_this_status_have_been_selected: 'Not all employees with this status have been selected',
  preview_report: 'Preview Report',
  save_and_generate: 'Save & Generate',

  report_date_range: 'Report Date Range',
  report_date: 'Report Date',
  you: 'You',

  new_report: 'New Report',
  edit_report: 'Edit Report',
  update_report: 'Update Report',
  view_report: 'View Report',
  new_report_template: 'New Report Template',
  new_template: 'New Template',
  edit_report_template: 'Edit Report Template',
  update_report_template: 'Update Report Template',
  view_report_template: 'View Report Template',
  save_as: 'Save As ...',
  report_not_generated: "Sorry, report wasn't generated",
  please_try_again: 'Please try again',
  mandatory_field: 'This is mandatory field',
  data_source: 'Data source',
  data_source_explanation:
    'Payroll data will include amounts that were processed in the payroll (prorated values, calculated values, etc.). Employee card option will include the amounts that have been saved to the employee card.',

  master_data: 'Master data',
  employee_id: 'Employee ID',
  payroll_calculation: 'Payroll calculation',
  employee_record: 'Employee record',
  pay_element: 'Pay element',

  created_by_user: 'Created by User',
  visibility: 'Visibility',
  back_to_edit: 'Back to edit',

  add_account: 'Add account',

  employee_and_id: 'Employee & ID',
  bulk_actions: 'Bulk actions ',
  out_of: ' out of ',
  email_address_for_employee: 'Email address for {employee}',
  enter_employee_email: 'Enter employee email address...',
  welcome_message: 'Welcome message',
  enter_welcome_message: 'Enter welcome message',
  no_attachment: 'No attachment',
  log: 'Log',
  rejected_transactions: 'Rejected transactions',
  pending_transactions: 'Pending transactions',
  id_and_name: 'ID & name',
  actions: 'Actions',
  select_employees: 'Select employees',
  start_dropping_employees: 'Start dropping employees here',
  unselect_employees: 'Unselect employees',
  company: 'Company',
  help: 'Help',
  templates: 'Templates',
  calendar: 'Calendar',

  start_typing_to_filter: 'Start typing to filter',
  position: 'Position',
  hireDate: 'Hire date',
  currency_frequency: 'Currency / Frequency',
  annual: 'Annual',

  per_amount: 'amount',
  per_annual: 'annual',
  quarter: 'quarter',
  month: 'month',
  biweek: 'biweek',
  week: 'week',
  day: 'day',
  hour: 'hour',
  unit: 'unit',
  percentage: 'percentage',

  choose_and_drop: 'Choose a file or drag it here',
  balance_taken: 'Balance/Taken',
  change: 'Change',
  action: 'Action',
  comment: 'Comment',
  date: 'Date',
  trans: ' trans',
  D: 'D',
  balances: 'Balances',
  days_taken: 'days taken',
  leave_a_comment_reason: 'Please leave a comment or describe a reason for update',
  sth_went_wrong_redirect: 'Something went wrong. Will be redirected in 5 seconds',
  sth_went_wrong_redirect_launchpad: 'Something went wrong. You will be redirected back to Launchpad.',
  sth_went_wrong: 'Something went wrong',
  no_employees_selected: 'No employees selected',
  column_settings_saved_ok: 'Column Settings have been saved successfully!',
  document_not_created: "Document hasn't been created",
  document_created_ok: 'Document has been successfully created!',
  document_removed_ok: 'Document has been successfully removed!',
  document_removed_nok: "Document hasn't been removed",
  employee_created_ok: 'Employee have been created successfully!',
  employee_fields_saved_ok: 'Employee fields have been saved successfully!',
  employee_fields_nok: 'Failed to save employee fields',
  pay_element_save_nok: 'Failed to save pay element: {errorMessage}',
  pay_element_delete_nok: 'Failed to delete pay element: {errorMessage}',
  leave_transaction_created_ok: 'Leave Transaction has been created successfully!',
  leave_transaction_created_nok: 'Failed to add Leave Transaction',
  leave_transaction_created_approved_ok: 'Leave Transaction has been created and approved successfully!',
  leave_transaction_updated_ok: 'Leave Transaction has been updated successfully!',
  leave_transaction_update_nok: 'Failed to update Leave Transaction',
  leave_transaction_approved_ok: 'Leave transaction has been approved successfully!',
  leave_transaction_rejected_ok: 'Leave transaction has been rejected successfully!',

  separation_sent_ok: 'Separation has been successfully sent!',
  separation_sent_nok: 'Failed to save Separation...',
  oops_sth_went_wrong: 'Oops! Something went wrong!',
  leave_balance_update_ok: 'Leave Balance has been updated successfully!',
  leave_balance_update_nok: 'Failed to update Leave Balance',
  report_template_created_ok: 'Report template has been created successfully!',
  report_template_created_nok: "Report template hasn't been created successfully!",
  report_update_warning: 'You cannot update this report template because you are not the owner',
  report_template_update_ok: 'Report Template has been updated successfully!',
  report_template_update_nok: "Report template hasn't been updated!",
  report_doesnt_exist: 'Report does not exist.',
  report_template_not_set: "Template hasn't been set",
  report_save_error: 'An error occurred while saving the report',
  report_not_created: "Report hasn't been created",
  report_created: 'Report has been created!',
  report_updated_ok: 'Report has been updated successfully!',
  report_updated_nok: "Report hasn't been updated!",
  report_generated_nok: "Report wasn't generated. Please try later",
  template_def_columns_required: 'Template definition column groups are required. Please check the form',
  report_removed_ok: 'Report has been successfully removed!',
  report_removed_nok: "Report hasn't been removed",
  report_template_removed_ok: 'Report template has been successfully removed!',
  report_template_removed_nok: "Report template hasn't been removed",
  unknown: 'Unknown',
  new_employee: 'New employee',

  getting_legal_entities: 'Getting legal entities',
  getting_consolidation_groups: 'Getting consolidation groups',
  user_auth: 'User Authorization',
  getting_user_photo: 'Getting user photo',
  getting_company_logo: 'Getting company logo',
  getting_current_period_info: 'Getting current period info',
  ongoing: 'Ongoing',
  by: ' by',
  created_at: 'Created at',
  d: 'd',
  have_been_used: 'have been used',
  process_details: 'Process details',
  choose_value: 'Choose value',
  custom: 'Custom',
  field_cannot_be_empty: 'Field cannot be empty',

  calculate_retroactive: 'Calculate retroactive element?',
  retroactive_calculating: 'Retroactive amount is being calculated.',
  you_need_to_set_effective_date: 'You need to set a new effective date for pay element change.',
  periodic_amount: 'Periodic amount',
  start_date: 'Start date',
  effective_date: 'Effective date',
  end_date: 'End date',
  goal_amount: 'Goal amount',
  gross_up: 'Gross up',
  partial_period_treatment: 'Partial period treatment',
  lump_sum: 'Lump sum',
  pro_rata: 'Pro rata',

  payroll_admin_console: 'Payroll admin console',
  employee_self_service: 'Employee self service',
  manager_self_service: 'Manager self service',

  h: 'h',
  hours: 'hours',
  hours_left: 'hours left',
  hours_taken: 'hours taken',
  search_employee: 'Search Employee',

  report_modal_title: 'Choose which report you want to generate',
  employee_master_data_report: 'Employee master data report',
  employee_master_data_report_exp: 'Reports generated based on employee master data',
  payroll_results_report: 'Payroll results report',
  payroll_results_report_exp: 'Reports generated based on payroll result',
  report_generated_ok: 'Report has been generated successfully!',
  report_not_error: 'The report was not generated in time, please try to refresh the page in a couple of minutes',
  no_active_period_for_entity: 'Selected entity does not have an active payroll period, actions are limited.',
  edit_mode: 'Edit mode',
  report_draft: 'draft',
  report_generating: 'generating',
  report_generated: 'generated',
  report_errored: 'errored',

  your_file_to_upload_or_browse: 'Your file to upload or browse',
  drag_and_drop: 'Drag & Drop',

  field_has_error: 'Please check the data',
  bank_account_created: 'Bank account created successfully!',
  you_need_to_fix_errors_before_continue: 'You need to fix these errors before you can continue',
  start_typing_to_display_employees: 'Start typing to display employees',
  create_hyperwallet_account: 'Create Hyperwallet account',
  search_employee_fields: 'Search employee by name, ID, email, position or department',
  masterdata_edit_not_allowed: 'Masterdata edit is currently disabled for selected employee, unable to perform actions.',
  updated: 'Updated',
  regenerate_masterdata: 'Regenerate Masterdata',
  transitional_balance: 'Transitional balance',
  transitional_taken: 'Transitional taken',
  cashout: 'Cashout',
  base_entry: 'Period start balances/taken amounts',
  payroll_results: 'Payroll calculation',
  employee_masterdata: 'Employee record',
  failed_to_load_bank_data: 'Failed to load bank data',
  bank_not_found: 'Provided bank details do not match with configured system banks',
  field_selected_but_not_exist_in_list: 'The field is selected but does not exist in the list',
  wrong_fields_selected_please_review: 'Some of the selected fields do not exist, please check',
  employees_are_being_activated: 'Employees are being activated, please wait...',

  organization: 'Organization',
  country: 'Country',
  total_headcount: 'Total headcount',
  report: 'Report',
  period: 'Period',
  remove_pending_termination:'Remove Pending Termination',
  remove_pending_termination_confirmation:'Are you sure you want to remove pending termination',
  remove_pending_termination_success:'Pending termination has been successfully removed',

  termination_reason: 'Termination Reason',
  ess_access_revoke_date: 'ESS access revoke date',
  ess_invitation_send_date: 'ESS invitation send date',
  ess_last_login_date: 'ESS last login date',
  ess_first_login_date: 'ESS first login date',
  employee_group_name: 'Pay group name',
  number_of_children: 'Number of children',
  ess_last_access_date: 'Last ESS Access Date',
  OK:'OK',
  pay_element_has_error: 'Failed to get termination pay elements, Please try again ',
  show_currency: 'Show currency',
  cannot_reject_transaction_from_ess: 'This transaction is created from ESS and cannot be rejected from PAC.',
  file_name_must_not_contain_non_ascii: 'Filename(s) should contain only basic alphanumeric characters (A-Z, a-z, 0-9). Special characters, such as (@, #, $, %, &, *, etc.), are not allowed.'
};

export default english;
