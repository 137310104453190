import {ApiObject} from '@api';
import {IQueryFieldBuilder, QueryFieldBuilder} from './query-field.builder';

const ENTITY_EMPLOYEE = 'employee';
const FIELD_ID = 'id';
const FIELD_STATUS = 'status';

export default class EmployeeQueryFieldBuilder {
    private readonly builder: IQueryFieldBuilder;

    constructor(queries: ApiObject.Queries = []) {
        this.builder = new QueryFieldBuilder(queries);
    }

    static build() {
        return new EmployeeQueryFieldBuilder();
    }

    getBuilder = (): IQueryFieldBuilder => {
        return this.builder;
    }

    queries = (): ApiObject.Queries => {
        return this.builder.getQueries();
    }

    applyStatuses = (statuses: ApiObject.Status[], force: boolean = false) => {
        if (force) {
            this.builder.clear();
        }

        this.builder.add(ENTITY_EMPLOYEE, FIELD_STATUS, ApiObject.QueryOperator.in, statuses.join(','));

        return this;
    }

    applyEmployeeIds = (ids: number[], force: boolean = false) => {
        if (force) {
            this.builder.clear();
        }

        if (ids.length === 0) {
            this.builder.remove(ENTITY_EMPLOYEE, FIELD_ID, ApiObject.QueryOperator.in);
            return this;
        }

        this.builder.add(ENTITY_EMPLOYEE, FIELD_ID, ApiObject.QueryOperator.in, ids.join(','));

        return this;
    }

    applyEmployeeNinIds = (ids: number[], force: boolean = false) => {
        if (force) {
            this.builder.clear();
        }

        if (ids.length === 0) {
            this.builder.remove(ENTITY_EMPLOYEE, FIELD_ID, ApiObject.QueryOperator.nin);

            return this;
        }

        this.builder.add(ENTITY_EMPLOYEE, FIELD_ID, ApiObject.QueryOperator.nin, ids.join(','));

        return this;
    }
}