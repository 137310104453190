import React, {PureComponent} from 'react';
import styled from 'styled-components';
import {stylingVariables, constants} from '@global';
import {Icon} from '@common';

const StyledActionsWrapper = styled.div`
    justify-content: flex-end;
    display: flex;
    width: 100%;
`;

const StyledActionsContentWrapper = styled.div<{isOpen: boolean}>`
  display: ${props => props.isOpen ? 'flex' : 'none'};
  align-items: center;
  position: absolute;
  right: 5px;
  top: 15px;
  background-color: ${stylingVariables.colorPalette.white};
  padding: 0 10px;
  box-shadow: ${stylingVariables.layout.boxShadow};
  border-radius: 35px;
  min-width: 80px;
  z-index: 2000;
`;

const StyledActionsButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${stylingVariables.colorPalette.orange};
  font-size: ${stylingVariables.fontSize.large};
  font-weight: ${stylingVariables.fontWeight.medium};
  border: none;
  box-shadow: none;
  background-color: transparent;
  position: relative;
  cursor: pointer;
  padding: 0;

  transform: rotate(90deg);
`;

const StyledCloseBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${stylingVariables.colorPalette.lightGreen};
  border: none;
  cursor: pointer;
  margin-left: 5px;
`;

interface IProps {
    children?: any;
}

interface IState {
    isOpen: boolean;
}

export default class HorizontalMenuActions extends PureComponent<IProps, IState> {
    private wrapperRef: any;

    public constructor(props: IProps) {
        super(props);

        this.state = {
            isOpen: false,
        };
    }

    public componentDidMount() {
        document.addEventListener('mousedown', this.closeWhenClickOutside);
    }

    public componentWillUnmount() {
        document.removeEventListener('mousedown', this.closeWhenClickOutside);
    }

    public render() {
        return (
            <StyledActionsWrapper ref={this.setWrapperRef}>
                <StyledActionsButton onClick={this.toggle}>
                    <Icon type={constants.IconTypes.MENU}
                          width={15}
                          height={15}
                          fillColor={stylingVariables.colorPalette.green}
                    />
                </StyledActionsButton>

                <StyledActionsContentWrapper isOpen={this.state.isOpen}>
                    {this.props.children}
                    <StyledCloseBtn onClick={this.close}>
                        <Icon type={constants.IconTypes.CLOSE}
                              height={20}
                              width={20}
                              fillColor={stylingVariables.colorPalette.green}
                        />
                    </StyledCloseBtn>
                </StyledActionsContentWrapper>
            </StyledActionsWrapper>
        );
    }

    private toggle = () => {
        this.setState({isOpen: !this.state.isOpen});
    }

    public close = () => {
        this.setState({isOpen: false});
    }

    public setWrapperRef = (node: any) => {
        this.wrapperRef = node;
    }

    public closeWhenClickOutside = (event: Event) => {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.close();
        }
    }
}
