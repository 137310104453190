import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {intl} from '@global';

import {RootState} from '../../../../../rootReducer';
import {EditableField, Modal} from '@common';
import * as selectors from '../../../selectors';
import {closeSalaryPaymentGroupModal, addSalaryPaymentGroup} from '../../../redux';
import {ApiObject} from '@api';
import * as globalSelectors from '../../../../selectors';

interface IProps {
    isModalOpen: boolean;
    closeSalaryPaymentGroupModal: () => void;
    addSalaryPaymentGroup: (group: string) => void;
    groups: ApiObject.SelectOption[];

    isLoading: boolean;
}

interface IState {
    group: string;
}

export class AddPayElementGroupModal extends PureComponent<IProps, IState> {
    public constructor(props: IProps) {
        super(props);
    }

    render() {
        if (!this.props.isModalOpen || !this.props.groups || !this.props.groups.length) {
            return null;
        }

        return  (
            <>
                <Modal.CenterModal onClickClose={this.close}>
                    <Modal.StyledHeader>
                        <h4>{intl.get('add_pay_element_type')}</h4>
                    </Modal.StyledHeader>

                    <Modal.OverflowableContent padding={'20px 40px'}>
                        {!!this.props.groups && <EditableField
                                    key={'select_payment_group'}
                                    type={'dropdown'}
                                    label={intl.get('pay_element_type')}
                                    code={'add_pay_element_type'}
                                    options={this.props.groups}
                                    isRequired={true}
                                    onChange={(value: any) => this.setState({group: value})}/>
                        }
                    </Modal.OverflowableContent>

                    <Modal.StyledActionBar>
                        <Modal.StyledCancelButton onClick={this.close}>
                            {intl.get('cancel')}
                        </Modal.StyledCancelButton>

                        <Modal.StyledOkButton
                            onClick={this.onSave}
                            isDisabled={this.props.isLoading || !this.state?.group}
                        >
                            {intl.get('add')}
                        </Modal.StyledOkButton>
                    </Modal.StyledActionBar>
                </Modal.CenterModal>
            </>
        );
    }

    protected onSave = () => {
        this.props.addSalaryPaymentGroup(this.state?.group);
        this.props.closeSalaryPaymentGroupModal();
    }

    private close = () => {
        this.setState({group: ''});
        this.props.closeSalaryPaymentGroupModal();
    }
}

const mapStateToProps = (state: RootState) => ({
    isModalOpen: selectors.isAddPayElementGroupModalOpen(state),
    groups: selectors.getEnableableSalarySections(state),

    isLoading: globalSelectors.isLoading(state),
});

const mapDispatchToProps = {
    closeSalaryPaymentGroupModal,
    addSalaryPaymentGroup,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddPayElementGroupModal);
