import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import { AutoSizer } from 'react-virtualized';
import styled from 'styled-components';

import {ApiObject} from '@api';
import {InfiniteTableColumn, StyledInfiniteTable as CommonStyledInfiniteTable} from '@common';
import {interfaces, intl, stylingVariables} from '@global';
import {RootState} from '../../../../../rootReducer';
import * as selectors from '../../../selectors';

import * as Columns from './Column/index';
import {downloadDocument} from '../../../redux';

const StyledInfiniteTable = styled(props => React.cloneElement(<CommonStyledInfiniteTable {...props} />, {maxWidth: props.maxWidth}))`
    .ReactVirtualized__Table__headerColumn:last-child{
        opacity: 0;
    }
    .ReactVirtualized__Table__row{
        .ReactVirtualized__Table__rowColumn {
            background-color: ${stylingVariables.colorPalette.lightGray};
        }
    }
`;

const StyledNotFound = styled.div`
  width: 100%;
  text-align: center;
  font-size: ${stylingVariables.fontSize.largest};
  color: ${stylingVariables.colorPalette.darkGray};
  font-weight: ${stylingVariables.fontWeight.semibold};
  padding: 30px;
`;

interface IProps {
    documents: ApiObject.FileHandle[];
    downloadDocument: (id: string) => any;
    width?: any;
}

interface IState {
    orders: interfaces.ListingOrders;
}

export class Table extends PureComponent<IProps, IState> {
    public constructor(props: IProps) {
        super(props);

        this.state = {
            orders: {},
        };
    }

    public changeOrder = (column: string) => {
        let orders: interfaces.ListingOrders = {...this.state.orders};
        if (orders[column]) {
            if (orders[column].direction === ApiObject.OrderByOperator.asc) {
                orders[column].direction = ApiObject.OrderByOperator.desc;
            } else {
                orders = {};
            }
        } else {
            orders = {
                [column]: {
                    column,
                    direction: ApiObject.OrderByOperator.asc,
                },
            };
        }

        this.setState({orders});
    }

    private applyOrder = (documents: ApiObject.FileHandle[]): ApiObject.FileHandle[] => {
        const orderKeys = Object.keys(this.state.orders);
        if (orderKeys.length === 0) {
            return documents;
        }

        const column: keyof ApiObject.FileHandle = orderKeys[0] as any;
        const order: interfaces.OrderParams = this.state.orders[column];
        const asc: boolean = order.direction === ApiObject.OrderByOperator.asc;

        return documents.sort((A: ApiObject.FileHandle, B: ApiObject.FileHandle) => {
            const a: any = A[column];
            const b: any = B[column];
            if (!a) {
                return asc ? 1 : -1;
            } else if (!b) {
                return asc ? -1 : 1;
            }

            if (typeof a === 'number' || typeof b === 'number') {
                return asc ? a - b : b - a;
            }

            const collator = new Intl.Collator();

            return asc
                ? collator.compare(`${a}`, `${b}`)
                : collator.compare(`${b}`, `${a}`);
        });
    }

    get documents() {
        return this.applyOrder(this.props.documents);
    }

    get tableRealWidth() {
        const defaultColumnWidth = 150;
        const fileColumnWidth = 200;
        const actionsColumnWidth = 80;

        return  defaultColumnWidth * 4 + fileColumnWidth + actionsColumnWidth;
    }

    public render() {
        if (this.documents.length === 0) {
            return (
                <StyledNotFound>
                    {intl.get('no_document_found')}
                </StyledNotFound>
            );
        }

        return (
            <AutoSizer>
                {({width, height}) => (
                    <StyledInfiniteTable
                        rowHeight={70}
                        headerHeight={40}
                        rowGetter={({ index }: {index: number}) => this.documents[index]}
                        rowCount={this.documents.length}
                        height={height - 35}
                        width={(width > this.tableRealWidth ? width : this.tableRealWidth)}
                        maxWidth={this.props.width}
                    >
                       {Columns.File({
                            label: intl.get('file'),
                            dataKey: 'originalName',
                            orders: this.state.orders,
                            changeOrder: this.changeOrder,
                            downloadDocument: this.props.downloadDocument,
                        })}
                        {InfiniteTableColumn.Ordered({
                            label: intl.get('description'),
                            dataKey: 'description',
                            orders: this.state.orders,
                            changeOrder: this.changeOrder,
                        })}
                        {InfiniteTableColumn.Ordered({
                            label: intl.get('size'),
                            dataKey: 'size',
                            type: ApiObject.FieldType.memorySizeBytes,
                            orders: this.state.orders,
                            changeOrder: this.changeOrder,
                        })}
                        {InfiniteTableColumn.Ordered({
                            label: intl.get('upload_date'),
                            dataKey: 'updateTime',
                            type: ApiObject.FieldType.date,
                            orders: this.state.orders,
                            changeOrder: this.changeOrder,
                        })}
                        {Columns.Visibility({
                            label: intl.get('visible_in_ess'),
                            dataKey: 'visibility',
                            orders: this.state.orders,
                            changeOrder: this.changeOrder,
                        })}
                        {Columns.Actions()}
                    </StyledInfiniteTable>
                )}
            </AutoSizer>
        );
    }
}

const mapStateToProps = (state: RootState) => ({
    documents: selectors.getDocuments(state),
});

const mapDispatchToProps = {
    downloadDocument,
};

export default connect(mapStateToProps, mapDispatchToProps)(Table);
