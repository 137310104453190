import React, {PureComponent} from "react";
import styled from "styled-components";
import {constants, stylingVariables} from '@global';
import {Icon} from "@common";

const StyledWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    font-size: ${stylingVariables.fontSize.medium};
`;
const StyledCount = styled.div`
    padding: 5px;
`;
const StyledLegends = styled.div`
    display: flex;
    justify-content: space-between; 
`;
const StyledIncremented = styled.div<{color?: string}>`
    padding: 5px;
    color: ${props => props.color || stylingVariables.colorPalette.brightGreen};
`;
const StyledDecremented = styled.div<{color?: string}>`
    padding: 5px;
    color: ${props => props.color || stylingVariables.colorPalette.red};
`;

interface TotalCountWithChangesProps {
    total: number,
    added?: number,
    removed?: number,
    inline?: boolean
}

export class IncrementedNumber extends PureComponent<{number?: string|number, color?: string}> {
    public render() {
        return (
            <StyledIncremented color={this.props.color}>
                <Icon type={constants.IconTypes.FILLED_ARROW_UP}
                      width={10}
                      height={6}
                      fillColor={this.props.color || stylingVariables.colorPalette.brightGreen}
                />
                +{this.props.number}
            </StyledIncremented>
        );
    }
}

export class DecrementedNumber extends PureComponent<{number?: number, color?: string}> {
    public render() {
        return (
            <StyledDecremented color={this.props.color}>
                <Icon type={constants.IconTypes.FILLED_ARROW_DOWN}
                      width={10}
                      height={6}
                      fillColor={this.props.color || stylingVariables.colorPalette.orange}
                />
                -{this.props.number}
            </StyledDecremented>
        );
    }
}

export default class TotalCountWithChanges extends PureComponent<TotalCountWithChangesProps> {
    public render() {
        return (
            <StyledWrapper style={{flexDirection: this.props.inline ? 'row' : 'column'}}>
                <StyledCount>
                    {this.props.total}
                </StyledCount>
                <StyledLegends>
                    {this.props.added && (<IncrementedNumber number={this.props.added}/>)}
                    {this.props.removed && (<DecrementedNumber number={this.props.removed}/>)}
                </StyledLegends>
            </StyledWrapper>
        );
    }
}