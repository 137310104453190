import React, {memo, PureComponent} from 'react';
import {Column} from 'react-virtualized';
import styled from 'styled-components';
import {get} from 'lodash';

import {stylingVariables, intl} from '@global';
import {defaultInfiniteTableCellDataGetter} from '@common';
import {ColorHelper} from '@library';

interface LeaveColumnProps {
    cellData?: any;
    id: number;
    label: string;
}

const StyledCellDataWrapper = styled.div`
    display: flex;
    flex-direction: row;
    margin-left: 15px;
`;

const StyledCellData = styled.div`
    font-size: ${stylingVariables.fontSize.mediumLarge};
    font-weight: ${stylingVariables.fontWeight.medium};
    text-align: center;
    width: 35px;
    line-height: 35px;

    span {
        font-weight: ${stylingVariables.fontWeight.regular};
    }
    
    :hover {
        border-radius: 50%;
        background-color: ${stylingVariables.colorPalette.lightGray};
    }
`;

const InformationTab = styled.span`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  top: 0;
  margin-left: 55px;
  background-color: ${stylingVariables.colorPalette.white};
  min-width: 180px;
  min-height: 80px;
  border: 1px solid ${stylingVariables.colorPalette.lightGray};
  box-shadow: 0px 0px 10px 1px ${stylingVariables.colorPalette.darkGray};

  position: absolute;
  z-index: 5;
  border-radius: 5px;
  padding: 13px 18px;
  vertical-align: top;

  :after, :before {
    right: 100%;
    top: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }
  
  :after {
    border-color: transparent;
    border-right-color: #ffffff;
    border-width: 11px;
    margin-top: -11px;
  }
  :before {
    border-color: transparent;
    border-right-color: ${stylingVariables.colorPalette.lightGray};
    border-width: 13px;
    margin-top: -13px;
  }
`;

const Dot = styled.div<{label: string}>`
    width: 5px;
    height: 5px;
    margin: auto;
    border-radius: 50%;
    background-color:  ${props => ColorHelper.stringToColour(props.label)};
`;

const Label = styled.div`
    height: 30px;
    font-size: ${stylingVariables.fontSize.medium};
    font-weight: ${stylingVariables.fontWeight.bold};
    line-height: ${stylingVariables.fontSize.large};

    div:not(:first-child) {
        font-size: ${stylingVariables.fontSize.mediumSmall};
        font-weight: ${stylingVariables.fontWeight.light};
    }
`;

interface IState {
    infoVisible: boolean;
}

const CellInfo: React.FC<{
    cellData: any,
    label: string,
    infoVisible: boolean,
    onMouseOver: (event: any) => void
} & LeaveColumnProps> = memo(({cellData, label, id, infoVisible, onMouseOver}) => {
    const balance = get(cellData, `balances.${id}.ytdBalance`, null);
    const taken = get(cellData, `balances.${id}.ytdTaken`, 0);
    const remaining = balance ? balance - taken : 0;

    return (
        <StyledCellDataWrapper>
            <Dot label={label}/>
            <StyledCellData
                onMouseOver={onMouseOver}
                onMouseLeave={onMouseOver}
            >
            {balance ? remaining : taken}
            </StyledCellData>
            {infoVisible  && balance ?
                <InformationTab>
                    <div>
                        {taken} days have been used
                    </div>
                    <div>
                        {balance ? balance + ' days total' : ''}
                    </div>
                </InformationTab>
            : null
            }
        </StyledCellDataWrapper>
    );
});

class Cell extends PureComponent<LeaveColumnProps, IState> {
    public constructor(props: LeaveColumnProps) {
        super(props);

        this.state = {
            infoVisible: false
        };
    }

    private toggleAdditionalInfo = (e: any) => {
        this.setState({infoVisible: !this.state.infoVisible})
    }

    public render() {
        return (
            <CellInfo
                onMouseOver={this.toggleAdditionalInfo}
                infoVisible={this.state.infoVisible}
                cellData={this.props.cellData} id={this.props.id}
                label={this.props.label}/>
        );
    }
};

const LeaveLabel: React.FC<LeaveColumnProps> = memo(({cellData, id, label}) => {
    const balance = get(cellData, `balances.${id}.ytdBalance`, null);
    return (
        <Label>
            <div>
               {label}
            </div>
            <div>
               {balance ? intl.get('days_left') : intl.get('days_taken')}
            </div>
        </Label>
    );
});

const Leave: React.FC<LeaveColumnProps> = ({id, label}: LeaveColumnProps) => (
    <Column
        key={`leave_${id}`}
        dataKey={`leave_${id}`}
        label={<LeaveLabel id={id} label={label}/>}
        width={200}
        flexGrow={1}
        cellDataGetter={defaultInfiniteTableCellDataGetter}
        cellRenderer={({cellData}) => <Cell id={id} label={label} cellData={cellData} />}
    />
);

export default Leave;
