import {constants} from '@global';
import React, {Component, Fragment} from 'react';
import {intl} from '@global';

import {Actions, Can, Modal} from '@common';

interface ActivateProps {
    forEmployeeIds: Set<number>;
    isLoading: boolean;
    cuttOffs?: Object|null;
    hiredAfterPeriodEnd?: boolean|false;
    onClickActivate: (ids: Set<number>) => void;
}

interface ActivateState {
    hiredAfterPeriodEnd?: boolean|false;
    isOpenConfirmationModal: boolean;
    cuttOffs?: Object|null;
}

export default class Activate extends Component<ActivateProps, ActivateState> {
    public constructor(props: any) {
        super(props);
        this.state = {
            isOpenConfirmationModal: false,
            cuttOffs: props.cuttOffs,
            hiredAfterPeriodEnd: props.hiredAfterPeriodEnd
        };
    }


     shouldComponentUpdate(nextProps: ActivateProps , nextState: ActivateState) { 
        if (nextProps !== this.props || nextState !== this.state ) {
            return true;
        }
        return false;
      }

    render() {
        return (
            <Fragment>
                <Can cuttOffs={this.getMasterDataEditAllowed(this.props.cuttOffs , this.props.hiredAfterPeriodEnd)} run={constants.PermissionCode.EMPLOYEES_ACTION_ACTIVATE}>
                    <Actions.Action onClick={this.openConfirmationModal} isDisabled={this.props.isLoading}>
                        {intl.get('activate')}
                    </Actions.Action>
                </Can>

                <Modal.ConfirmationModal
                    title={'activate'}
                    confirmationMessage={'activate_confirmation'}
                    acceptBtnTitle={'activate'}
                    isOpen={this.state.isOpenConfirmationModal}
                    onClickAccept={this.activate}
                    onClickClose={this.closeConfirmationModal}
                />
            </Fragment>
        );
    }

    private getMasterDataEditAllowed = (cuttOffs: any, hiredAfterPeriodEnd: any) => {
        if (hiredAfterPeriodEnd && cuttOffs) {
            return { masterdataEditAllowed: hiredAfterPeriodEnd };
        }
        return cuttOffs;
    };

    private activate = () => {
        this.closeConfirmationModal();

        this.props.onClickActivate(this.props.forEmployeeIds);
    }

    private openConfirmationModal = () => {
        this.setState({isOpenConfirmationModal: true});
    }

    private closeConfirmationModal = () => {
        this.setState({isOpenConfirmationModal: false});
    }
}
