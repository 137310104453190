import React, {PureComponent} from 'react';
import styled from 'styled-components';
import {Icon} from '@common';
import {constants, stylingVariables} from '@global';

export const StyledCloseButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: -30px;
    top: -30px;
    width: 56px;
    height: 56px;
    border-radius: 50%;
    background-color: ${stylingVariables.colorPalette.secondary};
    cursor: pointer;
    border: none;
    outline: none;
    padding: 10px;
    transform-origin: center center;
    transition: transform 0.5s, filter 0.25s ease-out;
  
    &:hover {
        background-color: ${stylingVariables.colorPalette.secondaryHover};
        box-shadow: ${stylingVariables.layout.boxShadow};
        transition: all 0.3s;
        transform: scale(1.05);
    }
`;

interface CloseButtonProps {
    onClick: () => void;
    className?: string;
}

export default class CloseButton extends PureComponent<CloseButtonProps> {
    render() {
        return (
            <StyledCloseButton className={this.props.className} onClick={this.props.onClick}>
                <Icon type={constants.IconTypes.CLOSE}
                      width={12}
                      height={12}
                      fillColor={stylingVariables.colorPalette.white}
                />
            </StyledCloseButton>
        );
    }
}
