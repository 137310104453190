import React, {PureComponent, Fragment} from 'react';
import {intl} from '@global';
import styled from 'styled-components';

import {constants, stylingVariables} from '@global';
import {Actions as CommonActions} from '@common';
import {ColorHelper} from '@library';

interface IProps {
    label?: string;
    onOpen?: () => void;
}

interface IState {
    isOpen: boolean;
    mode: string;
}

const StyledNameWrapper = styled.span<{color: string}>`
  cursor: pointer;
  padding: 5px 0;
  font-size: ${stylingVariables.fontSize.default};
  font-weight: ${stylingVariables.fontWeight.regular};
  color: ${stylingVariables.colorPalette.primary};
  outline: none;
  &:hover, &:focus {
    text-decoration: underline;
  }
  
  :before {
      content: '';
      display: inline-block;
      vertical-align: middle;
      margin-right: 10px;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background: ${(props): string => props.color};
    }
`;

export default class ViewAction extends PureComponent<IProps, IState> {
    public constructor(props: IProps) {
        super(props);

        this.state = {
            isOpen: false,
            mode: constants.Mode.Read,
        };
    }

    render() {
        return (
            <Fragment>
                <CommonActions.Action onClick={this.open}>
                    {this.props.label && (<StyledNameWrapper color={ColorHelper.stringToColour(this.props.label)}>
                        {this.props.label}
                    </StyledNameWrapper>)}
                    {!this.props.label && intl.get('view')}
                </CommonActions.Action>
            </Fragment>
        );
    }

    private open = () => {
        this.setState({isOpen: true});
        if (this.props.onOpen) {
            this.props.onOpen();
        }
    }
}
