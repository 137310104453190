import React, {PureComponent} from 'react';
import {intl} from '@global';
import arrayMove from 'array-move';

import {Modal} from '@common';

import CheckedColumns from './CheckedColumns';
import UncheckedColumns from './UncheckedColumns';
import {SortEnd} from 'react-sortable-hoc';
import {interfaces} from '@global';

interface ColumnSettingsModalProps {
    selectedColumns: interfaces.EmployeeColumn[];
    unselectedColumns: interfaces.EmployeeColumn[];

    saveColumnSettings: (columns: string[]) => void;
    closeColumnSettingsModal: () => void;
}

interface ColumnSettingsModalState {
    selectedColumns: interfaces.EmployeeColumn[];
    unselectedColumns: interfaces.EmployeeColumn[];
}

export default class ColumnSettingsModal extends PureComponent<ColumnSettingsModalProps, ColumnSettingsModalState> {
    public constructor(props: ColumnSettingsModalProps) {
        super(props);

        this.state = {
            selectedColumns: this.props.selectedColumns,
            unselectedColumns: this.props.unselectedColumns,
        };
    }

    render() {
        return (
            <Modal.RightSideModal onClickClose={this.props.closeColumnSettingsModal}>
                <Modal.StyledHeader>
                    <h4>{intl.get('add_or_remove_columns')}</h4>
                </Modal.StyledHeader>

                <Modal.OverflowableContent padding={'20px 40px'}>
                    <CheckedColumns
                        columns={this.state.selectedColumns}
                        onSortEnd={this.reorderSelectedColumns}
                        onCheckColumn={this.unselectColumn}
                    />

                    <UncheckedColumns
                        columns={this.state.unselectedColumns}
                        onCheckColumn={this.selectColumn}
                    />
                </Modal.OverflowableContent>

                <Modal.StyledActionBar>
                    <Modal.StyledCancelButton onClick={this.props.closeColumnSettingsModal}>
                        {intl.get('cancel')}
                    </Modal.StyledCancelButton>

                    <Modal.StyledOkButton onClick={this.save}>
                        {intl.get('save')}
                    </Modal.StyledOkButton>
                </Modal.StyledActionBar>
            </Modal.RightSideModal>
        );
    }

    private save = () => {
        this.props.closeColumnSettingsModal();

        this.props.saveColumnSettings(
            this.state.selectedColumns.map(c => c.code),
        );
    }

    private reorderSelectedColumns = (sort: SortEnd) => {
        this.setState({
            selectedColumns: arrayMove(
                this.state.selectedColumns,
                sort.oldIndex,
                sort.newIndex,
            ),
        });
    }

    private selectColumn = (code: string) => {
        const selectedColumns = [...this.state.selectedColumns];
        const unselectedColumns = [...this.state.unselectedColumns];
        const index = unselectedColumns.findIndex(c => c.code === code);
        if (index !== -1) {
            selectedColumns.push(unselectedColumns[index]);
            unselectedColumns.splice(index, 1);
        }

        this.setState({selectedColumns, unselectedColumns});
    }

    private unselectColumn = (code: string) => {
        const selectedColumns = [...this.state.selectedColumns];
        const unselectedColumns = [...this.state.unselectedColumns];
        const index = selectedColumns.findIndex(c => c.code === code);
        if (index !== -1) {
            unselectedColumns.push(selectedColumns[index]);
            selectedColumns.splice(index, 1);
        }

        this.setState({selectedColumns, unselectedColumns});
    }
}
