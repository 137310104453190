import {ApiEmployee, ApiObject} from '@api';
import React, {PureComponent} from 'react';
import styled from 'styled-components';

import {stylingVariables} from '@global';
import {FormatNumbers} from '@common';
import {DateHelper} from '@library';
import {connect} from "react-redux";
import {RootState} from "../../../../../rootReducer";
import * as globalSelectors from "../../../../selectors";
import {EmployeeInterfaces} from '@api';

export const StyledFieldRow = styled.div`
    margin: 8px 0;
    font-size: ${stylingVariables.fontSize.default};
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    label{
        display: inline-block;
        width: 250px;
        line-height: 25px;
    }
    label + span{
        font-weight: bold;
        color: ${stylingVariables.colorPalette.darkest};
    }
    .react-datepicker-wrapper input{
        border: none;
        width: 100%;
        padding: 0 15px;
        font-weight: bold;
    }
`;

const StyledLabel = styled.label`
    color: ${stylingVariables.colorPalette.deepGray};
`;

interface IState {
    employeeData?: EmployeeInterfaces.ConsolidationEmployee;
}

interface IProps {
    field: ApiObject.Field;
    value: any;
    currentLegalEntityId: number | null;
    currentConsolidationGroup: any;
}

class Field extends PureComponent<IProps, IState> {
    public constructor(props: IProps) {
        super(props);

        this.state = {};
    }

    async componentDidMount() {
        if (this.props.field.code === 'manager_employee_id' &&
            this.props.field.id &&
            this.props.currentLegalEntityId &&
            this.props.value
        ) {
            const employeeData = await ApiEmployee.findConsolidationGroupEmployeeById(
                this.props.currentLegalEntityId,
                parseInt(this.props.value),
            ) || [];
            this.setState(() => ({employeeData: employeeData[0]}));
        }
    }

    async componentDidUpdate(prevProps: { value: any; }) {
        if (this.props.field.code === 'manager_employee_id' &&
            this.props.field.id &&
            this.props.currentLegalEntityId &&
            prevProps.value !== this.props.value
        ) {
           const employeeData = await ApiEmployee.findConsolidationGroupEmployeeById(
               this.props.currentLegalEntityId,
               parseInt(this.props.value),
           ) || [];
           this.setState(() => ({employeeData: employeeData[0]}));
        }
    }

    public render() {
        const label = this.props.field.label;
        return (
            <StyledFieldRow>
                <StyledLabel>{this.props.field.required ? `${label} *` : label}</StyledLabel>

                {this.renderValue(this.props.field)}
            </StyledFieldRow>
        );
    }

    private formatEmployee = (employee: any): string => {
        switch (this.props.field.defaultMssReportOutputFormat) {
            case ApiObject.MssReportOutputFormat.key:
                return `${employee.referenceCode} (${employee.legalEntityName})`;
            case ApiObject.MssReportOutputFormat.value:
                return `${[employee.firstName || employee.person?.firstName, ' ',
                    employee.lastName || employee.person?.lastName].join('').trim()} (${employee.legalEntityName})`;
            default:
                return `${employee.referenceCode} - ${[employee.firstName || employee.person?.firstName, ' ',
                    employee.lastName || employee.person?.lastName].join('').trim()} (${employee.legalEntityName})`;
        }
    }

    private renderValue(field: ApiObject.Field) {
        if(!this.props.value){
            return <span>{''}</span>;
        }
        if(this.props.value === "-"){
            return <span>-</span>;
        }
        if (field.code === 'manager_employee_id') {
            if (this.state.employeeData) {
                return <span>{this.formatEmployee(this.state.employeeData)}</span>;
            }
            return <span>{this.props.value || ''}</span>;
        }
        if (field.options) {
            const option: ApiObject.SelectOption|undefined = field.options.find((x: ApiObject.SelectOption) => {
                return x.key === this.props.value;
            });

            if (option) {
                return <span>{option.label}</span>;
            }
        }

        if (field.type === ApiObject.FieldType.date) {
            return <span>{DateHelper.convertDateToHumanFormat(this.props.value) || ''}</span>;
        }

        if (field.type === ApiObject.FieldType.number || field.type === ApiObject.FieldType.decimal ) {
            return <span>
                {!Number.isNaN(this.props.value|| null) ? <FormatNumbers value={this.props.value|| null}/> : '-'}
            </span>
        }

        return <span>{this.props.value || ''}</span>;
    }
}

const mapStateToProps = (state: RootState) => ({
    currentLegalEntityId: globalSelectors.getCurrentLegalEntityId(state),
    currentConsolidationGroup: globalSelectors.currentConsolidationGroupId(state),
});

export default connect(mapStateToProps)(Field);
