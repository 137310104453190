import React, {PureComponent} from 'react';
import {connect} from 'react-redux';

import {Switch} from '@common';
import {constants} from '@global';

import {RootState} from '../../../../../rootReducer';
import * as selectors from '../../../selectors';

import CreateModal from './Create/Modal';
import DeleteModal from './Delete/Modal';

interface IProps {
    mode: constants.Mode | null;
}

class Crud extends PureComponent<IProps> {
    public render() {
        return (
            <Switch current={this.props.mode}
                    options={new Map([
                        [
                            constants.Mode.Create,
                            <CreateModal />,
                        ],
                        [
                            constants.Mode.Delete,
                            <DeleteModal />,
                        ],
                    ])}
            />
        );
    }
}

const mapStateToProps = (state: RootState) => ({
    mode: selectors.getDocumentMode(state),
});

export default connect(mapStateToProps)(Crud);
