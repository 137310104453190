import {ApiObject} from '@api';
import {RouteHelper} from '@library';
import React, {PureComponent, Fragment} from 'react';
import {connect} from 'react-redux';
import {toast} from 'react-toastify';
import {RootState} from '../../../../rootReducer';
import {default as CommonSelectEmployees} from '../common/SelectEmployees/SelectEmployees';
import {changeTemplate, setTotalEmployees} from '../redux';
import * as selectors from '../selectors';
import {intl} from '@global';

interface IProps {
	template: ApiObject.ReportTemplate|null;
	report: ApiObject.Report|null;
	totalEmployees: number;

	changeTemplate: (template: ApiObject.ReportTemplate) => void;
	setTotalEmployees: (count: number) => void;
	reportName: string;
	listOptionsOfEmployeeGroups: ApiObject.SelectOption[];
}

interface IState {
	template: ApiObject.ReportTemplate|null;
}

export class SelectEmployees extends PureComponent<IProps, IState> {
	constructor(props: IProps) {
		super(props);

		this.state = {
			template: props.template
		}
	}

	static getDerivedStateFromProps(nextProps: IProps, prevState: IState) {
		if (nextProps.template && prevState.template === null) {
			return {
				template: nextProps.template
			}
		}

		return null;
	}

	public save = (queries: ApiObject.GrouperQueries) => {
		const template = this.props.template;

		if (!template) {
			return;
		}

		if (queries.length === 0) {
			toast.warn(intl.get('no_employees_selected'));
			return;
		}

		const updatedTemplate = {
			...template,
			employees: {
				...template.employees,
				queries
			}
		};

		this.props.changeTemplate(updatedTemplate);
	}

	public render() {
		const {template} = this.state;

		if (!template) {
			return null;
		}

		return (
			<Fragment>
				<CommonSelectEmployees
					onSave={this.save}
					onCancel={() => RouteHelper.goToReports()}
					queries={template.employees ? template.employees.queries : []}

					totalEmployees={this.props.totalEmployees}
					setTotalEmployees={this.props.setTotalEmployees}
					reportName={this.props.reportName}
					isDraftAllowed={!!this.props.report && this.props.report.legalEntityReportType !== ApiObject.DataSource.payroll}

					listOptionsOfEmployeeGroups={this.props.listOptionsOfEmployeeGroups}
				/>
			</Fragment>
		);
	}
}

const mapStateToProps = (state: RootState) => ({
	reportName: selectors.getReportName(state),
	totalEmployees: selectors.getTotalEmployees(state),
	template: selectors.getTemplate(state),
	report: selectors.getReport(state),
	listOptionsOfEmployeeGroups: selectors.getListOptionsOfEmployeeGroups(state)
});

const mapDispatchToProps = {
	changeTemplate,
	setTotalEmployees,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(SelectEmployees);
