import {PureComponent} from 'react';

interface SwitchProps {
    current: string|number|null;
    options: Map<string|number, JSX.Element>;
    default?: JSX.Element;
}

export default class Switch extends PureComponent<SwitchProps>{
    render() {
        if (!this.props.current) {
            return this.props.default || null;
        }

        if (!this.props.options.has(this.props.current)) {
            return this.props.default || null;
        }

        return this.props.options.get(this.props.current);
    }
}
