import {ApiObject} from '@api';
import React from 'react';
import {Column} from 'react-virtualized';

import {interfaces} from '@global';
import {get} from 'lodash';
import {defaultInfiniteTableCellDataGetter, InfiniteTableHeader} from '@common';

interface ColumnProps {
    dataKey: string;
    label: string;
    width?: number;
    flexGrow?: number;
    className?: string;
    type?: string;
    options?: ApiObject.SelectOption[];

    orders?: interfaces.ListingOrders;
    changeOrder?: (column: string) => any;
}

const Ordered: React.FC<ColumnProps> = ({
    className,
    dataKey,
    label,
    width = 150,
    flexGrow = 1,
    orders,
    changeOrder,
    options = [],
}: ColumnProps) => (
    <Column
        key={dataKey}
        className={className}
        dataKey={dataKey}
        width={width}
        flexGrow={flexGrow}
        cellDataGetter={defaultInfiniteTableCellDataGetter}
        headerRenderer={() => <InfiniteTableHeader.Ordered
            label={label}
            dataKey={dataKey}
            orders={orders}
            changeOrder={changeOrder}
        />}
        cellRenderer={({cellData}: any) => {
            const key = get(cellData, dataKey, null);
            return options ? (options.find(x => `${x.key}` === `${key}`) || {label: ''}).label : key;
        }}
    />
);

export default Ordered;
