import {ApiObject} from '@api';
import {Icon} from '@common';
import {interfaces, stylingVariables, constants} from '@global';
import moment from 'moment';
import React, {PureComponent} from 'react';
import styled from 'styled-components';
import EmployeeMasterdataTable from './EmployeeMasterdata/Table';
import PayElementsTable from './PayElementsTable/Table';
import { IGNORE_COLUMN_TYPES } from '../../../../api/report-template.service'

const ROW_HEIGHT = 40;
const MARGIN = 4;

const Wrapper = styled.div`
	overflow-x: auto;
	display: inline-flex;
	width: 100%;
`;

const Legends = styled.div`
	width: 100px;
	margin-right: 15px;
	font-size: ${stylingVariables.fontSize.medium};
	font-weight: ${stylingVariables.fontWeight.semibold};
	color: ${stylingVariables.colorPalette.slateGray};
	padding: 4px;
	cursor: pointer;
	white-space: nowrap;
`;

const Legend = styled.div`
	height: ${ROW_HEIGHT}px;
	margin-bottom: ${MARGIN}px;
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

const Tables = styled.div`
	display: flex;
	overflow: auto;
	padding-bottom: 20px;
	width: 100%;
`;

interface IProps {
	template: ApiObject.ReportTemplate;
	periods: interfaces.Periods;
}

export class PreviewGrid extends PureComponent<IProps> {
	get columns() {
		return this.props.template.columns.filter((column) =>
			!IGNORE_COLUMN_TYPES.includes(column.columnType as string))
	}

	getMasterDataColumnGroups = () => {
		return this.columns.filter((column) => !column.columnType);
	}

	getPayElementsColumnGroups = () => {
		return this.columns.filter((column) => column.columnType);
	}

	getLastMonth = (): string => {
		const sortedPeriods = this.props.periods.sort(
			(a: interfaces.Period, b: interfaces.Period) => a.beginDate > b.beginDate ? 1 : -1)
		return moment(
			sortedPeriods.length > 0
			? sortedPeriods[sortedPeriods.length - 1].beginDate
			: moment()
		).format('MMMM YYYY');
	}

	public render() {
		const months = [this.getLastMonth()];

		if (months.length === 0) {
			return 'Please select date range';
		}

		return (
			<Wrapper>
				<Legends>
					{['Field', 'Data Sample'].map((legend, index) =>
						<Legend key={index}> <span>{legend}</span><span>
							<Icon type={constants.IconTypes.THIN_ARROW_RIGHT} width={14} fillColor={stylingVariables.colorPalette.slateGray} />
						</span> </Legend>
					)}
				</Legends>
				<Tables>
					<EmployeeMasterdataTable
						columns={[
							...(this.getMasterDataColumnGroups())
						]}
						month={this.getLastMonth()}
					/>
					<PayElementsTable
						columns={this.getPayElementsColumnGroups()}
						months={months} />
				</Tables>
			</Wrapper>
		);
	}
}

export default PreviewGrid;
