import {EnvironmentHelper} from '@library';

export {constantsGlobal as hrb} from 'hrb-common';

export const SYSTEM_DATE_FORMAT = 'YYYY-MM-DD';
export const SYSTEM_DATE_HUMAN_FORMAT = 'MMMM DD, YYYY';
export const SYSTEM_DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';
export const SYSTEM_DATE_TIME_HUMAN_FORMAT = 'MMMM DD, YYYY HH:mm';

export const IconTypes = {
    EMPLOYEES: 'employees',
    PAYROLL: 'payroll',
    COMPANY: 'company',
    TRAVEL: 'travel',
    REPORTS: 'reports',
    CALENDAR: 'calendar',
    HELP: 'help',
    MSS: 'mss',
    SEARCH: 'search',
    SKYLINE: 'skyline',
    ARROW_UP: 'arrow-up',
    ARROW_DOWN: 'arrow-down',
    ARROW_LEFT: 'arrow-left',
    ARROW_RIGHT: 'arrow-right',
    ARROW_BACK: 'arrow-back',
    FILLED_ARROW_UP: 'filled-arrow-up',
    FILLED_ARROW_DOWN: 'filled-arrow-down',
    MENU: 'menu',
    CLOSE: 'close',
    SETTINGS: 'settings',
    FILTER: 'filter',
    SOLID_FILTER: 'solid-filter',
    CHECK_MARK: 'check-mark',
    PHONE: 'phone',
    SKYPE: 'skype',
    MAIL: 'mail',
    DEBT: 'debt',
    ADD: 'add',
    BOLD_ADD: 'bold-add',
    EXPORT: 'export',
    DOT_AND_CIRCLE: 'dot-and-circle',
    TIME_LEFT: 'time-left',
    FILE: 'file',
    WARNING: 'warning',
    INFO_CIRCLE: 'info-circle',
    FILE_PDF: 'file-pdf',
    FILE_PNG: 'file-png',
    FILE_DOC: 'file-doc',
    LOG_OUT: 'logout',
    GRID: 'grid',
    USER: 'user',
    MOVE: 'move',
    TEMPLATES: 'templates',
    FILE_DOWNLOAD: 'file-download',
    FILE_UPLOAD: 'file-upload',
    PENCIL_EDIT: 'pencil-edit',
    TRASH: 'trash',
    CLOUD_STORAGE_UPLOAD: 'cloud-storage-upload',
    KEY: 'key',
    EDRIVE: 'edrive',
    PAYSLIPS: 'payslips',
    MY_INFO: 'my-info',
    LEAVE: 'leave',
    TIME: 'time',
    EXPENSE: 'expense',
    SUPPORT: 'support',
    COOKIE: 'cookie',
    INTERVIEW: 'interview',
    DRAG: 'drag',
    DRAG_VERTICAL: 'drag-vertical',
    DASHBOARD: 'dashboard',
    EYE: 'eye',
    THIN_ARROW_RIGHT: 'thin-arrow-right',
    USER_SECONDARY: 'user-secondary',
    REVOKE: 'revoke',
    WARNING_ALERT: 'warning-alert',
};

export const ROUTE_ENTITIES = '/entities';
export const ROUTE_EMPLOYEES = '/employees';
export const ROUTE_EMPLOYEE = '/employees/:id';
export const ROUTE_LEAVES = '/leaves';
export const ROUTE_LEAVES_BALANCES = '/leaves/balances';
export const ROUTE_LOANS = '/loans';
export const ROUTE_LOAN = '/loans/:id';
export const ROUTE_REPORTS = '/reports';
export const ROUTE_REPORT_TEMPLATES = '/reports/templates';
export const ROUTE_TEMPLATES_OLD = '/templates/import';
export const ROUTE_CALENDAR = '/pac/calendar';
export const ROUTE_HELP = EnvironmentHelper.getHrbFreshDeskUrl();

export const ROUTE_REPORTS_PROCESS = '/reports/process';
export const ROUTE_REPORTS_PROCESS_SELECT_DATA = '/reports/process/fields';
export const ROUTE_REPORTS_PROCESS_SELECT_EMPLOYEES = '/reports/process/employees';
export const ROUTE_REPORTS_PROCESS_PREVIEW = '/reports/process/preview';
export const ROUTE_REPORTS_PROCESS_EXPORT = '/reports/process/export';

export const ROUTE_PAYROLL_FLOW = '/pac/payroll-flow';
export const ROUTE_PAYROLL = '/pac/payroll';
export const ROUTE_EMPLOYEES_V2 = '/pac/employees';

export const ROUTE_DOCUMENTS = '/pac/docs';

export enum EmployeeStatuses {
    active = 'active',
    inactive = 'inactive',
    draft = 'draft',
}

export enum TerminationTypes {
    details = 'details',
    payout = 'payout',
}

export const EmployeeListingFields = [
    'id',
    'referenceCode',
    'legalEntityId',
    'person.firstName',
    'person.middleName',
    'person.lastName',
    'person.formattedName',
    'person.nationality',
    'person.nationalId',
    'person.gender',
    'person.birthDate',
    'person.primaryPhoneNumber',
    'person.primaryEmail',
    'person.address.country',
    'person.address.city',
    'person.address.addressLine1',
    'person.address.addressLine2',
    'position',
    'department',
    'terminationStatus',
    'hireDate',
    'status',
    'paymentPeriodicity',
    'paymentMethodId',
    'createDate',
    'fullName',
];

export const TransactionListingFields = [
    'leaveTypeName',
    'datesTransactionCount',
    'source',
    'payrollPeriod',
];

export enum PermissionLevel {
    Active = 'Active',
    Inactive = 'Inactive',
}

export enum PermissionLevelPriority {
    Active = 20,
    Inactive = 10,
}

export enum Role {
    ROLE_VIEWER = 'ROLE_VIEWER',
    ROLE_MSS_EMPLOYEE_EDIT = 'ROLE_MSS_EMPLOYEE_EDIT',
    ROLE_MSS_LEGAL_ENTITY_EDIT = 'ROLE_MSS_LEGAL_ENTITY_EDIT',
    ROLE_MSS_FIELD_GROUP_EDIT = 'ROLE_MSS_FIELD_GROUP_EDIT',
    ROLE_MANAGER = 'ROLE_MANAGER',
    ROLE_MSS_PAYROLL_EDIT = 'ROLE_MSS_PAYROLL_EDIT',
    ROLE_MSS_PAYROLL_SUBMIT = 'ROLE_MSS_PAYROLL_SUBMIT',
    ROLE_ADVISOR = 'ROLE_ADVISOR',
    ROLE_ANALYST = 'ROLE_ANALYST',
    ROLE_SUPERVISOR = 'ROLE_SUPERVISOR',
    ROLE_PAYROLL = 'ROLE_PAYROLL',
    ROLE_FINANCE = 'ROLE_FINANCE',
    ROLE_REQUESTS_ADMIN = 'ROLE_REQUESTS_ADMIN',

    // Document app roles
    ROLE_TEMPLATE_VIEWER = 'ROLE_TEMPLATE_VIEWER',
    ROLE_TEMPLATE_EDITOR = 'ROLE_TEMPLATE_EDITOR',
    ROLE_DOCUMENT_VIEWER = 'ROLE_DOCUMENT_VIEWER',
    ROLE_DOCUMENT_CREATOR = 'ROLE_DOCUMENT_CREATOR',
}

export enum PermissionCode {
    COMPANY = 'COMPANY',
    COMPANY_ACTIONS = 'COMPANY_ACTIONS',
    COMPANY_EDIT_COST_CENTER = 'COMPANY_EDIT_COST_CENTER',
    COMPANY_EDIT_GROUPS = 'COMPANY_EDIT_GROUPS',

    EMPLOYEES = 'EMPLOYEES',
    EMPLOYEES_IMPORT = 'EMPLOYEES_IMPORT',
    EMPLOYEES_ACTIONS = 'EMPLOYEES_ACTIONS',
    EMPLOYEES_ACTION_ADD_EMPLOYEE = 'EMPLOYEES_ACTION_ADD_EMPLOYEE',
    EMPLOYEES_ACTION_SEND_ESS_INVITATION = 'EMPLOYEES_ACTION_SEND_ESS_INVITATION',
    EMPLOYEES_ACTION_REVOKE_ACCESS_TO_ESS = 'EMPLOYEES_ACTION_REVOKE_ACCESS_TO_ESS',
    EMPLOYEES_ACTION_SUSPEND = 'EMPLOYEES_ACTION_SUSPEND',
    EMPLOYEES_ACTION_ACTIVATE = 'EMPLOYEES_ACTION_ACTIVATE',
    EMPLOYEES_ACTION_ADD_OR_REMOVE_COLUMNS = 'EMPLOYEES_ACTION_ADD_OR_REMOVE_COLUMNS',

    EMPLOYEE = 'EMPLOYEE',
    EMPLOYEE_IMPORT = 'EMPLOYEE_IMPORT',
    EMPLOYEE_EXPORT = 'EMPLOYEE_EXPORT',
    EMPLOYEE_CHANGE_STATUS = 'EMPLOYEE_CHANGE_STATUS',
    EMPLOYEE_SEPARATION = 'EMPLOYEE_SEPARATION',
    EMPLOYEE_FIELDS_EDIT = 'EMPLOYEE_FIELDS_EDIT',
    EMPLOYEE_DOCUMENTS_EDIT = 'EMPLOYEE_DOCUMENTS_EDIT',

    PAYROLL = 'PAYROLL',
    PAYROLL_CURRENT_PERIOD = 'PAYROLL_CURRENT_PERIOD',
    PAYROLL_CURRENT_PERIOD_SAVE = 'PAYROLL_CURRENT_PERIOD_SAVE',
    PAYROLL_CURRENT_PERIOD_CANCEL = 'PAYROLL_CURRENT_PERIOD_CANCEL',
    PAYROLL_CURRENT_PERIOD_SUBMIT = 'PAYROLL_CURRENT_PERIOD_SUBMIT',
    PAYROLL_CURRENT_PERIOD_OPEN_PERIOD = 'PAYROLL_CURRENT_PERIOD_OPEN_PERIOD',
    PAYROLL_CURRENT_IMPORT_ONE_TIME = 'PAYROLL_CURRENT_IMPORT_ONE_TIME',
    PAYROLL_CURRENT_ADD_ONE_TIME = 'PAYROLL_CURRENT_ADD_ONE_TIME',
    PAYROLL_CURRENT_PERIOD_EXPORT = 'PAYROLL_CURRENT_PERIOD_EXPORT',
    PAYROLL_CURRENT_PERIOD_REPORTS = 'PAYROLL_CURRENT_PERIOD_REPORTS',
    PAYROLL_CURRENT_PERIOD_REVIEW_TABLE = 'PAYROLL_CURRENT_PERIOD_REVIEW_TABLE',
    PAYROLL_CURRENT_PERIOD_REVIEW_TABLE_ACTION_REJECT = 'PAYROLL_CURRENT_PERIOD_REVIEW_TABLE_ACTION_REJECT',
    PAYROLL_CURRENT_PERIOD_REVIEW_TABLE_ACTION_APPROVE = 'PAYROLL_CURRENT_PERIOD_REVIEW_TABLE_ACTION_APPROVE',
    PAYROLL_CURRENT_PERIOD_EARNINGS = 'PAYROLL_CURRENT_PERIOD_EARNINGS',
    PAYROLL_CURRENT_PERIOD_UNITS = 'PAYROLL_CURRENT_PERIOD_UNITS',
    PAYROLL_CURRENT_PERIOD_DOCUMENTS = 'PAYROLL_CURRENT_PERIOD_DOCUMENTS',
    PAYROLL_CURRENT_PERIOD_DOCUMENTS_VIEW_DOCUMENT = 'PAYROLL_CURRENT_PERIOD_DOCUMENTS_VIEW_DOCUMENT',
    PAYROLL_CURRENT_PERIOD_DOCUMENTS_ADD_FILE = 'PAYROLL_CURRENT_PERIOD_DOCUMENTS_ADD_FILE',
    PAYROLL_CURRENT_PERIOD_DOCUMENTS_ADD_INTEGRATION_FILE = 'PAYROLL_CURRENT_PERIOD_DOCUMENTS_ADD_INTEGRATION_FILE',
    PAYROLL_CLOSED_PERIOD = 'PAYROLL_CLOSED_PERIOD',
    PAYROLL_CLOSED_PERIOD_ITEM = 'PAYROLL_CLOSED_PERIOD_ITEM',
    PAYROLL_CLOSED_PERIOD_ITEM_FILES = 'PAYROLL_CLOSED_PERIOD_ITEM_FILES',

    TEMPLATES = 'TEMPLATES',
    TEMPLATES_REPORT_TEMPLATES = 'TEMPLATES_REPORT_TEMPLATES',
    TEMPLATES_REPORT_TEMPLATES_ACTION_DEFINE_NEW = 'TEMPLATES_REPORT_TEMPLATES_ACTION_DEFINE_NEW',
    TEMPLATES_REPORT_TEMPLATES_VIEW = 'TEMPLATES_REPORT_TEMPLATES_VIEW',
    TEMPLATES_REPORT_TEMPLATES_ACTION_DELETE = 'TEMPLATES_REPORT_TEMPLATES_ACTION_DELETE',
    TEMPLATES_EMPLOYEE_TEMPLATES = 'TEMPLATES_EMPLOYEE_TEMPLATES',
    TEMPLATES_EMPLOYEE_TEMPLATES_ACTION_DEFINE_NEW = 'TEMPLATES_EMPLOYEE_TEMPLATES_ACTION_DEFINE_NEW',
    TEMPLATES_EMPLOYEE_TEMPLATES_VIEW = 'TEMPLATES_EMPLOYEE_TEMPLATES_VIEW',
    TEMPLATES_EMPLOYEE_TEMPLATES_ACTION_DELETE = 'TEMPLATES_EMPLOYEE_TEMPLATES_ACTION_DELETE',
    TEMPLATES_PAYROLL_TEMPLATES = 'TEMPLATES_PAYROLL_TEMPLATES',
    TEMPLATES_PAYROLL_TEMPLATES_ACTION_DEFINE_NEW = 'TEMPLATES_PAYROLL_TEMPLATES_ACTION_DEFINE_NEW',
    TEMPLATES_PAYROLL_TEMPLATES_VIEW = 'TEMPLATES_PAYROLL_TEMPLATES_VIEW',
    TEMPLATES_PAYROLL_TEMPLATES_ACTION_DELETE = 'TEMPLATES_PAYROLL_TEMPLATES_ACTION_DELETE',

    REPORTS = 'REPORTS',
    REPORTS_REPORTS = 'REPORTS_REPORTS',
    REPORTS_REPORTS_ACTION_NEW = 'REPORTS_REPORTS_ACTION_NEW',
    REPORTS_REPORTS_VIEW = 'REPORTS_REPORTS_ACTION_VIEW',
    REPORTS_REPORTS_VIEW_ACTION_EXPORT = 'REPORTS_REPORTS_VIEW_ACTION_EXPORT',
    REPORTS_REPORTS_ACTION_DELETE = 'REPORTS_REPORTS_ACTION_DELETE',

    SETTINGS = 'SETTINGS',
    SETTINGS_PAGES = 'SETTINGS_PAGES',
    SETTINGS_PAGES_ACTION_EDIT_GROUP = 'SETTINGS_PAGES_ACTION_EDIT_GROUP',
    SETTINGS_PAGES_ACTION_SAVE_CANCEL = 'SETTINGS_PAGES_ACTION_SAVE_CANCEL',

    CALENDAR = 'CALENDAR',
    CALENDAR_EVENTS = 'CALENDAR_EVENTS',
    CALENDAR_ACTION_EXPORT = 'CALENDAR_ACTION_EXPORT',

    HELP = 'HELP',

    LEAVES = 'LEAVES',
    LEAVES_LEAVE = 'LEAVES_LEAVE',
    LEAVES_EDIT_LEAVE = 'LEAVES_EDIT_LEAVE',

    DOCUMENTS = 'DOCUMENTS',
    DOCUMENTS_TEMPLATES = 'DOCUMENTS_TEMPLATES',
}

export enum CompensationTypes {
    recurringRate = 'recurring_rates',
    recurringDeductions = 'recurring_deductions',
    recurringEarnings = 'recurring_earnings',
    recurringDeductionsHistory = 'recurring_deductions_history',
    recurringEarningsFuture = 'recurring_earnings_future',
    recurringDeductionsFuture = 'recurring_deductions_future',
    recurringEarningsHistory = 'recurring_earnings_history',
    recurringDeductionsParent = 'recurring_deductions_parent',
    recurringEarningsParent = 'recurring_earnings_parent',
    additionalEarnings = 'additional_earnings',
    additionalEarningsHistory = 'additional_earnings_history',
    additionalEarningsFuture = 'additional_earnings_future',
    additionalEarningsParent = 'additional_earnings_parent',
    additionalDeductions = 'additional_deductions',
    additionalDeductionsHistory = 'additional_deductions_history',
    additionalDeductionsFuture = 'additional_deductions_future',
    additionalDeductionsParent = 'additional_deductions_parent',
    futurePayElement = 'future_pay_element',
    futurePayElementParent = 'future_pay_element_parent',
    additionalFuturePayElement = 'additional_future_pay_element',
    additionalFuturePayElementParent = 'additional_future_pay_element_parent',
    historyPayElement = 'history_pay_element',
    historyPayElementParent = 'history_pay_element_parent',
    additionalHistoryPayElement = 'additional_history_pay_element',
    additionalHistoryPayElementParent = 'additional_history_pay_element_parent',
    calculatedPayElement = 'calculated_pay_element',
    calculatedPayElementParent = 'calculated_pay_element_parent',
    additionalUnits = 'additional_units',
    recurringUnits = 'recurring_units',
}

export const DEFAULT_FIELD_GROUP_EMPLOYEE = 'wizard_step:employee';
export const DEFAULT_FIELD_GROUP_DOCUMENTS = 'documents';
export const DEFAULT_FIELD_GROUP_DEPENDENTS = 'wizard_step:dependents';
export const DEFAULT_FIELD_GROUP_OTHER = 'other';
export const DEFAULT_FIELD_GROUP_SALARY_OLD = 'wizard_step:salary';
export const DEFAULT_FIELD_GROUP_SALARY_NEW = 'salary';
export const DEFAULT_FIELD_GROUP_LEAVE = 'leave';

export const DEFAULT_DEPENDENT_CODE = 'employee_relative';
export const DEPENDENT_FIELD_RELATIONSHIP = 'employee_relative::relationship'
export const DEFAULT_FIELD_CODE_BANK_NAME = 'bank_name';
export const DEFAULT_FIELD_CODE_SWIFT = 'swift';
export const DEFAULT_FIELD_GROUP_PAYMENT = 'wizard_step:payment';
export const DEFAULT_CODE_SECONDARY_BANK_ACCOUNT = 'secondary_bank_account';

export const CUSTOM_EMPLOYEE_TABS = [
    DEFAULT_FIELD_GROUP_SALARY_NEW,
    DEFAULT_FIELD_GROUP_LEAVE,
];

export enum Mode {
    Create = 'Create',
    Read = 'Read',
    Update = 'Update',
    Delete = 'Delete',
}

export enum HttpHeaders {
    X_SESSION_TOKEN = 'X-Session-Token',
}

export enum Modules {
    pac_leave = 'pac_leave',
    help = 'help',
    payroll = 'pac_payroll',
    employees = 'pac_employees',
    reports = 'pac_reports',
    templates = 'pac_templates',
    calendar = 'pac_calendar',
    documents = 'pac_documents',
    payrollV2 = 'pac_payroll_v2'
}

export enum DependentsColumns {
    gender = 'employee_relative::person::gender',
    relationship = 'employee_relative::relationship',
    nationality = 'employee_relative::person::nationality',
}

export enum DependentsFields {
    formatted_name = 'employee_relative::person::formatted_name',
    gender = 'employee_relative::person::gender',
    birth_date = 'employee_relative::person::birth_date',
    school_type = 'employee_relative::school_type',
    working = 'employee_relative::working',
    relationship = 'employee_relative::relationship',
    nationality = 'employee_relative::person::nationality',
    national_id = 'employee_relative::person::national_id',
    marital_status = 'employee_relative::person::marital_status',
}

export enum ReportColumnGroupType {
    masterdata = 'masterdata',
    payElement = 'periodic',
}

export enum ReportProcessMode {
    createReport = 'createReport',
    editReport = 'editReport',
    viewReport = 'viewReport',
    generateReport = 'generateReport',
    createTemplate = 'createTemplate',
    editTemplate = 'editTemplate',
    viewTemplate = 'viewTemplate'
}

export const defaultGetReportAttempts: number[] = [
    ...(new Array(20).fill(200)), // 4 seconds for high checking
    ...(new Array(20).fill(500)), // 10 seconds for normal checking
    ...(new Array(46).fill(1000)), // 46 seconds for slowly checking
    ...(new Array(54).fill(10000)), // 9 minutes
]; // In summary we check 10 minute

export enum ModuleCodes {
  pac_employees_v2 = 'pac_employees_v2',
}
