import {ApiObject} from '@api';
import {interfaces, stylingVariables, constants} from '@global';
import moment from 'moment';
import React, {PureComponent} from 'react';
import {AutoSizer} from 'react-virtualized';
import {connect} from 'react-redux';

import {intl} from '@global';
import {
    StyledInfiniteTable as CommonStyledInfiniteTable,
    InfiniteTableColumn,
    InfiniteTableNoRows,
    InfiniteTableLoader,
} from '@common';
import {getAllTemplates} from '../../actions'
import {openReportExportProcess, exportReport} from '../../Process/redux'
import {changeFilter, changeOrder, clearOldAndGetReports, setOrders} from '../redux';

import * as selectors from '../selectors';
import * as globalSelectors from '../../../selectors';
import {RootState} from '../../../../rootReducer';
import styled from 'styled-components';

import * as Column from './Column';
import { AnyAction } from 'redux';

const StyledInfiniteTable = styled(CommonStyledInfiniteTable)`
    .ReactVirtualized__Table__headerRow{
        overflow: visible!important;
        .ReactVirtualized__Table__headerColumn:first-child {
            text-align: center;
        }
        .ReactVirtualized__Table__headerColumn {
            padding: 5px 10px;
            &:first-child {
              padding-left: 20px;
            }
        }
        .ReactVirtualized__Table__headerColumn:nth-last-child(2) {
            border-right: none;
        }
    }

    .ReactVirtualized__Table__row{
        .ReactVirtualized__Table__rowColumn {
            padding: 5px 10px;
            &:first-child {
              padding-left: 20px;
            }
        }
        
        &.highlighted, &:hover {
            cursor: pointer;
            .ReactVirtualized__Table__rowColumn {
                background-color: ${stylingVariables.hrb.colorPalette.lightGreen}; 
                border-top: 1px solid ${stylingVariables.hrb.colorPalette.green}; 
                border-bottom: 1px solid ${stylingVariables.hrb.colorPalette.green}; 
                &:first-child{
                    border-left: 1px solid ${stylingVariables.hrb.colorPalette.green}; 
                } 
                &:last-child{
                    border-right: 1px solid ${stylingVariables.hrb.colorPalette.green}; 
                }
                .checkbox {
                  display: flex;
                }
                .index {
                  display: none;
                }
            }
        }
    }
`;

interface IProps {
    isLoading: boolean;
    isMenuCollapsed: boolean;

    orders: interfaces.ListingOrders;
    filters: interfaces.ListingFilters;
    setOrders: (orders?: interfaces.ListingOrders) => AnyAction
    changeOrder: (column: string) => any;
    changeFilter: (params: interfaces.FilterParams) => any;
    filterSearch: string;

    clearOldAndGetReports: () => void;
    getAllTemplates: () => Promise<ApiObject.ReportTemplate[]>;
    reports: ApiObject.Report[];

    openReportExportProcess: (report: ApiObject.Report, mode?: constants.ReportProcessMode) => void;
    exportReport: (id: number, fileName?: string) => void;
}

interface IState {
    templates: ApiObject.ReportTemplate[];
    listTemplates: ApiObject.SelectOption[];
}

export class Table extends PureComponent<IProps, IState> {
    private readonly NON_CLICKABLE_COLUMNS = [
        'actions',
		];

    constructor(props: IProps) {
        super(props);

        this.state = {
            templates: [],
            listTemplates: []
        }
    }

     componentDidMount = async () => {
        this.props.clearOldAndGetReports();
        const templates = await this.props.getAllTemplates()
        this.setState({
            templates,
            listTemplates: templates.map((t: ApiObject.ReportTemplate) => ({key: `${t.id}`, label: t.label}))
        }, () => {
            this.props.setOrders({createdAt: {column: 'createdAt', direction: ApiObject.OrderByOperator.asc}})
        });
    }

    get reports() {
        const {filters, orders, filterSearch} = this.props;

        const sortByKey: string = Object.keys(orders).length > 0 ? Object.keys(orders)[0] : 'id';
        const sortAsc = orders[sortByKey] ? orders[sortByKey].direction === ApiObject.OrderByOperator.asc : false;

        const filterBySearch = (report: ApiObject.Report) => report.label.toLocaleLowerCase().includes(filterSearch.toLocaleLowerCase());
        const filterByTemplateId = (report: ApiObject.Report) => {
            if (!filters.templateId) {
                return true;
            }

            return +(report.templateId||0) === +filters.templateId.value;
        }
        const filterByCreationDate = (report: ApiObject.Report) => {
            if (!filters.createdAt) {
                return true;
            }

            const [from, till] = filters['createdAt'].value;

            return moment(report.createdAt) < moment(till) &&
                moment(report.createdAt) > moment(from);
        }
        const filterReportType = (report: ApiObject.Report) => {
            if (!filters.legalEntityReportType) {
                return true;
            }

            return report.legalEntityReportType === filters.legalEntityReportType.value;
        }
        const sort = (a: ApiObject.Report,b: ApiObject.Report) => {
            const collator = new Intl.Collator();
            if (sortAsc) {
                return collator.compare(`${b[sortByKey as keyof ApiObject.Report]}`, `${a[sortByKey as keyof ApiObject.Report]}`);
            }

            return collator.compare(`${a[sortByKey as keyof ApiObject.Report]}`, `${b[sortByKey as keyof ApiObject.Report]}`);
        }

        return this.props.reports
            .filter(filterBySearch)
            .filter(filterByCreationDate)
            .filter(filterByTemplateId)
            .filter(filterReportType)
            .sort(sort);
    }

    public render() {
        return (
            <AutoSizer>
                {({width, height}) => (
                    <StyledInfiniteTable
                        rowHeight={80}
                        headerHeight={40}
                        rowGetter={({ index }: {index: number}) => this.reports[index]}
                        rowCount={this.reports.length}
                        height={height - 180}
                        width={width}
                        isMenuCollapsed={this.props.isMenuCollapsed}
                        noRowsRenderer={this._renderNoRows}
                        onRowClick={({rowData}: any) => this.props.openReportExportProcess(rowData, constants.ReportProcessMode.viewReport)}
                        onColumnClick={({ dataKey, event }: any) => {
                            if (this.NON_CLICKABLE_COLUMNS.includes(dataKey)) {
                                event.stopPropagation();
                            }
                        }}
                    >
                        {InfiniteTableColumn.Ordered({
                            label: intl.get('report_name'),
                            dataKey: 'label',
                            width: 220,
                            style: {
                                color: stylingVariables.colorPalette.green
                            },
                            orders: this.props.orders,
                            changeOrder: this.props.changeOrder
                        })}
                        {Column.ReportCreationDateAndUser({
                            label: intl.get('report_generation_date') + ' & ' + intl.get('user'),
                            width: 120,
                            orders: this.props.orders,
                            filters: this.props.filters,
                            changeOrder: this.props.changeOrder,
                            changeFilter: this.props.changeFilter
                        })}
                        {Column.ReportTemplate({
                            label: intl.get('report_template'),
                            width: 120,
                            orders: this.props.orders,
                            filters: this.props.filters,
                            changeOrder: this.props.changeOrder,
                            changeFilter: this.props.changeFilter,
                            listTemplates: this.state.listTemplates
                        })}
                        {Column.DataSource({
                            label: intl.get('data_source'),
                            width: 160,
                            filters: this.props.filters,
                            orders: this.props.orders,
                            changeFilter: this.props.changeFilter,
                            changeOrder: this.props.changeOrder,
                        })}
                        {Column.Status({
                            label: intl.get('report_status'),
                            width: 100,
                        })}
                        {Column.Actions({
                            onClickExport: this.onExport,
                            templates: this.state.templates
                        })}
                    </StyledInfiniteTable>
                )}
            </AutoSizer>
        );
    }

    public onExport = (report: ApiObject.Report): void => {
        this.props.exportReport(report.id)
    }

    private _renderNoRows = () => {
        if (this.props.isLoading) {
            return (<InfiniteTableLoader/>);
        }

        return (
            <InfiniteTableNoRows text={intl.get('no_reports_found')}/>
        );
    }
}

const mapStateToProps = (state: RootState) => ({
    isLoading: globalSelectors.isLoading(state),
    isMenuCollapsed: globalSelectors.isMenuCollapsed(state),

    filterSearch: selectors.getFilterSearch(state),
    reports: selectors.getReports(state),
    orders: selectors.getOrders(state),
    filters: selectors.getFilters(state),
});

const mapDispatchToProps = {
    setOrders,
    clearOldAndGetReports,
    changeFilter,
    changeOrder,
    getAllTemplates,
    openReportExportProcess,
    exportReport,
};

export default connect(mapStateToProps, mapDispatchToProps)(Table);
