import React, {PureComponent} from 'react';
import {intl} from '@global';
import styled from 'styled-components';

import {stylingVariables} from '@global';
import {DateHelper} from '@library';
import {Layout} from '@common';
import {EmployeeInterfaces} from '@api';

const StyledWrapper = styled.div<{isCollapsed: boolean}>`
  flex-grow: 0;
  flex-basis: 280px;
  width: 365px;
  min-width: 365px;
  max-width: 365px;
  border-radius: ${stylingVariables.layout.cardBorderRadius};
  background: ${stylingVariables.colorPalette.white};
  border-radius: ${stylingVariables.layout.borderRadius};
  box-shadow: ${stylingVariables.layout.boxShadowLight};
  ${Layout.RowValue} {
    text-transform: capitalize;
  }
  
  ${props => props.isCollapsed && `
    &, span {
      font-size: 12px!important;
    }
    flex-basis: 100px;
    width: 100px;
    min-width: 100px;
    max-width: 100px;
  `}
`;

const StyledHeader = styled.div`
  background-color: ${stylingVariables.colorPalette.orange};
  color: ${stylingVariables.colorPalette.white};
  font-weight: ${stylingVariables.fontWeight.regular};
  font-size: ${stylingVariables.fontSize.mediumLarge};
  border-radius: ${stylingVariables.layout.borderRadius} ${stylingVariables.layout.borderRadius} 0 0;
  padding: 10px 15px;
`;

const StyledBody = styled.div<{isCollapsed: boolean}>`
  padding: 20px 15px;
  ${props => props.isCollapsed && `
    div > div {
      display: flex;
      justify-content: center;
    }
  `}
`;

interface IProps {
    employee: EmployeeInterfaces.Employee;
    isCollapsed: boolean;
}

export default class EosReason extends PureComponent<IProps> {
    public render() {
        const {isCollapsed} = this.props;
        const {eosReason, lastWorkingDate, eosHireDate} = this.props.employee;
        if (!eosReason) {
            return null;
        }

        return (
            <StyledWrapper isCollapsed={isCollapsed}>
                <StyledHeader>{intl.get('employee_separated')}</StyledHeader>

                <StyledBody isCollapsed={isCollapsed}>
                    <Layout.Rows>
                        <Layout.Row>
                            {!isCollapsed &&
                                <Layout.RowLabel percent={40}>
                                    {intl.get('reason')}
                                </Layout.RowLabel>
                            }
                            <Layout.RowValue>{eosReason}</Layout.RowValue>
                        </Layout.Row>

                        {
                            eosHireDate &&
                            <Layout.Row>
                                {!isCollapsed &&
                                    <Layout.RowLabel percent={40}>
                                        {intl.get('hire_date')}
                                    </Layout.RowLabel>
                                }
                                <Layout.RowValue>
                                    {DateHelper.convertDateToHumanFormat(eosHireDate)}
                                </Layout.RowValue>
                            </Layout.Row>
                        }

                        {
                            lastWorkingDate &&
                            <Layout.Row>
                                {!isCollapsed &&
                                    <Layout.RowLabel percent={40}>
                                        {intl.get('last_working_date')}
                                    </Layout.RowLabel>
                                }
                                <Layout.RowValue>
                                    {DateHelper.convertDateToHumanFormat(lastWorkingDate)}
                                </Layout.RowValue>
                            </Layout.Row>
                        }
                    </Layout.Rows>
                </StyledBody>
            </StyledWrapper>
        );
    }
}
