import {constants} from '@global';
import React, {PureComponent, Fragment} from 'react';
import {intl} from '@global';

import {Actions, Can} from '@common';

import InvitationModal from './InvitationModal';

export interface Invitation {
    id: number;
    fullName: string;
    loginUsername: string;
    validationError: string|null;
}

interface IProps {
    forEmployeeIds: Set<number>;
    defaultWelcomeMessage: string;
    defaultInvitations: Map<number, Invitation>;
    isLoading: boolean;

    onClickSend: (payload: any) => void;
}

interface IState {
    isOpenModal: boolean;
}

export default class SendEssInvitation extends PureComponent<IProps, IState> {
    public constructor(props: IProps) {
        super(props);

        this.state = {
            isOpenModal: false,
        };
    }

    render() {
        return (
            <Fragment>
                <Can run={constants.PermissionCode.EMPLOYEES_ACTION_SEND_ESS_INVITATION}>
                    <Actions.Action onClick={this.openModal} isDisabled={this.props.isLoading}>
                        {intl.get('send_ess_invitation')}
                    </Actions.Action>
                </Can>

                {
                    this.state.isOpenModal &&
                    <InvitationModal
                        forEmployeeIds={this.props.forEmployeeIds}
                        defaultWelcomeMessage={this.props.defaultWelcomeMessage}
                        defaultInvitations={this.props.defaultInvitations}
                        sendEssInvitation={this.props.onClickSend}
                        onClickCloseModal={this.closeModal}
                    />
                }
            </Fragment>
        );
    }

    private openModal = () => {
        this.setState({isOpenModal: true});
    }

    private closeModal = () => {
        this.setState({isOpenModal: false});
    }
}
