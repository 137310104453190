import { get, last, orderBy } from 'lodash'
import { createSelector } from 'reselect'
import moment from 'moment'
import {constants, interfaces, intl} from '@global'
import { ApiObject, EmployeeInterfaces } from '@api'
import { AccessManager } from '@library'

import { CompensationTypes, SYSTEM_DATE_FORMAT } from '../../constants'
import { RootState } from '../../rootReducer'
import { SalaryTabType } from './redux'
import { getCurrentLegalEntity, getUsingDefaultLanguage } from '../selectors'

const employeePayElementTypes = [
  CompensationTypes.recurringEarnings,
  CompensationTypes.recurringDeductions,
  CompensationTypes.additionalUnits,
  CompensationTypes.recurringUnits,
  CompensationTypes.additionalEarnings,
  CompensationTypes.additionalDeductions,
]

export const getEmployeeGroups = (state: RootState): interfaces.EmployeeGroups => {
  return state.global.employeeGroups
}

export const getEmployee = createSelector([(state: RootState) => state.employee], ({ employee , cutOffStatuses }) => {
  if (!employee) {
    return null
  }

  employee.fullName = [get(employee, 'person.firstName', ''), get(employee, 'person.lastName', '')].join(' ').trim()
  if (cutOffStatuses && cutOffStatuses[employee.id]){
    employee.cuttOffs = cutOffStatuses[employee.id]
  }

  return employee
})
export const getCurrentEmployeeId = (state: RootState): any => state.employee.id
export const getDocuments = (state: RootState): any => state.employee.documents
export const getSalaryEnabledPaymentGroups = (state: RootState): any => state.employee.salaryGroups
export const getDocumentMode = (state: RootState): any => state.employee.document.mode
export const getDocument = createSelector(
  [(state: RootState) => state.employee.documents, (state: RootState) => state.employee.document.id],
  (documents: ApiObject.FileHandle[], id: string | null): ApiObject.FileHandle | undefined => {
    if (!id) {
      return
    }
    return documents.find((document: ApiObject.FileHandle) => document.id === id)
  }
)

export const getFields = createSelector(
  [(state: RootState) => state.employee.fields],
  (fields: ApiObject.FieldEntity[]): ApiObject.Fields => {

    const formatted = fields.reduce(
      (accumulator: ApiObject.Fields, field) => {
        accumulator[field.entity] = accumulator[field.entity] || {}
        if (field.values) {
            for (const value of field.values) {
              accumulator[field.entity][value.code] = value.value
            }
        }

        return accumulator
      },
      { computed: {} } as ApiObject.Fields
    )

    formatted.computed.fullName = [get(formatted, 'person.first_name', ''), get(formatted, 'person.last_name', '')]
      .join(' ')
      .trim()

    return formatted
  }
)

export const getFieldGroups = createSelector(
  [(state: RootState) => state.employee.fieldGroups, getUsingDefaultLanguage],
  (groups: ApiObject.FieldGroup[], useLocalName: boolean) => {
    const groupWithLocaleNames = (group: ApiObject.FieldGroup): ApiObject.FieldGroup => {

      return {
        ...group,
        name: getLocalName(group, useLocalName),
        fields: group.fields.map(
          function(field) {
            return formatOptions(field, useLocalName)
          }
        ),
        subgroups: group.subgroups
          ? group.subgroups.map(subgroup => groupWithLocaleNames(subgroup))
          : group.subgroups
      }
    }

    return groups.map((group) => {
      return groupWithLocaleNames(group)
    })
  }
)

const formatOptions = (field: ApiObject.Field, useLocalName: boolean) => {
  if (useLocalName && field.localLabel) {
    field.label = field.localLabel
  }

  if (!field.options) {
    return field
  }

  const sortByKey =
    field.defaultMssReportOutputFormat === ApiObject.MssReportOutputFormat.key_value ||
    field.defaultMssReportOutputFormat === ApiObject.MssReportOutputFormat.key

  field.options = field.options
    .map((option) => {
      if (useLocalName && option.localLabel) {
        option.label = option.localLabel
      }

      switch (field.defaultMssReportOutputFormat) {
        case ApiObject.MssReportOutputFormat.key:
          option.label = option.displayKey || option.key
          break
        case ApiObject.MssReportOutputFormat.key_value:
          option.label = option.displayKey
            ? `${option.displayKey} - ${option.label}`
            : `${option.key} - ${option.label}`
          break
      }


      return option
    })
    .sort((a, b) => (sortByKey ? a.key.localeCompare(b.key) : a.label.localeCompare(b.label)))

  return field
}

export const getTabs = createSelector(
  [getFieldGroups, getCurrentLegalEntity, getUsingDefaultLanguage],
  (groups: ApiObject.FieldGroup[], legalEntity: ApiObject.LegalEntity | null, useLocalName: boolean) => {
    const orderedGroups =
      groups && groups.length > 0
        ? groups.sort((a: ApiObject.FieldGroup, b: ApiObject.FieldGroup) => a.ordinal - b.ordinal)
        : []

    const tabs = orderedGroups.map((group: ApiObject.FieldGroup) => {
      const name = getLocalName(group, useLocalName)
      return { code: group.code, name }
    })

    const salaryGroup = tabs.find((group) => group.code === constants.DEFAULT_FIELD_GROUP_SALARY_OLD)
    if (salaryGroup !== undefined) {
      salaryGroup.code = constants.DEFAULT_FIELD_GROUP_SALARY_NEW
    } else {
      tabs.push({ code: constants.DEFAULT_FIELD_GROUP_SALARY_NEW, name: 'Salary' })
    }

    if (AccessManager.hasModule(legalEntity, constants.Modules.pac_leave)) {
      tabs.push({ code: constants.DEFAULT_FIELD_GROUP_LEAVE, name: 'Leave' })
    }

    return tabs
  }
)

export const getPayElements = (state: RootState) => state.employee.payElements
export const getEmployeePayElements = (state: RootState) => state.employee.employeePayElements

export const getLocalPayElements = createSelector(
  [getPayElements, getUsingDefaultLanguage],
  (payElements: ApiObject.PayElement[], useLocalName: boolean) => {
    const payElementsWithLocalNames = (payElement: ApiObject.PayElement): ApiObject.PayElement => {
      return {
        ...payElement,
        name: getLocalName(payElement, useLocalName)

      }
    }

    return payElements.map((payElement) => {
      return payElementsWithLocalNames(payElement)
    })
  }
)

const getLocalName = (item: any, useLocalName: boolean) => {
  if (!useLocalName || !item.localName) {
    return item.name
  } else {
   return item.localName
  }
}

export const getPeriodPayElements = (state: RootState) => state.employee.periodPayElements

export const getCurrencies = createSelector([getCurrentLegalEntity], (legalEntity: ApiObject.LegalEntity | null) => {
  if (legalEntity === null) {
    return []
  }
  return legalEntity.currencies.map((currency) => {
    return { key: currency, label: currency }
  })
})

const getPayElementCompensationType = (payElement: ApiObject.PayElement) => {
  if (payElement.type === undefined || payElement.occurrence === undefined) {
    return undefined
  }
  const compensationType = `${payElement.occurrence}_${payElement.type}s` as CompensationTypes

  if (compensationType === CompensationTypes.recurringRate) {
    return CompensationTypes.recurringEarnings
  }

  return compensationType
}

const groupEmployeePayElementsByType = (payElementMap: any, employeePayElements: any) => {
  const result: any = {}
  employeePayElements.forEach((employeePayElement: any) => {
    const payElement: ApiObject.PayElement = payElementMap[employeePayElement.account]
    if (!payElement) {
      return
    }

    const compensationType = getPayElementCompensationType(payElement)
    if (compensationType !== undefined) {
      if (!result[compensationType]) {
        result[compensationType] = [employeePayElement]
      } else {
        result[compensationType].push(employeePayElement)
      }
    }
  })
  return result
}

const prepareEmployeePayElements = (
  employeePayElement: ApiObject.EmployeePayElement,
  payElement: ApiObject.PayElement
): EmployeeInterfaces.EmployeePayElementDisplay[] | null => {
  if (!employeePayElement || !payElement) {
    return null
  }

  let childrenAmounts: any[]
  childrenAmounts = []
  const lastChange = employeePayElement.changes ? last(orderBy(employeePayElement.changes, 'startDate')) : null

  const elements = lastChange ? [employeePayElement, lastChange] : [employeePayElement]
  let parentPresent = true
  const payElements = elements.map((epElement, index): EmployeeInterfaces.EmployeePayElementDisplay | null => {
    if (epElement.amount === null) {
      parentPresent = false
      return null
    }

    if (epElement.amount !== 0) {
      childrenAmounts.push(epElement.amount)
    }

    const account = employeePayElement.account
    const rootEmployeePayElementId = employeePayElement.rootEmployeePayElementId

    const label = payElement.name ? `${payElement.account} - ${payElement.name}` : `${payElement.account}`
    const payElementName = payElement.name ? payElement.name : `${payElement.account}`

    let payUnitName = epElement.payUnit
    let currencyName = epElement.currency
    if (payUnitName === ApiObject.PayUnitType.percentage) {
      payUnitName = ApiObject.PayUnitType.month
      currencyName = ApiObject.PayUnitType.percentage
    }

    if (payUnitName === ApiObject.PayUnitType.annual) {
      payUnitName = ApiObject.PayUnitType.per_annual
    }

    if (payUnitName === ApiObject.PayUnitType.amount) {
      payUnitName = ApiObject.PayUnitType.per_amount
    }

    const currencyAndFrequency = epElement.payUnit
      ? `${currencyName} / ${payUnitName && intl.get(payUnitName) ? intl.get(payUnitName) : payUnitName}`
      : currencyName

    let annualAmount = null
    if (epElement.amount && epElement.payUnit) {
      annualAmount = getAnnualAmountByPeriod(epElement.payUnit, epElement.amount)
    }

    const childElement = parentPresent ? index !== 0 : index !== 1
    const allowEdit = elements.length === 1 || childElement || !parentPresent

    return {
      ...epElement,
      rootEmployeePayElementId,
      account,
      label,
      payElementName,
      currencyAndFrequency,
      annualAmount,
      allowEdit,
      childElement,
    }
  }) as EmployeeInterfaces.EmployeePayElementDisplay[]
  return childrenAmounts.length > 0 ? payElements : []
}

const prepareHistoryElement = (
  employeePayElement: ApiObject.EmployeePayElement | EmployeeInterfaces.PayElementWithPeriod,
  payElement: ApiObject.PayElement
): EmployeeInterfaces.EmployeePayElementHistory | null => {
  if (!employeePayElement || !payElement) {
    return null
  }

  if (employeePayElement.amount === null) {
    return null
  }

  const label = payElement.name ? `${payElement.account} - ${payElement.name}` : `${payElement.account}`

  let period = 'Current'
  if ((employeePayElement as EmployeeInterfaces.PayElementWithPeriod).period) {
    period = (employeePayElement as EmployeeInterfaces.PayElementWithPeriod).period.name
  }

  return {
    label,
    currency: employeePayElement.currency!,
    amount: employeePayElement.amount!,
    startDate: employeePayElement.startDate,
    endDate: employeePayElement.endDate,
    period,
    childElement: false,
    rootEmployeePayElementId: employeePayElement.rootEmployeePayElementId,
    payUnit: payElement.payUnit,
    source: employeePayElement.source,
  }
}

// Groups and processes employee and period pay elements
export const getEmployeeSalarySections = createSelector(
  [getEmployeePayElements, getLocalPayElements, getPeriodPayElements],
  (
    employeePayElements: ApiObject.EmployeePayElement[],
    payElements: ApiObject.PayElement[] = [],
    periodPayElements: EmployeeInterfaces.PeriodWithPayElements[] = []
  ): EmployeeInterfaces.SalarySectionData[] => {

    const payElementMap: any = {}
    payElements.forEach((payElement) => {
      if (payElement.account !== undefined) {
        payElementMap[payElement.account] = payElement
      }
    })

    const currentEmployeePayElements = groupEmployeePayElementsByType(payElementMap, employeePayElements)

    const historyElementsMap: {
      [rootElementId: number]: (EmployeeInterfaces.PayElementWithPeriod | ApiObject.EmployeePayElement)[]
    } = {}
    employeePayElements.forEach((employeePayElement) => {
      if (!historyElementsMap[employeePayElement.rootEmployeePayElementId]) {
        historyElementsMap[employeePayElement.rootEmployeePayElementId] = [employeePayElement]
      } else {
        historyElementsMap[employeePayElement.rootEmployeePayElementId].push(employeePayElement)
      }
    })

    // Reverse periods first, so latest elements come first
    periodPayElements
      .slice()
      .reverse()
      .forEach((period) => {
        period.payElements.forEach((payElement) => {
          const periodPayElement: EmployeeInterfaces.PayElementWithPeriod = {
            ...payElement,
            period,
          }
          if (!historyElementsMap[periodPayElement.rootEmployeePayElementId]) {
            historyElementsMap[periodPayElement.rootEmployeePayElementId] = [periodPayElement]
          } else {
            historyElementsMap[periodPayElement.rootEmployeePayElementId].push(periodPayElement)
          }
        })
      })

    const historicEmployeePayElements = groupEmployeePayElementsByType(
      payElementMap,
      Object.values(historyElementsMap).flat(1)
    )

    return employeePayElementTypes.map((compensationType) => {
      const employeePayElements = (currentEmployeePayElements[compensationType] ?? [])
        .sort((a: { account: any; startDate: any }, b: { account: any; startDate: any }) => {
          const collator = new Intl.Collator()
          const nameSort = collator.compare(`${a.account}`, `${b.account}`)
          return nameSort === 0 ? collator.compare(`${a.startDate}`, `${b.startDate}`) : nameSort
        })
        .flatMap((e: ApiObject.EmployeePayElement) => {
          return prepareEmployeePayElements(e, payElementMap[e.account])
        })
        .filter((x: EmployeeInterfaces.EmployeePayElementDisplay) => {
          return x !== null && (!x.noChange || x.noChange !== true) && x.status !== 'rejected'
        })

      const historyElementsByRoot: { [rootId: number]: EmployeeInterfaces.EmployeePayElementHistory[] } = {}
      const historyPayElements = (historicEmployeePayElements[compensationType] ?? [])
        .map((e: ApiObject.EmployeePayElement | EmployeeInterfaces.PayElementWithPeriod) => {
          return prepareHistoryElement(e, payElementMap[e.account])
        })
        .filter((e: EmployeeInterfaces.EmployeePayElementHistory | null) => e !== null)
      historyPayElements.forEach((element: EmployeeInterfaces.EmployeePayElementHistory) => {
        const elements = historyElementsByRoot[element.rootEmployeePayElementId]
        if (!elements) {
          historyElementsByRoot[element.rootEmployeePayElementId] = [element]
        } else {
          elements.push(element)
        }
      })

      let childrenAmounts: any[]
      childrenAmounts = []
      const historyElements = Object.values(historyElementsByRoot).flatMap((elements) => {
        const result = []
        let latestWithSameAmount: EmployeeInterfaces.EmployeePayElementHistory = elements[0]
        for (let i = 0; i < elements.length; i++) {
          const element = elements[i]
          if (i === 0) {
            latestWithSameAmount = element
            result.push(element)
            if (element.amount !== 0) {
              childrenAmounts.push(element.amount)
            }
          } else if (latestWithSameAmount.amount !== element.amount) {
            if (latestWithSameAmount.startDate !== element.startDate) {
              element.endDate = moment(latestWithSameAmount.startDate).subtract(1, 'day').format(SYSTEM_DATE_FORMAT)
              element.childElement = true
              latestWithSameAmount = element
              result.push(element)
              if (element.amount !== 0) {
                childrenAmounts.push(element.amount)
              }
            }
          }
        }
        return result
      })

      return {
        code: compensationType,
        label: intl.get(compensationType),
        payElements: employeePayElements,
        historyElements: childrenAmounts.length > 0 ? historyElements : [],
      }
    })
  }
)

export const getEnableableSalarySections = createSelector(
  [getEmployeeSalarySections, getSalaryEnabledPaymentGroups],
  (sections: EmployeeInterfaces.SalarySectionData[], enabledGroups: string[]): ApiObject.SelectOption[] => {
    return sections
      .filter(
        (x) => enabledGroups.indexOf(x.code) === -1 && x.payElements.length === 0 && x.historyElements.length === 0
      )
      .map((x) => {
        return {
          key: x.code,
          label: x.label,
        }
      })
  }
)

export const getAnnualAmountByPeriod = (payUnit: ApiObject.PayUnitType | string, amount: number) => {
  switch (payUnit) {
    case ApiObject.PayUnitType.annual:
      return amount
    case ApiObject.PayUnitType.quarter:
      return amount * 4
    case ApiObject.PayUnitType.month:
      return amount * 12
    case ApiObject.PayUnitType.week:
      return amount * 52
    case ApiObject.PayUnitType.biweek:
      return amount * 26
    case ApiObject.PayUnitType.day:
      return null
    case ApiObject.PayUnitType.hour:
      return null
    case ApiObject.PayUnitType.unit:
      return null
    case ApiObject.PayUnitType.amount:
      return null
    case ApiObject.PayUnitType.percentage:
      return null
    default:
      return null
  }
}

export const getSalaryPaymentGroupModal = (state: RootState) => state.employee.salaryPaymentGroupModal
export const isAddPayElementGroupModalOpen = createSelector(
  [getSalaryPaymentGroupModal],
  (settings) => settings.isModalOpen
)

export const getSalaryTabType = (state: RootState): any => state.employee.salary.tabType

export const getErrors = (state: RootState): any => state.employee.errors
export const getErrorsFields = (state: RootState): ApiObject.EntityFieldErrorMessage[] => state.employee.errors.fields
export const getErrorByEntityAndCode = (errors: ApiObject.EntityFieldErrorMessage[], entity: string, code: string) => {
  if (!errors) {
    return null
  }

  const errorMessage = errors.find((e) => e.field === code && e.entity === entity)

  return errorMessage ? errorMessage.message : null
}

export const prepareActivationErrors = (
  state: RootState,
  errors: EmployeeInterfaces.ActivationError[]
): {
  title: string
  messages: string[]
} => {
  const employee = getEmployee(state) as EmployeeInterfaces.Employee

  const messages = []
  for (const error of errors) {
    messages.push(...error.errorMessages.map((errorMessage) => errorMessage.message))
  }

  return {
    title: intl.get('activate_employee_failed', { name: employee.fullName }),
    messages,
  }
}

export const getSalaryTabTypeCodes = createSelector([getSalaryTabType], (tabType) => {
  const recurrings = [
    CompensationTypes.recurringEarnings,
    CompensationTypes.recurringDeductions,
    CompensationTypes.recurringUnits,
    CompensationTypes.recurringRate,
  ]

  const oneTimes = [CompensationTypes.additionalEarnings, CompensationTypes.additionalDeductions]

  const units = [CompensationTypes.additionalUnits]

  const all = [...recurrings, ...oneTimes]

  switch (tabType) {
    case SalaryTabType.recurrings:
      return recurrings

    case SalaryTabType.one_times:
      return oneTimes

    case SalaryTabType.units:
      return units

    case SalaryTabType.all:
      return all

    default:
      return all
  }
})

export const getLeaveModalTransaction = (state: RootState) => state.employee.leave.modalTransaction

export const getCountryOptions = (state: RootState): ApiObject.SelectOption[] => {
  return state.employee?.countries
    ? state.employee.countries.map((country) => {
        return {
          key: country.code.toUpperCase(),
          label: country.name ? country.name : country.code,
          currencies: country.currencies,
        }
      })
    : []
}

export const getBankData = (state: RootState): ApiObject.BankData => state.employee.bankData
export const getUserBankDetails = (state: RootState): ApiObject.BankDetails => state.employee.bankDetails
export const getUserBankDetailsErrors = (state: RootState): any => state.employee.bankDetailsErrors
export const isEmployeeCardCollapsed = (state: RootState): boolean => state.employee.employeeCardCollapsed
export const paymentLoading = (state: RootState): boolean => state.employee.paymentLoading
