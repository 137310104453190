import React from 'react'
import axios, { AxiosResponse, AxiosRequestConfig } from 'axios'
import { toast } from 'react-toastify'

import { store, constants, intl } from '@global'
import { ApiProvider } from '@api'
import { EnvironmentHelper, RouteHelper, ApiError } from '@library'

import { SING_OUT, stopLoading } from './entrypoints/redux'

const allowContinueErrors = ['legal_entity_not_found']
const ignoreErrorMessages = ['Bank not found', 'Employees are still being activated']

const provider = axios.create({ baseURL: EnvironmentHelper.getApiUrl() })
provider.interceptors.response.use(
  (response: AxiosResponse) => {
    if (response.data.errors) {
      const dataErrors = response.data.errors
      if (dataErrors) {
        let messages: string[] = []
        // If errors as array
        if (Array.isArray(dataErrors)) {
          for (let err of dataErrors) {
            if (err.message) {
              err = err.message
            }
            messages.push(err)
          }
        // If errors as object
        } else {
          for (const err in dataErrors) {
            if (dataErrors.hasOwnProperty(err)) {
              messages.push(dataErrors[err])
            }
          }
        }
        // Filter ignored error messages
        messages = messages.filter((message: string) => !ignoreErrorMessages.includes(message))

        // Display error with toast
        if (messages.length) {
          toast.error(
            <div>
              {messages.map((msg, index) => (
                <span key={index}>
                  {msg}
                  <br />
                </span>
              ))}
            </div>
          )
        }
      }
    }
    return response
  },
  (error) => {
    if ([404].includes(error?.response?.status)) {
      let loginError = error.response.data?.errors ? error.response.data?.errors : null

      let codes: any = []
      let messages = []

      if (loginError) {
        loginError.forEach((err: any) => {
          if (err.code) {
            codes.push(err.code)
          }
          if (err.message) {
            messages.push(err.message)
          }
        })
      }

      if (allowContinueErrors.find((str) => codes.includes(str))) {
        toast.error(intl.get('no_active_period_for_entity'))
        return { data: null }
      }
    }

    
    if (!error.response) {
      return { data: null }
    }

    if ([401, 403].includes(error.response.status)) {
      toast.error(intl.get('sth_went_wrong_redirect'))
      setTimeout(() => {
        store.dispatch({ type: SING_OUT })
        RouteHelper.goToLaunchpad()
      }, 3000)

      return
    }

    if ([400, 422].includes(error.response.status)) {
      const data = error.response.data?.data
      let dataErrors =
        data &&
        Array.isArray(data) &&
        data.length > 0 &&
        data[0].hasOwnProperty('message') &&
        data[0].hasOwnProperty('entity') &&
        data[0].hasOwnProperty('field')
          ? data
          : null

      // Take Errors from response.data.data (more information) or take errors from response.data.errors (Minimal)
      const errors = dataErrors || error.response.data?.errors || null

      if (errors) {
        const messages = []
        if (Array.isArray(errors)) {
          for (let err of errors) {
            // hack to getting message if err as object
            if (err.message) {
              err = err.message
            }
            messages.push(err)
          }
        } else {
          for (const err in errors) {
            if (errors.hasOwnProperty(err)) {
              messages.push(errors[err])
            }
          }
        }

        const filteredMessages = messages.filter(message => message !== 'Required');

        toast.error(
            filteredMessages.length ? (
            <div>
              {filteredMessages.map((msg, index) => (
                <span key={index}>
                  {msg}
                  <br />
                </span>
              ))}
            </div>
          ) : (
            messages.length ? intl.get('employee_fields_nok') : intl.get('sth_went_wrong')
          ),
          {
            toastId: error.response.status,
          }
        )
        return { data: null, errors }
      } else {
        toast.error(`${error.response.status} ${error.response.statusText}`, {
          toastId: error.response.status,
        })
        return { data: null }
      }
    }

    if (error?.response?.status) {
      let errorMessage
      if (error.response.data.errors) {
        const errors = error.response.data.errors
          .map((err: any) => {
            return err.message
          })
          .join('. ')

        errorMessage = error.response.data.status + ' ' + errors
        toast.error(errorMessage)
      }
      ApiError.unexpectedStatusError(error.response.status, errorMessage)
    }

    store.dispatch(stopLoading())
  }
)

provider.interceptors.request.use((config: AxiosRequestConfig): AxiosRequestConfig | Promise<AxiosRequestConfig> => {
  const authorizedUser = store.getState().global.authorizedUser
  if (authorizedUser && authorizedUser.sessionToken) {
    config.headers[constants.HttpHeaders.X_SESSION_TOKEN] = authorizedUser.sessionToken
  }

  if (config?.url?.includes('/fe-hook')) {
    config.headers.authorization = EnvironmentHelper.getHookAuthorization()
  }

  return config
})

const api = new ApiProvider(provider)

export default api
