import React, {PureComponent, Fragment} from 'react';
import {intl} from '@global';
import styled from 'styled-components';

import {stylingVariables} from '@global';
import {EmployeeInterfaces} from '@api';

import ChangeEssAccess from './ChangeEssAccess';
import EssStatus from '../../common/Employee/EssStatus';

const StyledWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const StyledHeader = styled.div`
    font-size: 12px;
    font-weight: normal;
    color: ${stylingVariables.colorPalette.deepGray};
    padding: 5px 0;
`;

interface IProps {
    employee: EmployeeInterfaces.Employee;
}

export default class EssAccess extends PureComponent<IProps> {
    public render() {
        return (
            <Fragment>
                <StyledHeader>
                    {intl.get('ess_access')}
                </StyledHeader>

                <StyledWrapper>
                    <EssStatus employee={this.props.employee} />

                    <ChangeEssAccess employee={this.props.employee}/>
                </StyledWrapper>
            </Fragment>
        );
    }
}
