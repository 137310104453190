import React, {PureComponent} from 'react';
import styled from 'styled-components';
import {get} from 'lodash';

import {stylingVariables} from '@global';
import {ApiObject, EmployeeInterfaces} from '@api';

import Field from './Field';

const StyledFieldsGroupEdit = styled.div`
    margin: 20px 0;
    border-bottom: 1px solid ${stylingVariables.colorPalette.gray};
    padding-bottom: 30px;
    &:last-child {
        border-bottom: 0;
    }
`;

export const StyledFieldsGroupHeader = styled.h4`
    color: ${stylingVariables.colorPalette.darkest};
    font-size: 16px;
    font-weight: ${stylingVariables.fontWeight.bold};
    padding: 0;
    margin: 30px 0;
`;

interface IProps {
    employee: EmployeeInterfaces.Employee;
    fields: any;
    group: ApiObject.FieldGroup;
    errors: ApiObject.EntityFieldErrorMessage[];

    onChange: (field: ApiObject.Field, value: any) => void;
}

export default class Group extends PureComponent<IProps> {
    render() {
        return (
            <StyledFieldsGroupEdit>
                <StyledFieldsGroupHeader>{this.props.group.name}</StyledFieldsGroupHeader>
                {
                    this.renderFields()
                }
            </StyledFieldsGroupEdit>
        );
    }

    private renderFields() {
        return this.props.group.fields
            .sort(function(a,b) { return a.ordinal - b.ordinal })
            .map((field: ApiObject.Field) => {
            return (
                <Field
                    key={`${field.entity}_${field.code}`}
                    field={field}
                    employee={this.props.employee}
                    default={
                        get(this.props.fields, `${field.entity}.${field.code}`)
                    }
                    onChange={this.props.onChange}
                    errorMessage={this.getErrorMessageByField(field)}
                />
            );
        });
    }

    private getErrorMessageByField = (field: any): string | null => {
        const foundError = this.props.errors
            .find((error: ApiObject.EntityFieldErrorMessage) => {
                return error.field === field.code && error.entity === field.entity
            });

        return foundError ? foundError.message : null;
    }
}
