import React, { Fragment, PureComponent } from "react";
import { connect } from "react-redux";
import moment, { Moment } from "moment";
import styled from "styled-components";

import { EmployeeInterfaces, LeaveInterfaces, PayrollInterfaces } from "@api";
import { intl, stylingVariables, numberFormat } from "@global";
import { RootState } from "../../../../rootReducer";
import { create, getLeaves, getTransitionalBalances, setPeriod } from "./redux";
import StyledHeader from "../../../common/Leave/StyledHeader";
import { BlankButton, DateRangePicker } from "@common";
import { DateRangePickerFidelity } from "../../../../common/Date/DateRangePicker";
import TypeCard from "./TypeCard";
import TotalCard from "./TotalCard";
import * as selectors from "./selectors";
import * as employeeSelectors from "../../selectors";
import * as globalSelectors from "../../../selectors";
import EditBalances, {
  ModalCloseReason,
  ModalLocation,
} from "../../../common/Leave/Balance/EditBalances";
import Table from "../../../common/Leave/Balance/Table/Table";
import { RadioButtons } from "@common";
import { orderBy, reverse } from "lodash";
import { getTransitionalAmountsSum } from "library/transitionalAmounts.helper";

const StyledCardsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: center;
  padding-top: 18px;
  border-top: 1px solid ${stylingVariables.colorPalette.gray};
`;

const DetailsButton = styled((props) => <BlankButton {...props} />)`
  color: ${stylingVariables.colorPalette.primary};
  font-weight: ${stylingVariables.fontWeight.bold};
  font-size: ${stylingVariables.fontSize.mediumLarge};
  padding: 5px 25px;
  border-radius: 13px;
  &:hover {
    background-color: ${stylingVariables.colorPalette.primaryHover};
  }
`;

const DetailsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 30px 0;
`;

const DetailsContent = styled.div`
  width: 100%;

  > div {
    margin-top: 10px;
  }

  .radio-wrapper {
    flex-wrap: wrap;
    justify-content: flex-start;
    cursor: pointer;
  }

  .radio-buttons-wrapper {
    flex-wrap: wrap;
  }
`;

const TypeWrapper = styled.div`
  width: calc(100% - 100px);
  margin: 0 50px;
`;

const StyledTableWrapper = styled.div<{ height?: number }>`
  height: ${(props) => props.height || 500}px;
  max-height: 700px;
  padding: 27px 58px;
`;

const LinedHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  h4 {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: ${stylingVariables.fontSize.large};
    padding: 20px 0;
  }

  h4:after {
    content: "";
    border-top: 1px solid ${stylingVariables.colorPalette.gray};
    margin: 0 0 0 18px;
    flex: 1 0 18px;
  }

  h4:before {
    content: "";
    border-top: 1px solid ${stylingVariables.colorPalette.gray};
    margin: 0 18px 0 0;
    width: 50px;
  }
`;

interface IProps {
  from: Moment;
  till: Moment;
  employee: EmployeeInterfaces.Employee | null;
  types: LeaveInterfaces.Type[];
  currentBalanceWrapper: LeaveInterfaces.EmployeeLeaveBalanceWrapper;
  latestBalanceWrapper: LeaveInterfaces.EmployeeLeaveBalanceWrapper;

  setPeriod: (from: Moment, till: Moment) => void;
  getLeaves: () => void;
  create: (
    employeeId: number,
    transaction: LeaveInterfaces.Transaction
  ) => void;

  currentPayrollPeriod: PayrollInterfaces.PayrollPeriod | null;
  transitionalBalances: LeaveInterfaces.TransitionalBalances;
  getTransitionalBalances: () => void;
  leaveTypes: LeaveInterfaces.Type[];
  masterdataEditAllowed?: boolean;
}

interface IState {
  editModalOpen: boolean;
  detailsOpen: boolean;
  editModalDefaultLeaveTypeId?: number;
  selectedLeaveType?: number | string;
}

class Balance extends PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      editModalOpen: false,
      detailsOpen: false,
      selectedLeaveType: undefined,
    };
  }

  public componentDidMount() {
    const selectedLeaveType = this.props.leaveTypes.length
      ? this.props.leaveTypes[0].id
      : undefined;

    if (selectedLeaveType) {
      this.setState({ selectedLeaveType });
    }
  }

  componentDidUpdate(prevProps: IProps) {
    if (
      this.props.leaveTypes.length &&
      prevProps.leaveTypes.length !== this.props.leaveTypes.length
    ) {
      this.setState({
        selectedLeaveType: this.props.leaveTypes[0].id,
      });
    }
  }

  renderDataSource = () => {
    const options = this.props.leaveTypes.map((type) => {
      return { label: type.name, value: type.id };
    });

    return (
      <RadioButtons
        label={intl.get("leave_type")}
        className={"radio-wrapper"}
        onChange={this.onDataSourceChange}
        defaultValue={this.state.selectedLeaveType}
        options={options}
      />
    );
  };

  onDataSourceChange = async (selectedLeaveType: string | number) => {
    this.setState({ selectedLeaveType });
  };

  public render() {
    const year = this.props.from.year();
    const currentPayrollYear = moment(
      this.props.currentPayrollPeriod?.beginDate
    ).year();
    const isCurrentPayrollYear = year === currentPayrollYear;

    const daysTakenThisYear = Object.values(
      this.props.latestBalanceWrapper.balances
    ).reduce((prev, curr) => {
      const transitionalTaken = getTransitionalAmountsSum(curr, 'taken')
      return prev + (curr.ytdTaken ?? 0) + (curr.year1TransitionalTaken ?? 0) + transitionalTaken;
    }, 0)

    return (
      <Fragment>
        <StyledHeader>
          <h4>{intl.get("leave_balances_in_year", { year })}</h4>
          <DateRangePicker
            from={this.props.from}
            till={this.props.till}
            onApply={this.changePeriod}
            fidelity={DateRangePickerFidelity.onlyYear}
          />
        </StyledHeader>

        {this.props.latestBalanceWrapper.balances && (
          <StyledCardsWrapper>
            {this.props.types.map((t) => (
              <TypeCard
                key={t.id}
                typeId={t.id}
                label={t.name}
                hasBalance={t.hasBalance}
                amountType={t.amountType}
                annualBalance={
                  this.props.latestBalanceWrapper.balances[t.id] || {}
                }
                currentBalance={
                  this.props.currentBalanceWrapper.balances[t.id] || {}
                }
                year={year}
                isCurrentPayrollYear={isCurrentPayrollYear}
                editModal={this.openEditModal}
                masterdataEditAllowed={this.props.masterdataEditAllowed}
                leaveType={t.leaveType}
              />
            ))}

            <TotalCard count={daysTakenThisYear} year={year} />
          </StyledCardsWrapper>
        )}
        <DetailsWrapper>
          <DetailsButton onClick={this.toggleDetails}>
            {!this.state.detailsOpen && intl.get("view_details")}
            {this.state.detailsOpen && intl.get("close_details")}
          </DetailsButton>
          {this.state.detailsOpen && (
            <DetailsContent>
              <LinedHeader>
                <h4>{intl.get("balances_details")}</h4>
              </LinedHeader>
              <TypeWrapper>{this.renderDataSource()}</TypeWrapper>
              <StyledTableWrapper height={this.tableHeight}>
                <Table
                  regulation={this.selectedRegulation}
                  items={this.transitionalBalances}
                />
              </StyledTableWrapper>
            </DetailsContent>
          )}
        </DetailsWrapper>
        {this.state.editModalOpen && this.props.employee && (
          <EditBalances
            defaultLeaveTypeId={this.state.editModalDefaultLeaveTypeId}
            isOpen={this.state.editModalOpen}
            employee={this.props.employee as LeaveInterfaces.EmployeeLeave}
            showEmployeeWrapper={false}
            modalLocation={ModalLocation.EmployeeView}
            onClose={this.closeEditModal}
            afterSave={this.afterSaveBalances}
          />
        )}
      </Fragment>
    );
  }

  private get transitionalBalances(): any[] {
    let transitionalBalancesArray: any[] = [];

    const item =
      this.props.transitionalBalances && this.state.selectedLeaveType
        ? Object.values(this.props.transitionalBalances).find(
            (t) => t.leaveRegulationId === this.state.selectedLeaveType
          )
        : null;

    if (item) {
      const orderedEntries = orderBy(item.entries, "periodId", "asc");
      orderedEntries.forEach((logItem) => {
        const type = this.props.leaveTypes.find(
          (t) => t.id === item.leaveRegulationId
        );
        if (!type || !type.active) {
          return;
        }

        const filteredMap = logItem.entries.filter(
          (e) => e.amount !== 0 && !e.isBaseEntry
        );
        const orderedMap = orderBy(filteredMap, "createTime", "asc");
        const eligiblity = numberFormat(logItem.startingYtdBalance + logItem.startingYear1TransitionalBalance + logItem.periodTransitionalBalanceRemaining);
        const startingYtdBalance = numberFormat(logItem.startingYtdBalance);
        const startingYtdTaken = numberFormat(logItem.startingYtdTaken);
        const transitionalBalance = numberFormat(logItem.startingYear1TransitionalBalance + logItem.periodTransitionalBalanceRemaining);
        const transitionalTaken = numberFormat(logItem.startingYear1TransitionalTaken + logItem.periodTransitionalBalanceTaken);

        const baseEntry = {
          eligiblity,
          amount: 0,
          createTime: logItem.periodBeginDate,
          periodInitial: true,
          leaveRegulationId: item.leaveRegulationId,
          leaveTypeName: type ? type.name : "",
          startingYtdBalance,
          startingYtdTaken,
          transitionalBalance,
          transitionalTaken,
          periodName: logItem.periodName,
          date: logItem.periodBeginDate,
          hasBalance: type.hasBalance,
          action: intl.get("base_entry"),
        };

        const mappedArray: any[] = orderedMap.map((entry) => {
          let action = intl.get("new_period_balances_set", {
            period: logItem.periodName,
          });

          if (entry.type === "ytd_balance") {
            action = "leave_balance_changed";
          } else {
            action = entry.type;
          }

          return {
            ...entry,
            createTime: entry.createTime || entry.date,
            periodInitial: false,
            leaveRegulationId: item.leaveRegulationId,
            leaveTypeName: type ? type.name : "",
            startingYtdBalance: "",
            startingYtdTaken: "",
            transitionalBalance: "",
            transitionalTaken: "",
            periodName: logItem.periodName,
            date: logItem.periodBeginDate,
            hasBalance: type.hasBalance,
            action: intl.get(action),
            eligiblity: "",
          };
        });

        
        transitionalBalancesArray = [
          ...transitionalBalancesArray,
          baseEntry,
          ...mappedArray,
        ];
      });
    }
    return reverse(transitionalBalancesArray);
  }

  private get selectedRegulation(): any {
    return this.props.types && this.state.selectedLeaveType
      ? this.props.types.find((t) => t.id === this.state.selectedLeaveType)
      : null;
  }

  private get tableHeight(): number {
    return this.transitionalBalances.length > 0
      ? this.transitionalBalances.length * 71 + 105
      : 215;
  }

  private changePeriod = (from: Moment, till: Moment) => {
    this.props.setPeriod(from, till);
    this.props.getLeaves();
    this.props.getTransitionalBalances();
  };

  private openEditModal = (leaveTypeId: number) => {
    this.setState({
      editModalOpen: true,
      editModalDefaultLeaveTypeId: leaveTypeId,
    });
  };

  public loadTransitionalBalances = async () => {
    this.props.getTransitionalBalances();
  };

  public toggleDetails = async () => {
    await this.loadTransitionalBalances();
    this.setState({ detailsOpen: !this.state.detailsOpen });
  };

  private closeEditModal = (closeReason?: ModalCloseReason) => {
    if (closeReason === ModalCloseReason.SaveAction) {
      this.props.getLeaves();
      if (this.state.detailsOpen) {
        this.loadTransitionalBalances().catch();
      }
    }
    this.setState({
      editModalOpen: false,
    });
  };

  private afterSaveBalances = () => {
    if (this.state.detailsOpen) {
      this.loadTransitionalBalances().catch();
    }
  };
}

const mapStateToProps = (state: RootState, ownProps: any) => ({
  from: selectors.getPeriodFrom(state, ownProps),
  till: selectors.getPeriodTill(state, ownProps),
  types: selectors.getActiveLeaveTypes(state, ownProps),
  currentBalanceWrapper: selectors.getCurrentLeavePeriodBalances(state),
  latestBalanceWrapper: selectors.getLatestLeavePeriodBalances(state),
  currentPayrollPeriod: globalSelectors.getCurrentPayrollPeriod(state),
  employee: employeeSelectors.getEmployee(state),
  transitionalBalances: selectors.getLeaveBalancesWithEntries(state),
});

const mapDispatchToProps = {
  setPeriod,
  getLeaves,
  create,
  getTransitionalBalances,
};

export default connect(mapStateToProps, mapDispatchToProps)(Balance);
