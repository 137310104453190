import React, {PureComponent, Fragment} from 'react';
import {connect} from 'react-redux';
import moment, {Moment} from 'moment';
import styled from 'styled-components';

import {constants, stylingVariables, intl} from '@global';
import {EmployeeInterfaces, LeaveInterfaces, PayrollInterfaces} from '@api';

import * as globalSelectors from '../../../selectors';
import * as selectors from './selectors';
import * as employeeSelectors from '../../selectors';
import {
    getLeaves,
    setPeriod,
    create,
    createAndApprove,
    store,
    approve,
    reject,
} from './redux';
import Balance from './Balance';
import Requests from './Requests';
import Upcoming from './Upcoming';
import History from './History';
import Rejected from './Rejected';
import {RootState} from '../../../../rootReducer';
import AddAction from '../../../common/Leave/Transaction/Actions/Add';
import Ongoing from './Ongoing';
import {getLeaveTypes} from '../../../Leaves/redux';
import TransactionModal from '../../../common/Leave/Transaction/TransactionModal';
import {closeLeaveTransactionModal, openLeaveTransactionModal} from '../../redux';

const StyledTabsWrapper = styled.div<{firstEl: boolean}>`
     background: ${stylingVariables.colorPalette.white};
     margin-bottom: 30px;
     border-bottom-left-radius: 10px;
     border-bottom-right-radius: 10px;
     ${props => props.firstEl ? '' : 'border-radius: 10px;'}
     max-width: 100%;
     overflow: auto;
`;

const StyledTabs = styled.div`
    padding: 30px 0;
`;

const StyledHeader = styled.div`
    display: flex;
    justify-content: space-between;
    h4 {
        font-size: ${stylingVariables.fontSize.largeMedium};
        font-weight: ${stylingVariables.fontWeight.bold};
        padding: 30px 0 26px 77px;
    }
    button {
        padding-right: 77px;
    }
`;

interface IState {
    showRejected: boolean;
}

interface IProps {
    currentPayrollPeriod: PayrollInterfaces.PayrollPeriod|null;

    employee: EmployeeInterfaces.Employee;
    leaveTypes: LeaveInterfaces.Type[];

    setPeriod: (from: Moment, till: Moment) => void;
    getLeaveTypes: () => void;
    getLeaves: () => void;
    create: (employeeId: number, transaction: LeaveInterfaces.Transaction) => void;
    createAndApprove: (employeeId: number, transaction: LeaveInterfaces.Transaction) => void;

    store: (employeeId: number, transaction: LeaveInterfaces.Transaction) => void;
    approve: (employeeId: number, transaction: LeaveInterfaces.Transaction) => void;
    reject: (employeeId: number, transaction: LeaveInterfaces.Transaction) => void;
    closeLeaveTransactionModal: () => void;
    modalTransaction: LeaveInterfaces.Transaction[] | undefined;
    openLeaveTransactionModal: (transaction: LeaveInterfaces.Transaction[]) => void;
    masterdataEditAllowed: boolean;
}

export class Leave extends PureComponent<IProps, IState> {
    public constructor(props: IProps) {
        super(props);

        if (props.currentPayrollPeriod) {
            props.setPeriod(
                moment(props.currentPayrollPeriod.beginDate, constants.SYSTEM_DATE_FORMAT).startOf('year'),
                moment(props.currentPayrollPeriod.endDate, constants.SYSTEM_DATE_FORMAT).endOf('year'),
            );
        }

        this.state = {
            showRejected: false,
        };

        this.props.getLeaveTypes();
        this.props.getLeaves();
    }

    public render() {
        return (
            <Fragment>
                {this.props.leaveTypes && <StyledTabsWrapper firstEl={true}>
                    <Balance
                        employee={this.props.employee}
                        leaveTypes={this.props.leaveTypes}
                        masterdataEditAllowed={this.props.masterdataEditAllowed}
                    />
                </StyledTabsWrapper>}
                <StyledTabsWrapper firstEl={false}>
                    <StyledHeader>
                        <h4>{intl.get('leaves')}</h4>
                        <AddAction
                            method={'withPlusAdd'}
                            employee={this.props.employee}
                            legalEntityId={this.props.employee.legalEntityId}
                            leaveTypes={this.props.leaveTypes}
                            onClickCreate={this.props.create}
                            onClickCreateAndApprove={this.props.createAndApprove}
                            size={'30px'}
                            fontSize={'12px'}
                            shadow={true}
                        />
                    </StyledHeader>
                    <StyledTabs>
                        {this.state.showRejected && (
                            <Rejected employee={this.props.employee} toggleRejected={this.toggleRejected}/>
                        )}
                        {!this.state.showRejected && (
                         <>
                             <Requests employee={this.props.employee} toggleRejected={this.toggleRejected} />
                             <Upcoming employee={this.props.employee}/>
                             <Ongoing employee={this.props.employee}/>
                             <History employee={this.props.employee}/>
                         </>
                        )}

                    </StyledTabs>
                </StyledTabsWrapper>
                <TransactionModal
                    transactions={this.props.modalTransaction}
                    employee={this.props.employee}
                    leaveTypes={this.props.leaveTypes}
                    onTransactionOpen={this.onOpen}
                    onClose={this.props.closeLeaveTransactionModal}
                    onClickSave={this.props.store}
                    onClickReject={this.props.reject}
                    onClickApprove={this.props.approve}
                />
            </Fragment>
        );
    }

    onOpen = (e: any, transaction: LeaveInterfaces.Transaction) => {
        this.props.openLeaveTransactionModal([transaction]);
    }

    public toggleRejected = () => {
        this.setState({showRejected: !this.state.showRejected});
    }
}

const mapStateToProps = (state: RootState, ownProps: any) => ({
    currentPayrollPeriod: globalSelectors.getCurrentPayrollPeriod(state),
    leaveTypes: selectors.getActiveLeaveTypes(state, ownProps),
    rejectedTransactions: selectors.getRejectedLeaveRequests(state, ownProps),
    modalTransaction: employeeSelectors.getLeaveModalTransaction(state),
    masterdataEditAllowed: globalSelectors.masterdataEditAllowed(state, null)
});

const mapDispatchToProps = {
    setPeriod,
    getLeaveTypes,
    getLeaves,
    create,
    createAndApprove,
    store,
    approve,
    reject,
    closeLeaveTransactionModal,
    openLeaveTransactionModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(Leave);
