import React, {PureComponent} from 'react';
import styled from 'styled-components';

import {stylingVariables} from '@global';
import {LeaveInterfaces, EmployeeInterfaces} from '@api';

import LeaveSourceHelper from '../../../source.helper';
import {intl} from '@global';

interface ModalHeaderProps {
    forEmployee: EmployeeInterfaces.Employee;
    leave: LeaveInterfaces.Transaction;
}

const Content = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid ${stylingVariables.colorPalette.gray};
`;

const InformationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 20px;
  font-weight: ${stylingVariables.fontWeight.bold};
  display: inline-block;
`;

export const StyledHeader = styled.div<{color?: string}>`
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 20px 20px 0 0;
  background: ${stylingVariables.colorPalette.white};
  border: 1px solid ${stylingVariables.colorPalette.white};
  z-index: 1;
  h4 {
    color: ${props => props.color || stylingVariables.colorPalette.green};
    line-height: 1.4em;
  }
`;


const LeaveGroupIcon = styled.div`
  background: ${stylingVariables.colorPalette.lightGray};
  padding: 30px;
`;

const LeaveStatusLabel = styled.div<{colors: any}>`
  background: ${props => props.colors.color};
  color: ${props => props.colors.text};
  padding: 6px 10px;
  border-radius: 14px;
  font-size: ${stylingVariables.fontSize.medium};
  font-weight: ${stylingVariables.fontWeight.semibold};
  text-transform: capitalize;
  margin: 0 70px 0 auto
`;

export default class ModalHeader extends PureComponent<ModalHeaderProps> {
    public render() {
        const { status, leaveTypeName } = this.props.leave;
        return (
            <StyledHeader color={stylingVariables.colorPalette.darkest}>
                <Content>
                    <LeaveGroupIcon />
                    <InformationWrapper>
                        {leaveTypeName}
                    </InformationWrapper>
                    <LeaveStatusLabel colors={LeaveSourceHelper.getTransactionStatusColour(status)}>
                        {intl.get(status)}
                        &nbsp;
                        {intl.get('transaction')}
                    </LeaveStatusLabel>
                </Content>
            </StyledHeader>
        );
    }
}
