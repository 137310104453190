import { ApiObject } from '@api'
import { constants, interfaces, intl } from '@global'
import { FieldGroupHelper, Functions } from '@library'
import { CompensationTypes } from '../../constants'
import { IGNORE_COLUMN_TYPES } from '../../api/report-template.service'

export const isMonthlyPeriods = (legalEntityPeriods: ApiObject.LegalEntityPeriod[]): boolean => {
    return legalEntityPeriods.every((period: ApiObject.LegalEntityPeriod) =>{
        return !period.payrollCycles || period.payrollCycles.some(
          (payrollCycle: ApiObject.LegalEntityPayrollCycle) => payrollCycle.periodicity === 'monthly')
    })
}

export const canRegenerateMasterdata = (
  legalEntityReportType?: ApiObject.DataSource,
  legalEntityPeriods: ApiObject.LegalEntityPeriod[] = []
): boolean => {
    if (!legalEntityReportType || legalEntityPeriods.length === 0) {
        return false
    }
    if (legalEntityReportType === ApiObject.DataSource.employee) {
        return legalEntityPeriods.every(period =>
          period.payrollCycles && period.payrollCycles.some(payroll => payroll.status === 'draft')
        )
    }

    return false
}

export const getLegalEntityPeriods = (periods:  interfaces.Periods, legalEntityPeriods: ApiObject.LegalEntityPeriod[]): ApiObject.LegalEntityPeriod[]=> {
    return legalEntityPeriods.filter(({beginDate, endDate}) => {
        return periods.some(p => p.beginDate === beginDate && p.endDate === endDate)
    })
}

export const convertTemplateColumnsToSelectDataSchema = (columns: ApiObject.ReportColumn[] = []): interfaces.ReportSelectDataSchema => {
    const pureColumns =  [...columns.filter(c => !IGNORE_COLUMN_TYPES.includes(c.columnType as string))]
    let selectDataSchema: interfaces.ReportSelectDataSchema = {
        masterDataSchema: {groups: []},
        payElementsSchema: {groups: []}
    };

    selectDataSchema.masterDataSchema.groups.push({
        label: 'General Group',
        uniqueKey: Functions.uniqueID(),
        fields: pureColumns.filter(column => !column.columnType || column.columnType.indexOf('SUMMED') === -1).map(column => {
            return {
                uniqueKey: Functions.uniqueID(),
                label: column.label,
                key: FieldGroupHelper.getFieldKeyFromMasterdataFormula(column.formula)
            } as interfaces.IGroupFieldBuilderField
        })
    } as interfaces.IGroupFieldBuilderGroup);

    selectDataSchema.payElementsSchema.groups.push({
        label: 'General Group',
        uniqueKey: Functions.uniqueID(),
        fields: pureColumns.filter(column => column.columnType && column.columnType.indexOf('SUMMED') !== -1).map(column => {
            return {
                uniqueKey: Functions.uniqueID(),
                label: column.label,
                key: getFieldKeyFromPayElementFormula(column.filterFormula as string)
            } as interfaces.IGroupFieldBuilderField
        })
    } as interfaces.IGroupFieldBuilderGroup);

    return selectDataSchema;
}

export const convertSelectDataSchemaToTemplateColumns = (selectDataSchema: interfaces.ReportSelectDataSchema, dataSource: ApiObject.DataSource): ApiObject.ReportColumn[] => {
    let columns: ApiObject.ReportColumn[] = [];

    // Generate masterdata column groups
    selectDataSchema.masterDataSchema.groups.forEach(group => group.fields
        .filter(field => !!field.key)
        .forEach(field => {
            columns.push({
                formula: FieldGroupHelper.getFormulaForMasterdata(field.key),
                label: field.label,
                extraCss: null,
            } as ApiObject.ReportColumn)
        })
    )

    // Generate masterdata column groups
    selectDataSchema.payElementsSchema.groups.forEach(group => group.fields
        .filter(field => !!field.key)
        .forEach(field => {
            columns.push({
                formula: '$entry.amount',
                label: field.label,
                extraCss: null,
                filterFormula: `EQUALS($entry.account;'${field.key}')`,
                columnType: ApiObject.ReportColumnType.SUMMED
            } as ApiObject.ReportColumn)
        })
    )

    return columns;
}

const getFieldKeyFromPayElementFormula = (formula: string): string => {
    return formula.replace(/[^0-9]/g, '');
}

export const isReportProcesses = (mode: constants.ReportProcessMode) => {
    return [
        constants.ReportProcessMode.createReport,
        constants.ReportProcessMode.editReport,
        constants.ReportProcessMode.viewReport,
        constants.ReportProcessMode.generateReport
    ].includes(mode);
}

export const isTemplateProcesses = (mode: constants.ReportProcessMode) => {
    return [
        constants.ReportProcessMode.createTemplate,
        constants.ReportProcessMode.editTemplate,
        constants.ReportProcessMode.viewTemplate
    ].includes(mode);
}

const isGroupFieldSchemaValid = (groupFieldSchema: interfaces.IGroupFieldSchema) => {
    return groupFieldSchema.groups.every(group => group.fields.every(field => field.key));
}

const isGroupFieldSchemaEmpty = (groupFieldSchema: interfaces.IGroupFieldSchema) => {
    return groupFieldSchema.groups.length === 0 || groupFieldSchema.groups.every(group => group.fields.length === 0);
}

export const isValidSchema = (schema: interfaces.ReportSelectDataSchema): boolean => {
    if (isGroupFieldSchemaEmpty(schema.masterDataSchema) && isGroupFieldSchemaEmpty(schema.payElementsSchema)) {
        return false;
    }
    if (!isGroupFieldSchemaValid(schema.masterDataSchema)) {
        return false;
    }
    if (!isGroupFieldSchemaValid(schema.payElementsSchema)) {
        return false;
    }
    return true;
}

export const getInvalidSchemaKeys = (schema: interfaces.ReportSelectDataSchema): string[] => {
    const keys: string[] = [];

    schema.masterDataSchema.groups.forEach(group => {
        if ((!group.label || group.fields.length === 0) && group.uniqueKey) {
            keys.push(group.uniqueKey);
        }

        group.fields.forEach(field => {
            if (field.uniqueKey && !field.key) {
                keys.push(field.uniqueKey);
            }
        })
    })

    schema.payElementsSchema.groups.forEach(group => {
        if ((!group.label || group.fields.length === 0) && group.uniqueKey) {
            keys.push(group.uniqueKey);
        }

        group.fields.forEach(field => {
            if (field.uniqueKey && !field.key) {
                keys.push(field.uniqueKey);
            }
        })
    })

    return keys;
}


const uniqueAndSortOptions = (options: ApiObject.SelectOption[]) => {
    return [...new Map(options.map(item => [item.key, item])).values()].sort((a,b) => {
        const collator = new Intl.Collator();
        return collator.compare(`${a.label}`, `${b.label}`);
    })
}

export const generateQuickAddsFromOptions = (data: any): interfaces.IGroupFieldQuickAdd[] => {
    let quickAdds: interfaces.IGroupFieldQuickAdd[] = [];
    Object.keys(data).forEach(groupKey => {
        const fields = uniqueAndSortOptions(data[groupKey]).map((fieldOption: ApiObject.SelectOption) => ({
            key: fieldOption.key,
            label: fieldOption.label
        }));

        if (fields.length > 0) {
            quickAdds.push(
                {
                    label: groupKey,
                    groups: [
                        {
                            label: groupKey,
                            fields
                        }
                    ]
                }
            )
        }
    })

    return quickAdds;
}

export const getMasterdataListOptionsAndQuickAdds = (fieldGroups: ApiObject.FieldGroup[]): [ApiObject.SelectOption[], interfaces.IGroupFieldQuickAdd[]] => {
    let fieldsOptions: ApiObject.SelectOption[] = [
        // Additional fields
        {key: 'relationship.number_of_children', label: intl.get('number_of_children')},
        {key: 'employee.group_name', label: intl.get('employee_group_name')},
        {key: 'employee.first_login_time', label: intl.get('ess_first_login_date')},
        {key: 'employee.last_login_time', label: intl.get('ess_last_login_date')},
        {key: 'employee.ess_invitation_send_time', label: intl.get('ess_invitation_send_date')},
        {key: 'employee.ess_access_revoke_time', label: intl.get('ess_access_revoke_date')},
        {key: 'employee.separation_reason', label: intl.get('termination_reason')},
        {key: 'employee.last_working_date', label: intl.get('termination_date')},
    ];

    let quickAddsFields: any = {};

    const mergeFields = (fieldsOptions: ApiObject.SelectOption[], newFields: any[], prefix: string) => {
        return [...fieldsOptions, ...newFields.map((field: any) => {
            return ({
                key: FieldGroupHelper.getBasicFieldKey(field),
                label: `${prefix ? `${prefix} / ` : ''}${field.label}`
            });
        })];
    }

    const mergeQuickAddsFields = (newFields: any[], prefix: string, rootGroupName: string) => {
        if (!quickAddsFields[rootGroupName]) {
            quickAddsFields[rootGroupName] = [];
        }

        quickAddsFields[rootGroupName] = mergeFields(quickAddsFields[rootGroupName], newFields, prefix);
    }

    const groupFields = (group: any, rootGroup: string) => {
        const prefix = group.name === rootGroup ? '' : group.name;

        fieldsOptions = mergeFields(fieldsOptions, group.fields, prefix);

        mergeQuickAddsFields(group.fields, prefix, rootGroup);

        if (group.subgroups && group.subgroups.length > 0) {
            group.subgroups.forEach((s: any) => groupFields(s, rootGroup));
        }
    }

    fieldGroups.forEach((group: any) => {
        // Break on Salary groups
        if ([constants.DEFAULT_FIELD_GROUP_SALARY_NEW, constants.DEFAULT_FIELD_GROUP_SALARY_OLD].includes(group.code)) {
            return;
        }
        groupFields(group, group.name);
    })

    return [uniqueAndSortOptions(fieldsOptions), generateQuickAddsFromOptions(quickAddsFields)];
}

export const getPayElementsListOptionsAndQuickAdds = (payElements: ApiObject.PayElement[]): [ApiObject.SelectOption[], interfaces.IGroupFieldQuickAdd[]] => {
    let fieldsOptions: ApiObject.SelectOption[] = [];
    let quickAddsFields: any = {};

    payElements.forEach(payElement => {
        const option: ApiObject.SelectOption = {
            key: `${payElement.account}`,
            label: `${payElement.account} - ${payElement.name}`,
        };

        // add to list fields
        fieldsOptions.push(option);

        // Add to quick adds to relevant group
        const type = `${payElement.occurrence}_${payElement.type}s` as CompensationTypes;

        if ([
            CompensationTypes.recurringEarnings,
            CompensationTypes.additionalDeductions,
            CompensationTypes.additionalEarnings,
            CompensationTypes.recurringDeductions
        ].includes(type)) {
            const groupName = intl.get(type);
            if (!quickAddsFields[groupName]) {
                quickAddsFields[groupName] = [option];
            } else {
                quickAddsFields[groupName].push(option);
            }
        }
    })

    return [uniqueAndSortOptions(fieldsOptions), generateQuickAddsFromOptions(quickAddsFields)];
}
