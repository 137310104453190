import React from 'react';
import {Column} from 'react-virtualized';
import styled from 'styled-components';

import {ApiObject} from '@api';
import {FileHelper} from '@library';
import {defaultInfiniteTableCellDataGetter, Icon, InfiniteTableHeader} from '@common';
import {interfaces, stylingVariables} from '@global';

const StyledEmployeeWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  svg {
    flex-shrink: 0;
  }
`;

const StyledContentWrapper = styled.div`
  cursor: pointer;
  margin-left: 15px;
  color: ${stylingVariables.colorPalette.green};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  &:hover{
    text-decoration: underline;
  }
`;

interface IProps {
    label: string;
    dataKey: string;
    orders?: interfaces.ListingOrders;
    changeOrder?: (column: string) => any;
    width?: number;
    downloadDocument: (id: string) => any;
}

const File: React.FC<IProps> = ({label, dataKey, orders, changeOrder, width = 200, downloadDocument}: IProps) => (
    <Column
        dataKey={dataKey}
        width={width}
        flexGrow={1}
        cellDataGetter={defaultInfiniteTableCellDataGetter}
        headerRenderer={() => <InfiniteTableHeader.Ordered
            label={label}
            dataKey={dataKey}
            orders={orders}
            changeOrder={changeOrder}
        />}
        cellRenderer={({cellData}: {cellData?: ApiObject.FileHandle}) => {
            if (!cellData) {
                return null;
            }
            return (
                <StyledEmployeeWrapper>
                    <Icon type={FileHelper.getIconType(cellData.originalName)}
                          fillColor={stylingVariables.colorPalette.deepGray}
                          height={30}
                          width={30}
                    />
                    <StyledContentWrapper onClick={() => {downloadDocument(cellData.id);}}>
                        {cellData.originalName}
                    </StyledContentWrapper>
                </StyledEmployeeWrapper>
            );
        }}
    />
);

export default File;
