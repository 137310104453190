import React, {PureComponent} from 'react';
import styled from 'styled-components';
import moment, {Moment} from 'moment';
import {intl} from '@global';

import {stylingVariables} from '@global';
import {InlineDateRangePicker} from '@common';
import BlankButton from '../BlankButton';

const InlineCalendarContainer = styled.div`
  position: absolute;
  top: 45px;
  right: -130px;
  background-color: ${stylingVariables.colorPalette.white};
  padding: 20px 25px;
  box-shadow: ${stylingVariables.layout.boxShadow};
  border-radius: ${stylingVariables.layout.borderRadius};
  &:before {
    content: ' ';
    position: absolute;
    top: -10px;
    left: 35px;
    display: block;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid ${stylingVariables.colorPalette.white};
  }
`;

const ApplyButton = styled(props => <BlankButton {...props} />)`
  color: ${stylingVariables.colorPalette.green};
  float: right;
  font-weight: ${stylingVariables.fontWeight.semibold};
  font-size: ${stylingVariables.fontSize.mediumLarge};
`;

interface DateRangeCalendarContainerProps {
    from: Moment;
    till: Moment;

    onApply: (from: Moment, till: Moment) => void;
}

interface DateRangeCalendarContainerState {
    from?: string|null;
    till?: string|null;
}

export default class DateRangeCalendarContainer extends PureComponent<
    DateRangeCalendarContainerProps,
    DateRangeCalendarContainerState
> {
    private readonly DATE_FORMAT = 'YYYY-MM-DD';

    public constructor(props: DateRangeCalendarContainerProps) {
        super(props);

        this.state = {
            from: this.props.from.format(this.DATE_FORMAT),
            till: this.props.till.format(this.DATE_FORMAT),
        };
    }

    render() {
        return (
            <InlineCalendarContainer>
                <InlineDateRangePicker
                    from={this.state.from}
                    till={this.state.till}
                    onChange={(from?: string|null, till?: string|null) => this.setState({from, till})}
                />

                <ApplyButton onClick={this.apply} isDisabled={this.isApplyDisabled}>
                    {intl.get('apply')}
                </ApplyButton>
            </InlineCalendarContainer>
        );
    }

    private apply = () => {
        if (this.isApplyDisabled) {
            return;
        }

        this.props.onApply(
            this.state.from ? moment(this.state.from, this.DATE_FORMAT) : moment(),
            this.state.till ? moment(this.state.till, this.DATE_FORMAT) : moment(),
        );
    }

    private get isApplyDisabled() {
        return !this.state.from || !this.state.till;
    }
}
