import React, {PureComponent} from 'react';
import styled from 'styled-components';
import {intl} from '@global';

import {stylingVariables} from '@global';
import {Modal} from '@common';

const StyledConfirmationMessageWrapper = styled.div`
  padding: 30px 40px;
  color: ${stylingVariables.colorPalette.deepGray}
  font-size: ${stylingVariables.fontSize.mediumLarge};
  font-weight: ${stylingVariables.fontWeight.regular};
`;

interface ConfirmationModalProps {
    title: string;
    confirmationMessage: string;

    acceptBtnTitle: string;
    cancelBtnTitle: string;

    isOpen: boolean;
    onClickAccept: () => void;
    onClickClose: () => void;
}

export default class ConfirmationModal extends PureComponent<ConfirmationModalProps> {
    static defaultProps = {
        acceptBtnTitle: 'ok',
        cancelBtnTitle: 'cancel',
    };

    render() {
        if (!this.props.isOpen) {
            return null;
        }

        return (
            <Modal.CenterModal onClickClose={this.props.onClickClose}>
                <Modal.StyledHeader>
                    <h4>{intl.get(this.props.title)}</h4>
                </Modal.StyledHeader>

                <StyledConfirmationMessageWrapper>
                    {intl.get(this.props.confirmationMessage)}
                </StyledConfirmationMessageWrapper>

                <Modal.StyledActionBar>
                    <Modal.StyledCancelButton className="StyledCancelButton" onClick={this.props.onClickClose}>
                        {intl.get(this.props.cancelBtnTitle)}
                    </Modal.StyledCancelButton>

                    <Modal.StyledOkButton onClick={this.props.onClickAccept}>
                        {intl.get(this.props.acceptBtnTitle)}
                    </Modal.StyledOkButton>
                </Modal.StyledActionBar>
            </Modal.CenterModal>
        );
    }
}
