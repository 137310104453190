import { api } from '@global'
import { PayrollInterfaces } from '@api'

export default class ApiPayroll {
  public static async getCurrentPayrollPeriod(entityId: number): Promise<PayrollInterfaces.PayrollPeriod | null> {
    const active = await ApiPayroll.getActivePayrollPeriod(entityId)

    if (null !== active) {
      return active
    }

    const closed = await ApiPayroll.getClosedPayrollPeriods(entityId)

    return closed[closed.length - 1] || null
  }

  public static async getActivePayrollPeriod(entityId: number): Promise<PayrollInterfaces.PayrollPeriod | null> {
    const { data } = await api.get(`/mss/legal-entity/${entityId}/payroll-period/active`)

    return data && data.data ? data.data.period || null : null
  }

  public static async getClosedPayrollPeriods(entityId: number): Promise<PayrollInterfaces.PayrollPeriod[]> {
    const { data } = await api.get(`/mss/legal-entity/${entityId}/payroll-period/closed`)

    return data ? data.data || [] : []
  }
}
