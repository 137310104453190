import React, {PureComponent} from 'react';
import styled from 'styled-components';
import {stylingVariables, constants} from '@global';
import {Icon} from '@common';

const StyledActionsContentWrapper = styled.div<{isOpen: boolean, top: string, right: string, padding: string}>`
  display: ${props => props.isOpen ? 'flex' : 'none'};
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  right: ${props => props.right};
  top: ${props => props.top};
  background-color: ${stylingVariables.colorPalette.white};
  padding: ${props => props.padding};
  box-shadow: ${stylingVariables.layout.boxShadow};
  border-radius: 5px;
  min-width: 220px;
  z-index: 2000;
`;

const StyledActionsButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${stylingVariables.colorPalette.orange};
  font-size: ${stylingVariables.fontSize.large};
  font-weight: ${stylingVariables.fontWeight.medium};
  border: none;
  box-shadow: none;
  background-color: transparent;
  position: relative;
  cursor: pointer;
  padding: 0;
  width: 30px;
  height: 30px;
  float: right;
    border-radius: 4px;
    transition: all 200ms;
    &:hover {
        background-color: ${stylingVariables.colorPalette.secondary};
        svg {
            fill: ${stylingVariables.colorPalette.white};
        }
    }
`;

const StyledCloseBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -20px;
  right: -15px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${stylingVariables.colorPalette.secondary};
  box-shadow: ${stylingVariables.layout.boxShadow};
  border: none;
  cursor: pointer;
`;

const StyledAbsoluteWrapper = styled.div`
  position: absolute;
  z-index: 2000;
`;

interface ActionsProps {
    children?: any;

    iconType: string;
    iconWidth: number;
    iconHeight: number;
    iconColor: string;
    shouldShowCloseButton: boolean;
    top: string;
    right: string;
    padding: string;
}

interface ActionsState {
    isOpen: boolean;
}

export default class MenuActions extends PureComponent<ActionsProps, ActionsState> {
    static defaultProps = {
        iconType: constants.IconTypes.MENU,
        iconWidth: 15,
        iconHeight: 15,
        iconColor: stylingVariables.colorPalette.secondary,
        shouldShowCloseButton: true,
        top: '10px',
        right: '-20px',
        padding: '20px 30px',
    };

    private wrapperRef: any;

    public constructor(props: any) {
        super(props);

        this.state = {
            isOpen: false,
        };
    }

    public componentDidMount() {
        document.addEventListener('mousedown', this.closeWhenClickOutside);
    }

    public componentWillUnmount() {
        document.removeEventListener('mousedown', this.closeWhenClickOutside);
    }

    public render() {
        return (
            <div ref={this.setWrapperRef}>
                <StyledActionsButton onClick={this.toggle}>
                    <Icon type={this.props.iconType}
                          width={this.props.iconWidth}
                          height={this.props.iconHeight}
                          fillColor={this.props.iconColor}
                    />
                </StyledActionsButton>
                <StyledAbsoluteWrapper>
                    <StyledActionsContentWrapper
                        isOpen={this.state.isOpen}
                        top={this.props.top}
                        right={this.props.right}
                        padding={this.props.padding}
                    >
                        {
                            this.props.shouldShowCloseButton &&
                            <StyledCloseBtn onClick={this.close}>
                                <Icon type={constants.IconTypes.CLOSE}
                                      height={10}
                                      width={10}
                                      fillColor={stylingVariables.colorPalette.white}
                                />
                            </StyledCloseBtn>
                        }

                        {this.props.children}
                    </StyledActionsContentWrapper>
                </StyledAbsoluteWrapper>
            </div>
        );
    }

    private toggle = (e: any) => {
        e.stopPropagation()
        this.setState({isOpen: !this.state.isOpen});
    }

    public close = (e: any) => {
        e.stopPropagation()
        this.setState({isOpen: false});
    }

    public setWrapperRef = (node: any) => {
        this.wrapperRef = node;
    }

    public closeWhenClickOutside = (event: Event) => {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.close(event);
        }
    }
}
