import React, {PureComponent, Fragment} from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';
import moment, {Moment} from 'moment';
import {intl} from '@global';

import {SearchInputControlled} from '@common';
import {PayrollInterfaces} from '@api';
import {stylingVariables, constants} from '@global';

import * as entrySelectors from './../../selectors';
import * as selectors from '../../Leaves/selectors';
import Table from './Table/Table';
import {
    setSearchQuery,
    changeSearchQuery,
    clearAndGetEmployeeBalances,
    resetOrdersAndFilters,
} from '../redux';
import {RootState} from '../../../rootReducer';
import Period from './../Period';
import { debounce } from 'lodash';

const RightSideWrapper = styled.div`
    display: flex;
    align-items: center;
`;

const RightSideSpacer = styled.div`
    width: 20px;
`;

const TitleWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 0 0 25px 25px;
`;

const TitleContainer = styled.div`
    h4 {
        font-weight: ${stylingVariables.fontWeight.semibold};
        color: ${stylingVariables.colorPalette.darkest};
        display: inline;
    }

    text {
        font-size: ${stylingVariables.fontSize.mediumLarge};
    }
`;

const SmallText = styled.span`
    font-size: ${stylingVariables.fontSize.medium};
    font-weight: ${stylingVariables.fontWeight.semibold};
    color: ${stylingVariables.colorPalette.deepGray};
`;

interface BalancesProps {
    clearAndGetEmployeeBalances: () => void;
    setSearchQuery: (searchQuery: string) => void;
    resetOrdersAndFilters:() => void;
    changeSearchQuery: (searchQuery: string) => void;
    searchQuery: string;
    currentPayrollPeriod: PayrollInterfaces.PayrollPeriod | null;
    periodFilterFrom: Moment;
}

interface BalancesState {
    searchValue: string;
}

export class Balances extends PureComponent<BalancesProps, BalancesState> {
    public constructor(props: BalancesProps) {
        super(props);

        this.state = {
            searchValue: this.props.searchQuery,
        };
    }

    componentDidMount() {
      this.props.resetOrdersAndFilters();
    }

    componentDidUpdate(prevProps: Readonly<BalancesProps>, prevState: Readonly<BalancesState>, snapshot?: any) {
        if (this.props.searchQuery === ''
            && this.state.searchValue === prevProps.searchQuery
            && this.state.searchValue === prevState.searchValue
        ) {
            this.setState({
                searchValue: this.props.searchQuery,
            });
        }
    }

    private onSearchChange = (event: any) => {
        this.setState({
            searchValue: event.target.value,
        });
        this.search(event.target.value);
    }

    private search = debounce((searchQuery: string) => {
        this.props.changeSearchQuery(searchQuery);
        this.props.clearAndGetEmployeeBalances();
    }, 300);

    public render() {
        return (
            <Fragment>
                <TitleWrapper>
                    <TitleContainer>
                        <h4>{intl.get('leave_balances')}</h4>
                        <SmallText>
                            {intl.get('as_of_')}
                            {this.props.periodFilterFrom
                                ? moment(this.props.periodFilterFrom, constants.SYSTEM_DATE_FORMAT).format('MMMM YYYY')
                                : null}
                        </SmallText>
                    </TitleContainer>
                    <RightSideWrapper>
                        <SearchInputControlled
                            placeholder={'search'}
                            searchValue={this.state.searchValue}
                            onChange={this.onSearchChange}
                        />
                        <RightSideSpacer/>
                        <Period/>
                    </RightSideWrapper>
                </TitleWrapper>
                <Table/>
            </Fragment>
        );
    }
}

const mapStateToProps = (state: RootState, ownProps: any) => ({
    searchQuery: selectors.getSearchQuery(state),
    currentPayrollPeriod: entrySelectors.getCurrentPayrollPeriod(state),
    periodFilterFrom: selectors.getPeriodFilterFrom(state, ownProps),
});

const mapDispatchToProps = {
    setSearchQuery,
    clearAndGetEmployeeBalances,
    changeSearchQuery,
    resetOrdersAndFilters,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Balances);
