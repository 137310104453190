import React, {PureComponent} from "react";
import styled from 'styled-components';
import {stylingVariables} from '@global';
import {Icon} from "@common";
import {constants} from "@global";
import Popover from "./Popover";

interface IProps {
    type: string;
    message: any;
    iconSize: number;
    positionLeft?: boolean;
    className?: string;
}

export const Wrapper = styled.div`
    margin: 15px 0; 
    display: flex;
    align-items: flex-start;
`;

const StyledTypeIcon = styled.div`
    height: 18px;
    width: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    opacity: .7;
`;
const InfoIcon = styled.div<{iconSize: number}>`
  height: ${props => props.iconSize}px;
  width: ${props => props.iconSize}px;
  background-color: ${stylingVariables.colorPalette.blue};
  border-radius: 100%;
  &:before {
    content: '!';
    color: #fff;
    height: 100%;
    width: 100%;
    font-size: ${props => Math.round(props.iconSize * 0.8)}px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const HelpIcon = styled.div<{iconSize: number}>`
  height: ${props => props.iconSize}px;
  width: ${props => props.iconSize}px;
  background-color: ${stylingVariables.colorPalette.blue};
  border-radius: 100%;
  &:before {
    content: '?';
    color: #fff;
    height: 100%;
    width: 100%;
    font-size: ${props => Math.round(props.iconSize * 0.8)}px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export default class IconPopover extends PureComponent<IProps> {
    private getIcon = () => {
        switch (this.props.type) {
            case 'warning':
                return <Icon type={constants.IconTypes.WARNING_ALERT} width={18} height={18} fillColor={stylingVariables.colorPalette.orange}/>;
            case 'help':
                return <HelpIcon iconSize={this.props.iconSize}/>;
            case 'info':
                return <InfoIcon iconSize={this.props.iconSize}/>;
            default:
                return null;
        }
    };

    public render() {
        return (
            <Wrapper className={this.props.className} >
                <Popover message={this.props.message} positionLeft={this.props.positionLeft}>
                    <StyledTypeIcon>
                        {this.getIcon()}
                    </StyledTypeIcon>
                </Popover>
            </Wrapper>
        );
    }
}
