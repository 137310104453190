import { AnyAction, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { toast } from 'react-toastify';

import { constants, interfaces, intl } from '@global';
import {
    ApiObject,
    ApiEmployee,
    EmployeeQueryParams,
    ApiColumnSettings,
    EmployeeInterfaces,
    prepareErrors,
} from '@api';
import { EmployeeListingHelper } from '@library';

import { RootState } from '../../rootReducer';
import * as selectors from './selectors';
import * as globalSelectors from '../selectors';
import { startLoading, stopLoading, openErrorModal, setSearchQuery } from '../redux';

export type ThunkResult<R> = ThunkAction<R, RootState, undefined, AnyAction> | AnyAction;

export const SET_EMPLOYEES = 'employees/setEmployees';
export const SET_FIELD_GROUPS = 'employees/setFieldGroups';
export const SET_EMPLOYEE_GROUPS = 'employees/setEmployeeGroups';
export const SET_SELECTED_IDS = 'employees/setSelectedIds';
export const CHECK_SELECT_ALL = 'employees/checkSelectAll';
export const UNCHECK_SELECT_ALL = 'employees/uncheckSelectAll';
export const CHOSE_STATUS = 'employees/choseStatus';
export const SET_ORDERS = 'employees/setOrders';
export const SET_FILTERS = 'employees/setFilters';
export const SET_TAGS = 'employees/setTags';

export const OPEN_COLUMN_SETTINGS_MODAL = 'employees/openColumnSettingModal';
export const CLOSE_COLUMN_SETTINGS_MODAL = 'employees/closeColumnSettingModal';
export const SET_COLUMN_SETTINGS = 'employees/setColumnSettings';

export const SET_TERMINATION_DETAILS_FIELD_GROUPS = 'employees/setTerminationDetailsFieldGroups';
export const SET_TERMINATION_DETAILS_VALUES = 'employees/setTerminationDetailsValues';
export const SET_TERMINATION_PAYOUT_FIELD_GROUPS = 'employees/setTerminationPayoutFieldGroups';
export const SET_TERMINATION_PAYOUT_VALUES = 'employees/setTerminationPayoutValues';
export const SET_SEPARATION_ERRORS = 'employees/setSeparationErrors';
export const SET_SEPARATION_ACTION = 'employees/setSeparationAction';

export const SET_ACTIVE_COUNT = 'employees/setCountByStatusActive';
export const SET_INACTIVE_COUNT = 'employees/setCountByStatusInactive';
export const SET_DRAFT_COUNT = 'employees/setCountByStatusDraft';
export const SET_EMPLOYEES_CUT_OFFS = "employees/setEmployeeCutOffs"

export interface EmployeesCutOffs {
    [key: string]: Object
}

export interface EmployeesState {
    items: EmployeeInterfaces.Employee[];
    totalCount: number;
    countsByStatus: {
        active: number | null;
        inactive: number | null;
        draft: number | null;
    };
    fieldGroups: ApiObject.FieldGroup[];
    employeeGroups: interfaces.EmployeeGroups;
    isCheckSelectAll: boolean;
    selectedIds: Set<number>;
    chosenStatus: string;

    filters: interfaces.ListingFilters;
    orders: interfaces.ListingOrders;
    tags: EmployeeInterfaces.Tag[];
    columnSettings: {
        isModalOpen: boolean,
        selectedColumns: string[],
    };
    separation: {
        errors: any;
        action: string;
    };
    terminationDetails: {
        fieldGroups: ApiObject.FieldGroup[];
        values: any;
    };
    terminationPayout: {
        fieldGroups: ApiObject.FieldGroup[];
        values: any;
    };
    cutOffStatuses?: EmployeesCutOffs
}

export const defaultState: EmployeesState = {
    items: [],
    totalCount: 0,
    countsByStatus: {
        active: 0,
        inactive: 0,
        draft: 0,
    },
    fieldGroups: [],
    employeeGroups: {},
    isCheckSelectAll: false,
    selectedIds: new Set(),
    chosenStatus: constants.EmployeeStatuses.active,

    filters: {},
    orders: {},
    tags: [],
    columnSettings: {
        isModalOpen: false,
        selectedColumns: [],
    },
    separation: {
        errors: [],
        action: 'details',
    },
    terminationDetails: {
        fieldGroups: [],
        values: [],
    },
    terminationPayout: {
        fieldGroups: [],
        values: [],
    },
    cutOffStatuses: {}
};

export default function reducer(
    state: EmployeesState = defaultState,
    action: AnyAction,
): EmployeesState {
    switch (action.type) {
        case SET_EMPLOYEES:
            return {
                ...state,
                items: action.payload.employees,
                totalCount: action.payload.totalCount,
            };
        case SET_ACTIVE_COUNT:
            return {
                ...state,
                countsByStatus: {
                    ...state.countsByStatus,
                    active: action.payload,
                },
            };
        case SET_INACTIVE_COUNT:
            return {
                ...state,
                countsByStatus: {
                    ...state.countsByStatus,
                    inactive: action.payload,
                },
            };
        case SET_DRAFT_COUNT:
            return {
                ...state,
                countsByStatus: {
                    ...state.countsByStatus,
                    draft: action.payload,
                },
            };
        case SET_FIELD_GROUPS:
            return {
                ...state,
                fieldGroups: action.payload,
            };
        case SET_EMPLOYEE_GROUPS:
            return {
                ...state,
                employeeGroups: action.payload,
            };
        case SET_SELECTED_IDS:
            return {
                ...state,
                selectedIds: action.payload,
            };
        case CHECK_SELECT_ALL:
            return {
                ...state,
                isCheckSelectAll: true,
            };
        case UNCHECK_SELECT_ALL:
            return {
                ...state,
                isCheckSelectAll: false,
            };
        case CHOSE_STATUS:
            return {
                ...state,
                chosenStatus: action.payload,
            };
        case SET_COLUMN_SETTINGS:
            return {
                ...state,
                columnSettings: { ...state.columnSettings, selectedColumns: action.payload },
            };
        case OPEN_COLUMN_SETTINGS_MODAL:
            return {
                ...state,
                columnSettings: { ...state.columnSettings, isModalOpen: true },
            };
        case CLOSE_COLUMN_SETTINGS_MODAL:
            return {
                ...state,
                columnSettings: { ...state.columnSettings, isModalOpen: false },
            };
        case SET_ORDERS:
            return {
                ...state,
                orders: action.payload,
            };
        case SET_FILTERS:
            return {
                ...state,
                filters: action.payload,
            };
        case SET_TAGS:
            return {
                ...state,
                tags: action.payload,
            };
        case SET_TERMINATION_DETAILS_FIELD_GROUPS:
            return {
                ...state,
                terminationDetails: { ...state.terminationDetails, fieldGroups: action.payload },
            };
        case SET_TERMINATION_DETAILS_VALUES:
            return {
                ...state,
                terminationDetails: { ...state.terminationDetails, values: action.payload },
            };
        case SET_TERMINATION_PAYOUT_FIELD_GROUPS:
            return {
                ...state,
                terminationPayout: { ...state.terminationPayout, fieldGroups: action.payload },
            };
        case SET_TERMINATION_PAYOUT_VALUES:
            return {
                ...state,
                terminationPayout: { ...state.terminationPayout, values: action.payload },
            };
        case SET_SEPARATION_ERRORS:
            return {
                ...state,
                separation: { ...state.separation, errors: action.payload },
            };
        case SET_SEPARATION_ACTION:
            return {
                ...state,
                separation: { ...state.separation, action: action.payload },
            };
        case SET_EMPLOYEES_CUT_OFFS:
            return {
                ...state,
                cutOffStatuses: action.payload
            };
        default:
            return state;
    }
}

export function getFieldGroups(): ThunkResult<Promise<void>> {
    return async (dispatch: Dispatch, getState: () => RootState) => {
        const currentLegalEntityId = getState().global.currentLegalEntityId;
        if (!currentLegalEntityId) {
            return;
        }

        dispatch(
            setFieldGroups(await ApiEmployee.getFieldGroups(currentLegalEntityId)),
        );

        return;
    };
}

export function setFieldGroups(fieldGroups: any) {
    return {
        type: SET_FIELD_GROUPS,
        payload: fieldGroups,
    };
}

export function getEmployeeCounts(): ThunkResult<Promise<void>> {
    return async (dispatch: Dispatch, getState: () => RootState) => {
        const state = getState();
        const currentLegalEntityId = state.global.currentLegalEntityId;
        if (!currentLegalEntityId) {
            return;
        }

        const currentPeriod = globalSelectors.getCurrentPayrollPeriod(state);

        Object.values(constants.EmployeeStatuses).forEach(async status => {
            const params = new EmployeeQueryParams({
                status: status,
                currentPeriod: currentPeriod,
                filters: state.employees.filters,
                tags: state.employees.tags,
                offset: 0,
                limit: 1,
            });
            const { employees, total } = await ApiEmployee.list(currentLegalEntityId, params);
            if (employees.length === 0) {
                return;
            }
            dispatch(
                setCountByStatus(status, total),
            );
        });

        return;
    };
}

export function setCountByStatus(status: constants.EmployeeStatuses, count: number | null) {
    switch (status) {
        case constants.EmployeeStatuses.active:
            return { type: SET_ACTIVE_COUNT, payload: count };
        case constants.EmployeeStatuses.inactive:
            return { type: SET_INACTIVE_COUNT, payload: count };
        case constants.EmployeeStatuses.draft:
            return { type: SET_DRAFT_COUNT, payload: count };
    }
}

export function getEmployees(offset: number, limit: number = 20): ThunkResult<Promise<void>> {
    return async (dispatch: Dispatch, getState: () => RootState) => {
        const state = getState();
        const currentLegalEntityId = state.global.currentLegalEntityId;
        if (!currentLegalEntityId) {
            return;
        }

        const params = new EmployeeQueryParams({
            orders: selectors.getOrders(state, null),
            filters: selectors.getFilters(state, null),
            status: selectors.getChosenStatus(state, null),
            tags: selectors.getTags(getState(), null),
            currentPeriod: globalSelectors.getCurrentPayrollPeriod(state),
            offset,
            limit,
        });
        const { employees, total } = await ApiEmployee.list(currentLegalEntityId, params);
        if (employees.length === 0) {
            return;
        }
        dispatch(
            setEmployees([...getState().employees.items, ...employees], total),
        );
        dispatch(
            setCountByStatus(selectors.getChosenStatus(state, null) as constants.EmployeeStatuses, total),
        );


        let cutOffIds: Array<number> = []

        const ids = new Set(state.employees.selectedIds);
        for (const employee of employees) {
            ids.add(employee.id);
            cutOffIds.push(employee.id);
        }

        const { employeesCutoffStatuses } = await ApiEmployee.getEmployeeCutOffs(currentLegalEntityId, cutOffIds)
        dispatch(setEmployeeCutOffsStatuses({ ...getState().employees.cutOffStatuses, ...employeesCutoffStatuses }))

        if (!state.employees.isCheckSelectAll) {
            return;
        }

        dispatch(setSelectedIds(ids));

        return;
    };
}

export function getEmployeeGroups(): ThunkResult<Promise<void>> {
    return async (dispatch: Dispatch, getState: () => RootState) => {
        const currentLegalEntityId = getState().global.currentLegalEntityId;
        if (!currentLegalEntityId) {
            return;
        }

        dispatch(
            setEmployeeGroups(await ApiEmployee.getEmployeeGroups(currentLegalEntityId)),
        );

        return;
    };
}

export function clearOldAndGetEmployees(): ThunkResult<Promise<void>> {
    return async (dispatch: Dispatch, getState: () => RootState) => {
        const state = getState();
        const currentLegalEntityId = globalSelectors.getCurrentLegalEntityId(state);
        if (!currentLegalEntityId) {
            return;
        }

        dispatch(startLoading());
        dispatch(uncheckSelectAll());
        dispatch(setSelectedIds(new Set()));
        dispatch(setEmployees([]));
        dispatch(setEmployeeCutOffsStatuses(null))

        const params: EmployeeQueryParams = new EmployeeQueryParams({
            status: selectors.getChosenStatus(state, null),
            tags: selectors.getTags(state, null),
            filters: selectors.getFilters(state, null),
            orders: selectors.getOrders(state, null),
            currentPeriod: globalSelectors.getCurrentPayrollPeriod(state),
        });

        const { employees, total } = await ApiEmployee.list(currentLegalEntityId, params);

        if (employees.length > 0) {
            let cutOffIds: Array<number> = []
            for (const employee of employees) {
                cutOffIds.push(employee.id);
            }
            const { employeesCutoffStatuses } = await ApiEmployee.getEmployeeCutOffs(currentLegalEntityId, cutOffIds)

            dispatch(setEmployeeCutOffsStatuses({ ...getState().employees.cutOffStatuses, ...employeesCutoffStatuses }))
        }

        dispatch(setEmployees(employees, total));
        dispatch(stopLoading());
        dispatch(
            getEmployeeCounts() as any,
        );
        return;
    };
}

export function resetOrdersAndFilters(): any {
    return (dispatch: Dispatch) => {
        dispatch(setOrders({}));
        dispatch(setFilters({}));
        dispatch(setTags([]));
        dispatch(setSearchQuery(''));
        return;
    };
}

export function changeOrder(column: string): any {
    return (dispatch: Dispatch, getState: () => RootState) => {
        const orders: interfaces.ListingOrders = { ...getState().employees.orders };
        dispatch(
            setOrders(
                EmployeeListingHelper.updatedOrders(column, orders),
            ),
        );

        return;
    };
}

export function changeFilter(params: interfaces.FilterParams) {
    return (dispatch: Dispatch, getState: () => RootState) => {
        const filters: interfaces.ListingFilters = { ...getState().employees.filters };

        dispatch(
            setFilters(
                EmployeeListingHelper.updatedFilters(params, filters),
            ),
        );

        if (params.searchQuery || params.searchQuery === '') {
            dispatch(setSearchQuery(params.searchQuery));
        }

        return;
    };
}

export function changeSearchQuery(searchQuery: string) {
    return (dispatch: Dispatch, getState: () => RootState) => {
        const filters: interfaces.ListingFilters = { ...getState().employees.filters };
        const params = {
            column: 'id',
            value: [],
            expression: 'in',
            type: 'text',
            searchQuery,
        };
        dispatch(
            setFilters(
                EmployeeListingHelper.updatedFilters(params, filters),
            ),
        );

        dispatch(setSearchQuery(searchQuery));

        return;
    };
}

export function setSelectedIds(ids: Set<number>) {
    return {
        type: SET_SELECTED_IDS,
        payload: ids,
    };
}

export function checkSelectAll() {
    return {
        type: CHECK_SELECT_ALL,
    };
}

export function uncheckSelectAll() {
    return {
        type: UNCHECK_SELECT_ALL,
    };
}

export function clearEmployeesSelection() {
    return (dispatch: Dispatch) => {
        dispatch(uncheckSelectAll());
        dispatch(setSelectedIds(new Set()));

        return;
    };
}

export function selectAllEmployees() {
    return (dispatch: Dispatch, getState: () => RootState) => {
        dispatch(checkSelectAll());

        const ids = new Set(
            getState().employees.items.map(e => e.id),
        );

        dispatch(setSelectedIds(ids));

        return;
    };
}

export function setEmployeeCutOffsStatuses(employeesCutoffStatuses: EmployeesCutOffs | null): AnyAction {
    return {
        type: SET_EMPLOYEES_CUT_OFFS,
        payload: employeesCutoffStatuses,
    };
}

export function setEmployees(employees: EmployeeInterfaces.Employee[], totalCount: number = 0): AnyAction {
    return {
        type: SET_EMPLOYEES,
        payload: { employees, totalCount },
    };
}

export function setEmployeeGroups(data: any): AnyAction {
    return {
        type: SET_EMPLOYEE_GROUPS,
        payload: data,
    };
}

export function choseStatus(status: string): AnyAction {
    return {
        type: CHOSE_STATUS,
        payload: status,
    };
}

export function setOrders(orders: interfaces.ListingOrders) {
    return {
        type: SET_ORDERS,
        payload: orders,
    };
}

export function setFilters(filters: interfaces.ListingFilters) {
    return {
        type: SET_FILTERS,
        payload: filters,
    };
}

export function setTags(tags: EmployeeInterfaces.Tag[]) {
    return {
        type: SET_TAGS,
        payload: tags,
    };
}

export function activate(ids: Set<number>): ThunkResult<Promise<boolean>> {
    return async (dispatch: Dispatch, getState: () => RootState) => {
        const currentLegalEntityId = getState().global.currentLegalEntityId;
        if (!currentLegalEntityId) {
            return false;
        }

        dispatch(startLoading());

        const errors = await ApiEmployee.activate(currentLegalEntityId, ids);
        const successfullyUpdatedIds = new Set(ids);
        for (const e of errors) {
            successfullyUpdatedIds.delete(e.employeeId);
        }
        dispatch(
            updateEmployeesWhenStatusWasChanged(successfullyUpdatedIds) as any,
        );

        if (errors.length === 0) {
            toast.success(ids.size === 1
                ? intl.get('active_employee_success')
                : intl.get('active_employees_success'),
            );
        } else {
            const error = selectors.prepareActivationErrors(getState(), errors);
            dispatch(
                openErrorModal(error.messages, error.title),
            );
        }

        dispatch(stopLoading());

        return errors.length === 0;
    };
}

export function suspend(ids: Set<number>): ThunkResult<Promise<boolean>> {
    return async (dispatch: Dispatch, getState: () => RootState) => {
        const currentLegalEntityId = getState().global.currentLegalEntityId;
        if (!currentLegalEntityId) {
            return false;
        }

        dispatch(startLoading());

        for (const id of ids) {
            await ApiEmployee.suspend(currentLegalEntityId, id);
        }

        dispatch(
            updateEmployeesWhenStatusWasChanged(ids) as any,
        );
        toast.success(ids.size === 1
            ? intl.get('suspend_employee_success')
            : intl.get('suspend_employees_success'),
        );

        dispatch(stopLoading());

        return true;
    };
}

export function removePendingTermination(id: number): ThunkResult<Promise<boolean>> {
    return async (dispatch: Dispatch, getState: () => RootState) => {
        const currentLegalEntityId = getState().global.currentLegalEntityId;
        if (!currentLegalEntityId) {
            return false;
        }

        dispatch(startLoading());

        const success = await ApiEmployee.removeTermination(currentLegalEntityId, id);
        if (success) {
            let successId = new Set([id])
            dispatch(
                updateEmployeesWhenStatusWasChanged(successId, true) as any,
            );

            toast.success(intl.get('remove_pending_termination_success'));
        }

        dispatch(stopLoading());

        return true;
    };
}

function updateEmployeesWhenStatusWasChanged(ids: Set<number>, updateTerminationFlag: Boolean = false) {
    return (dispatch: Dispatch, getState: () => RootState) => {
        const employees: EmployeeInterfaces.Employee[] = [];
        let distinction = 0;

        getState().employees.items.forEach(employee => {

            if (!ids.has(employee.id)) {
                employees.push(employee);
            } else if (updateTerminationFlag && ids.has(employee.id)) {
                employee = { ...employee, terminationStatus: null }
                employees.push(employee);
            } else {
                distinction++;
            }

        });

        dispatch(setEmployees(
            employees,
            getState().employees.totalCount - distinction,
        ));

        dispatch(
            getEmployeeCounts() as any,
        );

        return;
    };
}

export function sendEssInvitation(payload: {
    welcomeMessage: string, employees: { id: number, loginUsername: string },
}) {
    return async (dispatch: Dispatch, getState: () => RootState) => {
        const currentLegalEntityId = getState().global.currentLegalEntityId;
        if (!currentLegalEntityId) {
            return;
        }

        dispatch(startLoading());

        try {
            const response = await ApiEmployee.sendEssInvitation(currentLegalEntityId, payload);
            if (response.data) {
                toast.success(intl.get('send_ess_invitation_success'));
            }
        } catch (e) {
            const errors = prepareErrors(e.response.data.errors);
            dispatch(
                openErrorModal(errors, intl.get('send_ess_invitation_failed')),
            );
        }

        dispatch(stopLoading());

        return;
    };
}

export function revokeEssAccess(ids: Set<number>) {
    return async (dispatch: Dispatch, getState: () => RootState) => {
        const currentLegalEntityId = getState().global.currentLegalEntityId;
        if (!currentLegalEntityId) {
            return;
        }

        dispatch(startLoading());

        await ApiEmployee.revokeEssAccess(currentLegalEntityId, ids);
        toast.success(intl.get('revoke_ess_success'));

        dispatch(stopLoading());

        return;
    };
}

export function openColumnSettingsModal(): AnyAction {
    return {
        type: OPEN_COLUMN_SETTINGS_MODAL,
    };
}

export function closeColumnSettingsModal(): AnyAction {
    return {
        type: CLOSE_COLUMN_SETTINGS_MODAL,
    };
}

export function getColumnSettings() {
    return async (dispatch: Dispatch, getState: () => RootState) => {
        const currentLegalEntityId = getState().global.currentLegalEntityId;
        if (!currentLegalEntityId) {
            return;
        }

        const settings = await ApiColumnSettings.getSelectedEmployeesColumns(currentLegalEntityId);
        dispatch(
            setColumnSettings(settings.columns),
        );

        return;
    };
}

export function saveColumnSettings(columns: string[]) {
    return async (dispatch: Dispatch, getState: () => RootState) => {
        const currentLegalEntityId = getState().global.currentLegalEntityId;
        if (!currentLegalEntityId) {
            return;
        }

        dispatch(startLoading());

        await ApiColumnSettings.saveEmployeesColumnSettings(currentLegalEntityId, columns);
        dispatch(
            setColumnSettings(columns),
        );
        toast.success(intl.get('column_settings_saved_ok'));

        dispatch(stopLoading());

        return;
    };
}

export function setColumnSettings(columns: string[]) {
    return {
        type: SET_COLUMN_SETTINGS,
        payload: columns,
    };
}

export function getTerminationDetailsValues(ids: Set<number>): ThunkResult<Promise<void>> {
    return async (dispatch: Dispatch, getState: () => RootState) => {
        const currentLegalEntityId = getState().global.currentLegalEntityId;
        if (!currentLegalEntityId) {
            return;
        }

        for (const id of ids) {
            dispatch(setTerminationDetailsValues(
                await ApiEmployee.getSeparationDetails(currentLegalEntityId, id),
            ));
        }

        return;
    };
}

export function getTerminationPayoutValues(ids: Set<number>): ThunkResult<Promise<void>> {
    return async (dispatch: Dispatch, getState: () => RootState) => {
        const currentLegalEntityId = getState().global.currentLegalEntityId;
        if (!currentLegalEntityId) {
            return;
        }

        for (const id of ids) {
            dispatch(setTerminationPayoutValues(
                await ApiEmployee.getSeparationPayout(currentLegalEntityId, id),
            ));
        }

        return;
    };
}

export function getTerminationDetailsFieldGroups(): ThunkResult<Promise<void>> {
    return async (dispatch: Dispatch, getState: () => RootState) => {
        const currentLegalEntityId = getState().global.currentLegalEntityId;
        if (!currentLegalEntityId) {
            return;
        }

        dispatch(setTerminationDetailsFieldGroups(
            await ApiEmployee.getSeparationFieldGroups(currentLegalEntityId, constants.TerminationTypes.details),
        ));

        return;
    };
}

export function getTerminationPayoutFieldGroups(): ThunkResult<Promise<void>> {
    return async (dispatch: Dispatch, getState: () => RootState) => {
        const currentLegalEntityId = getState().global.currentLegalEntityId;
        if (!currentLegalEntityId) {
            return;
        }

        dispatch(setTerminationPayoutFieldGroups(
            await ApiEmployee.getSeparationFieldGroups(currentLegalEntityId, constants.TerminationTypes.payout),
        ));

        return;
    };
}

export function setTerminationDetailsFieldGroups(fieldGroups: object[]) {
    return {
        type: SET_TERMINATION_DETAILS_FIELD_GROUPS,
        payload: fieldGroups,
    };
}

export function setTerminationDetailsValues(values: object[] | null) {
    return {
        type: SET_TERMINATION_DETAILS_VALUES,
        payload: values,
    };
}

export function setTerminationPayoutFieldGroups(fieldGroups: object[]) {
    return {
        type: SET_TERMINATION_PAYOUT_FIELD_GROUPS,
        payload: fieldGroups,
    };
}

export function setTerminationPayoutValues(values: object[] | null) {
    return {
        type: SET_TERMINATION_PAYOUT_VALUES,
        payload: values,
    };
}

export function setSeparationErrors(errors: any) {
    return {
        type: SET_SEPARATION_ERRORS,
        payload: errors,
    };
}

export function setSeparationAction(action: string) {
    return {
        type: SET_SEPARATION_ACTION,
        payload: action,
    };
}

export function clearSeparation() {
    return (dispatch: Dispatch) => {
        dispatch(setTerminationDetailsValues([]));
        dispatch(setTerminationPayoutValues([]));
        dispatch(setTerminationPayoutFieldGroups([]));
    };
}

export function clearSeparationErros() {
    return (dispatch: Dispatch) => {
        dispatch(setSeparationErrors([]));
    };
}

export function resetSeparation() {
    return (dispatch: Dispatch) => {
        dispatch(setSeparationAction(constants.TerminationTypes.details));
    };
}

export function saveTerminationPayout(values: [], ids: Set<number>) {
    return async (dispatch: Dispatch, getState: () => RootState) => {
        const entityId = getState().global.currentLegalEntityId;
        let separationValues = getState().employees.terminationPayout.values.terminationFieldEntities;
        if (!entityId || !separationValues) {
            return;
        }
        dispatch(startLoading());
        dispatch(setSeparationErrors([]));

        separationValues = separationValues.map((separationValue: any) => {
            separationValue.values = separationValue.values.map((value: any) => {
                if (value.code === 'amount' && values[separationValue.entityId]) {
                    value.value = values[separationValue.entityId];
                }
                return value;
            });

            return separationValue;
        });

        try {
            for (const id of ids) {
                const response = await ApiEmployee.saveSeparation(
                    entityId,
                    id,
                    separationValues,
                    constants.TerminationTypes.payout,
                );
                if (response.data.errors) {
                    dispatch(
                        setSeparationErrors(response.data),
                    );
                } else {
                    dispatch(setSeparationAction(constants.TerminationTypes.details));
                    dispatch(setSeparationErrors([]));
                    toast.success(intl.get('separation_sent_ok'));
                }
            }
        } catch (e) {
            if (e.response.data.data) {
                dispatch(
                    setSeparationErrors(e.response.data),
                );
            } else {
                const errors = prepareErrors(e.response.data.errors);
                dispatch(
                    openErrorModal(errors, intl.get('separation_sent_nok')),
                );
            }
        }

        dispatch(stopLoading());

        return;

    };
}

export function saveTerminationDetails(values: [], ids: Set<number>) {
    return async (dispatch: Dispatch, getState: () => RootState) => {
        const entityId = getState().global.currentLegalEntityId;
        let separationValues = getState().employees.terminationDetails.values;
        if (!entityId || !separationValues) {
            return;
        }
        dispatch(startLoading());
        dispatch(setSeparationErrors([]));
        separationValues = separationValues.map((separationValue: any) => {
            separationValue.values = separationValue.values.map((value: ApiObject.FieldValue) => {
                if (values[separationValue.entity]) {
                    const entityValues: ApiObject.FieldValue[] = values[separationValue.entity];
                    const index = entityValues.findIndex((item: any) => item.code === value.code);
                    if (index !== -1) {
                        value.value = entityValues[index].value;
                    }
                }

                return value;
            });

            return separationValue;
        });

        try {
            for (const id of ids) {
                const response = await ApiEmployee.saveSeparation(
                    entityId,
                    id,
                    separationValues,
                    constants.TerminationTypes.details,
                );
                if (response?.errors) {
                    dispatch(
                        setSeparationErrors(response.errors),
                    );
                } else {
                    dispatch(
                        setSeparationAction(constants.TerminationTypes.payout),
                    );
                }
            }
        } catch (e) {
            if (e.response.data.data) {
                dispatch(
                    setSeparationErrors(e.response.data),
                );
            } else {
                const errors = prepareErrors(e.response.data.errors);
                dispatch(
                    openErrorModal(errors, intl.get('separation_sent_nok')),
                );
            }
        }
        dispatch(stopLoading());

        return;
    };
}
