import {ColorHelper} from '@library';
import React from "react";
import {Column} from "react-virtualized";
import {defaultInfiniteTableCellDataGetter, Label, InfiniteTableHeader} from "@common";
import {intl} from '@global';

const Status = () => (
    <Column
        dataKey="status"
        width={80}
        flexGrow={1}
        cellDataGetter={defaultInfiniteTableCellDataGetter}
        headerRenderer={() => <InfiniteTableHeader.OrderedAndFiltered label={intl.get('status')}/>}
        cellRenderer={({cellData}) => {
            return (
                <Label.Tag color={ColorHelper.employeeStatusColor(cellData.status)}>
                    <span style={{textTransform: 'capitalize'}}>{cellData.status}</span>
                </Label.Tag>
            )
        }}
    />
);

export default Status;
