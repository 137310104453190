import {ApiObject} from '@api';
import {constants, intl, stylingVariables} from '@global';
import {Actions} from '@common';
import {RouteHelper} from '@library';
import {NavTabs} from 'hrb-common';
import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';
import {RootState} from '../../rootReducer';
import {ReportsTab} from './constants';
import ReportModal from './Process/common/ReportModal/ReportModal'
import * as selectors from './selectors';
import {changeTemplate, setMode as setProcessMode} from "./Process/redux";

const StyledWrapper = styled.div`
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  overflow: hidden;
  
  .hrb-common__navigation__nav-tabs__wrapper {
  	button {
      font-weight: ${stylingVariables.hrb.fontWeight.semibold};
      font-size: ${stylingVariables.hrb.fontSize.mediumLargeBigger}!important;
	}
  }
  
  .hrb-common__plus-action__wrapper {
  	.hrb-common__plus-action__circle {
  		svg {
  			width: 15px;
  		}
  		
  		span {
  			font-size: ${stylingVariables.hrb.fontSize.mediumLargeBigger};
  		}
  	}
  }
`;

const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 20px 20px;
`;

interface IProps {
	activeTab: ReportsTab;
	legalEntityPeriods: ApiObject.LegalEntityPeriod[];

	changeTemplate: (template: ApiObject.ReportTemplate) => any;
	setProcessMode: (mode: constants.ReportProcessMode) => any;
}

interface IState {
	newReport: boolean;
	newReportTemplate: boolean;
}

export class Navigation extends PureComponent<IProps, IState> {
	public constructor(props: IProps) {
		super(props);

		this.state = {
			newReport: false,
			newReportTemplate: false
		};
	}

	private toggleModal(modalName: keyof IState) {
		this.setState({...this.state, [modalName]: !this.state[modalName]})
	}

	get tabs() {
		return [
			{
				key: ReportsTab.reports,
				label: intl.get('reports'),
				onSelect: RouteHelper.goToReports,
				renderContent: () => <></>,
			},
			{
				key: ReportsTab.report_templates,
				label: intl.get('report_templates'),
				onSelect: RouteHelper.goToReportTemplates,
				renderContent: () => <></>,
			}
		];
	}

	private tabActions = () => {
		switch (this.props.activeTab) {
			case ReportsTab.reports:
				return <Actions.PlusAction onClick={() => this.toggleModal('newReport')}
										   isDisabled={this.props.legalEntityPeriods.length === 0}
										   iconSize={12}>
					{intl.get('new_report')}
				</Actions.PlusAction>
			case ReportsTab.report_templates:
				return <Actions.PlusAction onClick={() => this.handleCreateTemplateAction()} iconSize={12}>
					{intl.get('new_template')}
				</Actions.PlusAction>
			default: return null;
		}
	}

	private renderNewReportModal() {
		return (
			<ReportModal onClose={() => this.toggleModal('newReport')} />
		)
	}

	private async handleCreateTemplateAction() {
		await this.props.changeTemplate({
			id: -1,
			label: intl.get('custom'),
			isTemporary: false,
			employees: {
				queries: []
			},
			columns: [],
			grouping: [
				'$employee.reference_code'
			],
			visibility: ApiObject.ReportVisibility.public,
			labelFormula: '',
			ownerEmployee: {
				id: -1,
				firstName: '',
				lastName: '',
				referenceCode: ''
			},
		} as ApiObject.ReportTemplate);

		await this.props.setProcessMode(constants.ReportProcessMode.createTemplate);

		RouteHelper.goToReportSelectData();
	}

	public render() {
		return (
			<StyledWrapper>
				<StyledHeader>
					<h4>{intl.get('reports')}</h4>

					{this.tabActions()}
				</StyledHeader>

				<NavTabs
					reselect={true}
					activeTab={this.props.activeTab}
					tabs={this.tabs} />

				{this.state.newReport ? this.renderNewReportModal() : null}

			</StyledWrapper>
		);
	}
}

const mapStateToProps = (state: RootState) => ({
	legalEntityPeriods: selectors.getLegalEntityPeriods(state),
});

const mapDispatchToProps = {
	setProcessMode,
	changeTemplate,
};

export default connect(mapStateToProps,mapDispatchToProps)(Navigation);
