import React, {PureComponent} from 'react';

import {stylingVariables, intl} from '@global';
import {Modal as CommonModal} from '@common';

interface IProps {
    onClickDelete: () => void;
    onClickCancel: () => void;
}

export default class Modal extends PureComponent<IProps> {
    render() {
        return (
            <CommonModal.CenterModal width={'550px'} minWidth={'550px'} onClickClose={this.props.onClickCancel}>
                <CommonModal.StyledHeader color={stylingVariables.colorPalette.darkest}>
                    <h4>{intl.get('delete_dependent')}</h4>
                </CommonModal.StyledHeader>

                <CommonModal.OverflowableContent style={{padding: '20px 40px'}}>
                    {intl.get('delete_dependent_confirmation')}
                </CommonModal.OverflowableContent>

                <CommonModal.StyledActionBar style={{paddingTop: 20}}>
                    <CommonModal.StyledCancelButton onClick={this.props.onClickCancel}>
                        {intl.get('cancel')}
                    </CommonModal.StyledCancelButton>

                    <CommonModal.StyledOkButton onClick={this.props.onClickDelete}>
                        {intl.get('delete')}
                    </CommonModal.StyledOkButton>
                </CommonModal.StyledActionBar>
            </CommonModal.CenterModal>
        );
    }
}
