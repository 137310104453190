import {ApiObject} from '@api';
import {Back, Actions, Icon} from '@common';
import {stylingVariables, constants, interfaces, intl} from '@global';
import {ReportTemplatesHelper, RouteHelper} from '@library';
import React, {PureComponent, Fragment} from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';
import {RootState} from '../../../../rootReducer';
import Delete from '../../ReportTemplates/Table/Column/Delete';
import * as reportsSelectors from '../../selectors';
import {isReportProcesses, isTemplateProcesses} from '../../utils';
import GenerateReportModal from '../common/GenerateReportModal/GenerateReportModal';
import VisibilitySelector from '../common/VisibilitySelector/VisibilitySelector';
import {changePeriods, changeTemplate, setMode as setProcessMode} from '../redux'
import * as selectors from '../selectors';
import PreviewGrid from './PreviewGrid';
import moment from 'moment'
import DateRangeSelector from '../common/DateRangeSelector/DateRangeSelector'

const Heading = styled.div`
	margin-bottom: 20px;
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

const HeadingBreadCrumbs = styled.div`
	display: inline-flex;
  	align-items: center;
`;
const HeadingOptions = styled.div`
	display: inline-flex;
  	align-items: center;
`;

const Title = styled.div`
	margin-left: 10px;
	font-weight: ${stylingVariables.fontWeight.semibold};
	font-size: ${stylingVariables.fontSize.mediumLarge};
	.page_title {
		font-size: ${stylingVariables.fontSize.largest};
	}
`;

const PageActions = styled.div`
	width: 100%;
	padding: 40px;
	display: flex;
	justify-content: flex-end;
`;
const TemplateActions = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
`;
const DeleteButton = styled.div`
	cursor: pointer;
	display: flex;
	align-items: center;
	color: ${stylingVariables.colorPalette.red};
	font-size: ${stylingVariables.fontSize.default};
`;

interface IProps {
	template: ApiObject.ReportTemplate|null;
	periods: interfaces.Periods;
	mode: constants.ReportProcessMode;
	reportName: string;

	legalEntityPeriods: ApiObject.LegalEntityPeriod[];
	reportProcessChangePeriods: (periods: interfaces.Periods) => any;
	changeTemplate: (template: ApiObject.ReportTemplate) => any;
	setProcessMode: (mode: constants.ReportProcessMode) => any;
}

interface IState {
	rawPeriods: interfaces.Periods;
	periods: interfaces.Periods;

	deleteModal: boolean;
	updateModal: boolean;
	generateModal: boolean;
}

export class Preview extends PureComponent<IProps, IState> {
	constructor(props: IProps) {
		super(props);

		const propsPeriods = props.periods;

		this.state = {
			deleteModal: false,
			updateModal: false,
			generateModal: false,
			rawPeriods: propsPeriods,
			periods: propsPeriods && propsPeriods.length > 0 ? propsPeriods : [
				{
					beginDate: moment().startOf('month').format('YYYY-MM-DD'),
					endDate: moment().endOf('month').format('YYYY-MM-DD'),
				}
			]
		}
	}

	static getDerivedStateFromProps(nextProps: any, prevState: any) {
		if (nextProps && nextProps.periods && JSON.stringify(prevState.rawPeriods) !== JSON.stringify(nextProps.periods)) {
			return {
				rawPeriods: nextProps.periods,
				periods: nextProps.periods
			}
		}

		return null;
	}

	onChangePeriods = (periods: interfaces.Periods) => {
		if (periods.length === 0) {
			return null;
		}
		if (isReportProcesses(this.props.mode)) {
			this.props.reportProcessChangePeriods(periods);
		}
		this.setState({periods});
	}

	public render() {
		if (!this.props.template) {
			return null;
		}

		return (
			<Fragment>
				<Heading>
					<HeadingBreadCrumbs>
						<Back.Button />
						<Title>
							<span className={'page_title'}>{ReportTemplatesHelper.pageTitleByMode(this.props.mode)} / </span> {this.props.reportName}
						</Title>
					</HeadingBreadCrumbs>
					<HeadingOptions>
						{!isTemplateProcesses(this.props.mode) && <DateRangeSelector />}
						{isTemplateProcesses(this.props.mode) &&
							<VisibilitySelector
								forTemplate={true}
								style={{
									flexDirection: 'row',
									alignItems: 'center',
								}}
							/>
						}
					</HeadingOptions>
				</Heading>

				<PreviewGrid template={this.props.template} periods={this.state.periods} />

				<PageActions>
					{this.renderActions()}

					{this.state.generateModal && <GenerateReportModal
						template={this.props.template}
						onClose={() => this.toggleModal('generateModal')}
					/>}
					{this.state.deleteModal && <Delete
						isOpen={this.state.deleteModal}
						onAfterDelete={RouteHelper.goToReportTemplates}
						reportId={this.props.template.id}
						onClose={() => {this.toggleModal('deleteModal')}}
					/>}
				</PageActions>
			</Fragment>
		);
	}

	renderActions = () => {
		const { template } = this.props;
		if (!template) {
			return null;
		}

		if (isReportProcesses(this.props.mode)) {
			return <Actions.GrayButton onClick={RouteHelper.goBack}>
				{intl.get('close')}
			</Actions.GrayButton>
		}

		const isTemporaryTemplate: boolean = template.id === -1;
		const isOwner = ReportTemplatesHelper.isCurrentUserOwnerOnTemplate(template);

		return <TemplateActions>
			<div>
				{!isTemporaryTemplate && isOwner && <DeleteButton onClick={() => this.toggleModal('deleteModal')}>
					<Icon type={constants.IconTypes.TRASH} height={14} width={14} fillColor={stylingVariables.colorPalette.red} />
					{intl.get('delete_template')}
				</DeleteButton> }
			</div>
			<div>
				{!isTemporaryTemplate && isOwner && <Actions.ColorButton onClick={this.onClickUpdateTemplate} color={stylingVariables.colorPalette.green}>
					{intl.get('edit_template')}
				</Actions.ColorButton> }

				<Actions.ColorButton onClick={() => this.toggleModal('generateModal')} color={stylingVariables.colorPalette.orange}>
					{intl.get('generate_report')}
				</Actions.ColorButton>
			</div>
		</TemplateActions>
	}

	toggleModal = (modalName: keyof IState) => {
		this.setState({...this.state, [modalName]: !this.state[modalName]})
	}

	public onClickUpdateTemplate = () => {
			this.props.changeTemplate(this.props.template as ApiObject.ReportTemplate)
			this.props.setProcessMode(constants.ReportProcessMode.editTemplate)
			RouteHelper.goToReportSelectData();
	}
}

const mapStateToProps = (state: RootState) => ({
	periods: selectors.getPeriods(state),
	template: selectors.getTemplate(state),
	mode: selectors.getMode(state),
	reportName: selectors.getReportName(state),

	legalEntityPeriods: reportsSelectors.getLegalEntityPeriods(state),
});

const mapDispatchToProps = {
	reportProcessChangePeriods: changePeriods,
	setProcessMode,
	changeTemplate,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(Preview);
