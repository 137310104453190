import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {intl} from '@global';
import {get} from 'lodash';

import {constants, interfaces, stylingVariables} from '@global';
import {Modal} from '@common';
import {ApiObject, PayrollInterfaces} from '@api';
import {CloseButtonModal} from 'hrb-common';
import * as modalSelectors from '../selectors';
import * as selectors from '../../../../selectors';
import * as globalSelectors from '../../../../../selectors';

import {RootState} from '../../../../../../rootReducer';
import {closeSalaryModal, savePayElement, deletePayElement, setErrorFields} from '../../../../redux';

import BaseFields from './BaseFields';
import AdvancedFields from './AdvancedFields';
import {AccessManager} from '@library';
import moment from 'moment';
import styled from 'styled-components';

export const StyledActionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
`;

const StyledHeader = styled(Modal.StyledHeader)<{isEdit?: boolean}>`
  border: ${({isEdit}) => !isEdit && 'none' };

  h4 {
    font-size: ${stylingVariables.fontSize.big};
    font-weight: ${stylingVariables.fontWeight.bold};
    color: ${stylingVariables.colorPalette.darkest};
  }
`

const CloseModalBtnWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding: 19px 30px;
  border-bottom-left-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color .5s;

  &:hover {
    background-color: ${stylingVariables.colorPalette.lightGray};
    transition: background-color .5s;

    .hrb-common__modal__close-btn { 
        background-color: ${stylingVariables.colorPalette.deepGreen};
    }
  }

  .hrb-common__modal__close-btn {
    background-color: ${stylingVariables.colorPalette.green};
    position: static;
    align-items: center;

    svg {
        fill: ${stylingVariables.colorPalette.white};
        width: 12px;
        height: 12px;
    }
  }
`;

const StyledActionBar = styled(Modal.StyledActionBar)`
  border-top: none;
`

const ModalWrapper: React.FC<{isEdit: boolean, isReadOnly: boolean, onClickClose: () => void}> = ({ children, isEdit, isReadOnly, onClickClose }) => {
  return isEdit ?
    <Modal.RightSideModal zIndex={1000} shouldSupportCloseWhenClickOutside={isReadOnly} onClickClose={onClickClose}>{children}</Modal.RightSideModal> :
    <Modal.CenterModal width={'600px'} onClickClose={onClickClose}>{children}</Modal.CenterModal>
}

interface IProps {
    modalFields: ApiObject.Field[];
    baseFields: ApiObject.Field[];
    advancedFields: ApiObject.Field[];
    payElements: ApiObject.PayElement[];
    payElementType: string | undefined;
    modalLabel: string;

    isModalOpen: boolean;
    isLoading: boolean;
    employeeId: number;

    values?: ApiObject.EmployeePayElement;
    errors: ApiObject.EntityFieldErrorMessage[];

    employeePayElements: ApiObject.EmployeePayElement[];

    closeSalaryModal: () => void;
    setErrorFields: (errors: ApiObject.EntityFieldErrorMessage[]) => void
    savePayElement: (employeeId: number, employeePayElements: any[]) => void;
    deletePayElement: (employeeId: number, employeePayElements: any[]) => void;
    currentLegalEntityId: number|null;
    currentPayrollPeriod: PayrollInterfaces.PayrollPeriod|null;

    permissions: interfaces.Permissions;
    payelementList: ApiObject.PayElement[];
}

interface IState {
    updatedFields: ApiObject.FieldValue[];
    isOpenConfirmationModal: boolean;
    isOpenDeleteConfirmationModal: boolean;
    selectedPayElement?: ApiObject.PayElement;
    startDateBeforePeriodBegin: boolean;
    isEditModal: boolean;
    effectiveDateMissing: boolean;
}

export class Edit extends PureComponent<IProps, IState> {
    public constructor(props: IProps) {
        super(props);

        this.state = {
            updatedFields: [],
            isOpenConfirmationModal: false,
            isOpenDeleteConfirmationModal: false,
            startDateBeforePeriodBegin: this.checkStartDate(props),
            isEditModal: this.isEditModal(props),
            effectiveDateMissing: false,
        };
    }

    private retroactiveField = {
        code:  ApiObject.EmployeePayElementValues.calculateRetroactiveAmount,
        options: [
            {key: 'true', label: intl.get('yes')},
            {key: 'false', label: intl.get('no')},
        ],
    }

    render() {
        if (!this.props.modalFields || !this.props.isModalOpen) {
            return null;
        }

        return  (
            <>
                <ModalWrapper onClickClose={this.close} isEdit={this.state.isEditModal} isReadOnly={this.isReadOnly()}>
                    {this.state.isEditModal &&
                        <CloseModalBtnWrapper>
                            <CloseButtonModal onClick={this.close} />
                        </CloseModalBtnWrapper>
                    }

                    <StyledHeader isEdit={this.state.isEditModal}>
                        <h4>{get(this.props.values, 'label') || `Add "${this.props.modalLabel}" element`}</h4>
                    </StyledHeader>

                    <Modal.OverflowableContent padding={'0 40px 0 40px'}>
                        <BaseFields
                            fields={this.props.baseFields}
                            errors={this.props.errors}
                            updateField={this.updateField}
                            isFieldDisabled={this.isFieldDisabled}
                            getDefaultValueByField={this.getDefaultValueByField}
                            getPlaceholderValueByField={this.getPlaceholderValueByField}
                            selectedPayElement={this.state.selectedPayElement}
                            isEditModal={this.state.isEditModal}
                            currentPayrollPeriod={this.props.currentPayrollPeriod}
                            effectiveDateMissing={this.state.effectiveDateMissing}
                        />

                        <AdvancedFields
                            retroactiveField={this.retroactiveField}
                            updateRetroactive={this.updateRetroactive}
                            showRetroactive={this.state.startDateBeforePeriodBegin}
                            fields={this.props.advancedFields}
                            errors={this.props.errors}
                            updateField={this.updateField}
                            isFieldDisabled={this.isFieldDisabled}
                            getDefaultValueByField={this.getDefaultValueByField}
                            isEditModal={this.state.isEditModal}
                            isOpened={this.props.payElementType === constants.CompensationTypes.recurringDeductions}
                        />
                    </Modal.OverflowableContent>

                    <StyledActionBar>
                        {this.actions(this.props.values?.id)}
                    </StyledActionBar>

                </ModalWrapper>

                <Modal.ConfirmationModal
                    title={'warning'}
                    confirmationMessage={'recurring_deductions_save_confirmation'}
                    acceptBtnTitle={'save'}
                    isOpen={this.state.isOpenConfirmationModal}
                    onClickAccept={this.closeConfirmationModalAndSave}
                    onClickClose={this.closeConfirmationModal}
                />

                <Modal.ConfirmationModal
                    title={'warning'}
                    confirmationMessage={'pay_element_change_delete_confirmation'}
                    acceptBtnTitle={'delete'}
                    isOpen={this.state.isOpenDeleteConfirmationModal}
                    onClickAccept={this.closeConfirmationModalAndDelete}
                    onClickClose={this.closeDeleteConfirmationModal}
                />
            </>
        );
    }

    UNSAFE_componentWillReceiveProps(props: any) {
        const beforePeriodBegin = this.checkStartDate(props)
        const isEditModal = this.isEditModal(props)

        this.updateRetroactive(beforePeriodBegin)
        this.setState({startDateBeforePeriodBegin: beforePeriodBegin, isEditModal})
    }

    private checkStartDate = (props: any): boolean => {
        const startDateField = props.baseFields.find((field: ApiObject.Field) => {
            return field.code === ApiObject.EmployeePayElementValues.start_date
        })

        if (startDateField) {
            const value = this.getDefaultValueByField(startDateField, props)

            if (props.currentPayrollPeriod?.beginDate && value &&
                moment(value).isBefore(moment(props.currentPayrollPeriod.beginDate, 'YYYY-MM-DD')))
            {
                return true
            }
        }

        return false
    }

    private isEditModal = (props: any): boolean => {
        const output = props.values && Object.entries(props.values).length !== 0 ? true : false
        return output
    }

    public actions = (elementId?: number|null) => {
        if (this.isReadOnly()){
            return null;
        }

        return <StyledActionWrapper>
            <div>
                <Modal.StyledCancelButton onClick={this.close}>
                    {intl.get('cancel')}
                </Modal.StyledCancelButton>
                <Modal.StyledOkButton onClick={this.onSave} isDisabled={this.props.isLoading}>
                    {intl.get('save')}
                </Modal.StyledOkButton>
            </div>

            {elementId &&
                <Modal.StyledDeleteButton onClick={this.onDelete} isDisabled={this.props.isLoading}>
                    {intl.get('delete')}
                </Modal.StyledDeleteButton>
            }
        </StyledActionWrapper>;
    }

    private isFieldDisabled = (field: ApiObject.Field): boolean => {
        if (field.code === ApiObject.EmployeePayElementValues.pay_unit) {
            return true;
        }

        if (field.code === ApiObject.EmployeePayElementValues.start_date && this.props.values
            && Object.entries(this.props.values).length !== 0) {
            return true;
        }

        if (field.code === ApiObject.EmployeePayElementValues.currency) {
            let account = get(this.props.values, ApiObject.EmployeePayElementValues.account) ?? -1;
            if (account < 0) {
                account = this.state.updatedFields
                    .find(x => x.code === ApiObject.EmployeePayElementValues.account)?.value ?? -1;
            }
            const payElement = this.getPayElementByAccount(account);
            if (!payElement) {
                return false;
            }
            if (payElement.currency) {
                return true;
            }
        }

        if (this.isReadOnly()){
            return true;
        }

        return this.props.values
            ? (!field.allowEditIfFilled && get(this.props.values, field.code))
            : false;
    }

    private isReadOnly = (): boolean => {
       return !AccessManager.hasPermission(
            this.props.permissions,
            constants.PermissionCode.EMPLOYEE_FIELDS_EDIT,
        );
    }

    protected onSave = () => {
        this.isGoalAmountOrEndDateSet() ? this.save() : this.showConfirmationModal();
    }

    protected onDelete = () => {
        this.showDeleteConfirmationModal();
    }

    protected closeDeleteConfirmationModal = () => {
        this.setState({ isOpenDeleteConfirmationModal: false});
    }

    protected showDeleteConfirmationModal = () => {
        this.setState({ isOpenDeleteConfirmationModal: true });
    }

    protected closeConfirmationModalAndDelete = () => {
        this.closeDeleteConfirmationModal();
        this.delete();
    }

    protected closeConfirmationModalAndSave = () => {
        this.closeConfirmationModal();
        this.save();
    }

    protected showConfirmationModal = () => {
        this.setState({ isOpenConfirmationModal: true });
    }

    protected closeConfirmationModal = () => {
        this.setState({ isOpenConfirmationModal: false});
    }

    protected updateRetroactive = (value: Boolean) => {
        const calculateRetroactiveAmount = value && this.props.payElementType?.includes('recurring')

        const field = this.retroactiveField
        this.setState((prevState) => {
            const updatedFields = [...prevState.updatedFields];
            const existedField = updatedFields.find((item: any) => item.code === field.code);
            if (!existedField) {
                updatedFields.push({code: field.code, value: calculateRetroactiveAmount});
            } else {
                existedField.value = calculateRetroactiveAmount;
            }
            return {updatedFields};
        });
    }

    protected updateField = (field: ApiObject.Field, value: any) => {
        if (!value || value.length === 0) {
            value = null;
        }

        this.setState((prevState) => {
            const updatedFields = [...prevState.updatedFields];
            const existedField = updatedFields.find((item: any) => item.code === field.code);
            if (!existedField) {
                updatedFields.push({code: field.code, value});
            } else {
                existedField.value = value;
            }
            return {updatedFields};
        });

        if (field.code === ApiObject.EmployeePayElementValues.start_date) {
            this.setState({effectiveDateMissing: false})
            if (this.props.currentPayrollPeriod?.beginDate &&
                moment(value).isBefore(moment(this.props.currentPayrollPeriod.beginDate, 'YYYY-MM-DD')))
            {
                this.setState({startDateBeforePeriodBegin: true})
                this.updateRetroactive(true)
            }
            else {
                this.setState({startDateBeforePeriodBegin: false})
                this.updateRetroactive(false)
            }
        }

        this.afterUpdateField(field, value);
    }

    protected updateFieldByCode = (code: string, value: any) => {
        if (!this.props.modalFields) {
            return;
        }

        const field = this.props.modalFields.find(f => f.code === code);
        if (!field) {
            return;
        }

        this.updateField(field, value);
    }

    protected afterUpdateField = async(field: ApiObject.Field, value: any): Promise<boolean> => {
        if (field.code === ApiObject.EmployeePayElementValues.account) {
            const payElement = this.getPayElementByAccount(value) ?? undefined;
            this.setState({
                selectedPayElement: payElement,
            });
            if (!payElement) {
                return false;
            }
            this.updateFieldByCode(ApiObject.EmployeePayElementValues.currency, payElement.currency);
            this.updateFieldByCode(ApiObject.EmployeePayElementValues.pay_unit, payElement.payUnit);
        }

        return true;
    }

    private getPayElementByAccount = (account: number) => {
        return this.props.payElements.find(p => p.account === Number(account)) || null;
    }

    private save = async() => {
        if (!this.props.payElementType) {
            return false;
        }

        const startDateField = this.state.updatedFields.find((field) => {
            return field.code === ApiObject.EmployeePayElementValues.start_date
        })

        if (!startDateField) {
            this.setState({effectiveDateMissing: true})
            return false;
        }


        let payElementBase: any;
        const employeePayElement: any = this.props.employeePayElements.find(p => {
            return p.account === Number(this.props.values?.account) || null
        })


        if (this.props.values) {
            const existingPayElement = this.props.values;
            payElementBase = {
                rootEmployeePayElement: {
                    id: existingPayElement.rootEmployeePayElementId,
                },
                calculationType: employeePayElement.calculationType
            };
            this.props.modalFields.forEach(field => {
                payElementBase[field.code] = existingPayElement[field.code as keyof ApiObject.EmployeePayElement];
            });
        } else {
            payElementBase = {
                rootEmployeePayElement: {
                    createNew: true,
                },
            };
        }

        const payElementChanges: any = {};
        this.state.updatedFields
            .forEach(updatedField => {
                let value;
                switch (updatedField.code) {
                    case ApiObject.EmployeePayElementValues.pay_unit:
                        return;
                    case ApiObject.EmployeePayElementValues.start_date:
                    case ApiObject.EmployeePayElementValues.end_date:
                        value = moment(updatedField.value).format(constants.SYSTEM_DATE_FORMAT);
                        break;
                    case ApiObject.EmployeePayElementValues.amount:
                    case ApiObject.EmployeePayElementValues.total:
                    case ApiObject.EmployeePayElementValues.balance:
                    case ApiObject.EmployeePayElementValues.account:
                        value = Number(updatedField.value);
                        break;
                    default:
                        value = updatedField.value;
                        break;
                }
                payElementChanges[updatedField.code] = value;
            });


        if (!payElementChanges[ApiObject.EmployeePayElementValues.end_date]) {
          payElementChanges[ApiObject.EmployeePayElementValues.end_date] =
            get(
              this.props.values,
              ApiObject.EmployeePayElementValues.end_date
            )
        }

        if (this.props.payelementList.length && payElementChanges.account) {
          const filterCalulationType = this.props.payelementList.find(
            ({ account }) => account === payElementChanges.account
          );
          payElementChanges[ApiObject.EmployeePayElementValues.calculation_type] =
            filterCalulationType?.calculationType;
        }

        const response = await this.props.savePayElement(this.props.employeeId, [{
            ...payElementBase,
            ...payElementChanges,
        }]);


        if ((!this.props.errors || !this.props.errors.length) && response){
            this.close();
        }
    }

    private delete = async() => {
        if (!this.props.payElementType || !this.props.values) {
            return false;
        }

        const existingPayElement = this.props.values;

        const payElementChangeDelete = {
            rootEmployeePayElementId: existingPayElement.rootEmployeePayElementId,
            employeeId: existingPayElement.employeeId,
            changeId: existingPayElement.id,
        };

        await this.props.deletePayElement(this.props.employeeId, [payElementChangeDelete]);

        if (!this.props.errors || !this.props.errors.length){
            this.close();
        }
    }

    private close = () => {
        this.props.setErrorFields([]);
        this.props.closeSalaryModal();
        this.setState({updatedFields: [], effectiveDateMissing: false});
    }

    protected getPlaceholderValueByField = (field: ApiObject.Field) => {
        if (field.type === ApiObject.FieldType.date && this.props.currentPayrollPeriod) {
            return get(this.props.values, field.code) || this.props.currentPayrollPeriod.beginDate;
        }

        if (field.defaultValue) {
            return field.defaultValue;
        }

        return;
    }

    protected getDefaultValueByField = (field: ApiObject.Field, props?:IProps) => {
        let usableValues = this.props.values
        if (props) {
            usableValues = props.values
        }

        switch (field.code) {
            case ApiObject.EmployeePayElementValues.account:
                const account = get(usableValues, field.code);
                return account ? account.toString() : '';
            case ApiObject.EmployeePayElementValues.gross_up:
                const isGrossUp = get(usableValues, field.code);
                return isGrossUp ? 'true' : 'false';
            case ApiObject.EmployeePayElementValues.currency:
                let currency = get(usableValues, field.code);
                if (!currency) {
                    currency = this.state.updatedFields.find(x => x.code === field.code)?.value;
                }
                return currency;
            case ApiObject.EmployeePayElementValues.pay_unit:
                let payUnit = get(usableValues, field.code);
                if (!payUnit) {
                    payUnit = this.state.updatedFields.find(x => x.code === field.code)?.value;
                }
                return payUnit;
            case ApiObject.EmployeePayElementValues.end_date:
              let date = get(usableValues, field.code);
              return date
            default:
                return get(usableValues, field.code);
        }
    }

    private isGoalAmountOrEndDateSet = () => {
        if (!this.props.modalFields) {
            return true;
        }

        if (this.props.payElementType !== constants.CompensationTypes.recurringDeductions) {
            return true;
        }

        if (this.isGoalAmountSet() && this.isEndDateSet()){
            return false;
        }

        return !(!this.isGoalAmountSet() && !this.isEndDateSet());
    }

    private isGoalAmountSet = () => {
        const total = this.state.updatedFields.find(x => x.code === ApiObject.EmployeePayElementValues.total);

        if (!total && get(this.props.values, ApiObject.EmployeePayElementValues.total)) {
            return true;
        }

        return !!(total && total.value);
    }

    private isEndDateSet = () => {
        const endDate = this.state.updatedFields.find(x => x.code === ApiObject.EmployeePayElementValues.end_date);

        if (!endDate && get(this.props.values, ApiObject.EmployeePayElementValues.end_date)) {
            return true;
        }

        return !!(endDate && endDate.value);
    }
}

const mapStateToProps = (state: RootState, ownProps: any) => ({
    employeeId: selectors.getCurrentEmployeeId(state),
    currentLegalEntityId: globalSelectors.getCurrentLegalEntityId(state),
    currentPayrollPeriod: globalSelectors.getCurrentPayrollPeriod(state),
    isLoading: globalSelectors.isLoading(state),
    payElements: selectors.getLocalPayElements(state),
    employeePayElements: selectors.getEmployeePayElements(state),

    isModalOpen: modalSelectors.isSalaryModalOpen(state),
    modalLabel: modalSelectors.getSalaryModalLabel(state),
    payElementType: modalSelectors.getPayElementType(state),
    modalFields: modalSelectors.getPayElementFormFields(state, ownProps),
    baseFields: modalSelectors.getSalaryModalBaseFields(state, ownProps),
    advancedFields: modalSelectors.getSalaryModalAdvancedFields(state, ownProps),
    values: modalSelectors.getSalaryModalValues(state),

    errors: selectors.getErrorsFields(state),

    permissions: state.global.permissions,
    payelementList: selectors.getPayElements(state)
});

const mapDispatchToProps = {
    closeSalaryModal,
    savePayElement,
    deletePayElement,
    setErrorFields,
};

export default connect(mapStateToProps, mapDispatchToProps)(Edit);
