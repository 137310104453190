import React, {PureComponent, ChangeEvent} from 'react';
import styled from 'styled-components';

import {stylingVariables} from '@global';

const StyledTextAreaWrapper = styled.div<{height?: string}>`
  display: flex;
  flex-direction: column;
  label {
    font-size: ${stylingVariables.fontSize.medium};
    font-weight: ${stylingVariables.fontWeight.regular};
    margin-bottom: 5px;
  }
  textarea {
    display: block;
    font-size: ${stylingVariables.fontSize.medium};
    width: 100%;
    height: ${props => props.height || '150px'};
    border: 0;
    line-height: 1.1rem;
    outline: none;
    border-bottom: 2px solid ${stylingVariables.colorPalette.gray};
    padding: 10px 0;
    resize: none;
    &:focus {
      border-bottom: 2px solid ${stylingVariables.colorPalette.green};
    }
    &::-webkit-input-placeholder {
      font-style: italic;
    }
    &::-moz-placeholder {
      font-style: italic;
    }
    &:-ms-input-placeholder {
      font-style: italic;
    }
    &:-moz-placeholder {
      font-style: italic;
    }
  }
`;

interface TextAreaProps {
    label: string;
    placeholder?: string;

    isRequired?: boolean;
    defaultValue?: string;
    onChange?: (event: ChangeEvent<HTMLTextAreaElement>) => void;

    height?: string;
}

export default class TextArea extends PureComponent<TextAreaProps> {
    render() {
        return (
            <StyledTextAreaWrapper height={this.props.height}>
                <label>
                    {this.props.label} {this.props.isRequired && '*'}
                </label>

                <textarea
                    placeholder={this.props.placeholder}
                    defaultValue={this.props.defaultValue}
                    onChange={this.props.onChange}
                />
            </StyledTextAreaWrapper>
        );
    }
}
