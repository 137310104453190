import styled from 'styled-components';

import {stylingVariables} from '@global';
import BlankButton from '../BlankButton';
import React from 'react';

export const OverflowableContent = styled.div<{padding?: string}>`
  padding: ${props => props.padding || 0};
`;

export const StyledHeader = styled.div<{color?: string, padding?: string, noBorder?: boolean}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${props => props.padding || '20px 40px'};
  border-bottom: ${props => props.noBorder ? 'none' : `1px solid ${stylingVariables.colorPalette.darkGray}`};
  h4 {
    color: ${props => props.color || stylingVariables.colorPalette.green};
    line-height: 1.4em;
  }
`;

export const StyledGentHeaderTitle = styled.span`
  font-size: 20px;
  font-weight: 600;
  color: ${props => props.color || stylingVariables.colorPalette.darkest};
`;

export const StyledHeaderIcon = styled.div`
  height: 64px;
  width: 64px;
  background-color: ${stylingVariables.colorPalette.gray};
  border-radius: 50% 50% 50% 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
`;

export const StyledActionBar = styled.div<{noBorder?: boolean}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 40px;
  border-top: ${props => props.noBorder ? 'none' : `1px solid ${stylingVariables.colorPalette.darkGray}`};
  margin-top: auto;
  ${BlankButton} {
    margin: 0 10px;
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
`;

export const StyledActionRightBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 20px 40px;
  border-top: 1px solid ${stylingVariables.colorPalette.darkGray};
  ${BlankButton} {
    margin: 0 10px;
  }
`;

export const StyledModalActionBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 20px 40px;
  ${BlankButton} {
    margin: 0 10px;
  }
`;

export const StyledOkButton = styled(props => <BlankButton {...props} />)`
  padding: 10px 20px;
  background-color: ${stylingVariables.colorPalette.orange};
  color: ${stylingVariables.colorPalette.white};
  border-radius: 20px;
  font-size: ${stylingVariables.fontSize.default};
  font-weight: ${stylingVariables.fontWeight.regular};
  &:hover {
    background-color: ${stylingVariables.colorPalette.darkOrange};
  }
`;

export const StyledCancelButton = styled(props => <BlankButton {...props} />)`
  color: ${stylingVariables.colorPalette.dark};
  padding: 10px 20px;
  border: 1px solid ${stylingVariables.colorPalette.dark};
  background-color: ${stylingVariables.colorPalette.white};
  border-radius: 20px;
  font-size: ${stylingVariables.fontSize.default};
  &:hover {
    background-color: ${stylingVariables.colorPalette.darkest};
		color: ${stylingVariables.colorPalette.white};
  }
`;

export const StyledDeleteButton = styled(props => <BlankButton {...props} />)`
  color: ${stylingVariables.colorPalette.white};
  padding: 10px 20px;
  border: 1px solid ${stylingVariables.colorPalette.gray};
  background-color: ${stylingVariables.colorPalette.red};
  border-radius: 20px;
  font-size: ${stylingVariables.fontSize.default};
  font-weight: ${stylingVariables.fontWeight.regular};
  &:hover {
    background-color: ${stylingVariables.colorPalette.darkRed};
  }
`;

export const StyledWhiteButton = styled(props => <BlankButton {...props} />)`
  color: ${stylingVariables.colorPalette.dark};
  padding: 7px 30px;
  border: 1px solid ${stylingVariables.colorPalette.dark};
  background-color: ${stylingVariables.colorPalette.white};
  border-radius: 20px;
  font-size: ${stylingVariables.fontSize.mediumSmall};
  &:hover {
    box-shadow: ${stylingVariables.layout.boxShadow};
  }
`;

export const StyledCloseModalButton = styled(props => <BlankButton {...props} />)`
  color: ${stylingVariables.colorPalette.dark};
  padding: 7px 30px;
  border: 1px solid ${stylingVariables.colorPalette.dark};
  background-color: ${stylingVariables.colorPalette.white};
  border-radius: 20px;
  font-size: ${stylingVariables.fontSize.default};
  &:hover {
    background-color: ${stylingVariables.colorPalette.gray};
  }
`;

export const StyledRejectButton = styled(props => <BlankButton {...props} />)`
  color: ${stylingVariables.colorPalette.white};
  padding: 7px 30px;
  border: 1px solid ${stylingVariables.colorPalette.gray};
  background-color: ${stylingVariables.colorPalette.red};
  border-radius: 20px;
  font-size: ${stylingVariables.fontSize.mediumSmall};
  &:hover {
    background-color: ${stylingVariables.colorPalette.darkRed};
  }
`;

export const StyledApproveButton = styled(props => <BlankButton {...props} />)`
  color: ${stylingVariables.colorPalette.white};
  padding: 7px 30px;
  border: 1px solid ${stylingVariables.colorPalette.gray};
  background-color: ${stylingVariables.colorPalette.green};
  border-radius: 20px;
  font-size: ${stylingVariables.fontSize.mediumSmall};
  &:hover {
    background-color: ${stylingVariables.colorPalette.darkGreen};
  }
`;

export const StyledAddButton = styled(props => <BlankButton {...props} />)`
  padding: 7px 30px;
  background-color: ${stylingVariables.colorPalette.orange};
  color: ${stylingVariables.colorPalette.white};
  border-radius: 20px;
  font-size: ${stylingVariables.fontSize.mediumSmall};
  font-weight: ${stylingVariables.fontWeight.regular};
  &:hover {
    background-color: ${stylingVariables.colorPalette.darkOrange};
  }
`;
