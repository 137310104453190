import React, {PureComponent} from 'react';
import styled from 'styled-components';
import {stylingVariables} from '@global';

const Wrapper = styled.div`
	width: 100%;
  	background-color: ${stylingVariables.hrb.colorPalette.white};
  	border-radius: 30px;
`;

const Items = styled.div`
	display: flex;
	flex-direction: row;
	user-select: none;
	padding: 10px;
`;

const Item = styled.div<{isActive: boolean}>`
	display: flex;
	align-items: center;
	justify-content: center;
	min-width: 100px;
	height: 40px;
	font-size: ${stylingVariables.hrb.fontSize.medium};
	padding: 7px;
	color: ${stylingVariables.hrb.colorPalette.darkest};
	border-radius: 20px;
	cursor: pointer;
	font-weight: ${stylingVariables.hrb.fontWeight.semibold};
	font-size: ${stylingVariables.hrb.fontSize.mediumLargeBigger};
	white-space: nowrap;

	${props => props.isActive ? `
		background-color: ${stylingVariables.hrb.colorPalette.lightGray};
		color: ${stylingVariables.hrb.colorPalette.orange};
	` : ''}
`;

interface IProps {
	items: {key: string, label: string, count?: number}[];
	status: string;
	onChange: (status: string) => any;
}

export class SimpleStatusBar extends PureComponent<IProps> {
	public render() {
		return (
			<Wrapper>
				<Items>
					{this.props.items.map((item) =>
						<Item key={item.key} isActive={this.props.status === item.key} onClick={() => this.props.onChange(item.key)}>
							{item.label} {item.count ? `(${item.count})` : ''}
						</Item>)
					}
				</Items>
			</Wrapper>
		);
	}
}
export default SimpleStatusBar;
