import React, {PureComponent, Fragment} from 'react';
import {intl} from '@global';
import {connect} from 'react-redux';

import {LeaveInterfaces, EmployeeInterfaces} from '@api';
import {Actions as CommonActions, Can, Modal} from '@common';
import {constants} from '@global';

interface IProps {
    employee: EmployeeInterfaces.Employee;
    transaction: LeaveInterfaces.Transaction;
    masterdataEditAllowed?: boolean;

    onClickReject: (employeeId: number, transaction: LeaveInterfaces.Transaction) => void;
    method: string;
}

interface IState {
    isOpenConfirmationModal: boolean;
}

export class RejectAction extends PureComponent<IProps, IState> {
    public constructor(props: IProps) {
        super(props);

        this.state = {
            isOpenConfirmationModal: false,
        };
    }

    private reject = () => {
        this.props.onClickReject(this.props.employee.id, this.props.transaction);
        this.closeConfirmationModal();
    }

    private openConfirmationModal = () => {
        this.setState({isOpenConfirmationModal: true});
    }

    private closeConfirmationModal = () => {
        this.setState({isOpenConfirmationModal: false});
    }

    private rejectLink =
        <CommonActions.Action onClick={this.openConfirmationModal}>
            {intl.get('reject')}
        </CommonActions.Action>

    private rejectButton =
        <Modal.StyledRejectButton onClick={this.openConfirmationModal}>
            {intl.get('reject')}
        </Modal.StyledRejectButton>


    private rejectAllButton =
        <Modal.StyledRejectButton onClick={this.openConfirmationModal}>
            {intl.get('reject_all')}
        </Modal.StyledRejectButton>

    render() {
        let rejectAll = false;
        let rejectMethod
        switch(this.props.method) {
            case 'rejectLink':
                rejectMethod = this.rejectLink;
                break;
            case 'rejectButton':
                rejectMethod = this.rejectButton;
                break;
            case 'rejectAllButton':
                rejectAll = true;
                rejectMethod = this.rejectAllButton;
                break;
            default:
                rejectMethod = this.rejectLink;

        }

        return (
            <Fragment>
                <Can run={constants.PermissionCode.LEAVES_EDIT_LEAVE} disabled={!this.props.masterdataEditAllowed}>
                    { rejectMethod }
                </Can>

                <Modal.ConfirmationModal
                    title={'reject'}
                    confirmationMessage={rejectAll ? 'reject_all_leave_transactions_confirmation' : 'reject_leave_transaction_confirmation'}
                    acceptBtnTitle={'reject'}
                    isOpen={this.state.isOpenConfirmationModal}
                    onClickAccept={this.reject}
                    onClickClose={this.closeConfirmationModal}
                />
            </Fragment>
        );
    }
}

const mapDispatchToProps = {};

export default connect(null, mapDispatchToProps)(RejectAction);
