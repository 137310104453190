import React from 'react';
import {Column} from 'react-virtualized';

import {defaultInfiniteTableCellDataGetter, Actions as CommonActions} from '@common';
import * as Headers from '../Header/index';
import Delete from './Actions/Delete';
import Download from './Actions/Download';

const Actions = () => (
    <Column
        dataKey={'actions'}
        width={80}
        cellDataGetter={defaultInfiniteTableCellDataGetter}
        headerRenderer={() => <Headers.Settings/>}
        cellRenderer={({cellData}) => {
            return (
                <CommonActions.HorizontalMenuActions>
                    <Download document={cellData}/>
                    <Delete document={cellData}/>
                </CommonActions.HorizontalMenuActions>
            );
        }}
    />
);

export default Actions;
