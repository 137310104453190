import React, {PureComponent} from 'react';
import styled from 'styled-components';

import {stylingVariables} from '@global';

export const StyledFieldRow = styled.div`
  margin: 8px 0;
  font-size: ${stylingVariables.fontSize.default};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  label {
      display: inline-block;
      width: 250px;
      line-height: 25px;
  }

  label + span {
      font-weight: bold;
      color: ${stylingVariables.colorPalette.darkest};
  }

  .react-datepicker-wrapper input {
      border: none;
      width: 100%;
      padding: 0 15px;
      font-weight: bold;
  }
`;

const StyledLabel = styled.label`
  color: ${stylingVariables.colorPalette.deepGray};
`;

interface IProps {
  label: string;
  value: any;
}

export default class Field extends PureComponent<IProps> {
  public render() {
    return (
        <StyledFieldRow>
            <StyledLabel>{this.props.label}</StyledLabel>
            <span>
              {this.props.value}
            </span>

        </StyledFieldRow>
    );
  }
}
