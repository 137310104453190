import React, {PureComponent} from 'react';

import {FormatNumbers, Label, RadialChart, IconPopover} from '@common';
import {LeaveInterfaces} from '@api';
import {ColorHelper} from '@library';

import {StyledCard, StyledTitle} from './StyledCard';
import {MenuActions, MenuSize, Action} from 'hrb-common';
import styled from 'styled-components';
import {intl, stylingVariables} from '@global';
import { InformationTab } from '../../../Leaves/Balances/Table/Column/Leave';
import { getTransitionalAmountsSum } from 'library/transitionalAmounts.helper';

const MasterdataAlert = styled.div`
    position: absolute;
    left: 10px;
    bottom: 28px;

    div {
        margin: 0;
    }
`;

const MenuActionsWrapper = styled.div`
    position: absolute;
    right: 6px;
`;

export const ChartDisclaimer = styled.span`
    color: ${stylingVariables.colorPalette.dark};
    font-size: ${stylingVariables.fontSize.small};
    font-weight: ${stylingVariables.fontWeight.semibold};
    text-transform: lowercase;
    text-align: center;
    margin-top: 10px;
`;

const ChartWrapper = styled.div`
    position: relative;
`;

const HoverTrigger = styled.span`
    position: absolute;
    height: 20px;
    width: 20px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

const InformationTabWrapper = styled.div`
    position: absolute;
    top: 0;
    left: 40px;
`;

interface IProps {
    typeId: number;
    label: string;
    hasBalance: boolean;
    annualBalance: LeaveInterfaces.Balance;
    currentBalance: LeaveInterfaces.Balance;
    amountType: LeaveInterfaces.AmountType;
    editModal: (leaveTypeId: number) => void;

    year: number;
    isCurrentPayrollYear: boolean;
    masterdataEditAllowed?: boolean;
    leaveType?: string;
}

interface IState {
    color: string;
    showExtraInformation: boolean;
    balanceType: 'annual' | 'current';
}

export default class TypeCard extends PureComponent<IProps, IState> {

    private actionMenu?: MenuActions | null;

    constructor(props: IProps) {
        super(props);

        this.state = {
            color: ColorHelper.stringToColour(props.label),
            showExtraInformation: false,
            balanceType: 'current',
        };
    }

    componentDidUpdate(prevProps: Readonly<IProps>) {
        if (prevProps.isCurrentPayrollYear && !this.props.isCurrentPayrollYear) {
            this.showAnnualBalance();
        } else if (!prevProps.isCurrentPayrollYear && this.props.isCurrentPayrollYear) {
            this.showCurrentBalance();
        }
    }

    public render() {
        const { hasBalance, label, amountType, year, isCurrentPayrollYear, leaveType } = this.props;
        const { color, showExtraInformation, balanceType } = this.state;
        const { balance, taken } = this.getBalances();

        return (
            <>
                {(hasBalance || leaveType === 'sick' || taken !== 0) &&
                (<StyledCard borderColor={color}>
                    <StyledTitle>{label}</StyledTitle>
                    {hasBalance && isCurrentPayrollYear && <MenuActionsWrapper>
                      <MenuActions size={MenuSize.Small} ref={ref => this.actionMenu = ref}>
                          {balanceType === 'annual' && <Action.Action onClick={this.showCurrentBalance}>
                              {intl.get('view_current_balance')}
                          </Action.Action>}
                          {balanceType === 'current' && <Action.Action onClick={this.showAnnualBalance}>
                              {intl.get('view_annual_balance')}
                          </Action.Action>}
                          {balanceType === 'current' &&
                          <Action.Action
                            isDisabled={this.props.masterdataEditAllowed === false}
                            onClick={this.editBalances}>
                              {intl.get('edit_balance')}
                          </Action.Action>
                          }
                          {(!this.props.masterdataEditAllowed && balanceType === 'current') &&  (
                              <MasterdataAlert>
                                  <IconPopover
                                      type={'info'}
                                      message={intl.get('masterdata_edit_not_allowed')} iconSize={14}
                                  />
                              </MasterdataAlert>
                          )}
                      </MenuActions>
                    </MenuActionsWrapper>}
                    <ChartWrapper>
                        <RadialChart
                            label={hasBalance ? balance : taken}
                            radius={hasBalance ? 80 : 70}
                            strokeWidth={hasBalance ? 4 : 2}
                            progress={100}
                            progressColor={color}
                            noProgressColor={stylingVariables.colorPalette.deepGray}
                            formatNumber={true}
                        />
                        {hasBalance &&
                            <HoverTrigger onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave}/>}
                        {hasBalance && showExtraInformation &&
                        <InformationTabWrapper>
                          <InformationTab>
                            <Label.DotLabel color={color}>
                              <FormatNumbers showZero={true} value={balance > 0 ? balance : 0}/> {
                                TypeCard.getDisclaimer(amountType, true).toLowerCase()}
                            </Label.DotLabel>
                            <Label.DotLabel color={stylingVariables.colorPalette.deepGray}>
                              <FormatNumbers showZero={true} value={taken}/> {
                                TypeCard.getDisclaimer(amountType, false).toLowerCase()}
                            </Label.DotLabel>
                          </InformationTab>
                        </InformationTabWrapper>
                        }
                    </ChartWrapper>
                    <ChartDisclaimer>
                        {TypeCard.getDisclaimer(amountType, hasBalance)} {hasBalance && <React.Fragment>
                        {balanceType === 'current' && `${intl.get('as_of_')} ${intl.get('this_month')}`}
                        {balanceType === 'annual' && `${intl.get('in_')} ${year}`}
                    </React.Fragment>}
                    </ChartDisclaimer>
                </StyledCard>)}
            </>
        );
    }

    private getBalances = (): { balance: number, taken: number } => {
        const { annualBalance, currentBalance } = this.props
        const displayedBalance = this.state.balanceType === 'annual' ? annualBalance : currentBalance
        const transitionalAmountsBalance = getTransitionalAmountsSum(displayedBalance, 'balance')
        const transitionalAmountsTaken = getTransitionalAmountsSum(displayedBalance, 'taken')

            const balance = (displayedBalance.ytdBalance || 0) +
                (displayedBalance.year1TransitionalBalance || 0) +
                transitionalAmountsBalance
            const taken = (displayedBalance.ytdTaken || 0) +
                (displayedBalance.year1TransitionalTaken || 0) + 
                transitionalAmountsTaken
        
        return {
            balance,
            taken
        };
    }

    private onMouseEnter = () => {
        this.setState({
            showExtraInformation: true,
        });
    }

    private onMouseLeave = () => {
        this.setState({
            showExtraInformation: false,
        });
    }

    private closeMenu = () => {
        if (this.actionMenu) {
            this.actionMenu.close();
        }
    }

    private showCurrentBalance = () => {
        this.setState({
            balanceType: 'current',
        });
        this.closeMenu();
    }

    private showAnnualBalance = () => {
        this.setState({
            balanceType: 'annual',
        });
        this.closeMenu();
    }

    private editBalances = () => {
        this.props.editModal(this.props.typeId);
        this.closeMenu();
    }

    private static getDisclaimer(amountType: LeaveInterfaces.AmountType, hasBalance: boolean): string {
        switch (amountType) {
            case LeaveInterfaces.AmountType.workDays:
                return hasBalance ? intl.get('business_days_left') : intl.get('business_days_taken');
            case LeaveInterfaces.AmountType.calendarDays:
                return hasBalance ? intl.get('calendar_days_left') : intl.get('calendar_days_taken');
            case LeaveInterfaces.AmountType.hours:
                return hasBalance ? intl.get('hours_left') : intl.get('hours_taken');
        }
    }
}
