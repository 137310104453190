import React, {PureComponent} from 'react';
import {AutoSizer} from 'react-virtualized';
import styled from 'styled-components';

import {ApiObject} from '@api';
import {InfiniteTableColumn, StyledInfiniteTable as CommonStyledInfiniteTable} from '@common';
import {stylingVariables, intl} from '@global';

import * as Columns from './Column/index';
import {COLUMN_WIDTH_ACTIONS, COLUMN_WIDTH_DEFAULT, COLUMN_WIDTH_LABEL} from './constants';
import {EmployeePayElementDisplay} from '../../../../../api/employee.interfaces';
import {Row} from './Row';
import {StyledEditAction} from './Column/Edit';

import { CompensationTypes } from '../../../../../constants'

const StyledInfiniteTable = styled(CommonStyledInfiniteTable)`
    width: calc(100vw - 40px);
    .ReactVirtualized__Grid__innerScrollContainer{
        margin-bottom: 0;
    }
    .ReactVirtualized__Table__row{
        background-color: ${stylingVariables.colorPalette.gray};
				cursor: pointer;
        &:hover{
            border: 1px solid ${stylingVariables.colorPalette.primaryHover};

            ${StyledEditAction} {
              background-color: ${stylingVariables.colorPalette.primaryHover};
            }
        }
        .ReactVirtualized__Table__rowColumn {
            background-color: ${stylingVariables.colorPalette.gray};
        }
    }
    .ReactVirtualized__Table__headerColumn {
        white-space: normal;
        font-weight: ${stylingVariables.fontWeight.bold};
    }

    .ReactVirtualized__Table__row_salary {
        padding-right: 0 !important;
    }
`;

interface TableProps {
    width?: number;
    code?: String;
    rows: EmployeePayElementDisplay[];
    editAction: (values: any) => void;
    addAction: () => void;
    masterdataEditAllowed: boolean;
}

export class Table extends PureComponent<TableProps> {
    private get realTableWidth() {
        return 7 * COLUMN_WIDTH_DEFAULT + COLUMN_WIDTH_LABEL + COLUMN_WIDTH_ACTIONS;
    }

    public render() {
       let hideAnnual = this.props?.code === CompensationTypes.additionalEarnings ||  this.props?.code === CompensationTypes.additionalDeductions;
        return (
            this.props.rows && <AutoSizer>
                {({width, height}) => (
                    <StyledInfiniteTable
                        rowClassName={'ReactVirtualized__Table__row_salary'}
                        overscanRowCount={5}
                        rowHeight={80}
                        headerHeight={40}
                        rowGetter={({ index }: {index: number}) => this.props.rows[index]}
                        rowRenderer={Row(this.props.editAction)}
                        rowCount={this.props.rows.length}
                        width={width > this.realTableWidth ? width : this.realTableWidth}
                        maxWidth={this.props.width}
                        height={height}
                    >
                        {Columns.LabelWithStatus({label: intl.get('name'), dataKey: 'label'})}
												{InfiniteTableColumn.Default({
                            label: intl.get('currency_frequency'),
                            dataKey: 'currencyAndFrequency',
                        })}
                        {Columns.DoubleColumnAmount({
                            label: intl.get('amount'),
                            dataKey: 'amount',
                        })}
                        {!hideAnnual && Columns.DoubleColumnAmount({
                            label: intl.get('annual'),
                            dataKey: 'annualAmount',
                        })}
                        {Columns.DoubleColumnDate({
                            label: intl.get('start'),
                            dataKey: 'startDate',
                            type: ApiObject.FieldType.date,
                            width: 120,
                        })}
                        {Columns.DoubleColumnDate({
                            label: intl.get('end'),
                            dataKey: 'endDate',
                            type: ApiObject.FieldType.date,
                            width: 120,
                        })}
                        {this.props.masterdataEditAllowed &&
                            Columns.Edit({action: this.props.editAction})
                        }
                    </StyledInfiniteTable>
                )}
            </AutoSizer>
        );
    }
}
