import React, {PureComponent} from 'react';
import styled from 'styled-components';
import {Icon} from '@common';
import {constants, stylingVariables} from '@global';

export const Border = styled.span<{borderLen: number}>`
    content: '';
    border-top: 1px solid ${stylingVariables.colorPalette.gray};
    margin: 0;
    flex: 1 0 ${props => props.borderLen}px;
`;

export const IconWrapper = styled.div`
    height: 24px;
    min-width: 24px;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
        background-color: ${stylingVariables.colorPalette.primaryHover};
    }
`;

export const CollapseContent = styled.div<{isOpen: boolean}>`
    ${props => !props.isOpen && `
        max-height: 0;
        transition: max-height 0.15s ease-out;
        overflow: hidden;
    `}

    ${props => props.isOpen && `
        max-height: 100%;
        transition: max-height 0.25s ease-in;
    `}
`;

const LinedHeader = styled.div<{isOpen: boolean}>`
    display: flex;
    flex-direction: row;
    align-items: center;

    h4 {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-size: ${stylingVariables.fontSize.large};
        padding: 20px 0;
    }

    svg {
        transition: all 0.5s 0.25s;
    }

    h4:after {
        content: '';
        border-top: 1px solid ${stylingVariables.colorPalette.gray};
        margin: 0 0 0 18px;
        flex: 1 0 18px;
    }

    h4:before {
        content: '';
        border-top: 1px solid ${stylingVariables.colorPalette.gray};
        margin: 0 18px 0 0;
        width: 50px;
    }

      ${props => props.isOpen && `
            svg {
                transform: rotate(0deg);
            }
      `}

      ${props => !props.isOpen && `
            svg {
                transform: rotate(180deg);
            }
      `}
`;

const NoHeaderWrapper = styled.div<{isOpen: boolean}>`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    padding: 0 76px;
    cursor: pointer;

      ${props => props.isOpen && `
            svg {
                transform: rotate(0deg);
            }
      `}

      ${props => !props.isOpen && `
            svg {
                transform: rotate(180deg);
            }
      `}
`;

interface IProps {
    isOpen: boolean;
    label?: string;
    className?: string;
    children?: any;
    inHeader?: any;
    header?: boolean;
}

interface IState {
    isOpen: boolean;
}

export default class Collapse extends PureComponent<IProps, IState> {
    static defaultProps = {
        isOpen: true,
        header: true,
        label: '',
    };
    constructor(props: IProps) {
        super(props);
        this.state = {
            isOpen: this.props.isOpen,
        };
    }

    componentDidUpdate(prevProps: IProps) {
        if (prevProps.isOpen !== this.props.isOpen) {
            this.setState(prevState => ({
                isOpen: this.props.isOpen,
            }));
        }
    }

    render() {
        return (
            <div>
                {this.props.header &&
                    <LinedHeader isOpen={this.state.isOpen}><h4>{this.props.label}</h4>
                        {this.props.inHeader}<Border borderLen={20} />
                        <IconWrapper onClick={this.toggle}>
                            <Icon type={constants.IconTypes.ARROW_UP}
                                  width={10}
                                  height={6}
                            />
                        </IconWrapper>
                      <Border borderLen={76} />
                    </LinedHeader>
                }
                {!this.props.header &&
                    <NoHeaderWrapper isOpen={this.state.isOpen} onClick={this.toggle}>
                        {this.props.children[0]}
                        <IconWrapper>
                            <Icon type={constants.IconTypes.ARROW_UP}
                                width={16}
                                height={10}
                            />
                        </IconWrapper>
                    </NoHeaderWrapper>
                }
                <CollapseContent
                    isOpen={this.state.isOpen}
                >
                    {this.props.header && this.props.children}
                    {!this.props.header && this.props.children[1]}
                </CollapseContent>
            </div>
        );
    }

    toggle = () => {
        this.setState(prevState => ({
            isOpen: !prevState.isOpen,
        }));
    }
}
