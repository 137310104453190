import { api, intl } from '@global'
import { FieldGroupHelper, ReportHelper } from '@library'
import {
    CreateReportTemplate,
    DataSource,
    FieldGroup,
    LegalEntityPeriod,
    Report,
    ReportColumn,
    ReportColumnType,
    ReportTemplate,
    ReportVisibility,
    Visibility
} from './api.object'

export const IGNORE_COLUMN_TYPES: string[] = [
    ReportColumnType.ORDER_BY,
    ReportColumnType.REPORT_HEADER,
    ReportColumnType.REPORT
]

export const DEFAULT_GROUPING_FORMULA = `CONCAT($employee.reference_code)`
export const CUSTOM_GROUPING_PAYROLL_FORMULA_CURRENCIES = `CONCAT($entry.employee_id; '::'; $entry.base2_currency)`
export const CUSTOM_GROUPING_EMPLOYEE_FORMULA_CURRENCIES = `CONCAT($entry.employee_id; '::'; $entry.original_currency)`

const getReportSpecialColumns = (
  reportLabel: string = '',
  reportPeriod: string = '',
  groupingFormula = DEFAULT_GROUPING_FORMULA
): ReportColumn[] => {
    return [
        {
            label: ReportColumnType.REPORT,
            formula: groupingFormula,
            groupingFormula: groupingFormula,
            columnType: ReportColumnType.REPORT,
            extraCss: null
        } as ReportColumn,
        {
            label: ReportColumnType.ORDER_BY,
            formula: groupingFormula,
            columnType: ReportColumnType.ORDER_BY,
            extraCss: null
        },
        {
            label: intl.get('organization'),
            formula: '$legal_entity.name',
            columnType: ReportColumnType.REPORT_HEADER,
            extraCss: null,
        },
        {
            label: intl.get('country'),
            formula: '$legal_entity.country_name',
            columnType: ReportColumnType.REPORT_HEADER,
            extraCss: null,
        },
        {
            label: intl.get('currency'),
            formula: '$legal_entity.base1_currency',
            columnType: ReportColumnType.REPORT_HEADER,
            extraCss: null,
        },
        {
            label: intl.get('report'),
            formula: `CONCAT('${reportLabel}')`,
            columnType: ReportColumnType.REPORT_HEADER,
            extraCss: null,
        },
        {
            label: intl.get('total_headcount'),
            formula: '$report.headcount',
            columnType: ReportColumnType.REPORT_HEADER,
            extraCss: null,
        },
        {
            label: intl.get('period'),
            formula: `CONCAT('${reportPeriod}')`,
            columnType: ReportColumnType.REPORT_HEADER,
            extraCss: null,
        },
        {
            label: intl.get('report_generation_date'),
            formula: `DATE_FORMAT($report.generation_time; 'yyyy-MM-dd')`,
            columnType: ReportColumnType.REPORT_HEADER,
            extraCss: null,
        },
    ]
}

const prepareColumns = (
  columns: ReportColumn[],
  report: Report|null,
  fieldGroups: FieldGroup[],
  legalEntityPeriods: LegalEntityPeriod[],
): ReportColumn[] => {
    const showCurrencies = !!report?.showCurrencyForAmounts
    const allFields = FieldGroupHelper.extractFields(fieldGroups)
    const pureColumns: ReportColumn[] = []
    columns
      .filter((column: ReportColumn) => !IGNORE_COLUMN_TYPES.includes(column.columnType as string))
      // Add suffixes from MSS output formats
      .forEach((column: ReportColumn) => {
          // Pay elements columns
          if (column.formula === '$entry.amount') {
              if (showCurrencies) {
                  // In case currencies and Legal entity data source == `employee records`, we should use another way
                  const amountFormula = report?.legalEntityReportType === DataSource.employee
                    ? '$entry.original_amount'
                    : '$entry.base2_amount'
                  const currencyFormula = report?.legalEntityReportType === DataSource.employee
                    ? '$entry.original_currency'
                    : '$entry.base2_currency'

                  // Here we need add additional calculated fields to display all currencies
                  pureColumns.push({
                      label: `${column.label}_AMOUNT`,
                      formula: amountFormula,
                      filterFormula: column.filterFormula,
                      columnType: ReportColumnType.SUMMED,
                      visibility: Visibility.hidden,
                  } as ReportColumn)
                  pureColumns.push({
                      label: `${column.label}_CURRENCY`,
                      formula: currencyFormula,
                      filterFormula: column.filterFormula,
                      visibility: Visibility.hidden,
                  } as ReportColumn)

                  // Computed column what will be visible in report
                  pureColumns.push({
                      formula: `CONCAT($${column.label}_CURRENCY; ' '; $${column.label}_AMOUNT)`,
                      label: column.label,
                      columnType: ReportColumnType.COLUMN_CALCULATED_STRING
                  } as ReportColumn)
              } else {
                  pureColumns.push(column)
              }
          } else {
              // Masterdata fields
              const relatedField = allFields.find(field =>
                `${field.entity}.${field.code}` === FieldGroupHelper.getFieldKeyFromMasterdataFormula(column.formula))

              pureColumns.push(
                {
                    ...column,
                    formula: relatedField
                      ? FieldGroupHelper.getFieldFormulaFromMasterdataField(relatedField)
                      : column.formula
                }
              )
        }
      })
    
        const GROUPING_FORMULA = report?.legalEntityReportType === DataSource.employee
            ? CUSTOM_GROUPING_EMPLOYEE_FORMULA_CURRENCIES
            : CUSTOM_GROUPING_PAYROLL_FORMULA_CURRENCIES

    const specialColumns: ReportColumn[] = getReportSpecialColumns(
      report?.label,
      ReportHelper.reportPeriodLabel(report, legalEntityPeriods),
      showCurrencies ? GROUPING_FORMULA : DEFAULT_GROUPING_FORMULA
    )

    return [
      ...specialColumns,
      ...pureColumns,
    ]
}

export default class ApiReportTemplate {
    public static async list(
        legalEntityId: number, params: ReportTemplateQueryParams
    ): Promise<ReportTemplate[]> {
        const { data } = await api.get(`/masterdata/public/legal-entity/${legalEntityId}/reports/templates`, {
            params: params.params(),
        });

        return data.data ? data.data.templates || [] : []
    }

    public static async findByIds(legalEntityId: number, ids: number[]) {
        if (ids.length === 0) {
            return [];
        }

        const { data } = await api.get(`/masterdata/public/legal-entity/${legalEntityId}/reports/templates`, {
            params: { ids },
        });

        return data.data ? data.data.templates || [] : [];
    }

    public static async deleteReportTemplate(legalEntityId: number, id: number) {
        return await api.delete(`/masterdata/public/legal-entity/${legalEntityId}/reports/templates/${id}`);
    }

    public static async createTemplate(
      legalEntityId: number,
      template: ReportTemplate,
      report: Report|null,
      fieldGroups: FieldGroup[],
      legalEntityPeriods: LegalEntityPeriod[] = []
    ): Promise<ReportTemplate|null> {
        const { data } = await api.post(`/masterdata/public/legal-entity/${legalEntityId}/reports/templates`, {
            visibility: template.visibility,
            isTemporary: template.isTemporary,
            label: template.label,
            labelFormula: template.labelFormula,
            employees: template.employees,
            definition: {
                grouping: template.grouping,
                columns: prepareColumns(template.columns, report, fieldGroups, legalEntityPeriods),
            }
         } as CreateReportTemplate);

        return data.data ? data.data : null;
    }

    public static async editTemplate(
      legalEntityId: number,
      template: ReportTemplate,
      report: Report|null,
      fieldGroups: FieldGroup[],
      legalEntityPeriods: LegalEntityPeriod[] = [],
    ): Promise<ReportTemplate|null> {
        const response = await api.put(`/masterdata/public/legal-entity/${legalEntityId}/reports/templates/${template.id}`, {
            visibility: template.visibility,
            isTemporary: template.isTemporary,
            label: template.label,
            labelFormula: template.labelFormula,
            employees: template.employees,
            definition: {
                grouping: template.grouping,
                columns: prepareColumns(template.columns, report, fieldGroups, legalEntityPeriods),
            }
        } as CreateReportTemplate);

        if (!response || !response.data) {
            return null
        }
        return response.data.data || null;
    }
}

interface RequestParams {
    ids?: string|null;
    visibilities: string|null;
    is_temporary: boolean;
}

interface ReportTemplateQueryParamsInterface {
    ids?: number[]|null;
    visibilities?: ReportVisibility[]|null;
    isTemporary?: boolean;
}

export class ReportTemplateQueryParams {
    public ids: number[];
    public visibilities: ReportVisibility[];
    public isTemporary: boolean;

    public constructor({ids, visibilities, isTemporary}: ReportTemplateQueryParamsInterface = {}) {
        this.ids = ids || [];
        this.visibilities = visibilities || [];
        this.isTemporary = isTemporary || false;
    }

    public params(): RequestParams {
        const params: RequestParams = {
            visibilities: this.visibilities.join(','),
            is_temporary: this.isTemporary
        };

        if (this.ids.length) {
            params.ids = this.ids.join(',')
        }

        return params;
    }
}
