import {ApiObject} from '@api';
import {interfaces} from '@global';
import React, {PureComponent} from 'react';
import Filter from './Filter';
import styled from 'styled-components';

interface HeaderProps {
    label: string;
    dataKey?: string;
    type?: string;
    options?: ApiObject.SelectOption[];
    filters?: interfaces.ListingFilters;
    changeFilter?: (params: interfaces.FilterParams) => any;
}

const StyledHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  position: relative;
`;

export default class Filtered extends PureComponent<HeaderProps> {
    render() {
        return (
            <StyledHeaderWrapper>
                {this.props.label}

                <StyledActionsWrapper>
                    <Filter
                        column={this.props.dataKey}
                        type={this.props.type}
                        label={this.props.label}
                        options={this.props.options}
                        filters={this.props.filters}
                        changeFilter={this.props.changeFilter}
                    />
                </StyledActionsWrapper>
            </StyledHeaderWrapper>
        );
    }
}
