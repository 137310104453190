import React, {PureComponent} from 'react';
import {intl, stylingVariables} from '@global';
import {get} from 'lodash';

import {ApiObject, EmployeeInterfaces} from '@api';
import {Modal as CommonModal} from '@common';

import {getErrorByEntityAndCode} from '../../../selectors';

import Delete from '../Delete/Delete';
import EditableField from './EditableField';
import {CloseButtonModal} from 'hrb-common';
import styled from 'styled-components';

const CloseModalBtnWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding: 19px 30px;
  border-bottom-left-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color .5s;

  &:hover {
    background-color: ${stylingVariables.colorPalette.lightGray};
    transition: background-color .5s;

    .hrb-common__modal__close-btn { 
        background-color: ${stylingVariables.colorPalette.deepGreen};
    }
  }

  .hrb-common__modal__close-btn {
    background-color: ${stylingVariables.colorPalette.green};
    position: static;
    align-items: center;

    svg {
        fill: ${stylingVariables.colorPalette.white};
        width: 12px;
        height: 12px;
    }
  }
`;

const StyledHeader = styled(CommonModal.StyledHeader)`
  h4 {
    font-size: ${stylingVariables.fontSize.big};
    font-weight: ${stylingVariables.fontWeight.bold};
    color: ${stylingVariables.colorPalette.darkest};
  }
`;

const StyledActionsWrapper = styled(CommonModal.StyledActionBar)`
  border: none;
`;

interface IProps {
    entityId: string;
    employee: EmployeeInterfaces.Employee;
    group: ApiObject.FieldGroup;
    values: ApiObject.EntityValues;
    errors: ApiObject.EntityFieldErrorMessage[];

    onClickSave: (fields: ApiObject.FieldValue[]) => void;
    onClickClose: () => void;
}

interface IState {
    updatedFields: ApiObject.FieldValue[];
}

export default class Modal extends PureComponent<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            updatedFields: [],
        };
    }
    
    render() {
        return (
            <CommonModal.RightSideModal zIndex={1000} onClickClose={this.props.onClickClose}>
                <CloseModalBtnWrapper>
                    <CloseButtonModal onClick={this.props.onClickClose} />
                </CloseModalBtnWrapper>
                <StyledHeader>
                    <h4>{intl.get('edit_dependent')}</h4>
                </StyledHeader>

                <CommonModal.OverflowableContent padding={'20px 40px'}>
                    {
                        this.props.group.fields.map((field: ApiObject.Field) => (
                            <EditableField
                                key={`${field.entity}_${field.code}`}
                                field={field}
                                defaultValue={
                                    get(this.props.values, field.code) || undefined
                                }
                                errorMessage={
                                    getErrorByEntityAndCode(this.props.errors, field.entity, field.code)
                                }
                                onChange={this.handleChangeField}
                            />
                        ))
                    }
                </CommonModal.OverflowableContent>

                {
                    this.renderActions()
                }
            </CommonModal.RightSideModal>
        );
    }

    public renderActions() {
        if (!this.props.values.deletableEntity) {
            return (
                <StyledActionsWrapper>
                    <CommonModal.StyledCancelButton onClick={this.props.onClickClose}>
                        {intl.get('cancel')}
                    </CommonModal.StyledCancelButton>

                    <CommonModal.StyledOkButton onClick={this.handleClickSave}>
                        {intl.get('save')}
                    </CommonModal.StyledOkButton>
                </StyledActionsWrapper>
            );
        }

        return (
            <StyledActionsWrapper>
                <Delete
                    employee={this.props.employee}
                    entityId={this.props.entityId}
                    values={this.props.values}
                    onAfterAction={this.props.onClickClose}
                />

                <div>
                    <CommonModal.StyledCancelButton onClick={this.props.onClickClose}>
                        {intl.get('cancel')}
                    </CommonModal.StyledCancelButton>

                    <CommonModal.StyledOkButton onClick={this.handleClickSave}>
                        {intl.get('save')}
                    </CommonModal.StyledOkButton>
                </div>
            </StyledActionsWrapper>
        );
    }

    private handleClickSave = () => this.props.onClickSave(this.state.updatedFields);

    private handleChangeField = (field: ApiObject.Field, value: any) => {
        if (!value || value.length === 0) {
            value = null;
        }

        const updatedFields = [...this.state.updatedFields];
        const existedField = updatedFields.find(item => item.code === field.code);
        if (!existedField) {
            updatedFields.push({code: field.code, value});
        } else {
            existedField.value = value;
        }

        this.setState({updatedFields});
    }
}
