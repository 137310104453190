import {ApiObject} from '@api';
import React, {PureComponent} from 'react';
import {intl} from '@global';
import styled from 'styled-components';
import {Icon, Input} from '@common';
import {constants, stylingVariables} from '@global';
import {get} from 'lodash';

const StyledWrapper = styled.div`
    display: inline-block;
    position: relative;
    font-size: 13px;
    width: 100%;
`;

const StyledHeader = styled.div<{hasError: boolean, disabled: boolean, listOpen: boolean}>`
    padding: 13px 20px;
    border: 1px solid ${stylingVariables.colorPalette.darkestGray};
    ${props => props.hasError && `
        border: 1px solid ${stylingVariables.colorPalette.red};
    `};
    ${props => props.disabled ? `
        background-color: ${stylingVariables.colorPalette.gray};
    ` : `
        cursor: pointer;
    `};
    border-radius: ${stylingVariables.layout.inputBorderRadius};
    min-width: 320px;
    font-weight: ${stylingVariables.fontWeight.semibold};
    position: relative;

    &:hover {
        border: 1px solid ${stylingVariables.colorPalette.green};
    }

    ${props => props.listOpen && `
        border: 1px solid ${stylingVariables.colorPalette.green};
    `};
`;

const StyledHeaderTitle = styled.h3`
    display: inline-block;
    font-size: ${stylingVariables.fontSize.mediumLarge};
    font-weight: ${stylingVariables.fontWeight.semibold};
    color: ${stylingVariables.colorPalette.dark};
`;

const StyledArrow = styled.span`
    display: inline-block;
    position: absolute;
    float: right;
    right: 0;
    top: 0;
    bottom: 0;
    overflow: hidden;
    height: auto;
    padding: 15px;
    border-radius: 0 ${stylingVariables.layout.inputBorderRadius} ${stylingVariables.layout.inputBorderRadius} 0;
    background: ${stylingVariables.colorPalette.gray};
    cursor: pointer;
`;

const StyledContent = styled.div`
    position: absolute;
    left: 0;
    right: 0;
    border: 1px solid ${stylingVariables.colorPalette.darkGray};
    border-radius: ${stylingVariables.layout.inputBorderRadius};
    max-height: 300px;
    overflow: hidden;
    z-index: 10;
    background: ${stylingVariables.colorPalette.white};
`;

const StyledList= styled.ul`
    overflow-y: auto;
    max-height: 240px;
    min-height: 80px;
    font-weight: ${stylingVariables.fontWeight.regular};
    li {
        padding: 12px 20px;
        cursor: pointer;
        &:hover {
            background: ${stylingVariables.colorPalette.gray};
        }
    }
`;

const StyledSearchInput = styled.div`
  height: 36px;
  border-radius: 18px;
  margin: 12px 10px;
  padding: 0 15px;
  display: flex;
  background-color: ${stylingVariables.colorPalette.gray};
  align-items: center;
  input {
    background-color: transparent;
    &, &:focus {
      border-bottom: none;
    }
  }
  label {
    display: none;
  }
  svg {
    margin-left: 10px;
  }
  div {
    flex: 1
  }
`;

const StyledSubItem= styled.div`
  color: ${stylingVariables.colorPalette.deepGray};
  margin-top: 3px;
`;

const StyledLoadMore = styled.div`
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: center;
  font-size: ${stylingVariables.fontSize.medium};
  font-weight: ${stylingVariables.fontWeight.semibold};
  color: ${stylingVariables.colorPalette.dark};
  background-color: ${stylingVariables.colorPalette.lightGreen};
`;

interface DropdownProps {
    list: ApiObject.SelectOption[];
    selectedValue?: string;
    onChange: (value: any) => void;
    hasError?: boolean;
    externalFilter?: boolean;
    onFilter?: (value: string) => any;
    useLoadMore?: boolean;
    onLoadMore?: () => any;
    disabled: boolean;
    searchValue?: string;
    placeholder?: string;
}

interface DropdownState {
    listOpen: boolean;
    search: string;
    selected: ApiObject.SelectOption | null | undefined;
}

export default class Dropdown extends PureComponent<DropdownProps, DropdownState> {
    private wrapperRef: any;
    static defaultProps = {
        list: [],
        selected: [],
        disabled: false,
    };

    constructor(props: any) {
        super(props);
        this.state = {
            listOpen: false,
            search: this.props.searchValue || '',
            selected: this.props.list.find((x: ApiObject.SelectOption) => {
                return x.key === this.props.selectedValue;
            }),
        };
    }

    toggleList = () => {
        if (this.props.disabled) {
            return;
        }

        if (this.state.listOpen) {
            this.setState({search: this.props.searchValue || ''})
        }

        this.setState(prevState => ({
            listOpen: !prevState.listOpen,
        }));
    }

    toggleSelected = (item: ApiObject.SelectOption) => {
        this.props.onChange(item.key);
        this.setState({
            selected: item,
        });
        this.toggleList();
    }

    public componentDidMount() {
        document.addEventListener('mousedown', this.clickOutside);
    }

    public componentWillUnmount() {
        document.removeEventListener('mousedown', this.clickOutside);
    }

    public componentDidUpdate(prevProps: Readonly<DropdownProps>, prevState: Readonly<DropdownState>) {
        if (this.props.list.length !== prevProps.list.length || prevProps.selectedValue !== this.props.selectedValue) {
            this.setState({
                selected: this.props.list.find((x: ApiObject.SelectOption) => {
                    return x.key === this.props.selectedValue;
                }),
            });
        }
    }

    private onChangeSearch = (value: string): void => {
        this.setState({search: value});

        if (this.props.externalFilter && this.props.onFilter) {
            this.props.onFilter(value);
        }
    }

    private get list(): ApiObject.SelectOption[] {
        if (this.props.externalFilter) {
            return this.props.list;
        }

        return this.props.list
            .filter((item: ApiObject.SelectOption) =>
                item.label.toLowerCase().includes(this.state.search.toLowerCase()));
    }

    private onLoadMore = () => {
        if (this.props.onLoadMore) {
            this.props.onLoadMore();
        }
    }

    render() {
        const list = this.list;
        const {listOpen, selected} = this.state;
        const {disabled} = this.props;

        return (
            <StyledWrapper ref={this.setWrapperRef}>
                <StyledHeader onClick={() => this.toggleList()}
                              hasError={!!this.props.hasError}
                              disabled={disabled}
                              listOpen={listOpen}>
                    <StyledHeaderTitle>{get(selected, 'label', '...')}</StyledHeaderTitle>
                    {!disabled &&
                    <StyledArrow style={{backgroundColor: listOpen
                            ? stylingVariables.colorPalette.lightGreen
                            : stylingVariables.colorPalette.gray}}>
                        <Icon type={listOpen ? constants.IconTypes.ARROW_UP : constants.IconTypes.ARROW_DOWN}
                              fillColor={stylingVariables.colorPalette.dark}
                              height={10}
                              width={10}
                        />
                    </StyledArrow>}
                </StyledHeader>
                {listOpen &&
                <StyledContent>
                    <StyledSearchInput>
                        <Input label={''}
                               placeholder={this.props.placeholder || intl.get('search')}
                               onChange={(event: any) => this.onChangeSearch(event.target.value)}
                               autoFocus={true}
                               defaultValue={this.state.search}
                        />
                        <Icon type={constants.IconTypes.SEARCH}
                              fillColor={stylingVariables.colorPalette.green}
                              height={16}
                              width={16}
                        />
                    </StyledSearchInput>
                    <StyledList>
                        {list.map((item: ApiObject.SelectOption) => (
                            <li className='dd-list-item'
                                key={item.key}
                                onClick={() => this.toggleSelected(item)}>
                                {item.label}
                                {item.subLabel && <StyledSubItem>{item.subLabel}</StyledSubItem>}
                            </li>
                        ))}
                        {this.props.useLoadMore && (
                            <li className='dd-list-item' onClick={this.onLoadMore} style={{padding: 0}}>
                                <StyledLoadMore>
                                    {intl.get('load_more')}...
                                </StyledLoadMore>
                            </li>
                        )}
                    </StyledList>
                </StyledContent>
                }
            </StyledWrapper>
        );
    }

    public setWrapperRef = (node: any) => {
        this.wrapperRef = node;
    }

    public clickOutside = (event: Event) => {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.setState({listOpen: false});
        }
    }
}
