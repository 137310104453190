import React, {memo} from 'react';
import {Column} from 'react-virtualized';
import styled from 'styled-components';

import {stylingVariables, intl} from '@global';
import {defaultInfiniteTableCellDataGetter, FormatNumbers} from '@common';

const StyledEmployeeWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

const AmountWrapper = styled.div<{negative: boolean}>`
    display: flex;
    align-items: center;
    font-size: ${stylingVariables.fontSize.large};
    color: ${props => props.negative ?
        stylingVariables.colorPalette.red : stylingVariables.colorPalette.green};
`;

const Triangle = styled.div<{negative: boolean}>`
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid ${props => props.negative ?
        stylingVariables.colorPalette.red : stylingVariables.colorPalette.green};
    transform: rotate(${props => props.negative ? 0 : 180}deg);
    margin-right: 5px;
`;

const StyledHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;


interface IProps {}

const Cell: React.FC<{
    amount: number,
    type: string,
    action: string,
}> = memo(({amount, type, action}) => {
    const negative = amount < 0 || type === 'transaction' || type === 'cashout'
    return (
        <StyledEmployeeWrapper>
            {action !== 'base_entry' &&
                <AmountWrapper negative={negative}>
                    <Triangle negative={negative} /> <FormatNumbers showZero={true} value={amount}/>
                </AmountWrapper>
            }
        </StyledEmployeeWrapper>
    );
});

// eslint-disable-next-line no-empty-pattern
const Change: React.FC<IProps> = ({}) => (
    <Column
        dataKey='amount'
        width={120}
        flexGrow={0}
        flexShrink={0}
        cellDataGetter={defaultInfiniteTableCellDataGetter}
        headerRenderer={() => (
            <StyledHeaderWrapper>
                {intl.get('change')}
            </StyledHeaderWrapper>
        )}
        cellRenderer={({cellData}) => (
            <Cell
                amount={cellData.amount}
                type={cellData.type}
                action={cellData.action}
            />
        )}
    />
);
export default Change;
