import React, {PureComponent} from 'react';
import styled from 'styled-components';

import {stylingVariables, constants, intl} from '@global';
import {EnvironmentHelper} from '@library';
import {Icon, StyledInputWrapper} from '@common';
import {Footer} from 'hrb-common';

import AlphabetListEntitiesWithSearch from './AlphabetListEntitiesWithSearch';

const StyledEntitiesWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 750px;
  min-height: 800px;
  border-radius: 8px;
  padding: 20px 0px;
  margin: 40px auto;
  max-height: calc(100vh - 200px);

  background-color: ${stylingVariables.colorPalette.white};
  box-shadow: 0px 6px 16px #CACED566;

  ${StyledInputWrapper} {
    width: 442px;
    margin: 40px auto;

    input {
      height: 48px;
      border-radius: 24px;
    }

    svg {
        top: 17px;
        right: 20px;
    }
  }
`;

const StyledHeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
`;

const StyledTitle = styled.h4`
  margin: 10px 0;
`;

const StyledSubTitle = styled.span`
  font-size: ${stylingVariables.fontSize.mediumLarge};
  color: ${stylingVariables.colorPalette.deepGray};
`;

const BackToLaunchpad = styled.a`
  text-align: center;
  color: ${stylingVariables.colorPalette.green};
  font-size: ${stylingVariables.fontSize.mediumLarge};
  font-weight: ${stylingVariables.fontWeight.bold};
  text-decoration: none;
  margin-top: auto;
`;

const StyledCutout = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  height: 80px;
  width: 80px;
  background: ${stylingVariables.colorPalette.white};
  top: -44px;
  border: 4px solid ${stylingVariables.colorPalette.gray};
  left: calc(50% - 40px);
`

export default class Entities extends PureComponent {
    public render() {

      const footerMessages = {
        termOfUse: intl.get('term_of_use'),
        sla: intl.get('sla'),
        privacyPolicy: intl.get('privacy_policy')
      }

      return (
          <StyledEntitiesWrapper>
              <StyledCutout>
                  <Icon type={constants.IconTypes.MSS} width={48} height={44}/>
              </StyledCutout>
              <StyledHeaderWrapper>
                  <StyledTitle>{intl.get('admin_console')}</StyledTitle>
                  <StyledSubTitle>{intl.get('select_entity' )}</StyledSubTitle>
              </StyledHeaderWrapper>

              <AlphabetListEntitiesWithSearch />

              <BackToLaunchpad href={EnvironmentHelper.getLaunchpadUrl()}>
                  {intl.get('back_to_launchpad')}
              </BackToLaunchpad>

              <Footer messages={footerMessages} />
          </StyledEntitiesWrapper>
      );
    }
}
