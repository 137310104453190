import { EmployeeInterfaces } from '@api'

export default function generateInvitations(employees: EmployeeInterfaces.Employee[], ids: Set<number>) {
  return new Map(
    employees
      .filter((employee) => ids.has(employee.id))
      .map((employee) => {
        return [
          employee.id,
          {
            id: employee.id,
            fullName: employee.fullName,
            loginUsername: employee.loginUsername || employee.person.primaryEmail || '',
            validationError: null,
            terminationStatus: employee.terminationStatus,
          },
        ]
      })
  )
}
