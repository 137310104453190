import React, {PureComponent} from 'react';
import styled from 'styled-components';
import {Icon, BlankButton} from 'hrb-common';

import {stylingVariables, constants} from '@global';

const Wrapper = styled.div`
  display: flex;
  height: 40px;
  cursor: pointer;
`;

const StyledLabel = styled.div`
  padding: 0 10px;
  background-color: #fff;
  height: 40px;
  min-width: 180px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${stylingVariables.colorPalette.darkest};
  font-size: ${stylingVariables.fontSize.medium};
  font-weight: ${stylingVariables.fontWeight.semibold};
  border-top: 1px solid ${stylingVariables.colorPalette.mediumGreen};
  border-bottom: 1px solid ${stylingVariables.colorPalette.mediumGreen};
  position: relative;
;`;
const PrevButton = styled(props => <BlankButton {...props} />)`
    width: 40px;
    border: 1px solid ${stylingVariables.colorPalette.mediumGreen};
    height: 40px;
    background-color: ${stylingVariables.colorPalette.lightGreen};
    border-right: 0;
    border-radius: 50% 0 0 50%;
    &:hover {
      transition: background-color .3s;
      background-color: ${stylingVariables.colorPalette.mediumGreen};
      svg {
        fill: #fff;
      }
    }
`;
const NextButton = styled(props => <BlankButton {...props} />)`
    width: 40px;
    border: 1px solid ${stylingVariables.colorPalette.mediumGreen};
    height: 40px;
    background-color: ${stylingVariables.colorPalette.lightGreen};
    border-left: 0;
    border-radius: 0 50% 50% 0;
    &:hover {
      transition: background-color .3s;
      background-color: ${stylingVariables.colorPalette.mediumGreen};
      svg {
        fill: #fff;
      }
    }
`;

export interface IProps {
    year: number;
    onChange: (year: number) => void;
}

export class InlineYearSelector extends PureComponent<IProps> {
    render() {
        return (
            <Wrapper>
                <PrevButton onClick={this.prev}>
                    <Icon type={constants.hrb.IconTypes.ARROW_LEFT}
                          width={10}
                          height={10}
                          fillColor={stylingVariables.hrb.colorPalette.green}
                    />
                </PrevButton>

                <StyledLabel>
                    {this.props.year}
                </StyledLabel>

                <NextButton onClick={this.next}>
                    <Icon type={constants.hrb.IconTypes.ARROW_RIGHT}
                          width={10}
                          height={10}
                          fillColor={stylingVariables.hrb.colorPalette.green}
                    />
                </NextButton>
            </Wrapper>
        );
    }

    private prev = () => {
        this.props.onChange(this.props.year - 1);
    }

    private next = () => {
        this.props.onChange(this.props.year + 1);
    }
}

export default InlineYearSelector;
