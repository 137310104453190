import { ColorHelper } from '@library'
import styled from 'styled-components'

import { stylingVariables } from '@global'

const StyledEditButton = styled.div`
  position: absolute;
  right: 50px;
  top: 0;
  color: ${stylingVariables.colorPalette.primary};
  cursor: pointer;
  font-size: 14px;
  font-weight: ${stylingVariables.fontWeight.semibold};
  border-radius: 15px;
  height: 30px;
  display: flex;
  padding: 0 20px;
  align-items: center;
  justify-content: center;
  background-color: #fff;

  &:hover {
    background-color: ${ColorHelper.hexToRgbA(stylingVariables.colorPalette.primaryHover, 0.3)};
    transition: all 0.5s;
  }
`
export default StyledEditButton
