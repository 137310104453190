import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'

import { EmployeeInterfaces } from '@api'
import { Switch } from '@common'
import { stylingVariables } from '@global'

import SendEssInvitation from '../../common/Employee/SendEssInvitation/SendEssInvitation'
import generateInvitations from '../../common/Employee/SendEssInvitation/generateInvitations'
import RevokeEssAccess from '../../common/Employee/RevokeEssAccess'

import { sendEssInvitation, revokeEssAccess } from '../redux'
import { RootState } from '../../../rootReducer'
import * as globalSelectors from '../../selectors'

const StyledWrapper = styled.div`
  button {
    color: ${stylingVariables.colorPalette.green};
    font-weight: ${stylingVariables.fontWeight.regular};
  }
`

interface IProps {
  employee: EmployeeInterfaces.Employee
  defaultWelcomeMessage: string
  isLoading: boolean

  sendEssInvitation: (payload: any) => void
  revokeEssAccess: (id: number) => void
}

interface IState {
  isOpen: boolean
}

class ChangeEssAccess extends PureComponent<IProps, IState> {
  public constructor(props: IProps) {
    super(props)

    this.state = {
      isOpen: false,
    }
  }

  public render() {
    return (
      <StyledWrapper>
        <Switch
          current={this.props.employee.essStatus as string | null}
          options={
            new Map([
              [EmployeeInterfaces.EssStatus.invitation_sent, this.renderRevokeEssAccessAction()],
              [EmployeeInterfaces.EssStatus.account_created, this.renderRevokeEssAccessAction()],
            ])
          }
          default={this.renderSendEssInvitation()}
        />
      </StyledWrapper>
    )
  }

  private renderSendEssInvitation = () => {
    return (
      <SendEssInvitation
        forEmployeeIds={new Set([this.props.employee.id])}
        defaultWelcomeMessage={this.props.defaultWelcomeMessage}
        defaultInvitations={generateInvitations([this.props.employee], new Set([this.props.employee.id]))}
        isLoading={this.props.isLoading}
        onClickSend={this.props.sendEssInvitation}
      />
    )
  }

  private renderRevokeEssAccessAction = () => {
    return (
      <RevokeEssAccess
        forEmployeeIds={new Set([this.props.employee.id])}
        isLoading={this.props.isLoading}
        onClickRevoke={this.revokeEssAccess}
      />
    )
  }

  private revokeEssAccess = () => this.props.revokeEssAccess(this.props.employee.id)
}

const mapStateToProps = (state: RootState, ownProps: any) => ({
  defaultWelcomeMessage: globalSelectors.getWelcomeMessage(state, ownProps),
  isLoading: globalSelectors.isLoading(state),
})

const mapDispatchToProps = {
  sendEssInvitation,
  revokeEssAccess,
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangeEssAccess)
