import styled from 'styled-components';
import { stylingVariables } from '@global'

const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 78px;

  h4 {
    font-size: ${stylingVariables.fontSize.largeMedium};
    font-weight: ${stylingVariables.fontWeight.bold};
  }
`;

export default StyledHeader;
