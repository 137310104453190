import {ApiObject} from '@api';
import {Actions, Back, Popover, Icon} from '@common';
import {stylingVariables, constants, intl, interfaces} from '@global';
import {RouteHelper, ReportTemplatesHelper, ColorHelper} from '@library';
import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {toast} from 'react-toastify';
import styled from 'styled-components';
import {RootState} from '../../../../rootReducer';
import {
	convertTemplateColumnsToSelectDataSchema,
	convertSelectDataSchemaToTemplateColumns,
	isReportProcesses, isTemplateProcesses, isValidSchema, getInvalidSchemaKeys
} from '../../utils';
import DateRangeSelector from '../common/DateRangeSelector/DateRangeSelector';
import SaveReportModal from '../common/SaveReportModal/SaveReportModal';
import SaveTemplateModal from '../common/SaveTemplateModal/SaveTemplateModal';
import {changeTemplate, updateTemplate} from '../redux';
import * as selectors from '../selectors';
import SelectDataBuilder from './SelectDataBuilder';
import VisibilitySelector from '../common/VisibilitySelector/VisibilitySelector';

const Wrapper = styled.div`
	position: relative;
	height: 100%;
	display: flex;
	flex-direction: column;
	margin-right: 30px;
`;

const Heading = styled.div`
	margin-bottom: 20px;
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

const HeadingBreadCrumbs = styled.div`
	display: inline-flex;
  	align-items: center;
`;

const Main = styled.div`
	background-color: #fff;
	border-radius: 6px;
	height: calc(100% - 100px);
	position: relative;
	display: flex;
	flex-direction: column;
`;
const StyledBuilder = styled.div`
	margin: 0 15px 15px;
	height: 100%;
	overflow-y: auto;
`;
const PageDescription = styled.div`
	font-size: ${stylingVariables.fontSize.large};
	font-weight: ${stylingVariables.fontWeight.semibold};
	margin: 40px 20px 0;
`;
const FastOptions = styled.div`
	position: absolute;
	top: 70px;
	right: -25px;
	width: 50px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	z-index: 2;
`;
const FastAction = styled.div`
	cursor: pointer;
	height: 52px;
	width: 52px;
	margin-top: 30px;
	background-color: ${stylingVariables.colorPalette.gray};
	border-radius: 50%;
	padding: 6px;
	
	display: flex;
	align-items: center;
	justify-content: center;
`;

const FastActionContent = styled.div<{color: string}>`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	width: 100%;
	border-radius: 50%;
	background-color: ${props => ColorHelper.hexToRgbA(props.color, .7)};
	color: #fff;
	position: relative;
	
	&:hover {
		transition: background-color .2s;
		background-color: ${props => props.color};
	}
`;
const CountEmployeesInfo = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	position: absolute;
	top: -12px;
	right: -12px;
	height: 24px;
	min-width: 28px;
	border-radius: 12px;
	background-color: ${stylingVariables.colorPalette.yellow};
	color: ${stylingVariables.colorPalette.darkest};
	font-size: ${stylingVariables.fontSize.medium};
	font-weight: ${stylingVariables.fontWeight.semibold};
`;

const Title = styled.div`
	margin-left: 10px;
	font-weight: ${stylingVariables.fontWeight.semibold};
	font-size: ${stylingVariables.fontSize.mediumLarge};
	.page_title {
		font-size: ${stylingVariables.fontSize.largest};
	}
`;
const Footer = styled.div`
	z-index: 3;
	padding: 0 20px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: absolute;
	bottom: -20px;
	left: 0;
	right: 0;
	height: 60px;
	border-radius: 10px 10px 0 0;
	box-shadow: ${stylingVariables.layout.boxShadow};
	background-color: #fff;
`;

interface IProps {
	mode: constants.ReportProcessMode;
	totalEmployees: number;
	reportName: string;
	template: ApiObject.ReportTemplate|null;
	report: ApiObject.Report|null;

	changeTemplate: (template: ApiObject.ReportTemplate) => any;
	updateTemplate: (template: ApiObject.ReportTemplate) => any;
}

interface IState {
	rawTemplate: ApiObject.ReportTemplate|null;
	schema: interfaces.ReportSelectDataSchema;
	dataSource: ApiObject.DataSource;

	isOpenedSaveReportModal: boolean;
	isOpenedSaveTemplateModal: boolean;

	invalidSchemaKeys: string[];
}

export class SelectData extends PureComponent<IProps, IState> {
	constructor(props: IProps) {
		super(props);

		this.state = {
			rawTemplate: null,
			schema: {
				masterDataSchema: {groups: []},
				payElementsSchema: {groups: []},
			},
			dataSource: ApiObject.DataSource.payroll,

			isOpenedSaveReportModal: false,
			isOpenedSaveTemplateModal: false,

			invalidSchemaKeys: []
		}
	}

	static getDerivedStateFromProps(nextProps: IProps, prevState: IState) {
		if (nextProps.template && JSON.stringify(nextProps.template) !== JSON.stringify(prevState.rawTemplate)) {
			return {
				rawTemplate: nextProps.template,
				schema: convertTemplateColumnsToSelectDataSchema(nextProps.template.columns)
			}
		}
		return null;
	}

	onSaveAsTemplate = () => {
		const storedTemplate = this.storeTemplate();
		if (storedTemplate) {
			this.setState({isOpenedSaveTemplateModal: true});
		}
	}

	onSaveTemplate = async () => {
		const storedTemplate = this.storeTemplate();
		if (storedTemplate) {
			await this.props.updateTemplate(storedTemplate);

			if (isTemplateProcesses(this.props.mode)) {
				RouteHelper.goToReportTemplates();
			}
		}
	}

	onSaveAndGenerate = () => {
		const storedTemplate = this.storeTemplate();
		if (storedTemplate) {
			this.setState({isOpenedSaveReportModal: true});
		}
	}

	storeTemplate = (): ApiObject.ReportTemplate|null => {
		if (!this.props.template) {
			return null;
		}

		if (!this.isValid()) {
			toast.warn(intl.get('template_def_columns_required'))

			this.setState({
				invalidSchemaKeys: getInvalidSchemaKeys(this.state.schema)
			});

			return null;
		} else {
			this.setState({
				invalidSchemaKeys: []
			});
		}

		const storedTemplate: ApiObject.ReportTemplate = {
			...this.props.template,
			id: this.props.template.id || -1,
			columns: convertSelectDataSchemaToTemplateColumns(this.state.schema, this.state.dataSource)
		};

		this.props.changeTemplate(storedTemplate);

		return storedTemplate;
	}

	isValid = () => {
		return isValidSchema(this.state.schema);
	}

	renderActions = () => {
		if (isReportProcesses(this.props.mode)) {
			return <>
				<Actions.ColorButton onClick={this.onSaveAsTemplate} color={stylingVariables.colorPalette.green}>
					{intl.get('save_as_a_template')}
				</Actions.ColorButton>
				<Actions.ColorButton onClick={this.onSaveAndGenerate} color={stylingVariables.colorPalette.orange}>
					{intl.get('save_and_generate_report')}
				</Actions.ColorButton>
			</>
		}

		if (isTemplateProcesses(this.props.mode)) {
			return <>
				{this.props.mode === constants.ReportProcessMode.editTemplate && ReportTemplatesHelper.isCurrentUserOwnerOnTemplate(this.props.template) &&
					<Actions.ColorButton onClick={this.onSaveTemplate} color={stylingVariables.colorPalette.green}>
						{intl.get('save')}
					</Actions.ColorButton>
				}

				<Actions.ColorButton onClick={this.onSaveAsTemplate} color={stylingVariables.colorPalette.orange}>
					{intl.get('save_as')}
				</Actions.ColorButton>
			</>
		}
	}

	onPreviewClick = () => {
		const storedTemplate = this.storeTemplate();
		if (storedTemplate) {
			RouteHelper.goToReportPreview();
		}
	}

	onSelectedEmployeesClick = () => {
		const storedTemplate = this.storeTemplate();
		if (storedTemplate) {
			RouteHelper.goToReportSelectEmployees();
		}
	}

	public render() {
		const {template} = this.props;
		return (
			<Wrapper>
				<Heading>
					<HeadingBreadCrumbs>
						<Back.Button />
						<Title>
							<span className={'page_title'}>{ReportTemplatesHelper.pageTitleByMode(this.props.mode)} / </span> {this.props.reportName}
						</Title>
					</HeadingBreadCrumbs>
					<div>
						<DateRangeSelector />
						{isTemplateProcesses(this.props.mode) &&
							<VisibilitySelector
								forTemplate={true}
								style={{
									flexDirection: 'row',
									alignItems: 'center',
								}}
							/>
						}
					</div>
				</Heading>
				<Main>
					<PageDescription>
						{intl.get('select_data_fields_to_report')}
					</PageDescription>
					<StyledBuilder>
						{template &&
							<SelectDataBuilder
								invalidSchemaKeys={this.state.invalidSchemaKeys}
								schema={this.state.schema}
								onChange={(schema: interfaces.ReportSelectDataSchema) => this.setState({schema})}
								onDataSourceChange={(dataSource: ApiObject.DataSource) => this.setState({dataSource})}
							/>
						}
					</StyledBuilder>
				</Main>
				<FastOptions>
					<Popover message={intl.get('preview_report')} positionLeft={true}>
						<FastAction onClick={this.onPreviewClick}>
							<FastActionContent color={stylingVariables.colorPalette.blue}>
								<Icon type={constants.IconTypes.EYE} height={22} width={22} fillColor={'#fff'} />
							</FastActionContent>
						</FastAction>
					</Popover>
					{!this.props.mode.indexOf('Template') && <Popover message={intl.get('selected_employees')} positionLeft={true}>
						<FastAction onClick={this.onSelectedEmployeesClick}>
							<FastActionContent color={stylingVariables.colorPalette.green}>
								<Icon type={constants.IconTypes.EMPLOYEES} height={22} width={22} fillColor={'#fff'} />
								<CountEmployeesInfo>
									{this.props.totalEmployees}
								</CountEmployeesInfo>
							</FastActionContent>
						</FastAction>
					</Popover>}
				</FastOptions>
				<Footer>
					<div>
						<Actions.GrayButton onClick={isTemplateProcesses(this.props.mode) ? RouteHelper.goToReportTemplates : RouteHelper.goToReports}>
							{intl.get('cancel')}
						</Actions.GrayButton>
					</div>
					<div>
						{this.renderActions()}
					</div>
				</Footer>

				{this.state.isOpenedSaveReportModal &&
					<SaveReportModal onClose={() => this.setState({isOpenedSaveReportModal: false})} />
				}
				{this.state.isOpenedSaveTemplateModal &&
					<SaveTemplateModal onClose={() => this.setState({isOpenedSaveTemplateModal: false})}/>
				}
			</Wrapper>
		);
	}
}

const mapStateToProps = (state: RootState) => ({
	mode: selectors.getMode(state),
	template: selectors.getTemplate(state),
	reportName: selectors.getReportName(state),
	report: selectors.getReport(state),
	totalEmployees: selectors.getTotalEmployees(state)
});

const mapDispatchToProps = {
	changeTemplate,
	updateTemplate
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectData);
