import React from 'react';
import {Column} from 'react-virtualized';

import {defaultInfiniteTableCellDataGetter} from '@common';

import Cell from '../Actions/Cell';

const Actions = (employee: any) => (
    <Column
        dataKey={'actions'}
        width={80}
        flexGrow={0}
        cellDataGetter={defaultInfiniteTableCellDataGetter}
        cellRenderer={({cellData}) => <Cell transactions={cellData.transactions} employee={employee} />}
    />
);

export default Actions;
