import React, { PureComponent } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'

import { ApiObject } from '@api'
import { BlankButton, ChartersPreview } from '@common'

import { RootState } from '../../../rootReducer'
import * as selectors from '../../selectors'
import * as stylingVariables from '../../../stylingVariables'

import DropdownHeader from './DropdownHeader'
import SingOut from './Actions/SingOut'
import LocaleChanger from './LocaleChanger'

const ProfileMenuWrapper = styled.div`
  position: relative;
  z-index: 2000;
`

export const StyledLogo = styled((props) => <BlankButton {...props} />)`
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  width: 40px;
  min-width: 40px;
  height: 40px;
  min-height: 40px;

  &:hover {
    opacity: 0.8;
  }

  img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
`

export const StyledDropdownContent = styled.div`
  position: absolute;
  top: 65px;
  right: -10px;
  min-width: 288px;
  background-color: ${stylingVariables.colorPalette.white};
  box-shadow: ${stylingVariables.layout.boxShadow};
  border-radius: ${stylingVariables.layout.borderRadius};
  &:before {
    content: ' ';
    position: absolute;
    top: -15px;
    right: 15px;
    display: block;
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 15px solid ${stylingVariables.colorPalette.white};
  }
`

interface ProfileMenuProps {
  user: ApiObject.AuthorizedUser | null
  userLegalEntity: ApiObject.LegalEntity
  currentLegalEntity: ApiObject.LegalEntity,
}

interface ProfileMenuState {
  isOpen: boolean
}

export class ProfileMenu extends PureComponent<ProfileMenuProps, ProfileMenuState> {
  private wrapperRef: any

  constructor(props: any) {
    super(props)

    this.state = {
      isOpen: false,
    }
  }

  public componentDidMount() {
    document.addEventListener('mousedown', this.closeWhenClickOutside)
  }

  public componentWillUnmount() {
    document.removeEventListener('mousedown', this.closeWhenClickOutside)
  }

  render() {
    if (!this.props.user) {
      return null
    }

    return (
      <ProfileMenuWrapper ref={(node: any) => (this.wrapperRef = node)}>
        <StyledLogo onClick={this.toggle}>
          {this.props.user.photo ? (
            <img src={this.props.user.photo} alt={'Current User'} />
          ) : (
            <ChartersPreview text={this.props.user.employee.name} width={'40px'} height={'40px'} />
          )}
        </StyledLogo>

        {this.state.isOpen && (
          <StyledDropdownContent>
            <DropdownHeader user={this.props.user} />
            {this.props.userLegalEntity && (
              <LocaleChanger onSelect={this.close} userLegalEntity={this.props.currentLegalEntity} />
            )}
            <SingOut />
          </StyledDropdownContent>
        )}
      </ProfileMenuWrapper>
    )
  }

  public toggle = () => {
    this.setState({ isOpen: !this.state.isOpen })
  }

  public close = () => {
    this.setState({ isOpen: false })
  }

  public closeWhenClickOutside = (event: Event) => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.close()
    }
  }
}

const mapStateToProps = (state: RootState, ownProps: any) => ({
  user: selectors.getAuthorizedUser(state),
  userLegalEntity: selectors.getUserLegalEntity(state),
  currentLegalEntity: selectors.getCurrentLegalEntity(state, ownProps),
})

export default connect(mapStateToProps)(ProfileMenu)
