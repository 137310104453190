import { ApiObject } from '@api';
import React from 'react';
import { Column } from 'react-virtualized';

import { defaultInfiniteTableCellDataGetter, FormatNumbers } from '@common';
import { get } from 'lodash';
import { intl } from '@global';
import styled from 'styled-components';
import {stylingVariables} from '@global';

interface DefaultColumnProps {
  dataKey: string;
  label: string;
  width?: number;
  flexGrow?: number;
}

const CellWrapper = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
`

const Cell = styled.div<{borderBottom?: boolean, strikethroughText?: boolean}>`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  ${({strikethroughText}) => strikethroughText && 'text-decoration: line-through'};
  &::after {
    content: '';
    display: block;
    position: absolute;
    left: -20px;
    right: -20px;
    bottom: 0;
    height: 1px;
    background-color: ${({borderBottom}) => borderBottom && stylingVariables.colorPalette.lightestGray};
  }
`

const DoubleColumnAmount: React.FC<DefaultColumnProps> = ({
  dataKey,
  label,
  width = 80,
  flexGrow = 1,
}: DefaultColumnProps) => (
  <Column
    label={label}
    dataKey={dataKey}
    width={width}
    style={{ justifyContent: 'flex-end' }}
    flexGrow={flexGrow}
    cellDataGetter={defaultInfiniteTableCellDataGetter}
    headerRenderer={() => (
      <div style={{ textAlign: 'right' }}>{label}</div>
    )}
    cellRenderer={({ cellData }: any) => {
      let value = get(cellData, dataKey, null);
      if (!value && value !== 0) {
        value =  '-';
      }

      const hasChanges = cellData.changes && cellData.changes.length > 0

      let latestValue = null
      let latestChanges = null
      if (hasChanges) {
        latestChanges = cellData.changes.reduce((a: any, b: any) => (a.startDate > b.startDate ? a : b));
        latestValue =  get(latestChanges, dataKey, null);
        
        if (!latestValue && latestValue !== 0) {
          latestValue = '-';
        }
      }

      return (
        <CellWrapper>
          <Cell borderBottom={hasChanges} strikethroughText={hasChanges}>
            {(cellData.pay_unit === ApiObject.PayUnitType.percentage && dataKey === 'amount')
              ? <>{cellData[dataKey]}{intl.get('percentage_symbol')}</>
              : value === '-' ? value : <FormatNumbers showZero={true} value={value} />}
          </Cell>
          {hasChanges && (
            <Cell>{(latestChanges.pay_unit === ApiObject.PayUnitType.percentage && dataKey === 'amount')
            ? <>{latestChanges[dataKey]}{intl.get('percentage_symbol')}</>
            : latestValue === '-' ? latestValue : <FormatNumbers showZero={true} value={latestValue} />}</Cell>
          )}
        </CellWrapper>
      )
    }}
  />
);

export default DoubleColumnAmount;
