import React, {PureComponent} from 'react';
import styled from 'styled-components';

import {stylingVariables, constants, interfaces} from '@global';
import {Checkbox, Icon} from '@common';

import {SortableContainer, SortableElement, SortableHandle} from 'react-sortable-hoc';

const StyledColumnWrapper = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 7px 10px;
  background-color: ${stylingVariables.colorPalette.white};
  border: 1px solid ${stylingVariables.colorPalette.gray};
  margin-bottom: 5px;
  z-index: 300000;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    label {
      margin-left: 10px;
      font-size: ${stylingVariables.fontSize.medium};
      font-weight: ${stylingVariables.fontWeight.regular};
    }
    svg {
      cursor: pointer;
    }
  }

  svg {
    cursor: move;
  }
`;

interface ColumnProps {
    column: interfaces.EmployeeColumn;
    isChecked: boolean;

    children?: any;

    onClickCheckbox: (code: string) => void;
}

export class Column extends PureComponent<ColumnProps> {
    render() {
        return (
            <StyledColumnWrapper>
                <div>
                    <Checkbox
                        isChecked={this.props.isChecked}
                        onClick={this.onClickCheckbox}
                    />

                    <label>{this.props.column.label}</label>
                </div>

                {this.props.children}
            </StyledColumnWrapper>
        );
    }

    private onClickCheckbox = () => this.props.onClickCheckbox(this.props.column.code);
}

const DragHandle = SortableHandle(() => (
    <Icon
        type={constants.IconTypes.MOVE}
        fillColor={stylingVariables.colorPalette.green}
        width={15}
        height={15}
    />
));

export const Columns: React.FC<{children: any}> = ({children}) => <ul>{children}</ul>;
export const SortableColumns = SortableContainer(Columns);
export const SortableColumn = SortableElement(({column, isChecked, onCheckColumn}: {
    column: interfaces.EmployeeColumn,
    isChecked: boolean;
    onCheckColumn: (code: string) => void,

}) => (
    <Column column={column} isChecked={isChecked} onClickCheckbox={onCheckColumn}>
        <DragHandle/>
    </Column>
));
