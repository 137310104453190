import {Actions, Back} from '@common';
import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import ReactResizeDetector from 'react-resize-detector';

import {ApiObject, EmployeeInterfaces, EmployeeQueryParams, ApiEmployee} from '@api';
import {intl, interfaces, stylingVariables, constants} from '@global';
import {
    EmployeeQueryFieldBuilder,
    Functions,
    EmployeeListingHelper,
    RouteHelper
} from '@library';

import {RootState} from '../../../../../rootReducer';
import {startLoading, stopLoading} from '../../../../redux';
import * as selectors from '../../selectors';
import * as globalSelectors from '../../../../selectors';
import DateRangeSelector from '../DateRangeSelector/DateRangeSelector';
import SaveReportModal from '../SaveReportModal/SaveReportModal';
import Filters from './Filters';
import {
    Wrapper,
    StyledTables,
    StyledAvailableTable,
    StyledSelectedTable,
    Footer,
    Title,
    Heading,
    HeadingBreadCrumbs
} from './Styles';
import Table from './Table/Table';

import {GroupedQueriesBuilder, AllowedStatus} from './groupedQueries.builder';

export enum TableType {
    available = 'available',
    selected = 'selected'
}

interface DefaultStateTableSettings {
    employees: EmployeeInterfaces.Employee[];
    totalCount: number;
    orders: interfaces.ListingOrders;
    filters: interfaces.ListingFilters;
    nextPointer: string|null;
    isLoading: boolean;

    isCheckSelectAll: boolean;
    selectedIds: Set<number>;
}

interface IProps {
    mode: constants.ReportProcessMode;
    queries: ApiObject.GrouperQueries;
    totalEmployees: number;
    reportName: string;
    onSave: (queries: ApiObject.GrouperQueries) => void;
    onCancel: () => void;
    currentLegalEntityId: number|null;
    startLoading: () => void;
    stopLoading: () => void;
    setTotalEmployees: (count: number) => void;
    listOptionsOfEmployeeGroups: ApiObject.SelectOption[];
    isDraftAllowed?: boolean
}

interface IState {
    isOpenedSaveReportModal: boolean;
    queries: ApiObject.GrouperQueries;
    employeeStatus: AllowedStatus;
    search: string;
    available: DefaultStateTableSettings;
    selected: DefaultStateTableSettings;
    statisticByStatuses: any;
    isSaveButtonDisabled: boolean;
}

export class SelectEmployees extends PureComponent<IProps, IState> {
    private queriesBuilder: any;

    static defaultProps = {
        isDraftAllowed: true,
    }

    constructor(props: IProps) {
        super(props);

        const defaultTableSettings = {
            employees: [],
            totalCount: 0,
            orders: {},
            filters: {},
            nextPointer: null,
            isLoading: false,
            isCheckSelectAll: false,
            selectedIds: new Set() as Set<number>,
        };

        this.state = {
            isSaveButtonDisabled: false,
            isOpenedSaveReportModal: false,
            queries: props.queries,
            employeeStatus: ApiObject.Status.active,
            search: '',
            available: {...defaultTableSettings},
            selected: {...defaultTableSettings},
            statisticByStatuses: {
                [ApiObject.Status.active]: 0,
                [ApiObject.Status.inactive]: 0,
                [ApiObject.Status.draft]: 0
            }
        }

        this.initQueriesBuilder();
    }

    private initQueriesBuilder = (status?: AllowedStatus) => {
        this.queriesBuilder = GroupedQueriesBuilder.build(
            this.state.queries,
            (status || this.state.employeeStatus) as AllowedStatus,
            this.state.statisticByStatuses
        );
    }

    componentDidMount() {
        this.reload();
        this.setState((state) => ({
            ...state,
            isSaveButtonDisabled: this.props.totalEmployees <= 0,
        }))
    }

    async updateTotalCountOfEmployees() {
        if (this.state.queries.length === 0) {
            this.props.setTotalEmployees(0);
            return;
        }
        const {total} = await ApiEmployee.list(this.props.currentLegalEntityId as number, new EmployeeQueryParams({
            groupedQueries: this.state.queries,
            limit: 1,
        }));
        this.props.setTotalEmployees(total);
    }

    /**
     * Full reload
     */
    private reload = async () => {
        // Get statistics
        await this.getStatistics();

        // Get employees for two tables
        this.reloadEmployees();
        this.reloadSelectedEmployees();

        // Init query builder
        this.initQueriesBuilder();

        // Update total count
        this.updateTotalCountOfEmployees();
    }

    /**
     * Retrieve statistic by each employee status
     */
    getStatistics = async () => {
        const { currentLegalEntityId } = this.props;

        const fetchTotal = async (status: AllowedStatus) => {
            if (this.isSelectedAllByStatus(status)) {
                return;
            }
            const params = new EmployeeQueryParams({
                status: status,
                queries: this.queriesParamsForAvailableList(status),
                limit: 1
            });
            const {total} = await ApiEmployee.list(currentLegalEntityId as number, params);
            this.setState(state => ({
                ...state,
                statisticByStatuses: {
                    ...state.statisticByStatuses,
                    [status as string]: total
                }
            }));
            return;
        }

        await Promise.all([
            fetchTotal(ApiObject.Status.active),
            fetchTotal(ApiObject.Status.inactive),
            fetchTotal(ApiObject.Status.draft)
        ]);
    }

    private reloadEmployees = () => {
        this.setState((state) => ({
            ...state,
            available: {
                ...state.available,
                nextPointer: null,
                employees: [],
                totalCount: 0,
                selectedIds: new Set(),
                isCheckSelectAll: false
            }
        }), this.loadEmployees);
    }

    private reloadSelectedEmployees = () => {
        this.setState((state) => ({
            ...state,
            selected: {
                ...state.selected,
                nextPointer: null,
                employees: [],
                totalCount: 0,
                selectedIds: new Set(),
                isCheckSelectAll: false
            }
        }), this.loadSelectedEmployees);
    }

    isSelectedAllByStatus(status: AllowedStatus|null = null) {
        let groupedQueriesBuilder = GroupedQueriesBuilder.build(this.state.queries, status || this.state.employeeStatus);
        return groupedQueriesBuilder.isSelectedAll()
            && groupedQueriesBuilder.getIds().length === 0
            && groupedQueriesBuilder.getNotIds().length === 0;
    }

    /**
     * Get queries for employee listing by current template groupedQueries
     * @param status
     */
    queriesParamsForAvailableList = (status: AllowedStatus): ApiObject.EntityFieldQuery[] => {
        const groupedQueriesBuilder = GroupedQueriesBuilder.build(this.state.queries, status)
        return EmployeeQueryFieldBuilder.build()
            .applyStatuses([status])
            .applyEmployeeNinIds(
                groupedQueriesBuilder.getIds()
            )
            .applyEmployeeIds(
                groupedQueriesBuilder.isSelectedAll() ? groupedQueriesBuilder.getNotIds() : []
            ).queries();
    }

    /**
     * Load available employees
     */
    loadEmployees = () => {
        const {employeeStatus, search, available: {nextPointer: stateNextPointer, orders, filters, employees: stateEmployees, isLoading}} = this.state;
        const {currentLegalEntityId} = this.props;

        if (isLoading || this.isSelectedAllByStatus(employeeStatus)) {
            return;
        }

        // All loaded
        if (stateEmployees.length > 0 && !stateNextPointer) {
            return;
        }

        const loadingForStatus = employeeStatus;
        this.toggleLoading(TableType.available, true, async () => {
            let params: any;
            if (stateNextPointer) {
                params = new EmployeeQueryParams({nextPointer: stateNextPointer});
            } else {
                params = new EmployeeQueryParams({
                    status: employeeStatus,
                    orders,
                    filters,
                    queries: this.queriesParamsForAvailableList(employeeStatus),
                    searchQuery: search
                });
            }

            this.props.startLoading();
            const {employees, total, nextPointer} = await ApiEmployee.list(currentLegalEntityId as number, params);
            this.props.stopLoading();

            this.toggleLoading(TableType.available, false, () => {
                if (loadingForStatus !== this.state.employeeStatus) {
                    // break current loading if while loading - user changed tab
                    // and run loading again for current tab
                    this.loadEmployees();
                    return;
                }
                this.setLoadedEmployees(TableType.available, {employees, total, nextPointer});
            });
        });
    }

    /**
     * Load selected employees.
     */
    loadSelectedEmployees = () => {
        const {search, queries, selected: {orders, filters,  nextPointer: stateNextPointer, employees: stateEmployees, isLoading}} = this.state;
        const {currentLegalEntityId} = this.props;

        // is loading or no queries - break
        if (isLoading || queries.length === 0) {
            return;
        }

        // All loaded - break
        if (stateEmployees.length > 0 && !stateNextPointer) {
            return;
        }

        this.toggleLoading(TableType.selected, true, async () => {
            let params: any;
            if (stateNextPointer) {
                params = new EmployeeQueryParams({nextPointer: stateNextPointer});
            } else {
                params = new EmployeeQueryParams({orders, filters, groupedQueries: queries, searchQuery: search});
            }

            this.props.startLoading();
            const {employees, total, nextPointer} = await ApiEmployee.list(currentLegalEntityId as number, params);
            this.props.stopLoading();

            this.toggleLoading(TableType.selected, false, () => {
                this.setLoadedEmployees(TableType.selected, {employees, total, nextPointer});
            });
        });
    }

    /**
     * Select employee from unselected table to Selected table
     * @param employee
     */
    selectEmployee = (employee: EmployeeInterfaces.Employee) => {
        const id = employee.id;

        if (this.queriesBuilder.getNotIds().includes(id)) {
            this.queriesBuilder.removeNotId(id);
        } else {
            this.queriesBuilder.addId(id);
        }

        this.updateQueries(this.queriesBuilder.queries());
        this.moveEmployeeInState(employee, TableType.available, TableType.selected);

        this.props.setTotalEmployees(this.props.totalEmployees + 1);

        //unselect in table if was selected
        this.unselectId(TableType.available)(employee.id);
    }

    /**
     * Unselect employee from Selected Table to Unselected table
     * @param employee
     */
    unselectEmployee = (employee: EmployeeInterfaces.Employee) => {
        this.queriesBuilder.setStatus(employee.status as AllowedStatus);
        const id = employee.id;

        if (this.queriesBuilder.getIds().includes(id)) {
            this.queriesBuilder.removeId(id);
        } else {
            this.queriesBuilder.addNotId(id);
        }

        this.updateQueries(this.queriesBuilder.queries());

        this.moveEmployeeInState(employee, TableType.selected, TableType.available);

        // Revert to real tab status
        this.queriesBuilder.setStatus(this.state.employeeStatus);

        this.props.setTotalEmployees(this.props.totalEmployees - 1);

        //unselect in table if was selected
        this.unselectId(TableType.selected)(employee.id);
    }

    /**
     * Select employees from unselected table to Selected table
     * @param employees
     */
    bulkSelectEmployees = (employees: EmployeeInterfaces.Employee[]) => {
        employees.forEach(employee => {
            const id = employee.id;

            if (this.queriesBuilder.getNotIds().includes(id)) {
                this.queriesBuilder.removeNotId(id);
            } else {
                this.queriesBuilder.addId(id);
            }

            this.moveEmployeeInState(employee, TableType.available, TableType.selected);
        })
        this.updateQueries(this.queriesBuilder.queries());
        this.props.setTotalEmployees(this.props.totalEmployees + employees.length);

        if (employees.length > 10) {
            this.reloadEmployees();
        }
    }

    /**
     * Unselect employee from Selected Table to Unselected table
     * @param employees
     */
    bulkUnselectEmployees = (employees: EmployeeInterfaces.Employee[]) => {
        employees.forEach(employee => {
            const id = employee.id;

            this.queriesBuilder.setStatus(employee.status as AllowedStatus);
            if (this.queriesBuilder.getIds().includes(id)) {
                this.queriesBuilder.removeId(id);
            } else {
                this.queriesBuilder.addNotId(id);
            }

            this.moveEmployeeInState(employee, TableType.selected, TableType.available);
        })

        this.updateQueries(this.queriesBuilder.queries());

        // Revert to real tab status
        this.queriesBuilder.setStatus(this.state.employeeStatus);

        this.props.setTotalEmployees(this.props.totalEmployees - employees.length);

        if (employees.length > 10) {
            this.reloadSelectedEmployees();
        }
    }

    private save = () => {
        this.props.onSave(this.state.queries);
        RouteHelper.goToReportSelectData();
    }

    render() {
        return <Wrapper>
            <Heading>
                <HeadingBreadCrumbs>
                    <Back.Button />
                    <Title>
                        <span className={'page_title'}>Selected Employees / </span> {this.props.reportName}
                    </Title>
                </HeadingBreadCrumbs>
                <div>
                    <DateRangeSelector />
                </div>
            </Heading>

            <Filters
                selectedEmployeeStatus={this.state.employeeStatus}
                search={this.state.search}

                onSelectStatus={this.onChangeEmployeeStatus}
                onSearch={this.onSearchEmployee}

                statisticByStatuses={this.state.statisticByStatuses}

                isDraftAllowed={this.props.isDraftAllowed}
            />

            <StyledTables>
                <StyledAvailableTable label={intl.get('available_employees')} id={'available_table'}>
                    <ReactResizeDetector handleWidth={true} handleHeight={true}>
                        <Table
                            employees={this.state.available.employees}
                            totalCount={this.state.statisticByStatuses[this.state.employeeStatus]}
                            filters={this.state.available.filters}
                            orders={this.state.available.orders}
                            changeOrder={this.changeOrder(TableType.available, this.reloadEmployees)}
                            changeFilter={this.changeFilter(TableType.available, this.reloadEmployees)}

                            selectEmployee={this.selectEmployee}
                            onLoadMoreEmployees={this.loadEmployees}

                            emptyListText={'All employees are selected'}
                            listOptionsOfEmployeeGroups={this.props.listOptionsOfEmployeeGroups}

                            selectedIds={this.state.available.selectedIds}
                            setSelectedIds={this.changeSelectedIds(TableType.available)}
                            onSelectAll={this.onSelectAll(TableType.available)}
                            onUnselectAll={this.onUnselectAll(TableType.available)}
                            bulkActionMoveEmployeeLabel={intl.get('select_employees')}
                            onMoveEmployeesBulkAction={this.bulkMoveEmployees(TableType.available)}
                        />
                    </ReactResizeDetector>
                </StyledAvailableTable>

                <StyledSelectedTable label={intl.get('selected_employees')} id={'selected_table'}>
                    <ReactResizeDetector handleWidth={true} handleHeight={true}>
                        <Table
                            employees={this.state.selected.employees}
                            totalCount={this.props.totalEmployees}
                            filters={this.state.selected.filters}
                            orders={this.state.selected.orders}
                            changeOrder={this.changeOrder(TableType.selected, this.reloadSelectedEmployees)}
                            changeFilter={this.changeFilter(TableType.selected, this.reloadSelectedEmployees)}

                            unselectEmployee={this.unselectEmployee}
                            onLoadMoreEmployees={this.loadSelectedEmployees}

                            emptyListText={intl.get('start_dropping_employees')}
                            listOptionsOfEmployeeGroups={this.props.listOptionsOfEmployeeGroups}

                            selectedIds={this.state.selected.selectedIds}
                            setSelectedIds={this.changeSelectedIds(TableType.selected)}
                            onSelectAll={this.onSelectAll(TableType.selected)}
                            onUnselectAll={this.onUnselectAll(TableType.selected)}
                            bulkActionMoveEmployeeLabel={intl.get('unselect_employees')}
                            onMoveEmployeesBulkAction={this.bulkMoveEmployees(TableType.selected)}
                        />
                    </ReactResizeDetector>
                </StyledSelectedTable>
            </StyledTables>

            <Footer>
                {this.renderActions()}
            </Footer>

            {this.state.isOpenedSaveReportModal &&
                <SaveReportModal onClose={() => this.setState({isOpenedSaveReportModal: false})}/>
            }
        </Wrapper>
    }

    onSaveAndGenerate = () => {
        this.props.onSave(this.state.queries);
        this.setState({isOpenedSaveReportModal: true});
    }

    renderActions = () => {
        const { totalEmployees } = this.props;        
        const { isSaveButtonDisabled } = this.state;
        this.setState((state) => ({
            ...state,
            isSaveButtonDisabled: totalEmployees <= 0,
        }))
        if (constants.ReportProcessMode.generateReport === this.props.mode) {
            return <>
                <Actions.GrayButton onClick={this.props.onCancel}>
                    {intl.get('cancel')}
                </Actions.GrayButton>

                <Actions.ColorButton onClick={this.onSaveAndGenerate} color={stylingVariables.colorPalette.orange}>
                    {intl.get('save_and_generate_report')}
                </Actions.ColorButton>
            </>
        }

        return <>
            <Actions.GrayButton onClick={this.props.onCancel}>
                {intl.get('cancel')}
            </Actions.GrayButton>
            <Actions.ColorButton isDisabled={isSaveButtonDisabled} onClick={this.save} color={stylingVariables.colorPalette.green}>
                {intl.get('save')}
            </Actions.ColorButton>
    
        </>
    }

    private onSearchEmployee = (search: string) => this.setState({search}, () => {
        this.reloadSelectedEmployees();
        this.reloadEmployees();
    });
    private changeOrder = (tableType: TableType, callback: () => any) => (column: string) => {
        this.setState((state) => ({
            ...state,
            [tableType]: {
                ...state[tableType],
                orders: Functions.defaultUpdateOrders({...state[tableType].orders}, column)
            }
        }), callback);
    }
    private changeFilter = (tableType: TableType, callback: () => any) => (params: interfaces.FilterParams) => {
        this.setState((state) => ({
            ...state,
            [tableType]: {
                ...state[tableType],
                filters: EmployeeListingHelper.updatedFilters(params, this.state[tableType].filters)
            }
        }), callback);
    }
    private onChangeEmployeeStatus = (employeeStatus: AllowedStatus) => {
        this.setState({employeeStatus}, () => {
            this.reloadEmployees();
            this.initQueriesBuilder(employeeStatus);
        });
    }
    private updateQueries = (queries: ApiObject.GrouperQueries, callback?: () => any) => {
        this.setState((state) => ({
            ...state,
            queries: [...(queries.map(subqueries => [...subqueries]))]
        }), callback);
    }

    private toggleLoading = (tableType: TableType, isLoading: boolean, callback?: () => any) => {
        this.setState(state => ({
            ...state,
            [tableType]: {
                ...state[tableType],
                isLoading
            }
        }), callback);
    }

    private setLoadedEmployees(
        tableType: TableType,
        {total, employees, nextPointer}: { total: number | any; employees: EmployeeInterfaces.Employee[] | any; nextPointer: string|null }
    ) {
        const isCheckSelectAll = this.state[tableType].isCheckSelectAll;
        if (isCheckSelectAll) {
            const selectedIds = this.state[tableType].selectedIds;
            employees.forEach((employee: EmployeeInterfaces.Employee) => {
                selectedIds.add(employee.id);
            });

            this.setState(state => ({
                ...state,
                [tableType]: {
                    ...state[tableType],
                    selectedIds,
                }
            }));
        }

        this.setState(state => ({
            ...state,
            [tableType]: {
                ...state[tableType],
                employees: [...state[tableType].employees, ...employees],
                totalCount: total,
                nextPointer
            }
        }));
    }

    private changeSelectedIds = (tableType: TableType, callback?: () => any) => (ids: Set<number>) => {
        this.setState((state) => ({
            ...state,
            [tableType]: {
                ...state[tableType],
                selectedIds: ids
            }
        }), callback);
    }

    private unselectId = (tableType: TableType) => (id: number) => {
        const selectedIds = this.state[tableType].selectedIds;

        if (selectedIds.has(id)) {
            selectedIds.delete(id);

            this.changeSelectedIds(tableType)(selectedIds);
        }
    }

    private onSelectAll = (tableType: TableType, callback?: () => any) => () => {
        this.setState((state) => ({
            ...state,
            [tableType]: {
                ...state[tableType],
                selectedIds: new Set([...state[tableType].employees.map(e => e.id)]),
                isCheckSelectAll: true
            }
        }), callback);
    }

    private onUnselectAll = (tableType: TableType, callback?: () => any) => () => {
        this.setState((state) => ({
            ...state,
            [tableType]: {
                ...state[tableType],
                selectedIds: new Set(),
                isCheckSelectAll: false
            }
        }), callback);
    }

    private bulkMoveEmployees = (tableType: TableType) => () => {
        const employeeIds = [...this.state[tableType].selectedIds];

        const employees = [...this.state[tableType].employees.filter(e => employeeIds.includes(e.id))];

        if (tableType === TableType.selected) {
            this.bulkUnselectEmployees(employees);
        } else {
            this.bulkSelectEmployees(employees);
        }

        // reset selecting
        this.onUnselectAll(tableType)();
    }

    /**
     * Move employee between tables
     * @param employee
     * @param source
     * @param target
     */
    private moveEmployeeInState(employee: EmployeeInterfaces.Employee, source: TableType, target: TableType) {
        const sourceEmployees = this.state[source].employees;
        const targetEmployees = this.state[target].employees;

        const index = sourceEmployees.findIndex(e => e.id === employee.id);
        if (index !== -1) {
            sourceEmployees.splice(index, 1);

            if (this.state.employeeStatus === employee.status) {
                targetEmployees.push(employee);
            }
        }

        this.setState(state => ({
            ...state,
            [source]: {
                ...state[source],
                employees: sourceEmployees,
            },
            [target]: {
                ...state[target],
                employees: targetEmployees,
            },
            statisticByStatuses: {
                ...state.statisticByStatuses,
                [employee.status]: state.statisticByStatuses[employee.status] + (target === TableType.selected ?  -1 : 1)
            }
        }));
    }
}

const mapStateToProps = (state: RootState) => ({
    mode: selectors.getMode(state),
    currentLegalEntityId: globalSelectors.getCurrentLegalEntityId(state),
});

const mapDispatchToProps = {
    startLoading,
    stopLoading,
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectEmployees);

