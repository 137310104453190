import React, {PureComponent} from 'react';
import {intl} from '@global';
import {connect} from 'react-redux';
import styled from 'styled-components';

import {constants, stylingVariables} from '@global';
import {ApiObject, EmployeeInterfaces} from '@api';
import {Icon} from '@common';

import {deleteDependent} from '../redux';

import Modal from './Modal';

const DeleteBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  background-color: transparent;
  box-shadow: none;
  outline: none;
  padding: 0;
  span {
    margin-left: 5px;
    font-size: ${stylingVariables.fontSize.mediumLarge};
    font-weight: ${stylingVariables.fontWeight.semibold};
  }
`;

interface IProps {
    entityId: string;
    employee: EmployeeInterfaces.Employee;
    values: ApiObject.EntityValues;
    deleteDependent: (employeeId: number, entityId: string) => Promise<boolean>;
    onAfterAction?: () => void;
}

interface IState {
    isModalOpen: boolean;
}

export class Delete extends PureComponent<IProps, IState> {
    public constructor(props: IProps) {
        super(props);

        this.state = {
            isModalOpen: false,
        };
    }

    render() {
        return (
            <>
                <DeleteBtn onClick={this.open}>
                    <Icon type={constants.IconTypes.TRASH}
                          width={20}
                          height={20}
                          fillColor={stylingVariables.colorPalette.orange}
                    />

                    {intl.get('delete_dependent')}
                </DeleteBtn>

                {
                    this.state.isModalOpen &&
                    <Modal
                        onClickDelete={this.delete}
                        onClickCancel={this.close}
                    />
                }
            </>
        );
    }

    public delete = async() => {
        const isOk: boolean = await this.props.deleteDependent(this.props.employee.id, this.props.entityId);
        if (isOk) {
            this.close();
        }

        if (this.props.onAfterAction) {
            this.props.onAfterAction();
        }
    }

    public open = () => {
        this.setState({isModalOpen: true});
    }

    public close = () => {
        this.setState({isModalOpen: false});
    }
}

const mapDispatchToProps = {
    deleteDependent,
};

// @ts-ignore
export default connect(null, mapDispatchToProps)(Delete);
