import React, {PureComponent, ChangeEvent} from 'react';
import {intl} from '@global';
import Validator from 'validatorjs';

import {Modal, TextArea} from '@common';

import Emails from './Emails';
import WelcomeMessageFooter from './WelcomeMessageFooter';
import {Invitation} from './SendEssInvitation';

interface SendInvitationModalProps {
    forEmployeeIds: Set<number>;

    defaultWelcomeMessage: string;
    defaultInvitations: Map<number, Invitation>;

    onClickCloseModal: () => void;
    sendEssInvitation: (payload: any) => void;
}

interface SendInvitationModalState {
    welcomeMessage: string;
    invitations: Map<number, Invitation>;
}

export default class InvitationModal extends PureComponent<SendInvitationModalProps, SendInvitationModalState> {
    private readonly validationRules = {
        'invitations.*.loginUsername': 'required|email',
        'invitations.*.terminationStatus': 'not_in:active'
    };

    private readonly validationMessages = {
        required: 'Email address can not be empty',
        email: 'Please, enter correct email address',
        not_in: 'Cannot send an invitation to a terminated employee'
    };

    public constructor(props: SendInvitationModalProps) {
        super(props);

        this.state = {
            welcomeMessage: props.defaultWelcomeMessage,
            invitations: props.defaultInvitations,
        };
    }

    render() {
        return (
            <Modal.CenterModal onClickClose={this.props.onClickCloseModal}>
                <Modal.StyledHeader>
                    <h4>{intl.get('send_ess_invitation')}</h4>
                </Modal.StyledHeader>

                <Modal.OverflowableContent padding={'20px 40px'}>
                    <Emails onChangeEmail={this.setEmail} invitations={this.state.invitations}/>

                    <TextArea
                        label={intl.get('welcome_message')}
                        defaultValue={this.state.welcomeMessage}
                        placeholder={intl.get('enter_welcome_message')}
                        onChange={this.setWelcomeMessage}
                    />
                    <WelcomeMessageFooter/>
                </Modal.OverflowableContent>

                <Modal.StyledActionBar>
                    <Modal.StyledCancelButton onClick={this.props.onClickCloseModal}>
                        {intl.get('cancel')}
                    </Modal.StyledCancelButton>

                    <Modal.StyledOkButton onClick={this.send}>
                        {intl.get('send')}
                    </Modal.StyledOkButton>
                </Modal.StyledActionBar>
            </Modal.CenterModal>
        );
    }

    private send = () => {
        const invitations = toArray(this.state.invitations);
        const validator = new Validator({invitations}, this.validationRules, this.validationMessages);

        if (validator.passes()) {
            this.props.onClickCloseModal();

            this.props.sendEssInvitation({welcomeMessage: this.state.welcomeMessage, employees: invitations});
        }

        if (validator.fails()) {
            this.setState({ invitations: attachErrorMessages(invitations, validator) });
        }
    }

    private setEmail = (id: number, email: string) => {
        const current = this.state.invitations.get(id);
        if (current) {
            const invitations = new Map(this.state.invitations);
            invitations.set(id, {...current, loginUsername: email});

            this.setState({invitations});
        }
    }

    private setWelcomeMessage = (event: ChangeEvent<HTMLTextAreaElement>) => {
        this.setState({
            welcomeMessage: event.target.value,
        });
    }
}

function getErrors(validator: any, index: number): any {
    const loginUsername =
        validator.errors.has(`invitations.${index}.loginUsername`)
            ? validator.errors.get(`invitations.${index}.loginUsername`).join(',')
            : null

    const terminated =
        validator.errors.has(`invitations.${index}.terminationStatus`)
            ? validator.errors.get(`invitations.${index}.terminationStatus`).join(',')
            : null

    let output = ''

    if (loginUsername) {
        output = loginUsername + '. '
    }

    if (terminated) {
        output = output + terminated
    }

    return output
}

function attachErrorMessages(invitations: Invitation[], validator: any): Map<number, Invitation> {
    return new Map(invitations.map((invitation, index) => [
        invitation.id,
        {
            ...invitation,
            validationError: getErrors(validator, index),
        },
    ]));
}

function toArray(invitations: Map<number, Invitation>): Invitation[] {
    return Array.from(invitations.values());
}
