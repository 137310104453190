import {ApiObject} from '@api';
import {createSelector} from 'reselect';
import {camelCase} from 'lodash';

import {constants, interfaces, intl} from '@global';

import {RootState} from '../../rootReducer';
import {getFieldGroups, getEmployeeGroups} from './selectors';

export const getColumnSettings = (state:RootState) => state.employees.columnSettings;
export const isColumnsSettingsModalOpen = createSelector(
    [getColumnSettings],
    settings => settings.isModalOpen,
);
export const getAvailableColumns = createSelector(
    [getFieldGroups, getEmployeeGroups],
    (fieldGroups: ApiObject.FieldGroup[], employeeGroups: interfaces.EmployeeGroups): interfaces.EmployeeColumn[] => {
        const fields = prepareColumnsFromFieldGroups(fieldGroups, []);

        const columns = [];
        for (const key of constants.EmployeeListingFields) {
            let name = key;
            if (key.includes('.')) {
                name = key.split('.').reverse()[0];
            }

            const field = fields.find(x => x.code === name);
            if (field) {
                columns.push({...field, code: key});
            }
        }

        columns.push(
            {
                code: 'employeeGroupId',
                label: 'Pay group',
                type: 'dropdown',
                options: [
                    ({key: '', label: intl.get('choose')} as ApiObject.SelectOption),
                    ...(Object.keys(employeeGroups)
                        .map(key => ({key: `${key}`, label: employeeGroups[key].name} as ApiObject.SelectOption))),
                ],
            },
            {
                code: 'lastWorkingDate',
                label: 'Termination date',
                type: 'date',
            },
        );

        return columns;
    },
);
export const getSelectedColumns = createSelector(
    [getAvailableColumns, getColumnSettings],
    (columns, settings): interfaces.EmployeeColumn[] => {
        const selectedColumns = [];
        for (const key of settings.selectedColumns) {
            const column = columns.find(c => c.code === key);
            if (column) {
                selectedColumns.push(column);
            }
        }

        return selectedColumns;
    },
);
export const getUnselectedColumns = createSelector(
    [getAvailableColumns, getColumnSettings],
    (columns, settings): interfaces.EmployeeColumn[] => columns.filter(c => !settings.selectedColumns.includes(c.code)),
);

const prepareColumnsFromFieldGroups = (fieldObjects: ApiObject.FieldGroup[], presetFields: interfaces.EmployeeColumn[]):  interfaces.EmployeeColumn[] => {
    const allFields = [...presetFields];

    for (const fieldObject of fieldObjects) {
        for (const field of fieldObject.fields) {
            let type = field.type;
            // set type if it necessary
            if (type !== ApiObject.FieldType.dropdown && field.options && field.options.length > 0) {
                type = ApiObject.FieldType.dropdown;
            }

            allFields.push(
                {
                    code: camelCase(field.code.split('_').join(' ')),
                    label: field.label,
                    type,
                    options: field.options || [],
                },
            );
        }
        if (fieldObject.subgroups.length) {
            prepareColumnsFromFieldGroups(fieldObject.subgroups, allFields);
        }
    }

    return [...allFields];
};
