import {ApiObject} from '@api';
import {stylingVariables, intl, constants, interfaces} from '@global';
import React, {PureComponent} from 'react';
import {
    SortableContainer,
    SortableElement
} from 'react-sortable-hoc';
import arrayMove from 'array-move';

import {Functions} from '@library';
import IconPopover from '../IconPopover';
import {Icon, Actions as CommonActions} from '../index';
import Group from './Group';
import {
    Wrapper,
    GroupContainer,
    BuilderHeading,
    BuilderHeadingLabel,
    BuilderHeadingQuickAdding,
    QuickAddingIcon,
    DropdownActionsWrapper, Groups
} from './Styles';


export interface IProps {
    readOnly: boolean;
    listFields: ApiObject.SelectOption[];
    builderName: string;
    schema: interfaces.IGroupFieldSchema;
    quickAdds: interfaces.IGroupFieldQuickAdd[];
    noteMessage: string;
    onUpdate: (schema: interfaces.IGroupFieldSchema) => any;
    invalidSchemaKeys: string[];
    renderBefore?: React.ReactNode;
}

interface IState {
    rawSchema: interfaces.IGroupFieldSchema;
    schema: interfaces.IGroupFieldSchema;

    selectedFieldKeys: string[];
}

const SortableItem = SortableElement((props: any) => (
    <GroupContainer key={props.key}>
        <Group {...props} />
    </GroupContainer>
));

const Sortable = SortableContainer(({readOnly, children}: {readOnly: boolean, children: any}) => {
    return <Groups readOnly={readOnly}>{children}</Groups>;
});

export default class Builder extends PureComponent<IProps, IState> {
    static defaultProps = {
        schema: {
            groups: []
        },
        invalidSchemaKeys: [],
        quickAdds: [],
        listFields: [],
        readOnly: false,
        noteMessage: '',
    }

    constructor(props: IProps) {
        super(props);

        this.state = {
            schema: props.schema,
            rawSchema: props.schema,
            selectedFieldKeys: Builder.getSelectedFieldKeys(props.schema),
        }

    }

    componentDidMount(){
        if(this.state.schema.groups.length === 0) this.addGroup();
    }

    static getDerivedStateFromProps(nextProps: IProps, prevState: IState) {
        if (JSON.stringify(nextProps.schema) !== JSON.stringify(prevState.rawSchema)) {
            return {
                schema: nextProps.schema,
                rawSchema: nextProps.schema,
                selectedFieldKeys: Builder.getSelectedFieldKeys(nextProps.schema)
            }
        }

        return null;
    }

    public static getSelectedFieldKeys(schema: interfaces.IGroupFieldSchema) {
        const keys: string[] = [];
        schema.groups.forEach(group => {
            group.fields.forEach(field => {
                keys.push(field.key);
            })
        })

        return keys;
    }

    regenerateGroupsUniqueKeys = (groups: interfaces.IGroupFieldBuilderGroup[]) => {
        return groups.map(group => ({
            ...group,
            uniqueKey: Functions.uniqueID(),
            fields: [...group.fields.map(field => ({...field, uniqueKey: Functions.uniqueID()}))]
        }));
    }

    addGroup = () => {
        const newGroup = {
            label: 'General Group',
            fields: [{key: '', label: ''}]
        };

        this.updateGroups([
            ...this.state.schema.groups,
            this.regenerateGroupsUniqueKeys([newGroup])[0]
        ]);
    }

    removeGroup = (groupIndex: number) => {
        const groups = [...this.state.schema.groups];
        groups.splice(groupIndex, 1);
        this.updateGroups(groups);
    }

    updateGroups = (groups: interfaces.IGroupFieldBuilderGroup[], regenerateSelectedFields: boolean = true) => {
        const updatedSchema: interfaces.IGroupFieldSchema = {
            ...this.state.schema,
            groups
        };

        this.setState({
            schema: updatedSchema,
            selectedFieldKeys: regenerateSelectedFields ? Builder.getSelectedFieldKeys(updatedSchema) : this.state.selectedFieldKeys
        }, () => {
            this.props.onUpdate(this.state.schema);
        });
    }

    updateGroup = (groupIndex: number) => (group: interfaces.IGroupFieldBuilderGroup) => {
        const groups = [...this.state.schema.groups];
        groups.splice(groupIndex, 1, group);
        this.updateGroups(groups);
    }

    onSortEnd = ({oldIndex, newIndex}: {oldIndex: number, newIndex: number}) => {
        this.updateGroups(arrayMove([...this.state.schema.groups], oldIndex, newIndex), false);
    };

    isAllowedQuickAdd = (label: string): boolean => {
        let quickAdd = this.props.quickAdds.find(i => i.label === label) as interfaces.IGroupFieldQuickAdd;
        return quickAdd.groups.some(group => group.fields.some(field => !this.state.selectedFieldKeys.includes(field.key)));
    }

    static columnKey(key: string) {
        if (!key) {
            return key
        }
        // This suffix can be added in HRB side, we clear for comparing, but in results we will use key from columns
        ['::key', '::orLabel', '::orAndLabel'].forEach(removeKey => {
            key = key.replace(removeKey, '')
        })
        return key
    }

    render() {
        // If at least 1 field selected but not represented in original list fields
        const hasWarningFields = this.state.selectedFieldKeys.some(selectedFieldKey => {
            return this.props.listFields
              .findIndex(field => Builder.columnKey(field.key) === Builder.columnKey(selectedFieldKey)) === -1
        })
        const {schema} = this.state;
        return <Wrapper className={'builder'} readOnly={this.props.readOnly}>
            <BuilderHeading>
                <BuilderHeadingLabel>
                    {hasWarningFields &&
                      <IconPopover
                        className="icon-popover-warning-left"
                        type={'warning'}
                        message={intl.get('wrong_fields_selected_please_review')}
                        iconSize={14} />
                    }
                    {this.props.builderName}
                    {this.props.noteMessage &&
                        <IconPopover type={'info'} message={this.props.noteMessage} iconSize={14} />
                    }
                </BuilderHeadingLabel>
                <BuilderHeadingQuickAdding>
                    <QuickAdding actions={
                        this.props.quickAdds.map((item) => ({
                            label: item.label,
                            isDisabled: !this.isAllowedQuickAdd(item.label),
                            callback: () => {
                                // Filter groups-fields with already selected fields in other groups
                                let groups = [...item.groups.map(group => ({...group, fields: group.fields.map(field => ({...field}))}))];
                                groups.forEach(group => {
                                    group.fields = group.fields.filter(field => !this.state.selectedFieldKeys.includes(field.key));
                                })

                                groups.filter(group => group.fields.length > 0);

                                const updatedGroup = {...this.state.schema.groups[0]};
                                updatedGroup.fields = [...updatedGroup.fields, ...groups[0].fields.map(field => ({...field, uniqueKey: Functions.uniqueID()}))];


                                this.updateGroup(0)(updatedGroup);
                            }
                        }))
                    }/>
                </BuilderHeadingQuickAdding>
            </BuilderHeading>
            {this.props.renderBefore}
            {this.props.listFields.length > 0 &&
                <>
                    <Sortable onSortEnd={this.onSortEnd} useDragHandle readOnly={this.props.readOnly}>
                        {schema.groups.map((group, index) =>
                            <SortableItem index={index}
                                          key={group.uniqueKey}
                                          hasError={group.uniqueKey ? this.props.invalidSchemaKeys.includes(group.uniqueKey) : false}
                                          invalidSchemaKeys={this.props.invalidSchemaKeys}
                                          group={{...group}}
                                          readOnly={this.props.readOnly}
                                          onUpdateGroup={this.updateGroup(index)}
                                          onRemove={() => this.removeGroup(index)}
                                          listFields={this.props.listFields}
                                          selectedFieldKeys={this.state.selectedFieldKeys}
                            />
                        )}
                    </Sortable>
                </>
            }
        </Wrapper>;
    }
}

interface IQuickAddingProps {
    actions: {
        isDisabled: boolean;
        label: string;
        callback: () => any;
    }[]
}
interface IQuickAddingState {
    isOpened: boolean;
}

class QuickAdding extends PureComponent<IQuickAddingProps, IQuickAddingState> {
    constructor(props: IQuickAddingProps) {
        super(props);

        this.state = {
            isOpened: false
        }
    }

    render() {
        if (this.props.actions.length === 0) {
            return null;
        }

        return <DropdownActionsWrapper>
            <CommonActions.DropdownActions
                label={intl.get('quick_adding')}
                onIconRender={({isOpened}: {isOpened: boolean}) =>
                    <QuickAddingIcon isOpened={isOpened}>
                        <Icon type={constants.IconTypes.BOLD_ADD}
                              width={8}
                              height={8}
                              fillColor={stylingVariables.colorPalette.white}
                        />
                    </QuickAddingIcon>
                }
            >
                {this.props.actions.map((action, index) =>
                    <CommonActions.Action
                        isDisabled={action.isDisabled}
                        key={index}
                        style={{width: '100%', justifyContent: 'flex-end'}}
                        onClick={() => action.callback()}
                    >
                        {action.label}
                    </CommonActions.Action>
                )}
            </CommonActions.DropdownActions>
        </DropdownActionsWrapper>;
    }
}
