import React, { PureComponent, Fragment } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { intl } from "@global";

import { EmployeeInterfaces, ApiObject, PayrollInterfaces } from "@api";
import { Can, Layout, Actions, Switch } from "@common";
import { DomHelper } from "@library";
import { constants, stylingVariables } from "@global";

import Activate from "../../common/Employee/Activate";

import { activate } from "../redux";
import { RootState } from "../../../rootReducer";
import * as globalSelectors from "../../selectors";

const StyledWrapper = styled.div`
  position: relative;
`;

const StyledHoveredLink = styled(Actions.Link)`
  padding: 5px 20px;
  border-radius: 15px;
  &:hover {
    color: ${stylingVariables.colorPalette.green};
    background-color: ${stylingVariables.colorPalette.lightGreen};
  }
`;

const StyledStatusEditLink = styled.div`
  font-size: ${stylingVariables.fontSize.default};
  font-weight: ${stylingVariables.fontWeight.regular};
`;

interface IProps {
  employee: EmployeeInterfaces.Employee;
  isLoading: boolean;
  currentPeriod: PayrollInterfaces.PayrollPeriod | null;

  activate: (id: number) => any;
}

interface IState {
  forEmployeeIds: Set<number>;
  isOpen: boolean;
  hiredAfterPeriodEnd: boolean | false;
}

class EditStatus extends PureComponent<IProps, IState> {
  private wrapperRef: any;
  public constructor(props: IProps) {
    super(props);

    this.state = {
      forEmployeeIds: new Set([props.employee.id]),
      isOpen: false,
      hiredAfterPeriodEnd: false,
    };
  }

  public componentDidMount() {
    document.addEventListener("mousedown", this.closeWhenClickOutside);

    const hired = this.props.employee?.hireDate;
    const periodEndDate = this.props.currentPeriod?.endDate;
    if (hired && periodEndDate) {
      const hiredAfterPeriodEnd = new Date(hired) > new Date(periodEndDate);
      this.setState({ hiredAfterPeriodEnd });
    }
  }

  public componentWillUnmount() {
    document.removeEventListener("mousedown", this.closeWhenClickOutside);
  }

  public render() {
    const { hiredAfterPeriodEnd } = this.state;
    const isStatusInactive =
      this.props.employee.status === ApiObject.Status.inactive ||
      this.props.employee.status === ApiObject.Status.draft;
    const { disableEdit, cuttOffs } = this.getMasterDataEditAllowed(
      this.props.employee?.cuttOffs,
      hiredAfterPeriodEnd,
      isStatusInactive
    );
    const isEditDisabled =
      disableEdit ||  
      this.props.employee.status !== ApiObject.Status.draft
    return (
      <StyledWrapper ref={(node: any) => (this.wrapperRef = node)}>
        <Can
          disabled={isEditDisabled}
          cuttOffs={cuttOffs}
          run={constants.PermissionCode.EMPLOYEE_CHANGE_STATUS}
        >
          <StyledHoveredLink onClick={this.open}>
            <StyledStatusEditLink>{intl.get("edit")}</StyledStatusEditLink>
          </StyledHoveredLink>
        </Can>
        {this.state.isOpen && (
          <Layout.Popover>
            <Switch
              current={this.props.employee.status}
              options={
                new Map([
                  [
                    ApiObject.Status.draft,
                    <Fragment>
                      {this.renderActivate(hiredAfterPeriodEnd)}
                    </Fragment>,
                  ],
                ])
              }
            />
          </Layout.Popover>
        )}
      </StyledWrapper>
    );
  }

  private getMasterDataEditAllowed = (
    cuttOffs: any,
    hiredAfterPeriodEnd: any,
    isStatusInactive: boolean
  ) => {
    if (hiredAfterPeriodEnd && cuttOffs) {
      return {
        cuttOffs: { masterdataEditAllowed: hiredAfterPeriodEnd },
        disableEdit: !isStatusInactive && !cuttOffs.masterdataEditAllowed,
      };
    }
    return { cuttOffs: cuttOffs, disableEdit: false };
  };

  private renderActivate = (hiredAfterPeriodEnd: boolean) => {
    return (
      <Activate
        forEmployeeIds={this.state.forEmployeeIds}
        isLoading={this.props.isLoading}
        onClickActivate={this.activate}
        cuttOffs={this.props.employee?.cuttOffs}
        hiredAfterPeriodEnd={hiredAfterPeriodEnd}
      />
    );
  };

  private activate = () => {
    this.props.activate(this.props.employee.id);

    this.close();
  };

  private open = () => this.setState({ isOpen: true });
  private close = () => this.setState({ isOpen: false });
  public closeWhenClickOutside = (event: any) => {
    if (!this.wrapperRef || this.wrapperRef.contains(event.target)) {
      return;
    }

    if (DomHelper.hasSomeParentTheClass(event.target, "modal")) {
      return;
    }

    this.close();
  };
}

const mapStateToProps = (state: RootState) => ({
  isLoading: globalSelectors.isLoading(state),
  currentPeriod: globalSelectors.getCurrentPayrollPeriod(state),
});

const mapDispatchToProps = {
  activate,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditStatus);
