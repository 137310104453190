import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {Column} from 'react-virtualized';
import {intl} from '@global';

import {
    defaultInfiniteTableCellDataGetter,
    Actions as CommonActions,
    Can,
} from '@common';
import {LeaveInterfaces} from '@api';
import {RouteHelper} from '@library';
import {constants} from '@global';

import {RootState} from '../../../../../rootReducer';
import ViewAction from '../../../../common/Leave/Transaction/Actions/ViewAction';
import {openLeaveTransactionModal} from '../../../redux';

interface IProps {
    cellData: any;
    openLeaveTransactionModal: (modalState: LeaveInterfaces.TransactionModal) => void;
}

interface IState {
    employeeIds: Set<number>;
}

class Cell extends PureComponent<IProps, IState> {
    public constructor(props: IProps) {
        super(props);

        this.state = {
            employeeIds: new Set([props.cellData.id]),
        };
    }

    public render() {
        return (
            <div onClick={(e: any) => e.stopPropagation()}>
                <CommonActions.MenuActions>
                    <Can run={constants.PermissionCode.EMPLOYEE}>
                        <ViewAction
                            onOpen={() => this.props.openLeaveTransactionModal({
                                employee: this.props.cellData.employee,
                                transactions: this.props.cellData.transactions,
                            })}
                        />
                    </Can>
                    <CommonActions.Action
                        onClick={(event: any) => this.redirectToEmployeePage(event)}>
                        {intl.get('view_employee_card')}
                    </CommonActions.Action>
                </CommonActions.MenuActions>
            </div>
        );
    }

    private redirectToEmployeePage(event: any) {
        event.stopPropagation();
        RouteHelper.goToEmployeePage(this.props.cellData.employee.id, constants.DEFAULT_FIELD_GROUP_LEAVE);
    }
}

const mapStateToProps = (state: RootState, ownProps: any) => ({
});

const mapDispatchToProps = {
    openLeaveTransactionModal,
};

const ConnectedCell = connect(mapStateToProps, mapDispatchToProps)(Cell);

const Actions = () => (
    <Column
        dataKey='actions'
        width={80}
        cellDataGetter={defaultInfiniteTableCellDataGetter}
        cellRenderer={({cellData}) => <ConnectedCell cellData={cellData}/>}
    />
);

export default Actions;
