export {stylingVariablesGlobal as hrb} from 'hrb-common';

export const fontWeight = {
    light: 300,
    regular: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
    heavy: 800,
    black: 900,
};

export const fontSize = {
    small: '10px',
    mediumSmall: '11px',
    medium: '12px',
    mediumLarge: '13px',
    default: '14px',
    large: '15px',
    largeMedium: '16px',
    largest: '17px',
		big: '20px'
};

export const fontFamily = {
    lato: "Lato, 'Helvetica Neue', Helvetica, Arial, sans-serif",
    roboto: "Roboto, 'Helvetica Neue', Helvetica, Arial, sans-serif",
    sanFrancisco: "'SanFrancisco', serif",
};

export const colorPalette = {
    primary: '#ff5f1f',
    primaryHover: '#fee5db',
    secondary: '#001738',
    secondaryHover: '#303d63',
    darkest: '#001738',
    dark: '#1D2D41',
    red: '#EE1B08',
    darkRed: '#B4160A',
    yellow: '#EAC435',
    orange: '#FC6541',
    darkOrange: '#E35D3B',
    lightOrange: '#F58A71',
    gray: '#EFF0F2',
    green: '#26AB9E',
    darkGreen: '#1B8379',
    deepGreen: '#209292',
    brightGreen: '#33C15D',
    mediumGreen: '#7CCCC4',
    lightGreen: '#D3EEEC',
    lightGray: '#F8F8F8',
    lightestGray: "#E6E6E6",
    grayish: '#97A0AC',
    deepGray: '#5E6D82',
    slateGray: '#657388',
    darkGray: '#E3E4E6',
    darkerGray: '#D9D9D9',
    darkestGray: '#657387',
    white: '#FFFFFF',
    violet: '#3535EA',
    brightBlue: '#BAD8F2',
    blue: '#0471FF',
    royalblue: '#1E4FFF',
    goldenrod: '#EAC435',
};

export const baseStyles = {
    fontSize: {
        primary: fontSize.mediumSmall,
        secondary: fontSize.medium,
    },
    fontWeight: {
        primary: fontWeight.regular,
        secondary: fontWeight.semibold,
    },
    fontFamily: {
        primary: fontFamily.sanFrancisco,
        secondary: fontFamily.lato,
    },
};

export const layout = {
    height: '60px',
    menuWidth: '250px',
    menuCollapsedWidth: '110px',
    inputBorderRadius: '3px',
    borderRadius: '5px',
    cardBorderRadius: '8px',
    boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.26)',
    boxShadowLight: '0px 6px 16px #0000001A',
    gentModalBorder: '40px 0 8px 8px'
};
