import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';

import {constants, stylingVariables} from '@global';
import {ApiObject, EmployeeInterfaces} from '@api';

import {RootState} from '../../../rootReducer';
import * as selectors from '../selectors';

import Content from './Content';
import {RouteComponentProps, withRouter} from 'react-router';
import {NavLink} from 'react-router-dom';

const StyledTabsWrapper = styled.div<{whiteBackground: boolean}>`
  width: calc(100% - 385px);
  flex-grow: 3;
  margin: 0 20px;
  border-radius: ${stylingVariables.layout.cardBorderRadius};
  ${props => props.whiteBackground ? `background: ${stylingVariables.colorPalette.white};` : ''};
  overflow: visible;
  box-shadow: ${stylingVariables.layout.boxShadowLight};
`;

const StyledTabs = styled.div`
  display: flex;
  flex-wrap: nowrap;
  padding: 0 70px;
  height: 60px;
  background: ${stylingVariables.colorPalette.lightGray};
  border-top-left-radius: ${stylingVariables.layout.cardBorderRadius};
  border-top-right-radius: ${stylingVariables.layout.cardBorderRadius};
`;

const StyledTabHeader = styled(NavLink)`
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding: 15px;
    display: flex;
    align-items: flex-end;
    cursor: pointer;
    font-weight: ${stylingVariables.fontWeight.semibold};
    font-size: ${stylingVariables.fontSize.default};
    text-decoration: none;
    color: ${stylingVariables.colorPalette.darkest};

    &.active {
        color: ${stylingVariables.colorPalette.orange};
        border-bottom: 2px solid ${stylingVariables.colorPalette.orange};
    }

    &.disabled {
        cursor: not-allowed;
        pointer-events: none;
        color: ${stylingVariables.colorPalette.darkerGray};
    }
`;

interface IProps extends RouteComponentProps<any> {
    employee: EmployeeInterfaces.Employee;
    fields: ApiObject.Fields;
    fieldGroups: ApiObject.FieldGroup[];

    isCreateMode: boolean;
    tabs: {
        code: string;
        name: string;
    }[];
}

class Tabs extends PureComponent<IProps> {
    public render() {
        const routeParts = this.props.location.pathname.split('/');
        const isLeaveTab = routeParts.length > 3 && routeParts[3] === constants.DEFAULT_FIELD_GROUP_LEAVE;
        const isSalaryTab = routeParts.length > 3 && routeParts[3] === constants.DEFAULT_FIELD_GROUP_SALARY_NEW;
        const isDocumentsTab = routeParts.length > 3 && routeParts[3] === constants.DEFAULT_FIELD_GROUP_DOCUMENTS;
        const isTablessView = routeParts.length === 3 && routeParts[1] === 'employees';
        return (
            <StyledTabsWrapper whiteBackground={!isLeaveTab && !isSalaryTab}>
                <StyledTabs>
                    {
                        this.props.tabs.map((tab, i) => {
                            const code = tab.code.replace('wizard_step:', '');
                            return (
                                <StyledTabHeader
                                    key={code} to={`${this.props.match.url}/${code}`}
                                    className={
                                        this.props.isCreateMode
                                            ? (i === 0 ? 'active' : 'disabled')
                                            : (i === 0 && isTablessView ? 'active' : '')}
                                    activeClassName={'active'}
                                >
                                    {tab.name}
                                </StyledTabHeader>
                            );
                        })
                    }
                </StyledTabs>

                <Content
                    employee={this.props.employee}
                    fields={this.props.fields}
                    fieldGroups={this.props.fieldGroups}
                    tabs={this.props.tabs}
                    ignoreDefaultSpacing={isLeaveTab || isSalaryTab || isDocumentsTab}
                    isCreateMode={this.props.isCreateMode}
                />
            </StyledTabsWrapper>
        );
    }
}

const mapStateToProps = (state: RootState, ownProps: any) => ({
    tabs: selectors.getTabs(state, ownProps),
});

const mapDispatchToProps = {
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Tabs));
