import React from 'react';
import {Column} from 'react-virtualized';

import {DateHelper} from '@library';
import {defaultInfiniteTableCellDataGetter} from '@common';

interface DateProps {
    dataKey: string;
    label: string;
}

const Date: React.FC<DateProps> = ({dataKey, label}: DateProps) => (
    <Column
        label={label}
        dataKey={dataKey}
        width={100}
        flexGrow={1}
        cellDataGetter={defaultInfiniteTableCellDataGetter}
        cellRenderer={({cellData}) => {
            return (
                <span>
                    {DateHelper.convertDateToHumanFormat(cellData[dataKey])}
                </span>
            );
        }}
    />
);

export default Date;
