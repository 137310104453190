import {interfaces, stylingVariables} from '@global';
import React, {PureComponent} from 'react';
import styled from 'styled-components';
import Popover from './Popover';

const Wrapper = styled.div`
  display: flex;
  height: 60px;
  width: 100%;
  background-color: ${stylingVariables.colorPalette.lightGray};
  border-radius: 30px;
  padding: 0 10px;
  align-items: center;
`;

const StepItem = styled.div<{isActive: boolean}>`
  &:not(:last-child) {
    margin-right: 10px;
  }
  display: flex;
  align-items: center;
  justify-content: flex-start;
  user-select: none;
  ${props => props.isActive ? `flex: 1;` : ''};
  transition: all .3s;
`;
const StepIndex = styled.div<{isCompleted: boolean; isActive: boolean}>`
    height: 40px;
    width: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: ${stylingVariables.colorPalette.darkest};
    font-size: ${stylingVariables.fontSize.largeMedium};
    font-weight: ${stylingVariables.fontWeight.semibold};
    background-color: ${stylingVariables.colorPalette.darkerGray};
    position: relative;
    ${props => props.isActive ? `
        background-color: ${stylingVariables.colorPalette.orange};
        color: #fff;
        cursor: default;
    ` : ''};
    
    ${props => props.isCompleted && `
        background-color: ${stylingVariables.colorPalette.brightGreen};
        color: #fff;
    `}
`;
const StepLabel = styled.div<{isActive: boolean}>`
  margin-left: 10px;
  font-size: ${stylingVariables.fontSize.largeMedium};
  font-weight: ${stylingVariables.fontWeight.semibold};
  color: ${stylingVariables.colorPalette.darkest};
  white-space: nowrap;
  ${props => !props.isActive && `
    display: none;
  `};
`;

const CompletedCheckmarkIndex = styled.div`
  color: #000;
  height: 24px;
  width: 24px;
  position: absolute;
  top: -8px;
  right: -8px;
  background-color: ${stylingVariables.colorPalette.lightGray};
  padding: 5px;
  display: flex;
  border-radius: 50%;

  &:before {
    content: '';
    position: absolute;
    height: 14px;
    width: 14px;
    border-radius: 50%;
    color: #fff;
    background-color: ${stylingVariables.colorPalette.brightGreen};
  }
  &:after {
    content: '';
    position: absolute;
    top: 8px;
    left: 8px;
    height: 5px;
    width: 8px;
    border-left: 2px solid #fff;
    border-bottom: 2px solid #fff;
    transform: rotate(-45deg);
  }
`;
export interface IProps {
    steps: interfaces.StepInfo[];
    allowAnyClick: boolean;
}

interface IState {

}

export class BubbleStepNavigation extends PureComponent<IProps, IState> {
    static defaultProps = {
        allowAnyClick: false
    };

    render() {
        if (this.props.steps.length === 0) {
            return null;
        }

        const activeStepIndex = this.props.steps.findIndex((step: interfaces.StepInfo) => step.isActive);

        return (
            <Wrapper>
                {this.props.steps.map((step: interfaces.StepInfo, index: number) =>
                    <StepItem key={step.key}
                              isActive={step.isActive}
                              onClick={() => !this.props.allowAnyClick && index > activeStepIndex ? null : step.onClick(step.key)}>
                        <Popover message={step.label} fixed={true} isDisabled={index === activeStepIndex}>
                            <StepIndex isCompleted={index < activeStepIndex} isActive={index === activeStepIndex}>
                                {index + 1}
                                {index < activeStepIndex ? <CompletedCheckmarkIndex /> : null}
                            </StepIndex>
                        </Popover>
                        <StepLabel isActive={step.isActive}>
                            {step.label}
                        </StepLabel>
                    </StepItem>
                )}
            </Wrapper>
        );
    }

}

export default BubbleStepNavigation;
