import React, {PureComponent} from 'react';
import {intl} from '@global';

import {ApiObject, LeaveInterfaces} from '@api';
import {stylingVariables} from '@global';
import {Dropdown, Layout} from '@common';

interface IProps {
    default?: number;
    defaultName?: string;
    onChange: (leaveTypeId: number | null) => any;
    leaveTypes: LeaveInterfaces.Type[];
    errors?: string[];
}

interface IState {
    list:  ApiObject.SelectOption[];
}

export default class LeaveTypeRow extends PureComponent<IProps, IState> {
    public constructor(props: IProps) {
        super(props);

        this.state = {
            list: props.leaveTypes.map((leaveType: LeaveInterfaces.Type) => {
                return {
                    key: `${leaveType.id}`,
                    label: leaveType.name,
                };
            }),
        };
    }

    public render() {
        return (
            <Layout.Row>
                <Layout.RowLabel
                    percent={20}
                    top={14}
                    color={this.props.errors ? stylingVariables.colorPalette.red : undefined}
                >
                    {intl.get('leave_type')}*
                </Layout.RowLabel>

                <Layout.RowValue percent={70}>
                    {this.renderRowValue()}
                </Layout.RowValue>
            </Layout.Row>
        );
    }

    private renderRowValue() {
        if (this.props.default && this.props.defaultName) {
            return this.props.defaultName;
        }
        return (<Dropdown
            list={this.state.list}
            onChange={this.onChange}
        />);
    }

    private onChange = (leaveTypeId: string): void => this.props.onChange(+leaveTypeId);
}
