import React, {PureComponent} from 'react';
import {connect} from 'react-redux';

import {constants} from '@global';
import {Icon} from '@common';

import {RootState} from '../../rootReducer';
import * as selectors from '../selectors';
import {stylingVariables} from '@global';
import styled from 'styled-components';
import {intl} from '@global';
import {ApiObject} from '@api';

const StyledGroupLetter = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    font-size: 16px;
    margin-top: 2px;
    color: ${stylingVariables.colorPalette.grayish};
    background-color: ${stylingVariables.colorPalette.lightGray};
    border-radius: 50%;
    margin-right: 5px;
`;

const StyledGroupItemsWrapper = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    font-size: ${stylingVariables.fontSize.mediumLarge};
    color: ${stylingVariables.colorPalette.dark};
`;

const GroupWrapper = styled.div<{padding?: string}>`
    display: flex;
    flex: 1;
    flex-direction: row;
    padding: 8px 77px 10px 77px;
    border-bottom: 1px solid ${stylingVariables.colorPalette.gray};
    
    ${props => props.padding && `
        padding: ${props.padding};
    `}
`;

const ShadowBorder = styled.div`
    margin: 10px 0;
    border-bottom: 2px solid ${stylingVariables.colorPalette.lightGreen};
    box-shadow: 0px 3px 6px #00000029;
`;

const EntityLineWrapper = styled.div`
    display: flex;
    flex: 1;
`;

const EntitiesWrapper = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
`;

const ChildrenWrapper = styled.div<{show?: boolean}>`
    margin-top: 10px;
    display: none;
    flex: 1;
    
    ${props => props.show && `
        display: block;
  `}
`;

const NameWrapper = styled.div<{active?: boolean; overflow?: string}>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: left;
    padding-left: 20px;
    width: 100%;
    font-size: ${stylingVariables.fontSize.mediumLarge};
    color: ${stylingVariables.colorPalette.dark};
    transition: all 0.4s ease;
    padding-right: 0;
    overflow: hidden;
    height: 40px;

    ${props => props.overflow && `
        overflow: ${props.overflow};
    `}

    ${props => props.overflow && `
        color: ${stylingVariables.colorPalette.green};
        .icon-wrapper {
            background: ${stylingVariables.colorPalette.lightGreen};
            svg {
                transform: rotateZ(180deg) !important;
            }
        }
    `}

    &:hover {
        cursor: pointer;
        color: ${stylingVariables.colorPalette.green};
        .icon-wrapper {
            background: ${stylingVariables.colorPalette.lightGreen};
            svg {
                transform: rotateZ(180deg) !important;
            }
        }
    }

    svg {
        transition: all 0.6s ease;
    }
`;

const IconWrapper = styled.div`
    width: 40px;
    text-align: center;
    cursor: pointer;
    border-radius: 4px;

    &:hover {
        background: ${stylingVariables.colorPalette.lightGreen};

        svg {
            transform: rotateZ(180deg) !important;
        }
    }
    line-height: 40px;
`;

const EntityLine = styled.div<{usePadding?: boolean}>`
    font-weight: ${stylingVariables.fontWeight.regular};
    line-height: 34px;
    margin: 5px 0;
    border-radius: 17px;
    display: flex;
    justify-content: space-between;
    flex: 1;
    padding-left: 20px;
    cursor: pointer;
    
    ${props => props.usePadding && `
        margin: 5px 0 5px 20px;
    `}

    &:hover {
        span {
            display: block;
        }
        color: ${stylingVariables.colorPalette.green};
        background: ${stylingVariables.colorPalette.lightGray};
    }

    span {
        display: none;
        color: ${stylingVariables.colorPalette.orange};
        margin-right: 10px;
    }
`;

const ButtonActions = styled.div`
    display: flex;
    flex: 1;
    height: 40px;
`;


interface AlphabetEntitiesProps {
    groups: {
        group: string,
        children: any[],
    }[];
    searchQuery: string;
    padding?: string;
    authorizedUser: ApiObject.AuthorizedUser | null;
}

type tplotOptions = {
    [key: string]: boolean
}

interface IState {
    openSelects: tplotOptions;
}

export class AlphabetListEntities extends PureComponent<AlphabetEntitiesProps, IState> {
    public constructor(props: AlphabetEntitiesProps) {
        super(props);

        this.state = {
            openSelects: {},
        };
    }

    private getLegalEntitiesCount(entity: any) {
        return ' (' + entity.legalEntities.length + ')'
    }

    private toggleList(id: string) {
        const openSelects = this.state.openSelects
        openSelects[id] = !openSelects[id]
        this.setState({...openSelects, openSelects})
    }

    public render() {
        return (
            <div>
                {
                    Object.keys(this.props.groups).map((keyName: any) => (
                        <GroupWrapper key={keyName} padding={this.props.padding}>
                            <StyledGroupLetter>{keyName}</StyledGroupLetter>

                            <StyledGroupItemsWrapper>
                                {this.props.groups[keyName].children.map((item: any, idx) => (
                                    <span key={`wrapper-${item.name}`}>
                                        <EntityLineWrapper key={`entity-${item.name}`} >
                                            <EntitiesWrapper>
                                            {item.legalEntities ?
                                                <ButtonActions>
                                                    <NameWrapper active={this.state.openSelects[item.name]}
                                                        className="name-wrapper"
                                                        onClick={() => this.toggleList(item.name)}>

                                                        {item.name
                                                            ? item.name
                                                            : item.parentLegalEntity.name}
                                                        {this.getLegalEntitiesCount(item)}

                                                            <IconWrapper className="icon-wrapper">
                                                                <Icon
                                                                    type={constants.IconTypes.ARROW_DOWN}
                                                                    fillColor={stylingVariables.colorPalette.darkest}
                                                                    width={13}
                                                                    height={9}
                                                                />
                                                            </IconWrapper>
                                                    </NameWrapper>
                                                </ButtonActions>
                                            :
                                                <EntityLine onClick={() => this.selectLegalEntity(item)}>
                                                    {item.name ? item.name : item.parentLegalEntity.name}
                                                    <span>
                                                        {intl.get('select')}
                                                    </span>
                                                </EntityLine>
                                            }
                                            </EntitiesWrapper>
                                        </EntityLineWrapper>
                                        {item.legalEntities ?
                                            <ChildrenWrapper show={this.state.openSelects[item.name]}>
                                                {item.legalEntities.map((entity: any) => (
                                                    <EntityLine
                                                        onClick={() => this.selectLegalEntity(entity)}
                                                        usePadding={true}
                                                        key={`child-${entity.id}`}
                                                    >
                                                        {entity.name}
                                                        <span>
                                                            {intl.get('select')}
                                                        </span>
                                                    </EntityLine>
                                                ))}

                                                <ShadowBorder className="shadow-border"/>
                                            </ChildrenWrapper>
                                        : null}
                                    </span>

                                ))}
                            </StyledGroupItemsWrapper>
                        </GroupWrapper>
                    ))
                }
            </div>
        );
    }

    public selectLegalEntity = async (entity: any) => {
        const id = entity.parentLegalEntity?.id ? entity.parentLegalEntity.id : entity.id
        window.location.replace(`${constants.ROUTE_EMPLOYEES}?legalEntityId=${id}`);
    }
}

const mapStateToProps = (state: RootState, ownProps: any) => ({
    groups: selectors.getEntitySelectorList(state, ownProps),
    searchQuery: selectors.getAlphabetEntitiesSearchQuery(state, ownProps),
    authorizedUser: selectors.getAuthorizedUser(state),
});

export default connect(mapStateToProps)(AlphabetListEntities);
