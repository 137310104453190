import React, {PureComponent} from 'react';
import styled from 'styled-components';
import logo from '../../../../assets/images/hrblizz_logo.png';

import {stylingVariables} from '@global';
import {EnvironmentHelper} from '@library';

const StyledWelcomeMessageFooter = styled.div`
  display: flex;
  flex-direction: column;
  h4 {
    color: ${stylingVariables.colorPalette.green};
    margin-bottom: 15px;
    padding: 0;
  }
  p {
    white-space: pre-wrap;
    line-height: 1.1rem;
    font-weight: ${stylingVariables.fontWeight.regular};
    font-size: ${stylingVariables.fontSize.mediumSmall};
    margin: 5px 0 15px;
  }
  a {
    font-weight: ${stylingVariables.fontWeight.regular};
    font-size: ${stylingVariables.fontSize.mediumSmall};
    color: ${stylingVariables.colorPalette.green};
    margin: 5px 0 15px;
  }
  p {
    a {
      margin-bottom: 0;
      padding: 0 5px;
    }
  }
  img {
    width: 150px;
    align-self: center;
  }
`;

const ClickHereButton = styled.button`
  cursor: pointer;
  background-color: ${stylingVariables.colorPalette.green};
  color: ${stylingVariables.colorPalette.white};
  font-size: ${stylingVariables.fontSize.mediumSmall};
  font-weight: ${stylingVariables.fontWeight.semibold};
  border-radius: 5px;
  padding: 5px 10px;
`;

export default class WelcomeMessageFooter extends PureComponent {
    render() {
        return (
            <StyledWelcomeMessageFooter>
                <p>Please <ClickHereButton>Click Here</ClickHereButton> to create your account</p>

                <h4>What is HR blizz?</h4>

                <p>
                    HR blizz is your new and user-friendly online HR portal for viewing your HR information,
                    accessing payslips, requesting leave, submitting expense reports and transferring files.
                    Please see below HR blizz user manual for more information.
                </p>

                <a href={EnvironmentHelper.getHrbFreshDeskUrl()}>HR Blizz User Manual</a>

                <p>
                    Should you have any questions or feedback,
                    please email us at
                    <a href={`mailto:${EnvironmentHelper.getSupportEmail()}`}>
                        {EnvironmentHelper.getSupportEmail()}
                    </a>
                    or post a support ticket at
                    <a href={EnvironmentHelper.getHrbFreshDeskUrl()} target='_blank' rel='noopener noreferrer'>
                        {EnvironmentHelper.getHrbFreshDeskUrl()}
                    </a>
                </p>

                <p>Sincerely,</p>

                <p>HR blizz team</p>

                <img src={logo} alt='HR Blizz'/>
            </StyledWelcomeMessageFooter>
        );
    }
}
