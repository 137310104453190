import { ApiObject } from '@api'
import React, { PureComponent } from 'react'
import styled from 'styled-components'

import { intl, constants } from '@global'
import Field from './Field'
import { LoaderDots } from './LoaderDots'
import { StyledEditButton, Can } from '@common'

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
`

const LeftSide = styled.div`
  padding-left: 37px;
  flex: 0 0 60%;
  min-width: 690px;
`

const EditWrapper = styled.div`
  position: absolute;
  top: 30px;
  right: 10px;
  width: 300px;
`
interface IProps {
  fields: ApiObject.BankField[]

  onClickEditButton: () => void
  bankDetails: ApiObject.BankDetails
  isLoading: boolean
}

class View extends PureComponent<IProps> {
  private mapField = (field: ApiObject.BankField, returnDisplay: boolean): string => {
    const name: ApiObject.BankField['name'] = field.name
    let output = this.props.bankDetails[name]

    if (output && returnDisplay) {
      if (field.selectionChoices && field.selectionChoices.length > 0) {
        const matchingChoice = field.selectionChoices.find((choice) => {
          return choice.value === output
        })
        if (matchingChoice) {
          output = matchingChoice.label
        }
      }
    }
    return output
  }

  private renderFields() {
    return this.props.fields.map((field: ApiObject.BankField) => {
      return <Field key={field.label} label={field.label} value={this.mapField(field, true)} />
    })
  }

  public render() {
    const accountCreated = Object.keys(this.props.bankDetails).length > 0

    return (
      <Wrapper>
        <LeftSide>
          {this.props.isLoading ? (
            <LoaderDots>
              <div className="loader-dots" />
            </LoaderDots>
          ) : (
            this.props.fields?.length > 0 && this.renderFields()
          )}
        </LeftSide>

        <EditWrapper>
          <Can run={constants.PermissionCode.EMPLOYEE_FIELDS_EDIT}>
            <StyledEditButton onClick={this.props.onClickEditButton}>
              {intl.get(accountCreated ? 'edit' : 'create_hyperwallet_account')}
            </StyledEditButton>
          </Can>
        </EditWrapper>
      </Wrapper>
    )
  }
}

export default View
