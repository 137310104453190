import React, {PureComponent} from 'react';
import {intl} from '@global';

import {stylingVariables} from '@global';
import {Layout, StyledEditableFieldInput} from '@common';

interface IProps {
    onChange: (description: string) => any;
    errors?: string[];
}

export default class DescriptionRow extends PureComponent<IProps> {
    public render() {
        return (
            <Layout.Row>
                <Layout.RowLabel percent={30} color={this.props.errors ? stylingVariables.colorPalette.red : undefined}>
                    {intl.get('description')}
                </Layout.RowLabel>
                <Layout.RowValue percent={70}>
                    <StyledEditableFieldInput style={{width: '100%'}}>
                        <input type={'text'} onChange={(event: any) => this.props.onChange(event.target.value)}/>
                    </StyledEditableFieldInput>
                </Layout.RowValue>
            </Layout.Row>
        );
    }
}
