import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';

import {stylingVariables} from '@global';
import { Checkbox, Actions, StyledCheckbox} from '@common';

import {RootState} from '../../../../../../../rootReducer';
import * as globalSelectors from '../../../../../../selectors';

import BulkActions from './BulkActions';

const StyledIndexColumnHeaderWrapper = styled.div`
  position: absolute;
  z-index: 800;
  user-select: none;
`;

const StyledIndexColumnHeader = styled.div`
  position: relative;
  top: -20px;
  left: -20px;
  display: flex;
  align-items: center;
  padding: 5px 20px;
  z-index: 1000;
  background-color: #F8F8F8;
  border-radius: 20px;

  ${Actions.StyledDropdownIconWrapper} {
    background-color: ${stylingVariables.colorPalette.green};
    width: 25px;
    height: 25px;
  }

  ${Actions.StyledDropdownActionsButton} {
    color: ${stylingVariables.colorPalette.green};
    font-size: ${stylingVariables.fontSize.mediumLarge}
  }

  ${Actions.StyledDropdownActionsContentWrapper} {
    top: 40px;
    right: -30px;
  }

  ${StyledCheckbox} {
    margin-right: 5px;
  }
`;

interface IndexColumnHeaderProps {
    bulkAction: any;
    selectedIds: Set<number>;
    totalCount: number;
    isLoading: boolean;

    onUnselectAll: () => void;
    onSelectAll: () => void;
}

export class SelectedIndex extends PureComponent<IndexColumnHeaderProps> {
    render() {
        if (this.props.selectedIds.size === 0) {
            return <Checkbox isChecked={false} onClick={this.props.onSelectAll}/>;
        }

        return (
            <StyledIndexColumnHeaderWrapper>
                <StyledIndexColumnHeader>
                    <Checkbox onClick={this.props.onUnselectAll}
                        isChecked={true}
                        indeterminate={this.props.selectedIds.size !== this.props.totalCount}
                    />

                    <BulkActions
                        bulkAction={this.props.bulkAction}
                        ids={this.props.selectedIds}
                        totalCount={this.props.totalCount}
                        isLoading={this.props.isLoading}
                    />
                </StyledIndexColumnHeader>
            </StyledIndexColumnHeaderWrapper>
        );
    }
}

const mapStateToProps = (state: RootState) => ({
    isLoading: globalSelectors.isLoading(state),
});

export default connect(mapStateToProps)(SelectedIndex);
