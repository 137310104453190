import { ApiObject } from '@api'
import { interfaces, intl } from '@global'
import React, { PureComponent } from 'react';
import { Column } from 'react-virtualized';
import styled from 'styled-components';

import {defaultInfiniteTableCellDataGetter, InfiniteTableHeader} from '@common'

const StyledCellWrapper = styled.div`
    display: flex;
    flex-direction: row;
`;

interface IProps {
    cellData?: ApiObject.Report;
    label: string;
    width: number;
    filters: interfaces.ListingFilters;
    changeFilter: (params: interfaces.FilterParams) => any;
    orders: interfaces.ListingOrders;
    changeOrder: (column: string) => any;
}

interface ICellProps {
    cellData?: ApiObject.Report;
}

class Cell extends PureComponent<ICellProps> {
    get templateLabel() {
        if (!this.props.cellData?.legalEntityReportType) {
            return '-';
        }

        return intl.get(this.props.cellData.legalEntityReportType)
    }

    public render() {
        if (!this.props.cellData) {
            return null;
        }

        return (
            <StyledCellWrapper>
                {this.templateLabel}
            </StyledCellWrapper>
        );
    }
};

const DataSource: React.FC<IProps> = ({ label, width, filters, changeFilter, orders, changeOrder }: IProps) => (
    <Column
        dataKey={`legalEntityReportType`}
        label={label}
        width={width}
        cellDataGetter={defaultInfiniteTableCellDataGetter}
        cellRenderer={({ cellData }) => <Cell cellData={cellData} />}
        headerRenderer={() => (
          <InfiniteTableHeader.OrderedAndFiltered
            label={label}
            type={ApiObject.FieldType.dropdown}
            dataKey={'legalEntityReportType'}
            filters={filters}
            orders={orders}
            changeOrder={changeOrder}
            changeFilter={changeFilter}
            options={[
                {key: ApiObject.DataSource.payroll, label: intl.get(ApiObject.DataSource.payroll)},
                {key: ApiObject.DataSource.employee, label: intl.get(ApiObject.DataSource.employee)},
            ]}
          />
        )}
    />
);

export default DataSource;
