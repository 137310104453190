import React, {PureComponent} from 'react';
import {Icon, StyledInputWrapper} from '@common';
import {constants, stylingVariables, intl} from '@global';

interface IProps {
    theme?: string;
    placeholder?: string;
    defaultValue?: string;
    autoFocus?: boolean;
    searchValue: string;
    onChange: (value: any) => void;
}

class SearchInputControlled extends PureComponent<IProps> {
    render() {
        return (
            <StyledInputWrapper theme={this.props.theme} iconSize={15}>
                <input type='text'
                       autoFocus={this.props.autoFocus}
                       placeholder={
                            this.props.placeholder && intl.get(this.props.placeholder)
                            ? intl.get(this.props.placeholder)
                            : this.props.placeholder
                        }
                       onChange={this.props.onChange}
                       value={this.props.searchValue}/>

                <Icon type={constants.IconTypes.SEARCH}
                      width={15}
                      height={15}
                      fillColor={stylingVariables.colorPalette.deepGray}
                />
            </StyledInputWrapper>
        );
    }
}

export default SearchInputControlled;
