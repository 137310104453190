import {stylingVariables} from '@global';
import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  height: 100%;
`;

export const Heading = styled.div`
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const HeadingBreadCrumbs = styled.div`
  display: inline-flex;
  align-items: center;
`;
export const Title = styled.div`
	margin-left: 10px;
	font-weight: ${stylingVariables.fontWeight.semibold};
	font-size: ${stylingVariables.fontSize.mediumLarge};
	.page_title {
		font-size: ${stylingVariables.fontSize.largest};
	}
`;
export const StyledTables = styled.div`
    display: flex;
    justify-content: space-between;
    height: calc(100vh - 320px);
    
    margin: 40px 0 0;
    position: relative;
`;
export const StyledAvailableTable = styled.div<{label: string}>`
  position: relative;
  flex-shrink: 0;
  flex-grow: 0;
  flex: 45%;
  
  padding: 15px;
  
  &:after {
    content: '${props => props.label}';
    font-size: ${stylingVariables.fontSize.largest};
    font-weight: ${stylingVariables.fontWeight.semibold};
    position: absolute;
    top: -15px;
    padding: 5px;
    left: 25px;
    margin: 0 15px;
  }
`;
export const StyledSelectedTable = styled.div<{label: string}>`
  position: relative;
  flex-shrink: 0;
  flex-grow: 0;
  flex: 45%;
  
  border: 1.5px dashed ${stylingVariables.colorPalette.green};
  border-radius: 10px;
  padding: 15px;
  
  &:after {
    content: '${props => props.label}';
    font-size: ${stylingVariables.fontSize.largest};
    font-weight: ${stylingVariables.fontWeight.semibold};
    position: absolute;
    top: -15px;
    padding: 5px;
    left: 25px;
    margin: 0 15px;
    background-color: ${stylingVariables.colorPalette.gray};
    
  }
`;

export const Footer = styled.div`
  padding: 0 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  bottom: -20px;
  left: 0;
  right: 0;
  height: 60px;
  border-radius: 10px 10px 0 0;
  box-shadow: ${stylingVariables.layout.boxShadow};
  background-color: #fff;
`;
