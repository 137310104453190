import React, {PureComponent} from 'react';
import styled from 'styled-components';

import {ApiObject} from '@api';
import {BlankButton, ChartersPreview} from '@common';

import * as stylingVariables from '../../../stylingVariables';

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 44px 10px;
  border-bottom: 1px solid ${stylingVariables.colorPalette.gray};

  h4 {
    color: ${stylingVariables.colorPalette.green};
    font-size: ${stylingVariables.fontSize.large};
    font-weight: ${stylingVariables.fontWeight.regular};
  }
`;

const StyledLogo = styled(props => <BlankButton {...props} />)`
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  width: 60px;
  min-width: 60px;
  height: 60px;
  min-height: 60px;
  border-radius: 50%;
  margin-right: 5px;
  padding: 2px;
  border: 1px solid ${stylingVariables.colorPalette.green};
  img {
    width: 54px;
    height: 54px;
    border-radius: 50%;
  }
`;

interface ProfileMenuProps {
    user: ApiObject.AuthorizedUser;
}

export default class DropdownHeader extends PureComponent<ProfileMenuProps> {
    render() {
        return (
            <HeaderWrapper>
                <StyledLogo>
                    {
                        this.props.user.photo
                            ? <img src={this.props.user.photo} alt={'Current User'}/>
                            : <ChartersPreview
                                text={this.props.user.employee.name}
                                width={'54px'}
                                height={'54px'}
                            />
                    }
                </StyledLogo>

                <h4>
                    {this.props.user.employee.name}
                </h4>
            </HeaderWrapper>
        );
    }
}
