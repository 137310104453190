import React, {PureComponent} from 'react';
import {intl} from '@global';
import {Dropdown, MultiMonthField} from '@common'

import {interfaces} from '@global';
import {Layout} from '@common';
import {isMonthlyPeriods} from '../../utils'
import {DateHelper} from '@library'
import {RootState} from '../../../../rootReducer'
import * as reportsSelectors from '../../selectors'
import {connect} from 'react-redux'
import { ApiObject } from '@api';

interface IProps {
  legalEntityPeriods: ApiObject.LegalEntityPeriod[];
  periods: interfaces.Periods;
  allowPeriods: interfaces.Periods;
  errors?: string[];
  multiselect?: boolean;

  onChange: (periods: interfaces.Periods) => void;
}

export class DateRangeRow extends PureComponent<IProps> {
    private renderRowValue() {
      return isMonthlyPeriods(this.props.legalEntityPeriods)
        ? this.renderMonthPicker()
        : this.renderDropdown()
    }

    private renderMonthPicker() {
      return (
        <MultiMonthField
          onChange={this.props.onChange}
          periods={this.props.periods || []}
          allowPeriods={this.props.allowPeriods}
          multiselect={this.props.multiselect}
        />
      );
    }

    private renderDropdown() {
      const keyDelimiter: string = '_'
      const period = this.props.periods.length > 0 ? this.props.periods[0] : null
      const allowPeriods = this.props.allowPeriods.sort((a, b) => a.beginDate < b.beginDate ? 1 : -1)

      const selectedValue = period ? `${period.beginDate}${keyDelimiter}${period.endDate}` : undefined
      const options = allowPeriods.map(p => {
        return {key: `${p.beginDate}${keyDelimiter}${p.endDate}`, label: DateHelper.convertPeriodToHumanFormat(p)}
      })
      const onChange = (key: string) => {
        const [beginDate, endDate]: string[] = key.split(keyDelimiter)
        this.props.onChange([{beginDate, endDate}])
      }
      // Allow only single selecting now
      return <Dropdown
        selectedValue={selectedValue}
        list={options}
        onChange={onChange}
        disabled={options.length === 0}
      />;
    }

    public render() {
        return (
          <Layout.Row hasError={!!this.props.errors}>
            <Layout.RowLabel percent={30}>
                {intl.get('date')} *
            </Layout.RowLabel>

            <Layout.RowValue percent={70}>
                {this.renderRowValue()}
            </Layout.RowValue>
        </Layout.Row>
        );
    }
}

const mapStateToProps = (state: RootState) => ({
  legalEntityPeriods: reportsSelectors.getLegalEntityPeriods(state),
})

export default connect(mapStateToProps)(DateRangeRow)
