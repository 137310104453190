export interface CustomModule {
  description?: string
  icon: string
  moduleCode: string
  title: string
  url: string
}

export interface AuthorizedUser {
  loginUsername: string
  loginPassword: string | null
  sessionToken: string
  tfaToken: string | null
  employee: {
    id: number
    name: string
    referenceCode: string
    roles: {
      role: string
      legalEntity: {
        id: number
        legalName: string
        code: string | null
      }
    }[]
    legalEntity: LegalEntity
    enabledModules: string[]
    customModules: CustomModule[]
  }
  photo?: string
}
export interface Address {
  addressLine1?: string
  addressLine2?: string
  city?: string
  country?: string
  name?: string
  postOfficeBox?: string
  postalCode?: string
  residenceCountry?: string
  state?: string
}

export enum AllocationType {
  percent = 'percent',
  sum = 'sum',
}

export enum PayUnitType {
  amount = 'amount',
  annual = 'annual',
  quarter = 'quarter',
  month = 'month',
  biweek = 'biweek',
  week = 'week',
  day = 'day',
  hour = 'hour',
  unit = 'unit',
  percentage = 'percentage',
  per_annual = 'per_annual',
  per_amount = 'per_amount',
}

export enum ApprovalStatus {
  approved = 'approved',
  draft = 'draft',
  pending = 'pending',
  rejected = 'rejected',
}

export interface AmountWithCurrency {
  amount?: number
  currency?: string
}

export interface Bank {
  code?: string
  country?: string
  name?: string
  swift?: string
}

export interface CostCenter {
  id?: string
  name?: string
  objectCode?: string
  status?: Status
}

type DataImportStatusType = 'parsing' & 'mapping' & 'importing' & 'failed' & 'done'

export interface DataImportStatus {
  status?: DataImportStatusType
}

export interface DecimalSettings {
  decimalPrecision?: number
}

export enum EOSReason {
  resignation = 'resignation',
  retirement = 'retirement',
  termination = 'termination',
  maturity = 'maturity',
}

export enum CalculationType {
  pro_rata = 'pro_rata',
  lump_sum = 'lump_sum',
}

export interface EntityFieldErrorMessage {
  code: string
  entity: string
  field: string
  message: string
}

export interface EntityFieldOrderBy {
  entity: string
  field: string
  order: OrderByOperator
}

export interface EntityFieldQuery {
  entity: string
  field: string
  operator: QueryOperator
  value: any
}

export type Queries = EntityFieldQuery[]
export type GrouperQueries = Queries[]

export interface ErrorMessage {
  code?: string
  message?: string
}

export enum EventType {
  holiday = 'holiday',
  payroll_event = 'payroll_event',
  weekend = 'weekend',
}

export enum ReportSourceType {
  employee_masterdata = 'masterdata',
  payroll_results = 'payroll',
}

export interface Field {
  id: string
  index: number
  allowEditIfFilled: boolean
  defaultMssReportOutputFormat: MssReportOutputFormat | null
  localLabel?: string
  code: string
  defaultValue?: string
  entity: string
  entityId: number
  essPermission?: Permission
  required: boolean
  label: string
  options: SelectOption[]
  ordinal: number
  permission?: Permission
  type: FieldType
  values: FieldValue[]

  value?: string
}

export enum MssReportOutputFormat {
  auto = 'auto',
  key = 'key',
  value = 'value',
  key_value = 'key_value',
}

export interface FieldEntity {
  deletableEntity?: boolean
  deleteEntity?: boolean
  entity: string
  entityId?: string
  ordinal?: string
  relatedEntity?: string
  relatedEntityId?: string
  values: FieldValue[]
}

export interface FieldMove {
  afterFieldId: string
  targetFieldGroupId?: string
}

export enum FieldType {
  text = 'text',
  number = 'number',
  decimal = 'decimal',
  integer = 'integer',
  float = 'float',
  date = 'date',
  datetime = 'datetime',
  dropdown = 'dropdown',

  memorySizeBytes = 'memorySizeBytes',
}

export enum FloatTypeFields {
  'amount',
  'total',
  'balance',
}

export interface EntityValues {
  [code: string]: any
}

export interface Fields {
  [entity: string]: {
    [code: string]: string | null
  }
  computed: {
    fullName: string
  }
}

export interface FieldValue {
  code: EmployeePayElementValues | string
  value?: any
}

export interface FieldGroup {
  code: string
  fields: Field[]
  id?: string
  ignoreRequiredIfEmpty?: boolean
  localName?: string
  isRepeatable?: boolean
  name: string
  ordinal: number
  permission?: Permission
  subgroups: FieldGroup[]
}

export interface FileHandle {
  contentType?: string
  description?: string
  displayName?: string
  visibility?: string
  id: string
  originalName?: string
  size?: number
  updateTime?: string
  file?: File | null
}

export enum Gender {
  m = 'm',
  f = 'f',
}

export enum ImmigrationStatus {
  no_residency_permit = 'no_residency_permit',
  company_iqama = 'company_iqama',
  other_sponsor_iqama = 'other_sponsor_iqama',
  visit_visa = 'visit_visa',
}

export interface Language {
  code: string
  englishName: string
  localName: string
}

export interface ConsolidationGroup {
  name: string
  id?: number
  parentLegalEntity: parentLegalEntity
  legalEntities?: parentLegalEntity[]
}

export interface parentLegalEntity {
  id: number
  name: string
  logoAttachmentToken?: string
  roles: Set<string>
  currency: string
}

export interface LegalEntity {
  id: number
  legalName: string
  brandName: string | null
  name: string
  logoFileId: string | null
  logo?: string
  masterdataEditAllowed: boolean
  paymentMethod: PaymentMethod
  phone: string | null
  status: Status
  usesIntegrationFiles: boolean
  hrEmail: string | null
  firstDayOfWeek: number
  defaultWelcomeMessage: string | null
  decimalSettings: DecimalSettings
  country: string | null
  code: string | null
  addresses: Address[]
  activity: string
  enabledModules: string[]
  currencies: string[]
  roles?: Set<string>
  currency: string
  logoAttachmentToken?: string
  defaultLanguage: Language
  enabledLanguages: Language[]
  paymentProcessor?: string | null
}

export interface LegalEntityReport {
  generationDate?: string
  id?: string
  label: string
}

export interface MappingResult {
  errors?: MappingRowResult[]
}

export interface MappingRowResult {
  messages?: EntityFieldErrorMessage[]
  rowOrdinal?: string
}

export enum MaritalStatus {
  not_married = 'not_married',
  married = 'married',
  divorced = 'divorced',
  widowed = 'widowed',
}

export enum OrderByOperator {
  asc = 'asc',
  desc = 'desc',
}

export interface PayElement {
  account: number
  name: string
  occurrence?: PayElementOccurrence
  payUnit?: PayUnitType
  currency?: string
  type?: PayElementType
  localName?: string
  calculationType?: string
}

export enum PayElementOccurrence {
  recurring = 'recurring',
  additional = 'additional',
}

export interface EmployeePayElementProperties {
    rootEmployeePayElementId: number;
    account: number;
    amount?: number;
    currency?: string;
    balance?: number;
    total?: number;
    startDate: string;
    endDate?: string;
    payUnit?: PayUnitType;
    calculationType: CalculationType;
    grossUp: boolean;
    calculateRetroactiveAmount?: boolean;
}

export interface EmployeePayElement extends EmployeePayElementProperties {
  employeeId: number
  source?: string
  actorEmployeeId?: number
  createTime?: string
  changes: EmployeePayElementChange[]
  id?: number
}

export interface EmployeePayElementChange extends EmployeePayElementProperties {
  id?: number
  status?: PayElementStatus
  noChange: boolean
  delete: boolean
  source?: string
  actorEmployeeId?: number
  createTime?: string
  startDate: string
}

export interface PeriodPayElement {
  id: number
  account: number
  employeeId: number
  rootEmployeePayElementId: number
  amount: number
  total?: number
  balance?: number
  currency: string
  startDate: string
  endDate?: string
  source?: string
}

export enum PayElementStatus {
  draft = 'draft',
  pending = 'pending',
  rejected = 'rejected',
  imported = 'imported',
}

export enum PayElementType {
  deduction = 'deduction',
  earning = 'earning',
  accrual = 'accrual',
  contribution = 'contribution',
  tax = 'tax',
  unit = 'unit',
  cumulative = 'cumulative',
  leave = 'leave',
}

export enum PaymentPeriodicity {
  weekly = 'weekly',
  biweekly = 'biweekly',
  twicemonth = 'twicemonth',
  monthly = 'monthly',
  quarterly = 'quarterly',
  annually = 'annually',
}

export interface PaymentMethod {
  id?: string
  name?: string
}

export interface Payroll {
  beginDate?: string
  clientStatus?: ApprovalStatus
  currency?: string
  endDate?: string
  id?: string
  legalEntityId?: string
  name?: string
  payGroupCode?: string
  paymentDate?: string
  status?: PayrollStatus
}

export enum PayrollStatus {
  calculated = 'calculated',
  calculating = 'calculating',
  cancelled = 'cancelled',
  cancelling = 'cancelling',
  closed = 'closed',
  closing = 'closing',
  draft = 'draft',
  generating = 'generating',
  invalid = 'invalid',
  pending = 'pending',
}

export enum Permission {
  read = 'read',
  write = 'write',
  hidden = 'hidden',
}

export interface Person {
  address?: Address
  birthDate?: string
  firstName?: string
  formattedName?: string
  gender?: Gender
  gosiNumber?: string
  immigrationStatus?: ImmigrationStatus
  lastName?: string
  legalScheme?: string
  maritalStatus?: MaritalStatus
  middleName?: string
  molId?: string
  nationalId?: string
  nationality?: string
  passportNumber?: string
  primaryEmail?: string
  primaryPhoneNumber?: string
  visaId?: string
}

export enum QueryOperator {
  eq = 'eq',
  gt = 'gt',
  gte = 'gte',
  in = 'in',
  like = 'like',
  lt = 'lt',
  lte = 'lte',
  neq = 'neq',
  nin = 'nin',
  null = 'null',
  nnull = 'nnull',
}

export interface ReportColumnDefinition {
  entity: string
  extraCss?: string
  extraCssCondition?: string
  field: string
  formula?: string
  label: string
}

export interface ReportColumnGroupDefinition {
  columns: ReportColumnDefinition[]
  label: string
}

export interface ReportContent {
  content?: any
  filename?: string
  mime?: string
}

export interface ReportDefinition {
  columns: ReportColumn[]
  employeeIds?: string[] | null
  id?: string
  label: string
  template?: boolean
}

export interface ReportParameters {
  costCenters?: string[]
  departments?: string[]
  divisions?: string[]
  employeeIds?: string[]
  endDate?: string
  payrollId?: string
  reportCode?: string | null
  startDate?: string
}

export interface Response {
  data?: any
  errors?: ErrorMessage[]
  status?: number
}

export interface EntityResponse {
  data?: any
  errors?: EntityFieldErrorMessage[]
  status?: number
}

export interface RowFieldValue {
  entity?: string
  field?: string
  value?: any
}

export interface SelectOption {
  key: string
  label: string
  subLabel?: string | null;

  //required in responses, left that optional since we have options created by ourselves, without displayKey
  displayKey?: string
  accountType?: string
  localLabel?: string
}

export enum Status {
  active = 'active',
  draft = 'draft',
  inactive = 'inactive',
  pending = 'pending',
}

export enum TerminationStatus {
  acrive = 'active',
  draft = 'draft',
  inactive = 'inactive',
}

export interface TaskRunnerJobTriggerResponse {
  jobId?: string
}

export enum EmployeePayElementValues {
  account = 'account',
  amount = 'amount',
  pay_unit = 'payUnit',
  total = 'total',
  balance = 'balance',
  currency = 'currency',
  start_date = 'startDate',
  end_date = 'endDate',
  gross_up = 'grossUp',
  calculation_type = 'calculationType',
  calculateRetroactiveAmount = 'calculateRetroactiveAmount'
}

export enum ReportVisibility {
  private = 'private',
  public = 'public',
}

export enum ReportStatus {
  draft = 'draft',
  generating = 'generating',
  generated = 'generated',
  errored = 'errored',
}

export interface Report {
  id: number
  index?: number
  label: string
  generationDate?: string
  createdAt?: string
  templateId?: number
  legalEntityPeriodIds: number[]
  employeeIds: number[] | null
  isTemporary: boolean
  legalEntityReportType?: DataSource
  ownerEmployee: {
    id: number | null
    firstName: string | null
    lastName: string | null
    referenceCode: string | null
  }
  visibility: ReportVisibility
  regenerateMasterdata?: boolean
  status: ReportStatus
  errors?: string[]
  showCurrencyForAmounts?: boolean
}

export interface ColumnGroup {
  type: string
  label: string
  columns: ReportColumn[]
}

export interface ReportColumn {
  account?: number // Used only on FE side

  formula: string
  label: string
  extraCss: string | null
  columnType?: string | null | ReportColumnType
  filterFormula?: string | null
  visibility?: Visibility
  groupingFormula?: string
  outputType?: 'default'|'string'|'decimal'|'integer'
}

export enum ReportColumnType {
  REPORT_HEADER = 'REPORT_HEADER',
  ORDER_BY = '%%ORDER_BY',
  REPORT = '%%REPORT',
  SUMMED = 'SUMMED',
  COUNT = 'COUNT',
  COLUMN_CALCULATED = 'COLUMN_CALCULATED',
  COLUMN_CALCULATED_STRING = 'COLUMN_CALCULATED_STRING',
}

export enum Visibility {
  visible = 'visible',
  hidden = 'hidden'
}

export interface ReportTemplate {
  id: number
  index?: number
  label: string
  isTemporary: boolean
  employees: {
    queries: GrouperQueries
    orderBy?: EntityFieldOrderBy[]
  }
  columns: ReportColumn[]
  visibility: ReportVisibility
  labelFormula: string
  grouping: string[]
  ownerEmployee: {
    id: number
    firstName: string
    lastName: string
    referenceCode: string
  }
}

export interface LegalEntityPeriod {
  id: number
  beginDate: string
  endDate: string
  name: string
  status: string
  periodStatus: string
  clientStatus: LegalEntityPeriodClientStatus;
  payrollCycles: LegalEntityPayrollCycle[]|null;
}

export interface LegalEntityPayrollCycle {
  status: string;
  periodicity: 'monthly'|'weekly'
}

export enum LegalEntityPeriodClientStatus {
  preinput= 'preinput',
  input = 'input',
  waiting_for_processing = 'waiting_for_processing',
  in_review = 'in_review',
  authorization_form_approval = 'authorization_form_approval',
  rejected = 'rejected',
  approved = 'approved',
  off_cycle_input = 'off_cycle_input',
  off_cycle_processing = 'off_cycle_processing',
  off_cycle_review = 'off_cycle_review',
  off_cycle_rejected = 'off_cycle_rejected'
}

export enum LegalEntityPeriodStatus {
  open= 'open',
  input = 'input',
  rejected = 'rejected',
  approved = 'approved',
}

export interface CreateReportTemplate {
  visibility?: ReportVisibility // Default: 'private'
  isTemporary?: boolean // Default: true
  label: string
  labelFormula: string
  employees?: {
    queries: GrouperQueries
    orderBy: EntityFieldOrderBy[]
  }
  definition?: {
    grouping: string[]
    columns: ReportColumn[]
  }
}

export interface CreateReport {
  templateId: number
  legalEntityPeriodIds: number[]
  visibility?: ReportVisibility // Default: 'private'
  isTemporary?: boolean // Default: true
  regenerateMasterdata?: boolean
}

export interface EditReport {
  label?: string
  visibility?: ReportVisibility // Default: 'private'
  isTemporary?: boolean // Default: true
}

export enum DataSource {
  payroll = 'payroll_results',
  employee = 'employee_masterdata',
}

export interface userRolesAndModules {
  [key: string]: {
    modules: string[]
    roles: string[]
  }
}

export interface ApiOption {
  name: string;
  code: string;
  currencies: ApiOption[];
  accountType?: string;
}

export interface SelectOption {
  key: string;
  label: string;
  displayKey?: string;
  currencies?: ApiOption[];
  accountType?: string;
}

export interface BankField {
  category: string;
  dataType: string;
  label: string;
  maxLength: number|null;
  minLength: number|null;
  name: string;
  regularExpression: string;
  required: boolean;
  selectionChoices: SelectionChoice[];
}

export interface BankData {
  fields: BankField[];
  profileType: string;
  type: string;
}

export interface BankAccountField {
  fieldName: string;
  fieldValue: string;
}

export interface SelectionChoice {
  value: string;
  label: string;
}

export interface BankAccountFields {
  [key: string]: BankAccountField;
}

export interface BankDetails {
  profileType: string;
  status: string;
  token: string;
  transferMethodCountry: string;
  transferMethodCurrency: string;
  type: string;
  [key: string]: string;
}

export interface UserErrors {
  message: string;
  code: string;
  fieldName: string;
}

export interface BankAccount {
  id: number,
  employeeId: number,
  userToken: string;
  bankToken: string;
  recordStatus: string;
  processorStatus: string;
  paymentProcessorType: string;
  bankDetails: BankDetails;
  errors?: UserErrors[];
}
