import {FoldingSearchInput, IFoldingSearchInputTheme, IconPopover} from '@common';
import {stylingVariables, intl} from '@global';
import React, {CSSProperties, PureComponent} from 'react';
import styled from 'styled-components';

const StyledStatusBar = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding: 5px 10px;
  background-color: ${stylingVariables.colorPalette.white};
  border-radius: 25px;
  min-width: 80px;
  height: 50px;
`;

const StyledSearchInputWrapper = styled.div`
  width: 260px;
  margin-left: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

interface IProps {
    children: any;

    style?: CSSProperties;
    onSearch?: (value: string) => any;
    searchPlaceholder: string;
    searchQuery?: string;
}

export default class StatusBar extends PureComponent<IProps> {
    public static defaultProps = {
        searchPlaceholder: '',
    };
    public render() {
        return (
            <StyledStatusBar style={this.props.style}>
                {this.props.children}
                {this.props.onSearch && (
                    <StyledSearchInputWrapper>
                        <FoldingSearchInput
                            onChange={this.props.onSearch}
                            placeholder={this.props.searchPlaceholder || intl.get('search')}
                            value={this.props.searchQuery || ''}
                            autoFocus={true}
                            theme={IFoldingSearchInputTheme.minimal}
                        />

                        <div style={{marginBottom: 14}}>
                            <IconPopover
                                type={'info'}
                                message={intl.get('search_employee_fields')} iconSize={14}
                                positionLeft={true}
                            />
                        </div>
                    </StyledSearchInputWrapper>
                )}
            </StyledStatusBar>
        );
    }
}
