import {createSelector} from 'reselect';

import {constants} from '@global';
import {ApiObject} from '@api';

import {getFieldGroups} from '../../selectors';
import {RootState} from '../../../../rootReducer';
import { cloneDeep, groupBy } from 'lodash';

export const getDependentGroup = createSelector(
    [getFieldGroups],
    (groups: ApiObject.FieldGroup[]) => {
        const group = groups.find(g => g.code === constants.DEFAULT_FIELD_GROUP_DEPENDENTS);
        if (!group || !group.subgroups[0]) {
            return null;
        }

        return {
            ...group.subgroups[0],
            code: constants.DEFAULT_DEPENDENT_CODE,
            fields: group.subgroups[0].fields.sort((a, b) => a.ordinal - b.ordinal),
        };
    },
);

export const getDependents = createSelector(
    [
        (state: RootState) => state.employee.fields,
    ],
    (fields: ApiObject.FieldEntity[]) => {
        const personInfo: any = {}

        const dependentFields = groupBy(cloneDeep(fields)
            .filter((f) => f.entity.startsWith(constants.DEFAULT_DEPENDENT_CODE) && f.deleteEntity === false), 'entityId')
        
        Object.keys(dependentFields).forEach(entityId => {
            dependentFields[entityId].forEach(field => {
                if (entityId) {
                    personInfo[entityId] = personInfo[entityId] ? [...personInfo[entityId], ...field.values] : field.values
                }
            })
        })

        return cloneDeep(fields)
            .filter((f) => f.entity === constants.DEFAULT_DEPENDENT_CODE && f.deleteEntity === false)
            .map((f, index) => {
                const accumulator: ApiObject.EntityValues = {
                    index: index,
                    entity: f.entity,
                    entityId: f.entityId,
                    deletableEntity: f.deletableEntity,
                };

                if (f.entityId !== undefined) {
                    f.values = [...f.values, ...personInfo[f.entityId]]
                }
                for (const value of f.values) {
                    accumulator[value.code] = value.value
                }
                return accumulator
            })
            .filter((fieldValue) => fieldValue[constants.DEPENDENT_FIELD_RELATIONSHIP] !== null)
            .sort((a, b) => a.ordinal - b.ordinal)

    },
);

export const getDependentValuesByEntityId = createSelector(
    [
        getDependents,
        (state: RootState, ownProps: any) => ownProps.entityId,
    ],
    (fields: ApiObject.EntityValues[], entityId) => {
        return fields.find(f => f.entityId === entityId) || null;
    },
);
