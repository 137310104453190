import React, {PureComponent} from 'react';
import styled from 'styled-components';
import DatePicker, { registerLocale } from "react-datepicker";
import moment, {Moment} from 'moment';
import 'react-datepicker/dist/react-datepicker.css';

import {stylingVariables, constants} from '@global';
import {Icon} from '@common';

import BlankButton from '../BlankButton';
import * as Cookies from 'js-cookie';
import et from "date-fns/locale/et";

registerLocale("et", et);

const StyledMonthPickerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  .react-datepicker-wrapper {
    .react-datepicker__input-container {
      input,
      button {
        cursor: pointer;
        padding: 0 30px 0 10px;
        height: 30px;
        border: 1px solid ${stylingVariables.colorPalette.green};
        background: ${stylingVariables.colorPalette.white};
        font-weight: ${stylingVariables.fontWeight.semibold};
        width: 160px;
      }
    }
  }

  /* overrides those from 'react-datepicker/dist/react-datepicker.css' */
  .react-datepicker-popper {
    &[data-placement="bottom-end"] {
      top: auto !important;
      left: auto !important;
      right: 0px !important;
      transform: none !important;

      .react-datepicker {
        .react-datepicker__triangle {
          right: 50px;
          left: auto;
        }
      }
    }
  }
  .react-datepicker {
    border: 0;
    box-shadow: ${stylingVariables.layout.boxShadow};
    .react-datepicker__triangle {
      border-bottom-color: ${stylingVariables.colorPalette.white}!important;
      &:before {
        border-bottom: 0;
      }
    }
    .react-datepicker__month-container {
      .react-datepicker__header {
        background-color: ${stylingVariables.colorPalette.white};
        border-bottom: 0;
        font-weight: ${stylingVariables.fontWeight.light};
      }
      .react-datepicker__month {
        display: flex;
        .react-datepicker__month-wrapper {
          .react-datepicker__month-text {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 5px;
            height: 30px;
            font-weight: ${stylingVariables.fontWeight.light};
            &.react-datepicker__month--selected {
              background-color: ${stylingVariables.colorPalette.green};
            }
          }
        }
      }
    }
  }
`;

const StyledPickerWrapper = styled.div<{ isDisabled: boolean}>`
  position: relative;
  z-index: 300;

  svg {
    position: absolute;
    right: 5px;
    top: 5px;
  }

  ${props => props.isDisabled && `
    button{
        background: ${stylingVariables.colorPalette.darkGray} !important;
    }
  `}

  .react-datepicker__input-container {
    button {
      text-transform: capitalize;
    }
  }
`;

const PrevMonthButton = styled(props => <BlankButton {...props} />)`
    width: 30px;
    border: 1px solid ${stylingVariables.colorPalette.green};
    height: 30px;
    background-color: ${stylingVariables.colorPalette.white};
    border-right: 0;
    border-radius: 50% 0 0 50%;

    &:disabled{
        background-color: ${stylingVariables.colorPalette.darkGray};
    }
`;

const NextMonthButton = styled(props => <BlankButton {...props} />)`
    width: 30px;
    border: 1px solid ${stylingVariables.colorPalette.green};
    height: 30px;
    background-color: ${stylingVariables.colorPalette.white};
    border-left: 0;
    border-radius:  0 50% 50% 0;

    &:disabled{
        background-color: ${stylingVariables.colorPalette.darkGray};
    }
`;

const InputButton = React.forwardRef(({ value, onClick }: any, _) => (
    <button onClick={onClick}>{value}</button>
));

interface MonthPickerProps {
    selected: Moment;
    dateFormat: string;
    popperPlacement: string;
    onChangeDate: (date: Moment) => void;
    isDisabled: boolean;
    maxDate?: Moment;
}

export default class MonthPicker extends PureComponent<MonthPickerProps> {
    static defaultProps = {
        popperPlacement: 'bottom-start',
        dateFormat: 'MMMM, yyyy',
        isDisabled: false,
    };

    private locale = Cookies.get('locale');

    render() {
        return (
            <StyledMonthPickerWrapper>
                <PrevMonthButton onClick={this.chosePrevMonth} disabled={this.props.isDisabled}>
                    <Icon type={constants.IconTypes.ARROW_LEFT}
                          width={10}
                          height={10}
                          fillColor={stylingVariables.colorPalette.green}
                    />
                </PrevMonthButton>

                <StyledPickerWrapper isDisabled={this.props.isDisabled}>
                    <DatePicker
                        locale={this.locale}
                        disabled={this.props.isDisabled}
                        dateFormat={this.props.dateFormat}
                        selected={this.props.selected.toDate()}
                        onChange={date => {
                            const selected = date ? moment(date) : moment();

                            this.props.onChangeDate(selected);
                        }}
                        popperPlacement={this.props.popperPlacement}
                        showMonthYearPicker
                        customInput={<InputButton/>}
                    />
                    <Icon
                        type={constants.IconTypes.CALENDAR}
                        width={20}
                        height={20}
                    />
                </StyledPickerWrapper>

                <NextMonthButton
                    onClick={this.choseNextMonth}
                    disabled={this.props.isDisabled || this.props.selected.isSame(this.props.maxDate)}>
                    <Icon type={constants.IconTypes.ARROW_RIGHT}
                          width={10}
                          height={10}
                          fillColor={stylingVariables.colorPalette.green}
                    />
                </NextMonthButton>
            </StyledMonthPickerWrapper>
        );
    }

    private chosePrevMonth = () => {
        const date = moment(
            this.props.selected.add(-1, 'month'),
        );

        this.props.onChangeDate(date);
    }

    private choseNextMonth = () => {
        const date = moment(
            this.props.selected.add(1, 'month'),
        );

        this.props.onChangeDate(date);
    }
}
