import {Icon} from '@common';
import React, {PureComponent} from 'react';
import styled from 'styled-components';
import moment, {Moment} from 'moment';

import {stylingVariables, constants, intl, interfaces} from '@global';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 440px;
  background-color: #fff;
  padding: 35px 55px 70px;
  user-select: none;
`;

const YearSelector = styled.div`
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
  font-weight: ${stylingVariables.fontWeight.semibold};
  font-size: ${stylingVariables.fontSize.mediumLarge};
`;
const YearArrow = styled.div`
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  
  border-radius: 4px;
  transition: background-color .2s;
  &:hover {
    transition: background-color .2s;
    background-color: ${stylingVariables.colorPalette.lightGreen};
  }
`;
const MonthGrid = styled.div`
`;
const MonthGridRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: 15px 0;
`;
const MonthGridItem = styled.div<{isSelected: boolean; isDisabled: boolean}>`
  height: 30px;
  width: 100px;
  border-radius: 20px;
  border: 1px solid ${stylingVariables.colorPalette.green};
  font-size: ${stylingVariables.fontSize.medium};
  
  display: flex;
  align-items: center;
  justify-content: center;
  
  cursor: pointer;
  opacity: .8;
  &:hover {
    transition: all .2s;
    opacity: 1;
    background-color: ${stylingVariables.colorPalette.lightGreen};
  }
  
  ${props => props.isSelected && `
    background-color: ${stylingVariables.colorPalette.lightGreen};
  `}
  
  ${props => props.isDisabled && `
    opacity: .2;
    pointer-events: none;
  `}
`;
const Footer = styled.div<{color?: string}>`
  display: flex;
  justify-content: flex-end;
`;
const ActionButton = styled.span<{color?: string}>`
  cursor: pointer;
  padding: 10px 20px;
  font-size: ${stylingVariables.fontSize.default};
  font-weight: ${stylingVariables.fontWeight.semibold};
  color: ${props => props.color || stylingVariables.colorPalette.darkest};
  border-radius: 4px; 
  opacity: .8;
  &:hover {
    transition: all .2s;
    opacity: 1;
    background-color: ${stylingVariables.colorPalette.lightGreen};
  }
`;

interface IProps {
    allowPeriods: interfaces.Periods;
    periods: interfaces.Periods;
    onSave: (periods: interfaces.Periods) => void;
    onCancel: () => void;
    multiselect?: boolean;

    disableAfterDate?: string|Moment;
    disableBeforeDate?: string|Moment;
}

interface IState {
    year: number;
    periods: interfaces.Periods;
}

export default class MultiMonthPicker extends PureComponent<IProps, IState> {
    static defaultProps = {
        allowPeriods: [],
        multiselect: true,
        periods: [],
        onCancel: () => {}
    };

    constructor(props: IProps) {
        super(props);

        this.state = {
            year: props.periods.length > 0
              ? moment(props.periods[props.periods.length - 1].beginDate).year()
              : moment().year(),
            periods: props.periods
        }
    }

    isDisabled = (monthIndex: number) => {
        if (!this.isAllowedToSelectMonth(monthIndex)) {
            return true;
        }

        const {disableAfterDate, disableBeforeDate} = this.props;
        const monthDateFirstDay = moment(`${this.state.year}-${monthIndex < 10 ? `0${monthIndex}` : monthIndex}-01`)
        const monthDateLastDay = moment(monthDateFirstDay).endOf('month');
        if (disableBeforeDate && monthDateFirstDay < moment(disableBeforeDate).add(-1, 'month')) {
            return true;
        }
        if (disableAfterDate && monthDateLastDay > moment(disableAfterDate).add(1, 'month')) {
            return true;
        }

        return false;
    }

    getPeriodByMonthIndex(monthIndex: number): interfaces.Period {
        return {
            beginDate: moment(new Date(this.state.year, monthIndex - 1, 1)).format('YYYY-MM-DD'),
            endDate: moment(new Date(this.state.year, monthIndex - 1, 1)).endOf('month').format('YYYY-MM-DD')
        }
    }

    isAllowedToSelectMonth = (monthIndex: number) => {
        if (this.props.allowPeriods.length === 0) {
            return false;
        }
        const { beginDate, endDate } = this.getPeriodByMonthIndex(monthIndex)

        return this.props.allowPeriods.some(allowPeriod =>
          allowPeriod.beginDate === beginDate && allowPeriod.endDate === endDate
        )
    }

    isSelectedMonth = (monthIndex: number) => {
        if (this.state.periods === null) {
            return false;
        }

        const { beginDate, endDate } = this.getPeriodByMonthIndex(monthIndex)

        return this.state.periods.some(allowPeriod =>
          allowPeriod.beginDate === beginDate && allowPeriod.endDate === endDate
        )
    }

    onMonthClick = (monthIndex: number) => () => {
        if (this.isDisabled(monthIndex)) {
            return;
        }

        let periods = this.props.multiselect ? [...this.state.periods] : [];
        const {beginDate, endDate} = this.getPeriodByMonthIndex(monthIndex)

        if (this.isSelectedMonth(monthIndex)) {
            periods = periods.filter(p => p.beginDate !== beginDate && p.endDate !== endDate)
        } else {
            periods.push({beginDate, endDate})
        }

        periods = periods.sort((a,b) => a.beginDate > b.beginDate ? 1 : -1)

        this.setState({periods})
    }

    private save = () => {
        this.props.onSave(this.state.periods);
    }

    private cancel = () => {
        this.props.onCancel();
    }

    onMouseEnter = () => {
        document.addEventListener('keydown', this.handleKeyDown)
    }

    onMouseLeave = () => {
        document.removeEventListener('keydown', this.handleKeyDown)
    }

    componentWillUnmount() {
        this.onMouseLeave()
    }

    handleKeyDown = (event: any) => {
        switch (event.key) {
            case '1': return this.onMonthClick(1)();
            case '2': return this.onMonthClick(2)();
            case '3': return this.onMonthClick(3)();
            case '4': return this.onMonthClick(4)();
            case '5': return this.onMonthClick(5)();
            case '6': return this.onMonthClick(6)();
            case '7': return this.onMonthClick(7)();
            case '8': return this.onMonthClick(8)();
            case '9': return this.onMonthClick(9)();
            case '0': return this.onMonthClick(10)();
            case '-': return this.onMonthClick(11)();
            case '=': return this.onMonthClick(12)();
            case 'ArrowLeft': return this.prevYear();
            case 'ArrowRight': return this.nextYear();
            case 'Enter': return this.save();
            case 'Esc': return this.cancel();
            default: break;
        }
    }

    prevYear = () => {
        this.setState((state) => ({...state, year: state.year - 1}))
    }

    nextYear = () => {
        this.setState((state) => ({...state, year: state.year + 1}))
    }

    render() {
        const monthProps = (monthIndex: number) => {
            return {
                isSelected: this.isSelectedMonth(monthIndex),
                isDisabled: this.isDisabled(monthIndex),
                onClick: this.onMonthClick(monthIndex)
            };
        }

        return (
            <Wrapper
                onMouseEnter={this.onMouseEnter}
                onMouseLeave={this.onMouseLeave}
            >
                <YearSelector>
                    <YearArrow onClick={this.prevYear}>
                        <Icon
                            type={constants.IconTypes.ARROW_LEFT}
                            width={10}
                            height={10}
                            fillColor={stylingVariables.colorPalette.darkest}
                        />
                    </YearArrow>
                    {this.state.year}
                    <YearArrow onClick={this.nextYear}>
                        <Icon
                            type={constants.IconTypes.ARROW_RIGHT}
                            width={10}
                            height={10}
                            fillColor={stylingVariables.colorPalette.darkest}
                        />
                    </YearArrow>
                </YearSelector>
                <MonthGrid>
                    <MonthGridRow>
                        <MonthGridItem {...monthProps(1)}>{intl.get('january')}</MonthGridItem>
                        <MonthGridItem {...monthProps(2)}>{intl.get('february')}</MonthGridItem>
                        <MonthGridItem {...monthProps(3)}>{intl.get('march')}</MonthGridItem>
                    </MonthGridRow>
                    <MonthGridRow>
                        <MonthGridItem {...monthProps(4)}>{intl.get('april')}</MonthGridItem>
                        <MonthGridItem {...monthProps(5)}>{intl.get('may')}</MonthGridItem>
                        <MonthGridItem {...monthProps(6)}>{intl.get('june')}</MonthGridItem>
                    </MonthGridRow>
                    <MonthGridRow>
                        <MonthGridItem {...monthProps(7)}>{intl.get('july')}</MonthGridItem>
                        <MonthGridItem {...monthProps(8)}>{intl.get('august')}</MonthGridItem>
                        <MonthGridItem {...monthProps(9)}>{intl.get('september')}</MonthGridItem>
                    </MonthGridRow>
                    <MonthGridRow>
                        <MonthGridItem {...monthProps(10)}>{intl.get('october')}</MonthGridItem>
                        <MonthGridItem {...monthProps(11)}>{intl.get('november')}</MonthGridItem>
                        <MonthGridItem {...monthProps(12)}>{intl.get('december')}</MonthGridItem>
                    </MonthGridRow>
                </MonthGrid>
                <Footer>
                    <ActionButton onClick={this.cancel}>{intl.get('cancel')}</ActionButton>
                    <ActionButton onClick={this.save} color={stylingVariables.colorPalette.green}>{intl.get('apply')}</ActionButton>
                </Footer>
            </Wrapper>
        );
    }
}
