import React, {PureComponent, Fragment} from 'react';
import styled from 'styled-components';

import {interfaces, stylingVariables, intl} from '@global';
import {ApiObject, EmployeeInterfaces, PayrollInterfaces} from '@api';
import {SearchInput, Checkbox, InfiniteTableHeader} from '@common';

const StyledEmployeesWrapper = styled.div`
  height: 250px;
  overflow-y: auto;
  margin-top: 10px;
`;

const StyledEmployeeWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 0;
  color: ${stylingVariables.colorPalette.dark};
  font-size: ${stylingVariables.fontSize.mediumLarge};
  font-weight: ${stylingVariables.fontWeight.regular};
  &:last-child {
    margin-bottom: 0;
  }
  span {
    margin-left: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(100% - 24px);
  }
`;

const StyledLoadMore = styled.div`
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: center;
  font-size: ${stylingVariables.fontSize.small};
  font-weight: ${stylingVariables.fontWeight.semibold};
  color: ${stylingVariables.colorPalette.dark};
  background-color: ${stylingVariables.colorPalette.lightGreen};
  cursor: pointer;
`;

const SpinnerIcon = styled.div`
  margin: 5px auto;
  width: 30px;
  height: 30px;
  box-sizing: border-box;

  border: solid 4px transparent;
  border-top-color: ${stylingVariables.colorPalette.green};
  border-left-color: ${stylingVariables.colorPalette.green};
  border-radius: 50%;

  -webkit-animation: nprogress-spinner 600ms linear infinite;
  animation: nprogress-spinner 600ms linear infinite;
`;

const StyledTagsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid ${stylingVariables.colorPalette.gray};
    span {
        margin-left: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: calc(100% - 24px);
    }
`;

const StyledTagWrapper = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

const StyledFilterSubHeader = styled.div`
    margin: 10px 0;
    font-size: ${stylingVariables.fontSize.medium};
    font-weight: ${stylingVariables.fontWeight.semibold};
    color: ${stylingVariables.colorPalette.dark};
`;

interface IProps {
    currentPeriod?: PayrollInterfaces.PayrollPeriod|null;

    filters?: interfaces.ListingFilters;
    tags: EmployeeInterfaces.Tag[];

    changeFilter?: (filterParams: interfaces.FilterParams) => any;
    setTags?: (tags: EmployeeInterfaces.Tag[]) => void;

    onClose?: () => any;
    getEmployees?: (
        query: string,
        tags: EmployeeInterfaces.Tag[],
        limit: number,
        page: number,
    ) => any;
}

interface IState {
    searchQuery: string;
    selectedIds: Set<number>;
    tags: EmployeeInterfaces.Tag[];
    employeePage: number;
    total: number;
    isLoading: boolean;

    employees: {
        id: number,
        referenceCode: string,
        person: {firstName: string, lastName: string},
    }[];
}

export class FilterContent extends PureComponent<IProps, IState> {
    static COLUMN = 'id';

    private readonly availableTags: {label: string, tag: EmployeeInterfaces.Tag}[];

    public constructor(props: IProps) {
        super(props);

        let prevSelectedIds: Set<number> = new Set();
        let prevSearchQuery: string = '';
        if (props.filters && props.filters[FilterContent.COLUMN]) {
            prevSelectedIds = new Set(props.filters[FilterContent.COLUMN].value);
            prevSearchQuery = props.filters[FilterContent.COLUMN].searchQuery || '';
        }
        this.state = {
            selectedIds: prevSelectedIds,
            searchQuery: prevSearchQuery,
            employeePage: 0,
            total: 0,
            employees: [],
            tags: props.tags,
            isLoading: false,
        };

        this.availableTags = [
            {
                tag: EmployeeInterfaces.Tag.pending_termination,
                label: intl.get('pending_termination'),
            },
        ];

        if (props.currentPeriod) {
            this.availableTags.push(
                {
                    tag: EmployeeInterfaces.Tag.starters,
                    label: intl.get('starters'),
                },
                {
                    tag: EmployeeInterfaces.Tag.leavers,
                    label: intl.get('leavers'),
                },
            );
        }
    }

    public componentDidMount() {
        this.getEmployees(this.state.searchQuery, false);
    }

    private onClose = () => {
        if (this.props.onClose) {
            this.props.onClose();
        }
    }

    private onFilter = () => {
        const ids = [...this.state.selectedIds];

        if (this.props.setTags) {
            this.props.setTags(this.state.tags);
        }

        if (this.props.changeFilter) {
            const filterParams: interfaces.FilterParams = {
                column: FilterContent.COLUMN,
                value: ids,
                searchQuery: this.state.searchQuery,
                expression: ApiObject.QueryOperator.in,
                type: 'text',
            };
            this.props.changeFilter(filterParams);
        }

        this.onClose();
    }

    private onReset = () => {
        this.setState({searchQuery: ''});

        if (this.props.setTags) {
            this.props.setTags([]);
        }

        if (this.props.changeFilter) {
            const filterParams: interfaces.FilterParams = {
                column: FilterContent.COLUMN,
                value: [],
                searchQuery: '',
                expression: ApiObject.QueryOperator.in,
                type: 'text',
            };
            this.props.changeFilter(filterParams);
        }

        this.onClose();
    }

    render() {
        return (
            <InfiniteTableHeader.StyledFilterContent toRight={true}>
                <InfiniteTableHeader.StyledFilterHeader>
                    {intl.get('filter_by_employee_header')}
                </InfiniteTableHeader.StyledFilterHeader>

                {this.renderTags()}

                <StyledFilterSubHeader>{intl.get('filter_by_employee')}</StyledFilterSubHeader>
                <SearchInput onChange={(value: string) => this.getEmployees(value, false, true)}
                             placeholder={'start_typing_to_filter'}
                             defaultValue={this.state.searchQuery}
                />

                {this.renderEmployees()}

                {this.renderActions()}
            </InfiniteTableHeader.StyledFilterContent>
        );
    }

    renderEmployees() {
        if (0 === this.state.employees.length) {
            return null;
        }

        return (
            <StyledEmployeesWrapper>
                {
                    this.state.employees.map(employee => (
                        <StyledEmployeeWrapper key={employee.id}>
                            <Checkbox
                                isChecked={this.state.selectedIds.has(employee.id)}
                                onClick={() => this.toggleEmployeeSelection(employee.id)}
                            />

                            <span>
                                {employee.person.firstName} {employee.person.lastName} - {employee.referenceCode}
                            </span>
                        </StyledEmployeeWrapper>
                    ))
                }
                {
                    this.state.isLoading
                        ? <SpinnerIcon/>
                        : (
                            this.state.employees.length < this.state.total
                            && <span key={'load_more'}
                                     className='dd-list-item'
                                     onClick={this.onLoadMoreEmployees}
                                     style={{padding: 0}}
                                >
                                    <StyledLoadMore>{intl.get('load_more')}...</StyledLoadMore>
                            </span>
                        )
                }
            </StyledEmployeesWrapper>
        );
    }

    renderActions() {
        return (
            <div style={{display: 'flex', justifyContent: 'space-between', marginTop: 20}}>
                <InfiniteTableHeader.StyledAction onClick={this.onReset}>
                    {intl.get('reset')}
                </InfiniteTableHeader.StyledAction>

                <InfiniteTableHeader.StyledApplyAction onClick={this.onFilter}>
                    {
                        this.state.selectedIds.size === 0
                            ? intl.get('select_all')
                            : <Fragment>{intl.get('select')} ({this.state.selectedIds.size})</Fragment>
                    }
                </InfiniteTableHeader.StyledApplyAction>
            </div>
        );
    }

    renderTags() {
        return (
            <>
                <StyledFilterSubHeader>
                    {intl.get('filter_by_tags')}
                </StyledFilterSubHeader>

                <StyledTagsWrapper>
                    {
                        this.availableTags.map(tag => (
                            <StyledTagWrapper key={tag.tag}>
                                <Checkbox
                                    isChecked={this.state.tags.includes(tag.tag)}
                                    onClick={() => this.toggleTag(tag.tag)}
                                />

                                <span>{tag.label}</span>
                            </StyledTagWrapper>
                        ))
                    }
                </StyledTagsWrapper>
            </>
        );
    }

    private getEmployees = async(value: string, clearSelected: boolean = true, clearEmployees: boolean = false) => {
        const LIMIT = 20;

        if (!this.props.getEmployees){ return; }

        if (value !== this.state.searchQuery || clearEmployees) {
            this.clearEmployees();
        }

        this.setState({isLoading: true});
        const employeesData = await this.props.getEmployees(
            value,
            this.state.tags,
            LIMIT,
            this.state.employeePage,
        );
        const employees = employeesData.content || [];
        this.setState((state: IState) => ({
            searchQuery: value,
            isLoading: false,
            employees: [...state.employees, ...employees],
            total: employeesData.total || 0,
            selectedIds: clearSelected ? new Set() : state.selectedIds,
        }));
    }

    private toggleEmployeeSelection = (id: number) => {
        const selectedIds = new Set(this.state.selectedIds);
        if (selectedIds.has(id)) {
            selectedIds.delete(id);
        } else {
            selectedIds.add(id);
        }

        this.setState({selectedIds});
    }

    private clearEmployees(){
        this.setState({
            employeePage: 0,
            employees: [],
        });
    }

    private toggleTag = (tag: EmployeeInterfaces.Tag) => {
        this.clearEmployees();

        const tags = [...this.state.tags];
        const index = tags.findIndex(t => t === tag);
        if (-1 === index) {
            tags.push(tag);
        } else {
            tags.splice(index, 1);
        }

        this.setState((state: IState) => {
            const tags = [...state.tags];
            const index = tags.findIndex(t => t === tag);
            if (-1 === index) {
                tags.push(tag);
            } else {
                tags.splice(index, 1);
            }

            return {tags};
        }, () => this.getEmployees(this.state.searchQuery));
    }

    private onLoadMoreEmployees = () => {
        this.setState((state: IState) => ({
            employeePage: state.employeePage + 1,
        }), () => this.getEmployees(this.state.searchQuery));
    }
}

export default FilterContent;
