import React, {PureComponent} from 'react';
import styled from 'styled-components';
import ApiEmployee from '../api/employee.service';

import {stylingVariables} from '@global';
import {ColorHelper, PreviewHelper} from '@library';

const StyledChartersPreview = styled.div<{ text: string, width: string, height: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${props => ColorHelper.stringToColour(props.text)};
  width: ${props => props.width};
  height: ${props => props.height};
  min-width: ${props => props.width};
  min-height: ${props => props.height};
  border-radius: 50%;
  font-size: ${stylingVariables.fontSize.mediumLarge};
  font-weight: ${stylingVariables.fontWeight.regular};
  letter-spacing: 2px;
  color: ${stylingVariables.colorPalette.white};
`;

interface ChartersPreviewProps {
    text: string;
    photo: string;
    width: string;
    height: string;
    legalEntityId: number;
}

export default class ChartersPreview extends PureComponent<ChartersPreviewProps> {
    _isMounted = false;
    state = {
        photo: null,
    };
    public static defaultProps = {
        width: '40px',
        height: '40px',
        photo: null,
        legalEntityId: null,
    };

    componentDidMount(): void {
        this._isMounted = true;
        if (this.props.photo) {
            ApiEmployee.getPhoto(this.props.legalEntityId, this.props.photo).then(result => {
                if (this._isMounted) {
                    this.setState({
                        photo: result,
                    });
                }
            });
        }
        else {
            this.setState({
                photo: null
            })
        }
    }

    UNSAFE_componentWillReceiveProps(props: any) {
        if (props.photo) {
            if (this.props.photo !== props.photo) {
                ApiEmployee.getPhoto(props.legalEntityId, props.photo).then(result => {
                    if (this._isMounted) {
                        this.setState({
                            photo: result,
                        });
                    }
                });
            }
        }
        else {
            this.setState({
                photo: null
            })
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        if (this.state.photo) {
            return (
                <img alt="" style={{
                    backgroundImage: `url(${this.state.photo})`,
                    backgroundSize: 'cover',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: this.props.width,
                    height: this.props.height,
                    minWidth: this.props.width,
                    minHeight: this.props.height,
                    borderRadius: '50%',
                    boxShadow: `inset 0 0 2px 1px ${stylingVariables.colorPalette.gray}`,
                }}/>
            );
        } else {
            return (
                <StyledChartersPreview text={this.props.text} width={this.props.width} height={this.props.height}>
                    {PreviewHelper.generateFromString(this.props.text)}
                </StyledChartersPreview>
            );
        }
    }
}
