import React, {Fragment, memo} from 'react';
import {Column} from 'react-virtualized';

import {defaultInfiniteTableCellDataGetter, Checkbox} from '@common';

import * as Headers from '../Header';

interface IndexColumnProps {
    bulkAction: any;
    totalCount: number;
    selectedIds: Set<number>;
    onChangeSelection: (id: number) => void;

    onSelectAll: () => any;
    onUnselectAll: () => any;
}

interface ICellProps {
    index: number;
    cellData: any;
    selectedIds: Set<number>;
    onChangeSelection: (id: number) => void;
}

const Cell: React.FC<ICellProps> = memo(({index, cellData, selectedIds, onChangeSelection}) => {
    const isChecked = selectedIds.has(cellData.id);

    return (
        <Fragment>
            <span className={'index'}>{index + 1}</span>

            <Checkbox isChecked={isChecked}
                      onClick={() => onChangeSelection(cellData.id)}
                      className={isChecked ? 'checkbox checked' : 'checkbox'}
            />
        </Fragment>
    );
});

const SelectedIndex: React.FC<IndexColumnProps> = (
    {
        selectedIds,
        totalCount,
        onChangeSelection,
        onSelectAll,
        onUnselectAll,
        bulkAction
    }) => (
    <Column
        label={'#'}
        dataKey='index'
        width={60}
        cellDataGetter={defaultInfiniteTableCellDataGetter}
        headerRenderer={() => <Headers.SelectedIndex
            selectedIds={selectedIds}
            totalCount={totalCount}
            onSelectAll={onSelectAll}
            onUnselectAll={onUnselectAll}
            bulkAction={bulkAction}
        />}
        cellRenderer={({cellData, rowIndex}) => (
            <Cell cellData={cellData} index={rowIndex} selectedIds={selectedIds} onChangeSelection={onChangeSelection}/>
        )}
    />
);

export default SelectedIndex;
