import React, { PureComponent } from "react";
import { AutoSizer } from "react-virtualized";
import styled from "styled-components";

import {
  InfiniteTableColumn,
  StyledInfiniteTable as CommonStyledInfiniteTable,
} from "@common";
import { ApiObject, EmployeeInterfaces } from "@api";
import { interfaces, intl, stylingVariables } from "@global";
import * as Columns from "./Column";
import { RootState } from "../../../../../rootReducer";
import * as selectors from "../../selectors";
import * as rootSelectors from "../../../../selectors";
import { changeFilter, changeOrder } from "../../redux";
import { connect } from "react-redux";

// eslint-disable-next-line no-unexpected-multiline
const StyledInfiniteTable = styled(CommonStyledInfiniteTable)<{
  isMenuCollapsed?: boolean;
  maxWidth?: string;
  realWidth?: number;
}>`
  width: calc(
    100vw - 40px -
      ${(props) =>
        props.isMenuCollapsed
          ? stylingVariables.layout.menuCollapsedWidth
          : "350px"}
  );
  min-width: 1061px;

  .ReactVirtualized__Table__row {
    .ReactVirtualized__Table__rowColumn {
      background-color: ${stylingVariables.colorPalette.lightGray};
    }
  }
  .ReactVirtualized__Table__headerColumn {
    white-space: normal;
    overflow: hidden !important;
    text-transform: capitalize;

    &.ReactVirtualized__Table__headerColumn-borderless {
      border-right: none;
    }
  }
`;

const NoRowsTable = styled.div`
  padding-bottom: 50px;
  overflow: overlay;
`;

const NoRowsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 50px;
  background-color: ${stylingVariables.colorPalette.lightGray};
  border-radius: ${stylingVariables.layout.borderRadius};
  font-size: ${stylingVariables.fontSize.mediumLarge};
  font-weight: ${stylingVariables.fontWeight.regular};
  margin: 15px 0;
  span {
    font-style: italic;
    margin-left: 20px;
  }
`;

interface TableProps {
  items: any[];
  employee: EmployeeInterfaces.Employee;
  orders: interfaces.ListingOrders;
  isMenuCollapsed: boolean;
  regulation?: any;
}

interface IState {
  items: any[];
  order: number;
}

export class Table extends PureComponent<TableProps, IState> {
  private table: any;

  public constructor(props: TableProps) {
    super(props);

    this.state = {
      items: this.props.items,
      order: 1,
    };
  }

  private get realTableWidth() {
    return 20 + 150 + 3 * 90 + 2 * 100 + 120 + 250;
  }

  componentDidUpdate(
    prevProps: Readonly<TableProps>,
    prevState: Readonly<IState>
  ) {
    if (
      this.props.items.length !== prevProps.items.length ||
      (this.props.items.length &&
        JSON.stringify(prevProps.items) !== JSON.stringify(this.props.items))
    ) {
      this.setState({ items: this.props.items });
    }
  }

  public render() {
    const amountType = this.amountType();
    return (
      <AutoSizer>
        {({ width, height }) => (
          <StyledInfiniteTable
            isMenuCollapsed={this.props.isMenuCollapsed}
            ref={this.setRef}
            rowHeight={70}
            rowClassName={"ReactVirtualized__Table__row_salary-general"}
            overscanRowCount={5}
            headerHeight={40}
            rowGetter={({ index }: { index: number }) =>
              this.state.items[index]
            }
            rowCount={this.state.items.length}
            height={height}
            width={width > this.realTableWidth ? width : this.realTableWidth}
            noRowsRenderer={this._renderNoRows}
          >
            {Columns.CreatorEmployee({})}
            {InfiniteTableColumn.Default({
              label: intl.get("payroll_period"),
              dataKey: "periodName",
              type: ApiObject.FieldType.text,
              width: 150,
              flexGrow: 0,
              flexShrink: 0,
            })}
            {InfiniteTableColumn.Default({
              label: intl.get("balance"),
              dataKey: "startingYtdBalance",
              type: ApiObject.FieldType.text,
              width: 90,
              flexGrow: 0,
              flexShrink: 0,
            })}
            {InfiniteTableColumn.Default({
              label: intl.get("taken"),
              dataKey: "startingYtdTaken",
              type: ApiObject.FieldType.text,
              width: 90,
              flexGrow: 0,
              flexShrink: 0,
            })}
            {InfiniteTableColumn.Default({
              label: intl.get("transitional_balance"),
              dataKey: "transitionalBalance",
              type: ApiObject.FieldType.text,
              width: 100,
              flexGrow: 0,
              flexShrink: 0,
            })}
            {InfiniteTableColumn.Default({
              label: intl.get("transitional_taken"),
              dataKey: "transitionalTaken",
              type: ApiObject.FieldType.text,
              width: 100,
              flexGrow: 0,
              flexShrink: 0,
            })}
            {InfiniteTableColumn.Default({
              label: intl.get(amountType),
              dataKey: "eligiblity",
              type: ApiObject.FieldType.text,
              width: 90,
              flexGrow: 0,
              flexShrink: 0,
            })}
            {Columns.Change({})}
            {InfiniteTableColumn.Default({
              label: intl.get("action"),
              dataKey: "action",
              type: ApiObject.FieldType.text,
              width: 250,
              flexGrow: 1,
              flexShrink: 0,
            })}
          </StyledInfiniteTable>
        )}
      </AutoSizer>
    );
  }

  private setRef = (ref: any) => {
    this.table = ref;
  };

  private _renderNoRows = () => {
    return (
      <NoRowsTable>
        <NoRowsWrapper>
          <span>{intl.get("no_details_found")}</span>
        </NoRowsWrapper>
      </NoRowsTable>
    );
  };

  private amountType() {
    const { amountType, hasBalance } = this.props.regulation;
    let currentType = "days_left";
    if (amountType && amountType === "hours") {
      currentType = "hours_left";
      if (!hasBalance) {
        currentType = "hours_taken";
      }
    }
    return currentType;
  }
}
const mapStateToProps = (state: RootState, ownProps: any) => ({
  orders: selectors.getOrders(state, ownProps),
  filters: selectors.getFilters(state, ownProps),
  tags: selectors.getTags(state, ownProps),
  isCollapsedMenu: rootSelectors.isMenuCollapsed(state),
});

const mapDispatchToProps = {
  changeOrder,
  changeFilter,
};

export default connect(mapStateToProps, mapDispatchToProps)(Table);
