import React, {PureComponent} from 'react';
import {intl} from '@global';
import styled from 'styled-components';

import {interfaces, stylingVariables} from '@global';

const StyledResetFilters = styled.span`
  position: relative;
  display: flex;
  align-items: center;
  font-size: ${stylingVariables.fontSize.medium};
  color: ${stylingVariables.colorPalette.deepGray};
  padding-right: 10px;
  margin-right: 20px;
  cursor: pointer;
  opacity: .7;
  &:hover {
    opacity: 1;
  }
`;

interface IProps {
    orders: interfaces.ListingOrders;
    filters: interfaces.ListingFilters;

    resetOrdersAndFilters: () => void;
}

export default class ClearFiltersAndSorts extends PureComponent<IProps> {
    private get countActiveFiltersAndOrders(): number {
        return Object.keys(this.props.orders).length + Object.keys(this.props.filters).length;
    }

    private get hasActiveFilters(): boolean {
        return this.countActiveFiltersAndOrders > 0;
    }

    private resetFilters = () => {
        this.props.resetOrdersAndFilters();
    }

    render() {
        if (!this.hasActiveFilters) {
            return null;
        }
        return (
            <StyledResetFilters onClick={this.resetFilters}>
                {intl.get('clear_filters_and_sorts')}
            </StyledResetFilters>
        );
    }
}
