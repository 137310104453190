import {ApiObject} from '@api';
import {api} from '@global';

export default class ApiReport {
    public static async list(legalEntityId: number, params: ReportQueryParams): Promise<{reports: ApiObject.Report[], totalCount: number}> {
        const { data } = await api.post(`/masterdata/public/legal-entity/${legalEntityId}/reports/listing`, params.params());

        if (!data.data) {
            return {reports: [], totalCount: 0};
        }

        return {
            reports: data.data.reports || [],
            totalCount: (data.data.meta || {total: data.data.reports.length}).total || 0,
        };
    }

    public static async findByIds(legalEntityId: number, ids: number[]) {
        if (ids.length === 0) {
            return [];
        }

        const { data } = await api.post(`/masterdata/public/legal-entity/${legalEntityId}/reports/listing`, {
            visibilities: [ApiObject.ReportVisibility.private, ApiObject.ReportVisibility.public],
            ids
        });

        return data.data ? data.data.reports || [] : [];
    }

    public static async findById(legalEntityId: number|null, id: number|null) {
        if (!legalEntityId || !id) {
            return;
        }
        const reports = await ApiReport.findByIds(legalEntityId, [id]);
        if (!reports || reports.length === 0) {
            return null;
        }

        return reports[0];
    }

    public static async deleteReport(legalEntityId: number, id: number) {
        return await api.delete(`/masterdata/public/legal-entity/${legalEntityId}/reports/${id}`);
    }

    public static async deleteReportTemplate(legalEntityId: number, id: number) {
        return await api.delete(`/masterdata/public/legal-entity/${legalEntityId}/reports/templates/${id}`);
    }

    public static async createReport(legalEntityId: number, report: ApiObject.Report) {
        const { data } = await api.post(`/masterdata/public/legal-entity/${legalEntityId}/reports`, {
            legalEntityPeriodIds: report.legalEntityPeriodIds,
            legalEntityReportType: report.legalEntityReportType || ApiObject.DataSource.payroll,
            templateId: report.templateId,
            visibility: report.visibility,
            isTemporary: report.isTemporary,
            regenerateMasterdata: report.regenerateMasterdata,
        } as ApiObject.CreateReport);

        return data.data ? data.data : null;
    }

    public static async editReport(legalEntityId: number, report: ApiObject.Report) {
        const { data } = await api.put(`/masterdata/public/legal-entity/${legalEntityId}/reports/${report.id}`, {
            visibility: report.visibility,
            isTemporary: report.isTemporary,
            label: report.label,
        } as ApiObject.EditReport);

        return data.data ? data.data : null;
    }

    public static async updateReportName(legalEntityId: number, report: ApiObject.Report, label: string) {
        const { data } = await api.put(`/masterdata/public/legal-entity/${legalEntityId}/reports/${report.id}`, {
            label
        });

        return data.data ? data.data : null;
    }

    public static async generateReport(legalEntityId: number, report: ApiObject.Report) {
        const { data } = await api.get(`/masterdata/public/legal-entity/${legalEntityId}/reports/${report.id}/json`);

        return data.data ? data.data : null;
    }

    public static async getReportXlsx(legalEntityId: number, id: number) {
        return await api.get(
            `/masterdata/public/legal-entity/${legalEntityId}/reports/${id}/xlsx`,
            {responseType: 'arraybuffer'},
        );
    }
}

interface RequestParams {
    createdBefore?: string|null;
    createdAfter?: string|null;
    ids?: number[]|null;
    visibilities?: ApiObject.ReportVisibility[]|null;
    statuses?: ApiObject.ReportStatus[]|null;
}

interface ReportQueryParamsInterface {
    year?: number|null;
    ids?: number[]|null;
    visibilities?: ApiObject.ReportVisibility[]|null;
    statuses?: ApiObject.ReportStatus[]|null;
}

export class ReportQueryParams {
    public year: number|null;
    public ids: number[];
    public visibilities: ApiObject.ReportVisibility[];
    public statuses: ApiObject.ReportStatus[];

    public constructor({
       year = null,
       ids,
       visibilities,
       statuses,
    }: ReportQueryParamsInterface = {}) {
        this.year = year;
        this.ids = ids || [];
        this.visibilities = visibilities || [];
        this.statuses = statuses || [];
    }

    public params(): RequestParams {
        const params: RequestParams = {
            statuses: this.statuses,
            visibilities: this.visibilities,
        };

        if (this.year) {
            params.createdAfter = `${this.year}-01-01`;
            params.createdBefore = `${this.year}-12-31`;
        }

        if (this.ids.length > 0) {
            params.ids = this.ids;
        }

        return params;
    }
}
