import {RouteHelper} from '@library';
import {AnyAction, Dispatch} from 'redux';
import {toast} from 'react-toastify';
import {ThunkAction} from 'redux-thunk';
import moment from 'moment';

import {constants, intl} from '@global';
import {
    ApiEmployee,
    EmployeeInterfaces,
    LeaveInterfaces,
    ApiObject,
    prepareErrors,
    PayElementQueryParams,
    ApiPayElement,
} from '@api';
import {Status} from '../../api/api.object';
import {RootState} from '../../rootReducer';
import {openErrorModal, startLoading, stopLoading} from '../redux';

import {
    SET_PERIOD,
    SET_PERIOD_LEAVE_TRANSACTIONS,
    SET_PERIOD_LEAVE_BALANCES, SET_TRANSITIONAL_LEAVE_BALANCES,
} from './Tabs/Leave/redux';
import * as selectors from './selectors';
import {SYSTEM_DATE_FORMAT} from '../../constants';

type ThunkResult<R> = ThunkAction<R, RootState, undefined, AnyAction>;

export const SET_FIELD_GROUPS = 'employee/setFieldGroups';
export const SET_EMPLOYEE_FIELDS = 'employee/setEmployeeFields';
export const SET_EMPLOYEE = 'employee/setEmployee';
export const SET_DOCUMENTS = 'employee/setDocuments';
export const SET_DOCUMENT_MODE = 'employee/setDocumentMode';
export const SET_DOCUMENT_ID = 'employee/setDocumentId';
export const OPEN_SALARY_MODAL = 'employee/openSalaryModal';
export const CLOSE_SALARY_MODAL = 'employee/closeSalaryModal';
export const SET_SALARY_PAYMENT_GROUP_MODAL = 'employee/setSalaryPaymentGroupModal';
export const ADD_SALARY_PAYMENT_GROUP = 'employee/addSalaryPaymentGroup';
export const SET_SALARY_MODAL_GROUPS = 'employee/setSalaryModalGroups';
export const SET_SALARY_MODAL_VALUES = 'employee/setSalaryModalValues';
export const SET_SALARY_TAB_TYPE = 'employee/setSalaryTabType';
export const SET_PAY_ELEMENTS = 'employee/setPayElements';
export const SET_EMPLOYEE_PAY_ELEMENTS = 'employee/setEmployeePayElements';
export const SET_PERIOD_PAY_ELEMENTS = 'employee/setPeriodPayElements';
export const SET_CURRENCIES = 'employee/setCurrencies';
export const SET_ERRORS_FIELDS = 'employee/setErrors/fields';
export const SET_LEAVE_MODAL_TRANSACTION = 'employee/setLeaveModalTransaction';
export const SET_IS_EMPLOYEE_MD_EDIT_ALLOWED = 'employee/setIsEmployeeMasterdataEditAllowed';
export const SET_COUNTRIES = 'employee/setCountries';
export const SET_BANK_DATA = 'employee/setBankData';
export const SET_USER_BANK_DETAILS = 'employee/setUserBankDetails';
export const SET_USER_BANK_DETAILS_ERRORS = 'employee/setUserBankDetailsErrors';
export const SET_EMPLOYEE_CARD_COLLAPSED = 'employee/setEmployeeCardCollapsed';
export const CLEAR_PAYMENT_PROCESSOR = 'employee/clearPaymentProcessor';
export const SET_PAYMENT_LOADING = 'employee/setPaymentLoading';
export const SET_EMPLOYEES_CUT_OFFS = "employee/setEmployeeCutOffs"

export interface EmployeesCutOffs {
    [key: string]: Object
}

export interface Fields {
    [entity: string]: {
        [code: string]: string|null,
    };
    computed: {
        fullName: string;
    };
}

export interface EmployeeDocumentState {
    id: string | null;
    mode: constants.Mode | null;
}

export interface SalaryState {
    tabType: SalaryTabType;
}

interface Errors {
    fields: ApiObject.EntityFieldErrorMessage[];
}

export interface EmployeeState {
    id: number | null;
    employee: EmployeeInterfaces.Employee | null;
    fields: ApiObject.FieldEntity[];
    fieldGroups: ApiObject.FieldGroup[];
    errors: Errors;
    documents: ApiObject.FileHandle[];
    document: EmployeeDocumentState;
    salary: SalaryState;
    salaryModal: {
        values: ApiObject.EmployeePayElement | undefined;
        payElementType: string | undefined,
        isModalOpen: boolean,
    };
    salaryPaymentGroupModal: {
        isModalOpen: boolean,
    };
    salaryGroups: string[];
    payElements: ApiObject.PayElement[];
    employeePayElements: ApiObject.EmployeePayElement[];
    periodPayElements: EmployeeInterfaces.PeriodWithPayElements[];
    currencies: string[];
    leave: {
        from: string;
        till: string;
        transactions: LeaveInterfaces.Transaction[];
        balancesByPeriod: LeaveInterfaces.PeriodEmployeeBalance[];
        transitionalBalances: LeaveInterfaces.TransitionalBalances;
        modalTransaction: LeaveInterfaces.Transaction[] | undefined;
    };
    employeeMasterdataEditAllowed: boolean;
    countries: ApiObject.ApiOption[];
    bankData: ApiObject.BankData;
    bankDetails: ApiObject.BankDetails;
    bankDetailsErrors: ApiObject.UserErrors[];
    employeeCardCollapsed: boolean;
    paymentLoading: boolean;
    cutOffStatuses?: EmployeesCutOffs
}

export enum SalaryTabType {
    all = 'all',
    recurrings = 'recurrings',
    one_times = 'one_times',
    units = 'units',
}

export const defaultState: EmployeeState = {
    id: null,

    employee: null,
    fieldGroups: [],
    fields: [],
    errors: {
        fields: [],
    },
    documents: [],
    document: {
        id: null,
        mode: null,
    },
    salary: {
        tabType: SalaryTabType.all,
    },
    salaryModal: {
        values: undefined,
        payElementType: undefined,
        isModalOpen: false,
    },
    salaryPaymentGroupModal: {
        isModalOpen: false,
    },
    salaryGroups: [],
    payElements: [],
    employeePayElements: [],
    periodPayElements: [],
    currencies: [],
    leave: {
        from: moment().startOf('year').format(),
        till: moment().endOf('year').format(),
        transactions: [],
        balancesByPeriod: [],
        modalTransaction: undefined,
        transitionalBalances: {},
    },
    employeeMasterdataEditAllowed: true,
    countries: [],
    bankData: {} as ApiObject.BankData,
    bankDetails: {} as ApiObject.BankDetails,
    bankDetailsErrors: [],
    employeeCardCollapsed: false,
    paymentLoading: false,
    cutOffStatuses: {}
};

export default function reducer(
    state: EmployeeState = defaultState,
    action: AnyAction,
): EmployeeState {
    switch (action.type) {
        case SET_FIELD_GROUPS:
            return {
                ...state,
                fieldGroups: action.payload,
            };
        case SET_EMPLOYEE_FIELDS:
            return {
                ...state,
                fields: action.payload,
            };
        case SET_EMPLOYEE:
            return {
                ...state,
                id: action.payload ? action.payload.id : null,
                employee: action.payload,
            };
        case SET_DOCUMENTS:
            return {
                ...state,
                documents: action.payload,
            };
        case SET_DOCUMENT_ID:
            return {
                ...state,
                document: {
                    ...state.document,
                    id: action.payload,
                },
            };
        case SET_DOCUMENT_MODE:
            return {
                ...state,
                document: {
                    ...state.document,
                    mode: action.payload,
                },
            };
        case OPEN_SALARY_MODAL:
            return {
                ...state,
                salaryModal: {...state.salaryModal, isModalOpen: true},
            };
        case CLOSE_SALARY_MODAL:
            return {
                ...state,
                salaryModal: {...state.salaryModal, isModalOpen: false},
                errors: {
                    fields: [],
                },
            };
        case SET_SALARY_PAYMENT_GROUP_MODAL:
            return {
                ...state,
                salaryPaymentGroupModal: {...state.salaryPaymentGroupModal, isModalOpen: action.payload},
            };
        case ADD_SALARY_PAYMENT_GROUP:
            return {
                ...state,
                salaryGroups: [...state.salaryGroups, action.payload],
            };
        case SET_SALARY_MODAL_GROUPS:
            return {
                ...state,
                salaryModal: {
                    ...state.salaryModal,
                    payElementType: action.payload.payElementType,
                },
            };
        case SET_SALARY_MODAL_VALUES:
            return {
                ...state,
                salaryModal: {...state.salaryModal, values: action.payload},
            };
        case SET_SALARY_TAB_TYPE:
            return {
                ...state,
                salary: {...state.salary, tabType: action.payload},
            };
        case SET_PERIOD:
            return {
                ...state,
                leave: {
                    ...state.leave,
                    from: action.payload.from,
                    till: action.payload.till,
                },
            };
        case SET_PERIOD_LEAVE_TRANSACTIONS:
            return {
                ...state,
                leave: {
                    ...state.leave,
                    transactions: action.payload,
                },
            };
        case SET_PERIOD_LEAVE_BALANCES:
            return {
                ...state,
                leave: {
                    ...state.leave,
                    balancesByPeriod: action.payload,
                },
            };
        case SET_TRANSITIONAL_LEAVE_BALANCES:
            return {
                ...state,
                leave: {
                    ...state.leave,
                    transitionalBalances: action.payload,
                },
            };
        case SET_ERRORS_FIELDS:
            return {
                ...state,
                errors: {
                    ...state.errors,
                    fields: action.payload,
                },
            };
        case SET_PAY_ELEMENTS:
            return {
                ...state,
                payElements: action.payload,
            };
        case SET_LEAVE_MODAL_TRANSACTION:
            return {
                ...state,
                leave: {
                    ...state.leave,
                    modalTransaction: action.payload,
                },
            };
        case SET_EMPLOYEE_PAY_ELEMENTS:
            return {
                ...state,
                employeePayElements: action.payload,
            };
        case SET_PERIOD_PAY_ELEMENTS:
            return {
                ...state,
                periodPayElements: action.payload,
            };
        case SET_CURRENCIES:
            return {
                ...state,
                currencies: action.payload,
            };
        case SET_IS_EMPLOYEE_MD_EDIT_ALLOWED:
            return {
                ...state,
                employeeMasterdataEditAllowed: action.payload,
            };
        case SET_COUNTRIES:
            return {
                ...state,
                countries: action.payload,
            };
        case SET_BANK_DATA:
            return {
                ...state,
                bankData: action.payload,
            };
        case SET_USER_BANK_DETAILS:
            return {
                ...state,
                bankDetails: action.payload,
            };
        case SET_USER_BANK_DETAILS_ERRORS:
            return {
                ...state,
                bankDetailsErrors: action.payload,
            };
        case SET_EMPLOYEE_CARD_COLLAPSED:
            return {
                ...state,
                employeeCardCollapsed: action.payload,
            };
        case CLEAR_PAYMENT_PROCESSOR:
            return {
                ...state,
                bankDetails: {} as ApiObject.BankDetails,
                bankDetailsErrors: [],
                bankData: {} as ApiObject.BankData
            };
        case SET_PAYMENT_LOADING:
            return {
                ...state,
                paymentLoading: action.payload,
            };
        case SET_EMPLOYEES_CUT_OFFS:
            return {
                ...state,
                cutOffStatuses: action.payload
            }
        default:
            return state;
    }
}

export function clear() {
    return (dispatch: Dispatch) => {
        dispatch(loadFieldGroups() as any);
        dispatch(setEmployee(null));
        dispatch(setEmployeeFields([]));
        dispatch(setDocuments([]));
        dispatch(closeDocumentModal() as any);
        dispatch(setEmployeeCutOffsStatuses(null))
        dispatch(setPayElements([]))
        dispatch(setEmployeePayElements([]))
        dispatch(setPeriodPayElements([]))
        return;
    };
}

export function getEmptyEmployeeForCreating() {
    return async(dispatch: Dispatch, getState: () => RootState) => {
        clear()
        dispatch(setEmployee(
            {
                index: 0,
                id: 0,
                referenceCode: '',
                legalEntityId: 0,
                fullName: '',
                person: {
                    primaryEmail: null,
                    firstName: null,
                    lastName: null,
                },
                position: null,
                hireDate: null,
                terminationStatus: null,
                status: Status.active,
            } as EmployeeInterfaces.Employee,
        ));
        return;
    };
}

export function getEmployee(id: number) {
    return async(dispatch: Dispatch, getState: () => RootState) => {
        const entityId = getState().global.currentLegalEntityId;
        if (!entityId) {
            return;
        }

        dispatch(startLoading());

        const [employee] = await ApiEmployee.findByIds(entityId, [id]);

        const { employeesCutoffStatuses } = await ApiEmployee.getEmployeeCutOffs(entityId, [id])
        
        dispatch(setEmployee(employee));
        dispatch(setEmployeeCutOffsStatuses({...getState().employees.cutOffStatuses, ...employeesCutoffStatuses}))
        dispatch(getEmployeeFields(id) as any);
        dispatch(getEmployeePayElements(id) as any);

        dispatch(stopLoading());

        return;
    };
}

export function getEmployeeFields(id: number) {
    return async(dispatch: Dispatch, getState: () => RootState) => {
        const entityId = getState().global.currentLegalEntityId;
        if (!entityId) {
            return;
        }

        dispatch(startLoading());

        const fields = await ApiEmployee.getEmployeeFields(entityId, id);

        dispatch(setEmployeeFields(fields));

        dispatch(stopLoading());

        return;
    };
}

export function getCurrentEmployeeFields() {
    return async(dispatch: Dispatch, getState: () => RootState) => {
        const employeeId = getState().employee.id;
        if (!employeeId) {
            return;
        }

        dispatch(getEmployeeFields(employeeId) as any);

        return;
    };
}

export function getEmployeePayElements(employeeId: number) {
    return async(dispatch: Dispatch, getState: () => RootState) => {
        const entityId = getState().global.currentLegalEntityId;
        if (!entityId) {
            return;
        }

        dispatch(startLoading());

        const payElementsResult = await ApiEmployee.getEmployeePayElements(entityId, employeeId);
        const employeePayElements = payElementsResult.data.employeePayElements[employeeId];
        dispatch(setEmployeePayElements(employeePayElements ?? defaultState.employeePayElements));

        dispatch(stopLoading());
        return;
    };
}

export function getFieldGroups(): ThunkResult<Promise<void>> {
    return async(dispatch: Dispatch, getState: () => RootState) => {
        const entityId = getState().global.currentLegalEntityId;
        if (!entityId) {
            return;
        }

        dispatch(startLoading());
        dispatch(
            setFieldGroups(await ApiEmployee.getFieldGroups(entityId)),
        );
        dispatch(stopLoading());

        return;
    };
}

export function loadFieldGroups(): ThunkResult<Promise<void>> {
    return async(dispatch: Dispatch, getState: () => RootState) => {
        let groups = getState().employee.fieldGroups;
        if (groups.length === 0) {
            const entityId = getState().global.currentLegalEntityId;
            if (!entityId) {
                return;
            }
            dispatch(startLoading());
            groups = await ApiEmployee.getFieldGroups(entityId);
            dispatch(setFieldGroups(groups));
            dispatch(stopLoading());
        }
        return;
    };
}

export function getPayElements(accounts?: number[]): ThunkResult<Promise<void>> {
    return async(dispatch: Dispatch, getState: () => RootState) => {
        const entityId = getState().global.currentLegalEntityId;
        if (!entityId) {
            return;
        }

        let payElements: ApiObject.PayElement[] = [];
        while (true) {
            const params = (typeof accounts === 'undefined')
                ? new PayElementQueryParams({offset: payElements.length})
                : new PayElementQueryParams({limit: accounts.length, accounts: accounts});
            const payElementsResult: ApiObject.PayElement[] = await ApiPayElement.list(entityId as number, params);
            payElements = payElements.concat(payElementsResult);
            dispatch(setPayElements(payElements));
            if (payElementsResult.length !== PayElementQueryParams.DEFAULT_LIMIT || typeof accounts !== 'undefined') {
                break;
            }
        }

        return;
    };
}

export function getPeriodPayElements(): ThunkResult<Promise<void>> {
    return async(dispatch: Dispatch, getState: () => RootState) => {
        const state = getState();
        const entityId = state.global.currentLegalEntityId;
        const payrollPeriod = state.global.currentPayrollPeriod;
        const employeeId = state.employee.employee?.id;
        if (!entityId || !employeeId) {
            return;
        }

        dispatch(startLoading());
        const endDate = payrollPeriod?.endDate ?? moment().format(SYSTEM_DATE_FORMAT);
        const beginDate = state.employee.employee?.hireDate
            ?? (payrollPeriod ? moment(payrollPeriod.beginDate).startOf('year').format(SYSTEM_DATE_FORMAT) : null)
            ?? moment().startOf('year').format(SYSTEM_DATE_FORMAT);
        const result = await ApiPayElement.getPeriodPayElements(entityId, employeeId, {
            endDate,
            beginDate,
        });
        dispatch(stopLoading());
    
        dispatch(setPeriodPayElements(result.periods.map(
            (period: any): EmployeeInterfaces.PeriodWithPayElements => {
                return {
                    id: period.id,
                    name: period.name,
                    beginDate: period.beginDate,
                    endDate: period.endDate,
                    payElements: period.payElementsByEmployee[employeeId],
                    periodPayrollListingVersion: period.periodPayrollListingVersion,
                };
            })
        ));
    };
}

export function getDocuments(): ThunkResult<Promise<void>> {
    return async(dispatch: Dispatch, getState: () => RootState) => {
        const entityId = getState().global.currentLegalEntityId;
        const employeeId = getState().employee.id;
        if (!entityId || !employeeId) {
            return;
        }
        dispatch(startLoading());
        dispatch(
            setDocuments(await ApiEmployee.getDocuments(entityId, employeeId)),
        );
        dispatch(stopLoading());

        return;
    };
}

export function createDocument(document: ApiObject.FileHandle): ThunkResult<Promise<void>> {
    return async(dispatch: Dispatch, getState: () => RootState) => {
        const entityId = getState().global.currentLegalEntityId;
        const employeeId = getState().employee.id;
        if (!entityId || !employeeId) {
            return;
        }

        dispatch(startLoading());
        const response = await ApiEmployee.saveDocument(entityId, employeeId, document);
        dispatch(stopLoading());

        if (response.status !== 200) {
            toast.error(intl.get('document_not_created'));
            return;
        }
        toast.success(intl.get('document_created_ok'));
        dispatch(getDocuments() as any);

        return;
    };
}

export function deleteDocument(id: string): ThunkResult<Promise<void>> {
    return async(dispatch: Dispatch, getState: () => RootState) => {
        const entityId = getState().global.currentLegalEntityId;
        const employeeId = getState().employee.id;
        if (!entityId || !employeeId) {
            return;
        }

        const response = await ApiEmployee.deleteDocument(entityId, employeeId, id);

        if (response.status === 200) {
            dispatch(getDocuments() as any);
            toast.success(intl.get('document_removed_ok'));
        } else {
            toast.error(intl.get('document_removed_nok'));
        }

        return;
    };
}

export function downloadDocument(id: string): ThunkResult<Promise<void>> {
    return async(dispatch: Dispatch, getState: () => RootState) => {
        const entityId = getState().global.currentLegalEntityId;
        const employeeId = getState().employee.id;
        if (!entityId || !employeeId) {
            return;
        }

        await ApiEmployee.downloadDocument(entityId, employeeId, id);

        return;
    };
}

export function clearErrorFields(): ThunkResult<Promise<void>> {
    return async(dispatch: Dispatch) => {
        dispatch(setErrorFields([]));
        return;
    };
}

export function saveFields(employeeId: number, forUpdateFields: any): ThunkResult<Promise<boolean>> {
    return async(dispatch: Dispatch, getState: () => RootState) => {
        const entityId = getState().global.currentLegalEntityId;
        if (!entityId) {
            return false;
        }
        dispatch(setErrorFields([]));
        try {
            dispatch(startLoading());

            if (!employeeId) {
                // Try to Create employee and get employee fields
                const createdEmployee = await ApiEmployee.createEmployeeFields(entityId, forUpdateFields);
                if(createdEmployee.errors){
                    dispatch(setErrorFields(createdEmployee.errors || []));
                    return false;
                }else if (createdEmployee.id) {
                    dispatch(setEmployee(createdEmployee));
                    dispatch(getEmployeeFields(createdEmployee.id) as any);
                    toast.success(intl.get('employee_created_ok'));
                    RouteHelper.goToEmployeePage(createdEmployee.id);
                }
            } else {
                const fields = await ApiEmployee.saveEmployeeFields(entityId, employeeId, forUpdateFields);
                if(fields.errors){
                    dispatch(setErrorFields(fields.errors || []));
                    return false;
                }else{
                    dispatch(setEmployeeFields(fields));
                    toast.success(intl.get('employee_fields_saved_ok'));
                }
            }
        } catch (e) {
            dispatch(setErrorFields(e.response?.data?.data || []));

            return false;
        } finally {
            dispatch(stopLoading());
        }

        return true;
    };
}

export function savePayElement(employeeId: number, employeePayElements: any[]) {
    return async(dispatch: Dispatch, getState: () => RootState) => {
        const state = getState();
        let errored = false
        const entityId = state.global.currentLegalEntityId;
        if (!entityId) {
            return false;
        }
        if (!state.global.authorizedUser) {
            return false;
        }
        const actorEmployeeId = state.global.authorizedUser.employee.id;

        dispatch(startLoading());

        let retroactiveAlert = false

        employeePayElements.forEach(element => {
            if (element.calculateRetroactiveAmount) {
                retroactiveAlert = true
            }
        })

        try {
            const result = await ApiEmployee.saveEmployeePayElement(entityId, employeeId, {
                source: 'mss',
                actorEmployeeId,
                employeePayElements,
            });
            if (!result.data) {
                throw result;
            }
            const returnedPayElement: ApiObject.EmployeePayElement = result.data.employeePayElements[0];
            dispatch(setEmployeePayElements(state.employee.employeePayElements
                .filter(element => element.rootEmployeePayElementId !== returnedPayElement.rootEmployeePayElementId)
                .concat([returnedPayElement])));
        } catch (e) {
            const errorMessage = e.errors.length ? e.errors[0].message : '';
            toast.error(intl.get('pay_element_save_nok', {errorMessage}));
            errored = true
        } finally {
            if (retroactiveAlert) {
                toast.success(intl.get('retroactive_calculating'));
            }
            dispatch(stopLoading());
        }

        return !errored;
    };
}

export function deletePayElement(employeeId: number, employeePayElements: any[]) {
    return async(dispatch: Dispatch, getState: () => RootState) => {
        const state = getState();
        const entityId = state.global.currentLegalEntityId;
        if (!entityId) {
            return false;
        }
        if (!state.global.authorizedUser) {
            return false;
        }
        const actorEmployeeId = state.global.authorizedUser.employee.id;
        dispatch(startLoading());
        try {
            const result = await ApiEmployee.deleteEmployeePayElement(entityId, employeeId, {
                source: 'mss',
                actorEmployeeId,
                employeePayElements,
            });
            if (!result.data?.ok) {
                throw result;
            }

            dispatch(setEmployeePayElements(state.employee.employeePayElements.map(element => {
               const changes = element.changes.filter(element => element.id !== employeePayElements[0].changeId);
               return {...element, changes};
            })));
        } catch (e) {
            const errorMessage = e.errors.length ? e.errors[0].message : '';
            toast.error(intl.get('pay_element_delete_nok', {errorMessage}));
        } finally {
            dispatch(stopLoading());
        }

        return true;
    };
}


export function createDocumentModal(): ThunkResult<Promise<void>> {
    return async(dispatch: Dispatch) => {
        dispatch(setDocumentMode(constants.Mode.Create));

        return;
    };
}

export function removeDocumentModal(id: string): ThunkResult<void> {
    return (dispatch: Dispatch) => {
        dispatch(setDocumentId(id) as any);
        dispatch(setDocumentMode(constants.Mode.Delete));

        return;
    };
}

export function closeDocumentModal(): ThunkResult<void> {
    return (dispatch: Dispatch) => {
        dispatch(setDocumentId(null) as any);
        dispatch(setDocumentMode(null));

        return;
    };
}

export function activate(id: number): ThunkResult<Promise<boolean>> {
    return async(dispatch: Dispatch, getState: () => RootState) => {
        const currentLegalEntityId = getState().global.currentLegalEntityId;
        if (!currentLegalEntityId) {
            return false;
        }

        dispatch(startLoading());

        const errors = await ApiEmployee.activate(currentLegalEntityId, new Set([id]));
        if (errors.length === 0) {
            dispatch(setEmployee(
                {
                    ...selectors.getEmployee(getState()) as EmployeeInterfaces.Employee,
                    status: ApiObject.Status.active,
                },
            ));

            toast.success(intl.get('active_employee_success'));
        } else {
            const error = selectors.prepareActivationErrors(getState(), errors);
            dispatch(
                openErrorModal(error.messages, error.title),
            );
        }

        dispatch(stopLoading());

        return errors.length === 0;
    };
}

export function suspend(id: number): ThunkResult<Promise<boolean>> {
    return async(dispatch: Dispatch, getState: () => RootState) => {
        const currentLegalEntityId = getState().global.currentLegalEntityId;
        if (!currentLegalEntityId) {
            return false;
        }

        dispatch(startLoading());

        await ApiEmployee.suspend(currentLegalEntityId, id);
        dispatch(setEmployee(
            {
                ...selectors.getEmployee(getState()) as EmployeeInterfaces.Employee,
                status: ApiObject.Status.inactive,
            },
        ));

        toast.success(intl.get('suspend_employee_success'));
        dispatch(stopLoading());

        return true;
    };
}

export function sendEssInvitation(payload: {
    welcomeMessage: string, employees: { id: number, loginUsername: string },
}) {
    return async(dispatch: Dispatch, getState: () => RootState) => {
        const currentLegalEntityId = getState().global.currentLegalEntityId;
        if (!currentLegalEntityId) {
            return;
        }

        dispatch(startLoading());

        try {
            const response = await ApiEmployee.sendEssInvitation(currentLegalEntityId, payload);
            if (response.data) {
                dispatch(setEmployee(
                    {
                        ...selectors.getEmployee(getState()) as EmployeeInterfaces.Employee,
                        essStatus: EmployeeInterfaces.EssStatus.invitation_sent,
                    },
                ));

                toast.success(intl.get('send_ess_invitation_success'));
            }

        } catch (e) {
            const errors = prepareErrors(e.response.data.errors);
            dispatch(
                openErrorModal(errors, intl.get('send_ess_invitation_failed')),
            );
        }

        dispatch(stopLoading());

        return;
    };
}

export function revokeEssAccess(id: number) {
    return async(dispatch: Dispatch, getState: () => RootState) => {
        const currentLegalEntityId = getState().global.currentLegalEntityId;
        if (!currentLegalEntityId) {
            return;
        }

        dispatch(startLoading());

        await ApiEmployee.revokeEssAccess(currentLegalEntityId, new Set([id]));
        dispatch(setEmployee(
            {
                ...selectors.getEmployee(getState()) as EmployeeInterfaces.Employee,
                essStatus: null,
            },
        ));
        toast.success(intl.get('revoke_ess_success'));

        dispatch(stopLoading());

        return;
    };
}

export function createUserForPaymentProcessor(id: number): ThunkResult<Promise<ApiObject.BankAccount>> {
    return async(dispatch: Dispatch, getState: () => RootState) => {
        const currentLegalEntityId = getState().global.currentLegalEntityId;
        if (!currentLegalEntityId) {
            return;
        }

        dispatch(setPaymentLoading(true));

        const response = await ApiEmployee.createUserForPaymentProcessor(currentLegalEntityId, id);

        if (response.bankDetails) {
            dispatch(setUserBankDetails(response.bankDetails))
        }
        dispatch(setPaymentLoading(false));
        return response;
    };
}

export function getCountriesAndCurrencies(): ThunkResult<Promise<object>> {
    return async(dispatch: Dispatch, getState: () => RootState) => {
        const currentLegalEntityId = getState().global.currentLegalEntityId;
        if (!currentLegalEntityId) {
            return;
        }

        dispatch(setPaymentLoading(true));

        const response = await ApiEmployee.getCountriesAndCurrencies();

        dispatch(setCountries(response));

        dispatch(setPaymentLoading(false));
        return response;
    };
}

export function getFieldsForBankAccount(id: number, params: object): ThunkResult<Promise<object>> {
    return async(dispatch: Dispatch, getState: () => RootState) => {
        const currentLegalEntityId = getState().global.currentLegalEntityId;
        if (!currentLegalEntityId) {
            return;
        }
        dispatch(setPaymentLoading(true));

        try {
            const response = await ApiEmployee.getFieldsForBankAccount(currentLegalEntityId, id, params);

            if (!response || !response?.fields || response?.errors) {
                toast.error(intl.get('failed_to_load_bank_data'));
                return false;
            } else {
                dispatch(setBankData(response));
                return response;
            }
        } catch (e) {
            toast.error(intl.get('failed_to_load_bank_data'));
            return false;
        } finally {
            dispatch(setPaymentLoading(false));
        }
    };
}

export function createBankAccount(id: number, bankAccountFields: ApiObject.BankAccountField[]): ThunkResult<Promise<boolean>> {
    return async(dispatch: Dispatch, getState: () => RootState) => {
        const currentLegalEntityId = getState().global.currentLegalEntityId;
        if (!currentLegalEntityId) {
            return;
        }

        dispatch(setPaymentLoading(true));
        const { data, errors } = await 
        ApiEmployee.createBankAccount(currentLegalEntityId, id, bankAccountFields);
        dispatch(setPaymentLoading(false));
        
        if (data?.bankDetails) {
            dispatch(setUserBankDetails(data.bankDetails))
            toast.success(intl.get('bank_account_created'));
            return data;
        } else {
            dispatch(setUserBankDetailsErrors(errors))
            return false
        }
    };
}

export function getBankAccount(id: number): ThunkResult<Promise<ApiObject.BankAccount>> {
    return async(dispatch: Dispatch, getState: () => RootState) => {
        const currentLegalEntityId = getState().global.currentLegalEntityId;
        if (!currentLegalEntityId) {
            return;
        }

        dispatch(setPaymentLoading(true));

        const response = await ApiEmployee.getBankAccount(currentLegalEntityId, id);

        if (response.bankDetails) {
            dispatch(setUserBankDetails(response.bankDetails))
        }
        dispatch(setPaymentLoading(false));

        return response;
    };
}

export function setFieldGroups(fieldGroups: ApiObject.FieldGroup[]): AnyAction {
    return {
        type: SET_FIELD_GROUPS,
        payload: fieldGroups,
    };
}

export function setEmployeeFields(fields: ApiObject.FieldEntity[]): AnyAction {
    return {
        type: SET_EMPLOYEE_FIELDS,
        payload: fields,
    };
}

export function setEmployee(employee: EmployeeInterfaces.Employee|null): AnyAction {
    return {
        type: SET_EMPLOYEE,
        payload: employee,
    };
}

export function setDocuments(documents: ApiObject.FileHandle[]): AnyAction {
    return {
        type: SET_DOCUMENTS,
        payload: documents,
    };
}

export function setDocumentId(id: string|null): AnyAction {
    return {
        type: SET_DOCUMENT_ID,
        payload: id,
    };
}

export function setDocumentMode(mode: constants.Mode | null): AnyAction {
    return {
        type: SET_DOCUMENT_MODE,
        payload: mode,
    };
}

export function openSalaryModal(): AnyAction {
    return {
        type: OPEN_SALARY_MODAL,
    };
}

export function closeSalaryModal(): AnyAction {
    return {
        type: CLOSE_SALARY_MODAL,
    };
}

export function openSalaryPaymentGroupModal(): AnyAction {
    return {
        type: SET_SALARY_PAYMENT_GROUP_MODAL,
        payload: true,
    };
}

export function closeSalaryPaymentGroupModal(): AnyAction {
    return {
        type: SET_SALARY_PAYMENT_GROUP_MODAL,
        payload: false,
    };
}

export function addSalaryPaymentGroup(group: string): AnyAction {
    return {
        type: ADD_SALARY_PAYMENT_GROUP,
        payload: group,
    };
}

export function setSalaryModalGroups(payElementType: string | undefined): AnyAction {
    return {
        type: SET_SALARY_MODAL_GROUPS,
        payload: {
            payElementType,
        },
    };
}

export function setSalaryModalValues(values: ApiObject.EmployeePayElement | undefined): AnyAction {
    return {
        type: SET_SALARY_MODAL_VALUES,
        payload: values,
    };
}

export function setSalaryTabType(type: SalaryTabType): AnyAction {
    return {
        type: SET_SALARY_TAB_TYPE,
        payload: type,
    };
}

export function setErrorFields(errors: ApiObject.EntityFieldErrorMessage[]): AnyAction {
    return {
        type: SET_ERRORS_FIELDS,
        payload: errors,
    };
}

export function setPayElements(payElements: ApiObject.PayElement[]): AnyAction {
    return {
        type: SET_PAY_ELEMENTS,
        payload: payElements,
    };
}

export function openLeaveTransactionModal(transactions: LeaveInterfaces.Transaction[]) {
    return {
        type: SET_LEAVE_MODAL_TRANSACTION,
        payload: transactions,
    };
}

export function closeLeaveTransactionModal() {
    return {
        type: SET_LEAVE_MODAL_TRANSACTION,
        payload: undefined,
    };
}

export function setEmployeePayElements(employeePayElements: ApiObject.EmployeePayElement[]): AnyAction {
    return {
        type: SET_EMPLOYEE_PAY_ELEMENTS,
        payload: employeePayElements,
    };
}

export function setPeriodPayElements(periodPayElements: EmployeeInterfaces.PeriodWithPayElements[]): AnyAction {
    return {
        type: SET_PERIOD_PAY_ELEMENTS,
        payload: periodPayElements,
    };
}

export function setCurrencies(currencies: string[]): AnyAction {
    return {
        type: SET_CURRENCIES,
        payload: currencies,
    };
}

export function setIsEmployeeMasterdataEditAllowed(isAllowed: boolean): AnyAction {
    return {
        type: SET_IS_EMPLOYEE_MD_EDIT_ALLOWED,
        payload: isAllowed,
    };
}

export function setCountries(countries: ApiObject.ApiOption[]): AnyAction {
    return {
        type: SET_COUNTRIES,
        payload: countries,
    };
}

export function setBankData(bankData: ApiObject.BankData): AnyAction {
    return {
        type: SET_BANK_DATA,
        payload: bankData,
    };
}

export function setUserBankDetails(bankDetails: ApiObject.BankDetails): AnyAction {
    return {
        type: SET_USER_BANK_DETAILS,
        payload: bankDetails,
    };
}

export function setUserBankDetailsErrors(errors: ApiObject.UserErrors): AnyAction {
    return {
        type: SET_USER_BANK_DETAILS_ERRORS,
        payload: errors,
    };
}

export function setEmployeeCardCollapsed(collapsed: boolean): AnyAction {
    return {
        type: SET_EMPLOYEE_CARD_COLLAPSED,
        payload: collapsed,
    };
}

export function clearPaymentProcessor(): AnyAction {
    return {
        type: CLEAR_PAYMENT_PROCESSOR,
    };
}

export function setPaymentLoading(value: boolean): AnyAction {
    return {
        type: SET_PAYMENT_LOADING,
        payload: value
    };
}

export function setEmployeeCutOffsStatuses(employeesCutoffStatuses: EmployeesCutOffs|null): AnyAction {
    return {
        type: SET_EMPLOYEES_CUT_OFFS,
        payload: employeesCutoffStatuses,
    };
}
