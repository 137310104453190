import React from 'react';
import {ApiObject} from '@api';
import { Column } from 'react-virtualized';

import { defaultInfiniteTableCellDataGetter } from '@common';
import { get } from 'lodash';
import styled from 'styled-components';
import { DateHelper } from '@library';
import {constants, stylingVariables} from '@global';
import moment from "moment";

interface DefaultColumnProps {
  dataKey: string;
  label: string;
  width?: number;
  flexGrow?: number;
  type?: ApiObject.FieldType;
}

const CellWrapper = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
`

const Cell = styled.div<{borderBottom?: boolean}>`
  display: flex;
  height: 100%;
  align-items: center;
  position: relative;
  &::after {
    content: '';
    display: block;
    position: absolute;
    left: -20px;
    right: -20px;
    bottom: 0;
    height: 1px;
    background-color: ${({borderBottom}) => borderBottom && stylingVariables.colorPalette.lightestGray};
  }
`

const DoubleColumnDate: React.FC<DefaultColumnProps> = ({
  dataKey,
  label,
  width = 80,
  flexGrow = 1,
}: DefaultColumnProps) => (
  <Column
    label={label}
    dataKey={dataKey}
    width={width}
    style={{ justifyContent: 'flex-end' }}
    flexGrow={flexGrow}
    cellDataGetter={defaultInfiniteTableCellDataGetter}
    headerRenderer={() => (
      <div style={{ textAlign: 'right' }}>{label}</div>
    )}
    cellRenderer={({ cellData }: any) => {

      let overridedValue = null;
      const value = DateHelper.convertDateToHumanFormat(
        get(cellData, dataKey, null),
      );

      const hasChanges = cellData.changes && cellData.changes.length > 0

      let latestValue = null
      let latestChanges = null
      if (hasChanges) {
        latestChanges = cellData.changes.reduce((a: any, b: any) => (a.startDate > b.startDate ? a : b));
        latestValue = DateHelper.convertDateToHumanFormat(
          get(latestChanges, dataKey, null),
        );
      }

      if(dataKey === 'endDate'){
        const latestChangesStartDate = get(latestChanges, 'startDate', null);
        if(latestChangesStartDate){
          overridedValue = DateHelper.convertDateToHumanFormat(moment(latestChangesStartDate, constants.SYSTEM_DATE_FORMAT).subtract(1, "days"))
        }
      }

      return (
        <CellWrapper>
          <Cell borderBottom={hasChanges}>
            {overridedValue || value}
          </Cell>
          {hasChanges && (
            <Cell>{latestValue}</Cell>
          )}
        </CellWrapper>
      )
    }}
  />
);

export default DoubleColumnDate;
