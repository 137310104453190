const spanish = {
  mercans: 'Mercans',
  term_of_use: 'Condiciones de uso',
  sla: 'SLA',
  privacy_policy: 'Política de privacidad',

  admin_console: 'Consola de administración',
  secure_file_transfer: 'Transferencia segura de archivos',
  payslips: 'Nóminas',
  my_information: 'Mi información',
  my_team: 'Mi equipo',
  my_apps: 'Mis aplicaciones',
  apply_for_leave: 'Solicite una licencia',
  add_leave_transaction: "Agregar 'Licencia'...",
  add_and_approve: 'Añadir y aprobar',
  cannot_approve_payroll_processing: 'La nómina está procesando, no puede aprobar las solicitudes en este momento',
  enter_my_time_and_attendance_data: 'Introduzca mis datos de tiempo y asistencia',
  help_and_support: 'Ayuda y apoyo',
  select_entity: 'Seleccione una entidad en la que trabajar',
  back_to_launchpad: 'Volver al Lanzador',
  workflow: 'Flujo de trabajo',

  employees: 'Empleados',
  no_employees_found: 'No se encontraron empleados',
  no_leaves_found: 'Actualmente no hay ningún permiso',
  no_transactions: 'Actualmente no hay transacciones',
  name: 'Nombre',
  pay_unit: 'Unidad de pago',
  amount: 'Cantidad',
  currency: 'Moneda',
  no_details_found: 'Actualmente no hay detalles',
  ess_access: 'Acceso a la ESS',
  add_or_remove_columns: 'Agregar o quitar columnas',
  columns_selected: 'Columnas seleccionadas',
  unselected_columns: 'Columnas no seleccionadas',
  filter_by: 'Filtrar por',
  apply: 'Aplique',
  reset: 'Reiniciar',
  filter_by_employee_header: 'Filtrar por empleado y por identificación',
  filter_by_employee: 'Por empleado',
  filter_by_tags: 'Por las etiquetas',
  employee: 'Empleado',
  add_employee: 'Añadir empleado',
  add_employee_s: 'Añadir empleado(s)',
  save_and_next: 'Guardar y siguiente',
  save_and_add: 'Guardar y añadir',
  import_employee_data_from_excel: 'Importar los datos de los empleados desde Excel',
  employee_separated: 'Empleado Separado',
  advanced_settings: 'Configuración avanzada',
  separation_no_pay_elements: 'No se han establecido elementos de pago por terminacion',
  view_saved_separations: 'Ver Separaciones guardadas',
  insert_separation: 'Insertar la separación',
  send_ess_invitation: 'Envíe la invitación a la ESS',
  send_ess_invitation_success: '¡Las invitaciones de la ESS han sido enviadas con éxito!',
  send_ess_invitation_failed: 'Falló al enviar la invitación a la ESS...',
  revoke: 'Revoca',
  revoke_ess_access: 'Revocar el acceso a la ESS',
  revoke_ess_access_confirmation: '¿Estás seguro de que quieres revocar el acceso a la ESS?',
  revoke_ess_success: '¡El acceso a la ESS ha sido revocado con éxito!',
  insert_vacation: 'Insertar Vacaciones',
  insert_expense: 'Inserte gasto',
  insert_one_time_payment: 'Insertar un pago único',
  insert_absence: 'Insertar la ausencia',
  reason: 'Razón',
  annual_total: 'Total anual',
  before: 'Antes de',
  after: 'Después de',

  payrolls: 'Nóminas',
  payroll: 'Nómina',
  new_payroll: 'Nueva nómina',
  current: 'Actual',
  previous: 'Anterior',
  open: 'Abrir',
  input: 'Entrada',
  preinput: 'Corregir la entrada',
  processing: 'Procesando',
  authorization_form: 'Formulario de autorización',
  calculated: 'Calculado',
  calculating: 'Calculando',
  cancelled: 'Cancelado',
  cancelling: 'Cancelando',
  close: 'Cerrar',
  closed: 'Cerrado',
  closing: 'Cerrando',
  generating: 'Generando',
  invalid: 'Inválido',
  add_terminations: 'Agregar las terminaciones',
  pending_termination: 'Pendiente de terminación',
  starters: 'Entradas',
  starter: 'Inicio',
  leavers: 'Salidas',
  leaver: 'Dejar',
  add_new_hire: 'Agregar una nueva contratación',
  hire_date: 'Fecha de contratación',
  last_working_date: 'Última fecha de trabajo',
  review_and_approve_changed_inputs: 'Revisar y aprobar los cambios en las entradas',
  approve_and_continue: 'Aprobar y continuar',

  active: 'Activo',
  active_employee_success: '¡El empleado ha sido activado con éxito!',
  active_employees_success: '¡Los empleados han sido activados con éxito!',
  activate_employee_failed: 'No se ha activado el empleado {nombre}',
  activate_employees_failed: 'No se activaron los empleados',
  inactive: 'Inactivo',
  draft: 'Borrador',

  leaves: 'Licencias',
  leave_transactions: 'Transacciones de Licencias',
  leave_history_not_loaded: 'El historial de licencia no fue cargado',
  leave_details: 'Detalles de licencia',
  leave_balance: 'Saldo de Licencia',
  leave_balances: 'Saldos de Licencias',
  balances_details: 'Detalles de los saldos',
  edit_log: 'Editar el registro',
  leave_balances_in_year: 'Saldo de licencias {año}',
  leave_balance_changed: 'Cambios saldos de licencia',
  new_period_balances_set: '{período} - Nuevos saldos',
  leave_balance_transaction: 'La transacción de licencia se hizo',
  open_ended_leave: 'Licencia por tiempo indefinido',
  open_ended: 'Open-Ended',
  new_leave: 'Nuevo permiso',
  new_leave_transaction: 'Nueva transacción de licencia',
  edit_leave: 'Editar Licencia',
  upcoming: 'Próximamente',
  view_details_history: 'Ver detalles e historia',
  leave_type: 'Tipo de Licencia',
  leave_days: 'Dias de Licencias',
  selected_days: 'Días seleccionados',
  requested_leave_time: 'Solicitó un tiempo de licencia',
  balance: 'Saldos',
  transactions: 'Transacciones',
  entry_date_and_user: 'Fecha de entrada y usuario',
  before_transaction: 'Antes de la transacción',
  after_transaction: 'Después de la transacción',
  calendar_days_left: 'Dejar dias calendario',
  calendar_days_taken: 'Dias calendarios tomados',
  business_days_left: 'Quedan días hábiles',
  business_days_taken: 'Días hábiles tomados',
  leave_days_taken_in_year: 'Dias de licencias tomados en {año}',
  view_annual_balance: 'Ver el balance anual',
  view_current_balance: 'Ver el balance actual',
  employees_on_leave: 'Empleados en licencia',
  approve_leave_transaction_confirmation: '¿Está seguro de que quiere aprobar la solicitud de licencia?',
  reject_leave_transaction_confirmation: '¿Está seguro de que quiere rechazar la solicitud de licencia?',
  approve_all_leave_transactions_confirmation:
    '¿Está seguro de que quiere aprobar todas las solicitudes de licencia en este grupo?',
  reject_all_leave_transactions_confirmation:
    '¿Estás seguro de que quieres rechazar todas las solicitudes de licencias de este grupo?',
  used: 'usado',
  taken: 'tomado',
  left: 'dejado',
  invalid_adjustments_present: 'Hay ajustes de balance inválidos ({Tipos de licencia}) en otras pestañas',
  edit_balance: 'Editar el balance',
  edit_balances: 'Editar los balances',
  view_employee_card: 'Ver tarjeta de empleado',
  in_: 'en ',
  as_of_: 'a partir de ',
  days_left: 'Quedan días...',
  current_balance: 'Balance actual',
  add_or_remove_days: 'Añadir o quitar días',
  new_balance: 'Nuevo saldo',
  calendar_days: 'Dias  calendario',
  workdays: 'Días de trabajo',
  reject_all: 'Rechazar todo',
  view_details: 'Ver detalles',
  close_details: 'Cerrar detalles',
  postpone: 'Posponer',
  expand_row: 'Ampliar fila',
  transaction: 'Transacción',
  this_month: 'Este mes',
  this_year: 'Este año',
  payment_schedule: 'Calendario de pagos',

  view: 'Vista',
  approve: 'Aprobar',
  approve_all: 'Aprobar todos',
  reject: 'Rechazar',
  import: 'Importar',
  export: 'Exportar',
  dates: 'Fechas',
  status: 'Estado',
  pending: 'Pendiente',
  open_ended_pending: 'Pendiente de ser abierto...',
  activate: 'Activar',
  suspend: 'Suspender',
  suspend_employee_success: '¡El empleado ha sido suspendido con éxito!',
  suspend_employees_success: '¡Los empleados han sido suspendidos con éxito!',
  activate_confirmation: '¿Estás seguro de que quieres activarlo?',
  suspend_confirmation: '¿Está seguro de que quiere suspender?',
  terminate: 'Terminar',
  repayment: 'Reembolso',
  submitted: 'Presentado',
  rejected: 'Rechazado',
  done: 'Hecho',
  approved: 'Aprobado',
  waiting_for_approval: 'Esperando la aprobación',
  send: 'Enviar',

  select_all: 'Seleccione todo...',
  select: 'Seleccionar',

  edit: 'Editar',
  sign_out: 'Firme la salida.',
  settings: 'Ajustes',
  back: 'Volver',
  save: 'Guardar',
  cancel: 'Cancelar',
  contact: 'Contacte con',
  history: 'Historia',
  taken_this_year: 'Tomada este año',
  future: 'Futuro',
  attachments: 'Anexos',

  total: 'Total',
  total_annual: 'Total Anual',
  request: 'Solicitud',
  requests: 'Solicitudes',
  view_rejected_transaction: 'Ver la solicitud rechazada',
  close_view: 'Cerrar la vista',
  new: 'Nuevo',
  on: 'En',
  add: 'Agregar',
  add_file: 'Agregar archivo',
  new_file: 'Nuevo archivo',
  days: 'días',
  Days: 'Días',
  for: 'para',
  not_found: 'No se encuentra',

  start: 'Comenzar',
  end: 'Fin',
  to: 'a',
  select_date: 'Seleccione la fecha',
  load_more: 'Cargar más',
  select_days: 'Seleccione los días',
  ID: 'ID',
  clear_filters_and_sorts: 'Limpiar los filtros y clasificar',
  new_design: 'Nuevo diseño',

  documents: 'Documentos',
  new_document: 'Nuevo documento',
  remove_document: 'Quitar el documento',
  compensations: 'Compensaciones',
  display_name: 'Nombre de pantalla',
  description: 'Descripción',
  visible_in_ess: 'Visible en la ESS',
  file: 'Archivo',
  size: 'Tamaño',
  upload_date: 'Fecha de carga',
  remove_document_confirmation: '¿Estás seguro de que quieres quitar el documento?',
  no_document_found: 'No se ha encontrado ningún documento.',
  drop_here: 'Déjese caer aquí.',
  remove: 'Retirar',
  delete: 'Eliminar',
  added_this_month: 'añadido este mes',
  no: 'No',
  yes: 'Sí',

  last_activity: 'Última actividad',

  termination_date: 'Fecha de terminación',
  choose: 'Elija...',
  employee_group: 'Grupo de empleados',
  dates_transaction_count: 'Fechas / Recuento de transacciones',
  source: 'Fuente',
  leaveTypeName: 'Tipo de licencia',
  payroll_period: 'Período de pago',

  recurring_earnings: 'Pagos recurrentes',
  recurring_deductions: 'Deducciones recurrentes',
  recurring_units: 'Unidades recurrentes',
  additional_earnings: 'Pagos adicionales',
  additional_deductions: 'Deducciones adicionales',
  additional_units: 'Unidades adicionales',
  warning: '¡Advertencia!',
  amount_and_date_empty: 'Las deducciones recurrentes deben tener una fecha de finalización o una cantidad objetivo',
  recurring_deductions_save_confirmation:
    ' Las deducciones recurrentes deben tener una fecha de finalización o una cantidad objetivo. ¿Estás seguro de que quieres guardarlo?',
  pay_element_change_delete_confirmation: '¿Estás seguro de que quieres borrar este cambio de elemento de pago?',

  view_history: 'Ver el historial',
  close_history: 'Cerrar la historia',
  view_future: 'Cambios pendientes',
  close_future: 'Cerrar los cambios pendientes',
  add_pay_element_type: 'Añadir el grupo de elementos de pago',
  pay_element_type: 'Tipo de elemento de pago',

  all: 'Todos',
  recurrings: 'Recurrencias',
  one_times: 'Una vez',
  units: 'Unidades',

  expand_all: 'Expandir todo',
  collapse_all: 'Colapsar todo',
  percentage_symbol: '%',
  values: 'Valores',

  dependents: 'Dependientes',
  dependent: 'Dependiente',
  add_dependent: 'Añadir dependiente',
  edit_dependent: 'Editar dependiente',
  delete_dependent: 'Borrar dependiente',
  delete_dependent_confirmation: '¿Estás seguro de que quieres eliminar a los dependientes?',
  no_dependents_found: 'No se han encontrado dependientes',

  employee_fields_saved: '¡Los campos de los empleados han sido guardados con éxito!',
  employee_fields_not_saved: 'No se han guardado los campos de los empleados',

  dependent_deleted: '¡El dependiente ha sido borrado con éxito!',
  dependent_not_deleted: 'No se ha borrado el dependiente',

  cookie_disabled_message:
    'Las cookies se bloquean o no son compatibles con su navegador. Debe habilitar las cookies para el correcto funcionamiento de la aplicación.',
  no_employees: 'No hay empleados',

  reports: 'Informes',
  report_templates: 'Plantillas de informes',
  personal: 'Personal',
  common: 'Común',
  search: 'Busca en',
  report_template: 'Plantilla de informe',
  report_status: 'Estado del informe',
  report_generation_date: 'Fecha de generación del informe',
  report_name: 'Nombre del informe',
  user: 'Usuario',
  no_reports_found: 'No se han encontrado informes',
  template_name: 'Nombre de la plantilla',
  view_template: 'Ver plantilla',
  edit_template: 'Plantilla de edición',
  generate_report: 'Generar Informe',
  no_report_templates_found: 'No se han encontrado plantillas de informes',
  create_new_report: 'Crear un nuevo informe',
  report_from_existing_template: 'Crear un informe a partir de una plantilla existente',
  continue: 'Continúa',
  date_range: 'Rango de fechas',
  select_active: 'Seleccione Activo',
  select_inactive: 'Seleccione Inactivo',
  select_draft: 'Seleccione el borrador',
  manual_employee_select: ' O seleccionar los empleados manualmente y continuar',
  delete_report: 'Borrar el informe',
  delete_report_confirmation: '¿Estás seguro de que quieres borrar este informe?',
  delete_template: 'Eliminar la plantilla',
  delete_template_confirmation: '¿Está seguro de que quiere eliminar esta plantilla de informe?',

  january: 'Enero',
  february: 'Febrero',
  march: 'Marzo',
  april: 'Abril',
  may: 'Mayo',
  june: 'Junio',
  july: 'Julio',
  august: 'Agosto',
  september: 'Septiembre',
  october: 'Octubre',
  november: 'Noviembre',
  december: 'Diciembre',

  selected_employees: 'Empleados seleccionados',
  available_employees: 'Empleados disponibles',

  add_field: 'Agregar campo',
  add_group: 'Agregar grupo',
  quick_adding: 'Añadir rápidamente...',
  group_name: 'Nombre del grupo',

  save_and_generate_report: 'Guardar y generar el informe',
  save_as_a_template: 'Guardar como una plantilla',
  update_template: 'Actualizar Plantilla',
  select_data_fields_to_report: 'Seleccione los campos de datos a Reportar',
  not_all_employees_with_this_status_have_been_selected: 'No todos los empleados con este estatus han sido seleccionados',
  preview_report: 'Informe preliminar',
  save_and_generate: 'Guardar y Generar',

  report_date_range: 'Informe Rango de fechas',
  report_date: 'Fecha del informe',
  you: 'Usted',

  new_report: 'Nuevo informe',
  edit_report: 'Editar el informe',
  update_report: 'Actulizar el informe',
  view_report: 'Ver informe',
  new_report_template: 'Nueva plantilla de informe',
  new_template: 'Nueva plantilla',
  edit_report_template: 'Editar plantilla de informe',
  update_report_template: 'Actualizar Plantilla de Reporte',
  view_report_template: 'Ver plantilla de informe',
  save_as: 'Guardar como...',
  report_not_generated: 'Lo siento, el informe no fue generado',
  please_try_again: 'Por favor, inténtelo de nuevo.',
  mandatory_field: 'Este es un campo obligatorio',
  data_source: 'Fuente de datos',
  data_source_explanation:
    'Los datos de la nómina incluirán las cantidades que se procesaron en la nómina (valores prorrateados, valores calculados, etc.). La opción de tarjeta de empleado incluirá los importes que se han guardado en la tarjeta de empleado.',

  master_data: 'Datos maestros',
  employee_id: 'Identificación del empleado',
  payroll_calculation: 'Cálculo de la nómina',
  employee_record: 'Registro de empleados',
  pay_element: 'Elemento de pago',

  created_by_user: 'Creado por el usuario',
  visibility: 'Visibilidad',
  back_to_edit: 'Volver a la edición',

  add_account: 'Agregar cuenta',

  employee_and_id: 'Empleado e identificación',
  bulk_actions: 'Acciones Masivas',
  out_of: 'Fuera de',
  email_address_for_employee: 'Dirección de correo electrónico de ',
  enter_employee_email: 'Introduzca la dirección de correo electrónico del empleado...',
  welcome_message: 'Mensaje de bienvenida',
  enter_welcome_message: 'Escriba el mensaje de bienvenida',
  no_attachment: 'No hay archivo adjunto',
  log: 'Registro',
  rejected_transactions: 'Solicitudes rechazadas',
  pending_transactions: 'Solicitudes pendientes',
  id_and_name: 'ID y nombre',
  actions: 'Acciones',
  select_employees: 'Seleccionar los empleados...',
  start_dropping_employees: 'Empieza a dejar empleados aquí',
  unselect_employees: 'Empleados no seleccionados',
  company: 'Compañía',
  help: 'Ayuda',
  templates: 'Plantillas',
  calendar: 'Calendario',

  start_typing_to_filter: 'Empieza a escribir para filtrar',
  position: 'Posición',
  hireDate: 'Fecha de contratación',
  currency_frequency: 'Moneda / Frecuencia',
  annual: 'Anual',

  per_amount: 'cantidad',
  per_annual: 'anual',
  quarter: 'trimestre',
  month: 'mes',
  biweek: 'quincena',
  week: 'semana',
  day: 'día',
  hour: 'hora',
  unit: 'unidad',
  percentage: 'porcentaje',

  choose_and_drop: 'Elija un archivo o arrástrelo aquí',
  balance_taken: 'Saldo/tomado',
  change: 'Cambiar',
  action: 'Acción',
  comment: 'Comentario',
  date: 'Fecha',
  trans: ' trans',
  D: 'D',
  balances: 'Saldos',
  days_taken: 'días tomados',
  leave_a_comment_reason: 'Por favor, deje un comentario o describa una razón para la actualización',
  sth_went_wrong_redirect: 'Algo salió mal. Será redirigido en 5 segundos',
  sth_went_wrong: 'Algo salió mal.',
  no_employees_selected: 'No hay empleados seleccionados',
  column_settings_saved_ok: '¡Los ajustes de la columna se han guardado con éxito!',
  document_not_created: 'El documento no ha sido creado',
  document_created_ok: '¡El documento ha sido creado con éxito!',
  document_removed_ok: '¡El documento ha sido eliminado con éxito!',
  document_removed_nok: 'El documento no ha sido eliminado.',
  employee_created_ok: '¡Los empleados han sido creados con éxito!',
  employee_fields_saved_ok: '¡Los campos de los empleados han sido guardados con éxito!',
  employee_fields_nok: 'No se han guardado los campos de los empleados',
  pay_element_save_nok: 'No se salvó el elemento de pago: {errorMensaje}',
  pay_element_delete_nok: 'No se ha eliminado el elemento de pago: {errorMessage}',
  leave_transaction_created_ok: 'La transacción de salida ha sido creada con éxito!',
  leave_transaction_created_nok: 'No se ha añadido Leave Transaction',
  leave_transaction_created_approved_ok: 'La transacción de licencia ha sido creada y aprobada con éxito!',
  leave_transaction_updated_ok: 'Leave Transaction ha sido actualizado con éxito!',
  leave_transaction_update_nok: 'No se actualizó Leave Transaction',
  leave_transaction_approved_ok: '¡La solicitud de permiso ha sido aprobada con éxito!',
  leave_transaction_rejected_ok: '¡La solicitud de permiso ha sido rechazada con éxito!',

  separation_sent_ok: '¡La separación ha sido enviada con éxito!',
  separation_sent_nok: 'No se ha podido salvar la separación...',
  oops_sth_went_wrong: '¡Uy! ¡Algo salió mal!',
  leave_balance_update_ok: '¡El balance de licencia ha sido actualizado con éxito!',
  leave_balance_update_nok: 'No se ha actualizado el balance de vacaciones.',
  report_template_created_ok: '¡La plantilla de informe ha sido creada con éxito!',
  report_template_created_nok: '¡La plantilla del informe no ha sido creada con éxito!',
  report_update_warning: 'No puede actualizar esta plantilla de informe porque no es el propietario',
  report_template_update_ok: '¡La plantilla de informe ha sido actualizada con éxito!',
  report_template_update_nok: '¡La plantilla de informe no ha sido actualizada!',
  report_doesnt_exist: 'El informe no existe.',
  report_template_not_set: 'La plantilla no ha sido establecida',
  report_save_error: 'Se produjo un error al guardar el informe',
  report_not_created: 'El informe no ha sido creado',
  report_created: '¡El informe ha sido creado!',
  report_updated_ok: '¡El informe ha sido actualizado con éxito!',
  report_updated_nok: '¡El informe no ha sido actualizado!',
  report_generated_nok: 'El informe no ha sido generado. Por favor, inténtelo más tarde',
  template_def_columns_required: 'Se requieren grupos de columnas de definición de plantillas. Por favor, compruebe el formulario',
  report_removed_ok: '¡El informe ha sido eliminado con éxito!',
  report_removed_nok: 'El informe no ha sido eliminado.',
  report_template_removed_ok: '¡La plantilla del informe ha sido eliminada con éxito!',
  report_template_removed_nok: 'La plantilla del informe no ha sido eliminada.',
  unknown: 'Desconocido',
  new_employee: 'Nuevo empleado',

  getting_legal_entities: 'Obtener entidades legales',
  getting_consolidation_groups: 'Obtener grupos de consolidación',
  user_auth: 'Autorización del usuario',
  getting_user_photo: 'Obtención de la foto del usuario',
  getting_company_logo: 'Obtener el logo de la compañía',
  getting_current_period_info: 'Obtener información del período actual',
  ongoing: 'En curso',
  by: 'por',
  created_at: 'Creado en',
  d: 'd',
  days_have_been_used: 'se han utilizado los días',
  days_totel: 'días en total',
  process_details: 'Detalles del proceso',
  choose_value: 'Elija el valor',
  custom: 'Personalizado',
  field_cannot_be_empty: 'El campo no puede estar vacío',

  calculate_retroactive: '¿Calcular el elemento retroactivo?',
  retroactive_calculating: 'Se está calculando el importe retroactivo.',
  you_need_to_set_effective_date: 'Es necesario establecer una nueva fecha de entrada en vigor para el cambio de elemento salarial.',
  periodic_amount: 'Importe periódico',
  start_date: 'Fecha de inicio',
  effective_date: 'Fecha de entrada en vigencia',
  end_date: 'Fecha de finalización',
  goal_amount: 'Cantidad del objetivo',
  gross_up: 'Aumentar el Importe bruto',
  partial_period_treatment: 'Tratamiento de período parcial',
  lump_sum: 'Suma Fija',
  pro_rata: 'Prorrateo',

  payroll_admin_console: 'Consola de administración de la nómina',
  employee_self_service: 'Autoservicio del empleado',
  manager_self_service: 'Consola de administración de nóminas',

  h: 'h',
  hours_left: 'horas restantes',
  hours_taken: 'horas tomadas',
  search_employee: 'Busca en Empleado',

  report_modal_title: 'Elija el informe que desea generar',
  employee_master_data_report: 'Informe de datos maestros de los empleados',
  employee_master_data_report_exp: 'Reports generated based on employee master data',
  payroll_results_report: 'Informe de resultados de las nóminas',
  payroll_results_report_exp: 'Informe generado basado en datos maestros de los empleados',
  report_generated_ok: 'El Informe ha sido generado exitosamente!',
  report_not_error: 'El informe no se generó a tiempo, por favor intente actualizar la página en un par de minutos',
  no_active_period_for_entity: 'La entidad seleccionada no tiene un periodo de nómina activo, las acciones son limitadas.',
  edit_mode: 'Modo edicion',
  report_draft: 'borrador',
  report_generating: 'generando',
  report_generated: 'generado',
  report_errored: 'errored',
  your_file_to_upload_or_browse: 'Su archivo para subir o navegar',
  drag_and_drop: 'Arrastrar y soltar',
  updated: 'Updated',
  regenerate_masterdata: 'Regenerate Masterdata',
  bank_not_found: 'Provided bank details do not match with configured system banks',

  organization: 'Organization',
  country: 'Country',
  total_headcount: 'Total headcount',
  report: 'Report',
  period: 'Period',
  OK: 'OK',
  pay_element_has_error: '',
  show_currency: 'Show currency',
}

export default spanish
