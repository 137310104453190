import React from 'react';
import styled from 'styled-components';

const StyledRow = styled.div<any>`
  overflow: visible!important;
  cursor: default;
`;

interface RowProps {
    selectedIds: Set<number>;
}

export const Row = ({selectedIds}: RowProps) => ({ className, columns, key, style, rowData}: any) => (
    <StyledRow
        className={selectedIds.has(rowData.id) ? `${className} highlighted` : className}
        key={key}
        role='row'
        style={style}
    >
        {columns}
    </StyledRow>
);
