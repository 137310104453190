import {AxiosInstance} from 'axios';
import {EnvironmentHelper} from '@library';
import {store} from '@global'

export default class ApiProvider {
    private axios: AxiosInstance;

    constructor(provider: AxiosInstance) {
        this.axios = provider;
    }

    public async logErrors(e: {
        message: string;
        response: any;
    },                     url: string, method?: string, data?: {}) {

        if (e.response?.status === 422) {
            throw e;
        }
        const api = url.split('/');
        await this.sendLog(e, url, method, api && api.length > 2 ? api[1] : 'mss-api', data);
        console.error(e);
    }

    private async addLogData(data: any) {
        const authorizedUser = await store.getState().global.authorizedUser;
        if (!authorizedUser) {
            return false;
        }

        data.userData = {
            employee: authorizedUser.employee.id,
            legalEntityId: authorizedUser.employee.legalEntity.id,
            legalEntityName: authorizedUser.employee.legalEntity.legalName,
        }
    }

    public async sendLog(e: {
        message: string;
        stack?: string;
    }, url: string, method?: string, service?: string, data?: {}) {
        if (!EnvironmentHelper.isDevelopmentMode()) {
            const logData = { ...data }
            await this.addLogData(logData)

            await this.axios.post(
                EnvironmentHelper.getHookUrl(),
                {
                    message: e.message,
                    stacktrace: e.stack,
                    user: EnvironmentHelper.getServiceName(),
                    url: url,
                    service,
                    method,
                    data: JSON.stringify(logData || null),
                    callerLocation: window.location.href,
                }
            );
        }
    }

    public async get(url: string, config?: object): Promise<any> {
        let response;
        try {
            response = await this.axios.get(url, config);
        } catch (e) {
            await this.logErrors(e, url, 'GET');
        }
        return response;
    }

    public async post(url: string, data?: any, config?: object): Promise<any> {
        let response;
        try {
            response = await this.axios.post(url, data, config);
        } catch (e) {
            await this.logErrors(e, url, 'POST', data);
        }
        return response;
    }

    public async put(url: string, data?: any, config?: object): Promise<any> {
        let response;
        try {
            response = await this.axios.put(url, data, config);
        } catch (e) {
            await this.logErrors(e, url, 'PUT', data);
        }
        return response;
    }

    public async patch(url: string, data?: any, config?: object): Promise<any> {
        let response;
        try {
            response = await this.axios.patch(url, data, config);
        } catch (e) {
            await this.logErrors(e, url, 'PATCH', data);
        }
        return response;
    }

    public async delete(url: string, config?: object): Promise<any> {
        let response;
        try {
            response = await this.axios.delete(url, config);
        } catch (e) {
            await this.logErrors(e, url, 'DELETE');
        }
        return response;
    }
}
