import React, {PureComponent} from 'react';
import {intl} from '@global';
import {connect} from 'react-redux';

import {Modal as CommonModal} from '@common';
import {stylingVariables} from '@global';
import {RootState} from '../../../../../rootReducer';
import styled from 'styled-components';

import {deleteReportTemplate} from '../../redux'


const ModalButtons = styled.div`
    border-top: solid 1px ${stylingVariables.colorPalette.darkGray};
    padding: 30px 20px 0;
    display: flex;
    justify-content: flex-end;

    button {
        margin-left: 30px;
    }
`;

export enum ModalCloseReason {
    ModalCloseAction,
    SaveAction,
}

interface IProps {
    isOpen: boolean;
    reportId: number;
    onClose: (closeReason?: ModalCloseReason) => any;
    onAfterDelete: () => any;
    deleteReportTemplate: (reportId: number) => void;
}

interface IState {
    isOpenModal: boolean;
}

export class Modal extends PureComponent<IProps, IState> {
    static defaultProps = {
        onAfterDelete: () => {}
    }

    public constructor(props: IProps) {
        super(props);

        this.state = {
            isOpenModal: props.isOpen,
        };
    }

    render() {
        return (
            <CommonModal.CenterModal width={'550px'} minWidth={'550px'}
                onClickClose={() => this.closeModal(ModalCloseReason.ModalCloseAction)}
            >
                <CommonModal.StyledHeader color={stylingVariables.colorPalette.darkest}>
                    <h4>{intl.get('delete_template')}</h4>
                </CommonModal.StyledHeader>

                <CommonModal.OverflowableContent style={{padding: '20px 40px'}}>
                    {intl.get('delete_template_confirmation')}
                </CommonModal.OverflowableContent>

                <ModalButtons className='modalButtons'>
                    <CommonModal.StyledWhiteButton onClick={() => this.closeModal(ModalCloseReason.ModalCloseAction)}>
                        {intl.get('cancel')}
                    </CommonModal.StyledWhiteButton>

                    <CommonModal.StyledRejectButton onClick={this.onConfirm}>
                        {intl.get('delete')}
                    </CommonModal.StyledRejectButton>
                </ModalButtons>

            </CommonModal.CenterModal>
        );
    }

    private onConfirm = async() => {
        await this.props.deleteReportTemplate(this.props.reportId);
        this.props.onAfterDelete();
        this.props.onClose(ModalCloseReason.SaveAction)
    }

    private closeModal = (closeReason?: ModalCloseReason) => {
        this.setState({isOpenModal: false});
        this.props.onClose(closeReason);
    }
}

const mapStateToProps = (state: RootState) => ({
});

const mapDispatchToProps = {
    deleteReportTemplate
};

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
