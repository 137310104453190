import React, {PureComponent} from 'react';
import {Column} from 'react-virtualized';
import styled from 'styled-components';

import {constants, interfaces, stylingVariables} from '@global';
import {LeaveInterfaces} from '@api';
import { defaultInfiniteTableCellDataGetter, Icon} from '@common';

const StyledEmployeeIcon = styled.div`
  position: absolute;
  top: 25%;
  left: -15px;
  background: white;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledEmployeeIconWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  svg {
    cursor: pointer;
    pointer-events: none;
  }
`;

const StyledNameWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
`;

const InformationTabWrapper = styled.div`
  position: relative;
`;

const InformationTab = styled.span<{colourStyles: any}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  background: ${props => props.colourStyles.color};
  min-width: 180px;
  min-height: 50px;
  border: 1px solid ${stylingVariables.colorPalette.lightGray};
  box-shadow: 0 0 10px 1 ${stylingVariables.colorPalette.darkGray};
  color: ${props => props.colourStyles.text};

  transform: translateY(-50%);
  left: 20px;

  position: absolute;
  z-index: 5;
  border-radius: 5px;
  padding: 13px 18px;
  vertical-align: top;

  :after {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-right-color: ${props => props.colourStyles.color};
    border-left: 0;
    margin-top: -10px;
    margin-left: -10px;
  }
`;

interface CellProps {
    orders?: interfaces.ListingOrders;
    changeOrder?: (column: string) => any;
    creatorEmployee?: LeaveInterfaces.CreatorEmployee;
}

interface CellState {
    creatorInfoVisible: boolean;
}

class Cell extends PureComponent<CellProps, CellState> {
    public constructor(props: CellProps) {
        super(props);

        this.state = {
            creatorInfoVisible: false,
        };
    }

    private toggleAdditionalInfo = (e: any) => {
        this.setState({creatorInfoVisible: !this.state.creatorInfoVisible});
    }

    public render() {

        const creatorColor = {
            color: stylingVariables.colorPalette.gray,
        };
        return (
            <StyledNameWrapper>
                {this.props.creatorEmployee &&
                    <StyledEmployeeIcon>
                        <StyledEmployeeIconWrapper
                          onMouseOver={this.toggleAdditionalInfo}
                          onMouseLeave={this.toggleAdditionalInfo}
                        >
                          <Icon
                            type={constants.IconTypes.SUPPORT}
                            width={20}
                            height={20}
                          />
                            {this.state.creatorInfoVisible ?
                                <InformationTabWrapper>
                                    <InformationTab colourStyles={creatorColor}>
                                        {this.props.creatorEmployee.firstName} {this.props.creatorEmployee.lastName}
                                    </InformationTab>
                                </InformationTabWrapper>
                                : null
                            }
                        </StyledEmployeeIconWrapper>
                    </StyledEmployeeIcon>
                }
            </StyledNameWrapper>
        );
    }
}

const StyledHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const CreatorEmployee: React.FC = () => (
    <Column
        dataKey='creatorEmployee'
        style={{overflow: 'visible'}}
        width={20}
        flexGrow={0}
        flexShrink={0}
        cellDataGetter={defaultInfiniteTableCellDataGetter}
        headerRenderer={() => (
            <StyledHeaderWrapper />
        )}
        cellRenderer={({cellData}) => (
            <Cell
                creatorEmployee={cellData.creatorEmployee}
            />
        )}
    />
);
export default CreatorEmployee;
