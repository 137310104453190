import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';

import {stylingVariables} from '@global';
import {Modal as CommonModal} from '@common';

import * as selectors from './selectors';
import {closeErrorModal} from './redux';
import {RootState} from '../rootReducer';

const StyledErrorsWrapper = styled.ul`
  padding: 20px 20px 50px;
  overflow: auto;
  max-height: 500px;
  li {
      font-size: ${stylingVariables.fontSize.mediumLarge};
      line-height: 1.1rem;
      padding: 15px 20px;
      border-bottom: 1px solid ${stylingVariables.colorPalette.gray};
      &:last-child {
        border-bottom: 0;
      }
    }
`;

interface ModalProps {
    isOpen: boolean;
    title: string|null;
    errors: string[];

    closeErrorModal: () => void;
}

export class ErrorModal extends PureComponent<ModalProps> {
    public render() {
        if (!this.props.isOpen) {
            return null;
        }

        return (
            <CommonModal.CenterModal
                shouldSupportCloseWhenClickOutside={false}
                onClickClose={this.props.closeErrorModal}
            >
                <CommonModal.StyledHeader color={stylingVariables.colorPalette.orange}>
                    <h4>{this.props.title}</h4>
                </CommonModal.StyledHeader>

                <StyledErrorsWrapper>
                    {this.props.errors.map((error, index) => <li key={index}>{error}</li>)}
                </StyledErrorsWrapper>
            </CommonModal.CenterModal>
        );
    }
}

const mapStateToProps = (state: RootState) => ({
    ...selectors.getErrorModalProps(state),
});

const mapDispatchToProps = {
    closeErrorModal,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ErrorModal);
