import React, {PureComponent} from 'react';
import {Route, Router, Switch} from 'react-router';

import {history, constants} from '@global';
import {ApiObject} from '@api';
import {AccessManager} from '@library';

import Entities from './Entities/Entities';
import Employees from './Employees/Employees';
import Employee from './Employee/Employee';
import Leaves from './Leaves/Leaves';
import ReportsRoutes from './Reports/Routes';
import Empty from './Empty';

interface RouteSchema {
    path: string;
    component: any;
    exact?: boolean;
}

interface IProps {
    currentLegalEntity: ApiObject.LegalEntity|null;
}

interface IState {
    routes: RouteSchema[];
}

export default class Routes extends PureComponent<IProps, IState> {
    public constructor(props: IProps) {
        super(props);

        this.state = {
            routes: [],
        };
    }

    static getDerivedStateFromProps(props: IProps) {
        const routes: RouteSchema[] = [
            {
                path: constants.ROUTE_ENTITIES,
                component: Entities,
            },
            {
                path: constants.ROUTE_EMPLOYEES,
                component: Employees,
                exact: true,
            },
            {
                path: constants.ROUTE_EMPLOYEE,
                component: Employee,
            },
            {
                path: constants.ROUTE_PAYROLL_FLOW,
                component: Empty,
            },
            {
                path: constants.ROUTE_REPORTS,
                component: ReportsRoutes,
            },
            {
                path: constants.ROUTE_DOCUMENTS,
                component: Empty,
            },
            {
                path: constants.ROUTE_HELP,
                component: Empty,
            }
        ];

        if (AccessManager.hasModule(props.currentLegalEntity, constants.Modules.pac_leave)) {
            routes.push({path: constants.ROUTE_LEAVES, component: Leaves});
        }
        return {routes};
    }

    public render() {
        return (
            <Router history={history}>
                <Switch>
                    {this.state.routes.map(route =>
                        <Route
                            key={route.path}
                            exact={route.exact ?? false}
                            path={route.path}
                            component={route.component}
                        />,
                    )}
                </Switch>
            </Router>
        );
    }
}
