import {ApiObject} from '@api';
import {interfaces} from '@global';
import {createSelector} from 'reselect';
import {getReportTemplatesState} from '../selectors';
import {ReportTemplatesState} from './redux';

export const getTemplates = createSelector(
    [getReportTemplatesState],
    (state: ReportTemplatesState): ApiObject.ReportTemplate[] => {
        return state.templates.map((report, index) => {
            return {...report, index};
        })
    }
);
export const getFilters = createSelector(
    [getReportTemplatesState],
    (state: ReportTemplatesState): interfaces.ListingFilters => state.filters
);
export const getOrders = createSelector(
    [getReportTemplatesState],
    (state: ReportTemplatesState): interfaces.ListingOrders => state.orders
);

