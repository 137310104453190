import {interfaces, stylingVariables} from '@global';
import React, {PureComponent} from 'react';
import {AutoSizer} from 'react-virtualized';
import {connect} from 'react-redux';
import {RouteHelper} from '@library';
import {intl} from '@global';

import {
    ApiEmployee,
    LeaveInterfaces,
    EmployeeInterfaces,
    EmployeeQueryParams,
    PayrollInterfaces,
} from '@api';

import {
    StyledInfiniteTable as CommonStyledInfiniteTable,
    InfiniteTableColumn,
    InfiniteTableNoRows,
    InfiniteTableLoader,
} from '@common';

import {
    changeFilter,
    setTags,
    getEmployees,
    getLeaveTransactions,
    openLeaveTransactionModal,
    changeOrder,
} from '../../redux';

import * as selectors from '../../selectors';
import * as globalSelectors from '../../../selectors';
import {RootState} from '../../../../rootReducer';
import styled from 'styled-components';

import * as Column from './Column';

const StyledInfiniteTable = styled(CommonStyledInfiniteTable)`
    .ReactVirtualized__Table__headerRow{
        .ReactVirtualized__Table__headerColumn:first-child {
            text-align: center;
        }
        .ReactVirtualized__Table__headerColumn {
            padding: 5px 10px;
        }
        .ReactVirtualized__Table__headerColumn:nth-last-child(2) {
            border-right: none;
        }
    }

    .ReactVirtualized__Table__row{
        .ReactVirtualized__Table__rowColumn {
            padding: 5px 10px;
            height: 100%;
        }
    }
`;

const RowWrapper = styled.div`
    padding: 5px 0;

    .RowWrapper__Container {
        display: flex;
        cursor: pointer;
        height: 100%;
        width: 100%;
        border: 1px solid ${stylingVariables.colorPalette.white};
        border-radius: 5px;

        &.selected {
            border-color: ${stylingVariables.colorPalette.primary};
        }

        &:hover {
            border-color: ${stylingVariables.colorPalette.primaryHover};
        }
    }
`;

interface IProps {
    isLoading: boolean;
    isMenuCollapsed: boolean;
    currentLegalEntityId: number | null;
    currentPayrollPeriod: PayrollInterfaces.PayrollPeriod|null;
    showPayrollPeriod?: boolean;

    changeFilter: (params: interfaces.FilterParams) => any;
    setTags: (tags: EmployeeInterfaces.Tag[]) => void;
    getEmployees: (offset: number, limit?: number) => void;
    getLeaveTypes: () => void;
    getLeaveTransactions: () => void;
    openLeaveTransactionModal: (modalState: LeaveInterfaces.TransactionModal) => void;
    changeOrder: (column: string) => void;

    employeeLeaves: LeaveInterfaces.EmployeeLeave[];
    employeeIds: number[];
    totalCount: number;
    orders: interfaces.ListingOrders;
    filters: interfaces.ListingFilters;
    tags: EmployeeInterfaces.Tag[];
    leaveTypes: LeaveInterfaces.Type[];
    leaveRows: LeaveInterfaces.EmployeeLeaveHistoryGrouped[];
    selectedTransaction: LeaveInterfaces.Transaction | undefined;
}

interface IState {
    rows: LeaveInterfaces.EmployeeLeaveHistoryGrouped[];
    order: number;
    openRows: number[];
}

export class Table extends PureComponent<IProps, IState> {
    private table: any;

    public constructor(props: IProps) {
        super(props);

        this.state = {
            rows: this.props.leaveRows,
            order: 1,
            openRows: [],
        };
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>) {
        if (this.props.leaveRows.length !== prevProps.leaveRows.length ||
            (this.props.leaveRows.length &&  JSON.stringify(prevProps.leaveRows)
                !== JSON.stringify(this.props.leaveRows))
        ) {
            this.setState({ rows: this.props.leaveRows}, this.table.recomputeRowHeights());
        }
    }

    public render() {
        return (
            <AutoSizer>
                {({width, height}) => (
                    <StyledInfiniteTable
                        ref={this.setRef}
                        rowHeight={this.getRowHeight}
                        headerHeight={40}
                        rowGetter={({ index }: {index: number}) => this.state.rows[index]}
                        rowRenderer={({ columns, key, style, className, rowData }: any ) => (
                            <RowWrapper key={key} style={style} className={className}>
                                <div
                                    className={`RowWrapper__Container ${this.props.selectedTransaction === rowData.transactions[0] ? 'selected' : ''}`}
                                    onClick={() => this.openModal(rowData.employee, rowData.transactions)}
                                >
                                    {columns}
                                </div>
                            </RowWrapper>
                        )}
                        rowCount={this.state.rows.length}
                        height={height - 180}
                        width={width > 1000 ? width : 1000}
                        isMenuCollapsed={this.props.isMenuCollapsed}
                        noRowsRenderer={this._renderNoRows}
                    >
                        {InfiniteTableColumn.Default({
                            label: '#',
                            dataKey: 'index',
                            flexGrow: 0,
                            width: 70,
                            style: {justifyContent: 'center'}
                        })}

                        {
                            Column.Employee({
                                currentPeriod: this.props.currentPayrollPeriod,

                                filters: this.props.filters,
                                orders: this.props.orders,
                                tags: this.props.tags,

                                changeFilter: this.onChangeFilter,
                                changeOrder: this.onChangeOrder,
                                setTags: this.props.setTags,
                                onClickLink: this.redirectToEmployeePage,

                                getEmployees: this.getEmployeesForFilter,
                            })
                        }
                        {Column.LeaveType({
                            orders: this.props.orders,
                            changeOrder: this.onChangeOrder,
                        })}

                        {Column.Dates({
                            openLeaveTransactionModal: this.props.openLeaveTransactionModal,
                            toggleRowHeight: this.toggleRowHeight,
                            openRows: this.state.openRows,
                        })}
                        {Column.TransactionType({})}
                        {this.props.showPayrollPeriod
                            ? InfiniteTableColumn.Default({
                                label: intl.get('payroll_period'),
                                dataKey: 'transactions[0].period.periodName',
                                width: 150,
                            })
                            : null
                        }
                        {Column.Actions()}
                    </StyledInfiniteTable>
                )}
            </AutoSizer>
        );
    }

    private setRef = (ref: any) => {
        this.table = ref;
    }

    private toggleRowHeight = (index: number) => {
        this.setState(
            ({ openRows }) => ({
                openRows: openRows.includes(index)
                    ? openRows.filter(tallIndex => tallIndex !== index)
                    : [...openRows, index],
            }),
            () => this.table.recomputeRowHeights(index),
        );
    }

    private getRowHeight = ({ index }: {index: number}) => {
        const groupedTransaction = this.state.rows[index].transactions.length > 1;
        const openLength = (this.state.rows[index].transactions.length * 50) + 70;

        return this.state.openRows.includes(index) && groupedTransaction ? openLength : 80;
    }

    private _renderNoRows = () => {
        if (this.props.isLoading) {
            return (<InfiniteTableLoader/>);
        }

        return (
            <InfiniteTableNoRows text={intl.get('no_employees_found')}/>
        );
    }

    private openModal = (employee: LeaveInterfaces.EmployeeLeave, transactions: LeaveInterfaces.Transaction[]) => {
        return this.props.openLeaveTransactionModal({
            employee,
            transactions,
        });
    }

    private reIndex(data: any) {
        const output: LeaveInterfaces.EmployeeLeaveHistoryGrouped[] = [];
        data.forEach((trans: any, index: number) => {
            trans.index = index + 1;
            output.push(trans);
        });
        return output;
    }

    private sortColumn(column: string, order: number, key: string) {
        const sortOrder = order;
        return (a: any, b: any) => {
            const result = (a[key][column] < b[key][column]) ? -1 : (a[key][column] > b[key][column]) ? 1 : 0;
            this.setState({
                openRows: [],
            }, () => this.table.recomputeRowHeights());
            return result * sortOrder;
        };
    }

    private onChangeOrder = (column: string) => {
        const key = column === 'id' ? 'employee' : 'transactionInfo';

        const { rows, order } = this.state;
        let newRows = [...rows];
        const newOrder = order * -1;
        newRows = this.reIndex(newRows.sort(this.sortColumn(column, newOrder, key)));
        this.setState({ rows: newRows, order: newOrder });
        if (column !== 'leaveTypeName') {
            this.props.changeOrder(column);
        }
    }

    private onChangeFilter = (params: interfaces.FilterParams) => {
        this.props.changeFilter(params);
        this.props.getLeaveTransactions();
    }

    private getEmployeesForFilter = async(
        query: string, tags: EmployeeInterfaces.Tag[], limit: number, page: number
    ) => {
        if (!this.props.currentLegalEntityId) {
            return [];
        }

        const params = new EmployeeQueryParams({
            tags,
            currentPeriod: this.props.currentPayrollPeriod,
            employeeIds: this.props.employeeIds,
        });
        return await ApiEmployee.findByQueryWithPage(
            this.props.currentLegalEntityId,
            query,
            limit,
            page,
            params.params(),
            true,
        );
    }

    private redirectToEmployeePage(event: any, id: number) {
        event.stopPropagation();
        RouteHelper.goToEmployeePage(id);
    }
}

const mapStateToProps = (state: RootState, ownProps: any) => ({
    isLoading: globalSelectors.isLoading(state),
    isMenuCollapsed: globalSelectors.isMenuCollapsed(state),
    currentLegalEntityId: globalSelectors.getCurrentLegalEntityId(state),
    currentPayrollPeriod: globalSelectors.getCurrentPayrollPeriod(state),

    employeeLeaves: selectors.getEmployeeLeaves(state, ownProps),
    leaveTypes: selectors.getLeaveTypes(state, ownProps),
    employeeIds: selectors.employeeIds(state),
    orders: selectors.getOrders(state, ownProps),
    filters: selectors.getFilters(state, ownProps),
    tags: selectors.getTags(state, ownProps),

    totalCount: selectors.getTotalCount(state, ownProps),
});

const mapDispatchToProps = {
    changeFilter,
    setTags,
    getEmployees,
    getLeaveTransactions,
    openLeaveTransactionModal,
    changeOrder,
};

export default connect(mapStateToProps, mapDispatchToProps)(Table);
