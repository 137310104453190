import React from 'react';

import {interfaces} from '@global';
import {DEFAULT_NAME_WIDTH} from '../constants';

import Column from './Employee';

interface IProps {
    width: number;
    orders: interfaces.ListingOrders;
    filters: interfaces.ListingFilters;
    getEmployees: (query: string, limit: number, page: number) => any;
    changeOrder: (column: string) => void;
    changeFilter: (params: interfaces.FilterParams) => void;
}

const EmployeeColumn: React.FC<IProps> = ({
    filters,
    orders,
    getEmployees,
    changeFilter,
    changeOrder,
    width = DEFAULT_NAME_WIDTH
}) => (
    Column({
        width,
        filters,
        orders,
        changeOrder,
        changeFilter,
        getEmployees,
    })
);

export default EmployeeColumn;
