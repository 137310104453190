import React, {memo} from 'react';
import {Column} from 'react-virtualized';
import styled from 'styled-components';

import {constants, interfaces, intl} from '@global';
import {LeaveInterfaces} from '@api';
import {Can, defaultInfiniteTableCellDataGetter, InfiniteTableHeader} from '@common';
import ViewAction from '../../Actions/ViewAction';

const StyledEmployeeWrapper = styled.div`
  display: flex;
  cursor: pointer;
  width: 100%;
  align-items: center;
`;

const StyledContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

interface IProps {
    orders?: interfaces.ListingOrders;
    changeOrder?: (column: string) => any;

    onOpen: (transaction: LeaveInterfaces.Transaction[]) => void;
}

const Cell: React.FC<{
    cellData: any,
    onOpen: () => void;
}> = memo(({cellData, onOpen}) => {
    return (
        <StyledEmployeeWrapper>
            <StyledContentWrapper>
                <Can run={constants.PermissionCode.EMPLOYEE}>
                    <ViewAction onOpen={onOpen} label={cellData.transactions[0].leaveTypeName}/>
                </Can>
            </StyledContentWrapper>
        </StyledEmployeeWrapper>
    );
});

const StyledHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
`;

const LeaveType: React.FC<IProps> = ({
    orders,
    changeOrder,
    onOpen,
}) => (
    <Column
        dataKey='leaveTypeName'
        width={250}
        flexGrow={1}
        cellDataGetter={defaultInfiniteTableCellDataGetter}
        headerRenderer={() => (
            <StyledHeaderWrapper>
                {intl.get('leave_type')}
                <StyledActionsWrapper>
                    <InfiniteTableHeader.Order
                       column={'leaveTypeName'}
                       orders={orders}
                       changeOrder={changeOrder}
                    />
                </StyledActionsWrapper>
            </StyledHeaderWrapper>
        )}
        cellRenderer={({cellData}) => (
            <Cell
                cellData={cellData}
                onOpen={() => onOpen(cellData.transactions)}
            />
        )}
    />
);
export default LeaveType;
