import React, {PureComponent} from 'react';
import {intl} from '@global';
import styled from 'styled-components';
import DatePicker, { registerLocale } from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import * as Cookies from 'js-cookie';

import {stylingVariables} from '@global';
import {DateHelper} from '@library';

import et from "date-fns/locale/et";

registerLocale("et", et);

const StyledDatePickerField = styled.div<{isDisabled: boolean}>`
  width: 100%;
  font-weight: bold;
  position: relative;
  display: inline-block;
  pointer-events: ${props => props.isDisabled ? 'none' : 'all'};

  .react-datepicker {
    width: 100%;
    border: none;
  }

  .react-datepicker__navigation {
    top: 17px;
    pointer-events: all;
  }

  .react-datepicker__current-month{
    margin: 10px 0 10px 0;
    font-weight: bold;
    font-size: 15px;
    color: ${stylingVariables.colorPalette.darkest};
    text-transform: capitalize;
  }

  .react-datepicker__month-container{
      width: 100%;
  }

  .react-datepicker__month{
      font-weight: normal;
      margin: 0.5rem 0.4rem;
  }

  .react-datepicker__day--outside-month {
      color: ${stylingVariables.colorPalette.gray};
  }

  .react-datepicker__week {
    display: flex;
    justify-content: space-evenly;
  }

  .react-datepicker__header{
      border-bottom: 1px solid ${stylingVariables.colorPalette.darkGray};
      background-color: ${stylingVariables.colorPalette.white};
  }

  .react-datepicker__day-names{
      display: flex;
      justify-content: space-around;
      margin: 0 0;
      .react-datepicker__day-name{
        font-size: ${stylingVariables.fontSize.mediumSmall};
        color: ${stylingVariables.colorPalette.deepGray};
        width: 40px;
      }
  }

  .react-datepicker__day {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 25px;
    font-size: 11px;
    flex: 1;
    margin: 5px 0;
    span {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      font-size: 11px;
      color: ${stylingVariables.colorPalette.darkest};
      border: 0;
    }
    &:focus {
      outline: none;
    }

    &.react-datepicker__day--in-range,
    &.react-datepicker__day--in-selecting-range {
      background-color: ${stylingVariables.colorPalette.primaryHover};
      border-radius: 0;    
      span {
        border-radius: 0;
        color: ${stylingVariables.colorPalette.darkest};
        background-color: ${stylingVariables.colorPalette.primaryHover};
        margin: 0;
      }
    }

    &.react-datepicker__day--range-start,
    &.react-datepicker__day--selecting-range-start,
    &.react-datepicker__day--range-end,
    &.react-datepicker__day--selecting-range-end,
    &.react-datepicker__day--selected,
    &:hover {
      border-radius: 0;
      background-color: ${stylingVariables.colorPalette.white};
      .value {
        border-radius: 50%;
        position: absolute;
        top: 0;
        right: 10px;
        width: 25px;
        height: 25px;
        background-color: ${stylingVariables.colorPalette.primary};
        color: ${stylingVariables.colorPalette.white};
      }
    }

    &.react-datepicker__day--selected {
      .background-right {
        background-color:  ${stylingVariables.colorPalette.white};
      }
      .background-left {
        background-color:  ${stylingVariables.colorPalette.white};
      }
    }

    &.react-datepicker__day--range-start,
    &.react-datepicker__day--selecting-range-start {
      .background-left {
        background-color:  ${stylingVariables.colorPalette.white};
      }
      .background-right {
        background-color:  ${stylingVariables.colorPalette.primaryHover};
      }
    }

    &.react-datepicker__day--range-end,
    &.react-datepicker__day--selecting-range-end {
      .background-left {
        background-color:  ${stylingVariables.colorPalette.primaryHover};
      }
      .background-right {
        background-color:  ${stylingVariables.colorPalette.white};
      }
    }

    &.react-datepicker__day--disabled {
      span {
        color: ${stylingVariables.colorPalette.darkGray};
      }
    }
    &.react-datepicker__day--outside-month {
      span {
        color: #999999;
      }
    }
}
`;

const StyledHeading = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: ${stylingVariables.fontWeight.regular};
`;
const StyledFrom = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    cursor: pointer;
    font-size: ${stylingVariables.fontSize.medium};
    font-weight: ${stylingVariables.fontWeight.regular};
    color: ${stylingVariables.colorPalette.deepGray};
    span {
        margin-top: 10px;
    }
`;
const StyledTill = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    cursor: pointer;
    font-size: ${stylingVariables.fontSize.medium};
    font-weight: ${stylingVariables.fontWeight.regular};
    color: ${stylingVariables.colorPalette.deepGray};
    span {
        margin-top: 10px;
    }
`;

const StyledBlack = styled.span`
    color: ${stylingVariables.colorPalette.darkest};
    font-size: ${stylingVariables.fontSize.mediumLarge};
    font-weight: ${stylingVariables.fontWeight.semibold};
    text-transform: capitalize;
`;

const StyledGray = styled.span`
    color: ${stylingVariables.colorPalette.deepGray};
    font-size: ${stylingVariables.fontSize.mediumLarge};
    font-weight: ${stylingVariables.fontWeight.semibold};
`;

const StyledSelectDate = styled.span`
    color: ${stylingVariables.colorPalette.darkest};
    font-size: ${stylingVariables.fontSize.mediumLarge};
    font-weight: ${stylingVariables.fontWeight.semibold};
`;

const StyledMessage = styled.span`
    color: ${stylingVariables.colorPalette.deepGray};
    background-color: ${stylingVariables.colorPalette.lightGray};
    padding: 3px 12px;
    border-radius: 10px;
`;

interface DateRangeProps {
    from?: string|null;
    till?: string|null;
    onChange: (from?: string|null, till?: string|null) => void;

    isDisabled: boolean;
    isStartDateDisabled: boolean;
}

interface DateRangeState {
    from?: string|null;
    till?: string|null;
}

export default class InlineDateRangePicker extends PureComponent<DateRangeProps, DateRangeState> {
    static defaultProps = {
        isDisabled: false,
        isStartDateDisabled: false,
        onChange: () => {},
    };

    constructor(props: any) {
        super(props);
        const from = this.props.from || moment().format('YYYY-MM-DD');
        const till = this.props.till;

        this.state = {
            from,
            till,
        };

        this.props.onChange(from, till);
    }

    private clearFrom = () => {
        this.setState({from: null, till: null}, () => {
            this.props.onChange(this.state.from, this.state.till);
        });
    }

    private clearTill = () => {
        this.setState({till: null}, () => {
            this.props.onChange(this.state.from, this.state.till);
        });
    }

    private locale = Cookies.get('locale');

    public render() {
        let isSelectFrom: any = !this.state.from || (this.state.from && this.state.till);
        
        return <StyledDatePickerField isDisabled={this.props.isDisabled}>
            <StyledHeading>
                <StyledFrom>
                    <StyledGray>{intl.get('start')}</StyledGray>
                    {
                        this.state.from
                        ? (
                            <StyledBlack onClick={!this.props.isStartDateDisabled ? this.clearFrom : () => {}}>
                                {DateHelper.convertDateToHumanFormat(this.state.from)}
                            </StyledBlack>
                        )
                        : <StyledSelectDate>{intl.get('select_date')}</StyledSelectDate>
                    }
                </StyledFrom>
                <StyledMessage>{intl.get('to')}</StyledMessage>
                <StyledTill>
                    <StyledGray>{intl.get('end')}</StyledGray>
                    {
                        this.state.till
                        ? (
                            <StyledBlack onClick={this.clearTill}>
                                {DateHelper.convertDateToHumanFormat(this.state.till)}
                            </StyledBlack>
                        )
                        : <StyledSelectDate>{intl.get('select_date')}</StyledSelectDate>
                    }
                </StyledTill>
            </StyledHeading>
            <DatePicker
                locale={this.locale}
                dateFormat={'yyyy-MM-dd'}
                selected={this.state.from
                    ? new Date(this.state.from)
                    : (this.state.till ? new Date(this.state.till) : null)}
                startDate={this.state.from ? new Date(this.state.from) : null}
                endDate={this.state.till ? new Date(this.state.till) : null}
                renderDayContents={dayOfMonth => (
                    <>
                        <span className={'background-left'}/>
                        <span className={'value'}>{dayOfMonth}</span>
                        <span className={'background-right'} />
                    </>
                )}
                onChange={(value: any) => {
                    const parsedValue = new Date(value.getTime() - (value.getTimezoneOffset() * 60000 ))
                        .toISOString()
                        .split('T')[0];

                    let safeTill = false;
                    if (this.state.from && new Date(this.state.from) > value) {
                        isSelectFrom = true;
                        safeTill = true;
                    }
                    if (this.props.isStartDateDisabled && (this.state.from && new Date(this.state.from) > value)){
                        return;
                    }else if (this.props.isStartDateDisabled){
                        isSelectFrom = false;
                    }

                    this.setState({
                        from: isSelectFrom ? parsedValue : this.state.from,
                        till: isSelectFrom ? (safeTill ? this.state.till : null) : parsedValue,
                    }, () => {
                        this.props.onChange(this.state.from, this.state.till);
                    });
                }}
                disabled={this.props.isDisabled}
                selectsStart={isSelectFrom}
                selectsEnd={!isSelectFrom}
                inline
            />
        </StyledDatePickerField>;
    }
}
