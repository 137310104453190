import React, {PureComponent, Fragment} from 'react';
import styled, {createGlobalStyle} from 'styled-components';

import {stylingVariables} from '@global';

export const AnimationStyles = createGlobalStyle`
  @keyframes animation-bar {
    0% {
      transform: translate3d(-100%, 0px, 0px);
      animation-timing-function: ease-in;
    }
    100% {
      transform: translate3d(0px, 0px, 0px);
      animation-timing-function: linear;
    }
  }
  @-webkit-keyframes nprogress-spinner {
    0%   { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  @keyframes nprogress-spinner {
    0%   { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const LoaderWrapper = styled.div`
  pointer-events: none;
  display: block;
`;

const Bar = styled.div`
  background: ${stylingVariables.colorPalette.green};
  position: fixed;
  z-index: 1031;
  top: 0;
  right: 20px;

  width: 100%;
  height: 6px;

  animation: animation-bar 1200ms linear;
`;

const Spinner = styled.div`
  display: block;
  position: fixed;
  z-index: 1031;
  top: 12px;
  left: 12px; 
`;

export const SpinnerIcon = styled.div<{size?: number}>`
  ${props => `
    width: ${props.size ? props.size : 30}px;
    height: ${props.size ? props.size : 30}px;
  `}
  box-sizing: border-box;

  border: solid 4px transparent;
  border-top-color: ${stylingVariables.colorPalette.green};
  border-left-color: ${stylingVariables.colorPalette.green};
  border-radius: 50%;

  -webkit-animation: nprogress-spinner 600ms linear infinite;
  animation: nprogress-spinner 600ms linear infinite;
`;

interface IProps {
    isLoading: boolean;
    size?: number;
}

export default class Loader extends PureComponent<IProps> {
    render() {
        if (!this.props.isLoading) {
            return null;
        }

        return (
            <Fragment>
                <AnimationStyles/>
                <LoaderWrapper>
                    <Bar/>
                    <Spinner>
                        <SpinnerIcon size={this.props.size}/>
                    </Spinner>
                </LoaderWrapper>
            </Fragment>
        );
    }
}
