import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { InfiniteLoader, AutoSizer } from 'react-virtualized'
import styled from 'styled-components'
import { RouteHelper } from '@library'
import { interfaces, intl, stylingVariables } from '@global'
import { EmployeeInterfaces, EmployeeQueryParams, PayrollInterfaces } from '@api'
import { StyledInfiniteTable, InfiniteTableColumn, InfiniteTableNoRows } from '@common'

import {
  getEmployees,
  setSelectedIds,
  clearOldAndGetEmployees,
  changeOrder,
  changeFilter,
  changeSearchQuery,
  setTags,
} from '../redux'
import * as selectors from '../selectors'
import * as globalSelectors from '../../selectors'
import * as columnSettingsSelectors from '../columnSettingsSelectors'
import { RootState } from '../../../rootReducer'
import * as Columns from './Column'
import { Row } from './Row'
import ApiEmployee from '../../../api/employee.service'
import { setSearchQuery } from '../../redux'

import { debounce } from 'lodash'

const InfiniteTable = styled(StyledInfiniteTable)`
  .ReactVirtualized__Table__row {
    .ReactVirtualized__Table__rowColumn {
      .checkbox {
        display: none;
      }
    }

    .ReactVirtualized__Table__rowColumn {
      height: 100% !important;
    }

    &.highlighted,
    &:hover {
      .ReactVirtualized__Table__rowColumn {
        .checkbox {
          display: flex;
        }
        .index {
          display: none;
        }
      }
    }

    border: 1px solid transparent;
    &:hover {
      border: 1px solid ${stylingVariables.colorPalette.primaryHover};
    }
  }
`

interface TableProps {
  isLoading: boolean
  isMenuCollapsed: boolean
  currentLegalEntityId: number | null
  currentPayrollPeriod: PayrollInterfaces.PayrollPeriod | null
  searchQuery: string

  employees: EmployeeInterfaces.Employee[]
  status: string
  totalCount: number
  columns: interfaces.EmployeeColumn[]
  selectedIds: Set<number>
  orders: interfaces.ListingOrders
  filters: interfaces.ListingFilters
  tags: EmployeeInterfaces.Tag[]
  changeOrder: (column: string) => void
  changeFilter: (params: interfaces.FilterParams) => void
  setTags: (tags: EmployeeInterfaces.Tag[]) => void
  changeSearchQuery: (searchQuery: string) => void

  clearOldAndGetEmployees: () => void
  setSelectedIds: (ids: Set<number>) => void
  getEmployees: (offset: number, limit?: number) => void
  setSearchQuery: (searchQuery: string) => void

  onScrollTable: () => void
  reservedHeight: number
}

export class Table extends PureComponent<TableProps> {
  private readonly NON_CLICKABLE_COLUMNS = ['actions']
  componentDidUpdate(prevProps: TableProps) {
    if (prevProps.searchQuery !== this.props.searchQuery) {
      this.onSearch(this.props.searchQuery)
    }
  }

  public render() {
    const realTableWidth = this.props.columns.length * 230 + 500
    const activeEmployees = this.props.status === 'active'

    return (
      <InfiniteLoader
        isRowLoaded={this._isRowLoaded}
        loadMoreRows={this._loadMoreRows}
        rowCount={this.props.totalCount}
        minimumBatchSize={20}
      >
        {({ onRowsRendered, registerChild }) => (
          <AutoSizer>
            {({ width, height }) => (
              <InfiniteTable
                ref={registerChild}
                onRowsRendered={onRowsRendered}
                rowHeight={48}
                headerHeight={40}
                overscanRowCount={40}
                rowGetter={({ index }: { index: number }) => this.props.employees[index]}
                rowCount={this.props.employees.length}
                height={height - this.props.reservedHeight}
                width={width > realTableWidth ? width : realTableWidth}
                rowRenderer={Row({ selectedIds: this.props.selectedIds, onRowClick: this.onRowClick })}
                onColumnClick={({ dataKey, event }: any) => {
                  if (this.NON_CLICKABLE_COLUMNS.includes(dataKey)) {
                    event.stopPropagation()
                  }
                }}
                isMenuCollapsed={this.props.isMenuCollapsed}
                noRowsRenderer={this._renderNoRows}
                onScroll={this.props.onScrollTable}
              >
                {!activeEmployees && (
                  Columns.SelectedIndex({
                    selectedIds: this.props.selectedIds,
                    onChangeSelection: this.changeRowSelection,
                  })
                )}

                {Columns.EmployeeColumn({
                  currentPeriod: this.props.currentPayrollPeriod,

                  filters: this.props.filters,
                  orders: this.props.orders,
                  tags: this.props.tags,

                  changeOrder: this.onChangeOrder,
                  changeFilter: this.onChangeFilter,
                  setTags: this.props.setTags,

                  getEmployees: this.getEmployeesForFilter,
                })}

                {this.props.columns.map((column) =>
                  InfiniteTableColumn.OrderedAndFiltered({
                    label: column.label,
                    dataKey: column.code,
                    type: column.type,
                    options: column.options,
                    orders: this.props.orders,
                    filters: this.props.filters,
                    changeFilter: this.onChangeFilter,
                    changeOrder: this.onChangeOrder,
                  })
                )}

                {Columns.Actions()}
              </InfiniteTable>
            )}
          </AutoSizer>
        )}
      </InfiniteLoader>
    )
  }

  private onChangeOrder = (column: string) => {
    this.props.changeOrder(column)
    this.props.clearOldAndGetEmployees()
  }

  private onRowClick = (id: number) => {
    RouteHelper.goToEmployeePage(id)
  }

  private onChangeFilter = (params: interfaces.FilterParams) => {
    this.props.changeFilter(params)
    if (params.searchQuery && params.searchQuery !== this.props.searchQuery) {
      this.props.setSearchQuery(params.searchQuery)
    }
    this.props.clearOldAndGetEmployees()
  }

  private debouncedUpdate = debounce(() => {
    this.props.clearOldAndGetEmployees()
  }, 300)

  private onSearch = (searchQuery: string) => {
    this.props.changeSearchQuery(searchQuery)
    this.debouncedUpdate()
  }

  private changeRowSelection = (id: number): void => {
    const selectedIds = new Set(this.props.selectedIds)
    if (selectedIds.has(id)) {
      selectedIds.delete(id)
    } else {
      selectedIds.add(id)
    }

    this.props.setSelectedIds(selectedIds)
  }

  private _isRowLoaded = ({ index }: { index: number }): boolean => {
    return !!this.props.employees[index]
  }

  private _loadMoreRows = async ({ startIndex }: { startIndex: number }) => {
    await this.props.getEmployees(startIndex)
  }

  private _renderNoRows = () => {
    if (this.props.isLoading) {
      return null
    }

    return <InfiniteTableNoRows text={intl.get('no_employees_found')} />
  }

  private getEmployeesForFilter = async (
    query: string,
    tags: EmployeeInterfaces.Tag[],
    limit: number,
    page: number
  ) => {
    if (!this.props.currentLegalEntityId) {
      return []
    }

    const params = new EmployeeQueryParams({
      status: this.props.status,
      currentPeriod: this.props.currentPayrollPeriod,
      tags,
    })

    return await ApiEmployee.findByQueryWithPage(
      this.props.currentLegalEntityId,
      query,
      limit,
      page,
      params.params(),
      true
    )
  }
}

const mapStateToProps = (state: RootState, ownProps: any) => ({
  isLoading: globalSelectors.isLoading(state),
  isMenuCollapsed: globalSelectors.isMenuCollapsed(state),
  currentLegalEntityId: globalSelectors.getCurrentLegalEntityId(state),
  currentPayrollPeriod: globalSelectors.getCurrentPayrollPeriod(state),
  searchQuery: globalSelectors.getSearchQuery(state),
  employees: selectors.getEmployees(state, ownProps),
  totalCount: selectors.getTotalCount(state, ownProps),
  columns: columnSettingsSelectors.getSelectedColumns(state),
  selectedIds: selectors.getSelectedIds(state, ownProps),
  orders: selectors.getOrders(state, ownProps),
  filters: selectors.getFilters(state, ownProps),
  tags: selectors.getTags(state, ownProps),
  status: selectors.getChosenStatus(state, ownProps),
})

const mapDispatchToProps = {
  setSelectedIds,
  getEmployees,
  changeOrder,
  changeFilter,
  changeSearchQuery,
  setTags,
  clearOldAndGetEmployees,
  setSearchQuery,
}

export default connect(mapStateToProps, mapDispatchToProps)(Table)
