import {parse} from 'query-string';
import {history} from '@global';

class UrlHelper {
    public static readonly ACCESS_TOKEN: string = 'accessToken';
    public static readonly LEGAL_ENTITY_ID: string = 'legalEntityId';
    public static readonly SUPPORT_NEW_MSS: string = 'supportNewMSS';

    public static getAccessToken(): string|null {
        let accessToken = parse(window.location.search)[UrlHelper.ACCESS_TOKEN];
        if (accessToken instanceof Array) {
            accessToken = accessToken[0];
        }

        return accessToken ? accessToken.toString() : null;
    }

    public static getLegalEntityId(): number|null {
        let legalEntityId = parse(window.location.search)[UrlHelper.LEGAL_ENTITY_ID];
        if (legalEntityId instanceof Array) {
            legalEntityId = legalEntityId[0];
        }

        return legalEntityId ? Number.parseInt(legalEntityId.toString(), undefined) : null;
    }

    public static getSupportNewMSS(): boolean {
        let supportNewMSS = parse(window.location.search)[UrlHelper.SUPPORT_NEW_MSS];
        if (supportNewMSS instanceof Array) {
            supportNewMSS = supportNewMSS[0];
        }
        return supportNewMSS  === 'true';
    }

    public static setQueryParam(key: string, value: string) {
        const query = new URLSearchParams(history.location.search)
        if (!query) {
            return;
        }
        query.set(key, value)
        history.replace({...history.location, search: query.toString()})
    }

    public static getSearchQueryParam(queryParameter: string): any {
        return parse(window.location.search)[queryParameter];
    }
}

export default UrlHelper;
