import React, {PureComponent} from 'react';
import styled from 'styled-components';
import {debounce} from 'lodash';

import {stylingVariables, constants, intl} from '@global';
import {Icon} from '@common';

export const StyledInputWrapper = styled.div<{theme: string, height?: number, width?: number, iconSize: number}>`
    position: relative;
    ${props => props.height && `height: ${props.height}px`};
    ${props => props.width && `width: ${props.width}px`};
    input {
        width: 100%;
        font-size: ${stylingVariables.fontSize.mediumLarge};
        border-radius: 20px;
        ${props => props.theme === 'dark' ? '' : 'height: 35px;'};
        box-shadow: none;
        border: 1px solid ${stylingVariables.colorPalette.darkGray};
        padding: 5px 20px;
        ${props => props.theme === 'dark' ? 'background: ' + stylingVariables.colorPalette.darkGray + ';' : ''}
        
        ${props => props.height && `height: ${props.height}px`};
    }
    svg {
      position: absolute;
      right: 10px;
      top: calc(50% - ${props => props.iconSize / 2}px);
      ${props => props.theme === 'dark' ? 'filter: invert(52%) sepia(79%) saturate(371%) hue-rotate(125deg) brightness(92%) contrast(95%);' : ''}
    }
`;

interface SearchInputProps {
    placeholder?: string;
    defaultValue?: string;
    theme?: string;
    autoFocus?: boolean;
    onChange: (value: string) => any;
    height?: number;
    width?: number;
}

interface IState {
    searchValue: string|undefined;
}

export class SearchInput extends PureComponent<SearchInputProps, IState> {
    static defaultProps = {
        placeholder: 'search',
        theme: 'light',
        autoFocus: false
    };

    constructor(props: SearchInputProps) {
        super(props);

        this.state = {
            searchValue: this.props.defaultValue
        }
        this.debouncedOnChange = debounce(this.debouncedOnChange, 300);


    };

    UNSAFE_componentWillUpdate(newProps: SearchInputProps) {
        this.setState({searchValue: newProps.defaultValue})
    }

    public render() {
        return (
            <StyledInputWrapper theme={this.props.theme} height={this.props.height} width={this.props.width} iconSize={15}>
                <input type='text'
                       autoFocus={this.props.autoFocus}
                       placeholder={
                           this.props.placeholder && intl.get(this.props.placeholder)
                           ? intl.get(this.props.placeholder)
                           : this.props.placeholder
                        }
                       onChange={this.onChange}
                       defaultValue={this.props.defaultValue}/>

                <Icon type={constants.IconTypes.SEARCH}
                      width={15}
                      height={15}
                      fillColor={stylingVariables.colorPalette.deepGray}
                />
            </StyledInputWrapper>
        );
    }

    private readonly onChange = (event: any) => {
        const value = event.target.value;

        this.debouncedOnChange(value);
    };

    private readonly debouncedOnChange = (value: string) => {
        this.props.onChange(value);
    }
}

export default SearchInput;
