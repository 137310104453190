import React, {PureComponent, ChangeEvent} from 'react';
import styled from 'styled-components';

import {stylingVariables} from '@global';

const StyledInputWrapper = styled.div<{hasError: boolean}>`
  display: flex;
  flex-direction: column;
  label {
    font-size: ${stylingVariables.fontSize.default};
    font-weight: ${stylingVariables.fontWeight.regular};
    margin-bottom: 5px;
  }
  input {
    display: block;
    font-size: ${stylingVariables.fontSize.default};
    width: 100%;
    border: 0;
    outline: none;
    border-bottom: 2px solid ${stylingVariables.colorPalette.gray};
    padding: 10px 0;
    resize: none;
    &:focus {
      border-bottom: 2px solid ${stylingVariables.colorPalette.green};
    }
    &::-webkit-input-placeholder {
      font-style: italic;
      font-weight: 400;
    }
    &::-moz-placeholder {
      font-style: italic;
      font-weight: 400;
    }
    &:-ms-input-placeholder {
      font-style: italic;
      font-weight: 400;
    }
    &:-moz-placeholder {
      font-style: italic;
      font-weight: 400;
    }
  }

  ${props => props.hasError && `
    span {
      font-size: ${stylingVariables.fontSize.medium};
      font-weight: ${stylingVariables.fontWeight.regular};
      color: ${stylingVariables.colorPalette.red};
      margin-top: 10px;
      font-style: italic;
    }
    input {
      border-bottom: 2px solid ${stylingVariables.colorPalette.red};
      &:focus {
        border-bottom: 2px solid ${stylingVariables.colorPalette.red};
      }
    }
  `}
`;

interface InputProps {
    label: string;
    placeholder?: string;
    errorMessage?: string|null;

    type?: string;
    isRequired?: boolean;
    defaultValue?: string;
    autoFocus?: boolean;
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
    onKeyDown?: (event: any) => void;
    onKeyPress?: (event: any) => void;
}

export class Input extends PureComponent<InputProps> {
    static defaultProps = {
        label: ''
    }

    render() {
        return (
            <StyledInputWrapper hasError={!!this.props.errorMessage}>
                {
                    this.props.label &&
                    <label>{this.props.label} {this.props.isRequired && '*'}</label>
                }

                <input type={this.props.type || 'text'}
                       defaultValue={this.props.defaultValue}
                       placeholder={this.props.placeholder}
                       onChange={this.props.onChange}
                       autoFocus={this.props.autoFocus}
                       onKeyDown={this.props.onKeyDown}
                       onKeyPress={this.props.onKeyPress}
                />

                {this.props.errorMessage && <span>{this.props.errorMessage}</span>}
            </StyledInputWrapper>
        );
    }
}

export default Input;
