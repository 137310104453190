export default class PreviewHelper {
    public static generateFromString(text: string): string|null {
        const words = text.split(' ');
        if (words.length === 0) {
            return null;
        }

        let preview = '';
        if (words[0][0]) {
            preview += words[0][0].toLocaleUpperCase();
        }

        if (words[words.length - 1][0]) {
            preview += words[words.length - 1][0].toLocaleUpperCase();
        }

        return preview;
    }
}
