import {constants} from '@global';
import React, {PureComponent, Fragment} from 'react';
import {intl} from '@global';

import {Actions, Can} from '@common';
import {LeaveInterfaces, EmployeeInterfaces} from '@api';

import AddModal from './Modify/AddModal';

interface IProps {
    employee?: EmployeeInterfaces.Employee;
    legalEntityId: number;

    leaveTypes: LeaveInterfaces.Type[];

    onClickCreate: (employeeId: number, transaction: LeaveInterfaces.Transaction) => void;
    onClickCreateAndApprove?: (employeeId: number, transaction: LeaveInterfaces.Transaction) => void;

    size?: string,
    fontSize?: string,
    shadow?: boolean,
    method: string;
}

interface IState {
    isOpen: boolean;
}

export default class AddAction extends PureComponent<IProps, IState> {
    public constructor(props: IProps) {
        super(props);

        this.state = {
            isOpen: false,
        };
    }


    private open = () => {
        this.setState({isOpen: true});
    }

    private close = () => {
        this.setState({isOpen: false});
    }

    private withPlusAdd =
        <Actions.PlusAction
            onClick={this.open}
            size={this.props.size}
            fontSize={this.props.fontSize}
            shadow={this.props.shadow}
        >
            {intl.get('new_leave_transaction')}
        </Actions.PlusAction>

    private add =
        <Actions.Action>
            {intl.get('new_leave_transaction')}
        </Actions.Action>

    render() {

        let addMethod
        switch(this.props.method) {
            case 'withPlusAdd':
                addMethod = this.withPlusAdd;
                break;
            case 'add':
                addMethod = this.add;
                break;
            default:
                addMethod = this.add;

        }

        const {employee, legalEntityId, leaveTypes, onClickCreate, onClickCreateAndApprove} = this.props;
        return (
            <Fragment>
                <Can run={constants.PermissionCode.LEAVES_EDIT_LEAVE}>
                    {addMethod}
                </Can>

                {
                    this.state.isOpen &&
                    <AddModal
                        employee={employee}
                        legalEntityId={legalEntityId}

                        types={leaveTypes}

                        onClickCreate={onClickCreate}
                        onClickCreateAndApprove={onClickCreateAndApprove}
                        onClickClose={this.close}
                    />
                }
            </Fragment>
        );
    }
};
