import {constants, stylingVariables} from '@global';
import React, {PureComponent, Fragment} from 'react';
import {intl} from '@global';

import {Actions, Can, Modal, Icon} from '@common';

interface IProps {
    forEmployeeIds: Set<number>;
    isLoading: boolean;

    onClickRevoke: (ids: Set<number>) => void;
}

interface IState {
    isOpenConfirmationModal: boolean;
}

export default class RevokeEssAccess extends PureComponent<IProps, IState> {
    public constructor(props: any) {
        super(props);

        this.state = {
            isOpenConfirmationModal: false,
        };
    }

    render() {
        return (
            <Fragment>
                <Can run={constants.PermissionCode.EMPLOYEES_ACTION_REVOKE_ACCESS_TO_ESS}>
                    <Actions.Action onClick={this.openConfirmationModal} isDisabled={this.props.isLoading}>
                        {intl.get('revoke_ess_access')}
                    </Actions.Action>
                </Can>

                <Modal.GentConfirmationModal
                    title={'revoke_ess_access'}
                    acceptBtnTitle={'revoke'}
                    isOpen={this.state.isOpenConfirmationModal}
                    onClickAccept={this.revoke}
                    onClickClose={this.closeConfirmationModal}
                    headerIcon={constants.IconTypes.REVOKE}
                >
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <Icon type={constants.IconTypes.WARNING_ALERT} height={20} width={20} />
                        <span style={{
                            marginLeft: 10,
                            fontWeight: 600,
                            color: stylingVariables.colorPalette.darkest
                        }}>
                            {intl.get('revoke_ess_access_confirmation')}
                        </span>
                    </div>
                </Modal.GentConfirmationModal>
            </Fragment>
        );
    }

    private revoke = () => {
        this.closeConfirmationModal();

        this.props.onClickRevoke(this.props.forEmployeeIds);
    }

    private openConfirmationModal = () => {
        this.setState({isOpenConfirmationModal: true});
    }

    private closeConfirmationModal = () => {
        this.setState({isOpenConfirmationModal: false});
    }
}
