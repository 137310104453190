import {interfaces} from '@global';
import moment from 'moment';
import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {RootState} from '../../../rootReducer';
import {default as CommonClearFiltersAndSorts} from '../../common/ClearFiltersAndSorts';

import {resetFiltersAndSorts} from './redux';
import * as selectors from './selectors';

interface IProps {
    orders: interfaces.ListingOrders;
    filters: interfaces.ListingFilters;
    filterYear: number;
    filterSearch: string;
    resetFiltersAndSorts: () => void;
}

export class ClearFiltersAndSorts extends PureComponent<IProps> {
    get filters () {
        let filters = {...this.props.filters};
        if (this.props.filterYear !== moment().year()) {
            filters['_year'] = {column: '_year', value: this.props.filterYear};
        }
        if (!!this.props.filterSearch) {
            filters['_search'] = {column: '_search', value: this.props.filterSearch};
        }
        return filters;
    }

    public render() {
        return (
            <CommonClearFiltersAndSorts
                orders={this.props.orders}
                filters={this.filters}
                resetOrdersAndFilters={this.props.resetFiltersAndSorts}
            />
        );
    }
}


const mapStateToProps = (state: RootState) => ({
    orders: selectors.getOrders(state),
    filters: selectors.getFilters(state),
    filterSearch: selectors.getFilterSearch(state),
    filterYear: selectors.getFilterYear(state),
});

const mapDispatchToProps = {
    resetFiltersAndSorts,
};

export default connect(mapStateToProps, mapDispatchToProps)(ClearFiltersAndSorts);
