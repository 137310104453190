import React, {PureComponent} from 'react';
import {intl} from '@global';
import {ApiObject} from '@api';

import {Layout, Dropdown} from '@common';

interface IProps {
    defaultValue: string;
    defaultName?: string;
    errors?: string[];

    templates: ApiObject.ReportTemplate[];
    onChange: (templateId: number) => any;
}

interface IState {
  list:  ApiObject.SelectOption[];
}

export default class TemplateRow extends PureComponent<IProps, IState> {
    private get list(): ApiObject.SelectOption[] {
      return this.props.templates.map((template: ApiObject.ReportTemplate) => {
        return {
            key: `${template.id}`,
            label: template.label,
        };
      });
    }

    private onChange = (templateId: string): void => this.props.onChange(+templateId);

    private renderRowValue() {
      return (
        <Dropdown
            selectedValue={this.props.defaultValue}
            list={this.list}
            onChange={this.onChange}
        />
      );
    }

    public render() {
        return (
          <Layout.Row hasError={!!this.props.errors}>
            <Layout.RowLabel percent={30}>
                {intl.get('report_template')} *
            </Layout.RowLabel>

            <Layout.RowValue percent={70}>
                {this.renderRowValue()}
            </Layout.RowValue>
        </Layout.Row>
        );
    }
}

