import React from 'react';
import {Column} from 'react-virtualized';

import {defaultInfiniteTableCellDataGetter} from '@common';
import moment from 'moment';

interface DefaultColumnProps {
    dataKey: string;
    label: string;
    width?: number;
    flexGrow?: number;
}

const Dates: React.FC<DefaultColumnProps> = ({
          dataKey,
          label,
          width = 140,
          flexGrow = 1,
      }: DefaultColumnProps) => (
    <Column
        label={label}
        dataKey={dataKey}
        width={width}
        style={{justifyContent: 'flex-end'}}
        flexGrow={flexGrow}
        cellDataGetter={defaultInfiniteTableCellDataGetter}
        headerRenderer={() => (
            <div style={{textAlign: 'right'}}>{label}</div>
        )}
        cellRenderer={({cellData}: any) => {
            return <>
                {moment(
                    cellData.startDate,
                    'YYYY-MM-DD',
                ).format('MMM DD, YYYY')} - {cellData.endDate ?
                moment(
                    cellData.endDate,
                    'YYYY-MM-DD',
                ).format('MMM DD, YYYY')
                : '...'
            }
            </>;
        }}
    />
);

export default Dates;
