import React, { PureComponent } from 'react';
import { intl, stylingVariables } from '@global';
import { numberFormat } from '../../../../../Employee/Tabs/Payment/PaymentProcessor/fieldHelper';

import { LeaveInterfaces } from '@api';
import { Layout, InlineDateRangePicker } from '@common';
import styled from 'styled-components';

const Wrapper = styled.div`
  margin: 10px 0 0 77px;
`

const ReqPeriod = styled.div`
  display: flex;
  flex-direction: row;
  font-weight: ${stylingVariables.fontWeight.bold};
  font-size: ${stylingVariables.fontSize.medium};
  color: ${stylingVariables.colorPalette.deepGray};
  align-items: center;
`;

const PeriodDays = styled.div`
  margin-left: 5px;
  color: ${stylingVariables.colorPalette.white};
  background: ${stylingVariables.colorPalette.secondary};
  font-size: ${stylingVariables.fontSize.mediumLarge};
  border-radius: 4px;
  padding: 2px 8px;
`;

interface LeavePeriodRowProps {
  leave: LeaveInterfaces.Transaction;
  amountType: string | undefined;
}

export default class LeavePeriodRow extends PureComponent<LeavePeriodRowProps> {
  public render() {
    let reqDays;
    if (this.props.leave.amount) {
      reqDays = this.props.leave.amount;
    }

    const suffix = this.props.amountType && this.props.amountType === 'hours' ? 'h' : 'd';

    return (
      <Wrapper>
        <Layout.Row>
          <Layout.RowLabel percent={15} top={20}>
            {intl.get('selected_days')}
          </Layout.RowLabel>

          <Layout.RowDateRangeValue key={this.props.leave.id}>
            <InlineDateRangePicker
              from={this.props.leave.startDate}
              till={this.props.leave.endDate}
              isDisabled={true}
            />
          </Layout.RowDateRangeValue>
        </Layout.Row>
        {reqDays && (
          <Layout.Row>
            <Layout.RowLabel percent={15} top={20}></Layout.RowLabel>
            <ReqPeriod>
              {intl.get('requested_leave_time')}:
              <PeriodDays>
                {Number.isInteger(reqDays) ? reqDays : numberFormat(reqDays, true)} {intl.get(suffix)}
              </PeriodDays>
            </ReqPeriod>
          </Layout.Row>
        )}
      </Wrapper>
    )
  }
}
