import { createSelector } from 'reselect';
import moment from 'moment';

import { LeaveInterfaces } from '@api';

import { RootState } from '../../../../rootReducer';
import { isInPeriod } from '../../../common/Leave/filter.helper';
import { getUsingDefaultLanguage } from 'entrypoints/selectors';

const prepareTransaction = (transaction: LeaveInterfaces.Transaction, types: LeaveInterfaces.Type[]) => {
    const type = types.find(t => t.id === transaction.leaveTypeId);

    transaction.leaveTypeName = type ? type.name : '';

    return transaction;
};

export const getEmployee = (state: RootState) => state.employee.employee;

export const getLeaveTypes = createSelector(
    [(state: RootState, props: any) => state.leaves.types, getUsingDefaultLanguage],
    (leaveTypes: LeaveInterfaces.Type[], useLocalName: boolean) => {
        return leaveTypes.map((lt: LeaveInterfaces.Type) => {
        return { ...lt, name: useLocalName && lt.localName ? lt.localName : lt.name }
    })}
);

export const getActiveLeaveTypes = createSelector(
    [getLeaveTypes],
    (leaveTypes): LeaveInterfaces.Type[] => leaveTypes.filter(x => x.active)
);

export const getPeriodFrom = createSelector(
    [(state:RootState, props: any) => state.employee.leave.from],
    period => moment(period),
);
export const getPeriodTill = createSelector(
    [(state:RootState, props: any) => state.employee.leave.till],
    period => moment(period),
);

export const getTransactions = createSelector(
    [
        (state:RootState, props: any) => state.employee.leave.transactions,
        getLeaveTypes,
        getPeriodFrom,
        getPeriodTill,
    ],
    (transactions, types, from, till): LeaveInterfaces.Transaction[] => transactions ?
        transactions
            .map(transaction => prepareTransaction(transaction, types)) :
    [],
);

export const getLeavePeriodBalances = (state: RootState) => state.employee.leave.balancesByPeriod;

export const getLeaveTransitionalBalances = (state: RootState) => state.employee.leave.transitionalBalances;

export const getCurrentLeavePeriodBalances = (state: RootState): LeaveInterfaces.EmployeeLeaveBalanceWrapper => {
    const defaultResult = {
        employeeId: -1,
        balances: {},
    };
    const periods = state.employee.leave.balancesByPeriod;
    if (state.employee.id === null || periods.length === 0 || state.global.currentPayrollPeriod === null) {
        return defaultResult;
    }

    const period = periods.find(x => x.periodId === state.global.currentPayrollPeriod!.id);
    return period?.employeeBalances[state.employee.id] ? period.employeeBalances[state.employee.id] : defaultResult;
};

const getTransitionalAmountsSum = (balances: LeaveInterfaces.Balance, key: string): number => {
    return balances?.transitionalAmounts ? balances.transitionalAmounts.reduce(function (prev, curr) {
        return prev + curr[key as keyof LeaveInterfaces.TransitionalAmount]
    }, 0) : 0
}

export const getLeaveBalancesWithEntries = createSelector([getCurrentLeavePeriodBalances,getLeaveTransitionalBalances, getLeavePeriodBalances, (state:RootState) => state.employee.id],
    ({balances}, transitionalBalances, allLeavePeriods, employeeId) => {
        const output = {} as any;

        Object.keys(transitionalBalances).forEach(account => {
            const matchingBalance = balances[Number(account)]

            const nYearTransitionalBalance = getTransitionalAmountsSum(matchingBalance, 'balance')
            const nYearTransitionalTaken = getTransitionalAmountsSum(matchingBalance, 'taken')
            
            const entries = transitionalBalances[Number(account)].entries.map(e => {
                const matchingPeriodBalances = employeeId
                    ? allLeavePeriods.find(l => l.periodId === e.periodId)?.employeeBalances?.[employeeId]?.balances[Number(account)]
                    : null
                const periodTransitionalBalanceRemaining = matchingPeriodBalances
                    ? getTransitionalAmountsSum(matchingPeriodBalances, 'balance')
                    : nYearTransitionalBalance
                const periodTransitionalBalanceTaken = matchingPeriodBalances
                    ? getTransitionalAmountsSum(matchingPeriodBalances, 'taken')
                    : nYearTransitionalTaken
                

                return {
                    ...e,
                    periodTransitionalBalanceRemaining,
                    periodTransitionalBalanceTaken
                }
            })
            output[account] = { leaveRegulationId: Number(account), entries }
        })

        return output;
    },
);

export const getLatestLeavePeriodBalances = (state: RootState): LeaveInterfaces.EmployeeLeaveBalanceWrapper => {
    const defaultResult = {
        employeeId: -1,
        balances: {},
    };
    const periods = state.employee.leave.balancesByPeriod;
    if (state.employee.id === null || periods.length === 0) {
        return defaultResult;
    }
    const latest = periods[periods.length - 1];
    return latest.employeeBalances[state.employee.id] ? latest.employeeBalances[state.employee.id] : defaultResult;
};

export const getTransactionsSummary = createSelector(
    [getLeaveTypes, getTransactions],
    (types, transactions) => {
        const balances = {total: 0} as any;
        for (const type of types) {
            balances[type.id] = transactions.filter(t => {
                return t.leaveTypeId === type.id
                    && LeaveInterfaces.AcceptedTransactionStatuses.includes(t.status);
            }).length;

            balances.total += balances[type.id];
        }

        return balances;
    },
);

const groupTransactions = (transactions: LeaveInterfaces.Transaction[], payrollPeriod?: number):
    LeaveInterfaces.GroupedTransactions[] => {
    const transactionsMap = new Map();

    transactions.forEach((trans, index) => {
        const groupKey = `${trans.leaveTypeId}_${trans.status}_${payrollPeriod ? (
            trans.period ? trans.period.periodId : payrollPeriod
        ) : ''}`;
        if (transactionsMap.has(groupKey)) {
            transactionsMap.get(groupKey).transactions.push(trans);
        } else {
            const transactionInfo: LeaveInterfaces.TransactionGroupInfo = {
                leaveTypeId: trans.leaveTypeId,
                leaveTypeName: trans.leaveTypeName,
                status: trans.status,
                creatorEmployee: trans.creatorEmployee,
                source: trans.source,
                startDate: trans.startDate,
            };
            transactionsMap.set(groupKey, {transactionInfo, transactions: [trans]});
        }
    });

    const groupedTransactions: LeaveInterfaces.GroupedTransactions[] = [...transactionsMap.values()]
        .map((value, index) => {
            return {
                transactionInfo: value.transactionInfo,
                transactions: value.transactions
                    .sort((a: { startDate: string; }, b: { startDate: string; }) => {
                        return (moment(a.startDate, 'YYYY-MM-DD')
                            .isAfter(moment(b.startDate, 'YYYY-MM-DD'))) ? 1 : -1;
                    }),
            };
        });

    return groupedTransactions;
};

export const getLeaveRequests = createSelector(
    [getTransactions],
    (transactions): LeaveInterfaces.GroupedTransactions[] => {
        return groupTransactions(
            transactions.filter(t => t.status === LeaveInterfaces.TransactionStatus.draft),
        );
    },
);

export const getRejectedLeaveRequests = createSelector(
    [getTransactions],
    (transactions): LeaveInterfaces.GroupedTransactions[] => {
        return groupTransactions(
            transactions.filter(t => t.status === LeaveInterfaces.TransactionStatus.rejected),
        );
    },
);

export const getUpcomingLeaves = createSelector(
    [
        (state: RootState) => state.global.currentPayrollPeriod,
        getTransactions,
    ],
    (state, transactions): LeaveInterfaces.GroupedTransactions[] => {
        const acceptedTransactions = transactions
            .filter(t => LeaveInterfaces.AcceptedTransactionStatuses.includes(t.status));

        if (!state || !state.beginDate) {
            return groupTransactions(acceptedTransactions);
        }
        return groupTransactions(acceptedTransactions
            .filter(t => moment(t.startDate as string, 'YYYY-MM-DD').isAfter(
                moment(state.endDate as string, 'YYYY-MM-DD'))),
        );
    },
);

export const getOngoingLeaves = createSelector(
    [
        (state: RootState) => state.global.currentPayrollPeriod,
        getTransactions,
    ],
    (state, transactions): LeaveInterfaces.GroupedTransactions[] => {
        if (!state || !state.beginDate) {
            return [];
        }

        return groupTransactions(
            transactions
                .filter(transaction => isInPeriod(
                    transaction,
                    moment(state.beginDate),
                    moment(state.endDate),
                ))
                .filter(t => LeaveInterfaces.AcceptedTransactionStatuses.includes(t.status)),
            state.id,
        );
    },
);

export const getHistoricalLeaves = createSelector(
    [
        (state: RootState) => state.global.currentPayrollPeriod,
        getTransactions,
    ],
    (state, transactions): LeaveInterfaces.GroupedTransactions[] => {
        if (!state || !state.beginDate) {
            return [];
        }

        return groupTransactions(
            transactions
                .filter(t => moment(t.endDate as string, 'YYYY-MM-DD').isBefore(
                    moment(state.beginDate as string, 'YYYY-MM-DD')))
                .filter(t => LeaveInterfaces.AcceptedTransactionStatuses.includes(t.status)),
            state.id,
        );
    },
);
