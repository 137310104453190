import React, {CSSProperties} from 'react';
import {intl} from '@global';
import styled from 'styled-components';
import {stylingVariables, constants} from '@global';
import {Icon} from '@common';

const StyledButton = styled.button`
  color: ${stylingVariables.colorPalette.orange};
  font-size: ${stylingVariables.fontSize.large};
  font-weight: ${stylingVariables.fontWeight.medium};
  border: none;
  box-shadow: none;
  background-color: transparent;
  position: relative;
  cursor: pointer;
`;

export const StyledIconWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: -40px;
    top: -10px;
    width: 40px;
    height: 40px;
    background-color: ${stylingVariables.colorPalette.orange};
    border-radius: 50%;
`;

interface ActionProps {
    title: string;
    style?: CSSProperties;
    onClick?: () => any;
}

export const New: React.FC<ActionProps> = ({ title, style, onClick }: ActionProps) => {
    return (
        <StyledButton style={{...style}} onClick={onClick}>
            <StyledIconWrapper>
                <Icon type={constants.IconTypes.ADD} width={15} height={15}
                      fillColor={stylingVariables.colorPalette.white}
                />
            </StyledIconWrapper>

            {intl.get(title)}
        </StyledButton>
    );
};

export const Export: React.FC<ActionProps> = ({ title, style }: ActionProps) => {
    return (
        <StyledButton style={{...style}}>
            <StyledIconWrapper>
                <Icon type={constants.IconTypes.EXPORT} width={15} height={15}
                      fillColor={stylingVariables.colorPalette.white}
                />
            </StyledIconWrapper>

            {intl.get(title)}
        </StyledButton>
    );
};
