import {
    AllocationType,
    EmployeePayElementProperties,
    EntityFieldOrderBy,
    EntityFieldQuery,
    EOSReason,
    PaymentPeriodicity,
    PeriodPayElement,
    Status,
    EmployeePayElementChange,
    PayUnitType,
    PayElement,
} from './api.object';
import {PayrollPeriod} from './payroll.interfaces';

export interface MasterdataEditAllowed {
    masterdataEditAllowed?: boolean;
  }
  
export interface Employee {
    index: number;
    id: number;
    referenceCode: string | null;
    legalEntityId: number;
    fullName: string;
    person: {
        primaryEmail: string | null,
        firstName: string | null,
        lastName: string | null,
    };
    position: string | null;
    hireDate: string | null;
    terminationStatus: Status|null;
    createDate?: string;
    department?: string;
    employeeGroupId?: string;
    eosHireDate?: string; // YYYY-MM-DD
    eosReason?: EOSReason|null;
    essStatus?: EssStatus|null;
    lastLoginTime?: string;
    lastWorkingDate?: string;
    loginUsername?: string;
    paymentMethodId?: string;
    paymentPeriodicity?: PaymentPeriodicity;
    photoAccessToken?: string;
    status: Status;
    paymentProcessor: string|null;
    cuttOffs?: MasterdataEditAllowed|null;
    lastEssAccessDate?: string|null;
}

export interface ConsolidationEmployee {
    index: number;
    id: number;
    referenceCode: string | null;
    email: string | null,
    firstName: string | null,
    lastName: string | null,
}

export interface ListingRequest {
    employeeIds?: string[];
    employeeStatus?: Status;
    offset?: string;
    orderBy?: EntityFieldOrderBy[];
    pageSize?: string;
    pointer?: string;
    queries?: EntityFieldQuery[];
    search?: string;
    terminationStatus?: Status;
}

export interface ListingResponse {
    content?: Employee[];
    nextPointer?: string;
    total?: string;
}

export interface PayGroup {
    code?: string;
    name?: string;
}

export interface ResourceFavorite {
    resourceId?: string;
    resourceType?: string;
}

export interface BankAccount {
    accountCurrency?: string;
    accountName?: string;
    accountNumber?: string;
    allocationType?: AllocationType;
    allocationValue?: number;
    beneficiaryName?: string;
    endDate?: string;
    iban?: string;
    id?: string;
    routingCode?: string;
    startDate?: string;
    status?: Status;
    swift?: string;
}

export interface BulkRequestBody {
    employeeIds?: string[];
}

export enum EssStatus {
    inactive = 'inactive',
    account_created = 'account_created',
    invitation_sent = 'invitation_sent',
}

export interface ESSInvitation {
    employees?: Employee[];
    welcomeMessage?: string;
}

export interface ActivationError {
    employeeId: number;
    referenceCode: string;
    errorMessages: {
        message: string,
    }[];
}

export enum Tag {
    pending_termination = 'pending_termination',
    starters = 'starters',
    leavers = 'leavers',
}

export interface PeriodWithPayElements extends PayrollPeriod {
    payElements: PeriodPayElement[];
}

export interface PayElementWithPeriod extends PeriodPayElement {
    period: PayrollPeriod;
}

export interface EmployeePayElementDisplay extends EmployeePayElementProperties {
    source?: string;
    label: string;
    payElementName: string;
    currencyAndFrequency?: string;
    annualAmount: number | null;
    allowEdit: boolean;
    childElement: boolean;
    noChange?: boolean;
    status?: string;
    changes?: EmployeePayElementChange[];
    id?: number | null;
}

export interface EmployeePayElementHistory {
    label: string;
    currency: string;
    amount: number;
    startDate: string;
    endDate?: string;
    period: string;
    childElement: boolean;
    rootEmployeePayElementId: number;
    payUnit?: string;
    source?: string;
}

export type GroupedEmployeePayElementHistory = {
    history: EmployeePayElementHistory[];
    totalAmount?: number;
    active?: boolean;
    currencyAndFrequency?: string;
};

export interface SalarySectionData {
    label: string;
    code: string;
    payElements: EmployeePayElementDisplay[];
    historyElements: EmployeePayElementHistory[];
}

export interface EmployeePayElementDetails extends EmployeePayElementDisplay {
    annualAmount: number | null;
    amount?: number;
    payUnit?: PayUnitType;
    payElementName: string;
    currency?: string;
    id?: number | null;
    childElement: boolean;
}
  
export interface TerminationFieldEntityValues {
    code: string;
    value: string;
}

export interface TerminationFieldEntity {
    entity: string;
    entityId: string;
    values: TerminationFieldEntityValues[];
}

export interface TerminationPayouts {
    terminationFieldEntities: TerminationFieldEntity[];
    terminationPayElements: PayElement[];
}
