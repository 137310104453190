import {constants, interfaces, intl, stylingVariables} from '@global';
import {RouteHelper} from '@library';
import React, {PureComponent} from 'react';
import {connect} from 'react-redux';

import {Actions as CommonActions, Can, Icon} from '@common';
import styled from 'styled-components';

import {clearOldAndGetEmployees, openColumnSettingsModal, resetOrdersAndFilters} from './redux';
import {goToOldMssRoute} from '../redux';
import {RootState} from '../../rootReducer';
import * as selectors from './selectors';
import ClearFiltersAndSorts from '../common/Employee/ClearFiltersAndSorts';

const StyledWrapper = styled.div`
  display: flex;
`;
const StyledActionsIconWrapper = styled.div<{isOpened: boolean}>`
  display: flex;
  height: 20px;
  width: 20px;
  align-items: center;
  justify-content: center;
  svg {
    ${props => props.isOpened && `
        transform: rotate(45deg);
    `};
    transition: all .3s;
  }
`;

interface ActionsProps {
    orders: interfaces.ListingOrders;
    filters: interfaces.ListingFilters;
    resetOrdersAndFilters: () => void;
    clearOldAndGetEmployees: () => void;

    goToOldMssRoute: (path: string) => void;
    openColumnSettingsModal: () => void;
}

export class Actions extends PureComponent<ActionsProps> {
    render() {
        return (
            <StyledWrapper>
                <ClearFiltersAndSorts
                    clearOldAndGetEmployees={this.props.clearOldAndGetEmployees}
                    resetOrdersAndFilters={this.props.resetOrdersAndFilters}
                    orders={this.props.orders}
                    filters={this.props.filters}
                />

                <CommonActions.DropdownActions
                    toggleByHovering={true}
                    label={intl.get('add_employee_s')}
                    onIconRender={({isOpened}: {isOpened: boolean}) =>
                        <StyledActionsIconWrapper isOpened={isOpened}>
                            <Icon type={constants.IconTypes.BOLD_ADD}
                                  width={16}
                                  height={16}
                                  fillColor={stylingVariables.colorPalette.white}
                            />
                        </StyledActionsIconWrapper>

                    }
                >
                    <Can run={constants.PermissionCode.EMPLOYEES_ACTION_ADD_EMPLOYEE}>
                        <CommonActions.Action
                            style={{width: '100%', justifyContent: 'flex-end'}}
                            onClick={RouteHelper.goToEmployeeCreatePage}
                        >
                            {intl.get('add_employee')}
                        </CommonActions.Action>
                    </Can>
                    <Can run={constants.PermissionCode.EMPLOYEES_ACTION_ADD_EMPLOYEE}>
                        <CommonActions.Action
                            style={{width: '100%', justifyContent: 'flex-end'}}
                            onClick={() => this.props.goToOldMssRoute('/employees/import')}
                        >
                            {intl.get('import_employee_data_from_excel')}
                        </CommonActions.Action>
                    </Can>
                </CommonActions.DropdownActions>
            </StyledWrapper>
        );
    }
}

const mapStateToProps = (state: RootState, ownProps: any) => ({
    orders: selectors.getOrders(state, ownProps),
    filters: selectors.getFilters(state, ownProps),
});

const mapDispatchToProps = {
    goToOldMssRoute,
    openColumnSettingsModal,
    resetOrdersAndFilters,
    clearOldAndGetEmployees,
};

export default connect(mapStateToProps, mapDispatchToProps)(Actions);
