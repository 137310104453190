import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';

import {EmployeeInterfaces, ApiObject} from '@api';
import Accounts from './Accounts/Accounts'

import {RootState} from '../../../../rootReducer';
import ReactResizeDetector from 'react-resize-detector';
import PaymentProcessor from './PaymentProcessor/PaymentProcessor'

const StyledWrapper = styled.div`
  display: flex;
  min-height: 400px;
`;

interface IProps {
    employee: EmployeeInterfaces.Employee;
    group: ApiObject.FieldGroup|null;
    isEdit: boolean;
}

class Payment extends PureComponent<IProps> {
    public render() {
        if (!this.props.group) {
            return null;
        }

        const useHyperwallet = this.props.employee.paymentProcessor
            ? this.props.employee.paymentProcessor.toLowerCase() === 'hyperwallet'
            : false

        return (
            <StyledWrapper>

                <ReactResizeDetector handleWidth={true}>
                { useHyperwallet
                    ?
                        (<PaymentProcessor 
                            isEdit={this.props.isEdit}
                            employee={this.props.employee}
                        />)
                    :               
                        (<Accounts
                            fieldGroup={this.props.group}
                            isEdit={this.props.isEdit}
                        />)
                }
                </ReactResizeDetector>
            </StyledWrapper>
        );
    }
}

const mapStateToProps = (state: RootState) => ({});

export default connect(mapStateToProps)(Payment);
