import React, {PureComponent} from 'react';
import styled from 'styled-components';

import {stylingVariables} from '@global';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Container = styled.label`
  position: relative;
  display: inline-block;
  width: 34px;
  height: 16px;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
`;

const Circle = styled.span<{isChecked: boolean}>`
  position: absolute;
  top: 2px;
  bottom: 2px;
  right: ${props => props.isChecked ? '2px' : '20px'};
  left: ${props => props.isChecked ? '20px' : '2px'};
  background-color: #fff;
  border-radius: 50%;
  transition: all 100ms ease;
  z-index: 1;
  width: 12px;
  height: 12px;
`;

const Background = styled.span<{isChecked: boolean}>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 9px;
  background-color: ${props => props.isChecked
    ? stylingVariables.colorPalette.green
    : stylingVariables.colorPalette.darkGray
  };
`;

const Label = styled.span`
  margin: 0 5px;
  font-size: ${stylingVariables.fontSize.medium};
  font-weight: ${stylingVariables.fontWeight.regular};
  color: ${stylingVariables.colorPalette.deepGray};
`;

interface IProps {
    label?: string;
    isChecked: boolean;
    onChange: (value: boolean) => void;
}

export default class ToggleButton extends PureComponent<IProps> {
    render() {
        return (
            <Wrapper>
                <Container onClick={this.handleClick}>
                    <Circle isChecked={this.props.isChecked}/>
                    <Background isChecked={this.props.isChecked}/>
                </Container>
                {
                    this.props.label &&
                    <Label>{this.props.label}</Label>
                }
            </Wrapper>
        );
    }

    private handleClick = () => {
        this.props.onChange(!this.props.isChecked);
    }
}
