import { createSelector } from 'reselect';
import moment from 'moment';

import { LeaveInterfaces, EmployeeInterfaces } from '@api';

import { RootState } from '../../rootReducer';
import { getUsingDefaultLanguage } from 'entrypoints/selectors';

export const getPeriodFilterFrom = createSelector(
    [(state: RootState, props: any) => state.leaves.filter.from],
    date => moment(date),
);
export const getPeriodFilterTill = createSelector(
    [(state: RootState, props: any) => state.leaves.filter.till],
    date => moment(date),
);
export const getTypeIdFilter = (state: RootState, props: any): number | null => state.leaves.filter.typeId;
export const getStatusFilter = (state: RootState, props: any): string | null => state.leaves.filter.status;
export const getSearchQuery = (state: RootState): string => state.leaves.searchQuery;
export const getLatestEmployeeBalances = (state: RootState) => state.leaves.latestBalances;
export const getLeaveModalState = (state: RootState) => state.leaves.transactionModal;
export const getLeaveTransitionalBalances = (state:RootState) => state.employee.leave.transitionalBalances;

export const getTotalCount = (state: RootState, props: any): number => state.leaves.totalCount;

export const getLeaveTypes = createSelector(
    [(state: RootState, props: any) => state.leaves.types, getUsingDefaultLanguage],
    (leaveTypes: LeaveInterfaces.Type[], useLocalName: boolean) => {
        return leaveTypes.map((lt: LeaveInterfaces.Type) => {
        return { ...lt, name: useLocalName && lt.localName ? lt.localName : lt.name }
    })}
);

export const getActiveLeaveTypes = createSelector(
    [getLeaveTypes],
    (leaveTypes): LeaveInterfaces.Type[] => leaveTypes.filter(x => x.active)
);

export const getLeaves = (state: RootState, props: any) => state.leaves.leaves;
export const getFilters = (state: RootState, props: any) => state.leaves.filters;
export const getTags = (state: RootState, props: any) => state.leaves.tags;
export const getOrders = (state: RootState, props: any) => state.leaves.orders;

export const getEmployees = createSelector(
    [(state: RootState, props: any) => state.leaves],
    ({employees, cutOffStatuses}) => employees.map((employee: EmployeeInterfaces.Employee, index: number) => {
        employee.index = index + 1;
        employee.person = employee.person || {};
        employee.fullName = `${employee.person.firstName} ${employee.person.lastName}`;
        if (cutOffStatuses && cutOffStatuses[employee.id]){
            employee.cuttOffs = cutOffStatuses[employee.id]
          }  
        return employee;
    }),
);

export const employeeIds = createSelector(
    [(state: RootState) => state.leaves.employees],
    employees => employees.map(emp => {
        return emp.id;
    }),
);

export const getEmployeeLeaves = createSelector(
    [getEmployees, getLeaves, getLeaveTypes, getPeriodFilterFrom, getPeriodFilterTill, employeeIds],
    (employees, leaves): LeaveInterfaces.EmployeeLeave[] => {
        const employeeLeaves: LeaveInterfaces.EmployeeLeave[] = [];

        employees.forEach((employee, index) => {
            const leave = leaves[employee.id] ? leaves[employee.id] : { transactions: [], balances: [] };

            employeeLeaves.push({ ...employee, ...leave, index: index + 1 });

        });
        return employeeLeaves;
    },
);

export const getEmployeeById = createSelector(
    [getEmployees, (state: RootState, ownProps: any) => ownProps.employeeId],
    (employees, id) => {
        return employees.find(e => e.id === id) || null;
    },
);

export const getLeaveRequests = (state: RootState): LeaveInterfaces.EmployeeLeaveHistory[] =>
    state.leaves.leaveRequests;

export const getFieldGroups = (state: RootState): any => state.leaves.fieldGroups;
export const getEmployeeGroups = (state: RootState): any => state.leaves.employeeGroups;

export const getLeaveTypesWithBalance = createSelector(
    [getLeaveTypes],
    (leaveTypes): LeaveInterfaces.Type[] => {
        return leaveTypes.filter(t => t.hasBalance === true);
    },
);

const prepareTransaction = (transaction: LeaveInterfaces.EmployeeLeaveHistory, types: LeaveInterfaces.Type[]) => {
    const type = types.find(t => t.id === transaction.transaction.leaveTypeId);

    transaction.transaction.leaveTypeName = type ? type.name : '';
    return transaction;
};

export const getFilteredTransactions = createSelector(
    [
        getLeaveRequests,
        getLeaveTypes,
        getPeriodFilterFrom,
        getPeriodFilterTill,
    ],
    (leaves, types, from, till): LeaveInterfaces.EmployeeLeaveHistory[] => {
        return leaves && leaves.length > 0 ?
        leaves.map(transaction => prepareTransaction(transaction, types)) : [];
    },
);

export const getTransactions = createSelector(
    [getFilteredTransactions],
    (transactions): LeaveInterfaces.EmployeeLeaveHistoryGrouped[] => {

        const transactionsMap = new Map();

        transactions.forEach((trans, index) => {
            const groupKey = `${trans.employee.id}_${trans.transaction.leaveTypeId}_${trans.transaction.status}`;
            if (transactionsMap.has(groupKey)) {
                transactionsMap.get(groupKey).transactions.push(trans.transaction);
            } else {
                const transactionInfo: LeaveInterfaces.TransactionGroupInfo = {
                    leaveTypeId: trans.transaction.leaveTypeId,
                    leaveTypeName: trans.transaction.leaveTypeName,
                    status: trans.transaction.status,
                    creatorEmployee: trans.transaction.creatorEmployee,
                    source: trans.transaction.source,
                    startDate: trans.transaction.startDate,
                };
                transactionsMap.set(groupKey, { employee: trans.employee, transactionInfo,
                    transactions: [trans.transaction]});
            }
        });

        const indexedTransactions: LeaveInterfaces.EmployeeLeaveHistoryGrouped[] = [...transactionsMap.values()]
            .map((value, index) => {
                return { index: index + 1, employee: value.employee, transactionInfo: value.transactionInfo,
                    transactions: value.transactions
                        .sort((a: { startDate: string; }, b: { startDate: string; }) => {
                        return (moment(a.startDate, 'YYYY-MM-DD')
                            .isAfter(moment(b.startDate, 'YYYY-MM-DD'))) ? 1 : -1;
                    })};
            });

        return indexedTransactions;
    },
);
