import {ApiObject} from '@api';
import {intl, interfaces} from '@global';
import {Functions} from '@library';
import arrayMove from 'array-move';
import React, {PureComponent} from 'react';
import Field from './Field';
import {
    GroupWrapper,
    FieldContainer,
    RemoveFieldIconContainer,
    FieldAddButton
} from './Styles';
import {Actions, RemoveIcon} from '@common';
import {SortableElement, SortableContainer,} from 'react-sortable-hoc';

const SortableItem = SortableElement((props: any) => (
    <FieldContainer>
        <Field {...props} />
        {!props.readOnly && !props.field.notDeletable &&
            <RemoveFieldIconContainer onClick={props.onRemove}>
                <RemoveIcon size={14} />
            </RemoveFieldIconContainer>
        }
    </FieldContainer>
));

const Sortable = SortableContainer(({children}: {children: any}) => {
    return <ul>{children}</ul>;
});


interface IProps {
    readOnly: boolean;
    group: interfaces.IGroupFieldBuilderGroup;
    listFields: ApiObject.SelectOption[];
    selectedFieldKeys: string[];

    onUpdateGroup: (group: interfaces.IGroupFieldBuilderGroup) => any;
    onRemove: () => any;
    invalidSchemaKeys: string[];
    hasError: boolean;
}

export default class Group extends PureComponent<IProps> {
    addField = () => {
        this.props.onUpdateGroup({
            ...this.props.group,
            fields: [
                ...this.props.group.fields,
                {
                    uniqueKey: Functions.uniqueID(),
                    key: '',
                    label: '',
                }
            ]
        });
    }

    updateFields = (fields: interfaces.IGroupFieldBuilderField[]) => {
        this.props.onUpdateGroup({
            ...this.props.group,
            fields
        })
    }


    updateLabel = (value: string) => {
        this.props.onUpdateGroup({
            ...this.props.group,
            label: value
        })
    }

    updateField = (fieldIndex: number) => (field: interfaces.IGroupFieldBuilderField) => {
        const fields = [...this.props.group.fields];
        fields.splice(fieldIndex, 1, field);
        this.updateFields(fields);
    }

    removeField = (fieldIndex: number) => {
        const fields = [...this.props.group.fields];
        fields.splice(fieldIndex, 1);
        this.updateFields(fields);
    }

    onSortEnd = ({oldIndex, newIndex}: {oldIndex: number, newIndex: number}) => {
        this.updateFields(arrayMove([...this.props.group.fields], oldIndex, newIndex));
    }

    render() {
        return <GroupWrapper hasError={this.props.hasError}>

            <Sortable onSortEnd={this.onSortEnd} useDragHandle helperClass={'big-z-index'}>
                {this.props.group.fields.map((field, index) =>
                    <SortableItem
                        index={index}
                        key={field.uniqueKey}
                        hasError={field.uniqueKey ? this.props.invalidSchemaKeys.includes(field.uniqueKey) : false}
                        field={field}
                        readOnly={this.props.readOnly}
                        onUpdate={this.updateField(index)}
                        onRemove={() => this.removeField(index)}
                        selectedFieldKeys={this.props.selectedFieldKeys}
                        list={this.props.listFields}
                    />
                )}
            </Sortable>

            {!this.props.readOnly &&
                <>
                    <FieldAddButton>
                        <Actions.PlusAction onClick={this.addField} size={18} fontSize={12} iconSize={6}>
                            {intl.get('add')}
                        </Actions.PlusAction>
                    </FieldAddButton>
                </>
            }
        </GroupWrapper>;
    }
}
