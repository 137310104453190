import React, { PureComponent } from 'react'
import { intl, constants } from '@global'
import { connect } from 'react-redux'

import { EmployeeInterfaces, ApiObject } from '@api'
import { Actions, Can } from '@common'

import { RootState } from '../../../../../rootReducer'
import * as employeeSelectors from '../../../selectors'
import { saveDependentFields } from '../redux'

import Modal from './Modal'

interface IProps {
  employee: EmployeeInterfaces.Employee
  group: ApiObject.FieldGroup
  errors: ApiObject.EntityFieldErrorMessage[]
  saveDependentFields: (employeeId: number, fields: any) => Promise<boolean>
}

interface IState {
  isModalOpen: boolean
}

export class Create extends PureComponent<IProps, IState> {
  public constructor(props: IProps) {
    super(props)

    this.state = {
      isModalOpen: false,
    }
  }

  render() {
    return (
      <>
        <Can run={constants.PermissionCode.EMPLOYEES_ACTIONS}>
          <Actions.PlusAction onClick={this.open} size={30} fontSize={14}>
            {intl.get('add_dependent')}
          </Actions.PlusAction>
        </Can>

        {this.state.isModalOpen && (
          <Modal
            group={this.props.group}
            errors={this.props.errors}
            onClickSave={this.save}
            onClickClose={this.close}
          />
        )}
      </>
    )
  }

  public save = async(fields: ApiObject.FieldValue[]) => {
    const isOk: boolean = await this.props.saveDependentFields(this.props.employee.id, [
      {
        deleteEntity: false,
        entity: this.props.group.code,
        entityId: null,
        values: fields,
      },
    ]);

    if (isOk && this.props.errors.length === 0) {
      this.close();
    }
  }

  public open = () => {
    this.setState({ isModalOpen: true })
  }

  public close = () => {
    this.setState({ isModalOpen: false })
  }
}

const mapStateToProps = (state: RootState) => ({
  errors: employeeSelectors.getErrorsFields(state),
})

const mapDispatchToProps = {
  saveDependentFields,
}

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(Create)
