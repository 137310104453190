import React, {PureComponent} from "react";
import styled from 'styled-components';
import {intl} from '@global';

import {stylingVariables} from '@global';
import {Moment} from 'moment';

const StyledActivityLogWrapper = styled.div`
  flex-grow: 1;
  background-color: ${stylingVariables.colorPalette.white};
  box-shadow: ${stylingVariables.layout.boxShadow};
  border-radius: ${stylingVariables.layout.borderRadius};
`;

const StyledActivityLogHeader = styled.div`
  padding: 20px;
  font-size: ${stylingVariables.fontSize.mediumLarge};
  font-weight: ${stylingVariables.fontWeight.semibold};
  border-bottom: 1px solid ${stylingVariables.colorPalette.darkGray};
`;

const StyledEventWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: ${stylingVariables.fontSize.mediumLarge};
  position: relative;
  margin-bottom: 30px; 
  &:after {
    content: ' ';
    display: inline-block;
    height: 35px;
    position: absolute;
    top: 20px;
    left: 6px;
    border: 1px dashed ${stylingVariables.colorPalette.darkGray};    
  } 
  &:last-child {
    margin-bottom: 0;
    &:after {
      display: none;
    }
  } 
  span:first-child {   
    font-weight: ${stylingVariables.fontWeight.semibold};   
    &:before {
      content: '•';
      display: inline-flex;
      align-items: center;
      justify-content: center;
      color: ${stylingVariables.colorPalette.darkGray};     
      border: 1px solid ${stylingVariables.colorPalette.darkGray};
      border-radius: 50%;
      width: 15px;
      height: 15px;
      margin-right: 5px;
    }   
  }
  span:last-child {
    color: ${stylingVariables.colorPalette.deepGray};
    font-size: ${stylingVariables.fontSize.mediumSmall};
    font-weight: ${stylingVariables.fontWeight.light};
    margin-left: 20px;
    margin-top: 5px;
  }
`;

interface ActivityLogProps {
    title: string,
    items: {event: string, happened_at: Moment, by?: string}[]
}

export default class ActivityLog extends PureComponent<ActivityLogProps> {
    static defaultProps = {
        items: []
    };

    public render() {
        return (
            <StyledActivityLogWrapper>
                <StyledActivityLogHeader>{intl.get(this.props.title)}</StyledActivityLogHeader>

                <div style={{padding: '20px'}}>
                    {
                        this.props.items.map((item, index) => (
                            <StyledEventWrapper key={index}>
                                <span>{item.event}</span>

                                <span>
                                    {item.happened_at.format('DD MMMM, YYYY')}
                                    {item.by && ` by ${item.by}`}
                                </span>
                            </StyledEventWrapper>
                        ))
                    }
                </div>
            </StyledActivityLogWrapper>
        );
    }
}
