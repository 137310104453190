import {ApiObject, EmployeeInterfaces} from '@api';
import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';

import {Back} from '@common';

import {RootState} from '../../rootReducer';
import EmployeeSelector from '../common/Employee/EmployeeSelector/EmployeeSelector';
import * as selectors from './selectors';
import {RouteHelper} from '@library';

const StyledHeader = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

interface IProps {
    fields: ApiObject.Fields;
    employee: EmployeeInterfaces.Employee | null;
}

export class Header extends PureComponent<IProps> {
    public render() {
        return (
            <StyledHeader>
                <Back.Button onGoBack={() => RouteHelper.goToEmployeesPage()} />
                <EmployeeSelector currentEmployee={this.props.employee} />
            </StyledHeader>
        );
    }
}

const mapStateToProps = (state: RootState) => ({
    fields: selectors.getFields(state),
    employee: selectors.getEmployee(state),
});

export default connect(mapStateToProps)(Header);
