import React, { PureComponent } from 'react'
import { intl, numberFormat } from '@global'
import moment from 'moment'
import styled from 'styled-components'
import { connect } from 'react-redux'

import { stylingVariables } from '@global'
import { LeaveInterfaces } from '@api'
import { Layout } from '@common'
import { RootState } from '../../../rootReducer'

import * as selectors from '../../Leaves/selectors'

const Subtitle = styled(Layout.Subtitle)<{ short?: boolean; underlineHover?: boolean }>`
  ${(props) => (props.underlineHover ? 'cursor: pointer;' : '')}

  label {
    ${(props) => (props.underlineHover ? 'cursor: pointer;' : '')}
    margin-left: 5px;
    color: ${stylingVariables.colorPalette.white};
    font-size: ${stylingVariables.fontSize.medium};
    ${(props) => (props.short ? 'background: ' + stylingVariables.colorPalette.secondary + ';' : '')}
    ${(props) => (props.short ? 'padding: 2px 6px;' : '')}
      ${(props) => (props.short ? 'border-radius: 4px;' : '')}
  }
`

const PeriodWrapper = styled.span<{ underlineHover?: boolean }>`
  font-size: ${stylingVariables.fontSize.default};
  ${(props) =>
    props.underlineHover &&
    `
      &:hover {
       text-decoration: underline;
      }
  `}
`

const HoverLabel = styled.span`
  margin-left: 100px;
  color: ${stylingVariables.colorPalette.primary};
  font-size: ${stylingVariables.fontSize.default};

  span {
    font-weight: ${stylingVariables.fontWeight.bold} !important;
  }

  &:hover {
    text-decoration: underline;
  }
`

interface TransactionPeriodProps {
  transaction: LeaveInterfaces.Transaction
  short?: boolean
  underlineHover?: boolean
  leaveTypes: LeaveInterfaces.Type[]
}

export class TransactionPeriod extends PureComponent<TransactionPeriodProps> {
  render() {
    const startDate = moment(this.props.transaction.startDate, 'YYYY-MM-DD')
    const endDate = this.props.transaction.endDate ? moment(this.props.transaction.endDate, 'YYYY-MM-DD') : null

    const daysAmount = this.props.transaction.amount ? numberFormat(this.props.transaction.amount, true) : 0
    const underlineHover = this.props.underlineHover

    const leaveType = this.props.leaveTypes.find((type) => {
      return type.id === this.props.transaction.leaveTypeId
    })

    let suffixShort = leaveType && leaveType.amountType === 'hours' ? 'h' : 'd'
    let suffixLong = leaveType && leaveType.amountType === 'hours' ? 'hours' : 'days'

    if (!endDate) {
      return (
        <Subtitle short={this.props.short} underlineHover={underlineHover}>
          <PeriodWrapper underlineHover={underlineHover}>{startDate.format('MMM DD, YYYY')}</PeriodWrapper>
          {this.props.short && <label>{intl.get('open_ended_leave')}</label>}
          {!this.props.short && <label>{intl.get('open_ended')}</label>}
          {this.props.underlineHover && <HoverLabel>{intl.get('view')}</HoverLabel>}
        </Subtitle>
      )
    }

    return (
      <Subtitle short={this.props.short} underlineHover={underlineHover}>
        <PeriodWrapper underlineHover={underlineHover}>
          {startDate.format('MMM DD, YYYY')} - {endDate.format('MMM DD, YYYY')}
        </PeriodWrapper>
        {this.props.short && (
          <label>
            {daysAmount} {intl.get(suffixShort)}
          </label>
        )}
        {!this.props.short && (
          <label>
            {daysAmount} {intl.get(suffixLong)}
          </label>
        )}
        {this.props.underlineHover && <HoverLabel>{intl.get('view')}</HoverLabel>}
      </Subtitle>
    )
  }
}

const mapStateToProps = (state: RootState, ownProps: any) => ({
  leaveTypes: selectors.getLeaveTypes(state, ownProps),
})

export default connect(mapStateToProps, null)(TransactionPeriod)
