import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {Column} from "react-virtualized";
import {intl} from '@global';
import {Actions as CommonActions, defaultInfiniteTableCellDataGetter, IconPopover} from "@common";
import EditBalances, {ModalLocation} from './../../../../common/Leave/Balance/EditBalances';
import {EmployeeInterfaces} from '@api';
import {RouteHelper} from '@library';
import {constants} from '@global';
import styled from 'styled-components';

const MasterdataAlert = styled.div`
    position: absolute;
    left: 10px;
    top: 28px;

    div {
        margin: 0;
    }
`

interface IProps {
    isLoading: boolean;
    cellData: EmployeeInterfaces.Employee;
    balanceEditDisabled: boolean;
}

interface IState {
    employeeIds: Set<number>;
    editBalances: boolean;
}


class Cell extends PureComponent<IProps, IState> {
    public constructor(props: IProps) {
        super(props);

        this.state = {
            employeeIds: new Set([props.cellData.id]),
            editBalances: false
        };
    }

    private toggleModal(modalName: keyof IState) {
        this.setState({...this.state, [modalName]: !this.state[modalName]})
    }

    private get masterdataEditAllowed(): boolean {
        const {cuttOffs} = this.props.cellData;

        if (cuttOffs &&  cuttOffs.masterdataEditAllowed) {
            return cuttOffs.masterdataEditAllowed
        }

        return false
    }

    public render() {
        return (
            <CommonActions.MenuActions>
                <CommonActions.Action
                    isDisabled={this.props.isLoading || this.props.balanceEditDisabled || !this.masterdataEditAllowed}
                    onClick={() => this.toggleModal('editBalances')}>
                    {intl.get('edit_balances')}
                </CommonActions.Action>
                {!this.masterdataEditAllowed && (
                    <MasterdataAlert>
                        <IconPopover
                            type={'info'}
                            message={intl.get('masterdata_edit_not_allowed')} iconSize={14}
                            positionLeft={true}
                        />
                    </MasterdataAlert>
                )}


                {this.state.editBalances ? this.renderEditBalances() : null}
                <CommonActions.Action
                    onClick={(event: any)=> this.redirectToEmployeePage(event, this.props.cellData.id)}>
                    {intl.get('view_employee_card')}
                </CommonActions.Action>
            </CommonActions.MenuActions>
        );
    }

    private redirectToEmployeePage(event: any, id: number) {
        RouteHelper.goToEmployeePage(id, constants.DEFAULT_FIELD_GROUP_LEAVE);
    }

    private renderEditBalances() {
        return (
            <EditBalances
                isOpen={this.state.editBalances}
                forEmployeeIds={this.state.employeeIds}
                isLoading={this.props.isLoading}
                employee={this.props.cellData}
                modalLocation={ModalLocation.LeavesView}
                onClickLink={this.redirectToEmployeePage}
                onClose={() => this.toggleModal('editBalances')}
            />
        );
    }
}

const mapDispatchToProps = {
};

const ConnectedCell = connect(null, mapDispatchToProps)(Cell);

const Actions = (balanceEditDisabled: boolean) => (
    <Column
        dataKey='actions'
        width={80}
        flexGrow={0}
        flexShrink={0}
        cellDataGetter={defaultInfiniteTableCellDataGetter}
        cellRenderer={({cellData}) =>
          <ConnectedCell balanceEditDisabled={balanceEditDisabled} cellData={cellData} isLoading={false} />}
    />
);

export default Actions;
