import React, {PureComponent} from 'react';
import styled from 'styled-components';
import Group from './Group';

interface AlphabetListProps {
    groups: {
        group: string,
        children: any[],
    }[];
    itemRenderer: (item: any) => any;
}

const StyledAlphabetListWrapper = styled.div`
  width: 100%;
  height: 400px;
  overflow: auto;
  padding-right: 10px;
  margin-bottom: 20px;
`;

export default class AlphabetList extends PureComponent<AlphabetListProps> {
    render() {
        return (
            <StyledAlphabetListWrapper>
                {
                    Object.keys(this.props.groups).map((keyName: any) => (
                        <Group key={keyName}
                               letter={keyName}
                               items={this.props.groups[keyName].children}
                               itemRenderer={this.props.itemRenderer}
                        />
                    ))
                }
            </StyledAlphabetListWrapper>
        );
    }
}
