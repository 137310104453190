import React, {PureComponent} from 'react';
import styled from 'styled-components';
import {intl} from '@global';

import {stylingVariables, history, constants} from '@global';
import {Icon} from '@common';

const StyledBackButton = styled.button`
  display:inline-block;
  border-radius: ${stylingVariables.layout.borderRadius};
  padding: 12px;
  width: 39px;
  height: 39px;
  background-color: ${stylingVariables.colorPalette.lightGray};
  cursor: pointer;
  border: none;
  box-shadow: none;
  box-sizing: border-box;
  svg {
    transition: all .3s;
  }
  &:hover {
    svg {
      transition: all .3s;
      transform: translate(-6px);
    }
  }
`;

interface ButtonProps {
    onGoBack?: () => any;
}

export class Button extends PureComponent<ButtonProps> {
    render() {
        return (
            <StyledBackButton onClick={this.props.onGoBack ? this.props.onGoBack : Button.goBack}>
                <Icon type={constants.IconTypes.ARROW_BACK}
                      fillColor={stylingVariables.colorPalette.orange}
                      width={15}
                      height={15}
                />
            </StyledBackButton>
        );
    }

    private static goBack() {
        history.goBack();
    }
}

export const StyledHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  ${StyledBackButton} {
    margin-right: 5px;
  }

  h4 {
    color: ${stylingVariables.colorPalette.darkest};
    span {
      color: ${stylingVariables.colorPalette.green};
    }
  }
`;

interface HeaderProps {
    title: string;
    subtitle?: string|null;
    onGoBack?: () => any;
}

export class Header extends PureComponent<HeaderProps> {
    render() {
        return (
            <StyledHeaderWrapper>
                <Button onGoBack={this.props.onGoBack}/>

                <h4>
                    {intl.get(this.props.title)}
                    {this.props.subtitle && ` - ${this.props.subtitle}`}
                </h4>
            </StyledHeaderWrapper>
        );
    }
}

export const StyledFooterButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  ${StyledBackButton} {
    background-color: ${stylingVariables.colorPalette.gray};
    margin-right: 5px;
  }

  span {
    font-weight: ${stylingVariables.fontWeight.semibold};
    font-size: ${stylingVariables.fontSize.medium};
    color: ${stylingVariables.colorPalette.green};
  }
`;

export class FooterButton extends PureComponent {
    render() {
        return (
            <StyledFooterButtonWrapper>
                <Button/>

                {intl.get('back')}
            </StyledFooterButtonWrapper>
        );
    }
}
