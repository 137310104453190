import React, {CSSProperties, PureComponent} from 'react';
import {connect} from 'react-redux';
import {StatusBar as CommonStatusBar} from '@common';
import {stylingVariables} from '@global';
import {RootState} from '../../../../rootReducer';
import {SalaryTabType, setSalaryTabType} from '../../redux';
import * as selectors from '../../selectors';

const StatusBarStyle: CSSProperties = {
    backgroundColor: stylingVariables.colorPalette.lightGray,
};
const StatusBarItemStyles: CSSProperties = {
    backgroundColor: stylingVariables.colorPalette.lightGray,
    fontWeight: stylingVariables.fontWeight.bold,
};
const StatusBarItemActiveStyles: CSSProperties = {
    backgroundColor: stylingVariables.colorPalette.darkGray,
};

interface IProps {
    salaryTabType: SalaryTabType;

    setSalaryTabType: (type: SalaryTabType) => any;
}

export class TypesBar extends PureComponent<IProps, any> {
    private get types() {
        return [
            {key: SalaryTabType.all},
            {key: SalaryTabType.recurrings},
            {key: SalaryTabType.one_times},
            {key: SalaryTabType.units},
        ].filter(Boolean) as {key: SalaryTabType, isDisabled?: boolean}[];
    }

    private onSetSalaryTabType = (type: string) => {
        const found = this.types.find(i => i.key === type);
        if (found && found.isDisabled) {
            return;
        }

        this.props.setSalaryTabType(type as SalaryTabType);
    }

    public render() {
        return (
            <>
                <CommonStatusBar.StatusBar style={StatusBarStyle}>
                    {this.types.map((type: {key: SalaryTabType, isDisabled?: boolean}, index: number) => (
                        <CommonStatusBar.Item
                            styles={StatusBarItemStyles}
                            activeStyles={StatusBarItemActiveStyles}
                            key={index}
                            status={type.key}
                            isActive={type.key === this.props.salaryTabType}
                            isDisabled={type.isDisabled}
                            onClick={this.onSetSalaryTabType}
                        />
                    ))}
                </CommonStatusBar.StatusBar>
            </>
        );
    }
}

const mapStateToProps = (state: RootState) => ({
    salaryTabType: selectors.getSalaryTabType(state),
});

const mapDispatchToProps = {
    setSalaryTabType,
};

export default connect(mapStateToProps, mapDispatchToProps)(TypesBar);
