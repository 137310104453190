import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {get} from 'lodash';

import {constants} from '@global';
import {ApiObject, ApiPayElement} from '@api';

import {RootState} from '../../../../../rootReducer';
import * as selectors from '../../../../selectors';
import Field from './Field';

interface IProps {
    legalEntityId: number|null;

    group: ApiObject.FieldGroup;
    fields: any;
}

interface IState {
    requestedBankName: string|null;

    swift: ApiObject.Field|null;
    bankName: ApiObject.Field|null;
}

export class BankSwiftAndName extends PureComponent<IProps, IState>  {
    public constructor(props: IProps) {
        super(props);

        this.state = {
            requestedBankName: null,

            swift: this.props.group.fields
                .find(f => f.code === constants.DEFAULT_FIELD_CODE_SWIFT) || null,
            bankName: this.props.group.fields
                .find(f => f.code === constants.DEFAULT_FIELD_CODE_BANK_NAME) || null,
        };

        if (this.state.swift) {
            const value = get(this.props.fields, `${this.state.swift.entity}.${this.state.swift.code}`);

            this.requestBankName(value);
        }
    }

    render() {
        return (
            <>
                {
                    this.state.swift &&
                    <Field
                        key={`${this.state.swift.entity}_${this.state.swift.code}`}
                        field={this.state.swift}
                        value={
                            get(this.props.fields, `${this.state.swift.entity}.${this.state.swift.code}`)
                        }
                    />
                }

                {
                    this.state.bankName &&
                    <Field
                        key={`${this.state.bankName.entity}_${this.state.bankName.code}`}
                        field={this.state.bankName}
                        value={this.state.requestedBankName ?? get(this.props.fields, `${this.state.bankName.entity}.${this.state.bankName.code}`)}
                    />
                }
            </>
        );
    }

    /**
     * Request Bank Name and Set Into State
     *
     * @param swift
     */
    public requestBankName = async(swift?: string) => {
        if (!this.props.legalEntityId || !swift) {
            return null;
        }

        this.setState({
            requestedBankName: await ApiPayElement.getBankName(this.props.legalEntityId, swift),
        });
    }
}

const mapStateToProps = (state: RootState) => ({
    legalEntityId: selectors.getCurrentLegalEntityId(state),
});

export default connect(mapStateToProps)(BankSwiftAndName);
