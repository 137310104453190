import {Employee} from './employee.interfaces';
import * as stylingVariables from '../stylingVariables';

export interface EmployeeLeave extends Employee, EmployeeLeaves {}

export interface EmployeeLeavesResponse {
    employeeLeaves?: {
        [key: string]: EmployeeLeaves,
    };
}

export interface EmployeeLeaveRequestFilter {
    employees?: string[];
    endDate?: string;
    leaveTypes?: string[];
    startDate?: string;
    statuses?: TransactionStatus[];
}

export interface EmployeeLeaves {
    balances: {
        [key: number]: Balance,
    };
    transactions: Transaction[];
}

export interface EmployeeLeaveBalances {
    balances?: {
        [key: number]: Balance,
    };
}

export interface EmployeeLatestBalances {
    [employeeId: number]: {
        balances: Balance[];
    };
}

export interface LegalEntityPeriod {
    periodId?: number;
    periodName?: string;
    startDate: string;
    endDate: string;
    periodStatus?: string;
}

export interface PeriodEmployeeBalance extends LegalEntityPeriod {
    employeeBalances: { [key: number]: EmployeeLeaveBalanceWrapper };
}

export type TransitionalBalances = {
    [key: number]: RegulationLeaveBalanceWrapper;
};

export type PeriodEmployeeTransaction = LegalEntityPeriod & {
    employeeTransactions: { [key: number]: {
            employeeId: number;
            transactions: Transaction[];
    } };
}

export interface Transaction extends ApiTransaction {
    period: LegalEntityPeriod;
    startingPeriodBeginDate?: string;
    startingPeriodEndDate?: string;
}

export type LeaveTransitionalBalanceLogEntry = LeaveBalanceRegulationEntry & {
    relativeBalance :number;
    lastPeriodBalance :number;
    leaveRegulationId? :number;
    leaveTypeName? :string;
    startingYtdBalance: number;
    startingYtdTaken: number;
    periodInitial: boolean;
    periodName?: string;
    hasBalance?: boolean;
};

export type GroupedTransactions = {
    transactionInfo: TransactionGroupInfo;
    transactions: Transaction[];
};

export interface LeaveBalanceRegulationEntry {
    amount: number;
    date: string;
    type: string;
    comment: string;
    creatorEmployee?: CreatorEmployee;
    createTime: number;
    isBaseEntry: true;
}

export interface LeaveBalancePeriodRegulationEntry {
    periodId: number;
    periodName: string;
    periodBeginDate: string;
    periodEndDate: string;
    startingYtdBalance: number;
    startingYtdTaken: number;
    startingYear1TransitionalTaken: number;
    startingYear1TransitionalBalance: number;
    periodTransitionalBalanceRemaining: number;
    periodTransitionalBalanceTaken: number;
    entries: LeaveBalanceRegulationEntry[];
    leaveRegulationId? :number;
    leaveTypeName? :string;
}

export interface RegulationLeaveBalanceWrapper {
    leaveRegulationId: number;
    entries: LeaveBalancePeriodRegulationEntry[];
}

export interface EmployeeLeaveBalanceWrapper {
    employeeId: number;
    balances: { [key: number]: Balance };
}

export interface TransitionalAmount {
    ordinal: number;
    balance: number;
    taken: number;
}

export interface Balance {
    leaveTypeId: number;
    ytdBalance?: number;
    ytdTaken?: number;
    year1TransitionalBalance?: number;
    year1TransitionalTaken?: number;
    transitionalAmounts?: TransitionalAmount[];
}

export enum TransactionStatus {
    draft = 'draft',
    pending = 'pending',
    open_ended_pending = 'open_ended_pending',
    done = 'done',
    rejected = 'rejected',
    approved = 'approved',
}

export const TransactionTypes = {
    ess: {
        full: 'Request',
        short: 'REQ',
        color: stylingVariables.colorPalette.green,
    },
    other: {
        full: 'Direct transaction',
        short: 'DT',
        color: stylingVariables.colorPalette.blue,
    },
};

export const StatusColours = {
    pending: {
        color: stylingVariables.colorPalette.brightGreen,
        text: stylingVariables.colorPalette.white,
    },
    open_ended_pending: {
        color: stylingVariables.colorPalette.brightGreen,
        text: stylingVariables.colorPalette.white,
    },
    approved: {
        color: stylingVariables.colorPalette.brightGreen,
        text: stylingVariables.colorPalette.white,
    },
    done: {
        color: stylingVariables.colorPalette.darkGray,
        text: stylingVariables.colorPalette.darkest,
    },
    rejected: {
        color: stylingVariables.colorPalette.darkRed,
        text: stylingVariables.colorPalette.white,
    },
    draft: {
        color: stylingVariables.colorPalette.yellow,
        text: stylingVariables.colorPalette.darkest,
    },
};

export const AcceptedTransactionStatuses = [
    TransactionStatus.done,
    TransactionStatus.pending,
];

export const HistoricalTransactionStatuses = [
    TransactionStatus.done,
];

export interface ApiTransaction {
    id: string;
    leaveTypeId: number;
    amount?: number;
    leaveTypeName: string;
    startDate: string;
    endDate: string | null;
    status: TransactionStatus;
    originalTransaction?: ApiTransaction;
    creatorEmployee: CreatorEmployee;
    createTime: string;
    source?: string;
}

export interface CreatorEmployee {
    id: number;
    firstName: string;
    lastName: string;
    middleName: string;
}

export interface EmployeeLeaveHistory {
    employee: Employee;
    index?: number;
    transaction: Transaction;
}

export interface TransactionGroupInfo {
    leaveTypeId: number;
    leaveTypeName: string;
    startDate: string;
    status: TransactionStatus;
    creatorEmployee: CreatorEmployee;
    source?: string;
}

export interface EmployeeLeaveHistoryGrouped {
    employee: Employee;
    index?: number;
    transactionInfo: TransactionGroupInfo;
    transactions: Transaction[];
}

export interface Leaves {
    [employeeId: number]: EmployeeLeaves;
}

export interface Transactions {
    [employeeId: number]: {
        transactions: Transaction[];
    };
}

export interface Type {
    id: number;
    name: string;
    active: boolean;
    hasBalance: boolean;
    amountType: AmountType;
    code: string;
    leaveType?: string;
    localName?: string;
}

export enum AmountType {
    workDays = 'workdays',
    calendarDays = 'calendar_days',
    hours = 'hours'
}

export interface BalanceAdjustment {
    balanceAdjustment: number;
    comment: string;
    leaveTypeId: number|null;
    employeeId: number;
    log?: LeaveBalancePeriodRegulationEntry[];
}

export interface TransactionModal {
    transactions: Transaction[];
    employee: EmployeeLeave;
}
