import React, { PureComponent } from 'react';
import styled from 'styled-components';

import { stylingVariables, intl, constants } from '@global';
import { ApiObject, PayrollInterfaces } from '@api';
import { EditableField, Icon } from '@common';

import { getErrorByEntityAndCode } from '../../../../selectors';

const Header = styled.h4`
   font-weight: ${stylingVariables.fontWeight.bold};
   font-size: ${stylingVariables.fontSize.default};
   color: ${stylingVariables.colorPalette.darkest};
   padding: 0;
`;

const Wrapper = styled.div<{ isOpen: boolean }>`
  margin-top: 20px;
  margin-bottom: ${props => props.isOpen ? '20px' : '0'};
  position: relative;
`;

const AdvancedSettingsHeader = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: ${stylingVariables.fontWeight.semibold};
  font-size: ${stylingVariables.fontSize.default};
  color: ${stylingVariables.colorPalette.darkest};
  cursor: pointer;
  margin-bottom: 15px;
`;

const IconWrapper = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${stylingVariables.colorPalette.darkGray};

  &:hover {
    background-color: ${stylingVariables.colorPalette.lightGreen};

		svg {
			fill: ${stylingVariables.colorPalette.green};
		}
  }
`

interface IProps {
    fields: ApiObject.Field[];
    errors: ApiObject.EntityFieldErrorMessage[];
    selectedPayElement?: ApiObject.PayElement;
    isEditModal: boolean;
    currentPayrollPeriod: PayrollInterfaces.PayrollPeriod | null;
    effectiveDateMissing: boolean;

    updateField: (field: ApiObject.Field, value: any) => void;
    isFieldDisabled: (field: ApiObject.Field) => boolean;
    getDefaultValueByField: (field: ApiObject.Field) => any;
    getPlaceholderValueByField: (field: ApiObject.Field) => any;
}

interface IState {
    isOpen: boolean;
}

export default class BaseFields extends PureComponent<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            isOpen: true,
        };
    }

    render() {
        const startDateField = this.props.fields.find((field: ApiObject.Field) => {
            return field.code === ApiObject.EmployeePayElementValues.start_date
        })

        return (
            <Wrapper isOpen={this.state.isOpen}>
                <AdvancedSettingsHeader onClick={this.toggle}>
                    <Header>
                        {intl.get('values')}
                    </Header>
                    <IconWrapper>
                        <Icon
                            type={this.state.isOpen ? constants.IconTypes.ARROW_UP : constants.IconTypes.ARROW_DOWN}
                            width={12}
                            height={12}
                            fillColor={stylingVariables.colorPalette.dark}
                        />
                    </IconWrapper>
                </AdvancedSettingsHeader>

                {
                    this.state.isOpen && this.props.fields.map((field: ApiObject.Field) => (
                        <EditableField
                            key={`${field.entity}_${field.code}`}
                            type={field.type}
                            label={field.label}
                            code={field.code}
                            options={field.options}
                            isRequired={field.required}
                            errorMessage={getErrorByEntityAndCode(this.props.errors, field.entity, field.code)}
                            disabled={this.props.isFieldDisabled(field)}
                            defaultValue={this.props.getDefaultValueByField(field)}
                            onChange={(value: any) => this.props.updateField(field, value)}
                            placeholder={this.props.getPlaceholderValueByField(field)}
                        />
                    ))
                }
                {
                    this.state.isOpen && this.props.isEditModal && startDateField && (
                        <EditableField
                            key={`additional_${startDateField.entity}_${startDateField.code}`}
                            type={startDateField.type}
                            label={intl.get('effective_date')}
                            code={startDateField.code}
                            options={startDateField.options}
                            isRequired={startDateField.required}
                            errorMessage={
                                this.props.effectiveDateMissing
                                    ? intl.get('you_need_to_set_effective_date')
                                    : null}
                            disabled={false}
                            defaultValue={''}
                            onChange={(value: any) => this.props.updateField(startDateField, value)}
                            placeholder={this.props.currentPayrollPeriod?.beginDate || ''}
                        />
                    )
                }
            </Wrapper>
        );
    }

    public toggle = () => {
        this.setState({ isOpen: !this.state.isOpen });
    }
}
