import React, {PureComponent} from 'react';
import {connect} from 'react-redux';

import {EmployeeInterfaces, LeaveInterfaces} from '@api';

import {RootState} from '../../../../rootReducer';
import * as selectors from './selectors';
import {approve, reject, store} from './redux';

import Transactions from '../../../common/Leave/Transaction/Transactions';

import {intl} from '@global';

interface IProps {
    employee: EmployeeInterfaces.Employee;
    transactions: LeaveInterfaces.GroupedTransactions[];
    leaveTypes: LeaveInterfaces.Type[];

    store: (employeeId: number, transaction: LeaveInterfaces.Transaction) => void;
    approve: (employeeId: number, transaction: LeaveInterfaces.Transaction) => void;
    reject: (employeeId: number, transaction: LeaveInterfaces.Transaction) => void;
}

export class Ongoing extends PureComponent<IProps> {
    public render() {
        return (
            <Transactions
                label={intl.get('ongoing')}
                employee={this.props.employee}
                transactions={this.props.transactions}
                leaveTypes={this.props.leaveTypes}
                showPayrollPeriod={true}

                onClickSave={this.props.store}
                onClickApprove={this.props.approve}
                onClickReject={this.props.reject}
            />
        );
    }
}

const mapStateToProps = (state: RootState, ownProps: any) => ({
    transactions: selectors.getOngoingLeaves(state, ownProps),
    leaveTypes: selectors.getLeaveTypes(state, ownProps),
});

const mapDispatchToProps = {
    store,
    approve,
    reject,
};

export default connect(mapStateToProps, mapDispatchToProps)(Ongoing);
