import React, { PureComponent } from "react";
import ReactResizeDetector from "react-resize-detector";

import {
  openSalaryModal,
  setSalaryModalGroups,
  setSalaryModalValues,
} from "../../../redux";
import { ApiObject, EmployeeInterfaces, PayrollInterfaces } from "@api";
import ExpandableContainer from "./ExpandableContainer";
import AddButtonRow from "./AddButtonRow";
import TotalsRow from "./TotalsRow";
import { Table } from "../Table/Table";
import styled from "styled-components";
import { intl, stylingVariables, constants, interfaces } from "@global";
import { RootState } from "../../../../../rootReducer";
import * as globalSelectors from "../../../../selectors";
import { connect } from "react-redux";
import { HistoryTable } from "../Table/HistoryTable";
import { SalarySectionData } from "../../../../../api/employee.interfaces";
import moment from "moment";
import { AccessManager } from "@library";

const StyledEmployeeSalary = styled.div`
  width: 100%;
  padding: 40px 40px 40px 57px;
  border-bottom: 1px solid ${stylingVariables.colorPalette.gray};
  > button {
    position: absolute;
    bottom: 50px;
    left: 30px;
    > div {
      width: 20px;
      height: 20px;
      left: -20px;
      top: 0px;
    }
    > span {
      font-size: 12px;
      font-weight: bold;
    }
  }

  .ReactVirtualized__Table {
    margin: 0;
    .ReactVirtualized__Table__headerColumn {
      padding: 5px 20px;
    }
    .ReactVirtualized__Table__row {
      .ReactVirtualized__Table__rowColumn {
        font-weight: normal;
        &:first-child {
          color: ${stylingVariables.colorPalette.primary};
        }
      }
    }
  }
  &:last-child {
    border-bottom: 0;
  }
`;

const StyledCloseHistoryWrapper = styled.div`
  text-align: center;
  padding: 15px 0;
`;

const StyledCloseHistoryButton = styled.span`
  font-size: 14px;
  font-weight: bold;
  color: ${stylingVariables.colorPalette.primary};
  cursor: pointer;
  text-align: center;
  margin: 0 auto;
  border-radius: 17px;
  padding: 10px 20px;
  &:hover {
    background-color: ${stylingVariables.colorPalette.lightGray};
  }
`;

interface IProps {
  enabledSalaryGroups: string[];
  data: SalarySectionData;
  currentPayrollPeriod: PayrollInterfaces.PayrollPeriod | null;

  openSalaryModal: () => void;
  setSalaryModalGroups: (payElementType: string) => void;
  setSalaryModalValues: (values: any) => void;
  masterdataEditAllowed: boolean;
  permissions: interfaces.Permissions;
}

interface IState {
  history: boolean;
  future: boolean;
  containerHeight: number;
}

class SalarySection extends PureComponent<IProps, IState> {
  public constructor(props: IProps) {
    super(props);
    this.state = {
      history: false,
      future: false,
      containerHeight:
        props.data?.payElements?.filter((p) => !p.childElement).length * 80 +
        40,
    };
  }

  componentDidUpdate(
    prevProps: Readonly<IProps>,
    prevState: Readonly<IState>,
    snapshot?: any
  ) {
    if (
      prevProps.data?.payElements?.length !==
        this.props.data?.payElements?.length ||
      prevProps.data?.historyElements?.length !==
        this.props.data?.historyElements?.length
    ) {
      this.setState({
        containerHeight: this.containerHeight(this.state.history),
      });
    }
  }

  get links() {
    const links = [];

    const historyLabel = this.state.history
      ? intl.get("close_history")
      : intl.get("view_history");
    const historyLink = { label: historyLabel, action: this.toggleHistory };

    if (this.props.data.historyElements.length > 0) {
      links.push(historyLink);
    }

    return links;
  }

  private containerHeight(history: boolean): number {
    const { data } = this.props;
    const dataSource = history
      ? this.groupHistoryElements(data.historyElements)
      : data.payElements;
    const extraSpace = history ? 110 : 40;
    return dataSource.length * (history ? 50 : 80) + extraSpace;
  }

  get isMainTable(): boolean {
    return !this.state.future && !this.state.history;
  }

  get currentSalarySectionCode(): string {
    const { data } = this.props;
    const postfix = this.state.history ? "_history" : "_future";
    return this.isMainTable ? data.code : data.code + postfix;
  }

  get totalDataKey(): string {
    const recurringPaymentGroups: string[] = [
      constants.CompensationTypes.recurringEarnings,
      constants.CompensationTypes.recurringDeductions,
    ];

    if (recurringPaymentGroups.includes(this.currentSalarySectionCode)) {
      return "annualAmount";
    }

    return "amount";
  }

  get isSectionIsVisible(): boolean {
    const { data } = this.props;
    return (
      data.payElements.length + data.historyElements.length > 0 ||
      this.props.enabledSalaryGroups.includes(this.currentSalarySectionCode)
    );
  }

  private changeWrapperHeight = (tableHeight: number) => {
    this.setState({
      containerHeight: tableHeight + 110,
    });
  };

  private groupHistoryElements = (
    historyElements: EmployeeInterfaces.EmployeePayElementHistory[]
  ): EmployeeInterfaces.GroupedEmployeePayElementHistory[] => {
    const historyMap = new Map();

    historyElements.forEach((historyEl, index) => {
      const groupKey = historyEl.rootEmployeePayElementId;
      if (historyMap.has(groupKey)) {
        historyMap.get(groupKey).history.push(historyEl);
        historyMap.get(groupKey).totalAmount += historyEl.amount;
      } else {
        const active = this.props.data?.payElements?.find(
          (elements) =>
            elements.rootEmployeePayElementId ===
            historyEl.rootEmployeePayElementId
        );

        let currencyAndFrequency = historyEl.currency;

        if (active) {
          let payUnitName = active.payUnit;
          let currencyName = historyEl.currency;
          if (payUnitName === ApiObject.PayUnitType.percentage) {
            payUnitName = ApiObject.PayUnitType.month;
            currencyName = ApiObject.PayUnitType.percentage;
          }
          currencyAndFrequency = active.payUnit
            ? `${currencyName} / ${payUnitName}`
            : currencyName;
        }

        historyMap.set(groupKey, {
          history: [historyEl],
          totalAmount: historyEl.amount,
          active: active !== undefined,
          currencyAndFrequency,
        });
      }
    });

    const groupedHistory: EmployeeInterfaces.GroupedEmployeePayElementHistory[] =
      [...historyMap.values()]
        .map((value, index) => {
          return {
            history: value.history.sort(
              (a: { startDate: string }, b: { startDate: string }) => {
                return moment(a.startDate, "YYYY-MM-DD").isAfter(
                  moment(b.startDate, "YYYY-MM-DD")
                )
                  ? 1
                  : -1;
              }
            ),
            totalAmount: value.totalAmount,
            active: value.active,
            currencyAndFrequency: value.currencyAndFrequency,
          };
        })
        .sort((a, b) => {
          const collator = new Intl.Collator();
          return collator.compare(
            `${a.history[0].label}`,
            `${b.history[0].label}`
          );
        });

    return groupedHistory;
  };

  get table() {
    const { data } = this.props;
    const rows = data.payElements.filter((p) => !p.childElement);
    return this.isMainTable ? (
      <Table
        rows={rows}
        code={data?.code || ""}
        masterdataEditAllowed={this.props.masterdataEditAllowed}
        addAction={() => this.add(data.code)}
        editAction={(values: any) => this.edit(data.code, values)}
      />
    ) : (
      <HistoryTable
        rows={this.groupHistoryElements(data.historyElements)}
        currentPayrollPeriod={this.props.currentPayrollPeriod}
        changeWrapperHeight={this.changeWrapperHeight}
      />
    );
  }

  get afterTableContent() {
    const { data } = this.props;
    const hasAccess = AccessManager.hasPermission(
      this.props.permissions,
      constants.PermissionCode.EMPLOYEES_ACTIONS
    );

    return this.isMainTable ? (
      <>
        {this.props.masterdataEditAllowed && hasAccess && (
          <AddButtonRow action={() => this.add(data.code)} />
        )}
        <TotalsRow rows={data.payElements} dataKey={this.totalDataKey} />
      </>
    ) : (
      <StyledCloseHistoryWrapper>
        <StyledCloseHistoryButton
          onClick={this.state.history ? this.toggleHistory : this.toggleFuture}
        >
          {this.state.history
            ? intl.get("close_history")
            : intl.get("close_future")}
        </StyledCloseHistoryButton>
      </StyledCloseHistoryWrapper>
    );
  }

  public render() {
    const { data } = this.props;

    if (!this.isSectionIsVisible) {
      return null;
    }

    return (
      <StyledEmployeeSalary key={data.code}>
        <ExpandableContainer
          uniqId={data.label}
          label={data.label}
          links={this.links}
          content={
            data.payElements ? (
              <div style={{ height: this.state.containerHeight }}>
                <ReactResizeDetector handleWidth={true}>
                  {this.table}
                </ReactResizeDetector>
              </div>
            ) : null
          }
        />

        {this.afterTableContent}
      </StyledEmployeeSalary>
    );
  }

  private edit(payElementType: string, values: any) {
    const hasChanges = values.changes && values.changes.length > 0;

    let latestChanges = {};
    if (hasChanges) {
      latestChanges = values.changes.reduce((a: any, b: any) =>
        a.startDate > b.startDate ? a : b
      );
    }

    this.props.setSalaryModalGroups(payElementType);
    this.props.setSalaryModalValues({ ...values, ...latestChanges });
    this.props.openSalaryModal();
  }

  private add(payElementType: string) {
    this.props.setSalaryModalGroups(payElementType);
    this.props.setSalaryModalValues(null);
    this.props.openSalaryModal();
  }

  public toggleHistory = () => {
    this.setState({
      containerHeight: this.containerHeight(!this.state.history),
    });
    this.setState({
      history: !this.state.history,
      future: false,
    });
  };

  public toggleFuture = () => {
    this.setState({
      containerHeight: this.containerHeight(false),
    });
    this.setState({
      history: false,
      future: !this.state.future,
    });
  };
}

const mapStateToProps = (state: RootState) => ({
  currentPayrollPeriod: globalSelectors.getCurrentPayrollPeriod(state),
  masterdataEditAllowed: globalSelectors.masterdataEditAllowed(state, null),
  permissions: state.global.permissions,
});

const mapDispatchToProps = {
  openSalaryModal,
  setSalaryModalGroups,
  setSalaryModalValues,
};

export default connect(mapStateToProps, mapDispatchToProps)(SalarySection);
