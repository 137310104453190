export default class KeyboardHelper {
    public static handleEnterPressEvent = (callback: () => any) => (event: any): void => {
        if (event.key === 'Enter') {
            callback();
        }
    };

    public static handleEscDownEvent = (callback: () => any) => (event: any): void => {
        if (event.key === 'Esc' || event.key === 'Escape') {
            callback();
        }
    };

    public static handlePressTab = (callback: () => any) => (event: any): void => {
        if (!event.shiftKey && event.key === 'Tab') {
            event.preventDefault();
            event.stopPropagation();
            callback();
        }
    };

    public static handlePressShiftTab = (callback: () => any) => (event: any): void => {
        if (event.shiftKey && event.key === 'Tab') {
            event.preventDefault();
            event.stopPropagation();
            callback();
        }
    };
}
