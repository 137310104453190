import React, {PureComponent} from 'react';
import {intl} from '@global';

import {ApiEmployee, ApiObject, EmployeeInterfaces} from '@api';
import {Dropdown, Layout} from '@common';
import { throttle } from 'lodash';

interface RowEmployeeProps {
    employee?: EmployeeInterfaces.Employee|null;
    onChange: (employeeId: number | null, employee: EmployeeInterfaces.Employee|undefined) => any;
    currentLegalEntityId: number | null;
    errors?: string[];
}

interface RowEmployeeState {
    listEmployees: EmployeeInterfaces.Employee[];
    useLoadMoreEmployees: boolean;
    employeePage: number;
    queryEmployee: string;
}

export default class EmployeeRow extends PureComponent<RowEmployeeProps, RowEmployeeState> {
    public constructor(props: RowEmployeeProps) {
        super(props);

        this.state = {
            listEmployees: [],
            useLoadMoreEmployees: true,
            employeePage: 0,
            queryEmployee: '',
        };

        if (!props.employee) {
            this.onEmployeeSearch('');
        }
    }

    private get listEmployees(): ApiObject.SelectOption[] {
        return this.state.listEmployees.map((employee: EmployeeInterfaces.Employee) => {
            return {
                key: `${employee.id}`,
                label: this.employeeFullName(employee),
            };
        });
    }

    private employeeFullName = (employee: EmployeeInterfaces.Employee): string => {
        return `${employee.referenceCode} - ${[employee.person.firstName, ' ', employee.person.lastName].join('').trim()}`;
    }

    private onLoadMoreEmployees = () => {
        this.setState((state: RowEmployeeState) => ({
            employeePage: state.employeePage + 1,
        }), () => this.onEmployeeSearch(this.state.queryEmployee, true));
    }

    private onEmployeeSearch = async(value: string, loadMore: boolean = false) => {
        const LIMIT = 20;

        const listEmployees = (this.props.currentLegalEntityId)
            ? await ApiEmployee.findByQueryWithPage(
                this.props.currentLegalEntityId,
                value,
                LIMIT,
                this.state.employeePage)
            : [];

        let employees: any;
        if (loadMore) {
            employees = new Set([...this.state.listEmployees, ...listEmployees]);
        } else {
            employees = new Set(listEmployees);
        }

        this.setState(() => ({
            queryEmployee: value,
            listEmployees: [...employees],
            useLoadMoreEmployees: listEmployees.length === LIMIT,
        }));

        this.props.onChange(null, undefined);
    }

    private throttledSearch = throttle(this.onEmployeeSearch, 500);

    private onChangeEmployeeId = (employeeId: string): void => {
        const employee = this.state.listEmployees.find(e => String(e.id) === employeeId)
        this.props.onChange(+employeeId, employee);
    }

    private renderRowValue = () => {
        if (this.props.employee) {
            return (
                <>{this.employeeFullName(this.props.employee)}</>
            );
        }

        return (
            <Dropdown
                externalFilter={true}
                onFilter={this.throttledSearch}
                useLoadMore={this.state.useLoadMoreEmployees}
                onLoadMore={this.onLoadMoreEmployees}
                onChange={this.onChangeEmployeeId}
                list={this.listEmployees}
            />
        );
    }

    public render() {
        return (
            <Layout.Row hasError={!!this.props.errors}>
                <Layout.RowLabel percent={20} top={14}>
                    {intl.get('employee')} *
                </Layout.RowLabel>

                <Layout.RowValue percent={70}>
                    {this.renderRowValue()}
                </Layout.RowValue>
            </Layout.Row>
        );
    }
}
