import React, {PureComponent} from 'react';
import styled from 'styled-components';
import {Icon} from '@common';
import {constants, stylingVariables} from '@global';

const StyledContainerWrapper = styled.div<{isSticky: boolean, tableWidth: number}>`

${props => props.isSticky ? `
   ${StyledExpandableHeader}{
        position: sticky;
        top: -20px;
        z-index: 101;
        background-color: ${stylingVariables.colorPalette.white};
        width: ${props.tableWidth}px;
    }
    .ReactVirtualized__Table{
        overflow: visible!important;
    }
    .ReactVirtualized__Table__headerRow{
            position: sticky;
            top: 35px;
            z-index: 100;
            background-color: ${stylingVariables.colorPalette.white};
            width: ${props.tableWidth}px;
            box-shadow: 0px 5px 3px -2px ${stylingVariables.colorPalette.gray};
        }
   ` : ''};
`;

const StyledExpandableHeader = styled.div`
   display: flex;
   align-items: center;
   justify-content: flex-start;
   padding: 10px 0;
   border-radius: 5px;
   h4{
    font-size: ${stylingVariables.fontSize.largeMedium};
    font-weight: ${stylingVariables.fontWeight.bold};;
    margin-left: 7px;
   }
`;

const StyledExpandButton = styled.span`
   display: flex;
   width: 30px;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   border-radius: 5px;
   background-color: ${stylingVariables.colorPalette.darkGray};
   padding: 10px;
   cursor: pointer;
`;

const StyledContent = styled.div<{isContentExpanded: boolean}>`
   ${props => props.isContentExpanded ? '' : 'display: none'};
`;

const StyledLinks = styled.div`
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
    flex-grow: 1;
`;

const StyledLink = styled.span`
    font-size: 14px;
    font-weight: bold;
    color: ${stylingVariables.colorPalette.secondary};
    cursor: pointer;
    margin: 0 2px;
    border-radius: 17px;
    padding: 10px 15px;
    &:hover {
        background-color: ${stylingVariables.colorPalette.primaryHover};
    }
`;

interface Link {label: string; action: () => void;}

interface IProps {
    label: string;
    links?: Link[] | null;
    content: any;
    uniqId?: string;
}

interface IState {
    isContentExpanded: boolean;
    isSticky: boolean;
}

export default class ExpandableContainer extends PureComponent<IProps, IState> {
    currentRef: any = null;

    public constructor(props: IProps) {
        super(props);
        this.state = {
            isContentExpanded: true,
            isSticky: false,
        };
    }

    componentDidMount(): void {
        const main = this.getRouteElement();
        if (!main) {
            return;
        }
        main.addEventListener('scroll', this.onScroll);
    }

    getRouteElement = () => {
        return document.querySelector('.main-router-wrapper');
    }

    componentWillUnmount(): void {
        const main = this.getRouteElement();
        if (!main) {
            return;
        }

        main.removeEventListener('scroll', this.onScroll);
    }

    onScroll = ({target}: any) => {

        const isSticky = this.state.isSticky;
        if (!this.state.isContentExpanded){
            return;
        }
        const scrollTop = target.scrollTop;
        const compensation = 20 + 39 + 20 + 46; //height between header and tables

        const tableTop = this.currentRef.offsetTop + compensation;
        const tableBottom =  this.currentRef.offsetTop + compensation + this.currentRef.offsetHeight;

        if (!isSticky && scrollTop > tableTop && scrollTop < tableBottom){
            this.setState({isSticky: true});
        } else if (isSticky && (scrollTop < tableTop || scrollTop > tableBottom)){
            this.setState({isSticky: false});
        }
    }

    get tableWidth(){
        return this.currentRef ? this.currentRef.offsetWidth : 0;
    }

    public render() {
            return <StyledContainerWrapper ref={(_ref: any) => this.currentRef = _ref}
                                           isSticky={this.state.isSticky}
                                           tableWidth={this.tableWidth}>
                <StyledExpandableHeader>
                    <StyledExpandButton onClick={this.toggle}>
                        <Icon type={this.state.isContentExpanded ?
                            constants.IconTypes.ARROW_DOWN :
                            constants.IconTypes.ARROW_UP}
                              fillColor={stylingVariables.colorPalette.dark}
                              height={8}
                              width={8}
                        />
                        <Icon type={this.state.isContentExpanded ?
                            constants.IconTypes.ARROW_UP :
                            constants.IconTypes.ARROW_DOWN}
                              fillColor={stylingVariables.colorPalette.dark}
                              height={8}
                              width={8}
                        />
                    </StyledExpandButton>
                    <h4>{this.props.label}</h4>
                    <StyledLinks>
                        {this.props.links && this.props.links.map((link: Link) => {
                           return <StyledLink key={link.label} onClick={link.action}>{link.label}</StyledLink>;
                        })}
                    </StyledLinks>
                </StyledExpandableHeader>
                <StyledContent isContentExpanded={this.state.isContentExpanded}>
                    {this.props.content}
                </StyledContent>
            </StyledContainerWrapper>;
    }

    public toggle = () => {
        const newExtended = !this.state.isContentExpanded;
        this.setState((state: IState) => ({
            ...state,
            isContentExpanded: newExtended,
            isSticky: newExtended ?  state.isSticky : false,
        }));
    }
}
