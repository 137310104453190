import { ColorHelper, NamesHelper } from '@library'
import React, { PureComponent } from 'react'
import styled from 'styled-components'
import { get } from 'lodash'
import { intl } from '@global'

import { stylingVariables } from '@global'
import { ApiObject, EmployeeInterfaces } from '@api'
import { ChartersPreview, Label } from '@common'

import EssAccess from './EssAccess'
import Status from './Status'
import Contacts from './Contacts'
import LastAccess from './LastAccess'

const StyledEmployeeCardWrapper = styled.div<{ isCreateMode: boolean; isCollapsed?: boolean }>`
  flex-grow: 0;
  flex-basis: 280px;
  padding: ${(props) => (props.isCollapsed ? 10 : 30)}px;
  width: ${(props) => (props.isCollapsed ? 100 : 365)}px;
  min-width: ${(props) => (props.isCollapsed ? 100 : 365)}px;
  max-width: ${(props) => (props.isCollapsed ? 100 : 365)}px;
  border-radius: ${stylingVariables.layout.cardBorderRadius};
  background: ${stylingVariables.colorPalette.white};
  color: ${stylingVariables.colorPalette.darkest};
  margin-bottom: 10px;
  box-shadow: ${stylingVariables.layout.boxShadowLight};
  h5 {
    text-align: center;
    margin: 10px auto;
    font-size: ${stylingVariables.fontSize.largeMedium};
  }

  ${(props) =>
    props.isCreateMode &&
    `
    opacity: .7;
    pointer-events: none;
    cursor: default;
  `}
`
const StyledChartersPreview = styled.div<{ isCollapsed?: boolean }>`
  width: ${(props) => (props.isCollapsed ? 46 : 106)}px;
  height: ${(props) => (props.isCollapsed ? 46 : 106)}px;
  margin: 0 auto 20px auto;
  box-sizing: border-box;
  border-radius: 100%;
  background-color: ${stylingVariables.colorPalette.white};
  border: 1px solid ${stylingVariables.colorPalette.green};
  padding: 2px;
  > div {
    font-size: ${(props) => (props.isCollapsed ? 16 : 30)}px;
  }
`
const StyledPosition = styled.div`
  text-align: center;
  font-size: ${stylingVariables.fontSize.medium};
  font-weight: bold;
  margin: 10px auto;
  color: ${stylingVariables.colorPalette.deepGray};
`

const CollapsedStatuses = styled.div`
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  > div {
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    label {
      font-size: 10px;
      font-weight: 400;
      color: ${stylingVariables.colorPalette.deepGray};
      margin-bottom: 5px;
    }
    span {
    }
  }
`

interface IProps {
  employee: EmployeeInterfaces.Employee
  fields: ApiObject.Fields
  isCreateMode: boolean
  isCollapsed: boolean
}

export default class ContactCard extends PureComponent<IProps> {
  public render() {
    if (this.props.isCollapsed) {
      return (
        <StyledEmployeeCardWrapper isCreateMode={this.props.isCreateMode} isCollapsed={true}>
          <StyledChartersPreview isCollapsed={true}>
            <ChartersPreview
              text={this.props.fields.computed.fullName}
              photo={this.props.employee.photoAccessToken}
              legalEntityId={this.props.employee.legalEntityId}
              width={'40px'}
              height={'40px'}
            />
          </StyledChartersPreview>

          <StyledPosition>{get(this.props.fields, 'employee.position')}</StyledPosition>

          <h5>
            {intl.get('ID')} {get(this.props.fields, 'employee.reference_code')}
          </h5>

          <CollapsedStatuses>
            <div>
              <label>{intl.get('status')}</label>
              <Label.FatTag color={ColorHelper.employeeStatusColor(this.props.employee.status)}>
                {intl.get(this.props.employee.status)}
              </Label.FatTag>
            </div>

            <div>
              <label>{intl.get('ess_access')}</label>
              <Label.FatTag color={ColorHelper.employeeEssStatusColor(this.props.employee.essStatus)}>
                {NamesHelper.convertEssStatus(this.props.employee.essStatus)}
              </Label.FatTag>
            </div>
          </CollapsedStatuses>
        </StyledEmployeeCardWrapper>
      )
    }

    return (
      <StyledEmployeeCardWrapper isCreateMode={this.props.isCreateMode}>
        <StyledChartersPreview>
          <ChartersPreview
            text={this.props.fields.computed.fullName}
            photo={this.props.employee.photoAccessToken}
            legalEntityId={this.props.employee.legalEntityId}
            width={'100px'}
            height={'100px'}
          />
        </StyledChartersPreview>

        <h5>{this.props.fields.computed.fullName}</h5>

        <StyledPosition>{get(this.props.fields, 'employee.position')}</StyledPosition>

        <h5>
          {intl.get('ID')} {get(this.props.fields, 'employee.reference_code')}
        </h5>

        <Status employee={this.props.employee} />

        <EssAccess employee={this.props.employee} />

        <LastAccess employee={this.props.employee} />
        
        <Contacts fields={this.props.fields} />

      </StyledEmployeeCardWrapper>
    )
  }
}
