import {intl, interfaces} from '@global';
import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';
import {ApiObject} from '@api';
import {Functions} from '@library';
import {GroupFieldBuilder} from '@common';
import {RootState} from '../../../../rootReducer';
import * as basicSelectors from '../../selectors';

const Wrapper = styled.div`
  background-color: #fff;
  height: 100%;
  overflow: auto;
  display: flex;
`;

interface IProps {
    schema: interfaces.ReportSelectDataSchema;
    onChange: (schema: interfaces.ReportSelectDataSchema) => void;

    masterDataListFields: ApiObject.SelectOption[];
    masterDataQuickAdds: interfaces.IGroupFieldQuickAdd[];
    payElementsListFields: ApiObject.SelectOption[];
    payElementsQuickAdds: interfaces.IGroupFieldQuickAdd[];
    onDataSourceChange: (dataSource: ApiObject.DataSource) => void;
    invalidSchemaKeys: string[];
}

interface IState {
    schema: interfaces.ReportSelectDataSchema;
    employeeSchema: interfaces.IGroupFieldSchema;
}

export class SelectDataBuilder extends PureComponent<IProps, IState> {
    constructor(props: any) {
        super(props);

        this.state = {
            employeeSchema: {
                groups: [
                    {
                        uniqueKey: Functions.uniqueID(),
                        label: intl.get('employee_id'),
                        fields: [
                            {
                                uniqueKey: Functions.uniqueID(),
                                label: intl.get('employee_id'),
                                key: 'referenceCode'
                            }
                        ]
                    }
                ]
            },
            schema: props.schema,
        }
    }

    employeeIdFields = (): ApiObject.SelectOption[] => {
        return [
            {key: 'referenceCode', label: intl.get('employee_id')}
        ]
    }

    onDataSourceChange = (value: string|number ) => {
        this.props.onDataSourceChange(value as ApiObject.DataSource);
    }

    onChange = () => {
        this.props.onChange(this.state.schema);
    }

    public render() {
        return <Wrapper>
            <GroupFieldBuilder
                builderName={intl.get('employee_id')}
                readOnly={true}
                listFields={this.employeeIdFields()}
                schema={this.state.employeeSchema}
                onUpdate={(schema: interfaces.IGroupFieldSchema) => this.setState({employeeSchema: schema})}
                noteMessage={intl.get('mandatory_field')}
            />
            <GroupFieldBuilder
                builderName={intl.get('master_data')}
                quickAdds={this.props.masterDataQuickAdds}
                listFields={this.props.masterDataListFields}
                schema={this.state.schema.masterDataSchema}
                invalidSchemaKeys={this.props.invalidSchemaKeys}
                onUpdate={(masterDataSchema: interfaces.IGroupFieldSchema) => this.setState((state) => ({
                    ...state,
                    schema: {
                        ...state.schema,
                        masterDataSchema
                    }
                }), this.onChange)}
            />
            <GroupFieldBuilder
                builderName={intl.get('pay_element')}
                quickAdds={this.props.payElementsQuickAdds}
                listFields={this.props.payElementsListFields}
                schema={this.state.schema.payElementsSchema}
                invalidSchemaKeys={this.props.invalidSchemaKeys}
                onUpdate={(payElementsSchema: interfaces.IGroupFieldSchema) => this.setState((state) => ({
                    ...state,
                    schema: {
                        ...state.schema,
                        payElementsSchema
                    }
                }), this.onChange)}
            />
        </Wrapper>
    }
}

const mapStateToProps = (state: RootState) => ({
    masterDataListFields: basicSelectors.getMasterdataListFields(state),
    masterDataQuickAdds: basicSelectors.getMasterdataQuickAdds(state),
    payElementsListFields: basicSelectors.getPayElementsListFields(state),
    payElementsQuickAdds: basicSelectors.getPayElementsQuickAdds(state),
});

export default connect(mapStateToProps)(SelectDataBuilder);

