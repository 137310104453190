import React, {PureComponent} from 'react';

import styled from 'styled-components';
import {stylingVariables} from '@global';
import {FormatNumbers, Layout} from '@common';
import moment from 'moment';

const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 5px;

  li {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
  }

  li > span {
    position: absolute;
    top: 31px;
    left: 45px;
    font-weight: ${stylingVariables.fontWeight.semibold};
  }
`;

const LinedHeader = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    h4 {
        position: relative;
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-size: ${stylingVariables.fontSize.medium};
        font-weight: ${stylingVariables.fontWeight.regular};
        padding: 0;
        margin: 0;
    }

    span {
        position: absolute;
        top: 35px;
        left: 14px;
        font-weight: ${stylingVariables.fontWeight.regular};
    }

    h4:after {
        content: '';
        border-bottom: 1px solid ${stylingVariables.colorPalette.darkGray};
        margin: 40px 0 0 6px;
        flex: 1 0 10px;
    }

    h4:before {
        content: '';
        border-bottom: 1px solid ${stylingVariables.colorPalette.darkGray};
        border-left: 1px solid ${stylingVariables.colorPalette.darkGray};
        width: 20px;
        height: 40px;
        margin-right: 7px;
    }
`;

const Subtitle = styled(Layout.Subtitle)`
    label {
      margin-left: 5px;
      color: ${stylingVariables.colorPalette.orange};
      background: ${stylingVariables.colorPalette.gray};
      padding: 3px 6px;
      border-radius: 13px;
    }
`;

interface IProps {
    payElements: any;
}

export default class UnitsGroup extends PureComponent<IProps> {
    public render() {
        return (
            <div>
                <Content>
                    <ul>
                        { this.props.payElements.map((payElement: {
                                    source: any;
                                    startDate: moment.MomentInput;
                                    endDate: moment.MomentInput;
                                    amount: string | number | null;
                                    currency: string;
                                },                    index: number,
                            ) => (
                                <li
                                    key={index}
                                >
                                    <LinedHeader><h4><span>{index + 1}</span></h4></LinedHeader>
                                    <Subtitle>
                                        {moment(
                                            payElement.startDate,
                                            'YYYY-MM-DD',
                                        ).format('MMM DD, YYYY')} - {payElement.endDate ?
                                            moment(
                                                payElement.endDate,
                                                'YYYY-MM-DD',
                                            ).format('MMM DD, YYYY')
                                            : '...'
                                        }
                                        <label>
                                            <FormatNumbers showZero={true} value={payElement.amount}/>
                                            {payElement.currency}
                                        </label>
                                        {payElement.source}
                                    </Subtitle>
                                </li>
                            ))
                        }
                    </ul>
                </Content>
            </div>
        );
    }
}
