import React, {PureComponent} from 'react';
import styled from 'styled-components';
import {get} from 'lodash';

import {ApiObject} from '@api';
import {Icon, Input, Popover} from '@common';
import {constants, stylingVariables, intl} from '@global';

const StyledWrapper = styled.div`
    position: relative;
    font-size: 13px;
    user-select: none;
`;

const StyledHeader = styled.div<{isOpened: boolean, error: boolean}>`
    display: flex;
    align-items: center;
    height: 30px;
    padding: 0 30px 0 10px;
    border-bottom: 1px solid ${stylingVariables.colorPalette.darkGray};
    position: relative;
    
    ${props => props.isOpened && `z-index: 11;`};
    ${props => props.error && `color: ${stylingVariables.colorPalette.red};`};
`;

const StyledHeaderTitle = styled.span`
    position: relative;
    font-size: ${stylingVariables.fontSize.medium};
    font-weight: ${stylingVariables.fontWeight.regular};
`;

const StyledArrow = styled.span`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 0;
    top: 0;
    bottom: 0;
    height: 30px;
    width: 30px;
    overflow: hidden;
    border-radius: 0 ${stylingVariables.layout.inputBorderRadius} ${stylingVariables.layout.inputBorderRadius} 0;
    background: ${stylingVariables.colorPalette.gray};
    cursor: pointer;
`;

const StyledContent = styled.div<{isOpened: boolean}>`
    position: absolute;
    left: 0;
    right: 0;
    z-index: 10;
    background: ${stylingVariables.colorPalette.white};
    border: 1px solid ${stylingVariables.colorPalette.darkGray};
    border-radius: 0 0 ${stylingVariables.layout.cardBorderRadius} ${stylingVariables.layout.cardBorderRadius};
    box-shadow: ${stylingVariables.layout.boxShadow};
    
    ${props => props.isOpened && `z-index: 10;`};
`;

const StyledList= styled.ul`
    max-height: 200px;
    overflow-y: auto;
    li{
        padding: 10px;
        font-size: ${stylingVariables.fontSize.mediumSmall};
        font-weight: ${stylingVariables.fontWeight.regular};
        cursor: pointer;
        &:hover{
            background: ${stylingVariables.colorPalette.gray};
        }
    }
`;

const StyledSearchInput = styled.div`
  padding: 0 10px;
  margin-bottom: 3px;
  display: flex;
  align-items: center;
  label {
    display: none;
  }
  svg {
    margin-right: 10px;
  }
  div {
    flex: 1
  }
`;

interface IProps {
    list: ApiObject.SelectOption[];
    ignoreKeys: string[];
    selectedValue?: string;
    onChange: (value: any) => void;
    placeholder: string;

    onOpenContent?: (value: boolean) => any;
}

interface IState {
    rawSelectedValue?: string;

    listOpen: boolean;
    search: string;
    selected: ApiObject.SelectOption | null | undefined;
}

export default class SimpleDropdown extends PureComponent<IProps, IState> {
    private wrapperRef: any;

    static defaultProps = {
        list: [],
        ignoreKeys: [],
        placeholder: 'choose_value'
    };

    constructor(props: IProps) {
        super(props);
        this.state = {
            rawSelectedValue: props.selectedValue,
            listOpen: false,
            search: '',
            selected: SimpleDropdown.getSelected(props),
        };
    }

    static getSelected = (props: IProps) => {
        return props.list.find((x: ApiObject.SelectOption) => x.key === props.selectedValue);
    }

    public componentDidMount() {
        document.addEventListener('mousedown', this.clickOutside);
    }

    public componentWillUnmount() {
        document.removeEventListener('mousedown', this.clickOutside);
    }

    toggleList = (value: boolean|null = null) => {
        this.setState(prevState => ({
            listOpen: value !== null ? value : !prevState.listOpen,
        }), () => this.props.onOpenContent ? this.props.onOpenContent(this.state.listOpen) : null);
    }

    toggleSelected = (item: ApiObject.SelectOption) => {
        this.props.onChange(item.key);
        this.setState({
            selected: item,
        });
        this.toggleList();
    }

    private onChangeSearch = (value: string): void => {
        this.setState({search: value});
    };

    private get list(): ApiObject.SelectOption[] {
        let list = this.props.list
        if (this.state.search) {
            list = list.filter((item: ApiObject.SelectOption) => item.label.toLowerCase().includes(this.state.search.toLowerCase()));
        }
        if (this.props.ignoreKeys.length > 0) {
            list = list.filter((item: ApiObject.SelectOption) => !this.props.ignoreKeys.includes(item.key));
        }

        return list;
    }

    render() {
        const list = this.list;
        const {listOpen, selected} = this.state;

        let existedInList = !this.props.selectedValue || (this.props.selectedValue && selected)
        if (!existedInList) {
            // @ts-ignore
            console.info(`Field ${this.props.selectedValue} not exist in fields list, but used`)
        }
        return (
            <StyledWrapper className={'simple_dropdown__wrapper' + (this.state.listOpen ? ' opened' : '')} ref={_ref => this.wrapperRef = _ref}>
                <StyledHeader
                  error={!existedInList}
                  isOpened={this.state.listOpen}
                  onClick={() => this.toggleList()}
                  className={'simple_dropdown__header'}
                >
                    <Popover
                      isDisabled={!!existedInList}
                      message={intl.get('field_selected_but_not_exist_in_list')}
                    >
                        <StyledHeaderTitle>
                            {get(selected, 'label',
                            intl.get(this.props.placeholder) ? intl.get(this.props.placeholder) : this.props.placeholder)}
                        </StyledHeaderTitle>
                    </Popover>
                    <StyledArrow className={'simple_dropdown__header_arrow'}>
                        <Icon type={listOpen ? constants.IconTypes.ARROW_UP : constants.IconTypes.ARROW_DOWN}
                              fillColor={stylingVariables.colorPalette.dark}
                              height={10}
                              width={10}
                        />
                    </StyledArrow>
                </StyledHeader>
                {listOpen && (
                    <StyledContent isOpened={this.state.listOpen} className={'simple_dropdown__content'}>
                        <StyledSearchInput className={'simple_dropdown__content__search_input'}>
                            <Icon type={constants.IconTypes.SEARCH}
                                  fillColor={stylingVariables.colorPalette.green}
                                  height={16}
                                  width={16}
                            />
                            <Input label={''}
                                   autoFocus={true}
                                   defaultValue={this.state.search}
                                   placeholder={intl.get('search')}
                                   onChange={(event: any) => this.onChangeSearch(event.target.value)}
                            />
                        </StyledSearchInput>
                        <StyledList className={'simple_dropdown__content__list'}>
                            {list.map((item: ApiObject.SelectOption) => (
                                <li className='dd-list-item'
                                    key={item.key}
                                    onClick={() => this.toggleSelected(item)}>{item.label}</li>
                            ))}
                        </StyledList>
                    </StyledContent>
                )}
            </StyledWrapper>
        );
    }

    public clickOutside = (event: Event) => {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.toggleList(false);
        }
    }
}
