import React, {Fragment, memo} from 'react';
import {Column} from 'react-virtualized';
import {defaultInfiniteTableCellDataGetter} from '@common';

const Cell: React.FC<{index: number;}>
    = memo(({index}) => {
    return (
        <Fragment>
            <span className={'no-index'}>{index + 1}</span>
        </Fragment>
    );
});

const RowIndex = () => (
    <Column
        label={'#'}
        dataKey='index'
        width={60}
        cellDataGetter={defaultInfiniteTableCellDataGetter}
        headerRenderer={() => '#'}
        cellRenderer={({rowIndex}) => (
            <Cell index={rowIndex} />
        )}
    />
);

export default RowIndex;
