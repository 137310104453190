import {ApiObject} from '@api';
import {stylingVariables} from '@global';
import {ColorHelper} from '@library';
import React, {PureComponent} from 'react';
import styled from 'styled-components';

const DEFAULT_TD_WIDTH = 80;
const DEFAULT_TR_HEIGHT = 40;

const WrapperTable = styled.table`
  display: table;
  box-sizing: content-box;
  border-spacing: 0;
  border-collapse: inherit;
  
  color: ${stylingVariables.colorPalette.darkest};
  font-size: ${stylingVariables.fontSize.default};
  
  td {
    border: 2px solid ${stylingVariables.colorPalette.gray};
  }
  
  td:not(:last-child) {
    margin-right: 4px;
  }
`;
const DefaultTr = styled.tr`
  height: ${DEFAULT_TR_HEIGHT}px;
  display: table-row;
  white-space: nowrap;
`;
const DefaultTd = styled.td`
  display: table-cell;
  height: ${DEFAULT_TR_HEIGHT}px;
  vertical-align: middle;
  padding: 0 10px;
  border-radius: 6px;
  color: ${stylingVariables.colorPalette.darkest};
  font-weight: ${stylingVariables.fontWeight.semibold};
  overflow: hidden;
`;
const FieldTd = styled((props) => <DefaultTd {...props}/>)`
  min-width: ${props => props.width || DEFAULT_TD_WIDTH}px;
  background-color: ${ColorHelper.hexToRgbA(stylingVariables.colorPalette.grayish, 0.6)};
`;
const DataSampleTd = styled((props) => <DefaultTd {...props}/>)`
  min-width: ${props => props.width || DEFAULT_TD_WIDTH}px;
  background-color: ${stylingVariables.colorPalette.white};
`;

interface IProps {
    columns: ApiObject.ReportColumn[];
    month: string;
}

export default class Table extends PureComponent<IProps> {

    getSampleData = (columnLabel: string) => {
        switch (columnLabel) {
            case '#': return 1;
            case 'ID': return 'ID0000';
            default: return 'Sample data';
        }
    }

    render() {
        return <WrapperTable>
            <tbody>
                <DefaultTr>
                    {this.props.columns.map((column, index) => {
                        return <FieldTd key={index} width={column.label === '#' ? 40 : DEFAULT_TD_WIDTH}>
                            {column.label}
                        </FieldTd>;
                    })}
                </DefaultTr>
                <DefaultTr>
                    {this.props.columns.map((column, index) => {
                        return <DataSampleTd key={index} width={column.label === '#' ? 40 : DEFAULT_TD_WIDTH}>
                            {this.getSampleData(column.label)}
                        </DataSampleTd>;
                    })}
                </DefaultTr>
            </tbody>
        </WrapperTable>;
    }
}
