import React, {PureComponent} from 'react';

import {constants} from '@global';
import {Switch} from '@common';
import ViewModal from './Actions/View/Modal';
import EditModal from './Actions/Modify/EditModal';
import {EmployeeInterfaces, LeaveInterfaces} from '@api';

interface IProps {
    // If transaction is undefined, modal is closed
    transactions: LeaveInterfaces.Transaction[] | undefined;
    employee: EmployeeInterfaces.Employee | undefined;
    leaveTypes: LeaveInterfaces.Type[];
    onTransactionOpen?: (e: any, transaction: LeaveInterfaces.Transaction) => void;
    onClose: () => void;
    onClickSave: (employeeId: number, transaction: LeaveInterfaces.Transaction) => void;
    onClickReject: (employeeId: number, transaction: LeaveInterfaces.Transaction) => void;
    onClickApprove: (employeeId: number, transaction: LeaveInterfaces.Transaction) => void;
}

interface IState {
    mode: string;
}

export default class TransactionModal extends PureComponent<IProps, IState>{

    public constructor(props: IProps) {
        super(props);

        this.state = {
            mode: constants.Mode.Read,
        };
    }

    componentWillUnmount() {
        this.props.onClose();
    }

    render() {
        if (!this.props.transactions || !this.props.transactions.length || !this.props.employee) {
            return null;
        }

        return (<Switch
            current={this.state.mode}
            options={new Map([
                [
                    constants.Mode.Read,
                    <ViewModal
                        transactions={this.props.transactions}
                        employee={this.props.employee}
                        onTransactionOpen={this.props.onTransactionOpen}
                        onClickEdit={this.enableEditMode}
                        onClickApprove={this.props.onClickApprove}
                        onClickReject={this.props.onClickReject}
                        onClickClose={this.close}
                    />,
                ],
                [
                    constants.Mode.Update,
                    <EditModal
                        employee={this.props.employee}
                        transaction={this.props.transactions[0]}
                        legalEntityId={this.props.employee.legalEntityId}
                        types={this.props.leaveTypes}

                        onClickSave={this.props.onClickSave}
                        onClickClose={this.close}
                    />,
                ],
            ])}
        />);
    }

    private close = () => {
        this.setState({mode: constants.Mode.Read});
        this.props.onClose();
    }

    private enableEditMode = () => {
        this.setState({mode: constants.Mode.Update});
    }
}
