import {interfaces, intl} from '@global';
import {EmployeeListingHelper} from '@library';
import {AnyAction, Dispatch} from 'redux';
import {ThunkAction} from 'redux-thunk';
import moment, {Moment} from 'moment';
import {toast} from 'react-toastify';

import {
    ApiColumnSettings,
    ApiEmployee,
    ApiLeave,
    ApiObject,
    EmployeeInterfaces,
    EmployeeQueryParams,
    LeaveInterfaces,
    LeaveQueryParams,
} from '@api';

import {RootState} from '../../rootReducer';
import * as selectors from './selectors';
import * as globalSelectors from '../selectors';
import {openErrorModal, startLoading, stopLoading} from '../redux';
import {ModalLocation} from '../common/Leave/Balance/EditBalances';
import {
    getLeaves,
    SET_TRANSITIONAL_LEAVE_BALANCES,
    setPeriodLeaveBalances,
    setTransitionalLeaveBalances,
} from '../Employee/Tabs/Leave/redux';

export type ThunkResult<R> = ThunkAction<R, RootState, undefined, AnyAction> | AnyAction;

export const SET_LEAVE_TYPES = 'leaves/setLeaveTypes';
export const SET_PERIOD_FILTER = 'leaves/setPeriodFilter';
export const SET_TYPE_ID_FILTER = 'leaves/setTypeIdFilter';
export const SET_STATUS_FILTER = 'leaves/setStatusFilter';
export const SET_EMPLOYEE_LEAVES = 'leaves/setEmployeeLeaves';
export const SET_EMPLOYEE_TRANSACTIONS = 'leaves/setEmployeeTransactions';
export const SET_EMPLOYEE_LATEST_BALANCES = 'leaves/setEmployeeLatestBalances';

export const SET_ORDERS = 'leaves/setOrders';
export const SET_FILTERS = 'leaves/setFilters';
export const SET_TAGS = 'leaves/setTags';
export const SET_SEARCH_QUERY = 'leaves/setSearchQuery';

export const SET_EMPLOYEES = 'leaves/setEmployees';
export const SET_SELECTED_IDS = 'leaves/setSelectedIds';

const SET_EMPLOYEE_GROUPS = 'leaves/setEmployeeGroups';
const SET_FIELD_GROUPS = 'leaves/setFieldGroups';

const OPEN_COLUMN_SETTINGS_MODAL = 'leaves/openColumnSettingModal';
const CLOSE_COLUMN_SETTINGS_MODAL = 'leaves/closeColumnSettingModal';
const SET_COLUMN_SETTINGS = 'leaves/setColumnSettings';

const SET_ACTION_TIMESTAMP = 'SET_ACTION_TIMESTAMP';
const SET_EMPLOYEE_LEAVE_REQUESTS = 'leaves/setEmployeeLeaveRequests';
const SET_LEAVE_MODAL_TRANSACTION = 'leaves/setLeaveModalTransaction';

export const SET_EMPLOYEES_CUT_OFFS = "leaves/setEmployeeCutOffs"

export interface EmployeesCutOffs {
    [key: string]: Object
}

export interface LeavesState {
    totalCount: number;
    selectedIds: Set<Number>;

    types: LeaveInterfaces.Type[];
    leaves: LeaveInterfaces.Leaves;
    transactions: LeaveInterfaces.Transactions;
    latestBalances: LeaveInterfaces.EmployeeLatestBalances;
    transitionalBalances: LeaveInterfaces.TransitionalBalances;
    employees: LeaveInterfaces.EmployeeLeave[];
    leaveRequests: LeaveInterfaces.EmployeeLeaveHistory[];

    filters: interfaces.ListingFilters;
    orders: interfaces.ListingOrders;
    tags: EmployeeInterfaces.Tag[];

    filter: {
        from: string,
        till: string,
        typeId: number | null,
        status: string | null,
    };

    fieldGroups: ApiObject.FieldGroup[];
    employeeGroups: interfaces.EmployeeGroups;

    columnSettings: {
        isModalOpen: boolean,
        selectedColumns: string[],
    };
    searchQuery: string;
    timestamp: number;

    transactionModal: undefined | LeaveInterfaces.TransactionModal;

    cutOffStatuses?: EmployeesCutOffs
}

export const defaultState: LeavesState = {
    totalCount: 0,
    selectedIds: new Set(),

    types: [],
    leaves: {},
    transactions: {},
    latestBalances: {},
    transitionalBalances: {},
    employees: [],
    leaveRequests: [],

    filters: {},
    orders: {},
    tags: [],

    filter: {
        from: moment().startOf('month').format(),
        till: moment().endOf('month').format(),
        typeId: null,
        status: null,
    },

    fieldGroups: [],
    employeeGroups: {},

    columnSettings: {
        isModalOpen: false,
        selectedColumns: [],
    },
    searchQuery: '',
    timestamp: 0,

    transactionModal: undefined,
    cutOffStatuses: {}
};

export default function reducer(
    state: LeavesState = defaultState,
    action: AnyAction,
): LeavesState {
    switch (action.type) {
        case SET_ACTION_TIMESTAMP:
            return {
                ...state,
                timestamp: action.payload,
            };
        case SET_EMPLOYEES:
            return {
                ...state,
                employees: action.payload.employees,
                totalCount: action.payload.totalCount,
            };
        case SET_SELECTED_IDS:
            return {
                ...state,
                selectedIds: action.payload,
            };
        case SET_LEAVE_TYPES:
            return {
                ...state,
                types: action.payload,
            };
        case SET_EMPLOYEE_LEAVES:
            return {
                ...state,
                leaves: action.payload.leaves,
                employees: action.payload.employees,
            };
        case SET_EMPLOYEE_TRANSACTIONS:
            return {
                ...state,
                transactions: action.payload,
            };
        case SET_EMPLOYEE_LATEST_BALANCES:
            return {
                ...state,
                latestBalances: action.payload,
            };
        case SET_TRANSITIONAL_LEAVE_BALANCES:
            return {
                ...state,
                transitionalBalances: action.payload,
            };
        case SET_EMPLOYEE_LEAVE_REQUESTS:
            return {
                ...state,
                leaveRequests: action.payload.transactions,
            };
        case SET_PERIOD_FILTER:
            return {
                ...state,
                filter: {
                    ...state.filter,
                    ...action.payload,
                },
            };
        case SET_TYPE_ID_FILTER:
            return {
                ...state,
                filter: {
                    ...state.filter,
                    typeId: action.payload,
                },
            };
        case SET_STATUS_FILTER:
            return {
                ...state,
                filter: {
                    ...state.filter,
                    status: action.payload,
                },
            };
        case SET_TAGS:
            return {
                ...state,
                tags: action.payload,
            };
        case SET_ORDERS:
            return {
                ...state,
                orders: action.payload,
            };
        case SET_FILTERS:
            return {
                ...state,
                filters: action.payload,
            };
        case SET_EMPLOYEE_GROUPS:
            return {
                ...state,
                employeeGroups: action.payload,
            };
        case SET_FIELD_GROUPS:
            return {
                ...state,
                fieldGroups: action.payload,
            };
        case SET_COLUMN_SETTINGS:
            return {
                ...state,
                columnSettings: { ...state.columnSettings, selectedColumns: action.payload },
            };
        case OPEN_COLUMN_SETTINGS_MODAL:
            return {
                ...state,
                columnSettings: { ...state.columnSettings, isModalOpen: true },
            };
        case CLOSE_COLUMN_SETTINGS_MODAL:
            return {
                ...state,
                columnSettings: { ...state.columnSettings, isModalOpen: false },
            };
        case SET_SEARCH_QUERY:
            return {
                ...state,
                searchQuery: action.payload,
            };
        case SET_LEAVE_MODAL_TRANSACTION:
            return {
                ...state,
                transactionModal: action.payload,
            };
        case SET_EMPLOYEES_CUT_OFFS:
            return {
                ...state,
                cutOffStatuses: action.payload
            };
        default:
            return state;
    }
}

export function setLeaveTypes(types: LeaveInterfaces.Type[]) {
    return {
        type: SET_LEAVE_TYPES,
        payload: types,
    };
}

export function setEmployeeLeaves(leaves: LeaveInterfaces.Leaves, employees: EmployeeInterfaces.Employee[]): AnyAction {
    return {
        type: SET_EMPLOYEE_LEAVES,
        payload: {
            leaves,
            employees,
        },
    };
}

export function setEmployeeTransactions(transactions: LeaveInterfaces.Transactions): AnyAction {
    return {
        type: SET_EMPLOYEE_TRANSACTIONS,
        payload: transactions,
    };
}

export function setEmployeeLatestBalances(latestBalances: LeaveInterfaces.EmployeeLatestBalances): AnyAction {
    return {
        type: SET_EMPLOYEE_LATEST_BALANCES,
        payload: latestBalances,
    };
}

export function setPeriodFilter(from: Moment, till: Moment) {
    return {
        type: SET_PERIOD_FILTER,
        payload: {
            from: from.format(),
            till: till.format(),
        },
    };
}

export function setTypeIdFilter(id: number | null) {
    return {
        type: SET_TYPE_ID_FILTER,
        payload: id,
    };
}

export function setStatusFilter(status: string | null) {
    return {
        type: SET_STATUS_FILTER,
        payload: status,
    };
}

export function setOrders(orders: interfaces.ListingOrders) {
    return {
        type: SET_ORDERS,
        payload: orders,
    };
}

export function setFilters(filters: interfaces.ListingFilters) {
    return {
        type: SET_FILTERS,
        payload: filters,
    };
}

export function setTags(tags: EmployeeInterfaces.Tag[]) {
    return {
        type: SET_TAGS,
        payload: tags,
    };
}

export function setEmployees(employees: EmployeeInterfaces.Employee[], totalCount: number = 0): AnyAction {
    return {
        type: SET_EMPLOYEES,
        payload: { employees, totalCount },
    };
}

export function setSelectedIds(ids: Set<number>) {
    return {
        type: SET_SELECTED_IDS,
        payload: ids,
    };
}

export function setSearchQuery(searchQuery: string): AnyAction {
    return {
        type: SET_SEARCH_QUERY,
        payload: searchQuery,
    };
}

export function setLoadingTimestamp(timestamp: number) {
    return {
        type: SET_ACTION_TIMESTAMP,
        payload: timestamp,
    };
}

export function setEmployeeLeaveRequests(transactions: LeaveInterfaces.EmployeeLeaveHistory[]) {
    return {
        type: SET_EMPLOYEE_LEAVE_REQUESTS,
        payload: { transactions },
    };
}

export function openLeaveTransactionModal(modalState: LeaveInterfaces.TransactionModal) {
    return async(dispatch: Dispatch) => {
        dispatch({
            type: SET_LEAVE_MODAL_TRANSACTION,
            payload: modalState,
        });

        const balances = await ApiLeave.getEmployeeLeaveBalances(
            modalState.employee.legalEntityId,
            modalState.employee.id,
            modalState.transactions[0].period.startDate,
            modalState.transactions[0].period.endDate,
        );
        dispatch(setPeriodLeaveBalances(balances));
    };
}

export function closeLeaveTransactionModal() {
    return {
        type: SET_LEAVE_MODAL_TRANSACTION,
        payload: undefined,
    };
}

export function getEmployees(offset: number, limit: number = 8): ThunkResult<Promise<void>> {
    return async(dispatch: Dispatch, getState: () => RootState) => {
        const state = getState();
        const currentLegalEntityId = state.global.currentLegalEntityId;
        if (!currentLegalEntityId) {
            return;
        }

        const params = new EmployeeQueryParams({
            orders: selectors.getOrders(state, null),
            filters: selectors.getFilters(state, null),
            tags: selectors.getTags(getState(), null),
            currentPeriod: globalSelectors.getCurrentPayrollPeriod(state),
            status: 'active',
            offset,
            pageSize: limit,
        });
        const { employees, total } = await ApiEmployee.list(currentLegalEntityId, params);
        if (employees.length === 0) {
            return;
        }

        dispatch(
            setEmployees([...getState().leaves.employees, ...employees], total),
        );

        const employeesIds = employees.map((emp: any) => {
            return emp.id;
        }) || [];

        if (employeesIds.length > 0 ){
            const { employeesCutoffStatuses } = await ApiEmployee.getEmployeeCutOffs(currentLegalEntityId, employeesIds)
            dispatch(setEmployeeCutOffsStatuses({...getState().leaves.cutOffStatuses, ...employeesCutoffStatuses}))
        }
        
        const leaves = await ApiLeave.getLeaves(
            currentLegalEntityId,
            new LeaveQueryParams({
                from: selectors.getPeriodFilterFrom(getState(), null),
                till: selectors.getPeriodFilterTill(getState(), null),
                typeId: selectors.getTypeIdFilter(state, null),
                status: selectors.getStatusFilter(state, null),
                employeeIds: employeesIds,
            }),
        );

        const newLeaves = {...getState().leaves.leaves, ...leaves}

        dispatch(setEmployeeLeaves(newLeaves, [...getState().leaves.employees]));

        const transactions = await ApiLeave.getTransactions(
            currentLegalEntityId,
            new LeaveQueryParams({
                from: selectors.getPeriodFilterFrom(getState(), null),
                till: selectors.getPeriodFilterTill(getState(), null),
                employeeIds: employeesIds,
            }),
        );

        dispatch(setEmployeeTransactions(transactions));

        if (!state.employees.isCheckSelectAll) {
            return;
        }
        const ids = new Set(state.employees.selectedIds);
        for (const employee of employees) {
            ids.add(employee.id);
        }
        dispatch(setSelectedIds(ids));

        return;
    };
}

export function getEmployeeLatestBalances(employeeId: number) {
    return async(dispatch: Dispatch, getState: () => RootState) => {
        const state = getState();
        const currentLegalEntityId = state.global.currentLegalEntityId;
        if (!currentLegalEntityId) {
            return;
        }

        const latestBalance = await ApiLeave.getLeaves(
            currentLegalEntityId,
            new LeaveQueryParams({
                employeeIds: [employeeId],
            }),
        );

        dispatch(setEmployeeLatestBalances(mergeDeep(Object.assign({}, state.leaves.latestBalances), latestBalance)));

        return;
    };
}

export function reloadLeaves() {
    return async(dispatch: Dispatch) => {
        dispatch(clearAndGetEmployeeBalances() as any);
    };
}

export function mergeDeep(target: any, source: any) {
    const isObject = (obj: any) => obj && typeof obj === 'object';

    if (!isObject(target) || !isObject(source)) {
      return source;
    }

    Object.keys(source).forEach(key => {
      const targetValue = target[key];
      const sourceValue = source[key];

      if (Array.isArray(targetValue) && Array.isArray(sourceValue)) {
        target[key] = targetValue.concat(sourceValue);
      } else if (isObject(targetValue) && isObject(sourceValue)) {
        target[key] = mergeDeep(Object.assign({}, targetValue), sourceValue);
      } else {
        target[key] = sourceValue;
      }
    });

    return target;
}

function actionIsValid(state: RootState, data: any) {
    return data >= state.leaves.timestamp;
}

export function clearAndGetEmployeeBalances(): ThunkResult<Promise<void>> {
    return async(dispatch: Dispatch, getState: () => RootState) => {

        const state = getState();
        const timestamp = Date.now();

        dispatch(setLoadingTimestamp(timestamp));

        const entityId = state.global.currentLegalEntityId;
        if (!entityId) {
            return;
        }

        dispatch(startLoading());
        dispatch(setEmployeeLeaves({}, []));
        dispatch(setEmployeeTransactions([]));
        dispatch(setEmployeeCutOffsStatuses(null))

        try {

            const params: EmployeeQueryParams = new EmployeeQueryParams({
                filters: selectors.getFilters(state, null),
                orders: selectors.getOrders(state, null),
                tags: selectors.getTags(state, null),
                currentPeriod: globalSelectors.getCurrentPayrollPeriod(state),
                status: 'active',
                pageSize: 8,
            });

            const response = await ApiEmployee.list(entityId, params);

            const employees = response.employees || [];
            const total = response.total || 0;

            if (!actionIsValid(getState(), timestamp)) {
                dispatch(stopLoading());
                return;
            }

            dispatch(
                setEmployees([...getState().leaves.employees, ...employees], total),
            );

            const employeesIds = employees.map((emp: any) => {
                return emp.id;
            }) || [];

            if (employeesIds.length > 0 ){

                const { employeesCutoffStatuses } = await ApiEmployee.getEmployeeCutOffs(entityId, employeesIds)
                dispatch(setEmployeeCutOffsStatuses({...getState().leaves.cutOffStatuses, ...employeesCutoffStatuses}))

            }

            const leaves = employeesIds.length > 0 ? await ApiLeave.getLeaves(
                entityId,
                new LeaveQueryParams({
                    from: selectors.getPeriodFilterFrom(getState(), null),
                    till: selectors.getPeriodFilterTill(getState(), null),
                    typeId: selectors.getTypeIdFilter(state, null),
                    status: selectors.getStatusFilter(state, null),
                    employeeIds: employeesIds,
                }),
            ) : [];

            const newLeaves = {...getState().leaves.leaves, ...leaves}

            dispatch(setEmployeeLeaves(newLeaves, [...getState().leaves.employees]));

            const transactions = await ApiLeave.getTransactions(
                entityId,
                new LeaveQueryParams({
                    from: selectors.getPeriodFilterFrom(getState(), null),
                    till: selectors.getPeriodFilterTill(getState(), null),
                    employeeIds: employeesIds,
                }),
            );

            dispatch(setEmployeeTransactions(transactions));

        } catch (e) {
            const errors = e.response?.data?.errors || [];
            if (errors[0] && errors[0].message) {
                dispatch(openErrorModal(
                    [errors[0].message],
                    intl.get('oops_sth_went_wrong'),
                ));
            }
        }

        dispatch(stopLoading());

        return;
    };
}
export function setEmployeeCutOffsStatuses(employeesCutoffStatuses: EmployeesCutOffs | null): AnyAction {
    return {
        type: SET_EMPLOYEES_CUT_OFFS,
        payload: employeesCutoffStatuses,
    };
}

export function getLeaveTypes() {
    return async(dispatch: Dispatch, getState: () => RootState) => {
        const state = getState();
        const entityId = state.global.currentLegalEntityId;
        if (!entityId) {
            return;
        }

        dispatch(setLeaveTypes(
            await ApiLeave.getTypes(entityId),
        ));

        return;
    };
}

export function approve(employeeId: number, transaction: LeaveInterfaces.Transaction) {
    return async(dispatch: Dispatch, getState: () => RootState) => {
        const entityId = getState().global.currentLegalEntityId;
        if (!entityId) {
            return;
        }

        dispatch(startLoading());

        await ApiLeave.approve(entityId, transaction.id);
        transaction.status = LeaveInterfaces.TransactionStatus.pending;
        dispatch(updateLeaveTransaction(
            employeeId,
            { ...transaction, status: LeaveInterfaces.TransactionStatus.pending },
        ) as any);

        toast.success(intl.get('leave_transaction_approved_ok'));
        dispatch(stopLoading());

        return;
    };
}

export function reject(employeeId: number, transaction: LeaveInterfaces.Transaction) {
    return async(dispatch: Dispatch, getState: () => RootState) => {
        const entityId = getState().global.currentLegalEntityId;
        if (!entityId) {
            return;
        }

        dispatch(startLoading());

        await ApiLeave.reject(entityId, transaction.id);
        dispatch(updateLeaveTransaction(
            employeeId,
            { ...transaction, status: LeaveInterfaces.TransactionStatus.rejected },
        ) as any);

        toast.success(intl.get('leave_transaction_rejected_ok'));
        dispatch(stopLoading());

        return;
    };
}

export function getTransitionalBalances(employeeId: number) {
    return async(dispatch: Dispatch, getState: () => RootState) => {
        const entityId = getState().global.currentLegalEntityId;

        if (!entityId || !employeeId) {
            return;
        }

        dispatch(startLoading());
        const from = selectors.getPeriodFilterFrom(getState(), null).format('YYYY-MM-DD');

        try {
            const transitionalBalances = await ApiLeave.getEmployeeTransitionalBalances(
                entityId,
                employeeId,
                moment(from).add(-1, 'year').format('YYYY-MM-DD'),
                selectors.getPeriodFilterTill(getState(), null).format('YYYY-MM-DD'),
            );

            dispatch(setTransitionalLeaveBalances(transitionalBalances));
        } catch (e) {
            const errors = e.response?.data?.errors || [];
            if (errors[0] && errors[0].message) {
                dispatch(openErrorModal(
                    [errors[0].message],
                    intl.get('oops_sth_went_wrong'),
                ));
            }
        } finally {
            dispatch(stopLoading());
        }
        return;
    };
}

export function create(employeeId: number, transaction: LeaveInterfaces.Transaction) {
    return async(dispatch: Dispatch, getState: () => RootState) => {
        const state = getState();
        const entityId = state.global.currentLegalEntityId;
        if (!entityId) {
            return;
        }

        dispatch(startLoading());
        try {
            const responseTransaction: LeaveInterfaces.Transaction[] = await ApiLeave.create(
                entityId,
                employeeId,
                transaction,
            );
            dispatch(updateLeaveTransaction(employeeId, responseTransaction[0]) as any);
            toast.success(intl.get('leave_transaction_created_ok'));
        } catch (e) {
            toast.error(intl.get('leave_transaction_created_nok'));

            return false;
        } finally {
            dispatch(stopLoading());
        }

        return;
    };
}

export function createAndApprove(employeeId: number, transaction: LeaveInterfaces.Transaction) {
    return async(dispatch: Dispatch, getState: () => RootState) => {
        const state = getState();
        const entityId = state.global.currentLegalEntityId;
        if (!entityId) {
            return;
        }

        dispatch(startLoading());

        const responseTransaction = await ApiLeave.create(entityId, employeeId, transaction);

        if (!responseTransaction) {
            return;
        }

        await ApiLeave.approve(entityId, responseTransaction[0].id);

        dispatch(updateLeaveTransaction(employeeId, {
            ...responseTransaction[0],
            status: LeaveInterfaces.TransactionStatus.pending,
        }) as any);

        toast.success(intl.get('leave_transaction_created_approved_ok'));

        dispatch(stopLoading());

        return;
    };
}

export function store(employeeId: number, transaction: LeaveInterfaces.Transaction) {
    return async(dispatch: Dispatch, getState: () => RootState) => {
        const state = getState();
        const entityId = state.global.currentLegalEntityId;
        if (!entityId) {
            return;
        }

        dispatch(startLoading());

        try {
            await ApiLeave.store(entityId, transaction);

            dispatch(updateLeaveTransaction(employeeId, transaction) as any);
            toast.success(intl.get('leave_transaction_updated_ok'));
        } catch (e) {
            toast.error(intl.get('leave_transaction_update_nok'));

            return false;
        } finally {
            dispatch(stopLoading());
        }

        return;
    };
}

function updateEmployeeBalance(forEmployees: Set<number>|undefined) {
    return async(dispatch: Dispatch, getState: () => RootState) => {
        const state = getState();
        const currentLegalEntityId = state.global.currentLegalEntityId;
        const leaves = state.leaves.leaves;

        if (!forEmployees) {
            return;
        }

        const forEmployeeValues = forEmployees.values();
        const employeeId = forEmployeeValues.next().value;

        if (!currentLegalEntityId || !employeeId) {
            return;
        }

        const latestBalance = await ApiLeave.getLeaves(
            currentLegalEntityId,
            new LeaveQueryParams({
                employeeIds: [employeeId],
            }),
        );

        const newBalances = {...leaves, ...latestBalance};
        dispatch(setEmployeeLeaves(newBalances, state.leaves.employees));

        return;
    };
}

export function storeBalanceAdjustment(
    adjustments: LeaveInterfaces.BalanceAdjustment[],
    triggerLocation: ModalLocation,
    forEmployees: Set<number>|undefined,
) {
    return async(dispatch: Dispatch, getState: () => RootState) => {
        const state = getState();
        const entityId = state.global.currentLegalEntityId;
        if (!entityId) {
            return;
        }

        dispatch(startLoading());

        adjustments = adjustments.filter(adjustment => adjustment.balanceAdjustment !== 0);
        try {
            await ApiLeave.balanceAdjustment(
                entityId,
                adjustments,
            );

            if (triggerLocation === ModalLocation.LeavesView) {
                dispatch(updateEmployeeBalance(forEmployees) as any);
            } else if (triggerLocation === ModalLocation.EmployeeView) {
                dispatch(getLeaves() as any);
            }

            toast.success(intl.get('leave_balance_update_ok'));
        } catch (e) {
            toast.error(intl.get('leave_balance_update_nok'));

            return false;
        } finally {
            dispatch(stopLoading());
        }

        return;
    };
}

export function updateLeaveTransaction(employeeId: number, transaction: LeaveInterfaces.Transaction) {
    return async(dispatch: Dispatch) => {
        dispatch(updateLeavesLeaveTransaction(employeeId, transaction) as any);
        dispatch(updateEmployeeLeaveTransaction(employeeId, transaction) as any);
    };
}

function updateLeavesLeaveTransaction(
    employeeId: number,
    transaction: LeaveInterfaces.Transaction,
) {
    return async(dispatch: Dispatch, getState: () => RootState) => {
        const state = getState();
        const entityId = state.global.currentLegalEntityId;
        const transactions: LeaveInterfaces.Transactions = { ...state.leaves.transactions };
        const employees = [...state.leaves.employees];

        if (entityId && !employees.find((employee: EmployeeInterfaces.Employee) => employee.id === employeeId)) {
            const [forEmployee] = await ApiEmployee.findByIds(entityId, [employeeId]);
            employees.push(forEmployee);
        }

        if (!transactions[employeeId]) {
            transactions[employeeId] = {
                transactions: [transaction],
            };
        } else {
            const oldTransactionIndex = transactions[employeeId].transactions
                .findIndex((t: LeaveInterfaces.Transaction) => t.id === transaction.id);
            if (oldTransactionIndex !== -1) {
                transactions[employeeId].transactions.splice(oldTransactionIndex, 1);
            }

            transactions[employeeId].transactions.push(transaction);
        }

        dispatch(setEmployeeTransactions(transactions));

        return;
    };
}

function updateEmployeeLeaveTransaction(
    employeeId: number,
    transaction: LeaveInterfaces.Transaction,
) {
    return async(dispatch: Dispatch, getState: () => RootState) => {
        const state = getState();
        const leaves = state.leaves.leaveRequests;
        const entityId = state.global.currentLegalEntityId;

        const employees = state.leaves.employees;
        let employee = employees.find(e => e.id === employeeId);

        if (!employee) {
            if (!entityId) {
                return;
            }

            const params: EmployeeQueryParams = new EmployeeQueryParams({
                filters: selectors.getFilters(state, null),
                orders: selectors.getOrders(state, null),
                tags: selectors.getTags(state, null),
                employeeIds: [employeeId],
            });

            const response = await ApiEmployee.list(entityId, params);
            setEmployees([state.leaves.employees, ...response.employees], response.total + 1);
            const employees = [state.leaves.employees, ...response.employees];
            employee = employees.find(e => e.id === employeeId);

            if (!employee) {
                return;
            }
        }

        const oldTransactionIndex = leaves
            .findIndex((t: LeaveInterfaces.EmployeeLeaveHistory) => t.transaction.id === transaction.id);
        if (oldTransactionIndex !== -1) {
            leaves.splice(oldTransactionIndex, 1);
        }

        const period = {
            startDate: transaction.startingPeriodBeginDate || transaction.startDate,
            endDate: transaction.startingPeriodEndDate || transaction.endDate || moment(transaction.startDate).endOf('month').format('YYYY-MM-DD'),
        }

        const newTransaction = {transaction: {...transaction, period }, employee: employee};

        dispatch(setEmployeeLeaveRequests(leaves.concat([newTransaction])));

        return;
    };
}

export function resetOrdersAndFilters(): any {
    return (dispatch: Dispatch) => {
        dispatch(setTags([]));
        dispatch(setOrders({}));
        dispatch(setFilters({}));
        dispatch(setSearchQuery(''));
        return;
    };
}

export function changeOrder(column: string): any {
    return (dispatch: Dispatch, getState: () => RootState) => {
        const orders: interfaces.ListingOrders = { ...getState().leaves.orders };
        dispatch(
            setOrders(
                EmployeeListingHelper.updatedOrders(column, orders),
            ),
        );

        return;
    };
}

export function changeFilter(params: interfaces.FilterParams) {
    return (dispatch: Dispatch, getState: () => RootState) => {
        const filters: interfaces.ListingFilters = { ...getState().leaves.filters };

        dispatch(
            setFilters(
                EmployeeListingHelper.updatedFilters(params, filters),
            ),
        );

        if (params.searchQuery || params.searchQuery === '') {
            dispatch(setSearchQuery(params.searchQuery));
        }

        return;
    };
}

export function changeSearchQuery(searchQuery: string) {
    return (dispatch: Dispatch, getState: () => RootState) => {

        const filters: interfaces.ListingFilters = {...getState().leaves.filters};
        const params = {
            column: 'id',
            value: [],
            expression: 'in',
            type: 'text',
            searchQuery,
        };
        dispatch(
            setFilters(
                EmployeeListingHelper.updatedFilters(params, filters),
            ),
        );

        dispatch(setSearchQuery(searchQuery));

        return;
    };
}

export function getEmployeeGroups(): ThunkResult<Promise<void>> {
    return async(dispatch: Dispatch, getState: () => RootState) => {
        const currentLegalEntityId = getState().global.currentLegalEntityId;
        if (!currentLegalEntityId) {
            return;
        }

        dispatch(
            setEmployeeGroups(await ApiEmployee.getEmployeeGroups(currentLegalEntityId)),
        );

        return;
    };
}

export function getFieldGroups(): ThunkResult<Promise<void>> {
    return async(dispatch: Dispatch, getState: () => RootState) => {
        const currentLegalEntityId = getState().global.currentLegalEntityId;
        if (!currentLegalEntityId) {
            return;
        }

        dispatch(
            setFieldGroups(await ApiEmployee.getFieldGroups(currentLegalEntityId)),
        );

        return;
    };
}

export function setFieldGroups(fieldGroups: any) {
    return {
        type: SET_FIELD_GROUPS,
        payload: fieldGroups,
    };
}

export function setEmployeeGroups(data: any): AnyAction {
    return {
        type: SET_EMPLOYEE_GROUPS,
        payload: data,
    };
}

export function openColumnSettingsModal(): AnyAction {
    return {
        type: OPEN_COLUMN_SETTINGS_MODAL,
    };
}

export function closeColumnSettingsModal(): AnyAction {
    return {
        type: CLOSE_COLUMN_SETTINGS_MODAL,
    };
}

export function getColumnSettings() {
    return async(dispatch: Dispatch, getState: () => RootState) => {
        const currentLegalEntityId = getState().global.currentLegalEntityId;
        if (!currentLegalEntityId) {
            return;
        }

        const settings = await ApiColumnSettings.getSelectedLeavesColumns(currentLegalEntityId);
        dispatch(
            setColumnSettings(settings.columns),
        );

        return;
    };
}

export function saveColumnSettings(columns: string[]) {
    return async(dispatch: Dispatch, getState: () => RootState) => {
        const currentLegalEntityId = getState().global.currentLegalEntityId;
        if (!currentLegalEntityId) {
            return;
        }

        dispatch(startLoading());

        await ApiColumnSettings.saveLeavesColumnSettings(currentLegalEntityId, columns);
        dispatch(
            setColumnSettings(columns),
        );
        toast.success(intl.get('column_settings_saved_ok'));

        dispatch(stopLoading());

        return;
    };
}

export function setColumnSettings(columns: string[]) {
    return {
        type: SET_COLUMN_SETTINGS,
        payload: columns,
    };
}

export function getLeaveTransactions(overRideTimestampCheck: boolean = false): ThunkResult<Promise<void>> {
    return async(dispatch: Dispatch, getState: () => RootState) => {
        const state = getState();
        const entityId = state.global.currentLegalEntityId;
        const timestamp = Date.now();

        dispatch(setLoadingTimestamp(timestamp));

        if (!entityId) {
            return;
        }

        dispatch(startLoading());
        dispatch(setEmployeeLeaveRequests([]));

        try {
            const data = await ApiLeave.getEmployeeLeaveTransactions(
                entityId,
                new LeaveQueryParams({
                    from: selectors.getPeriodFilterFrom(getState(), null),
                    till: selectors.getPeriodFilterTill(getState(), null),
                }),
            );
            if (data.length > 0) {
                const leaveRequests = data[0]?.employeeTransactions;
                const period = {
                    ...data[0],
                };
                if (period.employeeTransactions) {
                    delete period.employeeTransactions;
                }

                const employeesIds = Object.keys(leaveRequests).map(function(key: string) {
                    return Number(key);
                }) || [];

                const params: EmployeeQueryParams = new EmployeeQueryParams({
                    filters: selectors.getFilters(state, null),
                    orders: selectors.getOrders(state, null),
                    tags: selectors.getTags(state, null),
                    employeeIds: employeesIds,
                });

                let response = await ApiEmployee.list(entityId, params);
                const employees = response.employees || [];

                while (response.nextPointer) {
                    response = await ApiEmployee.list(entityId, new EmployeeQueryParams({nextPointer: response.nextPointer}))
                    if (response.employees) {
                        employees.push(...response.employees)
                    }
                }

                const output: LeaveInterfaces.EmployeeLeaveHistory[] = [];
                employees.forEach((emp: EmployeeInterfaces.Employee) => {
                    const transactions = leaveRequests[emp.id] ? leaveRequests[emp.id].transactions : [];
                    transactions.forEach((transaction: LeaveInterfaces.Transaction) => {
                        const data = {transaction: {...transaction, period }, employee: emp};
                        output.push(data);
                    });
                });
                if (!actionIsValid(getState(), timestamp) && !overRideTimestampCheck) {
                    dispatch(stopLoading());
                    return;
                }
                dispatch(setEmployeeLeaveRequests(output));
            }
        } catch (e) {
            dispatch(openErrorModal(
                [e.message],
                intl.get('oops_sth_went_wrong'),
            ));
        }

        dispatch(stopLoading());

        return;
    };
}
