import React, {PureComponent} from 'react';

import {EmployeeInterfaces} from '@api';
import styled from 'styled-components';
import {stylingVariables} from '@global';
import {FormatNumbers, Layout} from '@common';
import moment from 'moment';

const Content = styled.div<{showBubble: boolean}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 5px;

  li {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
  }

  ${props => !props.showBubble && `
     ul > :first-child {
        h4:before {
          height: 20px;
          margin-top: 20px;
        }
     }
  `}

  li > span {
    position: absolute;
    top: 31px;
    left: 45px;
    font-weight: ${stylingVariables.fontWeight.semibold};
  }
`;

const Bubble = styled.div<{marginRight?: number, marginLeft?: number}>`
  border-radius: 50%;
  background: ${stylingVariables.colorPalette.gray};
  height: 24px;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${stylingVariables.fontSize.mediumLarge};
  margin-right: ${props => props.marginRight || 0}px;
  margin-left: ${props => props.marginLeft || 0}px;
`;

const LinedHeader = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    h4 {
        position: relative;
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-size: ${stylingVariables.fontSize.medium};
        font-weight: ${stylingVariables.fontWeight.regular};
        padding: 0;
        margin: 0;
    }

    span {
        position: absolute;
        top: 35px;
        left: 14px;
        font-weight: ${stylingVariables.fontWeight.regular};
    }

    h4:after {
        content: '';
        border-bottom: 1px solid ${stylingVariables.colorPalette.darkGray};
        margin: 40px 0 0 6px;
        flex: 1 0 10px;
    }

    h4:before {
        content: '';
        border-bottom: 1px solid ${stylingVariables.colorPalette.darkGray};
        border-left: 1px solid ${stylingVariables.colorPalette.darkGray};
        width: 20px;
        height: 40px;
        margin-right: 7px;
    }
`;

const Subtitle = styled(Layout.Subtitle)`
    label {
      margin-left: 5px;
      color: ${stylingVariables.colorPalette.orange};
      background: ${stylingVariables.colorPalette.gray};
      padding: 3px 6px;
      border-radius: 13px;
    }
`;

interface IProps {
    history: EmployeeInterfaces.EmployeePayElementHistory[];
    showBubble: boolean;
}

export default class HistoryGroup extends PureComponent<IProps> {
    public render() {
        return (
            <div>
                {this.props.showBubble && <Bubble marginLeft={-6}>{this.props.history.length}</Bubble>}
                <Content showBubble={this.props.showBubble}>
                    <ul>
                        {
                            this.props.history.map((history, index: number) => (
                                <li
                                    key={index}
                                >
                                    <LinedHeader><h4><span>{index + 1}</span></h4></LinedHeader>
                                    <Subtitle>
                                        {moment(
                                            history.startDate,
                                            'YYYY-MM-DD',
                                        ).format('MMM DD, YYYY')} - {history.endDate ?
                                            moment(
                                                history.endDate,
                                                'YYYY-MM-DD',
                                            ).format('MMM DD, YYYY')
                                            : '...'
                                        }
                                        <label>
                                            <FormatNumbers showZero={true} value={history.amount}/> {history.currency}
                                        </label>
                                    </Subtitle>
                                </li>
                            ))
                        }
                    </ul>
                </Content>
            </div>
        );
    }
}
