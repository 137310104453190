import React, { PureComponent, Fragment } from "react";
import { intl } from "@global";
import moment, { Moment } from "moment";
import styled from "styled-components";
import { connect } from "react-redux";

import { SearchInputControlled } from "@common";
import { LeaveInterfaces, PayrollInterfaces } from "@api";
import { stylingVariables, constants } from "@global";

import * as entrySelectors from "./../../selectors";
import * as selectors from "../../Leaves/selectors";
import Table from "./Table/Table";
import Period from "./../Details/Period";
import { RootState } from "../../../rootReducer";
import {
	setSearchQuery,
	changeSearchQuery,
	clearAndGetEmployeeBalances,
	getLeaveTransactions,
	getLeaveTypes,
	store,
	approve,
	reject,
	closeLeaveTransactionModal,
	openLeaveTransactionModal,
	resetOrdersAndFilters,
} from "../redux";
import TransactionModal from "../../common/Leave/Transaction/TransactionModal";
import { debounce } from "lodash";
import * as globalSelectors from "../../selectors";

const RightSideWrapper = styled.div`
	display: flex;
	align-items: center;
`;

const RightSideSpacer = styled.div`
	width: 20px;
`;

const TitleWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	margin: 0 0 25px 25px;
`;

const TitleContainer = styled.div`
	h4 {
		font-weight: ${stylingVariables.fontWeight.semibold};
		color: ${stylingVariables.colorPalette.darkest};
		display: inline;
	}

	text {
		font-size: ${stylingVariables.fontSize.mediumLarge};
	}
`;

const SmallText = styled.span`
	font-size: ${stylingVariables.fontSize.medium};
	font-weight: ${stylingVariables.fontWeight.semibold};
	color: ${stylingVariables.colorPalette.deepGray};
`;

interface IProps {
	clearAndGetEmployeeBalances: () => void;
	getLeaveTransactions: () => void;
	getLeaveTypes: () => void;
	store: (employeeId: number, transaction: LeaveInterfaces.Transaction) => void;
	approve: (employeeId: number, transaction: LeaveInterfaces.Transaction) => void;
	reject: (employeeId: number, transaction: LeaveInterfaces.Transaction) => void;
	closeLeaveTransactionModal: () => void;
	setSearchQuery: (searchQuery: string) => void;
	resetOrdersAndFilters: () => void;
	changeSearchQuery: (searchQuery: string) => void;
	openLeaveTransactionModal: (modalState: LeaveInterfaces.TransactionModal) => void;

	transactionModal?: LeaveInterfaces.TransactionModal;
	leaveTransactions: LeaveInterfaces.EmployeeLeaveHistoryGrouped[];
	searchQuery: string;
	leaveTypes: LeaveInterfaces.Type[];
	currentPayrollPeriod: PayrollInterfaces.PayrollPeriod | null;
	periodFilterFrom: Moment;
	isLoading: boolean;
}

interface IState {
	showPayrollPeriod: boolean;
	searchValue: string;
}

export class Details extends PureComponent<IProps, IState> {
	public constructor(props: IProps) {
		super(props);

		this.state = {
			showPayrollPeriod: true,
			searchValue: this.props.searchQuery,
		};

		props.getLeaveTransactions();
		props.getLeaveTypes();
	}

	componentDidMount() {
		this.props.resetOrdersAndFilters();
	}

	componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
		if (this.props.searchQuery === "" && this.props.searchQuery !== prevProps.searchQuery) {
			this.setState({
				searchValue: this.props.searchQuery,
			});
			this.props.getLeaveTransactions();
		}
	}

	private onSearchChange = (event: any) => {
		this.setState({
			searchValue: event.target.value,
		});
		this.search(event.target.value);
	};

	private search = debounce((searchQuery: string) => {
		this.props.changeSearchQuery(searchQuery);
		this.props.getLeaveTransactions();
	}, 300);

	onOpen = (e: any, transaction: LeaveInterfaces.Transaction) => {
		if (this.props.transactionModal) {
			this.props.openLeaveTransactionModal({
				employee: this.props.transactionModal.employee,
				transactions: [transaction],
			});
		}
	};

	public render() {
		return (
			<Fragment>
				<TitleWrapper>
					<TitleContainer>
						<h4>{intl.get("leave_transactions")}</h4>
						<SmallText>
							{intl.get("as_of_")}
							{this.props.periodFilterFrom
								? moment(this.props.periodFilterFrom, constants.SYSTEM_DATE_FORMAT).format("MMMM YYYY")
								: null}
						</SmallText>
					</TitleContainer>
					<RightSideWrapper>
						<SearchInputControlled
							placeholder={"search"}
							searchValue={this.state.searchValue}
							onChange={this.onSearchChange}
						/>
						<RightSideSpacer />
						<Period isDisabled={this.props.isLoading} />
					</RightSideWrapper>
				</TitleWrapper>
				<Table
					leaveRows={this.props.leaveTransactions}
					showPayrollPeriod={this.state.showPayrollPeriod}
					selectedTransaction={this.props.transactionModal?.transactions}
				/>
				<TransactionModal
					transactions={this.props.transactionModal?.transactions}
					employee={this.props.transactionModal?.employee}
					leaveTypes={this.props.leaveTypes}
					onTransactionOpen={this.onOpen}
					onClose={this.props.closeLeaveTransactionModal}
					onClickSave={this.props.store}
					onClickReject={this.props.reject}
					onClickApprove={this.props.approve}
				/>
			</Fragment>
		);
	}
}

const mapStateToProps = (state: RootState, ownProps: any) => ({
	isLoading: globalSelectors.isLoading(state),
	searchQuery: selectors.getSearchQuery(state),
	leaveTypes: selectors.getActiveLeaveTypes(state, ownProps),
	currentPayrollPeriod: entrySelectors.getCurrentPayrollPeriod(state),
	periodFilterFrom: selectors.getPeriodFilterFrom(state, ownProps),
	leaveTransactions: selectors.getTransactions(state, ownProps),
	transactionModal: selectors.getLeaveModalState(state),
});

const mapDispatchToProps = {
	setSearchQuery,
	clearAndGetEmployeeBalances,
	changeSearchQuery,
	getLeaveTransactions,
	getLeaveTypes,
	store,
	approve,
	reject,
	closeLeaveTransactionModal,
	openLeaveTransactionModal,
	resetOrdersAndFilters,
};

export default connect(mapStateToProps, mapDispatchToProps)(Details);
