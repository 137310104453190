import React, {PureComponent, Fragment} from 'react';
import {connect} from 'react-redux';

import {LeaveInterfaces, EmployeeInterfaces} from '@api';
import {Switch, Actions as CommonActions} from '@common';

import ViewAction from '../../Actions/ViewAction';
import ApproveAction from '../../Actions/Approve';
import RejectAction from '../../Actions/Reject';
import {approve, reject, store} from '../../../../../Employee/Tabs/Leave/redux';
import {openLeaveTransactionModal} from '../../../../../Employee/redux';
import {PayrollPeriod} from '../../../../../../api/payroll.interfaces';
import {RootState} from '../../../../../../rootReducer';
import * as globalSelectors from '../../../../../selectors';

interface CellProps {
    employee: EmployeeInterfaces.Employee;
    transactions: LeaveInterfaces.Transaction[];

    openLeaveTransactionModal: (transaction: LeaveInterfaces.Transaction[]) => void;

    approve: (employeeId: number, transaction: LeaveInterfaces.Transaction) => void;
    reject: (employeeId: number, transaction: LeaveInterfaces.Transaction) => void;
    currentPayrollPeriod: null|PayrollPeriod;
}

export class Cell extends PureComponent<CellProps> {
    render() {
        return (
            <div onClick={(e: any) => e.stopPropagation()}>
                <CommonActions.MenuActions>
                    <Switch
                        current={this.props.transactions[0]?.status}
                        options={new Map([
                            [
                                LeaveInterfaces.TransactionStatus.draft,
                                <Fragment>
                                    {this.renderViewAction()}
                                    {this.props.currentPayrollPeriod?.status !== 'processing' &&
                                        this.renderApproveAction()}
                                    {this.renderRejectAction()}
                                </Fragment>,
                            ],
                            [
                                LeaveInterfaces.TransactionStatus.pending,
                                <Fragment>
                                    {this.renderViewAction()}

                                    {this.renderRejectAction()}
                                </Fragment>,
                            ],
                        ])}
                        default={
                            <Fragment>
                                {this.renderViewAction()}
                            </Fragment>
                        }
                    />
                </CommonActions.MenuActions>
            </div>
        );
    }

    private onOpen = () => {
        this.props.openLeaveTransactionModal(this.props.transactions);
    }

    private renderViewAction() {
        return (
            <ViewAction onOpen={this.onOpen}/>
        );
    }

    private renderRejectAction() {
        return (
            <RejectAction
                method={'rejectLink'}
                employee={this.props.employee}
                transaction={this.props.transactions[0]}

                onClickReject={this.props.reject}
            />
        );
    }

    private renderApproveAction() {
        return (
            <ApproveAction
                method={'approveLink'}
                employee={this.props.employee}
                transaction={this.props.transactions[0]}
                
                onClickApprove={this.props.approve}
            />
        );
    }
}

const mapDispatchToProps = {
    store,
    approve,
    reject,
    openLeaveTransactionModal,
};

const mapStateToProps = (state: RootState) => ({
    currentPayrollPeriod: globalSelectors.getCurrentPayrollPeriod(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(Cell);
