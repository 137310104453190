import {interfaces} from '@global';
import {EmployeeListingHelper} from '@library';
import {AnyAction, Dispatch} from 'redux';
import {ThunkAction} from 'redux-thunk';
import moment from 'moment';

import {
    ApiEmployee,
    EmployeeInterfaces,
    LeaveInterfaces,
} from '@api';

import {RootState} from '../../../rootReducer';
import {SET_EMPLOYEE_LEAVES} from '../../Leaves/redux';
import {setLeaves} from '../../Leaves/EmployeeLeaves/redux';

type ThunkResult<R> = ThunkAction<R, RootState, undefined, AnyAction>;

export const SET_LEAVE_TYPES = 'leaves/setLeaveTypes';
export const SET_PERIOD_FILTER = 'leaves/setPeriodFilter';
export const SET_TYPE_ID_FILTER = 'leaves/setTypeIdFilter';
export const SET_ORDERS = 'leaves/setOrders';
export const SET_FILTERS = 'leaves/setFilters';
export const SET_TAGS = 'leaves/setTags';

const SET_EMPLOYEE_GROUPS = 'leaves/setEmployeeGroups';
const SET_FIELD_GROUPS = 'leaves/setFieldGroups';

export interface LeaveTransactionsState {
    types: LeaveInterfaces.Type[];

    leaves: LeaveInterfaces.Leaves;
    employees: EmployeeInterfaces.Employee[];

    filters: interfaces.ListingFilters;
    orders: interfaces.ListingOrders;
    tags: EmployeeInterfaces.Tag[];

    filter: {
        from: string,
        till: string,
        typeId: number | null,
        status: string | null,
    };
}

export const defaultState: LeaveTransactionsState = {
    types: [],

    leaves: {},
    employees: [],

    filters: {},
    orders: {},
    tags: [],

    filter: {
        from: moment().startOf('month').format(),
        till: moment().endOf('month').format(),
        typeId: null,
        status: null,
    },
};

export default function reducer(
    state: LeaveTransactionsState = defaultState,
    action: AnyAction,
): LeaveTransactionsState {
    switch (action.type) {
        case SET_LEAVE_TYPES:
            return {
                ...state,
                types: action.payload,
            };
        case SET_PERIOD_FILTER:
            return {
                ...state,
                filter: {
                    ...state.filter,
                    ...action.payload,
                },
            };
        case SET_TYPE_ID_FILTER:
            return {
                ...state,
                filter: {
                    ...state.filter,
                    typeId: action.payload,
                },
            };
        case SET_TAGS:
            return {
                ...state,
                tags: action.payload,
            };
        case SET_ORDERS:
            return {
                ...state,
                orders: action.payload,
            };
        case SET_FILTERS:
            return {
                ...state,
                filters: action.payload,
            };
        default:
            return state;
    }
}

export function setLeaveTypes(types: LeaveInterfaces.Type[]) {
    return {
        type: SET_LEAVE_TYPES,
        payload: types,
    };
}

export function setOrders(orders: interfaces.ListingOrders) {
    return {
        type: SET_ORDERS,
        payload: orders,
    };
}

export function setFilters(filters: interfaces.ListingFilters) {
    return {
        type: SET_FILTERS,
        payload: filters,
    };
}

export function setTags(tags: EmployeeInterfaces.Tag[]) {
    return {
        type: SET_TAGS,
        payload: tags,
    };
}

export function updateLeaveTransaction(employeeId: number, transaction: LeaveInterfaces.Transaction) {
    return async(dispatch: Dispatch) => {
        dispatch(updateLeavesLeaveTransaction(employeeId, transaction) as any);
        dispatch(updateEmployeeLeaveTransaction(employeeId, transaction) as any);
    };
}

function updateLeavesLeaveTransaction(
    employeeId: number,
    transaction: LeaveInterfaces.Transaction,
) {
    return async(dispatch: Dispatch, getState: () => RootState) => {
        const state = getState();
        const entityId = state.global.currentLegalEntityId;
        const leaves: LeaveInterfaces.Leaves = {...state.leaves.leaves};
        const employees = [...state.leaves.employees];

        if (entityId && !employees.find((employee: EmployeeInterfaces.Employee) => employee.id === employeeId)) {
            const [forEmployee] = await ApiEmployee.findByIds(entityId, [employeeId]);
            employees.push(forEmployee);
        }

        if (!leaves[employeeId]) {
            leaves[employeeId] = {
                balances: {},
                transactions: [transaction],
            };
        } else {
            const oldTransactionIndex = leaves[employeeId].transactions
                .findIndex((t: LeaveInterfaces.Transaction) => t.id === transaction.id);
            if (oldTransactionIndex !== -1) {
                leaves[employeeId].transactions.splice(oldTransactionIndex, 1);
            }

            leaves[employeeId].transactions.push(transaction);
        }

        dispatch(setEmployeeLeaves(leaves, employees));

        return;
    };
}

function updateEmployeeLeaveTransaction(
    employeeId: number,
    transaction: LeaveInterfaces.Transaction,
) {
    return async(dispatch: Dispatch, getState: () => RootState) => {
        const leaves = {...getState().employeeLeaves.leaves};

        const oldTransactionIndex = leaves.transactions
            .findIndex((t: LeaveInterfaces.Transaction) => t.id === transaction.id);
        if (oldTransactionIndex !== -1) {
            leaves.transactions.splice(oldTransactionIndex, 1);
        }

        leaves.transactions.push(transaction);

        dispatch(setLeaves(leaves));

        return;
    };
}

export function resetOrdersAndFilters(): any {
    return (dispatch: Dispatch) => {
        dispatch(setOrders({}));
        dispatch(setFilters({}));
        return;
    };
}

export function changeOrder(column: string): any {
    return (dispatch: Dispatch, getState: () => RootState) => {
        const orders: interfaces.ListingOrders = {...getState().leaves.orders};
        dispatch(
            setOrders(
                EmployeeListingHelper.updatedOrders(column, orders),
            ),
        );

        return;
    };
}

export function changeFilter(params: interfaces.FilterParams) {
    return (dispatch: Dispatch, getState: () => RootState) => {
        const filters: interfaces.ListingFilters = {...getState().leaves.filters};

        dispatch(
            setFilters(
                EmployeeListingHelper.updatedFilters(params, filters),
            ),
        );

        return;
    };
}

export function getEmployeeGroups(): ThunkResult<Promise<void>> {
    return async(dispatch: Dispatch, getState: () => RootState) => {
        const currentLegalEntityId = getState().global.currentLegalEntityId;
        if (!currentLegalEntityId) {
            return;
        }

        dispatch(
            setEmployeeGroups(await ApiEmployee.getEmployeeGroups(currentLegalEntityId)),
        );

        return;
    };
}

export function getFieldGroups(): ThunkResult<Promise<void>> {
    return async(dispatch: Dispatch, getState: () => RootState) => {
        const currentLegalEntityId = getState().global.currentLegalEntityId;
        if (!currentLegalEntityId) {
            return;
        }

        dispatch(
            setFieldGroups(await ApiEmployee.getFieldGroups(currentLegalEntityId)),
        );

        return;
    };
}

export function setFieldGroups(fieldGroups: any) {
    return {
        type: SET_FIELD_GROUPS,
        payload: fieldGroups,
    };
}

export function setEmployeeGroups(data: any): AnyAction {
    return {
        type: SET_EMPLOYEE_GROUPS,
        payload: data,
    };
}

export function setEmployeeLeaves(leaves: LeaveInterfaces.Leaves, employees: EmployeeInterfaces.Employee[]): AnyAction {
    return {
        type: SET_EMPLOYEE_LEAVES,
        payload: {
            leaves,
            employees,
        },
    };
}
