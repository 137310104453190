import React, {PureComponent, Fragment} from 'react';
import styled from 'styled-components';
import {SortEnd} from 'react-sortable-hoc';

import {interfaces, stylingVariables} from '@global';
import {SortableColumns, SortableColumn} from './Column';
import {intl} from '@global';

const StyledLabel = styled.div`
  font-size: ${stylingVariables.fontSize.large};
  font-weight: ${stylingVariables.fontWeight.regular};
  color: ${stylingVariables.colorPalette.green};
  margin-bottom: 20px;
`;

interface CheckedColumnsProps {
    columns: interfaces.EmployeeColumn[];

    onSortEnd: (sort: SortEnd) => void;
    onCheckColumn: (code: string) => void;
}

export default class CheckedColumns extends PureComponent<CheckedColumnsProps> {
    render() {
        return (
            <Fragment>
                <StyledLabel>{this.props.columns.length}  {intl.get('columns_selected')}</StyledLabel>

                <SortableColumns useDragHandle onSortEnd={this.props.onSortEnd}>
                    {
                        this.props.columns.map((column, index) => (
                            <SortableColumn key={`item-${column.code}`}
                                index={index}
                                isChecked={true}
                                column={column}
                                onCheckColumn={this.props.onCheckColumn}
                            />
                        ))
                    }
                </SortableColumns>
            </Fragment>
        );
    }
}
