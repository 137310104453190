import React, {PureComponent} from 'react';
import styled from 'styled-components';
import {Icon} from '@common';
import {constants, stylingVariables} from '@global';

export const StyledCheckbox = styled.div<{isChecked: boolean, indeterminate?: boolean, isDisabled?: boolean}>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 14px;
  min-width: 14px;
  height: 14px;
  min-height: 14px;
  background: transparent;
  border: 2px solid ${props => props.isChecked ? stylingVariables.colorPalette.green : stylingVariables.colorPalette.deepGray};
  border-radius: 3px;
  cursor: pointer;

  ${props => props.indeterminate && `
    background: ${stylingVariables.colorPalette.deepGray};
    color: ${stylingVariables.colorPalette.white}
    border-color: ${stylingVariables.colorPalette.deepGray};
  `}

  ${props => props.isDisabled && `
    opacity: .7;
    pointer-events: none;
    cursor: not-allowed;
  `}
`;

interface CheckboxProps {
    isChecked: boolean;
    isDisabled?: boolean;
    indeterminate?: boolean;
    onClick?: (e: any) => void;

    className?: string;
}

export default class Checkbox extends PureComponent<CheckboxProps> {
    static defaultProps = {
        indeterminate: false,
        isDisabled: false,
    };

    render() {
        return (
            <StyledCheckbox
                isChecked={this.props.isChecked}
                isDisabled={this.props.isDisabled}
                indeterminate={this.props.indeterminate}
                onClick={this.props.onClick}

                className={this.props.className}
            >
                {this.renderMark()}
            </StyledCheckbox>
        );
    }

    renderMark() {
        if (!this.props.isChecked) {
            return null;
        }

        if (this.props.indeterminate) {
            return '-';
        }

        return (
            <Icon type={constants.IconTypes.CHECK_MARK}
                     fillColor={stylingVariables.colorPalette.green}
                     width={7}
                     height={7}
            />
        );
    }
}
