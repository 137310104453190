import {api} from '@global';

export default class ApiSso {
    public static async login(accessToken: string) {
        const {data} = await api.post('api/oauth/session-token', {
            accessToken,
        });

        return data?.data || null;
    }

    public static async getAccessToken(sessionToken: string, clientId?: string) {
        const url = clientId ? `api/oauth/access-token?clientId=${clientId}`
            : 'api/oauth/access-token';

        const {data} = await api.post(url, {
            sessionToken,
        });

        return data.data ? data.data.accessToken : null;
    }
}
