import React, {PureComponent} from 'react';
import {stylingVariables} from '@global';
import styled from 'styled-components';
import {FormatNumbers} from './index';

const RadialChartWrapper = styled.div`
  position: relative;
  display: inline-block;
  transition: all 0.3s ease-in;
`;

const RadialChartTotal = styled.circle`
  opacity: 0.3;
`;

const RadialChartProgress = styled.circle`
  transform: rotate(-90deg);
  transform-origin: center;
  transition: all 0.6s cubic-bezier(0.58, 0.16, 0.5, 1.14);
  transition-delay: 0.3s;
`;

interface RadialChartProps {
    label?: any;
    radius: number;
    strokeWidth: number;
    textColor?: string;
    progressColor: string;
    noProgressColor: string;
    progress: number;
    dimension: number;
    formatNumber?: boolean;
}

export default class RadialChart extends PureComponent<RadialChartProps> {
    static defaultProps: RadialChartProps = {
        radius: 80,
        progress: 100,
        strokeWidth: 5,
        dimension: 80,
        progressColor: stylingVariables.colorPalette.green,
        noProgressColor: stylingVariables.colorPalette.deepGray,
    };

    render() {
        const circleRadius = Math.min(this.props.radius, 85);
        const circumference = 2 * 3.14 * circleRadius;
        const strokeLength = circumference / 100 * this.props.progress ;

        return (
            <RadialChartWrapper>
                <svg viewBox='0 0 180 180' width={this.props.dimension} height={this.props.dimension}>
                    <RadialChartTotal
                        stroke={this.props.noProgressColor}
                        strokeWidth={this.props.strokeWidth}
                        fill='none'
                        cx='90'
                        cy='90'
                        r={circleRadius}
                    />
                    <RadialChartProgress
                        stroke={this.props.progressColor}
                        strokeWidth={this.props.strokeWidth + 5}
                        strokeDasharray={`${strokeLength},${circumference}`}
                        strokeLinecap='round'
                        fill='none'
                        cx='90'
                        cy='90'
                        r={circleRadius}
                    />

                    <text x='50%' y='60%' textAnchor='middle' fontSize={40} fill={this.props.textColor}>
                        {this.props.formatNumber
                            ? <FormatNumbers showZero={true} value={this.props.label as number}/>
                            : this.props.label}
                    </text>
                </svg>
            </RadialChartWrapper>
        );
    }
}
