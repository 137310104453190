import {ApiObject} from '@api';
import {constants, stylingVariables} from '@global';
import React, {PureComponent} from 'react';
import {connect} from 'react-redux';

import {Actions as CommonActions, Icon} from '@common';
import {removeDocumentModal} from '../../../../../redux';

interface IProps {
    document: ApiObject.FileHandle;
    removeDocumentModal: (id: string) => any;
}

class Delete extends PureComponent<IProps> {
    private onOpenModal = () => {
        this.props.removeDocumentModal(this.props.document.id);
    };

    public render() {
        return (
            <CommonActions.Action style={{marginRight: 15}} onClick={this.onOpenModal}>
                <Icon type={constants.IconTypes.TRASH}
                      width={15} height={15}
                      fillColor={stylingVariables.colorPalette.green}
                />
            </CommonActions.Action>
        );
    }
}

const mapDispatchToProps = {
    removeDocumentModal,
};

export default connect(null, mapDispatchToProps)(Delete);
