import {ApiObject} from '@api';
import {interfaces} from '@global';

const uniqueID = () => {
    const chr4 = () => Math.random().toString(16).slice(-4);
    return chr4() + chr4() +
        '-' + chr4() +
        '-' + chr4() +
        '-' + chr4() +
        '-' + chr4() + chr4() + chr4();
}

const defaultUpdateOrders = (orders: interfaces.ListingOrders, column: string): interfaces.ListingOrders => {
    if (orders[column]) {
        if (orders[column].direction === ApiObject.OrderByOperator.asc) {
            orders[column].direction = ApiObject.OrderByOperator.desc;
        } else {
            orders = {};
        }
    } else {
        orders = {
            [column]: {
                column,
                direction: ApiObject.OrderByOperator.asc,
            },
        };
    }

    return orders;
}

export default {
    uniqueID,
    defaultUpdateOrders,
}