import React from 'react';
import styled from 'styled-components';

const StyledRow = styled.div`
  overflow: visible!important;
`;

interface RowProps {
    selectedIds: Set<number>;
	onRowClick: (rowData: any) => void;
}

export const Row = ({selectedIds, onRowClick}: RowProps) => ({ className, columns, key, style, rowData}: any) => (
    <StyledRow
        className={selectedIds.has(rowData.id) ? `${className} highlighted` : className}
        key={key}
        role='row'
        style={style}
		onClick={() => onRowClick(rowData.id)}
    >
        {columns}
    </StyledRow>
);
