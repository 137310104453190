import {EmployeeInterfaces} from '@api';
import {intl} from '@global';

export const employeeName = (employee: EmployeeInterfaces.Employee | null, defaultName: string = 'Unknown') => {
    if (!employee) {
        return defaultName;
    }

    if (!employee.person.firstName) {
        return defaultName;
    }
    return `${employee.person.firstName} ${employee.person.lastName || ''}`;
}

export const employeeNamWithRef = (employee: EmployeeInterfaces.Employee | null) => {
    if (!employee) {
        return intl.get('unknown');
    }

    const name = employeeName(employee);
    const ref = employee.referenceCode;

    if (!ref) {
        return name;
    }

    return `${name} - ${ref}`;
}
