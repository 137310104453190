import ApiProvider from './api.provider';
import ApiEmployee, {EmployeeQueryParams} from './employee.service';
import ApiPayElement, {PayElementQueryParams} from './payElement.service';
import ApiLeave, {LeaveQueryParams} from './leave.service';
import ApiColumnSettings from './columnSettings.service';
import ApiLegalEntity from './legalEntity.service';
import ApiSso from './sso.service';
import ApiPayroll from './payroll.service';
import ApiReport, {ReportQueryParams} from './report.service';
import ApiReportTemplate, {ReportTemplateQueryParams} from './report-template.service';
import * as ApiObject from './api.object';
import * as LeaveInterfaces from './leave.interfaces';
import * as EmployeeInterfaces from './employee.interfaces';
import * as PayrollInterfaces from './payroll.interfaces';

interface Error {
    code: string|null;
    message: string;
}

const prepareErrors = (errors: Error[]): string[] => {
    return errors.map(error => error.message);
};

export {
    ApiObject,
    LeaveInterfaces,
    EmployeeInterfaces,
    PayrollInterfaces,
    ApiProvider,
    prepareErrors,

    ApiEmployee,
    ApiLeave,
    LeaveQueryParams,
    ApiColumnSettings,
    EmployeeQueryParams,
    ApiLegalEntity,
    ApiSso,
    ApiPayroll,
    ApiPayElement,
    PayElementQueryParams,
    ApiReport,
    ReportQueryParams,
    ApiReportTemplate,
    ReportTemplateQueryParams
};
