import {createSelector} from 'reselect';

import {intl} from '@global';
import {ApiObject, EmployeeInterfaces} from '@api';

import {RootState} from '../../rootReducer';
export interface MasterdataEditAllowed {
    [masterdataEditAllowed:string]: boolean;
  }
export const getSelectedIds = (state:RootState, props: any): Set<number> => state.employees.selectedIds;
export const getEmployees = createSelector(
    [(state:RootState, props: any) => state.employees],
    ({items, cutOffStatuses}) => items.map((employee: EmployeeInterfaces.Employee, index: number) => {
        employee.index = index + 1;
        employee.person = employee.person || {};
        employee.fullName = `${employee.person.firstName} ${employee.person.lastName}`;
        if (cutOffStatuses && cutOffStatuses[employee.id]){
            employee.cuttOffs = cutOffStatuses[employee.id]
          }        
        return employee;
    }),
);

export const getTotalCount = (state:RootState, props: any): number => state.employees.totalCount;
export const getCounts = (state:RootState, props: any): any => state.employees.countsByStatus;
export const getFilters = (state:RootState, props: any) => state.employees.filters;
export const getOrders = (state:RootState, props: any) => state.employees.orders;
export const getTags = (state:RootState, props: any) => state.employees.tags;

export const getChosenStatus = (state:RootState, props: any) => state.employees.chosenStatus;
export const getFieldGroups = (state: RootState): any => state.employees.fieldGroups;
export const getEmployeeGroups = (state: RootState): any => state.employees.employeeGroups;

export const getFieldGroupsForTerminationDetails = (state: RootState): any =>
    state.employees.terminationDetails.fieldGroups;

export const getValuesForTerminationDetails = (state:RootState): any => {
    const formattedFields: any = [];
    if (!state.employees.terminationDetails.values) { return []; }
    if (!state.employees.terminationDetails.values.length) { return []; }

    state.employees.terminationDetails.values.map((terminationDetailsValue: any) => {
        if (!terminationDetailsValue.values) { return []; }
        terminationDetailsValue.values.map((value: ApiObject.FieldValue) => {
            formattedFields[value.code] = value.value;
            return value;
        });
        return terminationDetailsValue;
    });

    return formattedFields;
};

export const getFieldGroupsForTerminationPayout = (state: RootState): any => {
    return state.employees.terminationPayout.fieldGroups;
}
export const getValuesForTerminationPayout = (state: RootState): EmployeeInterfaces.TerminationPayouts => state.employees.terminationPayout.values;

export const getSeparationAction = (state: RootState): any => state.employees.separation.action;
export const getSeparationErrors = (state:RootState): any => {
    if (!state.employees.separation.errors) { return []; }
    if (!state.employees.separation.errors.length) { return []; }
    const result : any = [];
    state.employees.separation.errors.map((error: any) => {
        if (!result[error.entity]){
            result[error.entity] = [];
        }
        result[error.entity][error.field] = error.message;
        return error;
    });
    return result;
};

export const prepareActivationErrors = (state: RootState, errors: EmployeeInterfaces.ActivationError[]): {
    title: string,
    messages: string[],
} => {
    const employees = getEmployees(state, null);
    const getEmployeeName = (id: number): string => {
        const employee = employees.find(e => e.id === id);
        if (!employee) {
            return '';
        }

        const person = employee.person || {};
        return `${person.firstName} ${person.lastName} (${employee.referenceCode})`;
    };

    const messages = [];
    for (const error of errors) {
        messages.push(...error.errorMessages.map(
            errorMessage => errors.length > 1
                ? `Employee ${getEmployeeName(error.employeeId)}: ${errorMessage.message}`
                : errorMessage.message,
        ));
    }

    return {
        title: errors.length === 1
            ? intl.get('activate_employee_failed', {name: getEmployeeName(errors[0].employeeId)})
            : intl.get('activate_employees_failed'),
        messages,
    };
};
