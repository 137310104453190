import {stylingVariables} from '@global';
import React, {PureComponent} from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';

const Wrapper = styled.div`
	position: relative;
`;
const Container = styled.div<{pageX: number, pageY: number, fixed: boolean, positionLeft?: boolean, reversePageX: number, reversePageY: number}>`
	position: fixed;
    background-color: #fff;
    padding: 10px;
    z-index: 10000;
    top: ${props => props.pageY + 20}px;
    left: ${props => props.pageX}px;

    ${props => props.positionLeft && `
    	left: auto;
    	right: 0;
    `};
    box-shadow: ${stylingVariables.layout.boxShadow};
    border-radius: 4px;
    
    font-size: ${stylingVariables.fontSize.mediumLarge};
    color: ${stylingVariables.colorPalette.darkest};
    
    min-width: 80px;
    display: flex;
    justify-content: center;

    ${props => props.fixed && `
    	position: absolute;
    	white-space: nowrap;
    	top: -50px;
    	left: -10px;
    	
    	&:before {
			content: '';
			position: absolute;
			bottom: -8px;
			left: 20px;
			border-left: 12px solid transparent;
			border-right: 12px solid transparent;
			border-top: 12px solid #fff;
			width: 0;
			height: 0;
    	}
    `}
    
`;

interface IProps {
	message: string;
	fixed: boolean;
	isDisabled?: boolean;
	positionLeft?: boolean;
}

interface IState {
	mouseOver: boolean;
	pageX: number;
	pageY: number;
	reversePageX: number;
	reversePageY: number;
}

export class Popover extends PureComponent<IProps, IState> {
	static defaultProps = {
		fixed: false,
		isDisabled: false
	}

	constructor(props: IProps) {
		super(props);

		this.state = {
			mouseOver: false,
			pageX: 0,
			pageY: 0,
			reversePageX: 0,
			reversePageY: 0
		};
	}

	private handleMouseOver = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		const screenW = window.screen.width;
		const screenH = window.screen.height;

		this.setState({
			mouseOver: true,
			pageX: event.pageX,
			pageY: event.pageY,
			reversePageX: screenW - event.pageX,
			reversePageY: screenH - event.pageY,
		});
	};

	private handleMouseLeave = () => {
		this.setState({
			mouseOver: false,
			pageX: 0,
			pageY: 0,
			reversePageX: 0,
			reversePageY: 0
		})
	};

	public render() {
		const renderedContainer = <Container
			pageX={this.state.pageX}
			pageY={this.state.pageY}
			reversePageX={this.state.reversePageX}
			reversePageY={this.state.reversePageY}
			fixed={this.props.fixed}
			positionLeft={this.props.positionLeft}
		>
			{this.props.message}
		</Container>;
		return (
			<Wrapper
				onMouseMove={this.handleMouseOver}
				onMouseUp={this.handleMouseLeave}
				onMouseLeave={this.handleMouseLeave}
			>
				{this.props.children}

				{!this.props.isDisabled && this.state.mouseOver &&
					(this.props.fixed
						? renderedContainer
						: ReactDOM.createPortal(renderedContainer, document.body)
					)
				}
			</Wrapper>
		);
	}
}
export default Popover;
