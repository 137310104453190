import React, {PureComponent, Fragment} from 'react';
import styled from 'styled-components';

import {ApiObject} from '@api';
import {SimpleStatusBar, SearchInput} from '@common';
import {intl} from '@global';
import {AllowedStatus} from './groupedQueries.builder';

const StyledStatusBar = styled.div`
	position: relative;
	> div {
		display: flex;
		justify-content: space-between;
		overflow: visible;
	} 
   	button {
   		text-transform: capitalize;
   	}
`;
const StatusBarOptions = styled.div`
	position: absolute;
	right: 10px;
	top: 10px;
	
	display: flex;
	align-items: center;
	
	>div:not(:last-child) {
		margin-right: 20px;
	}
`;

interface IProps {
    selectedEmployeeStatus: AllowedStatus;
    search: string;

    onSearch: (search: string) => void;
    onSelectStatus: (status: AllowedStatus) => void;

    statisticByStatuses: any;
    isDraftAllowed?: boolean
}

export class Filters extends PureComponent<IProps> {
    static defaultProps = {
        selectedEmployeeStatus: ApiObject.Status.active,
        search: '',
        isDraftAllowed: true,
    }

    get statusBarItems() {
        const statuses = [ApiObject.Status.active, ApiObject.Status.inactive]

        if (this.props.isDraftAllowed) {
            statuses.push(ApiObject.Status.draft)
        }

        return statuses.map(status => (
            {
                key: status,
                label: intl.get( status),
                count: this.props.statisticByStatuses[status]
            }
        ));
    }

    public render() {
        return (
            <Fragment>
                <StyledStatusBar>
                    <SimpleStatusBar
                        items={this.statusBarItems}
                        status={this.props.selectedEmployeeStatus as string}
                        onChange={(status: string) => this.props.onSelectStatus(status as AllowedStatus)}
                    />

                    <StatusBarOptions>
                        <SearchInput
                            width={200}
                            height={40}
                            theme={'dark'}
                            onChange={(value: any) => this.props.onSearch(value || '')}
                            placeholder={intl.get('search')}
                            defaultValue={this.props.search}
                            autoFocus={true}
                        />
                    </StatusBarOptions>
                </StyledStatusBar>
            </Fragment>
        );
    }
}

export default Filters;
