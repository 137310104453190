import {ApiObject, ApiLegalEntity, PayElementQueryParams, ApiPayElement, ApiEmployee} from '@api';
import {interfaces} from '@global';
import {combineReducers, AnyAction, Dispatch} from "redux";
import {ThunkAction} from 'redux-thunk';
import {RootState} from '../../rootReducer';

import reports, {ReportsState, defaultState as defaultReportsState} from './Reports/redux';
import process, {ProcessState, defaultState as defaultProcessState} from './Process/redux';
import reportTemplates, {ReportTemplatesState, defaultState as defaultReportTemplatesState} from './ReportTemplates/redux';
import {getMasterdataListOptionsAndQuickAdds, getPayElementsListOptionsAndQuickAdds} from './utils';

import * as selectors from './selectors';

type ThunkResult<R> = ThunkAction<R, RootState, undefined, AnyAction>;

export const TOGGLE_REPORTS_MODAL = 'reports/basic/toggleReportsModal';
export const SET_LEGAL_ENTITY_PERIODS = 'reports/basic/setLegalEntityPeriods';
export const SET_PAY_ELEMENTS = 'reports/basic/setPayElements';
export const SET_FIELD_GROUPS = 'reports/basic/setFieldGroups';
export const SET_MASTERDATA_LIST_FIELDS = 'reports/basic/setMasterdataListFields';
export const SET_PAY_ELEMENTS_LIST_FIELDS = 'reports/basic/setPayElementsListFields';
export const SET_MASTERDATA_QUICK_ADDS = 'reports/basic/setMasterdataQuickAdds';
export const SET_PAY_ELEMENTS_QUICK_ADDS = 'reports/basic/setPayElementsQuickAdds';
export const SET_GENERATING_REPORT_IDS = 'reports/basic/setGeneratingReportIds';

export interface BasicState {
	chooseReportModal: boolean;
	legalEntityPeriods: ApiObject.LegalEntityPeriod[];
	payElements: ApiObject.PayElement[];
	fieldGroups: ApiObject.FieldGroup[];

	masterDataListFields: ApiObject.SelectOption[];
	payElementsListFields: ApiObject.SelectOption[];
	masterDataQuickAdds: interfaces.IGroupFieldQuickAdd[];
	payElementsQuickAdds: interfaces.IGroupFieldQuickAdd[];

	generatingReportIds: number[];
}

export const defaultBasicState: BasicState = {
	chooseReportModal: false,
	legalEntityPeriods: [],
	payElements: [],
	fieldGroups: [],
	masterDataListFields: [],
	payElementsListFields: [],
	masterDataQuickAdds: [],
	payElementsQuickAdds: [],
	generatingReportIds: []
};

function basic(
	state = defaultBasicState,
	action: AnyAction,
): BasicState {
	switch (action.type) {
		case TOGGLE_REPORTS_MODAL:
			return {
				...state,
				chooseReportModal: !state.chooseReportModal,
			};
		case SET_LEGAL_ENTITY_PERIODS:
			return {
				...state,
				legalEntityPeriods: action.payload,
			};
		case SET_PAY_ELEMENTS:
			return {
				...state,
				payElements: action.payload,
			};
		case SET_FIELD_GROUPS:
			return {
				...state,
				fieldGroups: action.payload,
			};
		case SET_MASTERDATA_LIST_FIELDS:
			return {
				...state,
				masterDataListFields: action.payload,
			};
		case SET_PAY_ELEMENTS_LIST_FIELDS:
			return {
				...state,
				payElementsListFields: action.payload,
			};
		case SET_MASTERDATA_QUICK_ADDS:
			return {
				...state,
				masterDataQuickAdds: action.payload,
			};
		case SET_PAY_ELEMENTS_QUICK_ADDS:
			return {
				...state,
				payElementsQuickAdds: action.payload,
			};

		case SET_GENERATING_REPORT_IDS:
			return {
				...state,
				generatingReportIds: action.payload
			}

		default:
			return state;
	}
}

export function initializeBasicData(): ThunkResult<Promise<void>> {
	return async(dispatch: Dispatch) => {
		dispatch(getLegalEntityPeriods() as any);
		dispatch(getFieldGroups() as any);
		dispatch(getPayElements() as any);

		return;
	};
}

export function getLegalEntityPeriods(): ThunkResult<Promise<void>> {
	return async(dispatch: Dispatch, getState: () => RootState) => {
		const currentLegalEntityId = getState().global.currentLegalEntityId;
		if (!currentLegalEntityId) {
			return;
		}

		const periods = await ApiLegalEntity.getPeriods(currentLegalEntityId);

		dispatch(setLegalEntityPeriods(periods));

		return;
	};
}

export function getFieldGroups(): ThunkResult<Promise<void>> {
	return async(dispatch: Dispatch, getState: () => RootState) => {
		const currentLegalEntityId = getState().global.currentLegalEntityId;
		if (!currentLegalEntityId) {
			return;
		}

		const fieldGroups = await ApiEmployee.getFieldGroups(currentLegalEntityId as number);
		dispatch(setFieldGroups(fieldGroups));

		const [listFields, quickAdds] = getMasterdataListOptionsAndQuickAdds(fieldGroups);
		dispatch(setMasterdataListFields(listFields) as any);
		dispatch(setMasterdataQuickAdds(quickAdds) as any);

		return;
	};
}

export function getPayElements(): ThunkResult<Promise<void>> {
	return async(dispatch: Dispatch, getState: () => RootState) => {
		const entityId = getState().global.currentLegalEntityId;
		if (!entityId) {
			return;
		}

		let payElements: ApiObject.PayElement[] = [];

		while (true) {
				const params = new PayElementQueryParams({offset: payElements.length})
				const payElementsResult: ApiObject.PayElement[] = await ApiPayElement.list(entityId as number, params);
				payElements = payElements.concat(payElementsResult);
				dispatch(setPayElements(payElements));

				if (payElementsResult.length !== PayElementQueryParams.DEFAULT_LIMIT ) {
						break;
				}
		}

		const [listFields, quickAdds] = getPayElementsListOptionsAndQuickAdds(payElements);
		dispatch(setPayElementsListFields(listFields) as any);
		dispatch(setPayElementsQuickAdds(quickAdds) as any);

		return;
	};
}

export function setLegalEntityPeriods(periods: ApiObject.LegalEntityPeriod[] = []): AnyAction {
	return {
		type: SET_LEGAL_ENTITY_PERIODS,
		payload: periods
	}
}
export function setFieldGroups(fieldGroups: ApiObject.FieldGroup[] = []): AnyAction {
	return {
		type: SET_FIELD_GROUPS,
		payload: fieldGroups
	}
}
export function setPayElements(payElements: ApiObject.PayElement[] = []): AnyAction {
	return {
		type: SET_PAY_ELEMENTS,
		payload: payElements
	}
}
export function setMasterdataListFields(listFields: ApiObject.SelectOption[] = []): AnyAction {
	return {
		type: SET_MASTERDATA_LIST_FIELDS,
		payload: listFields
	}
}
export function setMasterdataQuickAdds(quickAdds: interfaces.IGroupFieldQuickAdd[] = []): AnyAction {
	return {
		type: SET_MASTERDATA_QUICK_ADDS,
		payload: quickAdds
	}
}
export function setPayElementsListFields(listFields: ApiObject.SelectOption[] = []): AnyAction {
	return {
		type: SET_PAY_ELEMENTS_LIST_FIELDS,
		payload: listFields
	}
}
export function setPayElementsQuickAdds(quickAdds: interfaces.IGroupFieldQuickAdd[] = []): AnyAction {
	return {
		type: SET_PAY_ELEMENTS_QUICK_ADDS,
		payload: quickAdds
	}
}
export function toggleReportsModal(): AnyAction {
	return {
		type: TOGGLE_REPORTS_MODAL
	}
}

export function setGeneratingReportIds(ids: number[] = []): AnyAction {
	return {
		type: SET_GENERATING_REPORT_IDS,
		payload: ids
	}
}

export function addGeneratingReportId(id: number): ThunkResult<Promise<void>> {
	return async(dispatch: Dispatch, getState: () => RootState) => {
		dispatch(setGeneratingReportIds([...selectors.getGeneratingReportIds(getState()), id]))
	};
}

export function removeGeneratingReportId(id: number): ThunkResult<Promise<void>> {
	return async(dispatch: Dispatch, getState: () => RootState) => {
		dispatch(setGeneratingReportIds([...selectors.getGeneratingReportIds(getState())].filter(x => x !== id)));
	};
}

export interface RootReportsState {
	basic: BasicState;
	reports: ReportsState;
	reportTemplates: ReportTemplatesState;
	process: ProcessState;
}

export const defaultState: RootReportsState = {
	basic: defaultBasicState,
	reports: defaultReportsState,
	process: defaultProcessState,
	reportTemplates: defaultReportTemplatesState,
};

const reducer = combineReducers({
	basic,
	reports,
	reportTemplates,
	process
});

export default reducer;
