import React, {PureComponent} from 'react';

import {Collapse} from '@common';
import styled from "styled-components";
import {stylingVariables, intl} from "@global";

const NoAttachment = styled.div<{req?: boolean}>`
  color: ${stylingVariables.colorPalette.deepGray};
  font-style: italic;
  font-size: ${stylingVariables.fontSize.mediumLarge};
  margin-left: 65px;
`;


interface IProps {
    isOpen?: boolean,
}

export default class Attachment extends PureComponent<IProps> {
    public render() {
        return (
            <Collapse label={intl.get('attachment')} isOpen={this.props.isOpen}>
                <NoAttachment>{intl.get('no_attachment')}</NoAttachment>
            </Collapse>
        );
    }
}
