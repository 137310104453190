import React from 'react';
import styled from 'styled-components';

const StyledRow = styled.div``;

export const Row = (action?: (value: string) => void) => ({ className, columns, key, style, rowData }: any) => (
    <StyledRow
        className={className}
        key={key}
        role='row'
        style={{ ...style, height: style.height - 2 }} //Gap between rows
        onClick={() => action && action(rowData.entityId)}
    >
        {columns}
    </StyledRow>
);
