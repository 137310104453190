import React, {PureComponent, Fragment} from 'react';
import styled from 'styled-components';

import {interfaces, stylingVariables} from '@global';

import {Columns, Column} from './Column';
import {intl} from '@global';

interface UncheckedColumnsProps {
    columns: interfaces.EmployeeColumn[];

    onCheckColumn: (code: string) => void;
}

const StyledLabel = styled.div`
  font-size: ${stylingVariables.fontSize.large};
  font-weight: ${stylingVariables.fontWeight.regular};
  color: ${stylingVariables.colorPalette.deepGray};
  margin: 20px 0;
`;

export default class UncheckedColumns extends PureComponent<UncheckedColumnsProps> {
    render() {
        return (
            <Fragment>
                <StyledLabel>{intl.get('unselected_columns')}</StyledLabel>

                <Columns>
                {
                    this.props.columns.map(column => (
                        <Column
                            key={column.code}
                            column={column}
                            isChecked={false}
                            onClickCheckbox={this.props.onCheckColumn}
                        />
                    ))
                }
                </Columns>
            </Fragment>
        );
    }
}
