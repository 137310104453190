import React, {PureComponent} from "react";
import styled from 'styled-components';
import {stylingVariables} from '@global';
import {Icon} from "@common";
import {constants} from "@global";

interface MessageProps {
    type: string,
    message: any,
    onRemove?: () => any
}

export const StyledWrapper = styled.div`
    margin: 15px 0; 
    display: flex;
    align-items: flex-start;
`;

const StyledTypeIcon = styled.div`
    height: 18px;
    width: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    opacity: .7;
`;

const StyledMessage = styled.div`
    padding-top: 2px;
    flex: 1;
    font-size: ${stylingVariables.fontSize.medium};
`;

const StyledCloseIcon = styled.div`
    height: 12px;
    width: 12px;
    margin-top: -6px;
    background-color: ${stylingVariables.colorPalette.darkGray};
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    cursor: pointer;
`;

export default class Message extends PureComponent<MessageProps> {
    private getIcon = () => {
        switch (this.props.type) {
            case 'warning':
                return <Icon type={constants.IconTypes.WARNING} width={18} height={18} fillColor={stylingVariables.colorPalette.orange}/>;
            case 'info':
                return <Icon type={constants.IconTypes.INFO_CIRCLE} width={18} height={18} fillColor={stylingVariables.colorPalette.violet}/>;
            default:
                return null;
        }
    };

    public render() {
        return (
            <StyledWrapper>
                <StyledTypeIcon>
                    {this.getIcon()}
                </StyledTypeIcon>
                <StyledMessage>
                    {this.props.message}
                </StyledMessage>
                {this.props.onRemove && (
                    <StyledCloseIcon onClick={this.props.onRemove}>
                        <Icon type={constants.IconTypes.CLOSE} width={5} height={5} fillColor={stylingVariables.colorPalette.white}/>
                    </StyledCloseIcon>
                )}
            </StyledWrapper>
        );
    }
}
