import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';
import {constants, stylingVariables, intl} from '@global';
import {Icon} from '@common';
import {ApiObject} from '@api';
import {Footer, StyledResourcesWrapperFooter as StyledResourcesWrapper} from 'hrb-common';

import Navigation from './Navigation';
import CurrentEntity, {StyledLegalName} from './CurrentEntity';
import {StyledEntitiesWrapper} from './LegalEntitySelector';
import {StyledRouterWrapper} from '../../App';
import {RootState} from '../../../rootReducer';
import * as selectors from '../../selectors';
import {setIsMenuCollapsed} from '../../redux';

const StyledMenuWrapper = styled.div<{isCollapsed: boolean}>`
    display: flex;
    flex-direction: column;
    position: fixed;
    left: 0;
    top: 0;
    padding-top: ${stylingVariables.layout.height};
    width: ${props => props.isCollapsed ? stylingVariables.layout.menuCollapsedWidth : stylingVariables.layout.menuWidth};
    height: 100vh;
    background-color: ${stylingVariables.colorPalette.white};
    border-right: 1px solid ${stylingVariables.colorPalette.darkGray};
    z-index: 10;

    ${props => props.isCollapsed && `
        li > span{
            display: none;
        }
        ${StyledLegalName} {
            display: none;
        }
        ${StyledEntitiesWrapper} {
            @media (max-height: 800px) {
                top: 80px;
            }
        }
        ${StyledResourcesWrapper} {
            flex-direction: column;
            text-align: center;
            a {
                padding: 3px 0;
            }
        }

        +${StyledRouterWrapper}{
           margin-left: ${stylingVariables.layout.menuCollapsedWidth}!important;
        }
    `}
`;

const StyledToggleWrapper = styled.div`
    padding: 6px;
    background: ${stylingVariables.colorPalette.gray};
    border-radius: 50%;
    position: absolute;
    z-index: 2;
    top: 80px;
    right: -17px;
`

const StyledCollapseButton = styled.button`
    transition: all 0.5s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    height: 24px;
    width: 24px;
    background: ${stylingVariables.colorPalette.white};
    border-radius: 50%;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.161);

    :hover {
        background: ${stylingVariables.colorPalette.lightGreen};
    }
`;

interface IProps {
    currentLegalEntity: ApiObject.LegalEntity|null;
    isCollapsed: boolean;

    setIsMenuCollapsed: (isMenuCollapsed: boolean) => void;
}

export class Menu extends PureComponent<IProps> {
    render() {
        if (!this.props.currentLegalEntity) {
            return null;
        }

        const footerMessages = {
            termOfUse: intl.get('term_of_use'),
            sla: intl.get('sla'),
            privacyPolicy: intl.get('privacy_policy')
          }

        return (
            <StyledMenuWrapper isCollapsed={this.props.isCollapsed}>
                <CurrentEntity currentLegalEntity={this.props.currentLegalEntity}/>

                <Navigation currentLegalEntity={this.props.currentLegalEntity}/>

                <Footer messages={footerMessages}/>
                
                <StyledToggleWrapper onClick={this.toggle}>
                    <StyledCollapseButton>
                        <Icon
                            type={
                                this.props.isCollapsed ? constants.IconTypes.ARROW_RIGHT : constants.IconTypes.ARROW_LEFT
                            }
                            fillColor={stylingVariables.colorPalette.dark}
                            height={7}
                            width={9}
                        />
                    </StyledCollapseButton>
                </StyledToggleWrapper>
            </StyledMenuWrapper>
        );
    }

    public toggle = () => {
        this.props.setIsMenuCollapsed(!this.props.isCollapsed);
    }
}

const mapStateToProps = (state: RootState) => ({
    isCollapsed: selectors.isMenuCollapsed(state),
});

const mapDispatchToProps = {
    setIsMenuCollapsed,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Menu);
