import React, { PureComponent } from 'react'
import { intl } from '@global'
import styled from 'styled-components'
import { connect } from 'react-redux'

import { EmployeeInterfaces, LeaveInterfaces } from '@api'
import { BlankButton, Collapse, Can, Layout } from '@common'
import { stylingVariables, constants } from '@global'

import LeavePeriodRow from './LeavePeriodRow'
import Balance from './Balance'
import { RootState } from '../../../../../../rootReducer'
import * as selectors from '../../../../../Employee/Tabs/Leave/selectors'
import * as leaveSelectors from '../../../selectors'
import TransactionsGroup from '../../TransactionsGroup'

const EditButton = styled((props) => <BlankButton {...props} />)`
  color: ${stylingVariables.colorPalette.primary};
  font-weight: ${stylingVariables.fontWeight.bold};
  font-size: ${stylingVariables.fontSize.mediumLarge};
  padding: 5px 15px;
  border-radius: 13px;
  &:hover {
    background-color: ${stylingVariables.colorPalette.primaryHover};
  }
`

const TransactionsWrapper = styled.div`
  margin: 10px 0 45px 77px;
`

interface IProps {
  leave: LeaveInterfaces.Transaction | LeaveInterfaces.Transaction[]
  onClickEdit: () => void
  isOpen?: boolean
  types: LeaveInterfaces.Type[]
  balances: LeaveInterfaces.PeriodEmployeeBalance[]
  employee: EmployeeInterfaces.Employee | null
  onOpen?: (e: any, transaction: LeaveInterfaces.Transaction) => void
}

class LeaveDetails extends PureComponent<IProps> {
  public render() {
    const { balances, leave, employee, types } = this.props
    const isGroupView = Array.isArray(leave) && leave.length > 1
    const transaction = Array.isArray(leave) ? leave[0] : leave
    const transactions = Array.isArray(leave) ? leave : []
    let transactionAmount: number | undefined

    if (isGroupView) {
      transactions.forEach((tran) => {
        if (tran.amount) {
          transactionAmount = transactionAmount || 0
          transactionAmount += tran.amount
        }
      })
    } else {
      transactionAmount = transaction.amount
    }

    const employeeId = employee?.id ?? -1
    const period = balances.find((period) => period.periodId === transaction.period?.periodId)
    const leaveBalance = period?.employeeBalances[employeeId]?.balances[transaction.leaveTypeId]
    const leaveType = types.find((leaveType) => leaveType.id === transaction.leaveTypeId)
    const essTransaction = transaction.source === 'ess'

    return (
      <Collapse
        label={intl.get('leave_details')}
        isOpen={this.props.isOpen}
        inHeader={
          (LeaveInterfaces.TransactionStatus.draft === transaction.status ||
            (!transaction.originalTransaction && !transaction.endDate) ||
            (LeaveInterfaces.TransactionStatus.done !== transaction.status && transaction.originalTransaction)) &&
          !isGroupView && !essTransaction && (
            <Can run={constants.PermissionCode.LEAVES_EDIT_LEAVE}>
              <EditButton onClick={this.props.onClickEdit}>{intl.get('edit')}</EditButton>
            </Can>
          )
        }
      >
        {isGroupView && (
          <TransactionsWrapper>
            <Layout.Row>
                <Layout.RowLabel percent={15} top={20}>
                    {intl.get('transactions')}
                </Layout.RowLabel>

                <TransactionsGroup
                    transactions={transactions}
                    showBubble={true}
                    onOpen={this.props.onOpen}
                />
            </Layout.Row>
          </TransactionsWrapper>
        )}
        {!isGroupView && <LeavePeriodRow leave={transaction} amountType={leaveType?.amountType} />}
        {leaveType?.hasBalance && leaveBalance && (
          <Balance balance={leaveBalance} leaveDays={transactionAmount} type={leaveType} />
        )}
      </Collapse>
    )
  }
}

const mapStateToProps = (state: RootState, ownProps: any) => ({
  types: leaveSelectors.getLeaveTypes(state, ownProps),
  balances: selectors.getLeavePeriodBalances(state),
})

export default connect(mapStateToProps)(LeaveDetails)
