import {ApiObject} from '@api';
import React, {PureComponent} from 'react';
import styled from 'styled-components';
import { stylingVariables } from '@global';
import 'react-datepicker/dist/react-datepicker.css';
import Dropdown from './Dropdown';
import DateField from './DateField';
import {FloatInput} from './Input';

const StyledFieldRow = styled.div`
    margin: 8px 0;
    font-size: ${stylingVariables.fontSize.default};
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    label{
        color: ${stylingVariables.colorPalette.deepGray};
        line-height: 25px;
        display: inline-block;
        margin: 5px 10px 5px 0;
        flex-basis: 200px;
    }
    `;

export const StyledInputWrapper = styled.div<{ hasError?: boolean, disabled?: boolean }>`
    >input {
        padding: 12px 20px;
        border: 1px solid ${stylingVariables.colorPalette.darkestGray};
        border-radius: ${stylingVariables.layout.inputBorderRadius};
        font-size: ${stylingVariables.fontSize.default};
        font-weight: ${stylingVariables.fontWeight.semibold};
        color: ${stylingVariables.colorPalette.dark};
        width: 100%;

        &:hover {
            border: 1px solid ${stylingVariables.colorPalette.green};
        }

        &:focus {
            box-shadow: 1px 1px 5px 3px #C2EBEB;
            border: 1px solid ${stylingVariables.colorPalette.green};
        }

    }
    ${props => props.hasError && `
        >input {
            border: 1px solid ${stylingVariables.colorPalette.red};
        }
    `}

    ${props => props.disabled && `
        >input {
            cursor: cross;
            background-color: ${stylingVariables.colorPalette.gray};
        }
    `}

    display: inline-block;
    flex-basis: 200px;
    flex-grow: 2;
`;

const StyledFieldError = styled.div`
    margin-left: 200px;
    padding: 5px 0;
    color: ${stylingVariables.colorPalette.red};
`;

const StyledAfterText = styled.span`
   padding-left: 10px;
   font-weight: ${stylingVariables.fontWeight.semibold};
   font-size: ${stylingVariables.fontSize.default};
`;

interface EditableFieldProps {
    label: string;
    placeholder?: string;
    errorMessage?: string | null;
    type: string;
    code: string;
    isRequired?: boolean;
    defaultValue?: string;
    options?: ApiObject.SelectOption[];
    afterText?: string;
    disabled: boolean;

    onChange: (value: any) => void;
}

export default class EditableField extends PureComponent<EditableFieldProps> {
    static defaultProps = {
        disabled: false,
    };

    public render() {
        let input;
        let type = this.props.type;

        // If there are options - set type like a drop-down list
        if (this.props.options && this.props.options.length > 0) {
            type = ApiObject.FieldType.dropdown;
        }

        if (this.props.code in ApiObject.FloatTypeFields) {
            type = ApiObject.FieldType.float;
        }

        switch (type) {
            case ApiObject.FieldType.dropdown:
                input = <Dropdown list={this.props.options}
                                  selectedValue={this.props.defaultValue}
                                  onChange={this.props.onChange}
                                  hasError={!!this.props.errorMessage}
                                  disabled={this.props.disabled}/>;
                break;
            case ApiObject.FieldType.date:
                input = <DateField value={this.props.defaultValue}
                                   onChange={this.props.onChange}
                                   hasError={!!this.props.errorMessage}
                                   defaultValue={this.props.placeholder}
                                   disabled={this.props.disabled}
                />;
                break;
            case ApiObject.FieldType.number:
            case ApiObject.FieldType.float:
            case ApiObject.FieldType.decimal:
                input = <FloatInput defaultValue={this.props.defaultValue}
                                    onChange={this.props.onChange}
                                    disabled={this.props.disabled}/>;
                break;
            case ApiObject.FieldType.integer:
                input = <input type={'number'}
                               step={'1'}
                               defaultValue={this.props.defaultValue}
                               placeholder={this.props.placeholder}
                               onChange={this.onInputChange}
                               disabled={this.props.disabled}/>;
                break;
            default:
                input = <input type={this.props.type}
                               defaultValue={this.props.defaultValue}
                               placeholder={this.props.placeholder}
                               onChange={this.onInputChange}
                               disabled={this.props.disabled}/>;
        }

        return (
            <StyledFieldRow>
                <label>
                    {this.props.label} {this.props.isRequired && '*'}
                </label>

                <StyledInputWrapper hasError={!!this.props.errorMessage} disabled={this.props.disabled}>
                    {input}
                </StyledInputWrapper>

                {
                    this.props.afterText &&
                    <StyledAfterText>{this.props.afterText}</StyledAfterText>
                }

                {
                    this.props.errorMessage &&
                    <StyledFieldError>{this.props.errorMessage}</StyledFieldError>
                }
            </StyledFieldRow>
        );

    }

    private onInputChange = (event: any) => {
        this.props.onChange(event.target.value);
    }
}
