import {ApiObject} from '@api';
import React from "react";
import {Column} from "react-virtualized";
import {stylingVariables, intl} from "@global";
import {defaultInfiniteTableCellDataGetter, Label, InfiniteTableHeader} from "@common";

const Visibility = ({label, width = 150}: {label: string, width?: number}) => (
    <Column
        dataKey="visibility"
        width={width}
        minWidth={width}
        flexGrow={1}
        cellDataGetter={defaultInfiniteTableCellDataGetter}
        headerRenderer={() => <InfiniteTableHeader.OrderedAndFiltered label={label}/>}
        cellRenderer={({cellData}) => {
            if (cellData.visibility === ApiObject.ReportVisibility.public) {
                return <Label.Tag color={stylingVariables.colorPalette.mediumGreen}>{intl.get('common')}</Label.Tag>
            }

            return <Label.Tag color={stylingVariables.colorPalette.lightOrange}>{intl.get('personal')}</Label.Tag>
        }}
    />
);

export default Visibility;
