import * as ApiObject from '../../../../../api/api.object';

export type AllowedStatus = ApiObject.Status.active | ApiObject.Status.inactive | ApiObject.Status.draft;

interface ITotals {
    [ApiObject.Status.active]: number;
    [ApiObject.Status.inactive]: number;
    [ApiObject.Status.draft]: number
}

export class GroupedQueriesBuilder {
    private status: AllowedStatus = ApiObject.Status.active;

    private active: any;
    private inactive: any;
    private draft: any;
    private totals: ITotals = {
        [ApiObject.Status.active]: 0,
        [ApiObject.Status.inactive]: 0,
        [ApiObject.Status.draft]: 0
    };

    public static build(queries: ApiObject.GrouperQueries, status: AllowedStatus = ApiObject.Status.active, totals?: ITotals) {
        const self = new GroupedQueriesBuilder();
        self.status = status;

        if (totals) {
            self.totals = totals;
        }

        self.active = SubQueries.build(ApiObject.Status.active, totals ? totals[ApiObject.Status.active] : 0);
        self.inactive = SubQueries.build(ApiObject.Status.inactive, totals ? totals[ApiObject.Status.inactive]: 0);
        self.draft = SubQueries.build(ApiObject.Status.draft, totals ? totals[ApiObject.Status.draft] : 0);

        queries.forEach(orQueries => {
            let statusQuery: ApiObject.EntityFieldQuery|null = null;
            let idsQuery: ApiObject.EntityFieldQuery|null = null;
            let ninIdsQuery: ApiObject.EntityFieldQuery|null = null;

            orQueries.forEach((andQuery: ApiObject.EntityFieldQuery) => {
                if (andQuery.field === 'status') {
                    statusQuery = andQuery;
                }
                if (andQuery.field === 'id' && andQuery.operator === ApiObject.QueryOperator.in) {
                    idsQuery = andQuery
                }
                if (andQuery.field === 'id' && andQuery.operator === ApiObject.QueryOperator.nin) {
                    ninIdsQuery = andQuery;
                }
            })

            if (statusQuery) {
                const status: AllowedStatus = (statusQuery as ApiObject.EntityFieldQuery).value as AllowedStatus;
                self[status].toggleStatusQuery(true);
                self[status].initIdsQuery(idsQuery);
                self[status].initNinIdsQuery(ninIdsQuery);
            }
        })

        return self;
    }

    setStatus(status: AllowedStatus) {
        this.status = status;
        return this;
    }

    isSelectedAll(status: AllowedStatus|null = null) {
        return this[status || this.status].isAllSelected();
    }

    selectAll(value: boolean = true, status: AllowedStatus|null = null) {
        this[status || this.status].toggleStatusQuery(value);
        return this;
    }

    getIds() {
        return this[this.status].getIds();
    }

    getNotIds() {
        return this[this.status].getNotIds();
    }

    setIds(ids: number[]) {
        this[this.status].setIds(ids);
        return this;
    }

    setNotIds(ids: number[]) {
        this[this.status].setNotIds(ids);
        return this;
    }

    addId(id: number) {
        this[this.status].addId(id);
        return this;
    }

    removeId(id: number) {
        this[this.status].removeId(id);
        return this;
    }

    addNotId(id: number) {
        this[this.status].addNotId(id);
        return this;
    }

    removeNotId(id: number) {
        this[this.status].removeNotId(id);
        return this;
    }

    queries(): ApiObject.GrouperQueries {
        let queries: ApiObject.GrouperQueries = [];

        if (!this.active.isEmpty()) {
            queries.push(this.active.queries());
        }
        if (!this.inactive.isEmpty()) {
            queries.push(this.inactive.queries());
        }
        if (!this.draft.isEmpty()) {
            queries.push(this.draft.queries());
        }

        return queries;
    }
}

class SubQueries {
    private status: ApiObject.Status = ApiObject.Status.active;
    private total: number = 0;

    private statusQuery: boolean = false;
    private ids: number[] = [];
    private notIds: number[] = [];

    public static build(status: ApiObject.Status, total: number) {
        const self = new SubQueries();
        self.status = status;
        self.total = total;
        return self;
    }

    isEmpty() {
        return !this.statusQuery && this.ids.length === 0 && this.notIds.length === 0;
    }

    isAllSelected(): boolean {
        return this.statusQuery;
    }

    toggleStatusQuery(value: boolean = false) {
        this.statusQuery = value;

        if (!value) {
            this.ids = [];
            this.notIds = [];
        }

        return this;
    }

    initIdsQuery(query: ApiObject.EntityFieldQuery|null) {
        if (!query) {
            return;
        }
        this.ids = query.value.split(',').map((x: string) => +x);
    }

    initNinIdsQuery(query: ApiObject.EntityFieldQuery|null) {
        if (!query) {
            return;
        }
        this.notIds = query.value.split(',').map((x: string) => +x);
    }

    getIds() {
        return this.ids;
    }

    getNotIds() {
        return this.notIds;
    }

    setIds(ids: number[] = []) {
        this.ids = ids;

        this.autoCheckIsAll(true);

        return this;
    }

    addId(id: number) {
        if (!this.ids.includes(id)) {
            this.ids.push(id);
        }

        this.autoCheckIsAll(true);

        return this;
    }

    removeId(id: number) {
        if (this.ids.includes(id)) {
            this.ids.splice(this.ids.findIndex(x => x === id), 1);
        }

        this.autoCheckIsAll(true);

        return this;
    }

    addNotId(id: number) {
        if (!this.notIds.includes(id)) {
            this.notIds.push(id);
        }

        this.autoCheckIsAll();

        return this;
    }

    removeNotId(id: number) {
        if (this.notIds.includes(id)) {
            this.notIds.splice(this.notIds.findIndex(x => x === id), 1);
        }

        this.autoCheckIsAll();

        return this;
    }

    setNotIds(ids: number[] = []) {
        this.notIds = ids;

        this.autoCheckIsAll();

        return this;
    }

    autoCheckIsAll(disableOnEmptyIds: boolean = false) {
        // has ids/not ids -> auto enable status query
        if (this.ids.length > 0 || this.notIds.length > 0) {
            this.toggleStatusQuery(true);
        }

        // when ids/not ids is empty, then remove status query.

        // But have specific case, by flag disableOnEmptyIds.
        // For example:
        // - when we removed last ID from ids array, then we need to remove this,
        // - when we remove last ID from notIds list, then it should to be
        if (disableOnEmptyIds && this.ids.length === 0 && this.notIds.length === 0) {
            this.toggleStatusQuery(false);
        }

        // when user added all ids - auto enable status query and remove ids list.
        if (this.ids.length === this.total) {
            this.ids = [];
            this.toggleStatusQuery(true);
        }

        // when user added all ids as NOT ids - auto disabled status query and remove notIds list.
        if (this.notIds.length === this.total) {
            this.notIds = [];
            this.toggleStatusQuery(false);
        }
    }

    queries() {
        if (this.isEmpty()){
            return null;
        }

        const queries: ApiObject.Queries = [];

        if (this.statusQuery) {
            queries.push({
                entity: 'employee',
                field: 'status',
                operator: ApiObject.QueryOperator.eq,
                value: this.status
            })
        }

        if (this.ids.length > 0) {
            queries.push({
                entity: 'employee',
                field: 'id',
                operator: ApiObject.QueryOperator.in,
                value: this.ids.join(',')
            })
        }

        if (this.notIds.length > 0) {
            queries.push({
                entity: 'employee',
                field: 'id',
                operator: ApiObject.QueryOperator.nin,
                value: this.notIds.join(',')
            })
        }

        return queries;
    }
}
