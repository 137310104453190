import React, {PureComponent} from 'react';
import styled from 'styled-components';
import {Column} from 'react-virtualized';
import {constants, interfaces, stylingVariables} from '@global';
import {defaultInfiniteTableCellDataGetter, Icon, Layout} from '@common';
import HistoryGroup from './HistoryGroup';

const StyledGroupRow = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;

  span {
    font-weight: ${stylingVariables.fontWeight.regular};
  }
`;

const IconWrapper = styled.div<{isOpen: boolean}>`
  padding: 10px 7px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background-color: ${stylingVariables.colorPalette.lightGreen};
  }

  svg {
    transition: all 0.5s 0.25s;
  }

  ${props => props.isOpen && `
    svg {
      transform: rotate(180deg);
    }
  `}

  ${props => !props.isOpen && `
    svg {
      transform: rotate(0deg);
    }
  `}
`;

const Subtitle = styled(Layout.Subtitle)`
    label {
      margin-left: 5px;
      color: ${stylingVariables.colorPalette.orange};
      background: ${stylingVariables.colorPalette.gray};
      padding: 3px 6px;
      border-radius: 13px;
    }
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
`;

const ActiveDot = styled.div<{active: boolean}>`
  margin: auto 0 auto 20px;
  height: 5px;
  width: 5px;
  border-radius: 50%;
  margin-right: 5px;
  background: ${props => props.active ? stylingVariables.colorPalette.green : stylingVariables.colorPalette.red};
`;

interface CellProps {
    cellData: any;
    toggleRowHeight: (index: number) => void;
    rowIndex: number;
    isOpen: boolean;
}

interface CellState {
    isOpen: boolean;
}

class Cell extends PureComponent<CellProps, CellState> {
    constructor(props: CellProps) {
        super(props);
        this.state = {
            isOpen: props.isOpen,
        };
    }

    componentDidUpdate(prevProps: Readonly<CellProps>, prevState: Readonly<CellState>) {
        if (prevProps.isOpen !== this.props.isOpen) {
            this.setState( { isOpen: this.props.isOpen });
        }
    }

    public render() {
        const { cellData } = this.props;

        return (
            <div style={{width: '100%'}}>
                <StyledGroupRow key={cellData.id}>
                    {cellData.history.length === 1 && (
                        <TitleWrapper>
                            <ActiveDot active={cellData.active} />
                            <div>{cellData.history[0].label}</div>
                        </TitleWrapper>
                    )}
                    {cellData.history.length > 1 && (
                        <>
                            <TitleWrapper>
                                <ActiveDot active={cellData.active} />
                                <div>{cellData.history[0].label}</div>
                            </TitleWrapper>
                            <TitleWrapper>
                                <Subtitle>
                                    <label>{cellData.history.length} changes</label>
                                </Subtitle>
                                <IconWrapper isOpen={this.state.isOpen} onClick={(e: any) => this.toggle(e)}>
                                    <Icon
                                        type={constants.IconTypes.ARROW_DOWN}
                                        width={16}
                                        height={10}
                                    />
                                </IconWrapper>
                            </TitleWrapper>
                        </>
                    )}
                </StyledGroupRow>
                {this.state.isOpen && <HistoryGroup
                  showBubble={false}
                  history={cellData.history}
                />}
            </div>
        );
    }

    toggle = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState(prevState => ({
            isOpen: !prevState.isOpen,
        }));
        this.props.toggleRowHeight(this.props.rowIndex);
    }
}

const StyledHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

interface IProps {
    orders?: interfaces.ListingOrders;
    changeOrder?: (column: string) => any;
    toggleRowHeight: (index: number) => void;
    openRows: number[];
}

const GroupLabelWithStatus: React.FC<IProps> = ({
         toggleRowHeight,
         openRows,
     }) => (
    <Column
        dataKey={'label'}
        width={350}
        flexGrow={1}
        cellDataGetter={defaultInfiniteTableCellDataGetter}
        headerRenderer={() => (
            <StyledHeaderWrapper>
                {'Name'}
            </StyledHeaderWrapper>
        )}
        cellRenderer={({cellData, rowIndex}) => <Cell
            cellData={cellData}
            toggleRowHeight={toggleRowHeight}
            rowIndex={rowIndex}
            isOpen={openRows.includes(rowIndex)}
        />}
    />
);

export default GroupLabelWithStatus;
